import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../../config/notification";
import { store } from "react-notifications-component";
import {
  fetchingWorkflowList,
  fetchWorkflowList,
  workFlowListFetchComplete,
  deletingWorkflow,
  workflowDeleted,
  fetchingConditionList,
  fetchConditionList,
  conditionListFetchComplete,
  fetchingWorkflowById,
  fetchWorkflowById,
  workflowByIdFetchingComplete,
  workflowSaved,
  workflowSaveFailed,
} from "../store/actions";

import {
  INIT_WORKFLOW_LIST_FETCH,
  INIT_DELETE_WORKFLOW,
  INIT_CONDITION_LIST_FETCH,
  INIT_FETCH_WORKFLOW_BY_ID,
  INIT_WORKFLOW_SAVE,
} from "./actionTypes";
import axios from "../../../../config/axiosCall";

import {
  getRequestDetails,
  postRequestDetails,
  putRequestDetails,
} from "../../../../config/requestHeaders";

export default function* watchWorkflow() {
  yield all([
    takeEvery(INIT_WORKFLOW_LIST_FETCH, initWorkflowListFetchSaga),
    takeEvery(INIT_DELETE_WORKFLOW, initDeleteWorkflowSaga),
    takeEvery(INIT_CONDITION_LIST_FETCH, initConditionListFetchSaga),
    takeEvery(INIT_FETCH_WORKFLOW_BY_ID, initGetWorkflowByIdSaga),
    takeEvery(INIT_WORKFLOW_SAVE, initWorkflowSaveSaga),
  ]);
}

function* initWorkflowListFetchSaga() {
  yield put(fetchingWorkflowList());
  const url = `/Workflow/List`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);

    if (response) {
      yield put(fetchWorkflowList(response.data || ""));
    }
    yield put(workFlowListFetchComplete());
  } catch (error) {
    yield put(workFlowListFetchComplete());
  }
}

function* initDeleteWorkflowSaga(action) {
  yield put(deletingWorkflow());
  const url = "/Workflow/Delete";
  const requestDetails = { ...postRequestDetails };
  const { payload, callback } = action || {};
  yield (requestDetails.data = { ...payload });
  try {
    const response = yield call(axios, url, requestDetails);

    if (response) {
      callback && callback(response.status);
      const notification = buildNotification({
        message: "msp.workflowDeleted", 
        type: "success",
      });

      yield put(workflowDeleted());
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    yield put(workflowDeleted());
  }
}

function* initConditionListFetchSaga() {
  yield put(fetchingConditionList());
  const url = `/Workflow/ListConditions`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);

    if (response) {
      yield put(fetchConditionList(response.data || []));
    }
    yield put(conditionListFetchComplete());
  } catch (error) {
    yield put(conditionListFetchComplete());
  }
}

function* initGetWorkflowByIdSaga(action) {
  yield put(fetchingWorkflowById());
  const { id, callback } = action || {};
  const url = `/Workflow/workflowById?approvalWorkflowId=${id}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      const { data } = response || {};
      yield put(fetchWorkflowById(data));
      callback && callback(data);
    }
    yield put(workflowByIdFetchingComplete());
  } catch (error) {
    yield put(workflowByIdFetchingComplete());
    callback && callback();
  }
}

function* initWorkflowSaveSaga(action) {
  const url = "/Workflow/save";
  const { payload, callback } = action || {};
  const { formData } = payload || {};
  try {
    const requestDetails = { ...putRequestDetails };
    yield (requestDetails.data = { ...formData });
    const response = yield call(axios, url, requestDetails);

    if (response && response.status === 202) {
      callback && callback(response.status);
      yield put(workflowSaved());
      const message =
        formData && formData.approvalWorkflowID
          ? "msp.workflowUpdated"
          : "msp.workflowSaved"; 
      const notification = buildNotification({
        message: message,
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    } else {
      yield put(workflowSaveFailed());
    }
  } catch (error) {
    yield put(workflowSaveFailed());
  }
}
