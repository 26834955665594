import { call, put, all, takeEvery } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import axios from "../../../config/axiosCall";
import {
  verificationData,
  fetchingTokenDetail,
  fetchingTokenDetailSuccess,
  fetchingTokenDetailFailed,
} from "./action";
import { INIT_VERIFICATION_DATA, GET_TOKEN_DETAILS } from "./actionTypes";
import { postRequestDetails } from "../../../config/requestHeaders";
export default function* watchVerificationSaga() {
  yield all([
    takeEvery(INIT_VERIFICATION_DATA, initVerificationSaga),
    takeEvery(GET_TOKEN_DETAILS, getTokenDetailSaga),
  ]);
}
function* initVerificationSaga(action) {
  const url = "/Users/AccountConfirmed";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.data);
  const response = yield call(axios, url, requestDetails);
  try {
    if (response && response.status === 200) {
      yield put(verificationData());
      const notification = buildNotification({
        message: "msp.verificationSuccessful",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      action.callBackFunction(response);
    }
  } catch (error) {
    action.callBackFunction("error");
    console.log(error);
  }
}

function* getTokenDetailSaga(action) {
  yield put(fetchingTokenDetail());
  const requestDetails = { ...postRequestDetails };
  try {
    const url = "/Users/validateTokenForAuth?token=" + action.token;
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchingTokenDetailSuccess());
      action.callback("success", response.data);
    } else {
      // action.callback("failed");
      yield put(fetchingTokenDetailFailed());
    }
  } catch (error) {
    yield put(fetchingTokenDetailFailed());
    action.callback("failed");
  }
}
