import styled from "styled-components";
import { Cols } from "components";
import { IconWrapper } from "components/index";

export const Pages = styled(Cols)`
  align-items: center;
  cursor: pointer;
  &
    li:nth-last-child(${(props) =>
        props.range &&
        (props.range[0] === "dots"
          ? 6
          : props.range[props.range.length - 1] === "dots"
          ? 4
          : null)}) {
    display: none;
  }
  @media (min-width: 48em) {
    &
      li:nth-last-child(${(props) =>
          props.range &&
          (props.range[0] === "dots"
            ? 6
            : props.range[props.range.length - 1] === "dots"
            ? 4
            : null)}) {
      display: flex;
    }
  }
`;

export const PageDropdownWrapper = styled(Cols)`
  align-self: center;
  align-items: center;
`;

export const PageNumber = styled(Cols)`
  min-width: 1.5em;
  justify-content: center;
  color: ${(props) => (props.active ? "rgb(255,255,255)" : "rgb(21, 24, 27)")};
  font-size: var(--fs-12);
  font-family: ${(props) => (props.active ? "graphik-semibold" : "graphik")};
  font-weight: ${(props) => (props.active ? "600" : "normal")};
  text-align: center;
  letter-spacing: 0;
  line-height: 0.5rem;
  padding: 0.5em;
  background: ${(props) =>
    props.active ? "rgb(0, 142, 255)" : "rgb(255,255,255)"};
  border-radius: ${(props) => (props.active ? "1.25em" : "0")};
  @media (min-width: 36em) {
    min-width: 2em;
    line-height: 0.75rem;
  }
`;

export const PageText = styled.p`
  color: var(--dark-grey-clr);
  font-size: clamp(var(--fs-12), 2vw, var(--fs-14));
  font-family: var(--ff-medium);
  font-weight: var(--fw-normal);
`;

export const SVGWrapper = styled(IconWrapper)`
  svg {
    fill: ${(props) =>
      props.disabled ? `var(--disabled-clr-dark)` : `var(--grey-clr)`};
  }
`;

export const customStyles = {
  container: (provided) => ({
    ...provided,
    position: "relative",
    boxSizing: "border-box",
    width: "100%",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: "var(--fs-12)",
    cursor: "pointer",
    padding: "0",
    paddingRight: "0.75rem",
  }),
  control: (provided) => ({
    ...provided,
    background: "rgb(255, 255, 255)",
    color: "rgb(21, 24, 27)",
    height: "2em",
    minHeight: "2em",
    cursor: "pointer",
    border: 0,
    boxShadow: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? "rgb(244, 246, 248)" : "rgb(255, 255, 255)",
    color: "rgb(21, 24, 27)",
    fontSize: "var(--fs-12)",
    fontFamily: "var(--ff-medium)",
    fontWeight: "var(--fw-normal)",
    letterSpacing: "-0.0156rem",
    lineHeight: "0.75rem",
    cursor: "pointer",
    paddingLeft: "0.5em",
    textAlign: "center",
    borderRadius: state.isSelected ? "0.25rem" : "0",
    "&:hover": {
      background: "rgb(244, 246, 248)",
      borderRadius: "0.25rem",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "rgb(21, 24, 27)",
    fontSize: "var(--fs-12)",
    fontFamily: "var(--ff-medium)",
    fontWeight: "var(--fw-normal)",
    letterSpacing: "-0.0156rem",
    lineHeight: "0.75rem",
    paddingRight: "1.125rem",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "rgb(21, 24, 27)",
    fontSize: "var(--fs-12)",
    fontFamily: "var(--ff-medium)",
    fontWeight: "var(--fw-normal)",
    letterSpacing: "-0.0156rem",
    lineHeight: "0.75rem",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "1.875rem",
  }),
  indicatorSeparator: () => ({
    display: "none",
    backgroundColor: "transparent",
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: "0",
  }),
  menuList: (provided) => ({
    ...provided,
    fontSize: "var(--fs-12)",
    padding: "0.5rem",
    maxHeight: "12rem",
  }),
};
