import { put, takeEvery, call, all } from "redux-saga/effects";
import { store } from "react-notifications-component";

import axios from "../../../../config/axiosCall";
import { buildNotification } from "../../../../config/notification";

import { fetchingNotificationsList, fetchNotificationsList } from "./actions";
import { INIT_NOTIFICATION_LIST, SAVE_NOTIFICATION_LIST } from "./actionTypes";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../../config/requestHeaders";
import { storage } from "config/storage";
export default function* watchReportStatusFetch() {
  yield all([
    takeEvery(INIT_NOTIFICATION_LIST, getNotificationListSaga),
    takeEvery(SAVE_NOTIFICATION_LIST, saveNotificationListSaga),
  ]);
}

function* getNotificationListSaga(action) {
  const sessionDetails = yield JSON.parse(
    storage.getItem("sessionDetails")
  ) || {};
  let { languageId = "enGB" } = yield sessionDetails;
  yield put(fetchingNotificationsList(true));
  let url = "";
  if (action.data !== "")
    url = `/Report/listInvoiceNotifications?userName=${action.data}&languageID=${languageId}`;
  else url = `/Report/listInvoiceNotifications?languageID=${languageId}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(fetchingNotificationsList(false));
      yield put(fetchNotificationsList(response.data));
      if (action.callback) {
        action.callback(response.data);
      }
    }
  } catch (err) {
    yield put(fetchingNotificationsList(false));
  }
}

function* saveNotificationListSaga(action) {
  const url = "/Report/savenotificationInBulk";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    ...action.data,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "msp.statusNotificationChanged",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
    if (action.callback) {
      action.callback(response);
    }
  } catch (error) {
    if (action.callback) {
      action.callback(error);
    }
  }
}
