export const INIT_BULK_UPLOAD_TYPES_FETCH = "INIT_BULK_UPLOAD_TYPES_FETCH";
export const FETCHING_BULK_UPLOAD_TYPES = "FETCHING_BULK_UPLOAD_TYPES";
export const FETCH_BULK_UPLOAD_TYPES = "FETCH_BULK_UPLOAD_TYPES";
export const BULK_UPLOAD_TYPES_FETCH_COMPLETE =
  "BULK_UPLOAD_TYPES_FETCH_COMPLETE";

export const INIT_RECENT_UPLOADED_FILES_FETCH =
  "INIT_RECENT_UPLOADED_FILES_FETCH";
export const FETCHING_RECENT_UPLOADED_FILES = "FETCHING_RECENT_UPLOADED_FILES";
export const FETCH_RECENT_UPLOADED_FILES = "FETCH_RECENT_UPLOADED_FILES";
export const RECENT_UPLOADED_FILES_FETCH_COMPLETE =
  "RECENT_UPLOADED_FILES_FETCH_COMPLETE";

export const INIT_UPLOADED_FILES_LIST_FETCH = "INIT_UPLOADED_FILES_LIST_FETCH";
export const FETCHING_UPLOADED_FILES_LIST = "FETCHING_UPLOADED_FILES_LIST";
export const FETCH_UPLOADED_FILES_LIST = "FETCH_UPLOADED_FILES_LIST";
export const UPLOADED_FILES_LIST_FETCH_COMPLETE =
  "UPLOADED_FILES_LIST_FETCH_COMPLETE";

export const INIT_TEMPLATE_FETCH = "INIT_TEMPLATE_FETCH";
export const FETCHING_TEMPLATE = "FETCHING_TEMPLATE";
export const TEMPLATE_FETCH_COMPLETE = "TEMPLATE_FETCH_COMPLETE";

export const INIT_BULK_UPLOAD_FILE_FETCH = "INIT_BULK_UPLOAD_FILE_FETCH";
export const FETCHING_BULK_UPLOAD_FILE = "FETCHING_BULK_UPLOAD_FILE";
export const BULK_UPLOAD_FILE_FETCH_COMPLETE =
  "BULK_UPLOAD_FILE_FETCH_COMPLETE";

export const INIT_SEND_EMAIL = "INIT_SEND_EMAIL";
export const SENDING_EMAIL = "SENDING_EMAIL";
export const EMAIL_SENT = "EMAIL_SENT";
export const EMAIL_SENT_SUCCESS = "EMAIL_SENT_SUCCESS";
export const EMAIL_SENT_FAILED = "EMAIL_SENT_FAILED";

export const INIT_BULK_UPLOAD_FILE_STATUS_LIST_FETCH =
  "INIT_BULK_UPLOAD_FILE_STATUS_LIST_FETCH";
export const FETCHING_BULK_UPLOAD_FILE_STATUS_LIST =
  "FETCHING_BULK_UPLOAD_FILE_STATUS_LIST";
export const FETCH_BULK_UPLOAD_FILE_STATUS_LIST =
  "FETCH_BULK_UPLOAD_FILE_STATUS_LIST";
export const BULK_UPLOAD_FILE_STATUS_LIST_FETCH_COMPLETE =
  "BULK_UPLOAD_FILE_STATUS_LIST_FETCH_COMPLETE";

export const INIT_BULK_USER_UPLOAD = "INIT_BULK_USER_UPLOAD";
export const BULK_USER_UPLOADING = "BULK_USER_UPLOADING";
export const SET_BULK_USER_UPLOAD_PROGRESSION =
  "SET_BULK_USER_UPLOAD_PROGRESSION";
export const BULK_USER_UPLOAD_COMPLETE = "BULK_USER_UPLOAD_COMPLETE";
export const BULK_USER_UPLOAD_RESET = "BULK_USER_UPLOAD_RESET";
