import { fromJS } from "immutable";
import {
  CLEAR_DATA_CAPTURE,
  CLEAR_INPUTS,
  SET_DATA_CAPTURE,
  SET_INPUT,
  SET_INPUT_VALUE,
} from "./actionTypes";

const initialDataCapture = {
  X1: 0,
  Y1: 0,
  X2: 0,
  Y2: 0,
};
export const initialState = fromJS({
  dataCapture: initialDataCapture,
  input: null,
  headerOrLine: null,
  inputValue: null,
});

const reportDetailsreducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_CAPTURE: {
      return state.set("dataCapture", fromJS(action.data));
    }
    case CLEAR_DATA_CAPTURE: {
      return state.set("dataCapture", fromJS(initialDataCapture));
    }
    case SET_INPUT:
      const { input, headerOrLine } = action.data;
      return state
        .set("input", fromJS(input))
        .set("headerOrLine", fromJS(headerOrLine))
        .set("inputValue", null);
    case SET_INPUT_VALUE:
      return state.set("inputValue", fromJS(action.data));
    case CLEAR_INPUTS:
      return state
        .set("input", null)
        .set("headerOrLine", null)
        .set("inputValue", null);
    default:
      return state;
  }
};
export default reportDetailsreducer;
