import { put, takeEvery, call, all } from "redux-saga/effects";
import { store } from "react-notifications-component";
import { buildNotification } from "config/notification";
import axios from "config/axiosCall";
import { storage } from "config/storage";

import {
  fetchNotificationData,
  fetchingNotificationList,
  fetchNotificationCompleted,
  fetchingNotificationPreferences,
  fetchNotificationPreferences,
  fetchNotificationPreferencesCompleted,
  fetchArchiveData,
  fetchingArchivedNotification,
  fetchArchivedNotificationCompleted,
} from "./actions";

import {
  GET_NOTIFICATION_DATA,
  GET_NOTIFICATION_PREFERENCES,
  INIT_SUBMIT_NOTIFICATION_PREFERENCES,
  INIT_UPDATE_NOTIFICATION,
  INIT_GET_ARCHIVED_ANNOUNCEMENT,
} from "./actionTypes";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";

export default function* watchAnnouncements() {
  yield all([
    takeEvery(GET_NOTIFICATION_DATA, getNotification),
    takeEvery(GET_NOTIFICATION_PREFERENCES, getNotificationPreferences),
    takeEvery(
      INIT_SUBMIT_NOTIFICATION_PREFERENCES,
      submitNotificationPreferences
    ),
    takeEvery(INIT_UPDATE_NOTIFICATION, updateNotification),
    takeEvery(INIT_GET_ARCHIVED_ANNOUNCEMENT, getArchivedAnnouncement),
  ]);
}

function* getNotification() {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const languageID =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "enGB";
  const url = "/Users/notifications?LanguageID=" + languageID;
  yield put(fetchingNotificationList());
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchNotificationData(response.data));
    }
    yield put(fetchNotificationCompleted());
  } catch (error) {
    yield put(fetchNotificationCompleted());
  }
}

function* getNotificationPreferences(action) {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const languageID =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "enGB";
  const url =
    "/Report/invoiceNotificationForUser?userId=" +
    action.userId +
    "&LanguageID=" +
    languageID;
  yield put(fetchingNotificationPreferences());
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const { data } = response;

    yield put(fetchNotificationPreferences(data[0]["getNotificationStatuses"]));

    yield put(fetchNotificationPreferencesCompleted());
  } catch (err) {
    yield put(fetchNotificationPreferencesCompleted());
  }
}

function* submitNotificationPreferences(action) {
  const url = "/Report/savenotification";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    ...action.data,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "msp.statusSaveSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateNotification(action) {
  const url =
    "/Users/UpdateNotification?notificationId=" +
    action.data.notificationId +
    "&notificationType=" +
    action.data.notificationType +
    "&isArchive=" +
    action.data.isArchive +
    "&Active=" +
    action.data.Active;
  const postRequestDetailsNotifi = {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = yield call(axios, url, { ...postRequestDetailsNotifi });
    if (response && response.status === 204) {
      action.callback && action.callback();
      // const notification = buildNotification({
      //   message: "",
      //   type: "success",
      // });
      // store.addNotification({
      //   ...notification,
      // });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getArchivedAnnouncement(action) {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const languageID =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "enGB";
  yield put(fetchingArchivedNotification());
  const url = "/Users/notifications?LanguageID=" + languageID + "&isArchive=1";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchArchiveData(response.data));
    }
    yield put(fetchArchivedNotificationCompleted());
  } catch (error) {
    yield put(fetchArchivedNotificationCompleted());
    console.log(error);
  }
}
