import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { store } from "react-notifications-component";
import { buildNotification } from "config/notification";
import { NavTab, Popup, SecondaryBtn } from "components/";
import { tabs, generateColumns } from "container/InvoiceStatus/config/";
import { HeaderBtns, PopupHeader } from "../PaidInvoicePopup/style";
import { PopupContent, ShowDetailsBtn } from "./style";
import DetailsSection from "./DetailsSection";

const DetailedView = (props) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("");
  const [showDetails, setShowDetails] = useState(true);
  const {
    show,
    onClose,
    status,
    amountDetails,
    dfmInvoice,
    invoiceColHeaders,
    invoiceDetails,
    invoiceHeaderItems,
    paymentDetails,
    advancePaymentDetails,
    fetchPdfStatus,
    lineCols,
    lineColsAdvPayment
  } = props || "";

  let pdfContent = props.dfmInvoice || {};
  let sectionOneData = [];
  let sectionTwoData = [];
  let sectionThreeData = [];
  let sectionOneTitle = "";
  let sectionTwoTitle = "";

  const generateSectionOneData = (data, columns) => {
    const sectionData =
      (columns &&
        columns
          .filter(
            (col) =>
              data &&
              data[0] &&
              data[0][col.selector] &&
              data[0][col.selector] !== undefined &&
              !col.omit
          )
          .map((col) => {
            return {
              value: data[0][col.selector],
              label: col.name ? col.name : col.selector,
            };
          })) ||
      [];
    return sectionData;
  };

  const generateSectionTwoData = (fields,details) => {
    const columns = fields
      .sort((curr, prev) => {
        return curr.GridOrder - prev.GridOrder;
      })
      .map((col) => ({ selector: col.FieldName, name: col.Label }));
    return { data: details, columns };
  };
  const generateSectionThreeData = (headerData = [], invoiceDetails = []) => {
    //taking latest invoice incase of multiple invoices
    const invoiceDetail = invoiceDetails.length > 0 ? invoiceDetails[0] : {};
    const { SubmissionId = 0 } = invoiceDetail;
    let subTotal = 0,
      taxAmount = 0,
      total = 0;
    if (SubmissionId) {
      headerData.length > 0 &&
        headerData
          .filter((headerItem) => headerItem.SubmissionId === SubmissionId)
          .forEach((item) => {
            //standard attr names
            const {
              totalAmount = 0,
              subTotalExclTax = 0,
              totalTax = 0,
            } = item || {};
            const totalAmt = Number(totalAmount);
            const subTotalAmt = Number(subTotalExclTax);
            const totalTaxAmt = Number(totalTax);
            if (!isNaN(totalAmt)) {
              total = Number(totalAmt);
            }
            if (!isNaN(subTotalAmt)) {
              subTotal = Number(subTotalAmt);
            }
            if (!isNaN(totalTaxAmt)) {
              taxAmount = Number(totalTaxAmt);
            }
          });
    }

    return {
      total: subTotal,
      paid: taxAmount,
      balance: total,
    };
  };

  const invoiceCols = generateColumns(invoiceColHeaders || []);

  const filteredHeader =
    invoiceCols &&
    invoiceCols.filter(
      (item) =>
        item.selector.includes("InvoiceDocNumber") ||
        item.selector.includes("DocAmount") ||
        item.selector.includes("DocDate")
    );

  if (selectedTab === "Payment Details") {
    sectionOneData = generateSectionOneData(
      invoiceDetails || [],
      filteredHeader || []
    );
    sectionTwoData = generateSectionTwoData(lineCols,paymentDetails);
    sectionThreeData = amountDetails;
    sectionOneTitle = t("msp.paymentReceived");
    sectionTwoTitle = t("msp.remittances");
  } else if (selectedTab === "Invoice Details"){
    sectionOneData = generateSectionOneData(
      invoiceDetails || [],
      invoiceCols || []
    );
    sectionTwoData = null;
    sectionThreeData = generateSectionThreeData(
      invoiceHeaderItems,
      invoiceDetails
    );
    sectionOneTitle = t("msp.invoiceHeader");
  }
  else if(selectedTab === "Advance Payment Details"){
    sectionOneData = generateSectionOneData(
      invoiceDetails || [],
      filteredHeader || []
    );
    sectionTwoData = generateSectionTwoData(lineColsAdvPayment,advancePaymentDetails);
    sectionThreeData = amountDetails;
    sectionOneTitle = t("msp.advancePaymentReceived");
    sectionTwoTitle = t("msp.advancePaymentInformation");
  }

  useEffect(() => {
    const activeTab = status
      ? "Payment Details"
      : "Invoice Details";

    setSelectedTab(activeTab);
  }, [status, t]);

  let updateTabFlag = false;
  let updatedTabs = [];
  if (status) {
    updateTabFlag = true;
    updatedTabs = [...tabs];
    const index = updatedTabs.findIndex(
      (tab) => tab.selector === t("msp.paymentDetails")
    );
    index === -1 &&
      updatedTabs.splice(0, 0, {
        selectorID: 1,
        selector: "Payment Details",
        text: t("msp.paymentDetails"),
      });
  }
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let menus = sessionDetails ? sessionDetails.menus : [];
  let menuData;
  if(menus){
    menuData = menus.find(
      (item) => item.pageElementFileName === "advancePayments"
    )
  }
  if (amountDetails.advancePaid && amountDetails.advancePaid !== 0 && menuData) {    
    if (updateTabFlag === true)
      updatedTabs = [...updatedTabs];
    else
      updatedTabs = [...tabs];
        
    updateTabFlag = true;
    const index = updatedTabs.findIndex(
      (tab) => tab.selector === "Advance Payment Details"
    );
    index === -1 &&
      updatedTabs.push({
        selectorID: 3,
        selector: "Advance Payment Details",
        text: t("msp.advancePaymentDetails"),
      });
  }

  const handleTabChange = ({ selector }) => {
    setSelectedTab(selector);  
  };

  const handleDownload = () => {
    if (dfmInvoice && dfmInvoice.size !== 0) {
      const url = window.URL.createObjectURL(
        new Blob([dfmInvoice], {
          type: "application/pdf",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Invoice");
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "msp.attachmentDownloadSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    } else {
      const notification = buildNotification({
        message: "msp.noDataToDownload",
        type: "warning",
      });
      store.addNotification({
        ...notification,
      });
    }
  };

  return (
    <>
      <Popup show={show} onClose={onClose}>
        <PopupHeader>
          <NavTab
            tabs={updateTabFlag ? updatedTabs : tabs}
            active={selectedTab}
            changeActive={handleTabChange}
            showAll={false}
          />
          <HeaderBtns>
            <ShowDetailsBtn sm onClick={() => setShowDetails(!showDetails)}>
              {showDetails ? `${t("msp.show")} PDF` : t("msp.showDetails")}
            </ShowDetailsBtn>
            <SecondaryBtn sm onClick={handleDownload}>
              {t("msp.download")}
            </SecondaryBtn>
          </HeaderBtns>
        </PopupHeader>

        <PopupContent>
          <DetailsSection
            attachments={pdfContent}
            activeTab={selectedTab}
            sectionOneTitle={sectionOneTitle}
            sectionOneData={sectionOneData}
            sectionTwoTitle={sectionTwoTitle}
            sectionTwoData={sectionTwoData}
            sectionThreeData={sectionThreeData}
            showDetails={showDetails}
            fetchPdfStatus={fetchPdfStatus || false}
            handleDownload={handleDownload}
          />
          {/* <Spinner /> */}
        </PopupContent>
      </Popup>
    </>
  );
};

DetailedView.propTypes = {
  amountDetails: PropTypes.any,
  dfmInvoice: PropTypes.object,
  invoiceColHeaders: PropTypes.any,
  invoiceDetails: PropTypes.array,
  invoiceHeaderItems: PropTypes.array,
  invoiceLineitems: PropTypes.array,
  onClose: PropTypes.any,
  paymentDetails: PropTypes.object,
  advancePaymentDetails: PropTypes.object,
  show: PropTypes.any,
  status: PropTypes.any,
};

export default DetailedView;
