import {
  put,
  takeEvery,
  call,
  all,
  takeLatest,
  select,
} from "redux-saga/effects";

import { zeroPad } from "container/Support/config/index";

import {
  languageFetching,
  fetchLanguages,
  languageFetchComplete,
  supplierFieldFetching,
  fetchSupplierFields,
  supplierFieldFetchComplete,
  countryFetching,
  fetchCountry,
  countryFetchComplete,
  submissionFieldsFetching,
  fetchSubmissionFields,
  submissionFieldsFetchComplete,
  fetchTimeZones,
  timeZonesFetchComplete,
  submissionStatusFetching,
  fetchSubmissionStatus,
  submissionStatusFetchComplete,
  downloading,
  download,
  downloadComplete,
  clientPlacementConfigFetching,
  clientPlacementConfigFetch,
  clientPlacementConfigFetchComplete,
  validationTypeFetching,
  validationTypeFetch,
  validationTypeFetched,
  regexFetching,
  regexFetch,
  regexFetched,
  currencyFetching,
  currencyFetch,
  currencyFetchCompleted,
  downloadFailed,
  settingDefaultCurrency,
  setDefaultCurrencyCompleted,
  previewing,
  preview,
  previewComplete,
  previewFailed,
  fetchingSupplierLookupList,
  fetchSupplierLookupList,
  fetchSupplierLookupListComplete,
  savingActiveColumns,
  activeColumnsSavingCompleted,
  activeColumnsSaveFailed,
  fetchingActiveColumns,
  fetchActiveColumns,
  activeColumnsFetchingCompleted,
  activeColumnsFetchFailed,
  invoiceStatusFetching,
  invoiceStatusFetch,
  invoiceStatusFetchCompleted,
  fetchingSearchRecords,
  fetchSearchRecords,
  searchRecordsFetchingComplete,
} from "../store/actions";

import { authenticationSuccess } from "../container/Authentication/store/actions";

import {
  INIT_LANGUAGE_FETCH,
  INIT_SUPPLIER_FIELD_FETCH,
  INIT_COUNTRY_FETCH,
  INIT_SUBMISSION_FIELDS_FETCH,
  INIT_TIME_ZONES_FETCH,
  INIT_SUBMISSION_STAUS_FETCH,
  INIT_DOWNLOAD,
  INIT_CLIENT_PLACEMENT_CONFIG_STATUS_FETCH,
  INIT_VALIDATION_TYPES_FETCH,
  INIT_REGEX_FETCH,
  INIT_CURRENCY_FETCH,
  INIT_SET_DEFAULT_CURRENCY,
  INIT_PREVIEW,
  INIT_SUPPLIER_LOOKUP_LIST,
  INIT_SAVE_ACTIVE_COLUMNS,
  INIT_FETCH_ACTIVE_COLUMNS,
  INIT_GET_SELECTED_SUPPLIER_LIST,
  INIT_INVOICE_STATUS_FETCH,
  INIT_GLOBAL_SEARCH,
} from "./actionTypes";
import axios from "../config/axiosCall";
import {
  getRequestDetails,
  postRequestDetails,
} from "../config/requestHeaders";
import {
  selectAdminRoleDetails,
  selectSelectedUserId,
} from "container/Analytics/InvoiceList/store/selectors";

export default function* watchCommonAPI() {
  yield all([
    takeEvery(INIT_LANGUAGE_FETCH, initLanguageFetchSaga),
    takeEvery(INIT_SUPPLIER_FIELD_FETCH, initSupplierFieldsFetchSaga),
    takeEvery(INIT_COUNTRY_FETCH, initCountryFetchSaga),
    takeEvery(INIT_SUBMISSION_FIELDS_FETCH, initSubmissionFieldsFetchSaga),
    takeEvery(INIT_TIME_ZONES_FETCH, initTimeZonesFetchSaga),
    takeEvery(INIT_SUBMISSION_STAUS_FETCH, initSubmissionStatusFetchSaga),
    takeEvery(INIT_DOWNLOAD, initDownloadSaga),
    takeEvery(INIT_PREVIEW, initPreviewSaga),
    takeEvery(
      INIT_CLIENT_PLACEMENT_CONFIG_STATUS_FETCH,
      initClientPlacementConfigSaga
    ),
    takeEvery(INIT_CURRENCY_FETCH, initCurrencySaga),
    takeEvery(INIT_VALIDATION_TYPES_FETCH, initValidationTypeFetchSaga),
    takeEvery(INIT_REGEX_FETCH, initRegexFetchSaga),
    takeEvery(INIT_SET_DEFAULT_CURRENCY, setDefaultCurrency),
    takeEvery(INIT_SUPPLIER_LOOKUP_LIST, getSupplierLookupListSaga),
    takeEvery(INIT_SAVE_ACTIVE_COLUMNS, initSaveActiveColsSaga),
    takeEvery(INIT_FETCH_ACTIVE_COLUMNS, getActiveColumnsListSaga),
    takeLatest(INIT_GET_SELECTED_SUPPLIER_LIST, getSelectedSupplierListSaga),
    takeEvery(INIT_INVOICE_STATUS_FETCH, initInvoiceStatusFetchSaga),
    takeEvery(INIT_GLOBAL_SEARCH, initGlobalSearchSaga),
  ]);
}

function* initLanguageFetchSaga(action) {
  yield put(languageFetching());
  const url = "/Common/language";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) yield put(fetchLanguages(response.data));
    yield put(languageFetchComplete());
  } catch (error) {
    yield put(languageFetchComplete());
  }
}

function* initSupplierFieldsFetchSaga(action) {
  yield put(supplierFieldFetching());
  const url = "/Common/supplierfields";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    clientId: 2,
    roleId: "APB_ADMIN",
    langKey: "enGB",
    //fieldPlacementTypeId: 0,
  });
  try {
    let data = [];
    const response = yield call(axios, url, requestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(fetchSupplierFields(data));
    yield put(supplierFieldFetchComplete());
  } catch (error) {
    yield put(supplierFieldFetchComplete());
  }
}
function* initCountryFetchSaga(action) {
  yield put(countryFetching());
  const url = "/Common/listkeys?typeName=__Countries";
  const requestDetails = { ...getRequestDetails };
  try {
    let data = [];
    const response = yield call(axios, url, requestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(fetchCountry(data));
    yield put(countryFetchComplete());
  } catch (error) {
    yield put(countryFetchComplete());
  }
}
function* initSubmissionFieldsFetchSaga(action) {
  yield put(submissionFieldsFetching());
  const url = "/Common/supplierfields";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    clientId: 2,
    roleId: "APB_ADMIN",
    langKey: "enGB",
    fieldPlacementTypeId: 6,
  });
  try {
    let data = [];
    const response = yield call(axios, url, requestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(fetchSubmissionFields(data));
    yield put(submissionFieldsFetchComplete());
  } catch (error) {
    yield put(submissionFieldsFetchComplete());
  }
}

function* initTimeZonesFetchSaga() {
  yield put(countryFetching());
  const url = "/Common/listkeys?typeName=__Time_Zones";
  const requestDetails = { ...getRequestDetails };
  try {
    let data = [];
    const response = yield call(axios, url, requestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(fetchTimeZones(data));
    yield put(timeZonesFetchComplete());
  } catch (error) {
    yield put(timeZonesFetchComplete());
  }
}

function* initSubmissionStatusFetchSaga(action) {
  yield put(submissionStatusFetching());
  const url = "Common/listkeys?typeName=__SubmissionStatus";
  const requestDetails = { ...getRequestDetails };
  try {
    let data = [];
    const response = yield call(axios, url, requestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(fetchSubmissionStatus(data));
    yield put(submissionStatusFetchComplete());
  } catch (error) {
    yield put(submissionStatusFetchComplete());
  }
}

function* initDownloadSaga(action) {
  const { payload } = action;
  yield put(downloading());
  const url = `/Common/download?attachmentId=${payload.blobID}`;
  const requestDetails = { ...getRequestDetails };
  requestDetails.responseType = "blob";
  try {
    let data = [];
    const response = yield call(axios, url, requestDetails);
    if (response && response.status !== 204) {
      if (action.callback) action.callback();
      data = response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", payload.filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    yield put(download(data));
    yield put(downloadComplete());
  } catch (error) {
    yield put(downloadFailed(true));
  }
}

function* initPreviewSaga(action) {
  const { payload } = action;
  yield put(previewing());
  const url = `/Common/download?attachmentId=${payload.blobID}`;
  const requestDetails = { ...getRequestDetails };
  requestDetails.responseType = "blob";
  try {
    let data = [];
    const response = yield call(axios, url, requestDetails);
    if (response && response.status !== 204) {
      data = response.data;
      if (action.callback) action.callback(data);
      const blob = new Blob([data], { type: data.type });
      const blobData = window.URL.createObjectURL(blob);
      window.open(blobData, "_blank");
    }
    yield put(preview(data));
    yield put(previewComplete());
  } catch (error) {
    yield put(previewFailed(true));
  }
}

function* initClientPlacementConfigSaga(action) {
  yield put(clientPlacementConfigFetching());
  const url = "/Common/listSubmissionFieldPlacement";
  const requestDetails = { ...getRequestDetails };
  try {
    let data = [];
    const response = yield call(axios, url, requestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(clientPlacementConfigFetch(data));
    yield put(clientPlacementConfigFetchComplete());
  } catch (error) {
    yield put(clientPlacementConfigFetchComplete());
  }
}

function* initValidationTypeFetchSaga(action) {
  yield put(validationTypeFetching());
  const url = "/Common/ValidationType";
  const requestDetails = { ...getRequestDetails };
  try {
    let data = [];
    const response = yield call(axios, url, requestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(validationTypeFetch(data));
    yield put(validationTypeFetched());
  } catch (error) {
    yield put(validationTypeFetched());
  }
}

function* initRegexFetchSaga(action) {
  yield put(regexFetching());
  const url = "/Common/listRegEX";
  const requestDetails = { ...getRequestDetails };
  try {
    let data = [];
    const response = yield call(axios, url, requestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(regexFetch(data));
    yield put(regexFetched());
  } catch (error) {
    yield put(regexFetched());
  }
}

function* initCurrencySaga() {
  yield put(currencyFetching());
  const url = "/Common/listcurrency";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(currencyFetch(response.data));
    yield put(currencyFetchCompleted());
  } catch (error) {
    yield put(currencyFetchCompleted());
  }
}

function* setDefaultCurrency(action) {
  yield put(settingDefaultCurrency());
  const sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  const { currency, currencyID } = action.data;
  const config = { ...postRequestDetails };
  const url =
    "/Users/updateDefaultCurrency?currencyID=" + action.data.currencyID;
  try {
    const response = yield call(axios, url, config);
    if (response && response.status === 202) {
      const updatedSessionDetails = {
        ...sessionDetails,
        currency,
        currencyID,
      };
      sessionStorage.setItem(
        "sessionDetails",
        JSON.stringify(updatedSessionDetails)
      );
      yield put(authenticationSuccess(updatedSessionDetails));
      yield put(setDefaultCurrencyCompleted());
    }
  } catch (error) {
    yield put(setDefaultCurrencyCompleted());
  }
}

function* getSupplierLookupListSaga(action) {
  yield put(fetchingSupplierLookupList());
  // For Invoice Reports if the role is admin populate supplier based on user click
  const isRoleAdmin = yield select(selectAdminRoleDetails());
  const selectedUserId = yield select(selectSelectedUserId());
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let url = isRoleAdmin
    ? `/Supplier/companiesForLookUpForInvoiceReports?langID=${langID}&userId=${selectedUserId}&`
    : `/Supplier/companiesForLookUp?langID=${langID}&`;
  if (action.data && action.data.userId && !isRoleAdmin)
    url = `/Supplier/companiesForLookUpEdit?langID=${langID}&`;
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + data + `=${encodeURIComponent(action.data[data])}&`;
      }
    });
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      const {
        companies = [],
        fieldLabels = [],
        totalCount: totalSupplierCount = 0,
      } = response.data;
      const resObj = { companies: [], fieldLabels: [], totalSupplierCount: 0 };
      resObj.companies = companies;
      resObj.totalSupplierCount = totalSupplierCount;
      resObj.fieldLabels = fieldLabels;
      if (isRoleAdmin && selectedUserId) {
        resObj.totalSupplierCount = response.data && response.data.length;
      }
      yield put(
        fetchSupplierLookupList({
          ...resObj,
        })
      );
      yield put(fetchSupplierLookupListComplete());
    }
  } catch (err) {
    yield put(fetchSupplierLookupListComplete());
  }
}

function* initSaveActiveColsSaga(action) {
  const url = `/Search/SaveColumns`;
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.payload);
  try {
    yield put(savingActiveColumns());
    let response = yield call(axios, url, requestDetails);
    if (response) {
      action.callback && action.callback(response, true);
      yield put(activeColumnsSavingCompleted());
    }
  } catch (error) {
    action.callback && action.callback({}, false);
    yield put(activeColumnsSaveFailed());
  }
}

function* getActiveColumnsListSaga(action) {
  yield put(fetchingActiveColumns());
  const { payload } = action;
  let url = `Search/ActivateColumns?PageElementID=${payload.pageElementID}&ModuleID=${payload.moduleID}`;
  const requestDetails = { ...getRequestDetails };
  try {
    let response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      yield put(fetchActiveColumns(response.data));
      yield put(activeColumnsFetchingCompleted());
    }
  } catch (err) {
    yield put(activeColumnsFetchFailed());
  }
}

function* getSelectedSupplierListSaga(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let url = yield "Supplier/SupplierList?langID=" + langID;
  let keys = action.payload && Object.keys(action.payload);
  keys &&
    keys.forEach((item) => {
      if (action.payload[item]) {
        url += `&${item}=${action.payload[item]}`;
      }
    });
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);

    if (action.callback) action.callback(response);
  } catch (err) {
    action.callback && action.callback(err);
  }
}

function* initInvoiceStatusFetchSaga(action) {
  yield put(invoiceStatusFetching());
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let languageId = sessionDetails && sessionDetails.languageId;
  const url = `Common/InvoiceStatus?languageID=${languageId || "enGB"}`;
  const requestDetails = { ...getRequestDetails };
  try {
    let data = [];

    const response = yield call(axios, url, requestDetails);
    if (response && response.status !== 204) {
      data = response.data;
    }
    yield put(invoiceStatusFetch(data));
    yield put(invoiceStatusFetchCompleted());
  } catch (error) {
    yield put(invoiceStatusFetchCompleted());
  }
}

function* initGlobalSearchSaga(action) {
  let url = "",
    requestDetails = { ...getRequestDetails };
  const {
    searchCategory = "",
    searchValue = "",
    LanguageID = "enGB",
    isArchival = false,
  } = action.payload || {};
  if (!searchValue) {
    return;
  }
  switch (searchCategory) {
    case "1":
      url = `Search/listInvoiceGlobalSearch?InvoiceNumber=${searchValue}&LanguageID=${LanguageID}&isArchival=${isArchival}`;
      break;
    case "2":
      url = `HelpDesk/helpdeskDetailGlobalSearch?helpdeskId=${searchValue}&LanguageID=${LanguageID}`;
      break;
    case "3":
      url = `Submission/submissionByGlobalSearch?ReferenceNumber=${searchValue}`;
      break;
    case "4":
      url = `Search/listInvoiceGlobalSearch?urn=${searchValue}&LanguageID=${LanguageID}&isArchival=${isArchival}`;
      break;
    case "5":
      url = `HelpDesk/GetServiceNowTickets?ticketID=${searchValue}`;
      break;
    default:
      return;
  }
  yield put(fetchingSearchRecords());
  try {
    const response = yield call(axios, url, requestDetails);
    let data = [];
    if (response.data) {
      switch (searchCategory) {
        case "1":
          const { invoiceList = [] } = response.data || {};
          data = [...invoiceList].map((invoice, key) => ({
            id: invoice.invoiceId || key,
            value: invoice.invoiceDocNumber,
          }));
          break;
        case "2":
          const listHelpdeskTickets = response.data || {};
          data = [...listHelpdeskTickets].map((ticketId, key) => ({
            id: ticketId || key,
            value: ticketId ? `MSP${zeroPad(ticketId, 7)}` : "",
          }));
          break;
        case "3":
          const historyList = response.data || {};
          data = [...historyList].map((submissionId, key) => ({
            id: submissionId || key,
            value: submissionId,
          }));
          break;
        case "4":
          const { invoiceList: list = [] } = response.data || {};
          data = [...list].map((invoice, key) => ({
            id: invoice.invoiceId || key,
            value: invoice.urn,
          }));
          break;
        case "5":
          const listExtHelpdeskTickets = response.data || {};
          data = [...listExtHelpdeskTickets].map((ticket, key) => ({
            id: ticket.number || key,
            value: ticket ? ticket.number : "",
          }));
          break;
        default:
          return;
      }
    }
    yield put(fetchSearchRecords(data));
    yield put(searchRecordsFetchingComplete());
  } catch (error) {
    yield put(searchRecordsFetchingComplete());
  }
}
