import { fromJS } from "immutable";
import {
  CLEAR_FORM_VALUES,
  SET_ISPO,
  SET_FORM_VALUES,
  FETCHING_STANDARD_FIELDS,
  FETCH_STANDARD_FIELDS,
  STANDARD_FIELDS_FETCH_COMPLETE,
  FETCHING_COUNTRY_LIST,
  FETCH_COUNTRY_LIST,
  COUNTRY_LIST_FETCH_COMPLETE,
  FETCHING_PROFILE_LIST,
  FETCH_PROFILE_LIST,
  PROFILE_LIST_FETCH_COMPLETE,
  FETCHING_SUPPLIER_LIST,
  FETCH_SUPPLIER_LIST,
  SUPPLIER_LIST_FETCH_COMPLETE,
  FETCHING_PO_NUMBER,
  FETCH_PO_NUMBER,
  PO_NUMBER_FETCH_COMPLETE,
  INIT_FETCH_ADDITIONAL_FIELDS,
  FECTHING_ADDITIONAL_FIELDS,
  FETCH_ADDITIONAL_FIELDS,
  ADDITIONAL_FIELDS_FETCH_COMPLETE,
  CLEAR_ADDITIONAL_FIELDS,
  FETCHING_DOCUMENT_TYPES,
  FETCH_DOCUMENT_TYPES,
  DOCUMENT_TYPES_FETCH_COMPLETE,
  SET_VISITED,
  RESET_VISITED,
  SET_CLIENT_REF_VALUES,
  RESET_COMMON_DS_VALUES,
  SET_SUBMISSION_ID,
  SET_IS_OCR_EXTRACTED,
  SET_PO_FILTERS,
} from "./actionTypes";

export const defaultPOFilters = {
  IsAll: true,
  IsOpen: false,
  IsArchival: false,
};
export const initialState = fromJS({
  formValues: {},
  isPO: false,
  standardFields: [],
  fetchingStandardFields: false,
  fetchingCountry: false,
  countryDropdownList: [],
  fetchingProfile: false,
  profileDropdownList: [],
  fetchingSupplier: false,
  supplierDropdownList: [],
  fetchingAdditionalFields: false,
  fetchingPONumber: false,
  poNumberList: [],
  additionalFields: [],
  fetchingSubmissionProfiles: true,
  submissionProfiles: [],
  fetchingDocumentTypes: false,
  documentTypes: [],
  visited: {},
  resetVisited: false,
  clientRefValues: [],
  submissionID: 0,
  isOCRExtracted: false,
  poListFilter: defaultPOFilters,
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_VALUES:
      return state.set("formValues", fromJS(action.data));
    case CLEAR_FORM_VALUES:
      return state.set("formValues", fromJS({}));
    case SET_ISPO:
      return state.set("isPO", action.data);
    case FETCHING_STANDARD_FIELDS: {
      return state
        .set("fetchingStandardFields", true)
        .set("standardFields", fromJS([]));
    }
    case FETCH_STANDARD_FIELDS: {
      return state.set("standardFields", fromJS(action.data));
    }
    case STANDARD_FIELDS_FETCH_COMPLETE: {
      return state.set("fetchingStandardFields", false);
    }
    case FETCHING_COUNTRY_LIST: {
      return state.set("fetchingCountry", true);
    }
    case FETCH_COUNTRY_LIST: {
      return state.set("countryDropdownList", fromJS(action.data));
    }
    case COUNTRY_LIST_FETCH_COMPLETE: {
      return state.set("fetchingCountry", false);
    }
    case FETCHING_PROFILE_LIST: {
      return state.set("fetchingProfile", true);
    }
    case FETCH_PROFILE_LIST: {
      return state.set("profileDropdownList", fromJS(action.data));
    }
    case PROFILE_LIST_FETCH_COMPLETE: {
      return state.set("fetchingProfile", false);
    }
    case FETCHING_SUPPLIER_LIST: {
      return state.set("fetchingSupplier", true);
    }
    case FETCH_SUPPLIER_LIST: {
      return state.set("supplierDropdownList", fromJS(action.data));
    }
    case SUPPLIER_LIST_FETCH_COMPLETE: {
      return state.set("fetchingSupplier", false);
    }
    case FETCHING_PO_NUMBER: {
      return state.set("fetchingPONumber", true);
    }
    case FETCH_PO_NUMBER: {
      return state.set("poNumberList", fromJS(action.data));
    }
    case PO_NUMBER_FETCH_COMPLETE: {
      return state.set("fetchingPONumber", false);
    }
    case INIT_FETCH_ADDITIONAL_FIELDS: {
      return state.set("additionalFields", fromJS([]));
    }
    case FECTHING_ADDITIONAL_FIELDS: {
      return state.set("fetchingAdditionalFields", true);
    }
    case FETCH_ADDITIONAL_FIELDS: {
      return state.set("additionalFields", fromJS(action.data));
    }
    case ADDITIONAL_FIELDS_FETCH_COMPLETE: {
      return state.set("fetchingAdditionalFields", false);
    }
    case CLEAR_ADDITIONAL_FIELDS: {
      return state.set("additionalFields", fromJS([]));
    }
    case FETCHING_DOCUMENT_TYPES:
      return state.set("fetchingDocumentTypes", true);
    case FETCH_DOCUMENT_TYPES:
      return state.set("documentTypes", fromJS(action.data));
    case DOCUMENT_TYPES_FETCH_COMPLETE: {
      return state.set("fetchingDocumentTypes", false);
    }
    case SET_VISITED: {
      return state.set("visited", fromJS(action.data));
    }
    case RESET_VISITED: {
      return state.set("resetVisited", action.data);
    }
    case SET_CLIENT_REF_VALUES: {
      return state.set("clientRefValues", fromJS(action.data));
    }
    case SET_SUBMISSION_ID: {
      return state.set("submissionID", action.data);
    }
    case SET_IS_OCR_EXTRACTED: {
      return state.set("isOCRExtracted", action.data);
    }
    case RESET_COMMON_DS_VALUES: {
      return state
        .set("clientRefValues", fromJS([]))
        .set("submissionID", 0)
        .set("formValues", fromJS({}))
        .set("isPO", false)
        .set("visited", fromJS({}))
        .set("resetVisited", false)
        .set("poListFilter", fromJS(defaultPOFilters));
    }
    case SET_PO_FILTERS: {
      return state.set("poListFilter", fromJS(action.data));
    }
    default:
      return state;
  }
};

export default reducer;
