import { put, takeLatest, call, all, select } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";

import {
  advancePaymentsFetchComplete,
  advancePaymentsFetching,
  fetchAdvancePayments,
  fetchAdvPaymentActiveCols,
  initAdvancePaymentsFetch,
} from "./actions";

import {
  INIT_ADVANCE_PAYMENTS_FETCH,
  INIT_ADV_PAYMENT_ACTIVE_COLS,
  INIT_APPLY_FILTER,
  INIT_FETCH_LINKED_INVOICES,
} from "./actionTypes";
import {
  selectAdvancePaymentsList,
  selectAdvPaymentsActiveCols,
  selectFilterApplied,
  selectFilters,
  selectGridConfigs,
  selectLastSavedLookup,
} from "./selectors";
import { selectClientDetails } from "container/Administration/ClientManagement/store/selectors";
import { maxPageSize } from "config/index";
import { selectSelectedCurrencyObj } from "store/selectors";
import {
  generateColumns,
  staticCols,
} from "container/InvoiceStatus/config/index";
import i18next from "i18next";
export default function* watchAdvancePaymentSaga() {
  yield all([
    takeLatest(INIT_ADVANCE_PAYMENTS_FETCH, initAdvancePaymentsFetchSaga),
    takeLatest(INIT_ADV_PAYMENT_ACTIVE_COLS, getAdvancePaymentActiveColsSaga),
    takeLatest(INIT_APPLY_FILTER, initApplyFilterSaga),
    takeLatest(INIT_FETCH_LINKED_INVOICES, initTaggedInvoiceSaga),
  ]);
}

function* initAdvancePaymentsFetchSaga(action) {
  try {
    let sessionDetails =
      JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
    let langID =
      sessionDetails &&
      sessionDetails.languageId &&
      sessionDetails.languageId !== ""
        ? sessionDetails.languageId
        : "enGB";
    let clear = false;
    if (action.payload) clear = yield action.payload.clear;
    const { client } = yield select(selectClientDetails());
    const { id: currencyID } = yield select(selectSelectedCurrencyObj());
    const { isPageIndexRequired } = yield client;

    const gridConfigs = yield select(selectGridConfigs());
    const filterApplied = yield select(selectFilterApplied());
    const filters = yield select(selectFilters());

    const {
      isArchival = false,
      paymentNumber = null,
      statusID = null,
    } = clear || !filterApplied ? {} : yield filters.toJS();
    let supplierIds = null,
      isAllSuppliersSelected = false,
      deselectedSupplierList = null;

    if (!clear && filterApplied) {
      const suppliersMap = yield select(selectLastSavedLookup());
      const suppliers = yield suppliersMap.toJS();

      if (suppliers.selectedItem.length)
        supplierIds = suppliers.selectedItem
          .map((item) => item.supplierID)
          .toString();
      isAllSuppliersSelected = suppliers.isAllSuppliersSelected;
      if (suppliers.deselectedSuppliers.length)
        deselectedSupplierList = suppliers.deselectedSuppliers.toString();
    }
    const {
      dateFrom,
      dateTo,
      pageIndex = 1,
      pageSize = isPageIndexRequired ? Number(maxPageSize) : undefined,
    } = yield gridConfigs.toJS();

    const payload = {
      currencyID,
      languageID: langID,
      advPaymentStartDate: dateFrom,
      advPaymentToDate: dateTo,
      pageIndex,
      pageSize,
      isArchival,
      paymentNumber,
      statusID,
      supplierIds,
      isAllSuppliersSelected,
      deselectedSupplierList,
    };

    yield put(advancePaymentsFetching());
    let url = `/Search/ListadvancePayments`;

    const requestDetails = { ...postRequestDetails, data: payload };

    const response = yield call(axios, url, requestDetails);

    if (response && response.status === 200) {
      const { data } = yield response;
      const cols = yield data.fieldLabels
        .sort((a, b) => a.GridOrder - b.GridOrder)
        .map((field) => {
          return {
            selector: field.FieldName,
            name: field.Label,
            width: field.ColumnWidth,
          };
        });
      yield put(
        fetchAdvancePayments({
          cols,
          rows: data.advPaymentsList,
          totalCount: data.totalCount,
        })
      );
    }

    yield put(advancePaymentsFetchComplete());
  } catch (error) {
    console.log(error);
    yield put(advancePaymentsFetchComplete());
  }
}

function* getAdvancePaymentActiveColsSaga() {
  try {
    const sessionDetails =
      JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
    const { menus = [] } = sessionDetails;
    const module = menus.find(
      (item) => item.pageElementFileName === "advancePayments"
    );

    const { moduleID, pageElementId } = module;
    const existingActiveColumns = yield select(selectAdvPaymentsActiveCols());
    if (existingActiveColumns.size) return;
    let activeColumnsURL = `Search/ActivateColumns?PageElementID=${pageElementId}&ModuleID=${moduleID}`;
    const activeColReq = { ...getRequestDetails };
    const activeColresponse = yield call(axios, activeColumnsURL, activeColReq);
    if (activeColresponse && activeColresponse.status === 200) {
      const { data } = yield activeColresponse;
      yield put(fetchAdvPaymentActiveCols(data));
    }
  } catch (error) {
    yield put(fetchAdvPaymentActiveCols([]));
  }
}

function* initApplyFilterSaga(action) {
  try {
    const sessionDetails =
      JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
    const { menus = [] } = sessionDetails;
    const module = menus.find(
      (item) => item.pageElementFileName === "advancePayments"
    );

    const { moduleID, pageElementId: pageElementID } = module;
    const url = yield `/Search/SaveColumns`;
    const activeCols = yield select(selectAdvPaymentsActiveCols());
    const requestDetails = yield { ...postRequestDetails };
    const fieldConfigurationID = yield activeCols
      .toJS()
      .filter((item) => item.isSelected)
      .map((item) => item.fieldConfigurationID);
    requestDetails.data = yield {
      fieldConfigurationID,
      moduleID,
      pageElementID,
    };
    let response = yield call(axios, url, requestDetails);
    if (response && response.status === 200)
      yield put(initAdvancePaymentsFetch());
    return response;
  } catch (error) {
    console.log(error);
  }
}

function* initTaggedInvoiceSaga(action) {
  try {
    yield put(advancePaymentsFetching());
    let sessionDetails =
      JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
    let langID =
      sessionDetails &&
      sessionDetails.languageId &&
      sessionDetails.languageId !== ""
        ? sessionDetails.languageId
        : "enGB";
    const url = yield `Search/listInvoiceULID`;

    const { InvoiceULID, AdvancedPaymentsid } = action.data;
    const requestDetails = yield { ...postRequestDetails };
    const filterApplied = yield select(selectFilterApplied());
    let advPayments = yield select(selectAdvancePaymentsList());
    advPayments = advPayments.toJS();
    let { cols, rows, totalCount } = advPayments;
    let newRows = rows.slice();
    let index = rows.findIndex(
      (d) => d.AdvancedPaymentsid === AdvancedPaymentsid
    );
    const expanded = newRows[index].expanded;
    newRows[index].expanded = !expanded;
    let newAdvPayments = {
      cols,
      rows: newRows,
      totalCount,
    };
    let filters = yield select(selectFilters());
    filters = filters.toJS();

    requestDetails.data = yield {
      InvoiceULID,
      languageID: langID,
      isArchival: filterApplied ? filters.isArchival : false,
    };
    if (!expanded) {
      let response = yield call(axios, url, requestDetails);
      if (response && response.status === 200) {
        const { data } = response;
        const { fieldLabels, invoiceList } = data;
        const { client } = yield select(selectClientDetails());
        const { isPaymentStatusActive = true } = client;
        let invoiceCols = yield generateColumns(fieldLabels);

        invoiceCols.forEach((col) => {
          col.omit =
            col.selector && col.selector.toLowerCase() === "hasremittance"
              ? true
              : col.omit;
        });
        const updatedStaticCols = [...staticCols].map((col) => ({
          ...col,
          omit:
            col.selector === "paymentStatus" ? !isPaymentStatusActive : false,
          name: i18next.t(col.name),
        }));
        invoiceCols = [...updatedStaticCols, ...invoiceCols];
        const linkedDetails = {
          cols: invoiceCols,
          data: invoiceList,
        };

        newRows[index].details = linkedDetails;

        newAdvPayments = {
          cols,
          rows: newRows,
          totalCount,
        };
      }
    }

    yield put(fetchAdvancePayments(newAdvPayments));

    yield put(advancePaymentsFetchComplete());
  } catch (error) {
    console.log(error);
    yield put(advancePaymentsFetchComplete());
  }
}
