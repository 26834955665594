import styled from "styled-components";

export const Title = styled.p``;
export const TtlInvoice = styled.p``;
export const Amount = styled.p``;
export const IconGroup = styled.span``;

const PaymentQuickUpdateStyled = styled.section``;

export default PaymentQuickUpdateStyled;
