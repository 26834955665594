import { createSelector } from "reselect";
import { initialState } from "./reducers";

const selectRubberband = (state) => state.get("rubberband", initialState);

export const selectDataCapture = () =>
  createSelector(selectRubberband, (VPState) => VPState.get("dataCapture"));

export const selectInput = () =>
  createSelector(selectRubberband, (VPState) => VPState.get("input"));

export const selectHeaderOrLine = () =>
  createSelector(selectRubberband, (VPState) => VPState.get("headerOrLine"));

export const selectInputValue = () =>
  createSelector(selectRubberband, (VPState) => VPState.get("inputValue"));
