import React from "react";
import SvgIcon from "./style";

const Clock = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-861.000000, -188.000000)" fill="#919EAB">
          <g transform="translate(821.000000, 59.000000)">
            <g transform="translate(40.000000, 129.000000)">
              <path d="M6,0.187499985 C2.78906249,0.187499985 0.187499985,2.78906249 0.187499985,6 C0.187499985,9.21093751 2.78906249,11.8125 6,11.8125 C9.21093751,11.8125 11.8125,9.21093751 11.8125,6 C11.8125,2.78906249 9.21093751,0.187499985 6,0.187499985 Z M6,10.6875 C3.41015624,10.6875 1.31249999,8.58984376 1.31249999,6 C1.31249999,3.41015624 3.41015624,1.31249999 6,1.31249999 C8.58984376,1.31249999 10.6875,3.41015624 10.6875,6 C10.6875,8.58984376 8.58984376,10.6875 6,10.6875 Z M7.4484375,8.24062501 L5.45859375,6.79453125 C5.3859375,6.740625 5.34375,6.65625 5.34375,6.5671875 L5.34375,2.71874999 C5.34375,2.56406249 5.4703125,2.43749999 5.625,2.43749999 L6.375,2.43749999 C6.5296875,2.43749999 6.65625,2.56406249 6.65625,2.71874999 L6.65625,6.03984375 L8.22187501,7.17890625 C8.34843751,7.2703125 8.37421876,7.44609375 8.28281251,7.57265625 L7.8421875,8.17968751 C7.75078125,8.30390626 7.575,8.33203126 7.4484375,8.24062501 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Clock;
