import React from "react";
import StyledRadio from "./style";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
const Radio = (props) => {
  const { t } = useTranslation();
  let id = new Date().getTime();
  if (props.id) id = props.id;
  return (
    <>
      <StyledRadio
        aria-label="radioBtn"
        {...props}
        id={id}
        onChange={props.handleCheck}
      />
      <label title={props.title} htmlFor={id}>
        {t(props.label)}
      </label>
    </>
  );
};
Radio.propTypes = {
  id: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
};
export default Radio;
