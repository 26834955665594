import { fromJS } from "immutable";

import {
    FETCH_GET_SEARCH_FIELDS_LIST,FETCHING_SEARCH_FIELDS_LIST,GET_SEARCH_FIELDS_LIST_FETCH_COMPLETE, SEARCH_CONFIG_SAVING_FAILED, SEARCH_CONFIG_SAVED, SEARCH_CONFIG_SAVING
} from "./actionTypes";

export const initialState = fromJS({
    fetchingSearchFieldsList: false,
    searchFieldsList: [],
    isSearchConfigSaving : false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_SEARCH_FIELDS_LIST: {
        return state.set("fetchingSearchFieldsList", true);
      }
      case FETCH_GET_SEARCH_FIELDS_LIST: {
        return state.set("searchFieldsList", action.data);
      }
      case GET_SEARCH_FIELDS_LIST_FETCH_COMPLETE: {
        return state.set("fetchingSearchFieldsList", false);
      }
      case SEARCH_CONFIG_SAVING: {
        return state.set("isSearchConfigSaving", true);
      }
      case SEARCH_CONFIG_SAVED: {
        return state.set("isSearchConfigSaving", false); 
      }
      case SEARCH_CONFIG_SAVING_FAILED: {
        return state.set("isSearchConfigSaving", false);
      }
    default:
      return state;
  }
};

export default reducer;
