//Get categories from custom id

export const INIT_FETCH_CATEGORY = "INIT_FETCH_CATEGORY";
export const FETCHING_CATEGORY = "FETCHING_CATEGORY";
export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const CATEGORY_FETCH_COMPLETE = "CATEGORY_FETCH_COMPLETE";

//---------------------------------------------------------------------------------------------------------------//
//FAQ
//List FAQ
export const INIT_FETCH_FAQ_LIST = "INIT_FETCH_FAQ_LIST";
export const FETCHING_FAQ_LIST = "FETCHING_FAQ_LIST";
export const FETCH_FAQ_LIST = "FETCH_FAQ_LIST";
export const FAQ_LIST_FETCH_COMPLETE = "FAQ_LIST_FETCH_COMPLETE";

//FAQ Questions by id
export const INIT_FETCH_FAQ_BY_ID = "INIT_FETCH_FAQ_BY_ID";
export const FETCHING_FAQ_BY_ID = "FETCHING_FAQ_BY_ID";
export const FETCH_FAQ_BY_ID = "FETCH_FAQ_BY_ID";
export const FAQ_BY_ID_FETCH_COMPLETE = "FAQ_BY_ID_FETCH_COMPLETE";

//Save FAQ Question
export const INIT_SAVE_FAQ = "INIT_SAVE_FAQ";
export const FAQ_SAVED = "FAQ_SAVED";
export const FAQ_SAVE_FAILED = "FAQ_SAVE_FAILED";

//Remove FAQ Question
export const INIT_DELETE_FAQ = "INIT_DELETE_FAQ";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAILED = "DELETE_FAQ_FAILED";

//-------------------------------------------------------------------------------------------------------//
//TrainingMAterials
//List Training Material
export const INIT_FETCH_TRAINING_MATERIAL_LIST =
  "INIT_FETCH_TRAINING_MATERIAL_LIST";
export const FETCHING_TRAINING_MATERIAL_LIST =
  "FETCHING_TRAINING_MATERIAL_LIST";
export const FETCH_TRAINING_MATERIAL_LIST = "FETCH_TRAINING_MATERIAL_LIST";
export const TRAINING_MATERIAL_LIST_FETCH_COMPLETE =
  "TRAINING_MATERIAL_LIST_FETCH_COMPLETE";

//Training Material by id
export const INIT_FETCH_TRAINING_MATERIAL_BY_ID =
  "INIT_FETCH_TRAINING_MATERIAL_BY_ID";
export const FETCHING_TRAINING_MATERIAL_BY_ID =
  "FETCHING_TRAINING_MATERIAL_BY_ID";
export const FETCH_TRAINING_MATERIAL_BY_ID = "FETCH_TRAINING_MATERIAL_BY_ID";
export const TRAINING_MATERIAL_BY_ID_FETCH_COMPLETE =
  "TRAINING_MATERIAL_BY_ID_FETCH_COMPLETE";

//Save Traning Material
export const INIT_SAVE_TRAINING_MATERIAL = "INIT_SAVE_TRAINING_MATERIAL";
export const TRAINING_MATERIAL_SAVED = "TRAINING_MATERIAL_SAVED";
export const TRAINING_MATERIAL_SAVE_FAILED = "TRAINING_MATERIAL_SAVE_FAILED";

//Remove Training Material
export const INIT_DELETE_TRAINING_MATERIAL = "INIT_DELETE_TRAINING_MATERIAL";
export const DELETE_TRAINING_MATERIAL_SUCCESS =
  "DELETE_TRAINING_MATERIAL_SUCCESS";
export const DELETE_TRAINING_MATERIAL_FAILED =
  "DELETE_TRAINING_MATERIAL_FAILED";

//-------------------------------------------------------------------------------------------------------//
//Training Videos
//List Training Video
export const INIT_FETCH_TRAINING_VIDEO_LIST = "INIT_FETCH_TRAINING_VIDEO_LIST";
export const FETCHING_TRAINING_VIDEO_LIST = "FETCHING_TRAINING_VIDEO_LIST";
export const FETCH_TRAINING_VIDEO_LIST = "FETCH_TRAINING_VIDEO_LIST";
export const TRAINING_VIDEO_LIST_FETCH_COMPLETE =
  "TRAINING_VIDEO_LIST_FETCH_COMPLETE";

//Training Video by id
export const INIT_FETCH_TRAINING_VIDEO_BY_ID =
  "INIT_FETCH_TRAINING_VIDEO_BY_ID";
export const FETCHING_TRAINING_VIDEO_BY_ID = "FETCHING_TRAINING_VIDEO_BY_ID";
export const FETCH_TRAINING_VIDEO_BY_ID = "FETCH_TRAINING_VIDEO_BY_ID";
export const TRAINING_VIDEO_BY_ID_FETCH_COMPLETE =
  "TRAINING_VIDEO_BY_ID_FETCH_COMPLETE";

//Save Traning Video
export const INIT_SAVE_TRAINING_VIDEO = "INIT_SAVE_TRAINING_VIDEO";
export const TRAINING_VIDEO_SAVED = "TRAINING_VIDEO_SAVED";
export const TRAINING_VIDEO_SAVE_FAILED = "TRAINING_VIDEO_SAVE_FAILED";

//Remove Training Video
export const INIT_DELETE_TRAINING_VIDEO = "INIT_DELETE_TRAINING_VIDEO";
export const DELETE_TRAINING_VIDEO_SUCCESS = "DELETE_TRAINING_VIDEO_SUCCESS";
export const DELETE_TRAINING_VIDEO_FAILED = "DELETE_TRAINING_VIDEO_FAILED";
