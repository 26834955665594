import {
  INIT_GET_USER_ADDRESS_LIST,
  FETCH_USER_ADDRESS_LIST,
  FETCH_USER_ADDRESS_LIST_END,
  INIT_GET_USER_ADDRESS_BY_ADDRESS_ID,
  FETCH_USER_ADDRESS_BY_ID_DATA,
  FETCH_USER_ADDRESS_BY_ID_END,
  INIT_DELETE_USER_ADDRESS,
  DELETE_USER_ADDRESS_END,
  INIT_SAVE_USER_ADDRESS,
  SAVE_USER_ADDRESS_END,
  INIT_UPDATE_PASSWORD,
  UPDATING_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  INIT_UPDATE_SECURITY_QUESTION,
  UPDATING_SECURITY_QUESTION,
  UPDATE_SECURITY_QUESTION_SUCCESS,
  UPDATE_SECURITY_QUESTION_FAILED,
  SAVE_USER_COMPANY_DETAIL_DATA,
  SAVED_USER_COMPANY_DETAIL_DATA,
  INIT_UPDATE_USER_LANGUAGE,
  UPDATING_USER_LANGUAGE,
  UPDATED_USER_LANGUAGE,
  INIT_CURRENCY_MAPPING_FETCH,
  CURRENCY_MAPPING_FETCHING,
  CURRENCY_MAPPING_FETCH,
  CURRENCY_MAPPING_FETCH_COMPLETE,
  INIT_PROFILE_MFA_OTP,
  PROFILE_MFA_OTP_SENT,
  PROFILE_MFA_OTP_FAILED,
} from "./actionTypes";

export const initGetUserAddresslist = (id) => {
  return {
    type: INIT_GET_USER_ADDRESS_LIST,
    id,
  };
};

export const fetchUserAddressList = (data) => {
  return {
    type: FETCH_USER_ADDRESS_LIST,
    data,
  };
};

export function initUpdatePassword(payload, callback) {
  return {
    type: INIT_UPDATE_PASSWORD,
    payload,
    callback,
  };
}
export function updatingPassword() {
  return {
    type: UPDATING_PASSWORD,
  };
}
export function updatePasswordSuccess() {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
  };
}
export function updatePasswordFailed() {
  return {
    type: UPDATE_PASSWORD_FAILED,
  };
}
export function initUpdateSecurityQuestion(payload, callback) {
  return {
    type: INIT_UPDATE_SECURITY_QUESTION,
    payload,
    callback,
  };
}
export function updatingSecurityQuestion() {
  return {
    type: UPDATING_SECURITY_QUESTION,
  };
}
export function updateSecurityQuestionSuccess() {
  return {
    type: UPDATE_SECURITY_QUESTION_SUCCESS,
  };
}
export function updateSecurityQuestionFailed() {
  return {
    type: UPDATE_SECURITY_QUESTION_FAILED,
  };
}
export const fetchUserAddressListEnd = () => {
  return {
    type: FETCH_USER_ADDRESS_LIST_END,
  };
};

export const initGetUserAddressByAddressId = (data, callback) => {
  return {
    type: INIT_GET_USER_ADDRESS_BY_ADDRESS_ID,
    data,
    callback,
  };
};

export const fetchUserAddressByIdData = (data) => {
  return {
    type: FETCH_USER_ADDRESS_BY_ID_DATA,
    data,
  };
};

export const fetchUserAddressByIdEnd = () => {
  return {
    type: FETCH_USER_ADDRESS_BY_ID_END,
  };
};

export const initDeleteUserAddress = (data, callback) => {
  return {
    type: INIT_DELETE_USER_ADDRESS,
    data,
    callback,
  };
};

export const deleteUserAddressEnd = () => {
  return {
    type: DELETE_USER_ADDRESS_END,
  };
};

export const initSaveUserAddress = (data, callback) => {
  return {
    type: INIT_SAVE_USER_ADDRESS,
    data,
    callback,
  };
};

export const saveUserAddressEnd = () => {
  return {
    type: SAVE_USER_ADDRESS_END,
  };
};

export const initSubmitUserCompanyDetailData = (data, callback) => {
  return {
    type: SAVE_USER_COMPANY_DETAIL_DATA,
    data,
    callback,
  };
};

export const saveduserCompanyDetailData = () => {
  return {
    type: SAVED_USER_COMPANY_DETAIL_DATA,
  };
};

export const initUpdateUserLanguage = (payload, callback) => {
  return {
    type: INIT_UPDATE_USER_LANGUAGE,
    payload,
    callback,
  };
};

export const updatingUserLanguage = () => {
  return { type: UPDATING_USER_LANGUAGE };
};

export const updatedUserLanguage = () => {
  return { type: UPDATED_USER_LANGUAGE };
};

export const initCurrencyMappingFetch = (countryID) => {
  return {
    type: INIT_CURRENCY_MAPPING_FETCH,
    countryID,
  };
};

export const currencyMappingFetching = () => {
  return {
    type: CURRENCY_MAPPING_FETCHING,
  };
};

export const currencyMappingFetch = (data) => {
  return {
    type: CURRENCY_MAPPING_FETCH,
    data: data,
  };
};

export const currencyMappingFetchCompleted = () => {
  return {
    type: CURRENCY_MAPPING_FETCH_COMPLETE,
  };
};

export const initProfileMFAOTP = (payload) => ({
  type: INIT_PROFILE_MFA_OTP,
  payload,
});
export const passwordChangeOTPFailed = () => ({ type: PROFILE_MFA_OTP_FAILED });
export const passwordChangeOTPSuccess = () => ({
  type: PROFILE_MFA_OTP_SENT,
});
