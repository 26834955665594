import React from "react";

import { centered } from "globalStyles";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
const CenteredWrapper = styled.div`
  ${centered}
  padding: 2em;
  background-color: hsl(0, 0%, 100%);
  box-shadow: 0 0.125rem 2rem 0 rgb(0 0 0 / 0.55);
  z-index: 3;
  border-radius: 1em;
  color: var(--grey-clr);
  font-size: var(--fs-20);
  font-family: var(--ff-main);
  font-weight: var(--fw-normal);
`;

const ResolveRejectionLoading = (props) => {
  const { t } = useTranslation();
  return (
    <CenteredWrapper>
      <p>{t("msp.waitMessageForSetupPage")}</p>
    </CenteredWrapper>
  );
};

export default ResolveRejectionLoading;
