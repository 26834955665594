import {
  INIT_STATISTICS_FETCH,
  FETCHING_STATISTICS,
  FETCH_STATISTICS,
  STATISTICS_FETCH_COMPLETE,
} from "./actionTypes";

export const initStatisticsFetch = (payload) => {
  return {
    type: INIT_STATISTICS_FETCH,
    payload,
  };
};

export const statisticsFetching = () => {
  return {
    type: FETCHING_STATISTICS,
  };
};
export const fetchStatistics = (data) => {
  return {
    type: FETCH_STATISTICS,
    data: data,
  };
};
export const statisticsFetchComplete = () => {
  return {
    type: STATISTICS_FETCH_COMPLETE,
  };
};
