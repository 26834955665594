import Label from "components/Label/index";
import styled from "styled-components";

export const SelectLabel = styled(Label)`
  max-width: calc(100% - (1rem * 2));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  left: 0.75rem;
  pointer-events: none;
  position: absolute;
  color: ${(props) =>
    props.isFocused ? "var(--primary-clr)" : "var(--grey-clr)"};
  z-index: 1;
  opacity: 1;
  top: 0.25rem;
  padding-top: 0.3rem;
  opacity: ${(props) => (props.isFloating ? `1` : `0`)};
  font-size: var(--fs-12);
`;

export const customStyles = {
  container: (base, state) => ({
    ...base,
    position: "relative",
    boxSizing: "border-box",
    width: "100%",
  }),
  placeholder: (base, state) => ({
    ...base,
    maxWidth: "calc(100% - (0.65rem))",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display:
      state.isFocused || state.isSelected || state.hasValue ? "none" : "block",
  }),
  valueContainer: (base, state) => {
    return {
      ...base,
      marginTop: state.selectProps.menuIsOpen || state.hasValue ? "1em" : "",
      padding: "0.2rem 0.5rem",
    };
  },
  control: (base, state) => {
    const { showError, isDisabled, visited, value, required } =
      state.selectProps;
    return {
      ...base,
      borderStyle: "solid",
      borderWidth: `var(--oneDotFivePx)`,
      background: isDisabled ? "var(--disabled-clr)" : "",
      height: "3.5em",
      borderRadius: ".5em",
      borderColor: state.isFocused
        ? `var(--primary-clr)`
        : showError || (visited && !value && required)
        ? `var(--error-clr)`
        : `var(--input-border-clr)`,
      boxShadow: state.isFocused
        ? "0 0 0.8rem 0 rgba(102, 187, 255, 0.5);"
        : "none",
    };
  },
  singleValue: (base, state) => ({
    ...base,
    color: state.selectProps.isDisabled
      ? "rgb(181,192,202)"
      : "rgb(21, 24, 27)",
  }),

  input: (base) => ({
    ...base,
  }),
  indicatorSeparator: () => ({
    display: "none",
    backgroundColor: "transparent",
  }),

  menu: (base) => ({
    ...base,
    padding: "1em",
    left: 0,
    zIndex: 2,
  }),
  menuList: (base,state) => {
    const { customStyle } = state.selectProps;
    const { menuList } = customStyle || {};
    return {
      ...base,
      maxHeight: "20rem",
      ...menuList,
    };
  },

  option: (base, state) => ({
    ...base,
    borderRadius: "0.25em",
    backgroundColor: state.isSelected
      ? "#e7eaee"
      : state.isFocused
      ? "var(--dropdown-hover-clr)"
      : `var(--light-clr)`,
    color: "var(--dark-clr)",
  }),
};

export const SelectPlaceholder = styled(Label)`
  text-transform: capitalize;
  padding: 0;
  margin: 0;
  font-family: var(--ff-main);
`;
