import {
  INIT_GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_FETCH_COMPLETED,
  FETCH_GET_CLIENT_DETAILS,
  FETCHING_GET_CLIENT_DETAILS,
  INIT_GET_CLIENTS_LIST,
  FETCHING_CLIENTS_LIST,
  FETCH_CLIENTS_LIST,
  CLIENTS_LIST_FETCHING_COMPLETE,
  INIT_GET_CONTACT_LIST,
  FETCHING_CONTACT_LIST,
  FETCH_CONTACT_LIST,
  CONTACT_LIST_FETCHING_COMPLETE,
  INIT_SAVE_CONTACT,
  CONTACT_SAVE_SUCCESS,
  CONTACT_SAVE_FAILED,
  INIT_GET_CLIENT_DATA_BY_ID,
  FETCHING_CLIENT_DATA_BY_ID,
  CLIENT_DATA_BY_ID_FETCH_COMPLETE,
  INIT_SAVE_CLIENT_DATA,
  CLIENT_DATA_SAVE_SUCCESS,
  CLIENT_DATA_SAVE_FAILED,
  INIT_GET_CONTACT_EMAIL_LIST,
  FETCHING_CONTACT_EMAIL_LIST,
  FETCH_CONTACT_EMAIL_LIST,
  CONTACT_EMAIL_LIST_FETCHING_COMPLETE,
  INIT_GET_COUNTRY_CONTACT_LIST,
  FETCHING_COUNTRY_CONTACT_LIST,
  FETCH_COUNTRY_CONTACT_LIST,
  COUNTRY_CONTACT_LIST_FETCHING_COMPLETE,
  INIT_SAVE_CLIENT_HELPDESK_DATA,
  CLIENT_HELPDESK_DATA_SAVE_SUCCESS,
  CLIENT_HELPDESK_DATA_SAVE_FAILED,
  INIT_DELETE_CONTACT,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DELETE_FAILED,
} from "./actionTypes";

// get client details
export const initGetClientDetails = () => {
  return {
    type: INIT_GET_CLIENT_DETAILS,
  };
};
export const fetchingGetClientDetails = () => {
  return {
    type: FETCHING_GET_CLIENT_DETAILS,
  };
};
export const fetchGetClientDetails = (data) => {
  return {
    type: FETCH_GET_CLIENT_DETAILS,
    data: data,
  };
};
export const fetchGetClientDetailsCompleted = () => {
  return {
    type: GET_CLIENT_DETAILS_FETCH_COMPLETED,
  };
};

export function initGetClientsList() {
  return {
    type: INIT_GET_CLIENTS_LIST,
  };
}

export function fetchingClientsList() {
  return {
    type: FETCHING_CLIENTS_LIST,
  };
}

export function fetchClientsList(data) {
  return {
    type: FETCH_CLIENTS_LIST,
    data,
  };
}

export function clientsListFetchingComplete() {
  return {
    type: CLIENTS_LIST_FETCHING_COMPLETE,
  };
}

export function initGetContactList(data) {
  return {
    type: INIT_GET_CONTACT_LIST,
    data,
  };
}

export function fetchingContactList() {
  return {
    type: FETCHING_CONTACT_LIST,
  };
}

export function fetchContactList(data) {
  return {
    type: FETCH_CONTACT_LIST,
    data,
  };
}

export function contactListFetchingComplete() {
  return {
    type: CONTACT_LIST_FETCHING_COMPLETE,
  };
}

export function initSaveContact(data, callback) {
  return {
    type: INIT_SAVE_CONTACT,
    data,
    callback,
  };
}

export function contactSaveSuccess() {
  return {
    type: CONTACT_SAVE_SUCCESS,
  };
}

export function contactSaveFailed() {
  return {
    type: CONTACT_SAVE_FAILED,
  };
}

export function initDeleteContactData(payload, callback) {
  return {
    type: INIT_DELETE_CONTACT,
    payload,
    callback,
  };
}

export function contactDeleteSuccess() {
  return {
    type: CONTACT_DELETE_SUCCESS,
  };
}

export function contactDeleteFailed() {
  return {
    type: CONTACT_DELETE_FAILED,
  };
}

export const initGetClientDataById = (clientId, callback) => {
  return {
    type: INIT_GET_CLIENT_DATA_BY_ID,
    clientId,
    callback,
  };
};

export const fetchingClientDataById = () => {
  return {
    type: FETCHING_CLIENT_DATA_BY_ID,
  };
};

export const clientDatabyIdFetchingComplete = () => {
  return {
    type: CLIENT_DATA_BY_ID_FETCH_COMPLETE,
  };
};

export const initSaveClientData = (formData, callback) => {
  return {
    type: INIT_SAVE_CLIENT_DATA,
    formData,
    callback,
  };
};

export const clientDataSaveSuccess = () => {
  return {
    type: CLIENT_DATA_SAVE_SUCCESS,
  };
};

export const clientDataSaveFailed = () => {
  return {
    type: CLIENT_DATA_SAVE_FAILED,
  };
};

export const initSaveClientHelpdeskData = (payload, callback) => {
  return {
    type: INIT_SAVE_CLIENT_HELPDESK_DATA,
    payload,
    callback,
  };
};

export const clientHelpdeskDataSaveSuccess = () => {
  return {
    type: CLIENT_HELPDESK_DATA_SAVE_SUCCESS,
  };
};

export const clientHelpdeskDataSaveFailed = () => {
  return {
    type: CLIENT_HELPDESK_DATA_SAVE_FAILED,
  };
};

export function initGetContactEmailList() {
  return {
    type: INIT_GET_CONTACT_EMAIL_LIST,
  };
}

export function fetchingContactEmailList() {
  return {
    type: FETCHING_CONTACT_EMAIL_LIST,
  };
}

export function fetchContactEmailList(data) {
  return {
    type: FETCH_CONTACT_EMAIL_LIST,
    data,
  };
}

export function contactEmailListFetchingComplete() {
  return {
    type: CONTACT_EMAIL_LIST_FETCHING_COMPLETE,
  };
}

export function initGetCountryContactList() {
  return {
    type: INIT_GET_COUNTRY_CONTACT_LIST,
  };
}

export function fetchingCountryContactList() {
  return {
    type: FETCHING_COUNTRY_CONTACT_LIST,
  };
}

export function fetchCountryContactList(data) {
  return {
    type: FETCH_COUNTRY_CONTACT_LIST,
    data,
  };
}

export function countryContactListFetchingComplete() {
  return {
    type: COUNTRY_CONTACT_LIST_FETCHING_COMPLETE,
  };
}
