import { Rows } from "components/index";
import styled from "styled-components";

const ContactStyled = styled(Rows)`
  overflow: auto;
  line-height: 1.3;
  padding: 0 1em 1em 1em;
  gap: 1em;
`;
export default ContactStyled;
