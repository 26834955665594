export const INIT_FORGOT_PASSWORD_EMAIL = "INIT_FORGOT_PASSWORD_EMAIL";
export const FORGOT_PASSWORD_EMAIL_SUCCESS = "FORGOT_PASSWORD_EMAIL_SUCCESS";
export const FORGOT_PASSWORD_EMAIL_FAILURE = "FORGOT_PASSWORD_EMAIL_FAILURE";
export const RESET_PROPS_AFTER_SUCCESS = "RESET_PROPS_AFTER_SUCCESS";
export const INIT_CHECK_USER_EXIST = "INIT_CHECK_USER_EXIST";
export const FETCH_CHECK_USER_EXIST_COMPLETED =
  "FETCH_CHECK_USER_EXIST_COMPLETED";
export const INIT_LIST_SECURITY_QUESTION = "INIT_LIST_SECURITY_QUESTION";
export const FETCHING_LIST_SECURITY_QUESTION =
  "FETCHING_LIST_SECURITY_QUESTION";
export const FETCHED_LIST_SECURITY_QUESTION = "FETCHED_LIST_SECURITY_QUESTION";
export const FETCH_LIST_SECURITY_QUESTION_COMPLETED =
  "FETCH_LIST_SECURITY_QUESTION_COMPLETED";
export const INIT_SUBMIT_QNA = "INIT_SUBMIT_QNA";
export const SUBMITTING_QNA = "SUBMITTING_QNA";
export const SUBMITTING_QNA_SUCCESS = "SUBMITTING_QNA_SUCCESS";
export const SUBMITTING_QNA_FAILED = "SUBMITTING_QNA_FAILED";
export const INIT_SUBMIT_RESET_PASSWORD = "INIT_SUBMIT_RESET_PASSWORD";
export const SUBMITTING_RESET_PASSWORD = "SUBMITTING_RESET_PASSWORD";
export const SUBMITTING_RESET_PASSWORD_SUCCESS =
  "SUBMITTING_RESET_PASSWORD_SUCCESS";
export const SUBMITTING_RESET_PASSWORD_FAILED =
  "SUBMITTING_RESET_PASSWORD_FAILED";
  
export const GET_CLIENT_LANGUAGES = "GET_CLIENT_LANGUAGES";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const FETCHING_USER_DETAIL = "FETCHING_USER_DETAIL";
export const FETCHING_USER_DETAIL_SUCCESS = "FETCHING_USER_DETAIL_SUCCESS";
export const FETCHING_USER_DETAIL_FAILED = "FETCHING_USER_DETAIL_FAILED";
export const INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL =
  "INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL";
export const INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL_SUCCESS =
  "INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL_SUCCESS";
export const INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL_FAILED =
  "INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL_FAILED";

export const INIT_FORGOT_PASS_MFA_OTP = "INIT_FORGOT_PASS_MFA_OTP";
export const FORGOT_PASS_MFA_OTP_SENT = "FORGOT_PASS_MFA_OTP_SENT";
export const FORGOT_PASS_MFA_OTP_FAILED = "FORGOT_PASS_MFA_OTP_FAILED";
