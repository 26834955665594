import { fromJS } from "immutable";
import {
  INIT_GET_USER_ADDRESS_LIST,
  FETCH_USER_ADDRESS_LIST,
  FETCH_USER_ADDRESS_LIST_END,
  INIT_GET_USER_ADDRESS_BY_ADDRESS_ID,
  FETCH_USER_ADDRESS_BY_ID_DATA,
  FETCH_USER_ADDRESS_BY_ID_END,
  INIT_DELETE_USER_ADDRESS,
  DELETE_USER_ADDRESS_END,
  INIT_SAVE_USER_ADDRESS,
  SAVE_USER_ADDRESS_END,
  UPDATING_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  UPDATING_SECURITY_QUESTION,
  UPDATE_SECURITY_QUESTION_SUCCESS,
  UPDATE_SECURITY_QUESTION_FAILED,
  SAVE_USER_COMPANY_DETAIL_DATA,
  SAVED_USER_COMPANY_DETAIL_DATA,
  INIT_UPDATE_USER_LANGUAGE,
  UPDATED_USER_LANGUAGE,
  UPDATING_USER_LANGUAGE,
  CURRENCY_MAPPING_FETCHING,
  CURRENCY_MAPPING_FETCH,
  CURRENCY_MAPPING_FETCH_COMPLETE,
  PROFILE_MFA_OTP_SENT,
} from "./actionTypes";

export const initialState = fromJS({
  fetchUserAddressStatus: false,
  userAddressList: [],
  fetchAddressByIdStatus: false,
  userAddressByIdData: {},
  deleteAddressStatus: false,
  saveAddressStatus: false,
  updatingPassword: false,
  passwordUpdated: false,
  errorMessage: "",
  updatingSecurityQuestion: false,
  securityQuestionUpdated: false,
  savingUserCompanyDetails: false,
  updatingLanguage: false,
  languageId: "",
  fetchingCurrencyMapping: false,
  currencyMapingList: [],
  profileOTPSent: false,
});

function userProfileReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_MFA_OTP_SENT:
      return state.set("profileOTPSent", true);

    case INIT_GET_USER_ADDRESS_LIST: {
      return state.set("fetchUserAddressStatus", true);
    }

    case FETCH_USER_ADDRESS_LIST: {
      return state.set("userAddressList", action.data);
    }

    case FETCH_USER_ADDRESS_LIST_END: {
      return state.set("fetchUserAddressStatus", false);
    }

    case INIT_GET_USER_ADDRESS_BY_ADDRESS_ID: {
      return state.set("fetchAddressByIdStatus", true);
    }

    case FETCH_USER_ADDRESS_BY_ID_DATA: {
      return state.set("userAddressByIdData", action.data);
    }

    case FETCH_USER_ADDRESS_BY_ID_END: {
      return state.set("fetchAddressByIdStatus", false);
    }

    case INIT_DELETE_USER_ADDRESS: {
      return state.set("deleteAddressStatus", true);
    }

    case DELETE_USER_ADDRESS_END: {
      return state.set("deleteAddressStatus", false);
    }

    case INIT_SAVE_USER_ADDRESS: {
      return state.set("saveAddressStatus", true);
    }

    case SAVE_USER_ADDRESS_END: {
      return state.set("saveAddressStatus", false);
    }
    case UPDATING_PASSWORD: {
      return state.set("updatingPassword", true);
    }
    case UPDATE_PASSWORD_SUCCESS: {
      return state
        .set("updatingPassword", false)
        .set("passwordUpdated", true)
        .set("profileOTPSent", false);;
    }
    case UPDATE_PASSWORD_FAILED: {
      return state
        .set("errorMessage", action.message)
        .set("updatingPassword", false)
        .set("passwordUpdated", false);
    }
    case UPDATING_SECURITY_QUESTION: {
      return state.set("updatingSecurityQuestion", true);
    }
    case UPDATE_SECURITY_QUESTION_SUCCESS: {
      return state
        .set("updatingSecurityQuestion", false)
        .set("securityQuestionUpdated", true);
    }
    case UPDATE_SECURITY_QUESTION_FAILED: {
      return state
        .set("errorMessage", action.message)
        .set("updatingSecurityQuestion", false)
        .set("securityQuestionUpdated", false);
    }

    case SAVE_USER_COMPANY_DETAIL_DATA: {
      return state.set("savingUserCompanyDetails", true);
    }

    case SAVED_USER_COMPANY_DETAIL_DATA: {
      return state.set("savingUserCompanyDetails", false);
    }

    case INIT_UPDATE_USER_LANGUAGE: {
      return state.set("changingLanguage", true);
    }

    case UPDATING_USER_LANGUAGE: {
      return state.set("languageId", action.data);
    }

    case UPDATED_USER_LANGUAGE: {
      return state.set("changingLanguage", false);
    }

    case CURRENCY_MAPPING_FETCHING: {
      return state.set("fetchingCurrencyMapping", true);
    }
    case CURRENCY_MAPPING_FETCH: {
      return state.set("currencyMapingList", action.data);
    }
    case CURRENCY_MAPPING_FETCH_COMPLETE: {
      return state.set("fetchingCurrencyMapping", false);
    }

    default:
      return state;
  }
}

export default userProfileReducer;
