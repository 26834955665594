import { Card } from "components/index";
import styled from "styled-components";

export const AssignGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;

  justify-content: space-between;
  margin-block: 1em;
`;
export const AssignCardLayoutBottom = styled.div`
  p {
    svg {
      height: 0.9em;
      padding-left:0.5em;
      color: var(--dark-clr);
      opacity: 0.65;      
    }
  }  
`;
export const AssignGroupActions = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5em;
  background-color: var(--primary-clr);
  border-radius: 0px 0px 8px 8px;
  gap: 1rem;

  & > button {
    color: var(--light-clr);
    border: var(--onePx) solid transparent;
    padding: 0.25em;
    svg,
    img {
      width: 1.5rem;
    }
    :hover,
    :focus {
      border-color: var(--light-clr);
    }
  }
`;
export const AssignGroups = styled(Card)`
  position: relative;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border: none;
  background: ${(props) =>
    props.disabled ? "var(--disabled-clr)" : "var(--light-clr)"};
  box-shadow: 0px 0px 0.625em 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.5em;
  min-height: 0;
  min-width: 0;
  display: grid;
  gap: 0.5em;
  flex: 1 0 100%;
  padding: 1em;
  font-size: var(--fs-16);
  margin: 0;
  justify-content: space-between;
  color: var(--dark-clr);
  font-weight: var(--fw-normal);
  overflow-wrap: anywhere;
  :hover {
    box-shadow: rgb(102 187 255 / 50%) 0 0 0.8rem;
    ${AssignGroupActions} {
      opacity: 0.8;
    }
  }
  p {
    font-size: var(--fs-12);
    font-family: var(--ff-main);
    margin-bottom: 0.25em;
  }
  p > strong {
    font-size: var(--fs-14);
  }
  @media (min-width: 32em) {
    grid-template-columns: 1fr 1fr;
    gap: 1.9em 0.5em;
    grid-template-rows: 1.6fr 1fr;
    & > div[data-placement^="bottom"] {
      align-self: end;
    }
    & > div:nth-of-type(even) {
      text-align: end;
    }
  }

  @media (min-width: 75em) {
    flex: 0 0 calc(50% - 1em);
  }
`;
