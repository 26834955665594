import styled from "styled-components";

const Card = styled.div`
  min-height: 4em;
  background: var(--light-clr);
  border: 0.125em solid transparent;
  box-shadow: 0 0.125em 2em 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
  display: flex;
  padding: 0.75em;
  cursor: pointer;
`;

export default Card;
