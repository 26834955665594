import { buildNotification } from "config/notification";

export const errorMessages = {
  getLandingPageDataFailed: buildNotification({
    message: "msp.failedLandingPageMessage",
    type: "danger",
  }),
  overdueInvoicesFetchError: buildNotification({
    message: "msp.failedOverdueInvoiceMessage",
    type: "danger",
  }),
  forcastInvoicesFetchError: buildNotification({
    message: "msp.failedForcastMessage",
    type: "danger",
  }),
  invoiceHistoryByIdFetchError: buildNotification({
    message: "msp.failedInvoiceHistory",
    type: "danger",
  }),
  rejectedInvoicesResolvingError: buildNotification({
    message: "msp.resolveInvoiceMessage",
    type: "danger",
  }),
  noRecordFound: buildNotification({
    message: "msp.noDatafound",
    type: "warning",
  }),
  rejectedInvoicesFetchError: buildNotification({
    message: "msp.rejectedInvoiceMessage",
    type: "danger",
  }),
};
