import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";

import { Cols, Tooltip } from "components";
import Regular from "./Regular";

import {
  DownloadText,
  DropzoneTemplateBodyWrapper,
  DropzoneContainer,
  DownloadTemplateWrapper,
} from "./style";
import { TooltipText, TooltipWrapper } from "../Tooltip/style";
import Download from "../SVG/Download";
import { fileExtensionValidation } from "helper/index";

const TemplateDropzone = (props) => {
  const { t } = useTranslation();
  const maxSize = props.maxSize ? props.maxSize * 1048576 : 10 * 1048576; // mb to byte
  let timeout;
  const [active, setActive] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };
  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <>
      <Cols
        cursor={props.disabled ? "not-allowed" : "pointer"}
        ref={setReferenceElement}
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
      >
        <Dropzone
          onDrop={props.onDrop}
          onDropAccepted={props.onDropAccepted}
          onDropRejected={props.onDropRejected}
          accept={props.acceptableExt}
          disabled={props.disabled}
          maxFiles={1}
          maxSize={maxSize}
          multiple={false}
          noClick={props.noClick}
          noDrag={props.noDrag}
          validator={fileExtensionValidation}
        >
          {({
            isDragActive,
            isDragAccept,
            isDragReject,
            acceptedFiles,
            rejectedFiles,
            getInputProps,
            getRootProps,
            inputRef,
          }) => {
            const removeAll = () => {
              acceptedFiles.length = 0;
              acceptedFiles.splice(0, acceptedFiles.length);
              inputRef.current.value = "";
            };

            props.uploaded && removeAll();
            return (
              <React.Fragment>
                <DropzoneContainer
                  uploadContent={!!props.fileUploadContent}
                  cursor={props.disabled ? "not-allowed" : "pointer"}
                >
                  <DownloadTemplateWrapper>
                    <Download />
                    <DownloadText>
                      {t("msp.downloadTemplateFrom")}&nbsp;
                      <strong onClick={props.handleDownload}>
                        {t("msp.here")}
                      </strong>
                    </DownloadText>
                  </DownloadTemplateWrapper>

                  {!!props.fileUploadContent ? (
                    props.fileUploadContent
                  ) : (
                    <DropzoneTemplateBodyWrapper
                      {...getRootProps()}
                      isDragAccept={isDragAccept}
                      isDragActive={isDragActive}
                      isDragReject={isDragReject}
                      cursor={props.disabled ? "not-allowed" : "pointer"}
                    >
                      <input
                        {...getInputProps()}
                        aria-label={t("msp.uploadTemplate")}
                      />
                      <Regular
                        acceptedFiles={props.acceptedFiles}
                        desc={props.desc}
                        maxSize={props.maxSize || 10}
                        disabled={props.disabled}
                        required={props.required}
                      />
                    </DropzoneTemplateBodyWrapper>
                  )}
                </DropzoneContainer>
              </React.Fragment>
            );
          }}
        </Dropzone>
      </Cols>
      {active && props.disabled && (
        <Tooltip triggerElem={referenceElement} placement="top">
          <TooltipWrapper>
            <TooltipText>{props.tooltipText}</TooltipText>
          </TooltipWrapper>
        </Tooltip>
      )}
    </>
  );
};

export default TemplateDropzone;
