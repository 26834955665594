import styled from "styled-components";
import SiteActionStyled from "../SiteActions/style";
import NavStyled, { NavLinkStyled } from "./style";

export const NavToggle = styled.input.attrs(() => ({
  type: "checkbox",
  id: "navToggle",
  value: false,
}))`
  display: none;
  &:checked ~ {
    ${NavStyled},
    ${SiteActionStyled} {
      transform: scale(1, 1);
      @media (min-width: 36em) {
        transform: unset;
      }
      ${NavLinkStyled} {
        opacity: 1;
        transition: opacity 250ms ease-in-out 250ms;
      }
    }
  }
`;

export const NavToggler = styled.label`
  height: 3em;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: transparent;
  position: relative;
  grid-area: navigation;
  order: -1;
  color: var(--light-clr);
  svg {
    width: 2em;
    height: 1.5em;
  }
  @media (min-width: 68.75em) {
    display: none;
  }
`;
