import styled from "styled-components";

const IconWrapper = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: inherit;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
export default IconWrapper;
