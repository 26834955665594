import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { centered } from "globalStyles";
import Overlay from "components/Overlay/index";
import Robo from "../../assets/images/Register/robo-loader.gif";
import { SVGContainer } from "container/Verfication/style";
import { MoneyBag } from "components/SVG/index";
import { Img } from "components/index";

const Center = styled.div`
  ${centered}
`;
const Text = styled.p`
  color: var(--grey-clr);
  font-weight: normal;
  text-align: center;
`;

const StyledOverlay = styled(Overlay)`
  backdrop-filter: blur(0.5em);
`;
const LoaderWrapper = styled.div`
  gap: 1em;
  align-items: center;
  font-family: var(--ff-main);
  font-size: clamp(var(--fs-16), 2.5vw, var(--fs-20));
  h1 {
    color: var(--dark-clr);
    font-size: clamp(var(--fs-20), 2.5vw, var(--fs-28));
    font-family: var(--ff-semibold);
    font-weight: var(--fw-normal);
    text-align: center;
  }
  p {
    margin: 0.5em 0 1em 0;
    text-align: center;
  }
  img {
    margin: auto;
  }
`;

const Loader = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledOverlay>
        <Center>
          <LoaderWrapper>
            <SVGContainer>
              <MoneyBag />
            </SVGContainer>
            <h1>
              {props.title ? t(`${props.title}`) : `${t("msp.loading")}...`}
            </h1>
            <Text>
              {props.description
                ? t(`${props.description}`)
                : t("msp.settingUpYourPage")}
            </Text>
            <Img src={Robo} alt={t("msp.loading")} />
          </LoaderWrapper>
        </Center>
      </StyledOverlay>
    </>
  );
};

export default Loader;
