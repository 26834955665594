import { createSelector } from "reselect";
import { initialState } from "./reducers";

const selectSubmissionHistory = (state) =>
  state.get("submissionHistory", initialState);

export const selectSubmittedInvoices = () =>
  createSelector(selectSubmissionHistory, (VPState) =>
    VPState.get("submittedInvoices")
  );
export const selectLoadingSubmissions = () =>
  createSelector(selectSubmissionHistory, (VPState) => VPState.get("fetching"));
export const selectDataGetterParams = () =>
  createSelector(selectSubmissionHistory, (VPState) =>
    VPState.get("dataGetterParams")
  );
export const selectIsSSR = () =>
  createSelector(selectSubmissionHistory, (VPState) => VPState.get("isSSR"));

export const selectFilterParams = () =>
  createSelector(selectSubmissionHistory, (VPState) =>
    VPState.get("filterParams")
  );

export const selectSkip = () =>
  createSelector(selectSubmissionHistory, (VPState) => VPState.get("skip"));

export const selectTab = () =>
  createSelector(selectSubmissionHistory, (VPState) =>
    VPState.get("tabSelected")
  );
export const selectShowDownloadPopup = () =>
  createSelector(selectSubmissionHistory, (VPState) =>
    VPState.get("showDocDownloadPopup")
  );

export const selectInvoiceCopyCheck = () =>
  createSelector(selectSubmissionHistory, (VPState) =>
    VPState.get("invoiceCopyChecked")
  );
export const selectSupportingDocCheck = () =>
  createSelector(selectSubmissionHistory, (VPState) =>
    VPState.get("supportingDocCheck")
  );
