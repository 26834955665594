import React from "react";
import SvgIcon from "./style";

const Archive = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-939.000000, -188.000000)" fill="#919EAB">
          <g transform="translate(821.000000, 59.000000)">
            <g transform="translate(118.000000, 129.000000)">
              <path d="M10.125,1.49999998 L9.00000001,1.49999998 L9.00000001,0.281249986 C9.00000001,0.125929673 8.87407032,-1.50000002e-08 8.71875001,-1.50000002e-08 L7.78125,-1.50000002e-08 C7.62592969,-1.50000002e-08 7.5,0.125929673 7.5,0.281249986 L7.5,1.49999998 L4.5,1.49999998 L4.5,0.281249986 C4.5,0.125929673 4.37407031,-1.50000002e-08 4.21875,-1.50000002e-08 L3.28124999,-1.50000002e-08 C3.12592968,-1.50000002e-08 2.99999999,0.125929673 2.99999999,0.281249986 L2.99999999,1.49999998 L1.87499999,1.49999998 C1.25367186,1.49999998 0.749999987,2.00367187 0.749999987,2.62499999 L0.749999987,10.875 C0.749999987,11.4963281 1.25367186,12 1.87499999,12 L10.125,12 C10.7463281,12 11.25,11.4963281 11.25,10.875 L11.25,2.62499999 C11.25,2.00367187 10.7463281,1.49999998 10.125,1.49999998 Z M9.98437501,10.875 L2.01562499,10.875 C1.93795995,10.875 1.87499999,10.8120401 1.87499999,10.734375 L1.87499999,3.74999999 L10.125,3.74999999 L10.125,10.734375 C10.125,10.8120401 10.0620401,10.875 9.98437501,10.875 Z M8.74572657,6.17226563 L5.41035937,9.48091407 C5.30008594,9.59029688 5.12200781,9.58959376 5.01260156,9.47932032 L3.25265624,7.705125 C3.14327343,7.59485157 3.14397656,7.41677344 3.25424999,7.30736719 L3.78672656,6.77917969 C3.89699999,6.66979688 4.07507812,6.6705 4.18446093,6.78079688 L5.21814844,7.82285157 L7.82139844,5.24048437 C7.93167188,5.13110156 8.10975001,5.13180469 8.21915626,5.24207812 L8.74734376,5.77453125 C8.85672657,5.88480469 8.85600001,6.06288281 8.74572657,6.17226563 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Archive;
