import styled from "styled-components";
const InputAddon = styled.button.attrs(() => ({ type: "button" }))`
  padding: 0.5em;
  background-color: ${(props) =>
    props.disabled ? "var(--disabled-clr)" : "transparent"};
  outline: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border: var(--oneDotFivePx) solid var(--input-border-clr);
  border-left: none;
  font-weight: var(--fw-normal);
  text-align: center;
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  svg {
    width: 1em;
    height: 1em;
  }
`;

export default InputAddon;
