import styled from "styled-components";
import { Cols } from "components";

export const InviteOthersForm = styled.form`
  padding: 1em 0.5em;
  text-align: start;
`;
export const InviteToWrapper = styled(Cols)`
  font-size: var(--fs-16);
  font-family: var(--ff-main);
  color: var(--grey-clr);
  padding: 0.25em;
  position: relative;
  margin-block: 1em;
  :focus-within {
    color: var(--dark-clr);
  }
  ::after {
    position: absolute;
    top: 100%;
    content: "";
    width: 100%;
    height: var(--onePx);
    background-color: var(--grey-clr);
    opacity: 0.3;
  }
  input {
    border: none;
    outline: none;
  }
`;
export const OR = styled.p`
  font-size: var(--fs-16);
  font-family: var(--ff-main);
  color: var(--grey-clr);
  margin: 0.25em auto;
  text-align: center;
`;
export const ReadonlyAddress = styled.input`
  font-family: var(--ff-main);
  color: var(--dark-clr);
  width: 100%;
  border-radius: 0.25em;
  border: var(--onePx) solid rgb(187, 193, 197);
  padding: 0.25em;
  margin-block: 1em;
  :hover,
  :focus {
    box-shadow: rgb(102 187 255 / 50%) 0 0 0.8rem;
    border-color: var(--primary-clr);
    outline: none;
  }
`;
