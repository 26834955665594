import React from "react";
import { Calendar } from "components/SVG/";
import FloatingInput from "components/Inputs/FloatingInput";

const FloatingDateInput = (props) => {
  const isValidDateFormat = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode === 46 || charCode < 45 || charCode > 57) e.preventDefault();
  };
  return (
    <FloatingInput
      icon={<Calendar />}
      id={props.id}
      name={props.name}
      autoComplete="off"
      required={props.required}
      disabled={props.disabled}
      autoFocus={props.focus}
      value={props.inputValue || ""}
      onKeyPress={isValidDateFormat}
      maxLength="10"
      onChangeHandler={(e) => {
        props.onInputChange(e.target.value);
      }}
      placeholder={props.placeholder}
      error={props.error}
      onAddonClick={props.onClick}
    />
  );
};

export default FloatingDateInput;
