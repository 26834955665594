import { put, call, all, takeLatest, select } from "redux-saga/effects";

import {
  fetchingRejectedInvoice,
  fetchingSubmissionHistory,
  initFecthSubmissionHistory,
  rejectedInvoiceFailed,
  rejectedInvoiceFetched,
  submissionHistoryFailed,
  submissionHistoryFetched,
} from "../store/actions";

import {
  APPLY_SUBMISISON_HISTORY_FILTER,
  INIT_FECTH_RESOLVED_INVOICE,
  INIT_FECTH_SUBMISSION_HISTORY,
} from "./actionTypes";

import axios from "../../../config/axiosCall";

import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";
import { selectSubmittedInvoices } from "./selectors";
import getColumns from "../func/getColumns";

export default function* watchDocHistorySubmissions() {
  yield all([
    takeLatest(INIT_FECTH_SUBMISSION_HISTORY, initDocSubmissionHistoryListSaga),
    takeLatest(
      APPLY_SUBMISISON_HISTORY_FILTER,
      applySubmissionHistoryFilterSaga
    ),
    takeLatest(INIT_FECTH_RESOLVED_INVOICE, initGetResolvedDataSaga),
  ]);
}

function* initDocSubmissionHistoryListSaga(action) {
  try {
    let documentList = [];
    let additionalFields = [];
    let rowGroupCount = [];
    let invoiceStatus = [];
    let columnHeaders = [];
    const filterParam = {
      ...action.payload,
    };
    yield put(fetchingSubmissionHistory());
    const requestDetails = { ...postRequestDetails };
    requestDetails.data = JSON.stringify({
      languageID: "enGB",
      pageIndex: 1,
      ...filterParam,
    });

    const response = yield call(axios, "/Submission/history", requestDetails);

    if (response && response.status === 200) {
      const { data, rowCount, counts, fieldLabels } = response.data || [];

      documentList = [...data];

      rowGroupCount = [...rowCount];
      invoiceStatus = [...counts];

      additionalFields =
        response.data.columns &&
        response.data.columns.map((field, key) => {
          return {
            name: field.displayName ? field.displayName : field.attributeName,
            selector: field.attributeName,
            sortable: true,
            filterable: true,
            filterType:
              field.attributeName.toLowerCase().indexOf("date") > -1
                ? "date"
                : "text",
          };
        });

      const sortedFields =
        fieldLabels &&
        fieldLabels.sort((a, b) => (a.GridOrder > b.GridOrder ? 1 : -1));
      columnHeaders =
        sortedFields &&
        sortedFields.map((field, key) => {
          return {
            name: field.Label || " ",
            selector: field.FieldName,
            sortable: true,
            filterable: true,
            filterType: field.FieldName.toLowerCase().includes("date")
              ? "date"
              : "text",
            omit:
              field.FieldName.toLowerCase() === "submissionid" ||
              field.FieldName.toLowerCase() === "referencenumber"
                ? true
                : false,
            cell:
              field.FieldName.toLowerCase() === "statusname"
                ? (event) => {
                    return (
                      <td>
                        <p>{event.dataItem.StatusName}</p>
                      </td>
                    );
                  }
                : null,
          };
        });
    }
    yield put(
      submissionHistoryFetched({
        addtionalColumns: additionalFields,
        documentList,
        rowCount: rowGroupCount,
        counts: invoiceStatus,
        columnHeaders,
      })
    );
  } catch (error) {
    yield put(submissionHistoryFailed());
  }
}

function* applySubmissionHistoryFilterSaga({ payload }) {
  try {
    const { activeColsConfig, historyFilter } = payload;
    const url = `/Search/SaveColumns`;
    const requestDetails = { ...postRequestDetails };
    requestDetails.data = yield activeColsConfig;
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200) {
      yield put(initFecthSubmissionHistory(historyFilter));
    }
  } catch (error) {}
}

function* initGetResolvedDataSaga(action) {
  try {
    const id = action.payload;
    const submission = yield select(selectSubmittedInvoices());
    const submissionObj = yield submission.toJS();
    const { documentList, columnHeaders, additionalColumns } = submissionObj;
    const cols = getColumns(columnHeaders, additionalColumns);
    let index = documentList.findIndex((d) => d.SubmissionID === id);
    const expanded = documentList[index].expanded;
    documentList[index].expanded = !expanded;

    if (!expanded) {
      const url = `/Submission/ChildHistory?SubmissionParentId=${id}`;
      const requestDetails = { ...getRequestDetails };
      yield put(fetchingRejectedInvoice());
      const { data } = yield call(axios, url, requestDetails);

      const linkedDetails = {
        columns: cols,
        data: data.data,
      };

      documentList[index].details = linkedDetails;
    }
    let newSubmissionHistory = {
      ...submissionObj,
      documentList,
    };

    yield put(rejectedInvoiceFetched(newSubmissionHistory));
  } catch (error) {
    console.log(error);
    yield put(rejectedInvoiceFailed());
  }
}
