import { fromJS } from "immutable";

import {
  FETCHING_WORKFLOW_LIST,
  FETCH_WORKFLOW_LIST,
  WORKFLOW_LIST_FETCH_COMPLETE,
  DELETING_WORKFLOW,
  WORKFLOW_DELETED,
  FETCHING_CONDITION_LIST,
  FETCH_CONDITION_LIST,
  CONDITION_LIST_FETCH_COMPLETE,
  FETCHING_WORKFLOW_BY_ID,
  FETCH_WORKFLOW_BY_ID,
  WORKFLOW_BY_ID_FETCH_COMPLETE,
  INIT_WORKFLOW_SAVE,
  WORKFLOW_SAVED,
  WORKFLOW_SAVE_FAILED,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingWorkflowList: false,
  workflowList: "",
  deletingWorkflow: false,
  workflowsDeleted: [],
  fetchingConditionList: false,
  conditionList: "",
  fetchingWorkflowById: false,
  workflowById: "",
  workflowSaveStatus: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_WORKFLOW_LIST:
      return state.set("fetchingWorkflowList", true);

    case FETCH_WORKFLOW_LIST:
      return state.set("workflowList", action.data);

    case WORKFLOW_LIST_FETCH_COMPLETE: {
      return state.set("fetchingWorkflowList", false);
    }

    case DELETING_WORKFLOW: {
      return state.set("deletingWorkflow", true);
    }
    case WORKFLOW_DELETED: {
      return state.set("deletingWorkflow", false);
    }

    case FETCHING_CONDITION_LIST: {
      return state.set("fetchingConditionList", true);
    }
    case FETCH_CONDITION_LIST: {
      return state.set("conditionList", action.data);
    }
    case CONDITION_LIST_FETCH_COMPLETE: {
      return state.set("fetchingConditionList", false);
    }

    case FETCHING_WORKFLOW_BY_ID: {
      return state.set("fetchingWorkflowById", true);
    }

    case FETCH_WORKFLOW_BY_ID: {
      return state.set("workflowById", action.data);
    }

    case WORKFLOW_BY_ID_FETCH_COMPLETE: {
      return state.set("fetchingWorkflowById", false);
    }

    case INIT_WORKFLOW_SAVE: {
      return state.set("workflowSaveStatus", true);
    }

    case WORKFLOW_SAVED: {
      return state.set("workflowSaveStatus", false);
    }

    case WORKFLOW_SAVE_FAILED: {
      return state.set("workflowSaveStatus", false);
    }

    default:
      return state;
  }
};
export default reducer;
