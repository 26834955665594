export const INIT_FETCH_SIGNUP_FLEX_FIELDS = "INIT_FETCH_SIGNUP_FLEX_FIELDS";
export const FETCHING_SIGNUP_FLEX_FIELDS = "FETCHING_SIGNUP_FLEX_FIELDS";
export const FETCH_SIGNUP_FLEX_FIELDS = "FETCH_SIGNUP_FLEX_FIELDS";
export const FETCH_SIGNUP_FLEX_FIELDS_COMPLETE =
  "FETCH_SIGNUP_FLEX_FIELDS_COMPLETE";

export const INIT_SAVE_SIGNUP_FORM = "INIT_SAVE_SIGNUP_FORM";
export const SIGNUP_FORM_SAVED = "SIGNUP_FORM_SAVED";
export const SIGNUP_FORM_SAVE_FAILED = "SIGNUP_FORM_SAVE_FAILED";
export const RESET_SAVE_STATUS = "RESET_SAVE_STATUS";

export const INIT_CHECK_USER_EXISTS = "INIT_CHECK_USER_EXISTS";
export const USER_EXIST = "USER_EXIST";
export const CHECK_USER_EXISTS_STATUS = "CHECK_USER_EXISTS_STATUS";

export const INIT_FETCH_GRACE_PERIOD = "INIT_FETCH_GRACE_PERIOD";
export const FETCH_GRACE_PERIOD = "FETCH_GRACE_PERIOD";
