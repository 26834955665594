export const GET_NOTIFICATION_DATA = "GET_NOTIFICATION_DATA";
export const FETCH_NOTIFICATION_DATA = "FETCH_NOTIFICATION_DATA";

export const INIT_GET_LANDING_PAGE_DATA = "INIT_GET_LANDING_PAGE_DATA";
export const GET_LANDING_PAGE_DATA_COMPLETED =
  "GET_LANDING_PAGE_DATA_COMPLETED";
export const GET_LANDING_PAGE_DATA_FAILED = "GET_LANDING_PAGE_DATA_FAILED";

export const SET_SELECTED_DAY_INDEX = 'SET_SELECTED_DAY_INDEX';

export const INIT_GET_REJECTED_INVOICES = "INIT_GET_REJECTED_INVOICES";
export const FETCHING_REJECTED_INVOICES = "FETCHING_REJECTED_INVOICES";
export const FETCH_REJECTED_INVOICES = "FETCH_REJECTED_INVOICES";
export const FETCH_REJECTED_INVOICES_COMPLETED =
  "FETCH_REJECTED_INVOICES_COMPLETED";
export const FETCHING_REJECTED_INVOICES_FAILED =
  "FETCHING_REJECTED_INVOICES_FAILED";

export const INIT_GET_OVERDUE_INVOICES = "INIT_GET_OVERDUE_INVOICES";
export const GET_OVERDUE_INVOICES_COMPLETED = "GET_OVERDUE_INVOICES_COMPLETED";
export const GET_OVERDUE_INVOICES_FETCH_FAILED =
  "GET_OVERDUE_INVOICES_FETCH_FAILED";

export const INIT_GET_FORCAST_INVOICES = "INIT_GET_FORCAST_INVOICES";
export const GET_FORCAST_INVOICES_COMPLETED = "GET_FORCAST_INVOICES_COMPLETED";
export const GET_FORCAST_INVOICES_FETCH_FAILED =
  "GET_FORCAST_INVOICES_FETCH_FAILED";

export const INIT_GET_INVOICE_HISTORY_BY_ID = "INIT_GET_INVOICE_HISTORY_BY_ID";
export const GET_INVOICE_HISTORY_BY_ID_COMPLETED =
  "GET_INVOICE_HISTORY_BY_ID_COMPLETED";
export const GET_INVOICE_HISTORY_BY_ID_FETCH_FAILED =
  "GET_INVOICE_HISTORY_BY_ID_FETCH_FAILED";

// export const INIT_RESOLVE_REJECTED_INVOICES = "INIT_RESOLVE_REJECTED_INVOICES";
// export const REJECTED_INVOICES_RESOLVING = "REJECTED_INVOICES_RESOLVING";
// export const REJECTED_INVOICES_RESOLVED = "REJECTED_INVOICES_RESOLVED";
// export const REJECTED_INVOICES_RESOLVING_FAILED = "REJECTED_INVOICES_RESOLVING_FAILED";

export const IS_HEADER_IMG_CLICKED = "IS_HEADER_IMG_CLICKED";
