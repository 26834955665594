import React from "react";
import SvgIcon from "./style";
const Coin = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-298.000000, -329.000000)">
          <g transform="translate(298.000000, 328.000000)">
            <path d="M10.5,0.999999983 C8.98125,0.999999983 7.60625,1.28124998 6.609375,1.73124998 C6.58125,1.74374998 4.99999998,2.38749999 4.99999998,3.49999998 L4.99999998,4.02187499 C2.37812499,4.13124999 -2.00000002e-08,5.10624999 -2.00000002e-08,6.49999998 L-2.00000002e-08,12.5 C-2.00000002e-08,13.88125 2.46249999,15 5.49999998,15 C8.5375,15 11,13.88125 11,12.5 L11,11.9875 C13.803125,11.871875 16,10.803125 16,9.49999998 L16,3.49999998 C16,2.34374999 14.059375,0.999999983 10.5,0.999999983 Z M9.99999998,12.5 C9.99999998,12.934375 8.421875,14 5.49999998,14 C2.57812499,14 0.999999982,12.934375 0.999999982,12.5 L0.999999982,10.934375 C1.99374998,11.578125 3.63749999,12 5.49999998,12 C7.3625,12 9.00625,11.578125 9.99999998,10.934375 L9.99999998,12.5 Z M9.99999998,9.5 C9.99999998,9.934375 8.421875,11 5.49999998,11 C2.57812499,11 0.999999982,9.934375 0.999999982,9.5 L0.999999982,7.934375 C1.99374998,8.578125 3.63749999,9 5.49999998,9 C7.3625,9 9.00625,8.578125 9.99999998,7.934375 L9.99999998,9.5 Z M5.49999999,7.99999999 C2.96874999,7.99999999 0.915624982,7.328125 0.915624982,6.49999999 C0.915624982,5.67187499 2.96874999,4.99999999 5.49999999,4.99999999 C8.03125,4.99999999 10.084375,5.67187499 10.084375,6.49999999 C10.084375,7.328125 8.03125,7.99999999 5.49999999,7.99999999 Z M15,9.5 C15,9.909375 13.59375,10.8625 11,10.978125 L11,8.975 C12.65,8.90625 14.096875,8.51875 15,7.934375 L15,9.5 Z M15,6.5 C15,6.909375 13.590625,7.85625 11,7.975 L11,6.5 C11,6.325 10.946875,6.15625 10.871875,5.99062499 C12.578125,5.93749999 14.071875,5.53749999 15,4.93437499 L15,6.5 Z M10.5,4.99999999 C10.271875,4.99999999 10.0625,4.98437499 9.846875,4.97187499 C8.69375,4.29374999 7.190625,4.09062499 6.234375,4.03437499 C6.0375,3.86874999 5.91874999,3.69062499 5.91874999,3.49999999 C5.91874999,2.67187499 7.971875,1.99999999 10.503125,1.99999999 C13.034375,1.99999999 15.0875,2.67187499 15.0875,3.49999999 C15.0875,4.32812499 13.03125,4.99999999 10.5,4.99999999 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Coin;
