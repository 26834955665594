import { A, Modal } from "components/index";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ContactSupport from "./ContactSupport/index";
import PrivacyStatement from "./PrivacyStatement/index";
import FooterStyled, {
  ClientInfo,
  Copyright,
  FooterLinks,
  LogoFooter,
} from "./style";
import TermsOfUse from "./TermsOfUse/index";
import AccentureLogoPurple from "assets/images/Footer/AccentureLogo-Purple.png";

const Footer = (props) => {
  const { t } = useTranslation();
  const { logo, name } = props.clientDetails || {};
  const { fileData } = logo || {};
  const [activeContent, setShowContent] = useState("");
  const sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  const { baseRoleId } = sessionDetails || { baseRoleId: null };
  const showLinkContent = (link) => {
    setShowContent(link);
  };

  let content = null;
  let title = "";
  switch (activeContent) {
    case "1":
      title = t("msp.temsOfUse");
      content = <TermsOfUse />;
      break;
    case "2":
      title = t("msp.privacyStatement");
      content = <PrivacyStatement />;
      break;
    case "3":
      title = t("msp.contactSupport");
      content = <ContactSupport />;
      break;
    default:
      break;
  }

  return (
    <>
      <FooterStyled role="contentinfo">
        <ClientInfo>
          {fileData ? (
            baseRoleId === 1 || baseRoleId === 4 ? (
              <LogoFooter
                tabIndex="0"
                src={AccentureLogoPurple}
                alt="Accenture logo"
              />
            ) : (
              <img
                tabIndex="0"
                src={`data:image/*;base64, ${fileData}`}
                alt="Client logo"
              />
            )
          ) : null}
          <h1 aria-label="heading">
            {baseRoleId === 1 || baseRoleId === 4 ? null : name}
          </h1>
        </ClientInfo>
        <Copyright>
          &#169;
          {` ${new Date().getFullYear()} ${
            baseRoleId === 1 || baseRoleId === 4 ? "Accenture" : name
          }. ${t("msp.allRightsReserved")}.`}
        </Copyright>
        <FooterLinks>
          <A to={() => false} onClick={() => showLinkContent("1")}>
            {t("msp.temsOfUse")}
          </A>
          <A to={() => false} onClick={() => showLinkContent("2")}>
            {t("msp.privacyStatement")}
          </A>
          {/* <A
            to={() => false}
            onClick={() => showLinkContent("3")}
          >
            {t("msp.contactSupport")}
          </A> */}
        </FooterLinks>
      </FooterStyled>
      <Modal
        scrollable
        title={title}
        onClose={() => setShowContent("")}
        show={activeContent}
      >
        {content}
      </Modal>
    </>
  );
};

export default Footer;
