import {
  INIT_EMAIL_TEMPLATE_LIST,
  FETCHING_EMAIL_TEMPLATE_LIST,
  FETCH_EMAIL_TEMPLATE_LIST,
  EMAIL_TEMPLATE_LIST_FETCH_COMPLETE,
  INIT_CATEGORY_LIST,
  FETCHING_CATEGORY_LIST,
  FETCH_CATEGORY_LIST,
  CATEGORY_LIST_FETCH_COMPLETE,
  INIT_EMAIL_TEMPLATE_SAVE,
  EMAIL_TEMPLATE_SAVED,
  EMAIL_TEMPLATE_SAVE_FAILED,
  INIT_EMAIL_TEMPLATE_DELETE,
  DELETE_SUCCESS,
  DELETE_FAILED,
  RESET_SAVE_STATUS,
  RESET_EDIT_STATUS,
  DOWNLOADED_ATTACHMENT,
  INIT_EMAIL_TEMPLATE_EDIT,
  SET_EMAIL_TEMPLATE_EDIT_DATA,
  EDIT_TEMPLATE_FILE,
  EDIT_ATTACHMENT_FILE,
  EDIT_ATTACHMENT_FILE_COMPLETE,
  EDIT_TEMPLATE_FILE_COMPLETE,
  UPDATE_MUTATION,
} from "./actionTypes";

export const initEmailTemplateList = () => {
  return {
    type: INIT_EMAIL_TEMPLATE_LIST,
  };
};

export const fetchingEmailTemplateList = () => {
  return {
    type: FETCHING_EMAIL_TEMPLATE_LIST,
  };
};

export const fetchEmailTemplateList = (data) => {
  return {
    type: FETCH_EMAIL_TEMPLATE_LIST,
    data: data,
  };
};

export const EmailTemplateListFetchingComplete = () => {
  return {
    type: EMAIL_TEMPLATE_LIST_FETCH_COMPLETE,
  };
};

export const getCategoryList = () => {
  return {
    type: INIT_CATEGORY_LIST,
  };
};

export const getTemplateFile = (blobid,name) => {
  return {
    type: EDIT_TEMPLATE_FILE,
    blobid,
    name
  };
};

export const getAttachmentFileComplete = (data) => {
  return {
    type: EDIT_ATTACHMENT_FILE_COMPLETE,
    data,
  };
};

export const getTemplateFileComplete = (data) => {
  return {
    type: EDIT_TEMPLATE_FILE_COMPLETE,
    data,
  };
};

export const getAttachmentFile = (blobid) => {
  return {
    type: EDIT_ATTACHMENT_FILE,
    blobid,
  };
};

export const fetchingCategoryList = () => {
  return {
    type: FETCHING_CATEGORY_LIST,
  };
};

export const fetchCategoryList = (data) => {
  return {
    type: FETCH_CATEGORY_LIST,
    data: data,
  };
};

export const CategoryListFetchingComplete = () => {
  return {
    type: CATEGORY_LIST_FETCH_COMPLETE,
  };
};

export const initEmailTemplateSave = (payload, saveSuccess) => {
  return {
    type: INIT_EMAIL_TEMPLATE_SAVE,
    payload,
    saveSuccess,
  };
};

export const emailTemplateSaved = () => {
  return {
    type: EMAIL_TEMPLATE_SAVED,
  };
};
export const emailTemplateSaveFailed = () => {
  return {
    type: EMAIL_TEMPLATE_SAVE_FAILED,
  };
};

export const initEmailTemplateDelete = (id, userId) => {
  return {
    type: INIT_EMAIL_TEMPLATE_DELETE,
    id,
    userId,
    // deleteSuccess
  };
};
export const deleteSuccess = (id) => {
  return {
    type: DELETE_SUCCESS,
    id,
  };
};
export const deletFailed = () => {
  return {
    type: DELETE_FAILED,
  };
};

export const resetFormSave = () => {
  return {
    type: RESET_SAVE_STATUS,
  };
};

export const resetEditStatus = () => {
  return {
    type: RESET_EDIT_STATUS,
  };
};

export function downloadedAttachment(data) {
  return {
    type: DOWNLOADED_ATTACHMENT,
    data,
  };
}

export const getDataForEdit = (id, fetchSuccess) => {
  return {
    type: INIT_EMAIL_TEMPLATE_EDIT,
    id,
    callback: fetchSuccess,
  };
};
export const setDataForEdit = (data) => {
  return {
    type: SET_EMAIL_TEMPLATE_EDIT_DATA,
    data,
  };
};
export const updateMutation = (data) => {
  return {
    type: UPDATE_MUTATION,
    data,
  };
};
