import styled from "styled-components";
import { Rows } from "components/index";
import { line } from "globalStyles";
import FloatingControl from "components/Inputs/FloatingInput/style";
import LabeledControl from "components/Inputs/LabeledInput/style";
import InputAddon from "components/InputAddon/index";
import InputControl from "components/Inputs/Input/style";

export const ControlWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  ${FloatingControl} svg {
    width: 2em;
    height: 1em;
    fill: hsl(0, 0%, 60%);
  }
  ${LabeledControl} svg {
    width: 2em;
    height: 1em;
    fill: hsl(0, 0%, 60%);
  }
  &:hover ${InputControl}, &:focus-within ${InputControl} {
    clip-path: inset(-5px 0px -5px -5px);
  }
  &:hover
    ${InputControl},
    &:focus-within
    ${InputControl},
    &:hover
    ${InputAddon},
    &:focus-within
    ${InputAddon} {
    box-shadow: rgb(102 187 255 / 50%) 0.25em 0 0.8rem;
    border-color: var(--primary-clr);
    outline: none;
  }
`;

export const OptionsWrapper = styled(Rows)`
  display: flex;
  background-color: var(--light-clr);
  border: var(--oneDotFivePx) solid var(--input-border-clr);
  position: absolute;
  width: 100%;
  top: 120%;
  border-radius: 0.5em;
  box-shadow: 0 0.125em 2em 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  padding: 0.75em;
  max-height: 16rem;
  overflow: auto;

  ul {
    display: flex;
    flex-direction: column;
  }

  li {
    padding: 0.5em 0.2em;
    border-radius: 0.4em;
    &:focus,
    &:focus-within,
    &:hover {
      background-color: var(--light-grey-clr);
    }
  }

  span {
    text-align: center;
    color: var(--grey-clr);
  }
`;

export const Divider = styled.hr`
  ${line}
  margin: 0.5em 0;
`;
