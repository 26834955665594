import styled from "styled-components";

export const Progressbar = styled.div`
  display: flex;
  margin: 0.5em 0;
  height: 0.625rem;
  overflow: hidden;
  line-height: 0;
  font-size: var(--fs-12);
  background-color: rgb(242, 242, 242);
  border-radius: 0.25rem;
  flex-grow: 1;
`;

export const Progress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  overflow: hidden;
  color: var(--light-clr);
  text-align: center;
  white-space: nowrap;
  background: linear-gradient(
    -90deg,
    hsl(var(--primary-clr-hs) 70%) 0%,
    hsl(var(--primary-clr-hs) 30%) 100%
  );
  transition: width 0.6s ease;
`;
