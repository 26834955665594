import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Modal as SupplierModal,
  Button,
  PrimaryBtn,
  DataTable,
  GridSearch,
} from "components/index";

import { BtnWrapper, DataTableContainer, SearchWrapper } from "./style";
import { selectSupplierLookupCols } from "store/selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { compose } from "redux";

const LookupList = (props) => {
  const { t } = useTranslation();

  const { cols = [] } = props;

  let columns = [];
  if (cols.length) {
    columns = cols
      .sort((curr, prev) => {
        return curr.GridOrder - prev.GridOrder;
      })
      .map((col) => {
        const { ColumnWidth = 100, Label = "", FieldName = "" } = col;
        return {
          name: Label,
          selector: FieldName === "SupplierID" ? "supplierID" : FieldName,
          filterable: true,
          width: ColumnWidth,
          omit: FieldName === "SupplierID",
        };
      });
  }

  return (
    <SupplierModal
      title={t("msp.selectSuppliers")}
      show={props.show}
      onClose={props.onClose}
      scrollable
      preventOutterClickClose
    >
      <SearchWrapper>
        <GridSearch
          onChangeHandler={props.handleLookupSearch}
          value={props.searchValue}
          placeholder={`${t("msp.search")}...`}
          disabled={props.lookupProps.loading}
          hint={props.searchHint}
        />
        <Button sm onClick={props.handleResetLookupSearch}>
          {t("msp.reset")}
        </Button>
      </SearchWrapper>
      <DataTableContainer>
        <DataTable
          {...props.lookupProps}
          columns={props.lookupProps.columns || columns}
          onSelectionChange={props.onSelectionChange}
          onHeaderSelectionChange={props.onHeaderSelectionChange}
          // dataItemKey="SupplierID"
        />
      </DataTableContainer>
      <BtnWrapper>
        <Button sm onClick={props.onClose}>
          {t("msp.cancel")}
        </Button>
        {props.hideSaveBtn ? null : (
          <PrimaryBtn
            sm
            onClick={() =>
              props.handleSaveSupplier(props.lookupProps.selectedRows)
            }
          >
            {t("msp.save")}
          </PrimaryBtn>
        )}
      </BtnWrapper>
    </SupplierModal>
  );
};

LookupList.propTypes = {
  handleLookupSearch: PropTypes.any,
  handleResetLookupSearch: PropTypes.any,
  handleSaveSupplier: PropTypes.func,
  lookupProps: PropTypes.shape({
    columns: PropTypes.any,
    loading: PropTypes.any,
    data: PropTypes.any,
    filterable: PropTypes.any,
    total: PropTypes.any,
    ssr: PropTypes.any,
    onPageChange: PropTypes.any,
    headerSelectionValue: PropTypes.any,
    resetPage: PropTypes.any,
  }),
  onClose: PropTypes.any,
  onHeaderSelectionChange: PropTypes.any,
  onSelectionChange: PropTypes.any,
  searchHint: PropTypes.any,
  searchValue: PropTypes.any,
  show: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  cols: selectSupplierLookupCols(),
});
const withConnect = connect(mapStateToProps);
export default compose(withConnect)(LookupList);
