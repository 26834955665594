import { all, takeEvery, takeLatest, call, put } from "redux-saga/effects";
import {
  getRequestDetails,
  putRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";
import axios from "../../../config/axiosCall";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  INIT_GET_USER_ADDRESS_LIST,
  INIT_GET_USER_ADDRESS_BY_ADDRESS_ID,
  INIT_DELETE_USER_ADDRESS,
  INIT_SAVE_USER_ADDRESS,
  INIT_UPDATE_PASSWORD,
  INIT_UPDATE_SECURITY_QUESTION,
  SAVE_USER_COMPANY_DETAIL_DATA,
  INIT_UPDATE_USER_LANGUAGE,
  INIT_CURRENCY_MAPPING_FETCH,
  INIT_PROFILE_MFA_OTP,
} from "./actionTypes";
import {
  fetchUserAddressList,
  fetchUserAddressListEnd,
  fetchUserAddressByIdData,
  fetchUserAddressByIdEnd,
  deleteUserAddressEnd,
  saveUserAddressEnd,
  updatingPassword,
  updatePasswordFailed,
  updatePasswordSuccess,
  updatingSecurityQuestion,
  updateSecurityQuestionFailed,
  updateSecurityQuestionSuccess,
  saveduserCompanyDetailData,
  updatedUserLanguage,
  updatingUserLanguage,
  currencyMappingFetching,
  currencyMappingFetch,
  currencyMappingFetchCompleted,
  passwordChangeOTPFailed,
  passwordChangeOTPSuccess,
} from "./actions";

import { storage } from "../../../config/storage";

export default function* rootUserProfileSaga() {
  yield all([
    takeLatest(INIT_PROFILE_MFA_OTP, initPassowordChangeOTPSaga),
    takeLatest(INIT_UPDATE_PASSWORD, initUpdatePassword),
    takeEvery(INIT_GET_USER_ADDRESS_LIST, getUserAddressList),
    takeEvery(INIT_GET_USER_ADDRESS_BY_ADDRESS_ID, getUserAddressbyId),
    takeEvery(INIT_DELETE_USER_ADDRESS, deleteUserAddress),
    takeEvery(INIT_SAVE_USER_ADDRESS, saveUserAddress),
    takeEvery(INIT_UPDATE_SECURITY_QUESTION, initUpdateSecurityQuestion),
    takeEvery(SAVE_USER_COMPANY_DETAIL_DATA, saveUserCompanyDetail),
    takeEvery(INIT_UPDATE_USER_LANGUAGE, saveUserLanguageChange),
    takeEvery(INIT_CURRENCY_MAPPING_FETCH, initCurrencyMappingSaga),
  ]);
}

function* getUserAddressList(action) {
  let param = "";
  if (action.id) {
    param = `?userID=${action.id.userId}`;
  }

  const url = `Users/ListUserAddress${param}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchUserAddressList(response.data));
    }
    yield put(fetchUserAddressListEnd());
  } catch (error) {
    yield put(fetchUserAddressListEnd());
    const notification = buildNotification({
      message: "msp.errorOccuredMsg",
      type: "failure",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* getUserAddressbyId(action) {
  const url = `Users/UserAddressByAddressId?userAddressID=${action.data.addressId}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      action.callback(response.data);
      yield put(fetchUserAddressByIdData(response.data));
    }
    yield put(fetchUserAddressByIdEnd());
  } catch (error) {
    yield put(fetchUserAddressByIdEnd());
    const notification = buildNotification({
      message: "msp.errorOccuredMsg",
      type: "failure",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* deleteUserAddress(action) {
  const url = `Users/deleteUserAddress`;
  const requestDetails = { ...putRequestDetails };

  yield (requestDetails.data = { ...action.data });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 201) {
      action.callback();
      const notification = buildNotification({
        message: "msp.userAddressDeleted",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
    }
    yield put(deleteUserAddressEnd());
  } catch (error) {
    yield put(deleteUserAddressEnd());
    const notification = buildNotification({
      message: "msp.errorOccuredMsg",
      type: "failure",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* saveUserAddress(action) {
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = { ...action.data });
  const url = `Users/SaveUserAddress`;
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      action.callback();
      const notification = buildNotification({
        message: "msp.userAddressSaved",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
    }
    yield put(saveUserAddressEnd());
  } catch (error) {
    yield put(saveUserAddressEnd());
    const notification = buildNotification({
      message: "msp.errorOccuredMsg",
      type: "failure",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* initPassowordChangeOTPSaga(action) {
  try {
    const { userName } = JSON.parse(storage.getItem("sessionDetails")) || "";
    const url = "/Login/ResetOldPasswordSendMFAOTP";
    const requestDetails = { ...postRequestDetails };
    requestDetails.data = yield {
      oldPassword: btoa(action.payload.oldPassword),
      newPassword: btoa(action.payload.newPassword),
      confirmPassword: btoa(action.payload.confirmPassword),
      userName,
    };
    yield call(axios, url, requestDetails);

    yield put(passwordChangeOTPSuccess());
  } catch (error) {
    console.log(error);
    yield put(passwordChangeOTPFailed());
  }
}

function* initUpdatePassword(action) {
  const { userName } = JSON.parse(storage.getItem("sessionDetails")) || "";
  yield put(updatingPassword());
  const url = "/Login/ResetOldPassword";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    oldPassword: btoa(action.payload.oldPassword),
    newPassword: btoa(action.payload.newPassword),
    confirmPassword: btoa(action.payload.confirmPassword),
    userName,
    otp: action.payload.otp
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (!response) yield put(updatePasswordFailed());

    if (response && response.status === 202) {
      yield put(updatePasswordSuccess());
      const notification = buildNotification({
        message: "msp.passwordChangedSuccessfully",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
      action.callback && action.callback();
    } else if (response && response.status !== 202) {
      yield put(updatePasswordFailed());
    }
  } catch (error) {
    yield put(updatePasswordFailed());
  }
}

function* initUpdateSecurityQuestion(action) {
  yield put(updatingSecurityQuestion());
  const url = "Users/SaveSecurityQuestion";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    Password: btoa(action.payload.Password),
    securityQuestion: action.payload.securityQuestion,
    securityAnswer: action.payload.securityAnswer,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    // if (!response) yield put(updateSecurityQuestionFailed());

    if (response && response.status === 202) {
      yield put(updateSecurityQuestionSuccess());
      const notification = buildNotification({
        message: "msp.securityQuestionUpdated",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
      action.callback && action.callback();
    } else if (response && response.status !== 202) {
      yield put(updateSecurityQuestionFailed());
    }
  } catch (error) {
    yield put(updateSecurityQuestionFailed());
  }
}

function* saveUserCompanyDetail(action) {
  const url = "/Users/save";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    ...action.data,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      yield put(saveduserCompanyDetailData());
      action.callback(response.statusText);
      const notification = buildNotification({
        message: "msp.companyDetailsSaved",
        type: "success",
      });

      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    } else {
      yield put(saveduserCompanyDetailData());
    }
  } catch (error) {
    yield put(saveduserCompanyDetailData());
  }
}

function* saveUserLanguageChange(action) {
  const { payload, callback } = action || {};
  const { languageId } = payload || {};
  const url = `/Users/UpdateLanguage?languageId=${languageId}`;
  const requestDetails = { ...postRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      yield put(updatingUserLanguage());
      const notification = buildNotification({
        message: "msp.languageChanged",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      callback && callback(response);
    }
    yield put(updatedUserLanguage());
  } catch (error) {
    callback && callback(error);
    yield put(updatedUserLanguage());
  }
}

function* initCurrencyMappingSaga(action) {
  yield put(currencyMappingFetching());
  const url = `Common/listCurrencyMapping?countryID=${action.countryID}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200 || response.status === 204) {
      yield put(currencyMappingFetch(response.data ? response.data : []));
    }
    yield put(currencyMappingFetchCompleted());
  } catch (error) {
    yield put(currencyMappingFetchCompleted());
  }
}
