import React, { Component } from "react";
import NotificationComponent from "components/Layout/Header/SiteActions/Notifications";
import { jsonPrs } from "helper/index";
import { storage } from "config/storage";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/zh-cn';
import 'moment/locale/en-gb';
import 'moment/locale/it';
import 'moment/locale/pt';
import 'moment/locale/ja';
import { getLocale } from "../../helper";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import {
  initGetNotification,
  initGetNotificationPreferences,
  initSubmitNotificationPreferences,
  initUpdateNotification,
  initGetArchivedAnnouncements,
  fetchNotificationPreferences,
  fetchNotificationData,
} from "./store/actions";

import {
  selectNotificationData,
  selectNotificationDataFetchStatus,
  selectNotificationPreferencesFetchStatus,
  selectNotificationPreferences,
  selectArchiveData,
  selectArchiveDataFetchStatus,
} from "./store/selectors";
class Notifications extends Component {
  sessionDetails = jsonPrs(storage.getItem("sessionDetails")) || {};
  userId = this.sessionDetails ? this.sessionDetails.userId : 0;
  baseRoleId = this.sessionDetails ? this.sessionDetails.baseRoleId : 0;
  state = {
    showArchive: false,
  };
  languageId = this.sessionDetails && this.sessionDetails.languageId && this.sessionDetails.languageId !== "" ? this.sessionDetails.languageId : "enGB";
  
  componentDidMount() {
    this.props.getNotificationPreferences(this.userId);
  }
  toggleNotificationMode = () => {
    // if prevState true then call  notification as it will revert on setState
    !this.state.showArchive
      ? this.props.getArchivedAnnouncements()
      : this.props.getNotification();

    this.setState((prevState) => {
      return {
        ...prevState,
        showArchive: !prevState.showArchive,
      };
    });
  };
  togglePreference = (e) => {
    const { checked, name } = e.target;

    const preferences = this.props.preferences.map((element) => ({
      ...element,
      enabled: element.statusID === name ? checked : element.enabled,
    }));
    this.props.setNotificationPref(preferences);
    this.props.submitNotificationPreferences({
      userID: this.userId,
      notificationStatuses: preferences.map((item) => ({
        statusID: item.statusID,
        enabled: item.enabled,
      })),
      createdBy: this.userId,
      statusNotificationList: [null],
    });
  };

  moveToArchive = (data) => {
    const notifications = this.props.notifications.map((item) => {
      return {
        ...item,
        removed: item.announcementID === data.notificationId,
      };
    });
    const updatedNotifications = this.props.notifications.filter((item) => {
      return item.announcementID !== data.notificationId;
    });
    this.props.setNotification(notifications);
    setTimeout(() => {
      this.props.setNotification(updatedNotifications);
    }, 900);

    this.props.updateNotification(data);
  };
  render() {
    const {
      notifications,
      fetchingNotifications,
      preferences,
      archiveData,
      FetchingArchivedData,
    } = this.props || {};
    moment.locale(getLocale(this.languageId));
    const data = this.state.showArchive ? archiveData : notifications;
    const preferenceList = preferences
      ? preferences.map((item) => {
          return {
            label: item.label,
            checked: item.enabled,
            statusID: item.statusID,
          };
        })
      : [];

    return (
      <NotificationComponent
        fetching={fetchingNotifications || FetchingArchivedData}
        showArchive={this.state.showArchive}
        data={
          data
            ? data.map((item) => {
                return {
                  id: item.announcementID,
                  importanceID: item.importanceID,
                  importance: item.importance,
                  notificationType: item.notificationType,
                  removed: item.removed,
                  title: item.message,
                  subtitle: item.title,
                  displayDate: this.state.showArchive
                    ? moment(item.createdDate).format("MM/DD/YYYY")
                    : moment(item.createdDate).fromNow(),
                };
              })
            : []
        }
        preferences={preferenceList}
        onPreferenceToggle={this.togglePreference}
        toggleNotificationMode={this.toggleNotificationMode}
        moveToArchive={this.moveToArchive}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  notifications: selectNotificationData(),
  fetchingNotifications: selectNotificationDataFetchStatus(),
  fetchingPreferences: selectNotificationPreferencesFetchStatus(),
  preferences: selectNotificationPreferences(),
  archiveData: selectArchiveData(),
  FetchingArchivedData: selectArchiveDataFetchStatus(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setNotificationPref: (data) => {
      dispatch(fetchNotificationPreferences(data));
    },
    setNotification: (data) => {
      dispatch(fetchNotificationData(data));
    },
    getNotification: () => {
      dispatch(initGetNotification());
    },
    getNotificationPreferences: (userId) => {
      dispatch(initGetNotificationPreferences(userId));
    },
    submitNotificationPreferences: (data) => {
      dispatch(initSubmitNotificationPreferences(data));
    },
    updateNotification: (data, callback) => {
      dispatch(initUpdateNotification(data, callback));
    },
    getArchivedAnnouncements: () => {
      dispatch(initGetArchivedAnnouncements());
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(withTranslation()(Notifications));
