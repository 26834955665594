import { AttachmentBtn, ResolvedIcon } from "../style";
import { ArrowCircle, Attachment } from "components/SVG/index";
import { A } from "components/index";
export const staticColumns = [
  {
    name: "BulkReferenceID",
    selector: "BulkReferenceID",
    sortable: false,
    omit: true,
  },
  {
    name: " ",
    selector: "isChild",
    sortable: false,
    omit: true,
  },
  {
    name: "flowType",
    selector: "FlowType",
    sortable: false,
    omit: true,
  },
  {
    name: "",
    selector: "InvoiceStatusID",
    sortable: false,
    omit: true,
  },
];
export default function getColumns(
  columnHeaders,
  additionalColumns,
  downloadFuncRef,
  expandFuncRef = () => {},
  hasReviewPermission = () => {}
) {
  let cols = [
    {
      width: "50px",
      selector: `expanded`,
      name: ` `,
      filterable: false,
      sortable: false,
      cell: (props) => {
        const { dataItem } = props;
        const { HasChild = false, expanded = false } = dataItem;
        return (
          <td data-selected>
            {HasChild ? (
              <ResolvedIcon
                {...props}
                onClick={() => {
                  dataItem.expanded = !dataItem.expanded;
                  expandFuncRef(dataItem);
                }}
                expanded={expanded}
              >
                <ArrowCircle />
              </ResolvedIcon>
            ) : null}
          </td>
        );
      },
    },
    {
      width: "50px",
      selector: `   `,
      filterable: false,
      sortable: false,
      cell: ({ dataItem }) => {
        if (!downloadFuncRef) return <td></td>;
        const { SubmissionID } = dataItem;

        return (
          <td style={{ color: "var(--accenture-clr)" }}>
            <AttachmentBtn
              onClick={() => downloadFuncRef(SubmissionID)}
              aria-label="attachmentBtn"
            >
              <Attachment />
            </AttachmentBtn>
          </td>
        );
      },
    },
    {
      name: "msp.submissionID",
      selector: "ReferenceNumber",
      sortable: true,
      filterable: true,
      cell: ({ dataItem }) => {
        let url = "/document-submission/invoice-review";
        let search = "";
        const {
          FlowType = 0,
          SubmissionID,
          BulkReferenceID,
          isChild,
          StatusKey,
        } = dataItem;

        if (FlowType > 0)
          if (FlowType > 2) url = `/document-submission/invoice-generation`;

        if (SubmissionID) search += `?ref=${SubmissionID}`;

        if (BulkReferenceID) search += `&bulkRef=${BulkReferenceID}`;
        const disable = isChild
          ? !(StatusKey === "UPL" || StatusKey === "PRO")
          : false;
        return (
          <td
            style={{
              color: `var(--dark-clr)`,
              fontFamily:
                StatusKey === "WAI" || !url || disable
                  ? "var(--ff-main)"
                  : "var(--ff-medium)",
            }}
          >
            {StatusKey === "WAI" || !url || disable ? (
              dataItem.ReferenceNumber
            ) : (
              <A
                to={{
                  pathname: url,
                  search,
                }}
                style={{
                  color: hasReviewPermission
                    ? "var(--dark-clr)"
                    : "var(--grey-clr)",
                  fontFamily: hasReviewPermission
                    ? "var(--ff-medium)"
                    : "var(--ff-main)",
                  pointerEvents: hasReviewPermission ? "auto" : "none",
                }}
              >
                {dataItem.ReferenceNumber}
              </A>
            )}
          </td>
        );
      },
    },
    ...staticColumns,
  ];
  if (columnHeaders) cols = cols.concat(...columnHeaders);

  if (additionalColumns) cols = cols.concat(...additionalColumns);
  return cols;
}
