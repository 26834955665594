import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  gap: 1em;
`;

export const Message = styled.p`
  color: var(--grey-clr);
  font-size: clamp(var(--fs-12), 4vw, var(--fs-16));
  font-family: graphik;
  font-family: var(--ff-main);
  font-weight: var(--fw-normal);
  margin: 1.25em auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 2em;
  align-items: center;
`;
