import React from "react";
import LabeledInput from "components/Inputs/LabeledInput";
import { Calendar } from "components/SVG/";
const LabeledDateInput = (props) => {
  const isValidDateFormat = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode === 46 || charCode < 45 || charCode > 57) e.preventDefault();
  };
  const propsObj = {
    required: props.required,
    placeholder: props.placeholder,
    label: props.label,
    id: props.id,
    name: props.name,
    autoComplete: "off",
    disabled: props.disabled,
    autoFocus: props.focus,
    showError: props.showError,
    value: props.inputValue || "",
    icon: props.icon,
    onKeyPress: isValidDateFormat,
    maxLength: "10",
    onChange: (e) => {
      props.onInputChange(e.target.value);
    },
    error: props.error,
    onFocus: props.onFocusHandler,
    onBlur: props.onBlurHandler,
    onAddonClick: props.onClick,
  };
  const input = props.customInput ? (
    React.cloneElement(props.customInput, { ...propsObj })
  ) : (
    <LabeledInput
      {...propsObj}
      icon={<Calendar />}
      onChangeHandler={(e) => {
        props.onInputChange(e.target.value);
      }}
    />
  );
  return <>{input}</>;
};

export default LabeledDateInput;
