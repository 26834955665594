import React, { useState } from "react";
import PropTypes from "prop-types";
import { currencyFormatter } from "helper/";
import { Tooltip } from "components/";
import { Coin } from "components/SVG/";
import {
  ContentWrapper,
  TextWrapper,
  Col,
  CoinIconWrapper,
  CurrencyCode,
  TotalValue,
  TotalInvoices,
} from "./style";

const MultiCurrencyDetails = (props) => {
  const { multiCurrencyData, delay } = props || {};
  let data = [];
  let timeout;
  const [active, setActive] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };
  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  data = multiCurrencyData || [];
  return (
    (data && data.length > 0 && (
      <>
        <Col
          mt="auto"
          ref={setReferenceElement}
          onMouseEnter={showTip}
          onMouseLeave={hideTip}
        >
          <CoinIconWrapper>
            <Coin />
          </CoinIconWrapper>
        </Col>
        {active && (
          <Tooltip triggerElem={referenceElement} placement="bottom">
            <ContentWrapper>
              {data &&
                data.map((item) => {
                  return (
                    <>
                      <TextWrapper>
                        <CurrencyCode>
                          {(item && item.currency) || ""}
                        </CurrencyCode>
                        <TotalValue amt margin="0 0.5em">
                          {currencyFormatter(
                            Number(item && item.amount),
                            "en-US",
                            false,
                            false
                          ) || 0}
                        </TotalValue>
                        <TotalInvoices amt margin="0 0 0 auto">
                          {(item && item.noOfInvoice) || 0}
                        </TotalInvoices>
                      </TextWrapper>
                    </>
                  );
                })}
            </ContentWrapper>
          </Tooltip>
        )}
      </>
    )) ||
    null
  );
};

MultiCurrencyDetails.propTypes = {
  multiCurrencyData: PropTypes.any,
  delay: PropTypes.any,
};

export default MultiCurrencyDetails;
