import { put, takeEvery, call, all, fork, take } from "redux-saga/effects";
import { store } from "react-notifications-component";
import { storage } from "config/storage";
import axios from "config/axiosCall";
import { buildNotification } from "config/notification";
import { getRequestDetails, postRequestDetails } from "config/requestHeaders";
import { createUploader } from "helper/fileUploadWatcher";

import {
  fetchingBulkUploadTypes,
  fetchBulkUploadTypes,
  bulkUploadTypesFetchingComplete,
  fetchingRecentUploadedFiles,
  fetchRecentUploadedFiles,
  recentUploadedFilesFetchingComplete,
  fetchingUploadedFilesList,
  fetchUploadedFilesList,
  uploadedFilesListFetchingComplete,
  fetchingTemplate,
  templateFetchingComplete,
  fetchingBulkUploadFile,
  bulkUploadFileFetchingComplete,
  sendingEmail,
  emailSent,
  fetchingBulkUploadFileStatusList,
  bulkUploadFileStatusListFetchingComplete,
  fetchBulkUploadFileStatusList,
  bulkUserUploading,
  bulkUserUploaded,
  setBulkUserUploadProgress,
  bulkUserUploadReset,
  emailSentSuccess,
  emailSentFailed,
} from "./actions";
import {
  INIT_BULK_UPLOAD_TYPES_FETCH,
  INIT_RECENT_UPLOADED_FILES_FETCH,
  INIT_UPLOADED_FILES_LIST_FETCH,
  INIT_TEMPLATE_FETCH,
  INIT_BULK_UPLOAD_FILE_FETCH,
  INIT_SEND_EMAIL,
  INIT_BULK_UPLOAD_FILE_STATUS_LIST_FETCH,
  INIT_BULK_USER_UPLOAD,
} from "./actionTypes";

export default function* watchBulkUpload() {
  yield all([
    takeEvery(INIT_BULK_UPLOAD_TYPES_FETCH, initGetBulkUploadTypesSaga),
    takeEvery(INIT_RECENT_UPLOADED_FILES_FETCH, initGetRecentUploadedFilesSaga),
    takeEvery(INIT_UPLOADED_FILES_LIST_FETCH, initGetUploadedFilesList),
    takeEvery(INIT_TEMPLATE_FETCH, downloadTemplate),
    takeEvery(INIT_BULK_UPLOAD_FILE_FETCH, downloadBulkUploadFile),
    takeEvery(INIT_SEND_EMAIL, sendEmailToBulkUsersSaga),
    takeEvery(
      INIT_BULK_UPLOAD_FILE_STATUS_LIST_FETCH,
      initGetBulkUploadFileStatusList
    ),
    takeEvery(INIT_BULK_USER_UPLOAD, initBulkUserCreationSaga),
  ]);
}

function* initGetBulkUploadTypesSaga() {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const languageId =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "enGB";
  yield put(fetchingBulkUploadTypes());
  const url = `/Users/bulkUploadTypes?languageID=${languageId}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const { data } = response || {};
    yield put(fetchBulkUploadTypes(data || []));
    yield put(bulkUploadTypesFetchingComplete());
  } catch (error) {
    yield put(bulkUploadTypesFetchingComplete());
  }
}

function* initGetRecentUploadedFilesSaga(action) {
  yield put(fetchingRecentUploadedFiles());
  const { langID } = action.payload || {};
  const url = `/Users/recentUploadedFiles?langID=${langID}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const { data } = response || {};
    yield put(fetchRecentUploadedFiles(data || ""));
    yield put(recentUploadedFilesFetchingComplete());
  } catch (error) {
    yield put(recentUploadedFilesFetchingComplete());
  }
}

function* initGetUploadedFilesList(action) {
  yield put(fetchingUploadedFilesList());
  const {
    bulkUploadTypeID: fileType,
    fromDate,
    toDate,
    langID,
    fileStatusID,
  } = action.payload || {};
  let url = `/Users/listUploadedFiles?fileType=${fileType}&langID=${langID}&fromDate=${fromDate}&toDate=${toDate}`;
  if (fileStatusID) {
    url += `&fileStatusID=${parseInt(fileStatusID)}`;
  }
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const { data } = response || {};
    yield put(fetchUploadedFilesList(data || ""));
    yield put(uploadedFilesListFetchingComplete());
  } catch (error) {
    yield put(uploadedFilesListFetchingComplete());
  }
}

function* downloadTemplate(action) {
  yield put(fetchingTemplate());
  const { templateID } = action.payload || {};
  const url = `/Users/downloadTemplate?templateID=${templateID}`;
  const requestDetails = { ...getRequestDetails, responseType: "blob" };
  try {
    const response = yield call(axios, url, requestDetails);
    const { data } = response || {};
    if (data) {
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Template.xlsx");
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "msp.templateDownlodedMsg",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
    yield put(templateFetchingComplete());
  } catch (error) {
    yield put(templateFetchingComplete());
  }
}

function* downloadBulkUploadFile(action) {
  yield put(fetchingBulkUploadFile());
  const { attachmentId, fileName } = action.payload || {};
  const url = `/Users/downloadBulkUploadFile?attachmentId=${attachmentId}`;
  const requestDetails = { ...getRequestDetails, responseType: "blob" };
  try {
    const response = yield call(axios, url, requestDetails);
    const { data } = response || {};
    if (data) {
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "msp.successfullyDownloaded",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
    yield put(bulkUploadFileFetchingComplete());
    if (action.callback) action.callback();
  } catch (error) {
    yield put(bulkUploadFileFetchingComplete());
    if (action.callback) action.callback();
  }
}

function* sendEmailToBulkUsersSaga(action) {
  yield put(sendingEmail());
  const { payload } = action || {};
  const { fileDataID } = payload || {};
  const url = `/Users/sendEmailToBulkUsers?fileDataID=${fileDataID}`;
  const requestDetails = { ...postRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "msp.emailSent",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(emailSent(fileDataID));
      yield put(emailSentSuccess());
    } else {
      yield put(emailSentFailed());
    }
    if (action.callback) action.callback();
  } catch (error) {
    yield put(emailSentFailed());
    if (action.callback) action.callback();
  }
}

function* initGetBulkUploadFileStatusList() {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const languageId =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "enGB";
  yield put(fetchingBulkUploadFileStatusList());
  const url = `/Common/listBulkUploadFileStatus?LanguageID=${languageId}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const data = yield response.data &&
      response.data.map((item) => ({ id: item.id, value: item.label }));
    yield put(fetchBulkUploadFileStatusList(data || ""));
    yield put(bulkUploadFileStatusListFetchingComplete());
  } catch (error) {
    yield put(bulkUploadFileStatusListFetchingComplete());
  }
}

function* watchOnProgress({ chan, progression }) {
  while (true) {
    const data = yield take(chan);
    const { loaded, total } = data;
    const progress = (loaded * 100) / total;
    progression.sent = progress > 90 ? 90 : progress;
    yield put(setBulkUserUploadProgress(progression));
  }
}

function* initBulkUserCreationSaga(action) {
  const { payload, callback } = action || {};
  const url = "/Users/bulkuserCreation";
  const [uploadPromise, chan] = yield createUploader({ payload, url });
  const progression = { sent: 0, uploaded: false };
  yield put(bulkUserUploading(progression));
  yield fork(watchOnProgress, { chan, progression });

  try {
    const response = yield call(() => uploadPromise);
    yield (progression.sent = 100);
    yield (progression.uploaded = true);
    yield put(bulkUserUploaded(progression));

    if (response) {
      const notification = buildNotification({
        message: "msp.recordInsertSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
    yield put(bulkUserUploadReset(progression));
    if (callback) yield callback(response);
  } catch (error) {
    yield put(bulkUserUploadReset(progression));
    if (callback) yield callback(error);
  }
}
