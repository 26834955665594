import styled from "styled-components";
import { errorText } from "../../globalStyles";

const ErrorText = styled.div`
  margin-top: 0.5rem;
  ${errorText};
  width: 100%;
`;

export default ErrorText;
