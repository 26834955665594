import {
  adminRoutes,
  authRoutes,
  commonAuthRoutes,
  InvoiceReview,
  POFlip,
  EForm,
  ResolveRejections,
} from "./routes";

export const generateFilteredRoutes = (
  menus,
  isExtHelpDeskLinkUsed = false
) => {
  const filteredRoute = [
    ...commonAuthRoutes,
    ...adminRoutes,
    ...authRoutes
      .filter((item) => {
        return (
          menus &&
          menus.findIndex(
            (x) =>
              x.pageElementFileName &&
              x.pageElementFileName.trim() === item.key &&
              item.key.trim() &&
              x.accessAllowed
          ) > -1
        );
      })
      .map((menuItem) => {
        const item = menus.find(
          (x) =>
            x.pageElementFileName &&
            x.pageElementFileName.trim() === menuItem.key &&
            menuItem.key.trim()
        );
        return {
          ...menuItem,
          isMenu: item.isMenu,
          pageElementId: item.pageElementId,
          parentPageElementId: item.parentPageElementId,
          order: item.pageElementSortOrder,
          moduleName: item.moduleName,
          name: item.pageElementDisplayName,
          title: item.pageElementDisplayName,
        };
      }),
  ];

  filteredRoute.push(
    {
      path: "/document-submission/invoice-review",
      name: "Invoice Review",
      key: "documentSubmissionReview",
      Component: InvoiceReview,
      title: "Invoice Review",
    },
    {
      path: "/document-submission/purchase-order-selection",
      name: "Invoice Generation",
      key: "DocumentSubmissionInvoiceGeneration",
      Component: POFlip,
      title: "Invoice Generation",
    },
    {
      path: "/document-submission/invoice-generation",
      name: "Invoice Generation",
      key: "DocumentSubmissionInvoiceGeneration",
      Component: EForm,
      title: "Invoice Generation",
    },
    {
      path: "/document-submission/resolve-rejections",
      name: "Document Review",
      key: "DocumentSubmissionResolveRejection",
      Component: ResolveRejections,
      title: "Document Review",
    }
  );
  if (isExtHelpDeskLinkUsed) {
    const serviceNow = authRoutes.find(
      (r) => r.key === "supportOpenServiceNowDetails"
    );
    if (serviceNow) filteredRoute.push(serviceNow);
  }
  return filteredRoute;
};
