import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setInput } from "../store/actions";

export default function useInputSelection() {
  const dispatch = useDispatch();
  return useCallback(
    ({ target }, headerOrLine) => {
      dispatch(setInput({ input: target, headerOrLine }));
    },
    [dispatch]
  );
}
