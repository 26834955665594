import {
  GET_ANNOUNCEMENT_DETAILS,
  UPDATE_ANNOUNCEMENT_DETAILS,
  INIT_NEWS_AND_EVENTS_EDIT,
  INIT_NEWS_AND_EVENTS_EDIT_FETCHING,
  SET_NEWS_AND_EVENTS_EDIT_DATA,
  EDIT_NEWS_AND_EVENTS_DATA_FETCHED,
  INIT_NEWSANDEVENT_DELETE,
  DELETE_SUCCESS,
  DELETE_FAILED,
  SAVE_ANNOUNCEMENT_DATA,
  INIT_ITEMS_FETCH,
  FORM_SUBMISSION_STATUS,
  RESET_FORM_SUBMISSION_STATUS,
  INIT_CATEGORY_LIST_FETCH,
  FETCHING_CATEGORY_LIST,
  FETCH_CATEGORY_LIST,
  CATEGORY_LIST_FETCH_COMPLETE,
  INIT_IMPORTANCE_LIST_FETCH,
  FETCHING_IMPORTANCE_LIST,
  FETCH_IMPORTANCE_LIST,
  IMPORTANCE_LIST_FETCH_COMPLETE,
  SAVE_ANNOUNCEMENT_DATA_COMPLETED,
} from "./actionTypes";

export function getAnnouncementDetails(param) {
  return {
    type: GET_ANNOUNCEMENT_DETAILS,
    param,
  };
}
export function updateAnnouncementDetails(announcementList) {
  return {
    type: UPDATE_ANNOUNCEMENT_DETAILS,
    announcementList,
  };
}
export const getDataForEdit = (id, fetchDataSuccess) => {
  return {
    type: INIT_NEWS_AND_EVENTS_EDIT,
    id,
    fetchDataSuccess,
  };
};
export const getDataForEditFetching = () => {
  return {
    type: INIT_NEWS_AND_EVENTS_EDIT_FETCHING,
  };
};
export const setDataForEdit = (data) => {
  return {
    type: SET_NEWS_AND_EVENTS_EDIT_DATA,
    data,
  };
};
export const editDataFetched = () => {
  return {
    type: EDIT_NEWS_AND_EVENTS_DATA_FETCHED,
  };
};
export const initNewsandEventDelete = (id, userId, callback) => {
  return {
    type: INIT_NEWSANDEVENT_DELETE,
    id,
    userId,
    callback,
  };
};
export const deleteSuccess = (id) => {
  return {
    type: DELETE_SUCCESS,
    id,
  };
};
export const deletFailed = () => {
  return {
    type: DELETE_FAILED,
  };
};
export const saveItemData = (data, saveSuccess) => {
  return {
    type: SAVE_ANNOUNCEMENT_DATA,
    data,
    saveSuccess,
  };
};

export const saveItemDataCompleted = () => {
  return {
    type: SAVE_ANNOUNCEMENT_DATA_COMPLETED,
  };
};

export const initItemData = (id, fetchSuccess) => {
  return {
    type: INIT_ITEMS_FETCH,
    id,
    callback: fetchSuccess,
  };
};

export const formSubmissionStatusAction = (data) => {
  return {
    type: FORM_SUBMISSION_STATUS,
    data: data,
  };
};
export const resetFormSubmissionStatus = () => {
  return {
    type: RESET_FORM_SUBMISSION_STATUS,
  };
};

export const initCategoryListData = (custId) => {
  return {
    type: INIT_CATEGORY_LIST_FETCH,
    custId,
  };
};
export const fetchingCategoryList = () => {
  return {
    type: FETCHING_CATEGORY_LIST,
  };
};
export function fetchCategoryList(data) {
  return {
    type: FETCH_CATEGORY_LIST,
    data,
  };
}
export const categoryListFetchingComplete = () => {
  return {
    type: CATEGORY_LIST_FETCH_COMPLETE,
  };
};
export const initImportanceListData = (custId) => {
  return {
    type: INIT_IMPORTANCE_LIST_FETCH,
    custId,
  };
};
export const fetchingImportanceList = () => {
  return {
    type: FETCHING_IMPORTANCE_LIST,
  };
};
export function fetchImportanceList(data) {
  return {
    type: FETCH_IMPORTANCE_LIST,
    data,
  };
}
export const importanceListFetchingComplete = () => {
  return {
    type: IMPORTANCE_LIST_FETCH_COMPLETE,
  };
};
