import {
  BULK_SUBMITTED_INVOICE_FETCH_FAILED,
  BULK_SUBMITTED_INVOICE_FETCH_SUCCESS,
  CLEAN_INVOICE_DETAILS,
  FETCHING_BULK_SUBMITTED_INVOICE,
  FETCHING_SUBMITTED_INVOICE,
  FETCHING_SUPPORTING_DOCUMENTS,
  GET_LOOKUPS,
  INIT_CHECK_DUPLICATE_INVOICE,
  INIT_FETCH_BULK_SUBMITTED_INVOICE,
  INIT_FETCH_SUBMITTED_INVOICE,
  INIT_FETCH_SUPPORTING_DOCUMENTS,
  INIT_GET_TOLERANCE_VALUE,
  INIT_UPLOAD_SD,
  SET_BULK_BUCKET,
  SET_CURRENT_LOOKUP,
  SET_CURRENT_PAGE,
  SET_DOWNLOADINGS,
  SET_DUPLICATE_INVOICE_STATUS,
  SET_LOOKUPS,
  SET_PAGE_CONFIG,
  SET_TOLERANCE_VALUE,
  SET_UPLOAD_PROGRESS,
  SHOW_LOOKUP_BY_ID,
  SUBMITTED_INVOICE_FETCH_FAILED,
  SUBMITTED_INVOICE_FETCH_SUCCESS,
  SUPPORTING_DOCUMENTS_FAILED,
  SUPPORTING_DOCUMENTS_FETCHED,
  SUPPORTING_DOCUMENTS_UPLOAD_COMPLETED,
  SUPPORTING_DOCUMENTS_UPLOAD_FAILED,
  TOLERANCE_VALUE_FETCH_FAILED,
  UPDATE_LINE_ITEMS,
  UPDATE_RESET_PAGE,
} from "./actionTypes";

export const initFetchSubmittedInvoice = (payload) => ({
  type: INIT_FETCH_SUBMITTED_INVOICE,
  payload,
});
export const fetchingSubmittedInvoice = () => ({
  type: FETCHING_SUBMITTED_INVOICE,
});
export const submittedInvoiceFetchSuccess = (data) => ({
  type: SUBMITTED_INVOICE_FETCH_SUCCESS,
  data,
});
export const submittedInvoiceFetchFailed = () => ({
  type: SUBMITTED_INVOICE_FETCH_FAILED,
});

export const initFetchBulkSubmittedInvoice = (payload) => ({
  type: INIT_FETCH_BULK_SUBMITTED_INVOICE,
  payload,
});
export const fetchingBulkSubmittedInvoice = () => ({
  type: FETCHING_BULK_SUBMITTED_INVOICE,
});
export const bulkSubmittedInvoiceFetchSuccess = (data) => ({
  type: BULK_SUBMITTED_INVOICE_FETCH_SUCCESS,
  data,
});
export const bulkSubmittedInvoiceFetchFailed = () => ({
  type: BULK_SUBMITTED_INVOICE_FETCH_FAILED,
});
export const setCurrentPage = (data) => ({
  type: SET_CURRENT_PAGE,
  data,
});
export const setBulkBucket = (data) => ({
  type: SET_BULK_BUCKET,
  data,
});

export const getLookups = (payload) => ({
  type: GET_LOOKUPS,
  payload,
});
export const setLookups = (data) => ({
  type: SET_LOOKUPS,
  data,
});

export const initDuplicateInvoiceCheck = (payload) => ({
  type: INIT_CHECK_DUPLICATE_INVOICE,
  payload,
});
export const setDuplicateInvoiceCheck = (data) => ({
  type: SET_DUPLICATE_INVOICE_STATUS,
  data,
});
export const showLookupByID = (data) => ({
  type: SHOW_LOOKUP_BY_ID,
  data,
});
export const setCurrentLookup = (data) => ({
  type: SET_CURRENT_LOOKUP,
  data,
});

export const setPageConfig = (data) => ({
  type: SET_PAGE_CONFIG,
  data,
});
export const updateResetPage = (data) => ({
  type: UPDATE_RESET_PAGE,
  data,
});

export const initSuppotingDocumentsFecth = (payload) => ({
  type: INIT_FETCH_SUPPORTING_DOCUMENTS,
  payload,
});

export const suppotingDocumentsFetching = () => ({
  type: FETCHING_SUPPORTING_DOCUMENTS,
});
export const suppotingDocumentsFetchComplete = (data) => ({
  type: SUPPORTING_DOCUMENTS_FETCHED,
  data,
});

export const suppotingDocumentsFetchFailed = () => ({
  type: SUPPORTING_DOCUMENTS_FAILED,
});

export const initUploadSupportingDoc = (payload) => {
  console.log(payload);
  return {
    type: INIT_UPLOAD_SD,
    payload,
  };
};
export const setUploadProgress = (data) => ({
  type: SET_UPLOAD_PROGRESS,
  data,
});

export const supportingDocUploadCompleted = () => ({
  type: SUPPORTING_DOCUMENTS_UPLOAD_COMPLETED,
});
export const supportingDocUploadFailed = () => ({
  type: SUPPORTING_DOCUMENTS_UPLOAD_FAILED,
});

export const setDownloading = (data) => ({
  type: SET_DOWNLOADINGS,
  data,
});
export const initFetchTolaranceValue = (payload) => ({
  type: INIT_GET_TOLERANCE_VALUE,
  payload,
});

export const setTolaranceValue = (data) => ({
  type: SET_TOLERANCE_VALUE,
  data,
});
export const tolaranceValueFecthFailed = () => ({
  type: TOLERANCE_VALUE_FETCH_FAILED,
});
export const updateLineItems = (payload) => ({
  type: UPDATE_LINE_ITEMS,
  payload,
});

export const cleanInvoiceDetails = () => ({
  type: CLEAN_INVOICE_DETAILS,
});
