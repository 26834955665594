export const zeroPad = (num, places) => {
  let zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
};

export const summarySections = [
  {
    key: 562,
    title: "msp.open",
    count: "",
    status: "rgb(213, 0, 47)",
  },
  {
    key: 563,
    title: "msp.inProgress",
    count: "",
    status: "rgb(255, 200, 44)",
  },

  {
    key: 564,
    title: "msp.completed",
    count: "",
    status: "rgb(126, 195, 68)",
  },
];

export const defaultFormData = {
  helpDeskID: 0,
  stateID: 1,
  queryTypeID: 0,
  queryTypeCountryID: 0,
  isRelated: false,
  relatesTo: "",
  ticketID: "",
  additionalFields: [],
  attachmentdetails: [],
};

export const supportQueryStep = {
  queryID: 1,
  helpDeskFields: [],
};

export const getInputControlConfig = (
  attributeName,
  allowMultipleSelection = false,
  isMultiLine = false
) => {
  let config = {
    inputType: "floatingInput",
    attrs: {
      id: "",
      name: "",
      label: "",
      placeholder: "",
    },
  };
  switch (attributeName) {
    case "amount":
    case "numeric":
      config = {
        ...config,
        attrs: {
          ...config.attrs,
          type: "number",
        },
      };
      break;
    case "text":
      config = {
        ...config,
        inputType: isMultiLine ? "floatingTextarea" : "floatingInput",
        attrs: {
          ...config.attrs,
          type: "text",
        },
      };
      break;
    case "time":
    case "date":
      config = {
        ...config,
        inputType: "datepicker",
        attrs: {
          ...config.attrs,
        },
      };
      break;
    case "customlist":
      config = {
        ...config,
        inputType: allowMultipleSelection
          ? "floatingMultiSelect"
          : "floatingSelect",
        attrs: {
          ...config.attrs,
          options: [],
        },
      };
      break;
    default:
      config = {
        ...config,
      };
  }
  return config;
};

export const newRequestsFormControls1 = [
  {
    inputType: "floatingSelect",
    grow: 3,
    attrs: {
      type: "select",
      id: "queryTypeID",
      name: "queryTypeID",
      placeholder: "msp.queryType",
      required: true,
      loading: false,
    },
  },
  {
    inputType: "floatingSelect",
    grow: 3,
    attrs: {
      type: "select",
      id: "queryTypeCountryID",
      name: "queryTypeCountryID",
      placeholder: "msp.countryInvoiced",
      required: true,
      loading: false,
    },
  },
];

export const newRequestsFormControls2 = [
  {
    inputType: "checkbox",
    grow: 10,
    attrs: {
      type: "checkbox",
      id: "isRelated",
      name: "isRelated",
      label: "msp.queryInfo",
      display: true,
    },
  },
  {
    inputType: "floatingInput",
    grow: 3,
    attrs: {
      id: "relatesTo",
      name: "relatesTo",
      placeholder: "msp.existingRequestOrIncidentReferenceNumber",
      // required: true,
      display: false,
    },
  },
];

export const serviceNowFormData = {
  userEmail: "shreyas@servicetest.com",
  queryType: "",
};

export const latestUpdatedData = {
  id: "updates",
  headerText: "msp.latestUpdates",
  refreshText: "msp.refresh",
  data: [],
};

export const overviewData = [
  {
    id: "allRequests",
    actionText: "msp.seeAllRequests",
    height: "13.1rem",
    width: "13.4rem",
    url: "/support/list",
  },
];
