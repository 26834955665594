import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, PrimaryBtn } from "components/";
import { WarningYellow } from "components/SVG/";
import { ButtonWrapper, ContentWrapper, Message, Title } from "./style";

const LogoutMessage = (props) => {
  const { t } = useTranslation();
  return props.show ? (
    <Modal centered noHeader show preventOutterClickClose maxWidth="50vw">
      <ContentWrapper role="Modal">
        <WarningYellow />
        <div>
          <Title>{t("msp.sessionEnd")}</Title>
          <Message>{t("msp.logOffWarning")}</Message>
        </div>
        <ButtonWrapper>
          <PrimaryBtn aria-label="Stay Logged In"
            onClick={() => {
              props.onClose();
            }}
          >
            {t("msp.stayLoggedIn")}
          </PrimaryBtn>
        </ButtonWrapper>
      </ContentWrapper>
    </Modal>
  ) : null;
};

LogoutMessage.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.any,
};

export default LogoutMessage;
