import { put, takeEvery, call, all } from "redux-saga/effects";
import { store } from "react-notifications-component";
import { buildNotification } from "../../../../config/notification";
import axios from "../../../../config/axiosCall";
import {
  getRequestDetails,
  postRequestDetails,
  putRequestDetails,
} from "../../../../config/requestHeaders";

import {
  fetchingCategory,
  fetchCategory,
  initFetchFaqList,
  fetchingFaqList,
  fetchFaqList,
  faqListFetchingComplete,
  fetchingFaqById,
  fetchFaqById,
  faqByIdFetchComplete,
  faqSaved,
  faqSaveFailed,
  deleteFaqSuccess,
  deleteFaqFailed,
  initFetchTrainingMaterialList,
  fetchingTrainingMaterialList,
  fetchTrainingMaterialList,
  trainingMaterialListFetchingComplete,
  fetchingTrainingMaterialById,
  fetchTrainingMaterialById,
  trainingMaterialByIdFetchComplete,
  trainingMaterialSaved,
  trainingMaterialSaveFailed,
  deleteTrainingMaterialSuccess,
  deleteTrainingMaterialFailed,
  initFetchTrainingVideoList,
  fetchingTrainingVideoList,
  fetchTrainingVideoList,
  trainingVideoListFetchingComplete,
  fetchingTrainingVideoById,
  fetchTrainingVideoById,
  trainingVideoByIdFetchComplete,
  trainingVideoSaved,
  trainingVideoSaveFailed,
  deleteTrainingVideoSuccess,
  deleteTrainingVideoFailed,
  categoryFetchComplete,
} from "./action";
import {
  INIT_FETCH_CATEGORY,
  INIT_FETCH_FAQ_LIST,
  INIT_FETCH_FAQ_BY_ID,
  INIT_SAVE_FAQ,
  INIT_DELETE_FAQ,
  INIT_FETCH_TRAINING_MATERIAL_LIST,
  INIT_FETCH_TRAINING_MATERIAL_BY_ID,
  INIT_SAVE_TRAINING_MATERIAL,
  INIT_DELETE_TRAINING_MATERIAL,
  INIT_FETCH_TRAINING_VIDEO_LIST,
  INIT_FETCH_TRAINING_VIDEO_BY_ID,
  INIT_SAVE_TRAINING_VIDEO,
  INIT_DELETE_TRAINING_VIDEO,
} from "./actionTypes";

export default function* watchHelpSaga() {
  yield all([
    takeEvery(INIT_FETCH_CATEGORY, getCategorySaga),
    takeEvery(INIT_FETCH_FAQ_LIST, getFaqListSaga),
    takeEvery(INIT_FETCH_FAQ_BY_ID, getFaqByIdSaga),
    takeEvery(INIT_SAVE_FAQ, initSaveFaq),
    takeEvery(INIT_DELETE_FAQ, deleteFaq),
    takeEvery(INIT_FETCH_TRAINING_MATERIAL_LIST, getTrainingMaterialListSaga),
    takeEvery(INIT_FETCH_TRAINING_MATERIAL_BY_ID, getTrainingMaterialByIdSaga),
    takeEvery(INIT_SAVE_TRAINING_MATERIAL, initSaveTrainingMaterial),
    takeEvery(INIT_DELETE_TRAINING_MATERIAL, deleteTrainingMaterial),
    takeEvery(INIT_FETCH_TRAINING_VIDEO_LIST, getTrainingVideoListSaga),
    takeEvery(INIT_FETCH_TRAINING_VIDEO_BY_ID, getTrainingVideoByIdSaga),
    takeEvery(INIT_SAVE_TRAINING_VIDEO, initSaveTrainingVideo),
    takeEvery(INIT_DELETE_TRAINING_VIDEO, deleteTrainingVideo),
  ]);
}
function* getCategorySaga(action) {
  yield put(fetchingCategory());
  const url = "/CustomListItem/listkeys?customListId=2";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(fetchCategory(response.data));
      yield put(categoryFetchComplete());
    }
  } catch (error) {
    yield put(categoryFetchComplete());

    const notification = buildNotification({
      message: "msp.dataLoadError",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* getFaqListSaga(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langKey =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "";
  let url = `/Help/ListFAQdetails?langKey=${langKey}`;
  if (action.data) {
    const { categoryID } = action.data;
    if (categoryID) {
      url = `/Help/ListFAQdetails?langKey=${langKey}&categoryID=${categoryID}`;
    }
  }
  yield put(fetchingFaqList());
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(fetchFaqList(response.data));
    yield put(faqListFetchingComplete());
  } catch (error) {
    yield put(faqListFetchingComplete());

    const notification = buildNotification({
      message: "msp.dataLoadError",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* getFaqByIdSaga(action) {
  yield put(fetchingFaqById());
  const url = `/Help/FAQdetailsById?HelpItemID=${action.id}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(fetchFaqById(response.data));
    }
    yield put(faqByIdFetchComplete());
  } catch (error) {
    yield put(faqByIdFetchComplete());

    const notification = buildNotification({
      message: "msp.dataLoadError",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* initSaveFaq(action) {
  const url = "/Help/SaveFAQ";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.payload);
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      yield put(faqSaved());
      const notification = buildNotification({
        message: "msp.faqSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      if (action.saveSuccess) {
        action.saveSuccess();
      }
    } else {
      yield put(faqSaveFailed());
    }
  } catch (error) {
    yield put(faqSaveFailed());
  }
}

function* deleteFaq(action) {
  const url = "/Help/deleteFAQ";
  const requestDetails = { ...putRequestDetails };
  yield (requestDetails.data = {
    listOfIds: action.id,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteFaqSuccess(id));
      const notification = buildNotification({
        message: "msp.faqsDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });

      yield put(initFetchFaqList());
    } else {
      yield put(deleteFaqFailed());
    }
  } catch (error) {
    yield put(deleteFaqFailed());
  }
}

function* getTrainingMaterialListSaga(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langKey =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "";
  let url = `/Help/ListTrainingdetails?langKey=${langKey}`;
  if (action.data) {
    const { categoryID } = action.data;
    if (categoryID) {
      url = `/Help/ListTrainingdetails?langKey=${langKey}&categoryID=${categoryID}`;
    }
  }
  const requestDetails = { ...getRequestDetails };
  yield put(fetchingTrainingMaterialList());
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(fetchTrainingMaterialList(response.data));
    yield put(trainingMaterialListFetchingComplete());
  } catch (error) {
    yield put(trainingMaterialListFetchingComplete());

    const notification = buildNotification({
      message: "msp.dataLoadError",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* getTrainingMaterialByIdSaga(action) {
  yield put(fetchingTrainingMaterialById());
  const url = `/Help/TrainingDetailsById?trainingItemID=${action.id}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(fetchTrainingMaterialById(response.data));
    }
    yield put(trainingMaterialByIdFetchComplete());
  } catch (error) {
    yield put(trainingMaterialByIdFetchComplete());

    const notification = buildNotification({
      message: "msp.dataLoadError",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* initSaveTrainingMaterial(action) {
  const url = "/Help/SaveTraining";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.payload);
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      yield put(trainingMaterialSaved());
      const notification = buildNotification({
        message: "msp.trainingMaterialSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      if (action.saveSuccess) {
        action.saveSuccess();
      }
    } else {
      yield put(trainingMaterialSaveFailed());
    }
  } catch (error) {
    yield put(trainingMaterialSaveFailed());
  }
}

function* deleteTrainingMaterial(action) {
  const url = "/Help/deleteTrainingDetails";
  const requestDetails = { ...putRequestDetails };
  yield (requestDetails.data = {
    listOfIds: action.id,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteTrainingMaterialSuccess(id));
      const notification = buildNotification({
        message: "msp.trainingMaterialsDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(initFetchTrainingMaterialList());
    } else {
      yield put(deleteTrainingMaterialFailed());
    }
  } catch (error) {
    yield put(deleteTrainingMaterialFailed());
  }
}

function* getTrainingVideoListSaga(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langKey =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "";
  let url = `/Help/ListTrainingVideos?langKey=${langKey}`;
  if (action.data) {
    const { categoryID } = action.data;
    if (categoryID) {
      url = `/Help/ListTrainingVideos??langKey=${langKey}&categoryID=${categoryID}`;
    }
  }
  const requestDetails = { ...getRequestDetails };
  yield put(fetchingTrainingVideoList());
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(fetchTrainingVideoList(response.data));
    yield put(trainingVideoListFetchingComplete());
  } catch (error) {
    yield put(trainingVideoListFetchingComplete());

    const notification = buildNotification({
      message: "msp.dataLoadError",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* getTrainingVideoByIdSaga(action) {
  yield put(fetchingTrainingVideoById());
  const url = `/Help/TrainingVideoById?trainingVideoID=${action.id}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(fetchTrainingVideoById(response.data));
    }
    yield put(trainingVideoByIdFetchComplete());
  } catch (error) {
    yield put(trainingVideoByIdFetchComplete());

    const notification = buildNotification({
      message: "msp.dataLoadError",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* initSaveTrainingVideo(action) {
  const url = "/Help/SaveTrainingVideo";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.payload);
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      yield put(trainingVideoSaved());
      const notification = buildNotification({
        message: "msp.trainingVideoSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      if (action.saveSuccess) {
        action.saveSuccess();
      }
    } else {
      yield put(trainingVideoSaveFailed());
    }
  } catch (error) {
    yield put(trainingVideoSaveFailed());
  }
}

function* deleteTrainingVideo(action) {
  const url = "/Help/deleteTrainingVideos";
  const requestDetails = { ...putRequestDetails };
  yield (requestDetails.data = {
    listOfIds: action.id,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteTrainingVideoSuccess(id));
      const notification = buildNotification({
        message: "msp.trainingVideosDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(initFetchTrainingVideoList());
    } else {
      yield put(deleteTrainingVideoFailed());
    }
  } catch (error) {
    yield put(deleteTrainingVideoFailed());
  }
}
