import { fromJS } from "immutable";
import {
  INIT_FORGOT_PASSWORD_EMAIL,
  FORGOT_PASSWORD_EMAIL_SUCCESS,
  FORGOT_PASSWORD_EMAIL_FAILURE,
  RESET_PROPS_AFTER_SUCCESS,
  INIT_CHECK_USER_EXIST,
  FETCH_CHECK_USER_EXIST_COMPLETED,
  FETCHING_LIST_SECURITY_QUESTION,
  FETCHED_LIST_SECURITY_QUESTION,
  FETCH_LIST_SECURITY_QUESTION_COMPLETED,
  SUBMITTING_QNA,
  SUBMITTING_QNA_SUCCESS,
  SUBMITTING_QNA_FAILED,
  SUBMITTING_RESET_PASSWORD,
  SUBMITTING_RESET_PASSWORD_SUCCESS,
  SUBMITTING_RESET_PASSWORD_FAILED,
  FETCHING_USER_DETAIL,
  FETCHING_USER_DETAIL_SUCCESS,
  FETCHING_USER_DETAIL_FAILED,
  INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL,
  INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL_SUCCESS,
  INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL_FAILED,
  FORGOT_PASS_MFA_OTP_SENT,
} from "./actionTypes";

export const initialState = fromJS({
  isforgotPasswordEmail: false,
  isUserExist: false,
  fetchingQuesList: false,
  securityQuestionList: [],
  isSubmittedQnA: false,
  isResetPassword: false,
  isUserFetched: false,
  isSubmitPassword: false,
  securityOTP: false,
});

function loginPageReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_FORGOT_PASSWORD_EMAIL:
      return state.set("isforgotPasswordEmail", true);

    case FORGOT_PASSWORD_EMAIL_SUCCESS:
      return state.set("isforgotPasswordEmail", false);

    case FORGOT_PASSWORD_EMAIL_FAILURE:
      return state
        .set("isforgotPasswordEmail", false)
        .set("isUserExist", false)
        .set("fetchingQuesList", false)
        .set("securityQuestionList", false)
        .set("isSubmittedQnA", false)
        .set("isResetPassword", false);

    case RESET_PROPS_AFTER_SUCCESS:
      return state.set("isEmailSent", false);

    case INIT_CHECK_USER_EXIST:
      return state.set("isUserExist", true);

    case FETCH_CHECK_USER_EXIST_COMPLETED:
      return state.set("isUserExist", false);

    case FETCHING_LIST_SECURITY_QUESTION:
      return state.set("fetchingQuesList", true);

    case FETCHED_LIST_SECURITY_QUESTION:
      return state.set("securityQuestionList", action.data);

    case FETCH_LIST_SECURITY_QUESTION_COMPLETED:
      return state.set("fetchingQuesList", false);
    case FORGOT_PASS_MFA_OTP_SENT:
      return state.set("securityOTP", true);
    case SUBMITTING_QNA:
      return state.set("isSubmittedQnA", true);

    case SUBMITTING_QNA_SUCCESS:
      return state.set("isSubmittedQnA", false).set("securityOTP", false);
    case SUBMITTING_QNA_FAILED:
      return state.set("isSubmittedQnA", false);//.set("securityOTP", false);

    case SUBMITTING_RESET_PASSWORD:
      return state.set("isResetPassword", true);

    case SUBMITTING_RESET_PASSWORD_SUCCESS:
      return state.set("isResetPassword", false);

    case SUBMITTING_RESET_PASSWORD_FAILED:
      return state.set("isResetPassword", false);

    case FETCHING_USER_DETAIL:
      return state.set("isUserFetched", true);

    case FETCHING_USER_DETAIL_SUCCESS:
      return state.set("isUserFetched", false);

    case FETCHING_USER_DETAIL_FAILED:
      return state.set("isUserFetched", false);

    case INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL:
      return state.set("isSubmitPassword", true);

    case INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL_SUCCESS:
      return state.set("isSubmitPassword", false);

    case INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL_FAILED:
      return state.set("isSubmitPassword", false);

    default:
      return state;
  }
}

export default loginPageReducer;
