import { fromJS } from "immutable";

import {
  LANGUAGE_FETCHING,
  LANGUAGE_FETCH,
  LANGUAGE_FETCH_COMPLETE,
  SUPPLIER_FIELD_FETCHING,
  SUPPLIER_FIELD_FETCH,
  SUPPLIER_FIELD_FETCH_COMPLETE,
  COUNTRY_FETCHING,
  COUNTRY_FETCH,
  COUNTRY_FETCH_COMPLETE,
  SUBMISSION_FIELDS_FETCHING,
  SUBMISSION_FIELDS_FETCH,
  SUBMISSION_FIELDS_FETCH_COMPLETE,
  TIME_ZONES_FETCHING,
  TIME_ZONES_FETCH,
  TIME_ZONES_FETCH_COMPLETE,
  SUBMISSION_STATUS_FETCHING,
  SUBMISSION_STATUS_FETCH,
  SUBMISSION_STATUS_FETCH_COMPLETE,
  CLIENT_PLACEMENT_CONFIG_FETCHING,
  CLIENT_PLACEMENT_CONFIG_FETCH,
  CLIENT_PLACEMENT_CONFIG_FETCH_COMPLETE,
  VALIDATION_TYPES_FETCHING,
  VALIDATION_TYPES_FETCH,
  VALIDATION_TYPES_FETCHED,
  REGEX_FETCHING,
  REGEX_FETCH,
  REGEX_FETCHED,
  CURRENCY_FETCH,
  CURRENCY_FETCHING,
  CURRENCY_FETCH_COMPLETE,
  DOWNLOAD_FAILED,
  INIT_SET_SELECTED_CURRENCY,
  DOWNLOADING,
  DOWNLOAD_COMPLETE,
  DOWNLOAD,
  SETTING_DEFAULT_CURRENCY,
  SET_DEFAULT_CURRENCY_COMPLETED,
  PREVIEWING,
  PREVIEW,
  PREVIEW_COMPLETE,
  PREVIEW_FAILED,
  FETCHING_SUPPLIER_LOOKUP_LIST,
  FETCH_SUPPLIER_LOOKUP_LIST,
  FETCH_SUPPLIER_LOOKUP_LIST_COMPLETED,
  FETCHING_ACTIVE_COLUMNS,
  ACTIVE_COLUMNS_FETCH_COMPLETE,
  FETCH_ACTIVE_COLUMNS,
  SAVING_ACTIVE_COLUMNS,
  ACTIVE_COLUMNS_SAVED,
  ACTIVE_COLUMNS_FETCH_FAILED,
  ACTIVE_COLUMNS_SAVE_FAILED,
  IS_GLOBAL_SEARCH_ACTIVE,
  INVOICE_STATUS_FETCH,
  INVOICE_STATUS_FETCHING,
  INVOICE_STATUS_FETCH_COMPLETE,
  INIT_SUPPLIER_LOOKUP_LIST,
  INIT_GLOBAL_SEARCH,
  SEARCH_RECORDS_FETCH,
  SEARCH_RECORDS_FETCHING,
  SEARCH_RECORDS_FETCHED,
  RESET_SEARCH,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingLanguage: true,
  languages: [],
  fecthingSupplierField: true,
  supplierFields: [],
  fetchingCountry: true,
  countries: [],
  fetchingSubmissionFields: true,
  submissionFields: [],
  fetchingTimeZones: true,
  timeZones: [],
  fetchingSubmissionStatus: true,
  submissionStatus: [],
  downloading: false,
  download: "",
  isDownloadFailed: false,
  previewing: false,
  preview: "",
  isPreviewFailed: false,
  clientPlacementConfigStatus: false,
  clientPlacementConfig: [],
  fetchingValidationTypes: false,
  validationTypes: [],
  fetchingRegex: false,
  regexList: [],
  fetchingCurrency: false,
  currencyList: [],
  selectedCurrency: "USD",
  selectedCurrencyObj: {},
  setDefaultCurrency: false,
  fetchSupplierLookupList: true,
  supplierLookupList: [],
  totalSupplierCount: 0,
  supplierLookupCols: [],
  fetchingActiveColumns: false,
  activeColumnsList: [],
  isActiveColumnsSaving: false,
  activeColumnsFetchFailed: false,
  activeColumnsSaveFailed: false,
  globalSearchActiveStatus: false,
  searchResults: [],
  searching: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_FETCHING:
      return state.set("fetchingLanguage", true);
    case LANGUAGE_FETCH:
      return state.set("languages", action.data);
    case LANGUAGE_FETCH_COMPLETE: {
      return state.set("fetchingLanguage", false);
    }
    case SUPPLIER_FIELD_FETCHING:
      return state.set("fecthingSupplierField", true);
    case SUPPLIER_FIELD_FETCH:
      return state.set("supplierFields", action.data);
    case SUPPLIER_FIELD_FETCH_COMPLETE: {
      return state.set("fecthingSupplierField", false);
    }

    case COUNTRY_FETCHING:
      return state.set("fetchingCountry", true);
    case COUNTRY_FETCH:
      return state.set("countries", action.data);
    case COUNTRY_FETCH_COMPLETE: {
      return state.set("fetchingCountry", false);
    }

    case SUBMISSION_FIELDS_FETCHING:
      return state.set("fetchingSubmissionFields", true);
    case SUBMISSION_FIELDS_FETCH:
      return state.set("submissionFields", action.data);
    case SUBMISSION_FIELDS_FETCH_COMPLETE: {
      return state.set("fetchingSubmissionFields", false);
    }

    case TIME_ZONES_FETCHING:
      return state.set("fetchingTimeZones", true);
    case TIME_ZONES_FETCH:
      return state.set("timeZones", action.data);
    case TIME_ZONES_FETCH_COMPLETE: {
      return state.set("fetchingTimeZones", false);
    }

    case SUBMISSION_STATUS_FETCHING:
      return state.set("fetchingSubmissionStatus", true);
    case SUBMISSION_STATUS_FETCH:
      return state.set("submissionStatus", fromJS(action.data));
    case SUBMISSION_STATUS_FETCH_COMPLETE: {
      return state.set("fetchingSubmissionFields", false);
    }

    case CLIENT_PLACEMENT_CONFIG_FETCHING:
      return state.set("clientPlacementConfigStatus", true);
    case CLIENT_PLACEMENT_CONFIG_FETCH:
      return state.set("clientPlacementConfig", action.data);
    case CLIENT_PLACEMENT_CONFIG_FETCH_COMPLETE: {
      return state.set("clientPlacementConfigStatus", false);
    }
    case VALIDATION_TYPES_FETCHING: {
      return state.set("fetchingValidationTypes", true);
    }
    case VALIDATION_TYPES_FETCH: {
      return state.set("validationTypes", action.data);
    }
    case VALIDATION_TYPES_FETCHED: {
      return state.set("fetchingValidationTypes", false);
    }

    case REGEX_FETCHING: {
      return state.set("fetchingRegex", true);
    }
    case REGEX_FETCH: {
      return state.set("regexList", action.data);
    }
    case REGEX_FETCHED: {
      return state.set("fetchingRegex", false);
    }

    case CURRENCY_FETCH:
      return state.set("currencyList", action.data);
    case CURRENCY_FETCHING:
      return state.set("fetchingCurrency", true);
    case CURRENCY_FETCH_COMPLETE:
      return state.set("fetchingCurrency", false);

    case DOWNLOADING: {
      return state.set("downloading", true);
    }
    case DOWNLOAD: {
      return state.set("download", action.data);
    }
    case DOWNLOAD_COMPLETE: {
      return state.set("downloading", false);
    }
    case DOWNLOAD_FAILED: {
      return state
        .set("isDownloadFailed", action.data)
        .set("downloading", false);
    }

    case PREVIEWING: {
      return state.set("previewing", true);
    }
    case PREVIEW: {
      return state.set("preview", action.data);
    }
    case PREVIEW_COMPLETE: {
      return state.set("previewing", false);
    }
    case PREVIEW_FAILED: {
      return state.set("isPreviewFailed", action.data).set("previewing", false);
    }

    case INIT_SET_SELECTED_CURRENCY: {
      return state
        .set("selectedCurrency", action.data.key)
        .set("selectedCurrencyObj", action.data);
    }

    case SETTING_DEFAULT_CURRENCY: {
      return state.set("setDefaultCurrency", true);
    }

    case SET_DEFAULT_CURRENCY_COMPLETED: {
      return state.set("setDefaultCurrency", true);
    }

    case INIT_SUPPLIER_LOOKUP_LIST:
      return state.set("supplierLookupList", []).set("totalSupplierCount", 0);

    case FETCHING_SUPPLIER_LOOKUP_LIST: {
      return state.set("fetchingSupplierLookupList", true);
    }
    case FETCH_SUPPLIER_LOOKUP_LIST: {
      return state
        .set("supplierLookupList", action.data.companies)
        .set("totalSupplierCount", action.data.totalSupplierCount)
        .set("supplierLookupCols", action.data.fieldLabels);
    }
    case FETCH_SUPPLIER_LOOKUP_LIST_COMPLETED: {
      return state.set("fetchingSupplierLookupList", false);
    }

    case FETCHING_ACTIVE_COLUMNS: {
      return state.set("fetchingActiveColumns", true);
    }
    case FETCH_ACTIVE_COLUMNS: {
      return state
        .set("fetchingActiveColumns", false)
        .set("activeColumnsList", action.data);
    }
    case ACTIVE_COLUMNS_FETCH_COMPLETE: {
      return state.set("fetchingActiveColumns", false);
    }
    case ACTIVE_COLUMNS_FETCH_FAILED: {
      return state
        .set("fetchingActiveColumns", false)
        .set("activeColumnsFetchFailed", action.data);
    }
    case SAVING_ACTIVE_COLUMNS: {
      return state.set("isActiveColumnsSaving", true);
    }
    case ACTIVE_COLUMNS_SAVED: {
      return state.set("isActiveColumnsSaving", false);
    }
    case ACTIVE_COLUMNS_SAVE_FAILED: {
      return state
        .set("isActiveColumnsSaving", false)
        .set("activeColumnsSaveFailed", action.data);
    }
    case IS_GLOBAL_SEARCH_ACTIVE: {
      return state.set("globalSearchActiveStatus", action.bool);
    }
    case INVOICE_STATUS_FETCH:
      return state.set("invoiceStatus", action.data);
    case INVOICE_STATUS_FETCHING:
      return state.set("fetchingInvoiceStatus", true);
    case INVOICE_STATUS_FETCH_COMPLETE: {
      return state.set("fetchingCurrency", false);
    }
    case INIT_GLOBAL_SEARCH:
      return state.set("searchResults", []);
    case RESET_SEARCH:
      return state.set("searchResults", []);
    case SEARCH_RECORDS_FETCHING:
      return state.set("searching", true);
    case SEARCH_RECORDS_FETCH:
      return state.set("searchResults", action.data);
    case SEARCH_RECORDS_FETCHED:
      return state.set("searching", false);
    default:
      return state;
  }
};
export default reducer;
