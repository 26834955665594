import {
  INIT_NOTIFICATION_LIST,
  FETCH_NOTIFICATION_LIST,
  SAVE_NOTIFICATION_LIST,
  FETCHING_NOTIFICATION_LIST,
} from "./actionTypes";

export const initNotificationsList = (data, listFetchSuccess) => {
  return {
    type: INIT_NOTIFICATION_LIST,
    data,
    callback: listFetchSuccess,
  };
};

export const fetchingNotificationsList = (boolean) => {
  return {
    type: FETCHING_NOTIFICATION_LIST,
    boolean,
  };
};

export const fetchNotificationsList = (data) => {
  return {
    type: FETCH_NOTIFICATION_LIST,
    data,
  };
};

export const saveNotificationList = (data, saveStatus) => {
  return {
    type: SAVE_NOTIFICATION_LIST,
    data,
    callback: saveStatus,
  };
};
