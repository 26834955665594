import styled from "styled-components";

export const ClientInfo = styled.div``;
export const Copyright = styled.p``;
export const FooterLinks = styled.div``;
export const LogoFooter = styled.img``;

const FooterStyled = styled.footer`
  text-align: center;
  width: 100%;
  max-width: 81.25rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  bottom: 0;
  background: var(--light-clr);
  box-shadow: 0 -0.125em 1em 0 rgba(33, 43, 54, 0.1),
    0 -0.0625em 0 0 rgb(239, 242, 245);
  border-radius: 0;
  padding: 1em;
  color: var(--primary-text-clr);
  font-size: var(--fs-12);
  flex-wrap: wrap;
  gap: 0.5em;
  z-index: 2;
  justify-content: flex-start;
  * {
    max-height: 100%;
  }
  ${ClientInfo} {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin: 0 auto;
    img {
      align-self: center;
      height: auto;
      max-height: 1rem;
    }
    h1 {
      color: var(--dark-clr);
      font-family: var(--ff-medium);
      font-size: var(--fs-12);
      font-weight: var(--fw-normal);
    }
  }
  ${Copyright} {
    margin: 0 auto;
    color: var(--dark-clr); //provided by design : [var(--primary-text-clr)]
  }

  ${FooterLinks} {
    display: flex;
    gap: 1em;
    margin: 0 auto;
    a {
      cursor: pointer;
      color: var(--dark-clr); //provided by design : [var(--primary-text-clr)]
      :hover,
      :focus {
        font-family: var(--ff-medium);
        color: var(--primary-clr);
      }
    }
  }
  @media (min-width: 62em) {
    gap: 1em;
    ${ClientInfo} {
      gap: 1em;
      img {
        max-height: 2rem;
      }
      h1 {
        font-size: var(--fs-14);
      }
    }
    ${ClientInfo}, ${Copyright}, ${FooterLinks} {
      margin: 0;
      margin-right: 2rem;
    }
    ${FooterLinks} {
      gap: 2em;
    }
  }
`;

export default FooterStyled;
