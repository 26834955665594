import React, { useEffect, useState } from "react";

import {
  Datepicker,
  FloatingInput,
  Button,
  PrimaryBtn,
  ErrorText,
  Rows,
} from "components";
import moment from "moment";

import { buildNotification } from "config/notification";
import { store } from "react-notifications-component";

import { useTranslation } from "react-i18next";

import { jsonPrs } from "helper/index";
import { storage } from "config/storage";
import {
  DateRangeMobile,
  DateRangeDesktop,
  DateRangeBtnWrapper,
} from "./style";

let timerId;

const DateRange = (props) => {
  const { t } = useTranslation();
  const sessionDetails = jsonPrs(storage.getItem("sessionDetails"));
  const { dateFormat } = sessionDetails || {};

  const defaultStartDate = moment().subtract(30, "days");
  const defaultEndDate = moment();

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const [startDateInput, setStartDateInput] = useState(
    defaultStartDate.format(dateFormat)
  );
  const [endDateInput, setEndDateInput] = useState(
    defaultEndDate.format(dateFormat)
  );

  const minDate = moment().subtract(Number(props.range), "months");
  const maxDate = moment();
  useEffect(() => {
    startDate && setStartDateInput(startDate.format(dateFormat));
    endDate && setEndDateInput(endDate.format(dateFormat));
  }, [startDate, endDate, dateFormat]);

  const resetDates = () => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
  };
  const isValidDate = () => {
    if (startDate && endDate) {
      const isValid =
        moment(startDate).isSameOrBefore(endDate, "day") &&
        moment(startDate).isSameOrAfter(minDate, "day") &&
        moment(endDate).isSameOrBefore(maxDate, "day");

      return isValid;
    }
  };

  const delayFunctionExecution = (funcRef, delay) => {
    timerId && clearTimeout(timerId);

    timerId = setTimeout(funcRef, delay);
  };
  const handleInputDateValidation = ({ name, value }) => {
    const date = moment(value, dateFormat, true);
    let errorMsg = t("msp.invalidDate");
    if (value.length === 10) {
      if (date.isValid()) {
        if (name === "startDateInput") {
          setStartDate(date);
        } else {
          setEndDate(date);
        }
      } else {
        errorMsg = t("msp.invalidDateFormat");
        const notification = buildNotification({
          message: errorMsg,
          type: "danger",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 2000,
          },
        });
        if (name === "startDateInput") {
          setStartDateInput("");
          setStartDate("");
        } else {
          setEndDateInput("");
          setEndDate("");
        }
      }
    } else {
      if (name === "startDateInput") {
        setStartDateInput("");
        setStartDate("");
        // errorMsg = "Invalid start date";
      } else {
        setEndDateInput("");
        setEndDate("");
        // errorMsg = "Invalid end date";
      }
      const notification = buildNotification({
        message: errorMsg,
        type: "danger",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 2000,
        },
      });
    }
  };

  const handleDateInputChange = ({ target }) => {
    const { name, value } = target;
    if (name === "startDateInput") {
      setStartDateInput(value);
    } else {
      setEndDateInput(value);
    }
    delayFunctionExecution(
      () => handleInputDateValidation({ name, value }),
      1500
    );
  };

  const isValidDateFormat = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode === 46 || charCode < 45 || charCode > 57) e.preventDefault();
  };

  const isValid = isValidDate();
  return (
    <>
      <DateRangeMobile>
        <Datepicker
          id="startDateMobileInput"
          name="startDateInput"
          placeholder={t("msp.startDate")}
          dateValue={startDate}
          setDate={({ target }) => setStartDate(target.value)}
          startDate={startDate && startDate.toDate()}
          endDate={endDate && endDate.toDate()}
          selectsStart
          minDate={minDate}
          maxDate={endDate}
        />
        <Datepicker
          id="endDateMobileInput"
          name="endDateInput"
          placeholder={t("msp.endDate")}
          dateValue={endDate}
          setDate={({ target }) => setEndDate(target.value)}
          startDate={startDate && startDate.toDate()}
          endDate={endDate && endDate.toDate()}
          selectsEnd
          minDate={minDate}
          maxDate={endDate}
        />
      </DateRangeMobile>
      <DateRangeDesktop>
        <Rows>
          <FloatingInput
            id="startDateDesktopInput"
            name="startDateInput"
            placeholder={t("msp.startDate")}
            value={startDateInput}
            onChangeHandler={handleDateInputChange}
            aria-label="startDate"
            onKeyPress={isValidDateFormat}
          />
          <Datepicker
            inline={true}
            id="startDate"
            startDate={startDate && startDate.toDate()}
            endDate={endDate && endDate.toDate()}
            dateValue={startDate && startDate.toDate()}
            placeholder={t("msp.selectStartDate")}
            selectsStart
            setDate={({ target }) => setStartDate(target.value)}
            minDate={minDate}
            maxDate={endDate}
          />
        </Rows>
        <Rows>
          <FloatingInput
            id="endDateDesktopInput"
            name="endDateInput"
            placeholder={t("msp.endDate")}
            value={endDateInput}
            onChangeHandler={handleDateInputChange}
            onKeyPress={isValidDateFormat}
          />
          <Datepicker
            inline={true}
            id="endDate"
            startDate={startDate && startDate.toDate()}
            endDate={endDate && endDate.toDate()}
            dateValue={endDate}
            placeholder={t("msp.selectEndDate")}
            selectsEnd
            setDate={({ target }) => setEndDate(target.value)}
            minDate={startDate}
            maxDate={maxDate}
          />
        </Rows>
      </DateRangeDesktop>
      <DateRangeBtnWrapper>
        {!isValid ? (
          <ErrorText as="span">{t("msp.dateRangeInvalid")}</ErrorText>
        ) : null}
        <Button sm onClick={resetDates}>
          {t("msp.clear")}
        </Button>
        <PrimaryBtn
          sm
          disabled={!isValid}
          onClick={
            isValid
              ? () => {
                  props.applyDateRangeFilter &&
                    props.applyDateRangeFilter({ startDate, endDate });
                }
              : () => false
          }
        >
          {t("msp.apply")}
        </PrimaryBtn>
      </DateRangeBtnWrapper>
    </>
  );
};

export default DateRange;
