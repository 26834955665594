import {
  INIT_DS_SAVE,
  RESET_UPLOAD_INVOICE,
  SET_BACKUP_UPLOAD_INVOICE_FILES,
  SET_CURRENT_INVOICE_REF,
  SET_EDIT_MODE,
  SET_NON_PO_BULK_SUPPLIER_ID,
  SET_SELECTED_CARD,
  SET_SELECT_ALL,
  SET_UPLOAD_INVOICE_FILES,
  SET_UPLOAD_PROGRESS,
  SET_VIEW_MODE,
} from "./actionTypes";

export const setUploadInvoiceFiles = (data) => ({
  type: SET_UPLOAD_INVOICE_FILES,
  data,
});

export const setBackupUploadInvoiceFiles = (data) => ({
  type: SET_BACKUP_UPLOAD_INVOICE_FILES,
  data,
});
export const setNonPOBulkSupplierID = (data) => ({
  type: SET_NON_PO_BULK_SUPPLIER_ID,
  data,
});

export const setEditMode = (data) => ({
  type: SET_EDIT_MODE,
  data,
});
export const setViewMode = (data) => ({
  type: SET_VIEW_MODE,
  data,
});
export const setSelectedCard = (data) => ({
  type: SET_SELECTED_CARD,
  data,
});

export const setSelectAll = (data) => ({
  type: SET_SELECT_ALL,
  data,
});
export const setCurrentInvoiceRef = (data) => ({
  type: SET_CURRENT_INVOICE_REF,
  data,
});

export const initInvoiceUpload = (data) => ({
  type: INIT_DS_SAVE,
  data,
});

export const setUploadProgress = (data) => {
  return {
    type: SET_UPLOAD_PROGRESS,
    data,
  };
};

export const resetUploadInvoice = () => ({ type: RESET_UPLOAD_INVOICE });
