import {
  INIT_MODULE_LIST_FETCH,
  FETCHING_MODULE_LIST,
  FETCH_MODULE_LIST,
  MODULE_LIST_FETCH_COMPLETE,
  ATTRIBUTE_TYPE_LIST_FETCH_COMPLETE,
  FETCH_ATTRIBUTE_TYPE_LIST,
  FETCHING_ATTRIBUTE_TYPE_LIST,
  INIT_ATTRIBUTE_TYPE_LIST_FETCH,
  FETCHING_ATTRIBUTES_LIST,
  FETCH_ATTRIBUTES_LIST,
  ATTRIBUTES_LIST_FETCH_COMPLETE,
  INIT_ATTRIBUTES_LIST_FETCH,
  INIT_GET_CUSTOM_LIST,
  CUSTOM_LIST_FETCH_COMPLETE,
  FETCH_CUSTOM_LIST,
  FETCHING_CUSTOM_LIST,
  INIT_GET_VMD_TAB_DATA,
  FETCHING_VMD_TAB_DATA,
  FETCH_VMD_TAB_DATA,
  VMD_TAB_DATA_FETCH_COMPLETE,
  INIT_GET_VMD_SECTION_DATA,
  FETCHING_VMD_SECTION_DATA,
  FETCH_VMD_SECTION_DATA,
  VMD_SECTION_DATA_FETCH_COMPLETE,
  INIT_GET_SUBMISSION_FIELD_PLACEMENT,
  FETCHING_SUBMISSION_FIELD_PLACEMENT,
  FETCH_SUBMISSION_FIELD_PLACEMENT,
  SUBMISSION_FIELD_PLACEMENT_FETCH_COMPLETE,
  INIT_GET_SEARCH_FIELD_PLACEMENT,
  SEARCH_FIELD_PLACEMENT_FETCH_COMPLETE,
  FETCH_SEARCH_FIELD_PLACEMENT,
  FETCHING_SEARCH_FIELD_PLACEMENT,
  INIT_ATTRIBUTES_DELETE,
  ATTRIBUTES_DELETE_SUCCESS,
  ATTRIBUTES_DELETE_FAILED,
  INIT_ATTRIBUTE_EDIT,
  ATTRIBUTE_SAVE_FAILED,
  ATTRIBUTE_SAVED,
  INIT_ATTRIBUTE_SAVE,
  INIT_GET_APPROVAL_TYPE_LIST,
  FETCHING_APPROVAL_TYPE_LIST,
  FETCH_APPROVAL_TYPE_LIST,
  INIT_GET_REGEX_LIST,
  FETCHING_REGEX_LIST,
  FETCH_REGEX_LIST,
  REGEX_LIST_FETCH_COMPLETE,
  APPROVAL_TYPE_LIST_FETCH_COMPLETE,
  INIT_GET_VALIDATION_TYPE,
  FETCHING_VALIDATION_TYPE,
  FETCH_VALIDATION_TYPE,
  VALIDATION_TYPE_FETCH_COMPLETE,
  SET_EDIT_ATTRIBUTE_DATA_COMPLETED,
  INIT_GET_CREATE_COMPANY_SECTION_DATA,
  FETCHING_CREATE_COMPANY_SECTION_DATA,
  FETCH_CREATE_COMPANY_SECTION_DATA,
  CREATE_COMPANY_SECTION_DATA_FETCH_COMPLETE,
} from "./actionTypes";

export const initFetchModuleList = () => {
  return {
    type: INIT_MODULE_LIST_FETCH,
  };
};

export const fetchingModuleList = () => {
  return {
    type: FETCHING_MODULE_LIST,
  };
};

export const fetchModuleList = (data) => {
  return {
    type: FETCH_MODULE_LIST,
    data: data,
  };
};

export const moduleListFetchingComplete = () => {
  return {
    type: MODULE_LIST_FETCH_COMPLETE,
  };
};

export const initFetchAttributeTypeList = () => {
  return {
    type: INIT_ATTRIBUTE_TYPE_LIST_FETCH,
  };
};

export const fetchingAttributeTypeList = () => {
  return {
    type: FETCHING_ATTRIBUTE_TYPE_LIST,
  };
};

export const fetchAttributeTypeList = (data) => {
  return {
    type: FETCH_ATTRIBUTE_TYPE_LIST,
    data: data,
  };
};

export const attributeTypeListFetchingComplete = () => {
  return {
    type: ATTRIBUTE_TYPE_LIST_FETCH_COMPLETE,
  };
};

export const initFetchAttributesList = () => {
  return {
    type: INIT_ATTRIBUTES_LIST_FETCH,
  };
};

export const fetchingAttributesList = () => {
  return {
    type: FETCHING_ATTRIBUTES_LIST,
  };
};

export const fetchAttributesList = (data) => {
  return {
    type: FETCH_ATTRIBUTES_LIST,
    data: data,
  };
};

export const attributesListFetchingComplete = () => {
  return {
    type: ATTRIBUTES_LIST_FETCH_COMPLETE,
  };
};

export const initGetCustomList = () => {
  return {
    type: INIT_GET_CUSTOM_LIST,
  };
};

export const fetchingCustomList = () => {
  return {
    type: FETCHING_CUSTOM_LIST,
  };
};

export const fetchCustomList = (data) => {
  return {
    type: FETCH_CUSTOM_LIST,
    data: data,
  };
};

export const customListFetchingComplete = () => {
  return {
    type: CUSTOM_LIST_FETCH_COMPLETE,
  };
};

export const initGetVMDTabData = () => {
  return {
    type: INIT_GET_VMD_TAB_DATA,
  };
};

export const fetchingVMDTabData = () => {
  return {
    type: FETCHING_VMD_TAB_DATA,
  };
};

export const fetchVMDTabData = (data) => {
  return {
    type: FETCH_VMD_TAB_DATA,
    data: data,
  };
};

export const VMDTabDataFetchingComplete = () => {
  return {
    type: VMD_TAB_DATA_FETCH_COMPLETE,
  };
};

export const initGetVMDSectionData = (id) => {
  return {
    type: INIT_GET_VMD_SECTION_DATA,
    id
  };
};

export const fetchingVMDSectionData = () => {
  return {
    type: FETCHING_VMD_SECTION_DATA,
  };
};

export const fetchVMDSectionData = (data) => {
  return {
    type: FETCH_VMD_SECTION_DATA,
    data: data,
  };
};

export const VMDSectionDataFetchingComplete = () => {
  return {
    type: VMD_SECTION_DATA_FETCH_COMPLETE,
  };
};

export const initGetCreateCompanySectionData = () => {
  return {
    type: INIT_GET_CREATE_COMPANY_SECTION_DATA,
  };
};

export const fetchingCreateCompanySectionData = () => {
  return {
    type: FETCHING_CREATE_COMPANY_SECTION_DATA,
  };
};

export const fetchCreateCompanySectionData = (data) => {
  return {
    type: FETCH_CREATE_COMPANY_SECTION_DATA,
    data: data,
  };
};

export const createCompanySectionDataFetchingComplete = () => {
  return {
    type: CREATE_COMPANY_SECTION_DATA_FETCH_COMPLETE,
  };
};

export const initGetSubmissionFieldPlacement = () => {
  return {
    type: INIT_GET_SUBMISSION_FIELD_PLACEMENT,
  };
};

export const fetchingSubmissionFieldPlacement = () => {
  return {
    type: FETCHING_SUBMISSION_FIELD_PLACEMENT,
  };
};

export const fetchSubmissionFieldPlacement = (data) => {
  return {
    type: FETCH_SUBMISSION_FIELD_PLACEMENT,
    data: data,
  };
};

export const SubmissionFieldPlacementFetchingComplete = () => {
  return {
    type: SUBMISSION_FIELD_PLACEMENT_FETCH_COMPLETE,
  };
};

export const initGetSearchFieldPlacement = () => {
  return {
    type: INIT_GET_SEARCH_FIELD_PLACEMENT,
  };
};

export const fetchingSearchFieldPlacement = () => {
  return {
    type: FETCHING_SEARCH_FIELD_PLACEMENT,
  };
};

export const fetchSearchFieldPlacement = (data) => {
  return {
    type: FETCH_SEARCH_FIELD_PLACEMENT,
    data: data,
  };
};

export const SearchFieldPlacementFetchingComplete = () => {
  return {
    type: SEARCH_FIELD_PLACEMENT_FETCH_COMPLETE,
  };
};

export const initAttributesDelete = (id, deleteSuccess) => {
  return {
    type: INIT_ATTRIBUTES_DELETE,
    id,
    deleteSuccess,
  };
};
export const attributesDeleteSuccess = (id) => {
  return {
    type: ATTRIBUTES_DELETE_SUCCESS,
    id,
  };
};
export const attributesDeleteFailed = () => {
  return {
    type: ATTRIBUTES_DELETE_FAILED,
  };
};

export const getDataForAttributeEdit = (id, fetchSuccess) => {
  return {
    type: INIT_ATTRIBUTE_EDIT,
    id,
    callback: fetchSuccess,
  };
};

export const setDataForAttributeEditCompleted = (data) => {
  return {
    type: SET_EDIT_ATTRIBUTE_DATA_COMPLETED,
    data,
  };
};

export const initAttributeSave = (formdata, isEditMode, callback) => {
  return {
    type: INIT_ATTRIBUTE_SAVE,
    formdata,
    isEditMode,
    callback,
  };
};

export const attributeSaved = () => {
  return {
    type: ATTRIBUTE_SAVED,
  };
};
export const attributeSaveFailed = () => {
  return {
    type: ATTRIBUTE_SAVE_FAILED,
  };
};

export const initGetRegExList = () => {
  return {
    type: INIT_GET_REGEX_LIST,
  };
};

export const fetchingRegExList = () => {
  return {
    type: FETCHING_REGEX_LIST,
  };
};

export const fetchRegExList = (data) => {
  return {
    type: FETCH_REGEX_LIST,
    data: data,
  };
};

export const regExListFetchingComplete = () => {
  return {
    type: REGEX_LIST_FETCH_COMPLETE,
  };
};

export const initGetApprovalTypeList = () => {
  return {
    type: INIT_GET_APPROVAL_TYPE_LIST,
  };
};

export const fetchingApprovalTypeList = () => {
  return {
    type: FETCHING_APPROVAL_TYPE_LIST,
  };
};

export const fetchApprovalTypeList = (data) => {
  return {
    type: FETCH_APPROVAL_TYPE_LIST,
    data: data,
  };
};

export const approvalTypeListFetchingComplete = () => {
  return {
    type: APPROVAL_TYPE_LIST_FETCH_COMPLETE,
  };
};

export const initGetValidationType = () => {
  return {
    type: INIT_GET_VALIDATION_TYPE,
  };
};

export const fetchingValidationType = () => {
  return {
    type: FETCHING_VALIDATION_TYPE,
  };
};

export const fetchValidationType = (data) => {
  return {
    type: FETCH_VALIDATION_TYPE,
    data: data,
  };
};

export const validationTypeFetchingComplete = () => {
  return {
    type: VALIDATION_TYPE_FETCH_COMPLETE,
  };
};
