export const passwordRegex =
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[.!@$%&{}:;<>,.?~_=|\\\\-])[A-Za-z\\d.!@$%&{}:;<>,.?~_=|\\\\-]{8,50}$";

export const emailRegex =
  '^(([^<>()\\[\\]\\.,;:\\s@"\\`~!#$%\\^&*+=\\|{}\']+(\\.[^<>()\\[\\]\\.,;:\\s@"\\`~!#$%\\^&*+=\\|{}\']+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

export const telephoneRegex =
  "^(\\([0-9]{3}\\)|[0-9]{3}[-.\\s]?)[0-9]{3}[-.\\s]?[0-9]{4}$";

export const nameRegex =
  "^[A-Za-záÁàÀâÂäÄãÃåÅçÇéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕøØúÚùÙûÛüÜ]+$";

export const stateRegEx =
  "^[ A-Za-záÁàÀâÂäÄãÃåÅçÇéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕøØúÚùÙûÛüÜ]+$";

export const addressRegex =
  "^[A-Za-záÁàÀâÂäÄãÃåÅçÇéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕøØúÚùÙûÛüÜ0-9? ,_-]+$";

export const userNameRegex = "^\\S*$";
