import React from "react";

import { Route, Switch } from "react-router-dom";

import { UserProfile, PageNotFound } from "./routes";
const AuthRoutes = (routes) => {
  const updatedRoutes = [
    ...routes,
    {
      path: "/my-profile",
      name: "User Profile",
      key: "userProfile",
      Component: UserProfile,
      title: "User Profile",
    },

    {
      path: "404",
    },
  ];

  return (
    <Switch>
      {updatedRoutes.map(({ path, Component }, key) => {
        const route =
          path === "404" ? (
            <Route key="404NotFound" component={PageNotFound} />
          ) : (
            <Route exact path={path} key={key} component={Component} />
          );
        return route;
      })}
    </Switch>
  );
};

export default AuthRoutes;
