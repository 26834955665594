import React from "react";
import PropTypes from "prop-types";
import StyledCheckbox from "./style";

const Checkbox = (props) => {
  let id = new Date().getTime();
  if (props.id) id = props.id;
  return (
    <div>
      <StyledCheckbox
        aria-label="checkbox"
        {...props}
        id={id}
        onChange={props.handleToggleCheckbox}
      />
      <label htmlFor={id}>{props.label}</label>
    </div>
  );
};
Checkbox.propTypes = {
  id: PropTypes.any.isRequired,
  label: PropTypes.any,
};
export default Checkbox;
