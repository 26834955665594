import Label from "components/Label";
import styled from "styled-components";

const LabledControl = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${Label} {
    margin-bottom: 0.25em;
    color: ${(props) =>
      props.disabled
        ? "var(--primary-text-clr)"
        : props.error
        ? "var(--error-clr)"
        : "var(--grey-clr)"};
    pointer-events: none;
    font-family: var(--ff-main);
  }
`;

export default LabledControl;
