import { createSelector } from "reselect";
import { initialState } from "./reducer";

export const selectAnnouncements = (state) =>
  state.get("notifications", initialState);

export const selectNotificationData = () =>
  createSelector(selectAnnouncements, (VPState) =>
    VPState.get("notificationData")
  );

export const selectNotificationDataFetchStatus = () =>
  createSelector(selectAnnouncements, (VPState) =>
    VPState.get("notificationDataFetchStatus")
  );
export const selectNotificationPreferencesFetchStatus = () =>
  createSelector(selectAnnouncements, (VPState) =>
    VPState.get("notificationPreferencesFetchStatus")
  );
export const selectNotificationPreferences = () =>
  createSelector(selectAnnouncements, (VPState) =>
    VPState.get("notificationPreferences")
  );
export const selectArchiveData = () =>
  createSelector(selectAnnouncements, (VPState) => VPState.get("archiveData"));
export const selectArchiveDataFetchStatus = () =>
  createSelector(selectAnnouncements, (VPState) =>
    VPState.get("archivedDataFetchStatus")
  );
