import { fromJS } from "immutable";

import {
  BULK_SUBMITTED_INVOICE_FETCH_FAILED,
  BULK_SUBMITTED_INVOICE_FETCH_SUCCESS,
  CLEAN_INVOICE_DETAILS,
  FETCHING_BULK_SUBMITTED_INVOICE,
  FETCHING_SUBMITTED_INVOICE,
  FETCHING_SUPPORTING_DOCUMENTS,
  SET_BULK_BUCKET,
  SET_CURRENT_LOOKUP,
  SET_CURRENT_PAGE,
  SET_DOWNLOADINGS,
  SET_DUPLICATE_INVOICE_STATUS,
  SET_LOOKUPS,
  SET_PAGE_CONFIG,
  SET_TOLERANCE_VALUE,
  SET_UPLOAD_PROGRESS,
  SHOW_LOOKUP_BY_ID,
  SUBMITTED_INVOICE_FETCH_FAILED,
  SUBMITTED_INVOICE_FETCH_SUCCESS,
  SUPPORTING_DOCUMENTS_FAILED,
  SUPPORTING_DOCUMENTS_FETCHED,
  SUPPORTING_DOCUMENTS_UPLOAD_COMPLETED,
  SUPPORTING_DOCUMENTS_UPLOAD_FAILED,
  UPDATE_RESET_PAGE,
  FETCHING_REJECTED_INVOICE,
  REJECTED_INVOICE_FETCH_FAILED,
  REJECTED_INVOICE_FETCH_SUCCESS,
} from "./actionTypes";
import { maxPageSize } from "config/index";

export const initialState = fromJS({
  invoice: {},
  bulkInvoices: [],
  currentPage: 0,
  bulkBucket: {},
  fetching: false,
  fetchingOCRDetails: false,
  lookups: {},
  isDuplicateInvoice: false,
  showLookupByID: {},
  currentLookupSelection: {},
  pageConfig: { pageIndex: 1, pageSize: maxPageSize },
  resetPage: -1,
  supportingDocumets: [],
  fetchnigSupportingDocuments: false,
  uploaded: false,
  uploadProgress: 0,
  downloading: [],
  tolerance: {
    absoluteToleranceValue: null,
    tolerancePercentage: null,
  },
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_SUBMITTED_INVOICE:
    case FETCHING_BULK_SUBMITTED_INVOICE:
    case FETCHING_REJECTED_INVOICE:
      return state.set("fetching", true);
    case SUBMITTED_INVOICE_FETCH_SUCCESS:
    case REJECTED_INVOICE_FETCH_SUCCESS:
      return state.set("invoice", fromJS(action.data)).set("fetching", false);
    case SUBMITTED_INVOICE_FETCH_FAILED:
    case BULK_SUBMITTED_INVOICE_FETCH_FAILED:
    case REJECTED_INVOICE_FETCH_FAILED:
      return state.set("fetching", false);
    case BULK_SUBMITTED_INVOICE_FETCH_SUCCESS:
      return state
        .set("bulkInvoices", fromJS(action.data))
        .set("fetching", false);
    case SET_CURRENT_PAGE:
      return state.set("currentPage", action.data);
    case SET_BULK_BUCKET:
      return state.set("bulkBucket", fromJS(action.data));

    case SET_LOOKUPS:
      return state.set("lookups", fromJS(action.data));

    case SET_DUPLICATE_INVOICE_STATUS:
      return state.set("isDuplicateInvoice", action.data);
    case SHOW_LOOKUP_BY_ID:
      return state.set("showLookupByID", fromJS(action.data));
    case SET_CURRENT_LOOKUP:
      return state.set("currentLookupSelection", fromJS(action.data));

    case SET_PAGE_CONFIG:
      return state.set("pageConfig", fromJS(action.data));
    case UPDATE_RESET_PAGE:
      return state.set("resetPage", action.data);

    case FETCHING_SUPPORTING_DOCUMENTS:
      return state.set("fetchnigSupportingDocuments", true);

    case SUPPORTING_DOCUMENTS_FETCHED:
      return state
        .set("supportingDocumets", fromJS(action.data))
        .set("fetchnigSupportingDocuments", false);
    case SUPPORTING_DOCUMENTS_FAILED:
      return state.set("fetchnigSupportingDocuments", false);

    case SET_UPLOAD_PROGRESS:
      return state.set("uploadProgress", action.data).set("uploaded", false);
    case SUPPORTING_DOCUMENTS_UPLOAD_COMPLETED:
      return state.set("uploaded", true).set("uploadProgress", 0);
    case SUPPORTING_DOCUMENTS_UPLOAD_FAILED:
      return state.set("uploaded", false).set("uploadProgress", 0);
    case SET_DOWNLOADINGS:
      return state.set("downloading", fromJS(action.data));
    case SET_TOLERANCE_VALUE:
      return state.set("tolerance", fromJS(action.data));
    case CLEAN_INVOICE_DETAILS:
      return state
        .set("invoice", fromJS({}))
        .set("bulkInvoices", fromJS([]))
        .set("fetching", false)
        .set("showLookupByID", fromJS({}))
        .set("pageConfig", fromJS({ pageIndex: 1, pageSize: maxPageSize }))
        .set("currentLookupSelection", fromJS({}))
        .set(
          "tolerance",
          fromJS({
            absoluteToleranceValue: null,
            tolerancePercentage: null,
          })
        )
        .set("lookups", fromJS({}))
        .set("supportingDocumets", fromJS([]))
        .set("downloading", fromJS([]))
        .set("isDuplicateInvoice", false)
        .set("resetPage", -1)
        .set("currentPage", 0)
        .set("bulkBucket", fromJS({}));

    default:
      return state;
  }
};
export default reducer;
