import React from "react";
import SvgIcon from "./style";
const DatepickerIcon = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 14 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-417.000000, -162.000000)">
          <g transform="translate(416.000000, 162.000000)">
            <path d="M13.5,1.99999998 L12,1.99999998 L12,0.374999981 C12,0.16874998 11.83125,-2.00000002e-08 11.625,-2.00000002e-08 L11.375,-2.00000002e-08 C11.16875,-2.00000002e-08 11,0.16874998 11,0.374999981 L11,1.99999998 L4.99999998,1.99999998 L4.99999998,0.374999981 C4.99999998,0.16874998 4.83124999,-2.00000002e-08 4.62499999,-2.00000002e-08 L4.37499999,-2.00000002e-08 C4.16874999,-2.00000002e-08 3.99999998,0.16874998 3.99999998,0.374999981 L3.99999998,1.99999998 L2.49999998,1.99999998 C1.67187498,1.99999998 0.999999983,2.67187499 0.999999983,3.49999998 L0.999999983,14.5 C0.999999983,15.328125 1.67187498,16 2.49999998,16 L13.5,16 C14.328125,16 15,15.328125 15,14.5 L15,3.49999998 C15,2.67187499 14.328125,1.99999998 13.5,1.99999998 Z M2.49999999,2.99999999 L13.5,2.99999999 C13.775,2.99999999 14,3.22499999 14,3.49999999 L14,4.99999999 L1.99999999,4.99999999 L1.99999999,3.49999999 C1.99999999,3.22499999 2.22499999,2.99999999 2.49999999,2.99999999 Z M13.5,15 L2.49999999,15 C2.22499999,15 1.99999999,14.775 1.99999999,14.5 L1.99999999,5.99999999 L14,5.99999999 L14,14.5 C14,14.775 13.775,15 13.5,15 Z M8.990625,8.428125 L10.071875,9.509375 C10.1375,9.575 10.1375,9.678125 10.071875,9.740625 L5.97187499,13.840625 L4.87499999,13.996875 C4.65937499,14.028125 4.47187499,13.84375 4.50312499,13.625 L4.65937499,12.528125 L8.759375,8.428125 C8.821875,8.365625 8.928125,8.365625 8.990625,8.428125 Z M11.340625,8.471875 L10.740625,9.071875 C10.675,9.1375 10.571875,9.1375 10.509375,9.071875 L9.428125,7.990625 C9.3625,7.925 9.3625,7.821875 9.428125,7.759375 L10.028125,7.159375 C10.240625,6.946875 10.5875,6.946875 10.8,7.159375 L11.340625,7.7 C11.553125,7.9125 11.553125,8.259375 11.340625,8.471875 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default DatepickerIcon;
