import { createSelector } from "reselect";
import { initialState } from "./reducer";

export const selectVMDModule = (state) => state.get("VMDModule", initialState);

export const selectEmailInviteStatus = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("emailInviteSent"));

export const selectFetchingListStatus = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("fetchingList"));

export const selectListData = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("listData"));

export const selectStatusCounts = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("statusCount"));

export const selectTotalCount = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("totalCount"));

export const selectFetchingEditListStatus = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("fetchingEditList"));

export const selectEditListData = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("editListData"));

export const selectFetchOwnershipStatus = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("fetchOwnership"));

export const selectOwnershipData = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("ownershipData"));

export const selectFetchingAttributesDetailsStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchingAttributesDetails")
  );

export const selectAttributesDetails = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("attributesData"));

export const selectFetchingContactDetailsStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchingContactDetails")
  );

export const selectContactDetails = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("contactData"));

export const selectFetchingLocationDetailsStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchingLocationDetails")
  );

export const selectLocationDetails = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("locationData"));

export const selectFetchingAttachmentDetailsStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchingAttachmentDetails")
  );

export const selectAttachmentDetails = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("attachmentData"));

export const selectFetchingWorkflowStepDataStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchingWorkflowStepData")
  );

export const selectWorkflowStepData = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("workflowData"));

export const selectFetchingDiffDataStatus = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("fetchingDiffData"));

export const selectDiffData = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("diffData"));

export const selectFetchNewCmpnyStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchingNewCmpnyStatus")
  );

export const selectFetchTakeProxyStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchingTakeProxyStatus")
  );

export const selectFetchWorkflowStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchingWorkflowState")
  );

export const selectWorkflowData = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("workflowState"));

export const selectFetchContactDiffStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchingContactDiffData")
  );

export const selectContactDiffData = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("contactDiffData"));

export const selectFetchLocationDiffStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchingLocationDiffData")
  );

export const selectLocationDiffData = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("locationDiffData"));

export const selectInviteRefreshStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("inviteRefreshDone")
  );

export const selectFetchSaveLogoStatus = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("fetchSaveLogo"));

export const selectAuditTrailData = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("auditTrailData"));

export const selectFetchAuditTrailStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchingAuditTrailList")
  );

export const selectAuditTrailDetails = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("auditTrailDetails")
  );

export const selectFetchAuditTrailDetailsStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchingAuditTrailDetails")
  );

export const selectLogoDetails = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("logoData"));

export const selectFetchLogoStatus = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("fetchLogoStatus"));

export const selectFetchClearAuditStatus = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("fetchLogoStatus"));

export const selectAuditTrailList = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("auditTrailList"));

export const selectFetchFlexFieldStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("fetchFlexFieldStatus")
  );

export const selectFlexFields = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("flexFields"));

export const selectRejectRemoveStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("rejectRemoveCompanyStatus")
  );

export const selectRejectStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("rejectCompanyStatus")
  );

export const selectFormData = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("formData"));

export const selectSaveContactStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("saveContactStatus")
  );
export const selectSaveAttachmentStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("saveAttachmentStatus")
  );
export const selectSaveLocationStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("saveLocationStatus")
  );

export const selectUploadProgress = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("uploadProgress"));

export const selectIsEditManageSupplier = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("isEditMode"));

export const selectVmdRequestDataFetchStatus = () =>
  createSelector(selectVMDModule, (VPState) =>
    VPState.get("vmdRequestDataFetchStatus")
  );

export const selectVmdRequestData = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("vmdRequestData"));

export const selectCardOnHandleCancelButton = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("isCancel"));

export const selectShowLoaderOnSave = () =>
  createSelector(selectVMDModule, (VPState) => VPState.get("showLoaderOnSave"));
