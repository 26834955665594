import {
  call,
  put,
  all,
  takeLatest,
  takeEvery,
  select,
} from "redux-saga/effects";
import {
  fetchingGetClientDetails,
  fetchingClientsList,
  fetchClientsList,
  clientsListFetchingComplete,
  fetchGetClientDetails,
  fetchGetClientDetailsCompleted,
  fetchingContactList,
  fetchContactList,
  contactListFetchingComplete,
  contactSaveSuccess,
  contactSaveFailed,
  fetchingCountryContactList,
  fetchCountryContactList,
  countryContactListFetchingComplete,
  contactDeleteSuccess,
  contactDeleteFailed,
  fetchingClientDataById,
  clientDatabyIdFetchingComplete,
  clientDataSaveSuccess,
  clientDataSaveFailed,
  clientHelpdeskDataSaveSuccess,
  clientHelpdeskDataSaveFailed,
  fetchingContactEmailList,
  fetchContactEmailList,
  contactEmailListFetchingComplete,
} from "./actions";
import {
  INIT_GET_CLIENT_DETAILS,
  INIT_GET_CLIENTS_LIST,
  INIT_GET_CONTACT_LIST,
  INIT_SAVE_CONTACT,
  INIT_DELETE_CONTACT,
  INIT_GET_CLIENT_DATA_BY_ID,
  INIT_SAVE_CLIENT_DATA,
  INIT_SAVE_CLIENT_HELPDESK_DATA,
  INIT_GET_CONTACT_EMAIL_LIST,
  INIT_GET_COUNTRY_CONTACT_LIST,
} from "./actionTypes";

import axios from "config/axiosCall";
import { storage } from "config/storage";
import { buildNotification } from "config/notification";
import { store } from "react-notifications-component";
import { getRequestDetails, postRequestDetails } from "config/requestHeaders";
import { selectSessionDetails } from "../../../Authentication/store/selectors";

export default function* rootLoginSaga() {
  yield all([
    takeLatest(INIT_GET_CLIENT_DETAILS, getClientDetailsSaga),
    takeEvery(INIT_GET_CLIENTS_LIST, initGetClientsList),
    takeEvery(INIT_GET_CONTACT_LIST, initGetContactListSaga),
    takeEvery(INIT_SAVE_CONTACT, initSaveContactSaga),
    takeEvery(INIT_DELETE_CONTACT, initDeleteContactSaga),
    takeEvery(INIT_GET_CLIENT_DATA_BY_ID, initGetClientDataByIdSaga),
    takeEvery(INIT_SAVE_CLIENT_DATA, initSaveClientDataSaga),
    takeEvery(INIT_SAVE_CLIENT_HELPDESK_DATA, initSaveClientHelpdeskSaga),
    takeEvery(INIT_GET_CONTACT_EMAIL_LIST, initGetContactEmailListSaga),
    takeEvery(INIT_GET_COUNTRY_CONTACT_LIST, initCountryContactListSaga),
  ]);
}

// get client details
function* getClientDetailsSaga() {
  yield put(fetchingGetClientDetails());
  const url = `/ClientManagement/ClientById?clientId=1`;
  try {
    const response = yield call(axios, url);

    if (response && response.status === 200) {
      const { client } = yield response.data || {};
      const { dateFormat, noOfYears } = client || {};
      const localStorageItem = yield storage.getItem("sessionDetails");
      const minDate = new Date();
      const noOfMonths = noOfYears * 12;
      client && minDate.setMonth(minDate.getMonth() - noOfMonths);
      const updatedLocalStorageItem = yield {
        ...JSON.parse(localStorageItem),
        isExtHelpDeskLinkUsed: client.isExtHelpDeskLinkUsed,
        contactSupportEmailAddress: client.emailAddress,
        contactSupportPhoneNumber: client.phoneNumber,
        defaultMinDate: Date.parse(minDate.toString()),
        dateFormat,
      };
      yield storage.setItem(
        "sessionDetails",
        JSON.stringify(updatedLocalStorageItem)
      );
      yield put(fetchGetClientDetails(response.data));
    }
    yield put(fetchGetClientDetailsCompleted());
  } catch (error) {
    yield put(fetchGetClientDetailsCompleted("Failed to Fetch Data"));
  }
}

function* initGetClientsList() {
  yield put(fetchingClientsList());
  const url = "/ClientManagement/ClientList";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(fetchClientsList(response.data));
    yield put(clientsListFetchingComplete());
  } catch (error) {
    yield put(clientsListFetchingComplete());
  }
}

function* initGetContactListSaga(action) {
  yield put(fetchingContactList());
  let url = "/ClientManagement/ContactList?";
  let filterParamKeys = action.data && Object.keys(action.data);
  filterParamKeys &&
    filterParamKeys.forEach((key) => {
      if (action.data[key]) {
        url += `${key}=${action.data[key]}&`;
      }
    });
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && (response.status === 200 || response.status === 204)) {
      yield put(fetchContactList(response.data));
    }
    yield put(contactListFetchingComplete());
  } catch (error) {
    yield put(contactListFetchingComplete());
  }
}

function* initSaveContactSaga(action) {
  const url = "/ClientManagement/AddContact";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    ...action.data,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    action.callback && action.callback(response);
    if (response && response.status === 202) {
      const message = action.data.contactId
        ? "msp.contactUpdateSuccess"
        : "msp.contactCreateSuccess";
      const notification = buildNotification({
        message,
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
    yield put(contactSaveSuccess());
  } catch (error) {
    yield put(contactSaveFailed());
  }
}

function* initDeleteContactSaga(action) {
  const url = "/ClientManagement/DeleteContact";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    ...action.payload,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    action.callback && action.callback(response);
    if (response && response.status === 201) {
      const notification = buildNotification({
        message: "msp.contactDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      action.callback && action.callback();
    }
    yield put(contactDeleteSuccess());
  } catch (error) {
    yield put(contactDeleteFailed());
  }
}

function* initGetClientDataByIdSaga(action) {
  yield put(fetchingClientDataById());
  const sessionDetails = yield select(selectSessionDetails());
  const { languageId } = sessionDetails;
  const url = `/ClientManagement/ClientById?clientId=${action.clientId}&languageID=${languageId}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    action.callback && action.callback(response);
    yield put(clientDatabyIdFetchingComplete());
  } catch (error) {
    action.callback && action.callback(error);
    yield put(clientDatabyIdFetchingComplete());
  }
}

function* initSaveClientDataSaga(action) {
  const url = "/ClientManagement/AddClient";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = { ...action.formData });
  try {
    const response = yield call(axios, url, requestDetails);
    action.callback && action.callback(response);
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "msp.clientUpdateSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(clientDataSaveSuccess());
    } else {
      yield put(clientDataSaveFailed());
    }
  } catch (error) {
    yield put(clientDataSaveFailed());
  }
}

function* initSaveClientHelpdeskSaga(action) {
  const url = "/ClientManagement/SaveContactCountry";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = { ...action.payload });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      action.callback && action.callback();
      yield put(clientHelpdeskDataSaveSuccess());
      const notification = buildNotification({
        message: "msp.countryContactSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 2000,
        },
      });
    } else {
      yield put(clientHelpdeskDataSaveFailed());
    }
  } catch (error) {
    yield put(clientHelpdeskDataSaveFailed());
  }
}

function* initGetContactEmailListSaga(action) {
  yield put(fetchingContactEmailList());
  let url = "/ClientManagement/ContactEmailList";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchContactEmailList(response.data));
    }
    yield put(contactEmailListFetchingComplete());
  } catch (error) {
    yield put(contactEmailListFetchingComplete());
  }
}

function* initCountryContactListSaga() {
  yield put(fetchingCountryContactList());
  const url = "/ClientManagement/countryContacts";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchCountryContactList(response.data));
    }
    yield put(countryContactListFetchingComplete());
  } catch (error) {
    yield put(countryContactListFetchingComplete());
  }
}
