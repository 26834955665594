import {
  INIT_SUBMISSION_PROFILE_FETCH,
  FETCHING_SUBMISSION_PROFILES,
  FETCH_SUBMISSION_PROFILE,
  SUBMISSION_PROFILE_FETCH_COMPLETE,
  INIT_LIST_HEADER_ATTRS_FETCH,
  FETCHING_LIST_HEADER_ATTRS,
  FETCH_LIST_HEADER_ATTRS,
  LIST_HEADER_ATTRS_FETCH_COMPLETE,
  INIT_LIST_LINE_ATTRS_FETCH,
  FETCHING_LIST_LINE_ATTRS,
  FETCH_LIST_LINE_ATTRS,
  LIST_LINE_ATTRS_FETCH_COMPLETE,
  INIT_SUBMISSION_PROFILE_SAVE,
  SUBMISSION_PROFILE_SAVED,
  SUBMISSION_PROFILE_SAVE_FAILED,
  INIT_SUBMISSION_PROFILES_DELETE,
  DELETE_SUCCESS,
  DELETE_FAILED,
  INIT_SUBMISSION_EDIT,
  SUBMISSION_PROFILE_DELETING,
} from "./actionTypes";

export const initSubmissionProfileList = (payload) => {
  return {
    type: INIT_SUBMISSION_PROFILE_FETCH,
    payload,
  };
};

export const submissionProfilesFetching = () => {
  return {
    type: FETCHING_SUBMISSION_PROFILES,
  };
};
export const submissionProfilesFetch = (data) => {
  return {
    type: FETCH_SUBMISSION_PROFILE,
    data: data,
  };
};
export const submissionProfileFetchComplete = () => {
  return {
    type: SUBMISSION_PROFILE_FETCH_COMPLETE,
  };
};

export const initListHeaderAttrsFetch = () => {
  return {
    type: INIT_LIST_HEADER_ATTRS_FETCH,
  };
};

export const lineHeaderAttrsFetching = () => {
  return {
    type: FETCHING_LIST_HEADER_ATTRS,
  };
};
export const lineHeaderAttrsFetch = (data) => {
  return {
    type: FETCH_LIST_HEADER_ATTRS,
    data: data,
  };
};
export const lineHeaderAttrsFetchComplete = () => {
  return {
    type: LIST_HEADER_ATTRS_FETCH_COMPLETE,
  };
};

export const initListAttrsFetch = () => {
  return {
    type: INIT_LIST_LINE_ATTRS_FETCH,
  };
};

export const lineAttrsFetching = () => {
  return {
    type: FETCHING_LIST_LINE_ATTRS,
  };
};
export const lineAttrsFetch = (data) => {
  return {
    type: FETCH_LIST_LINE_ATTRS,
    data: data,
  };
};
export const lineAttrsFetchComplete = () => {
  return {
    type: LIST_LINE_ATTRS_FETCH_COMPLETE,
  };
};

export const initSubmissionProfileSave = (payload, callback) => {
  return {
    type: INIT_SUBMISSION_PROFILE_SAVE,
    payload,
    callback,
  };
};

export const submisisonProfileSaved = () => {
  return {
    type: SUBMISSION_PROFILE_SAVED,
  };
};
export const submissionProfileSaveFailed = () => {
  return {
    type: SUBMISSION_PROFILE_SAVE_FAILED,
  };
};

export const initSubmissionProfilesDelete = (id) => {
  return {
    type: INIT_SUBMISSION_PROFILES_DELETE,
    id,
  };
};
export const deleting = () => {
  return {
    type: SUBMISSION_PROFILE_DELETING,
  };
};
export const deleteSuccess = (id) => {
  return {
    type: DELETE_SUCCESS,
    id,
  };
};
export const deletFailed = () => {
  return {
    type: DELETE_FAILED,
  };
};
export const getDataForEdit = (id, callback) => {
  return {
    type: INIT_SUBMISSION_EDIT,
    id,
    callback,
  };
};
