import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import {
  CurrencyFormatter,
  IconWrapper,
  MultiCurrencyDetails,
} from "components/index";
import { Arrow } from "components/SVG/index";
import PaymentQuickUpdateStyled, {
  Amount,
  IconGroup,
  Title,
  TtlInvoice,
} from "./style";

import {
  selectLandingPageData,
  selectLandingPageDataFetching,
  selectSelectedDayIndex
} from "container/HomePage/store/selectors";

import { selectSelectedCurrencyObj } from "store/selectors";
import { initLandingPageData, setSelectedDayIndex } from "container/HomePage/store/actions";

const defaultDayRange = [7, 15, 30, 45, 60, 75, 90, 120];

class PaymentQuickUpdate extends Component {
  componentDidMount() {
    //const isNotHome = this.props.history.location.pathname !== "/";
    //if (isNotHome) {
      const { getLandingPageData, selectedCurrency, selectedDayIndex } = this.props;
      const currencyID = selectedCurrency ? selectedCurrency.id : 0;
      const day = defaultDayRange[selectedDayIndex];
      if (day && currencyID) getLandingPageData(day, currencyID);
    //}
  }

  componentDidUpdate(prevProps) {
    this.watchSelectedDay(prevProps);
    //const isNotHome = this.props.history.location.pathname !== '/';
    //if (isNotHome) 
    if (prevProps) this.watchSelectedCurrency(prevProps);
  }

  watchSelectedDay = (prevProps) => {
    const { selectedDayIndex: oldValue } = prevProps || {};
    const { selectedDayIndex: newValue } = this.props || {};

    if (oldValue !== newValue && newValue >= 0) {
      const { selectedCurrency, getLandingPageData } = this.props || {};
      const currencyID = selectedCurrency ? selectedCurrency.id : 0;
      const day = defaultDayRange[newValue];
      getLandingPageData(day, currencyID);
    }
  };

  watchSelectedCurrency = (prevProps) => {
    const { selectedCurrency: oldValue } = prevProps || {};
    const { selectedCurrency: newValue } = this.props || {};

    if (oldValue && newValue && oldValue.id !== newValue.id) {
      const currencyID = newValue ? newValue.id : 0;
      const day = defaultDayRange[this.props.selectedDayIndex];

      this.props.getLandingPageData(day, currencyID);
    }
  };

  render() {
    const { selectedCurrency, landingPageData, selectedDayIndex, setSelectedDayIndex } = this.props || {};
    const code = selectedCurrency ? selectedCurrency.key : "";
    const { expectedPayment, expectedPaymentForAllCurrencies } =
      landingPageData || {};
    const { amount, noOfInvoice } = expectedPayment || {
      noOfInvoice: "",
      amount: "",
    };

    return (
      <PaymentQuickUpdateStyled>
        <Title>
          {this.props && this.props.t("msp.paymentsExpectedIn")}

          <span style={{ marginLeft: "auto" }}>
            {defaultDayRange[selectedDayIndex]}{" "}
            {this.props && this.props.t("msp.Days")}
          </span>
          <IconGroup>
            <IconWrapper
              disabled={selectedDayIndex === defaultDayRange.length - 1}
              onClick={() => setSelectedDayIndex(selectedDayIndex + 1)}
              aria-label="upper-arrow"
            >
              <Arrow />
            </IconWrapper>
            <IconWrapper
              disabled={selectedDayIndex === 0}
              onClick={() => setSelectedDayIndex(selectedDayIndex - 1)}
              aria-label="lower-arrow"
            >
              <Arrow />
            </IconWrapper>
          </IconGroup>
        </Title>
        <Amount>
          <CurrencyFormatter value={amount} code={code} />
        </Amount>
        <TtlInvoice>
          {noOfInvoice || 0} {this.props && this.props.t("msp.Invoices")}
          <MultiCurrencyDetails
            multiCurrencyData={expectedPaymentForAllCurrencies}
          />
        </TtlInvoice>
      </PaymentQuickUpdateStyled>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLandingPageDataFetching: selectLandingPageDataFetching(),
  landingPageData: selectLandingPageData(),
  selectedCurrency: selectSelectedCurrencyObj(),
  selectedDayIndex: selectSelectedDayIndex(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getLandingPageData: (noOfDays, currencyID) => {
      dispatch(initLandingPageData(noOfDays, currencyID));
    },
    setSelectedDayIndex: (index) => {
      dispatch(setSelectedDayIndex(index));
    },
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect(withTranslation()(withRouter(PaymentQuickUpdate)))
);
