export const sessionDetails = {
  isAuthenticated: false,
  userName: "",
  roleId: "",
  baseRoleId: "",
  userId: "",
  loginId: "",
  menus: [],
  token: "",
  expirationDate: "",
  primaryEmail: "",
  lastPwdChangedOn: "",
  passwordExpiryDuration: 0,
  suppliers: [],
  languageId: "",
  defaultMinDate: "",
};

export const userLogoutTimeInSecs = //120; //10;
  (parseInt(process.env.REACT_APP_LOGOUT_TIME_IN_MINS) || 20) * 60;

export const maxPageSize =
  parseInt(process.env.REACT_APP_DATA_TABLE_PER_PAGE) || 10;

export const dbDateFormat = "MM/DD/YYYY";
