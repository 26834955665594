import {
  FETCHED_ADV_PAYMENT_COLS,
  FETCHING_ADV_PAYMENT_COLS,
  FETCH_ADV_PAYMENT_COLS,
  INIT_GET_ADV_PAYMENT_COLS,
} from "./actionTypes";

export const initGetAdvPaymentCols = () => ({
  type: INIT_GET_ADV_PAYMENT_COLS,
});

export const fetchingGetAdvPaymentCols = () => ({
  type: FETCHING_ADV_PAYMENT_COLS,
});

export const fetchGetAdvPaymentCols = (data) => ({
  type: FETCH_ADV_PAYMENT_COLS,
  data: data,
});

export const getAdvPaymentColsFetchingComplete = () => {
  return {
    type: FETCHED_ADV_PAYMENT_COLS,
  };
};
