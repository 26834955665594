import React, { useEffect, useRef } from "react";

import { connect } from "react-redux";
import { compose } from "redux";

import { createStructuredSelector } from "reselect";

import { useTranslation } from "react-i18next";

import { selectSelectedCurrency } from "store/selectors";

import { initSetSelectedCurrency, initSetDefaultCurrency } from "store/actions";
import { selectSessionDetails } from "container/Authentication/store/selectors";
import CurrencyDropdownStyled, { DropdownItem, SetDefaultBtn } from "./style";

const CurrencyDropdown = (props) => {
  const { t } = useTranslation();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      handleSetCurrency({
        key:
          props.sessionDetails && props.sessionDetails.currency
            ? props.sessionDetails.currency
            : "",
        id:
          props.sessionDetails && props.sessionDetails.currencyID
            ? props.sessionDetails.currencyID
            : 0,
      });
    }
  });

  const handleSetCurrency = (currency) => {
    props.setSelectedCurrency && props.setSelectedCurrency(currency);
  };
  const { currencyList } = props;

  return (
    <CurrencyDropdownStyled tabIndex="-1">
      {currencyList && currencyList.length
        ? currencyList.map((curr) => {
            const currencyName = curr.currency;

            return (
              <DropdownItem
                sm
                active={props.selectedCurrency === currencyName}
                default={props.defaultCurrency === currencyName}
                key={curr.currencyID}
                onClick={() =>
                  handleSetCurrency({
                    id: curr.currencyID,
                    key: currencyName,
                  })
                }
              >
                <span>{currencyName}</span>
                <SetDefaultBtn
                  sm
                  onClick={() => props.setDefaultCurrency(curr)}
                >
                  {t("msp.setAsDefault")}
                </SetDefaultBtn>
              </DropdownItem>
            );
          })
        : null}
    </CurrencyDropdownStyled>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCurrency: (data) => {
      dispatch(initSetSelectedCurrency(data));
    },
    setDefaultCurrency: (currency) => {
      dispatch(
        initSetSelectedCurrency({
          id: currency.currencyID,
          key: currency.currency,
        })
      );
      dispatch(initSetDefaultCurrency(currency));
    },
  };
};

const mapStateToProps = createStructuredSelector({
  selectedCurrency: selectSelectedCurrency(),
  sessionDetails: selectSessionDetails(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CurrencyDropdown);
