import { put, takeEvery, call, all } from "redux-saga/effects";
import axios from "../../../../config/axiosCall";
import { getRequestDetails } from "../../../../config/requestHeaders";
import {
  statisticsFetching,
  fetchStatistics,
  statisticsFetchComplete,
} from "../store/actions";

import { INIT_STATISTICS_FETCH } from "./actionTypes";

export default function* watchWorkflow() {
  yield all([takeEvery(INIT_STATISTICS_FETCH, initStatisticsFetchSaga)]);
}

function* initStatisticsFetchSaga(action) {
  const { payload = {} } = action;
  let url = `/Report/statistics`;
  if (payload.fromYear && payload.toYear) {
    url += `?fromYear=${payload.fromYear}&toYear=${payload.toYear}`;
  }
  yield put(statisticsFetching());
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);

    if (response && response.status === 200) {
      yield put(fetchStatistics(response.data));
    }
    yield put(statisticsFetchComplete());
  } catch (error) {
    yield put(statisticsFetchComplete());
  }
}
