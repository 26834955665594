import { ApiConfig } from "./apiConfig";
import axios from "axios";
import { storage } from "./storage";

const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || "";
const clientToken = JSON.parse(storage.getItem("clientDetails")) || "";

const instance = axios.create({
  baseURL: ApiConfig(),
  headers: {
    Authorization: `Bearer ${sessionDetails.token}`,
    "X-App-Info": clientToken.token,
  },
});

export default instance;
