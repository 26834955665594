import { AmtText } from "components/CurrencyFormatter/style";
import { IconWrapper } from "components/index";
import { AuthContainer } from "components/Layout/style";
import PaymentQuickUpdateStyled, {
  Amount,
  IconGroup,
  Title,
  TtlInvoice,
} from "components/PaymentQuickUpdate/style";
import styled from "styled-components";

export const Greetings = styled.p``;

const BannerStyled = styled(AuthContainer)`
  background: rgb(0, 0, 0);
  background: var(--banner-gradient);
  background-size: 100% 100%;
  background-position: center 0;
  box-shadow: 0 0.125em 1em 0 rgb(0 0 0 / 15%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 36em) {
    border-radius: 0 0 1.5em 1.5em;
    background: var(--banner-gradient);
  }
  ${Greetings} {
    display: none;
    font-size: clamp(var(--fs-14), 3.5vw, var(--fs-32));
    color: var(--light-clr);
    font-family: var(--ff-light);
    padding: 0.5em;
    & > strong {
      font-family: var(--ff-medium);
      font-weight: var(--fw-normal);
    }
    @media (min-width: 36em) {
      padding: 1em 0.5em;
      display: unset;
    }
  }
  ${PaymentQuickUpdateStyled} {
    font-size: clamp(var(--fs-12), 2vw, var(--fs-16));
    border-radius: inherit;
    background: hsl(var(--banner-clr-hue) var(--banner-clr-sat) 30%);
    color: var(--light-clr);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    height: 100%;
    padding: 0.5em 1em 0.75em 1em;
    justify-content: space-between;

    @media (min-width: 36em) {
      border-radius: 0 0 1.5em 0;
      flex-direction: column;
      flex-grow: 0;
      gap: 0;
      margin: 0;
    }

    ${Title} {
      /* flex: 1 0 50%; */
      order: 1;
      span,
      ${IconWrapper} {
        color: hsl(var(--banner-clr-hue), var(--banner-clr-sat), 80%);
      }
      ${IconGroup} {
        display: flex;
        flex-direction: column;
        gap: 0;

        ${IconWrapper} {
          :first-child {
            transform: rotate(180deg);
          }
        }
        svg {
          width: 1em;
          height: 1em;
        }
      }

      @media (min-width: 36em) {
        flex: unset;
        order: unset;
        justify-content: flex-start;
      }
    }
    ${TtlInvoice} {
      order: 3;
      /* flex-basis: 30%; */
      line-height: 1;
      margin-top: 0.5em;
      justify-content: space-between;
      display: flex;
      align-items: center;
      gap: 0.5em;

      @media (min-width: 36em) {
        flex: unset;
        order: unset;
      }
    }
    ${Amount} {
      order: 2;
      /* flex-basis: 100%; */
      font-family: var(--ff-light);
      font-size: clamp(var(--fs-18), 2.18vw, var(--fs-32));
      font-weight: var(--fw-normal);

      div {
        svg {
          height: 1em;
          fill: var(--light-clr);
        }
      }

      ${AmtText} small {
        color: var(--light-clr);
        font-family: var(--ff-medium);
      }
    }
    ${Title},${Amount} {
      display: flex;
      align-items: center;
      gap: 0.5em;
      @media (min-width: 36em) {
        flex: unset;
        order: unset;
        justify-content: flex-start;
      }
    }
  }

  @media (min-width: 48em) {
    flex-direction: row;
  }
`;
export default BannerStyled;
