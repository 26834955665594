import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";

import getReducerInjectors from "./utils/reducerInjectors";
import getSagaInjectors from "./utils/sagaInjectors";
import { reducerDesc, sagaDesc } from "./helper/ReducerSagaInjector";

import configureStore from "./configureStore";

import LanguageWrapper from "./context/language-context";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "assets/css/variables.css";
import "assets/css/Fonts.css";
import "assets/css/Scrollbar.css";
import "react-datepicker/dist/react-datepicker.css";
import "components/Datepicker/Datepicker.css";

import { Spinner } from "./components";

if (process.env.NODE_ENV === "production") console.log = () => {};

const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);

if (process.env.NODE_ENV !== "production") {
  // package is lagging
  /* const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000); */
}
const reducerInjector = getReducerInjectors(store);
const sagaInjector = getSagaInjectors(store);
const { injectReducer } = reducerInjector;
const { injectSaga } = sagaInjector;

reducerDesc.map(({ key, reducer }) => injectReducer(key, reducer));
sagaDesc.map(({ key, saga }) => injectSaga(key, { saga }));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<Spinner />}>
    <LanguageWrapper>
      <Provider store={store}>
        <BrowserRouter>
          <StrictMode>
            <App history={history} />
          </StrictMode>
        </BrowserRouter>
      </Provider>
    </LanguageWrapper>
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
