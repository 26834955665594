import styled from "styled-components";

export const TooltipArrow = styled.div`
  width: 0.375em;
  height: 0.375em;
  &::before {
    content: "";
    background: rgb(21, 24, 27);
    width: ${(props) => props.hw || "0.375em"};
    height: ${(props) => props.hw || "0.375em"};
    transform: translate(-45%, -90%) rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
  }
`;

export const TipWrapper = styled.div`
  border-radius: 0.375rem;
  font-weight: 500;
  font-size: var(--fs-12);
  z-index: ${(props) => props.zIndex || 3};

  &[data-popper-placement^="right"] {
    ${TooltipArrow} {
      left: 0;
    }
  }

  &[data-popper-placement^="left"] {
    ${TooltipArrow} {
      right: -5px;
    }
  }

  &[data-popper-placement^="top"] {
    ${TooltipArrow} {
      bottom: -0.375rem;
      &::before {
        border-bottom: var(--onePx) solid rgb(21, 24, 27);
        border-right: var(--onePx) solid rgb(21, 24, 27);
      }
    }
  }

  &[data-popper-placement^="bottom"] {
    ${TooltipArrow} {
      top: 0;
      &::before {
        border-top: var(--onePx) solid rgb(21, 24, 27);
        border-left: var(--onePx) solid rgb(21, 24, 27);
      }
    }
  }
`;

export const TooltipWrapper = styled.div`
  max-width: 22.3125em;
  height: auto;
  background: rgb(21, 24, 27);
  border: var(--onePx) solid rgb(21, 24, 27);
  box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  z-index: 10;
  padding: 1em;
`;

export const TooltipText = styled.span`
  color: rgb(255, 255, 255);
  font-size: var(--fs-12);
  font-family: graphik-medium;
  font-weight: 500;
  letter-spacing: -0.0131rem;
  line-height: 1em;
`;
