import React from "react";
import SvgIcon from "./style";

const ArrowChevron = () => {
  return (
    <SvgIcon
      viewBox="0 0 12 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-1262.000000, -354.000000)">
          <g transform="translate(1248.000000, 344.000000)">
            <path d="M14.4609375,19.3359375 L23.6640625,10.2734375 C24.03125,9.90624997 24.625,9.90624997 24.9921875,10.2734375 L25.546875,10.828125 C25.9140625,11.1953125 25.9140625,11.7890625 25.546875,12.15625 L17.5546875,20 L25.5390625,27.84375 C25.90625,28.2109375 25.90625,28.8046875 25.5390625,29.171875 L24.984375,29.7265625 C24.6171875,30.09375 24.0234375,30.09375 23.65625,29.7265625 L14.453125,20.6640625 C14.09375,20.296875 14.09375,19.703125 14.4609375,19.3359375 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default ArrowChevron;
