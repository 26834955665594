import { call, put, all, takeEvery } from "redux-saga/effects";
import { store } from "react-notifications-component";

import axios from "../../../../config/axiosCall";
import { buildNotification } from "../../../../config/notification";
import { storage } from "config/storage";

import {
  INIT_USER_MANAGEMENT_LIST,
  INIT_SAVE_USER_BY_ID_DATA,
  INIT_USER_BY_ID_DATA,
  INIT_DELETE_USER,
  INIT_USER_ROLE_LIST,
  INIT_REGIONAL_SETTINGS,
  INIT_CHECK_FOR_EXISTING_USER,
  INIT_UPDATE_FAILED_PASSWORD_COUNT,
} from "./actionTypes";

import {
  setUserList,
  fetchUserRoleDropdownList,
  setUserByIdData,
  fetchRegionalSettings,
  userExist,
  fetchUserByIdDataStatus,
  userListFetchStatus,
  fetchUserRoleListStatus,
  deleteUserStatus,
  updateFailedPasswordCount,
} from "./actions";

import {
  getRequestDetails,
  postRequestDetails,
} from "../../../../config/requestHeaders";

export default function* rootUserManagementSaga() {
  yield all([
    takeEvery(INIT_USER_MANAGEMENT_LIST, getUserList),
    takeEvery(INIT_USER_ROLE_LIST, getUserRoleSaga),
    takeEvery(INIT_SAVE_USER_BY_ID_DATA, saveUserDataSaga),
    takeEvery(INIT_USER_BY_ID_DATA, getUserByIdDataSaga),
    takeEvery(INIT_DELETE_USER, deleteUserDataSaga),
    takeEvery(INIT_REGIONAL_SETTINGS, getRegionalSettingsSaga),
    takeEvery(INIT_CHECK_FOR_EXISTING_USER, initCheckForExistingUSerSaga),
    takeEvery(
      INIT_UPDATE_FAILED_PASSWORD_COUNT,
      initUpdatedFailedPasswordCount
    ),
  ]);
}

function* getUserList(action) {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const languageID =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "enGB";
  yield put(userListFetchStatus(true));
  let url = "/Users?approved=true&LanguageID=" + languageID;
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + "&" + data + "=" + action.data[data];
      }
    });
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && (response.status === 200 || response.status === 204)) {
      yield put(userListFetchStatus(false));
      yield put(setUserList(response.data));
    }
  } catch (err) {
    yield put(userListFetchStatus(false));
  }
}

function* getUserRoleSaga() {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const languageID =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "enGB";
  yield put(fetchUserRoleListStatus(true));
  const url = "/Users/roles?LanguageID=" + languageID;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchUserRoleListStatus(false));
      yield put(fetchUserRoleDropdownList(response.data));
    }
  } catch (err) {
    yield put(fetchUserRoleListStatus(false));
  }
}

function* saveUserDataSaga(action) {
  const url = "/Users/save";
  const requestDetails = { ...postRequestDetails };
  const { isLoggedUserHelpdesk, accountLockFieldDataChanged, ...formData } =
    action.data || {};
  yield (requestDetails.data = {
    ...formData,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (isLoggedUserHelpdesk && accountLockFieldDataChanged) {
      yield put(updateFailedPasswordCount(formData.userId));
    }
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "msp.userDetailsSaved",
        type: "success",
      });

      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
      if (action.callback) {
        action.callback(response);
      }
    } else {
      if (action.callback) {
        action.callback(response);
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(error);
    }
  }
}

function* getUserByIdDataSaga(action) {
  yield put(fetchUserByIdDataStatus(true));
  let url = "/Users/userProfileDetails";
  if (action.userId) url = "/Users/userById?userId=" + action.userId;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(setUserByIdData(response.data));
      if (action.callBack) {
        action.callBack(response.data);
      }
      yield put(fetchUserByIdDataStatus(false));
    }
  } catch (err) {
    yield put(fetchUserByIdDataStatus(false));
  }
}

function* deleteUserDataSaga(action) {
  yield put(deleteUserStatus(true));
  const url = "/Users/Delete";
  const requestDetails = { ...postRequestDetails };
  requestDetails.data = {
    ...action.data,
  };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200) {
      yield put(deleteUserStatus(false));
      const notification = buildNotification({
        message: "msp.userDeletedMessage",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      action.callback && action.callback();
    }
  } catch (error) {
    yield put(deleteUserStatus(false));
  }
}

function* getRegionalSettingsSaga() {
  const url = "/Users/ListRegionalSettings";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchRegionalSettings(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* initCheckForExistingUSerSaga(action) {
  const url = "/Users/UserAlreadyExists?userName=" + action.data;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(userExist(response.data));
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* initUpdatedFailedPasswordCount(action) {
  const url = `/Users/updateFailedPasswordCount?userId=${action.userId}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      if (action.callback) {
        action.callback(response.status);
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(error.status);
    }
  }
}
