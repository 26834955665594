import chCH from "../../translations/chCH.json";
import enGB from "../../translations/enGB.json";
import esES from "../../translations/esES.json";
import frFR from "../../translations/frFR.json";
import jpJP from "../../translations/jpJP.json";
/* import csCZ from "./translations/csCZ.json";
import huHU from "./translations/huHU.json";*/
import deDE from "../../translations/deDE.json";


import itIT from "../../translations/itIT.json";
import ptPT from "../../translations/ptPT.json";
/*import krKR from "./translations/krKR.json";
import nlNL from "./translations/nlNL.json";
import plPL from "./translations/plPL.json";
import ptPT from "./translations/ptPT.json";
import roRO from "./translations/roRO.json";
import ruRU from "./translations/ruRU.json";
import slSL from "./translations/slSL.json";
import svSE from "./translations/svSE.json";
import thTH from "./translations/thTH.json";
import trTR from "./translations/trTR.json"; */
export const getTranslationFile = {
  chCH,
  /*csCZ,*/
  deDE, 
  enGB,
  esES,
  frFR,
  /*huHU,*/
  itIT,
  jpJP,
  ptPT,
  /*krKR,
  nlNL,
  plPL,
  ptPT,
  roRO,
  ruRU,
  slSL,
  svSE,
  thTH,
  trTR, */
};
