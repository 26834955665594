// Get Client Details
export const INIT_GET_CLIENT_DETAILS = "INIT_GET_CLIENT_DETAILS";
export const FETCHING_GET_CLIENT_DETAILS = "FETCHING_GET_CLIENT_DETAILS";
export const FETCH_GET_CLIENT_DETAILS = "FETCH_GET_CLIENT_DETAILS";
export const GET_CLIENT_DETAILS_FETCH_COMPLETED =
  "GET_CLIENT_DETAILS_FETCH_COMPLETED";

//Get client list
export const INIT_GET_CLIENTS_LIST = "INIT_GET_CLIENTS_LIST";
export const FETCHING_CLIENTS_LIST = "FETCHING_CLIENTS_LIST";
export const FETCH_CLIENTS_LIST = "FETCH_CLIENTS_LIST";
export const CLIENTS_LIST_FETCHING_COMPLETE = "CLIENTS_LIST_FETCHING_COMPLETE";

export const INIT_GET_CONTACT_LIST = "INIT_GET_CONTACT_LIST";
export const FETCHING_CONTACT_LIST = "FETCHING_CONTACT_LIST";
export const FETCH_CONTACT_LIST = "FETCH_CONTACT_LIST";
export const CONTACT_LIST_FETCHING_COMPLETE = "CONTACT_LIST_FETCHING_COMPLETE";

export const INIT_SAVE_CONTACT = "INIT_SAVE_CONTACT";
export const CONTACT_SAVE_SUCCESS = "CONTACT_SAVE_SUCCESS";
export const CONTACT_SAVE_FAILED = "CONTACT_SAVE_FAILED";

export const INIT_DELETE_CONTACT = "INIT_DELETE_CONTACT";
export const CONTACT_DELETE_SUCCESS = "CONTACT_DELETE_SUCCESS";
export const CONTACT_DELETE_FAILED = "CONTACT_DELETE_FAILED";

export const INIT_GET_CLIENT_DATA_BY_ID = "INIT_GET_CLIENT_DATA_BY_ID";
export const FETCHING_CLIENT_DATA_BY_ID = "FETCHING_CLIENT_DATA_BY_ID";
export const CLIENT_DATA_BY_ID_FETCH_COMPLETE =
  "CLIENT_DATA_BY_ID_FETCH_COMPLETE";

export const INIT_SAVE_CLIENT_DATA = "INIT_SAVE_CLIENT_DATA";
export const CLIENT_DATA_SAVE_SUCCESS = "CLIENT_DATA_SAVE_SUCCESS";
export const CLIENT_DATA_SAVE_FAILED = "CLIENT_DATA_SAVE_FAILED";

export const INIT_GET_CONTACT_EMAIL_LIST = "INIT_GET_CONTACT_EMAIL_LIST";
export const FETCHING_CONTACT_EMAIL_LIST = "FETCHING_CONTACT_EMAIL_LIST";
export const FETCH_CONTACT_EMAIL_LIST = "FETCH_CONTACT_EMAIL_LIST";
export const CONTACT_EMAIL_LIST_FETCHING_COMPLETE =
  "CONTACT_EMAIL_LIST_FETCHING_COMPLETE";

export const INIT_GET_COUNTRY_CONTACT_LIST = "INIT_GET_COUNTRY_CONTACT_LIST";
export const FETCHING_COUNTRY_CONTACT_LIST = "FETCHING_COUNTRY_CONTACT_LIST";
export const FETCH_COUNTRY_CONTACT_LIST = "FETCH_COUNTRY_CONTACT_LIST";
export const COUNTRY_CONTACT_LIST_FETCHING_COMPLETE =
  "COUNTRY_CONTACT_LIST_FETCHING_COMPLETE";

export const INIT_SAVE_CLIENT_HELPDESK_DATA = "INIT_SAVE_CLIENT_HELPDESK_DATA";
export const CLIENT_HELPDESK_DATA_SAVE_SUCCESS =
  "CLIENT_HELPDESK_DATA_SAVE_SUCCESS";
export const CLIENT_HELPDESK_DATA_SAVE_FAILED =
  "CLIENT_HELPDESK_DATA_SAVE_FAILED";
