import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, PrimaryBtn, SecondaryBtn } from "components/index";
import {
  ButtonWrapper,
  ContentWrapper,
  Message,
  WarningText,
  WarningWrapper,
} from "./style";

const WarningMessage = (props) => {
  const { t } = useTranslation();
  return props.show ? (
    <Modal centered noHeader show preventOutterClickClose maxWidth="70vh">
      <ContentWrapper role="Modal">
        <div>
          <Message>
            {t("msp.doubleCheckBeforeSubmitMsg")}:
            <WarningWrapper as="ul" aria-label="warning-messages" aria-hidden="true">
              {props.warningFields &&
                props.warningFields.map((field) => (
                  <div>
                    <WarningText aria-label="warning-messages" as="li" aria-hidden="true">
                      {field.name} : {field.message}
                    </WarningText>
                  </div>
                ))}
            </WarningWrapper>
          </Message>
        </div>
        <ButtonWrapper>
          <PrimaryBtn sm onClick={props.onContinue}>
            {t("msp.continue")}
          </PrimaryBtn>
          <SecondaryBtn onClick={props.onClose} sm>
            {t("msp.cancel")}
          </SecondaryBtn>
        </ButtonWrapper>
      </ContentWrapper>
    </Modal>
  ) : null;
};

WarningMessage.propTypes = {
  onClose: PropTypes.func,
  onContinue: PropTypes.any,
  show: PropTypes.any,
  warningFields: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string,
  }),
};

export default WarningMessage;
