import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../../config/notification";
import { store } from "react-notifications-component";
import { storage } from "config/storage";
import {
  fetchingUserGroupList,
  fetchUserGroupList,
  userGroupListFetchingComplete,
  updatingUserGroupStatus,
  userGroupStatusUpdated,
  deletingUserGroup,
  userGroupDeleted,
  fetchingBaseRoleList,
  fetchBaseRoleList,
  baseRoleListFetchingComplete,
  fetchingUserList,
  fetchUserList,
  userListFetchingComplete,
  fetchingUserGroupById,
  userGroupByIdFetchingComplete,
  userGroupSaved,
  userGroupSaveFailed,
} from "../store/actions";
import {
  INIT_FETCH_USER_GROUP_LIST,
  INIT_UPDATE_USER_GROUP_STATUS,
  INIT_DELETE_USER_GROUP,
  INIT_FETCH_BASE_ROLE_LIST,
  INIT_FETCH_USER_LIST,
  INIT_FETCH_USER_GROUP_BY_ID,
  INIT_SAVE_USER_GROUP,
} from "../store/actionTypes";

import axios from "../../../../config/axiosCall";
import {
  getRequestDetails,
  postRequestDetails,
  putRequestDetails,
} from "../../../../config/requestHeaders";

export default function* watchUserGroupSaga() {
  yield all([
    takeEvery(INIT_FETCH_USER_GROUP_LIST, getUserGroupListSaga),
    takeEvery(INIT_UPDATE_USER_GROUP_STATUS, updateUserGroupStatusSaga),
    takeEvery(INIT_DELETE_USER_GROUP, initDeleteUserGroupSaga),
    takeEvery(INIT_FETCH_BASE_ROLE_LIST, getBaseRoleListSaga),
    takeEvery(INIT_FETCH_USER_LIST, getUserListByBaseRoleIdSaga),
    takeEvery(INIT_FETCH_USER_GROUP_BY_ID, getUserGroupById),
    takeEvery(INIT_SAVE_USER_GROUP, initSaveUserGroupSaga),
  ]);
}

function* getUserGroupListSaga(action) {
  yield put(fetchingUserGroupList());
  const { fromDate, toDate } = action.payload || {};
  let url = "/Users/ListGroups";
  if (fromDate && toDate) {
    url += `?fromDate=${fromDate}&toDate=${toDate}`;
  }
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(fetchUserGroupList(response.data || ""));
    }
    yield put(userGroupListFetchingComplete());
  } catch (error) {
    yield put(userGroupListFetchingComplete());
  }
}

function* updateUserGroupStatusSaga(action) {
  yield put(updatingUserGroupStatus());
  const { payload, callback } = action || {};
  const url = "/Users/changeGroupStatus";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = { ...payload });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      callback && callback(response.status);
      const notification = buildNotification({
        message: "msp.userGroupStatusUpdated",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
    yield put(userGroupStatusUpdated());
  } catch (error) {
    yield put(userGroupStatusUpdated());
  }
}

function* initDeleteUserGroupSaga(action) {
  yield put(deletingUserGroup());
  const url = "/Users/DeleteGroup";
  const requestDetails = { ...postRequestDetails };
  const { payload, callback } = action || {};
  yield (requestDetails.data = { ...payload });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      callback && callback(response.status);
      const notification = buildNotification({
        message: "msp.userGroupDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
    yield put(userGroupDeleted());
  } catch (error) {
    yield put(userGroupDeleted());
  }
}

function* getBaseRoleListSaga() {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const languageID =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "enGB";
      
  yield put(fetchingBaseRoleList());
  const url = "/Users/baserole?LanguageID=" +languageID;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const data = yield response.data &&
      response.data.map((item) => ({
        id: item.baseRoleId,
        value: item.baseRoleNameLabel,
      }));
    yield put(fetchBaseRoleList(data));
    yield put(baseRoleListFetchingComplete());
  } catch (error) {
    yield put(baseRoleListFetchingComplete());
  }
}

function* getUserListByBaseRoleIdSaga(action) {
  yield put(fetchingUserList());
  const { baseRoleID } = action.payload || {};
  const url = `/Users?baseRoleID=${baseRoleID}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const data = yield response.data &&
      response.data.map((item) => ({
        userId: item.userId,
        userName: item.userName,
        profile: item.profile,
      }));
    yield put(fetchUserList(data || ""));
    yield put(userListFetchingComplete());
  } catch (error) {
    yield put(userListFetchingComplete());
  }
}

function* getUserGroupById(action) {
  yield put(fetchingUserGroupById());
  const { groupID } = action.payload || {};
  const url = `/Users/GroupById?groupID=${groupID}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      action.callback && action.callback(response);
    }
    yield put(userGroupByIdFetchingComplete());
  } catch (error) {
    yield put(userGroupByIdFetchingComplete());
    action.callback && action.callback(error);
  }
}

function* initSaveUserGroupSaga(action) {
  const { payload, callback } = action || {};
  const { formData } = payload || {};
  const url = "/Users/SaveGroup";
  const requestDetails = { ...putRequestDetails };
  yield (requestDetails.data = { ...formData });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      callback && callback(response.status);
      yield put(userGroupSaved());
      const message =
        formData && formData.groupID
          ? "msp.userGroupUpdated"
          : "msp.userGroupCreated";
      const notification = buildNotification({
        message,
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    } else {
      yield put(userGroupSaveFailed());
    }
  } catch (error) {
    yield put(userGroupSaveFailed());
  }
}
