import { SecondaryBtn, Form, ButtonWrapper, Rows } from "components/index";
import styled from "styled-components";

export const AddMutationBtn = styled(SecondaryBtn)`
  margin-bottom: 1em;
  span {
    display: flex;
    align-items: center;
    gap: 1em;
    svg {
      width: 1em;
    }
  }
`;
export const MutationControls = styled(Rows)`
  overflow: auto;
  padding: 0 1em 1em;
`;
export const MutationForm = styled(Form)`
  margin: 0;
  overflow: hidden;
  gap:0;

  ${ButtonWrapper} {
    margin-left: auto;
    padding: 0 1em 1em 0
  }
`;
