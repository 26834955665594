import {
  call,
  put,
  all,
  takeLatest,
  takeEvery,
  delay,
} from "redux-saga/effects";

import axios from "../../../config/axiosCall";

import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import {
  INIT_HELPDESK_OPENCASES_HISTORY_FETCH,
  SUBMIT_REQUEST,
  FETCH_QUERY_TYPE_LIST,
  FETCH_HELPDESK_TLOG,
  INIT_SAVE_COMMENT,
  GET_COMMENTS,
  INIT_FETCH_QUERY_FIELDS,
  INIT_ADD_ATTACHMENT,
  INIT_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE,
  INIT_GET_SERVICE_QUERY_BY_ID,
  INIT_SERVICE_NOW_SAVE_COMMENT,
} from "./actionTypes";
import {
  initFetchComplete,
  FetchCasesSuccess,
  FetchCasesFailure,
  queryTypeList,
  queryFieldsList,
  fetchTLogSuccess,
  fetchingHelpdeskDetailsByIDFailure,
  submitSuccess,
  submitFailure,
  fetchingHelpdeskDetailsByID,
  fetchingHelpdeskDetailsByIDCompleted,
  commentSaved,
  commentSaveFailed,
  fetchingqueryFields,
  queryFieldsListFetchingCompleted,
  attachmentAdded,
  savedHelpdeskOpenRequestMarkAsComplete,
  fetchComments,
  fetchCommentsEnd,
  updateHelpdeskCases,
  fetchingCases,
  FetchingGetServiceNowQueryByID,
  FetchGetServiceNowQueryByID,
  ServiceNowQueryByIDFetchCompleted,
  servicenowCommentSaved,
  serviceNowCommentSaveFailed,
  attachmentAddCompleted,
  requestSaving,
  queryTypeListFetchError,
  queryFieldsListFetchingFailed,
  fetchCommentsFailed,
  attachmentAddFailed,
  setLegendList,
  fetchingComments,
} from "./actions";
import {
  getRequestDetails,
  postRequestDetails,
  putRequestDetails,
} from "../../../config/requestHeaders";
import { errorMessages } from "../config/errorMessages";
import { storage } from "config/storage";
import {fetchingSearchRecords,
  searchRecordsFetchingComplete
} from "../../../store/actions";

export default function* rootHelpDeskSaga() {
  yield all([
    takeLatest(INIT_HELPDESK_OPENCASES_HISTORY_FETCH, getinitOpenCases),
    takeEvery(FETCH_QUERY_TYPE_LIST, fetchqueryType),
    takeEvery(INIT_FETCH_QUERY_FIELDS, fetchqueryfield),
    takeEvery(SUBMIT_REQUEST, submitRequest),
    takeEvery(FETCH_HELPDESK_TLOG, fetchTLogCases),
    takeEvery(GET_COMMENTS, initGetCommentsSaga),
    takeEvery(INIT_SAVE_COMMENT, initCommentSaveSaga),
    takeEvery(INIT_SERVICE_NOW_SAVE_COMMENT, initServiceNowCommentSaveSaga),
    takeEvery(INIT_ADD_ATTACHMENT, initAddAttachmentSaga),
    takeEvery(
      INIT_HELPDESK_OPEN_REQUEST_MARK_AS_COMPLETE,
      helpdeskOpenRequestMarkasCompleteSaga
    ),
    // takeEvery(INIT_GET_HELPDESK_QUERY_TYPE, initGetHelpdeskQueryAttributeSaga),
    takeEvery(INIT_GET_SERVICE_QUERY_BY_ID, initGetServicenowByIdSaga),
  ]);
}

function* getinitOpenCases(action) {
  try {
    const sessionDetails = yield JSON.parse(
      storage.getItem("sessionDetails")
    ) || {};
    let { languageId = "enGB" } = yield sessionDetails;

    yield put(fetchingCases());
    const zeroPad = (num, places) => {
      let zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    };
    const { filterData = null } = yield action;
    yield delay(1000);
    const { pageSize = 1000, pageIndex = 1, statusID = 0 } = filterData;
    let url = `/HelpDesk?pageSize=${pageSize}&pageIndex=${pageIndex}&Language=${languageId}`;

    if (action.isExtHelpDeskLinkUsed) url = `/HelpDesk/GetServiceNowTickets?`;
    else if (!action.isExtHelpDeskLinkUsed)
      if (statusID) url += `&statusID=${statusID}`;

    let keys = [];
    if (action.filterData) keys = Object.keys(action.filterData);

    keys.forEach((data) => {
      if (data === "FromDate" || data === "ToDate" || data === "quertTypeID" || data === "active") {
        if (action.filterData[data]) {
          url += "&" + data + "=" + action.filterData[data];
        }
      }
    });
    const requestDetails = yield { ...getRequestDetails };

    let response = yield call(axios, url, requestDetails);
    const { data = null } = response;
    let initCasesList = [];
    let modifyInternalList = [];
    let getRowCount = [];
    let legendList = [];
    if (data) {
      if (response.data.listHelpdeskTickets) {
        legendList = response.data.customListDetails;
        initCasesList = response.data.listHelpdeskTickets;
        getRowCount =
          response.data.rowCount.length > 0 ? response.data.rowCount : [];
        if (initCasesList.length)
          modifyInternalList = initCasesList.map((item) => {
            return {
              ...item,
              searchId: `MSP${zeroPad(item.helpdeskId, 7)}`,
            };
          });

        yield put(FetchCasesSuccess(modifyInternalList, getRowCount));
        yield put(updateHelpdeskCases(modifyInternalList, getRowCount));
        yield put(setLegendList(legendList));
        yield put(initFetchComplete());
      } else {
        initCasesList = response.data;
        yield put(FetchCasesSuccess(initCasesList, []));
        yield put(updateHelpdeskCases(initCasesList, []));
        yield put(initFetchComplete());
      }
    } else if (response && response.status === 204) {
      store.addNotification({
        ...errorMessages["noRecordFound"],
        dismiss: {
          duration: 1000,
        },
      });
      yield put(FetchCasesSuccess([], []));
      yield put(updateHelpdeskCases([], []));
      yield put(initFetchComplete());
    } else {
      yield put(FetchCasesFailure(true));
      store.addNotification({
        ...errorMessages["listFetchError"],
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(FetchCasesFailure(true));
    store.addNotification({
      ...errorMessages["listFetchError"],
      dismiss: {
        duration: 1000,
      },
    });
  }
}

function* fetchqueryType() {
  const sessionDetails = yield JSON.parse(
    storage.getItem("sessionDetails")
  ) || {};
  let { languageId = "enGB" } = yield sessionDetails;
  const url = `/Common/listHelpdeskQuery?languageID=${languageId}`;
  const requestDetails = { ...getRequestDetails };
  try {
    let response = yield call(axios, url, requestDetails);
    if (response && response.data) {
      yield put(queryTypeList(response.data));
    } else if (response && response.status === 204) {
      store.addNotification({
        ...errorMessages["noRecordFound"],
        dismiss: {
          duration: 1000,
        },
      });
      yield put(queryTypeList([]));
    } else {
      yield put(queryTypeListFetchError(true));
      store.addNotification({
        ...errorMessages["queryTypeListFetchError"],
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(queryTypeListFetchError(true));
    store.addNotification({
      ...errorMessages["queryTypeListFetchError"],
      dismiss: {
        duration: 1000,
      },
    });
  }
}

function* fetchqueryfield(action) {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const languageId =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "enGB";
  yield put(fetchingqueryFields());
  let queryFieldlist = [];
  var url;
  if (action.queryField.QueryTypeCountryID)
       url = `/HelpDesk/attributes?querytypeId=${action.queryField.QueryTypeID}&languageID=${languageId}&countryID=${action.queryField.QueryTypeCountryID}`;
  else
       url = `/HelpDesk/attributes?querytypeId=${action.queryField}&languageID=${languageId}`;

      const requestDetails = { ...getRequestDetails };
  try {
    let response = yield call(axios, url, requestDetails);
    if (response && response.data) {
      // Only two property is required from helpdeskprofile , that is why updating min data as much is required
      let updatedData = {};
      const attachmentAllowed =
        response.data.helpdeskProfile.attachmentAllowed !== null
          ? response.data.helpdeskProfile.attachmentAllowed
          : 0;
      const maxRepeats =
        response.data.helpdeskProfile.maxRepeats !== null
          ? response.data.helpdeskProfile.maxRepeats
          : 0;
      updatedData = {
        ...response.data,
        helpdeskProfile: {
          attachmentAllowed: attachmentAllowed,
          maxRepeats: maxRepeats,
        },
      };
      queryFieldlist = updatedData;
      yield put(queryFieldsList(queryFieldlist));
      yield delay(2000);
      if (action.fetchDynamicFieldSuccess) {
        action.fetchDynamicFieldSuccess(response.status);
      }
      yield put(queryFieldsListFetchingCompleted());
    } else if (response && response.status === 204) {
      store.addNotification({
        ...errorMessages["noRecordFound"],
        dismiss: {
          duration: 1000,
        },
      });
      yield put(queryFieldsList([]));
      yield put(queryFieldsListFetchingCompleted());
    } else {
      yield put(queryFieldsListFetchingFailed(true));
    }
  } catch (error) {
    yield put(queryFieldsListFetchingFailed(true));
  }
}

function* fetchTLogCases(action) {
  yield put(fetchingHelpdeskDetailsByID());
  const url = `/HelpDesk/helpdeskDetailByID?helpdeskId=${action.reqID}`;
  const requestDetails = { ...getRequestDetails };
  try {
    let response = yield call(axios, url, requestDetails);
    if (response && response.status === 204) {
      yield put(fetchingHelpdeskDetailsByIDFailure(true));
      if (action.globalSearch) {
        store.addNotification({
          ...errorMessages["noResultFound"],
          dismiss: {
            duration: 2000,
          },
        });
      } else {
        store.addNotification({
          ...errorMessages["noRecordFound"],
          dismiss: {
            duration: 1000,
          },
        });
      }
    }
    if (response && response.data) {
      yield put(fetchTLogSuccess(response.data));
      if (action.fetchHelpdeskByIdSuccess) {
        action.fetchHelpdeskByIdSuccess(response.status, response.data);
      }
      yield put(fetchingHelpdeskDetailsByIDCompleted());
    } else {
      yield put(fetchTLogSuccess({}));
      yield put(fetchingHelpdeskDetailsByIDCompleted());
    }
  } catch (error) {
    yield put(fetchingHelpdeskDetailsByIDFailure(true));
    store.addNotification({
      ...errorMessages["helpdeskDetailsByIDFailed"],
      dismiss: {
        duration: 1000,
      },
    });
  }
}

function* submitRequest(action) {
  yield put(requestSaving());
  let url;
  const requestDetails = { ...postRequestDetails };
  if (action.isExtHelpDeskLinkUsed === false) {
    url = `/HelpDesk`;
    yield (requestDetails.data = {
      ...action.formData,
    });
  } else if (action.isExtHelpDeskLinkUsed === true) {
    url = `/HelpDesk/SaveServiceNowTicket`;
    yield (requestDetails.data = {
      ...action.formData,
    });
  }
  try {
    let response = yield call(axios, url, requestDetails);
    if (response && response.data) {
      yield put(submitSuccess());
      const notification = buildNotification({
        message: "msp.supportQuerySubmitSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
      if (action.saveSuccess) {
        action.saveSuccess(response && response.data ? response.data : []);
      }
    } else {
      yield put(submitFailure(true));
      store.addNotification({
        ...errorMessages["isSubmitRequestFailed"],
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    store.addNotification({
      ...errorMessages["isSubmitRequestFailed"],
      dismiss: {
        duration: 1000,
      },
    });
    yield put(submitFailure(true));
  }
}

function* initServiceNowCommentSaveSaga(action) {
  const requestDetails = { ...putRequestDetails };
  yield (requestDetails.data = {
    ...action.formdata,
  });
  const url = `/HelpDesk/UpdateServiceNowTicket`;

  try {
    let response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      yield put(servicenowCommentSaved());
      if (action.callback) {
        action.callback(response.status);
      }
    }
    else{
      store.addNotification({
        ...errorMessages["saveCommentFailed"],
        dismiss: {
          duration: 1000,
        },
      });
      yield put(serviceNowCommentSaveFailed());
    }
  } catch (error) {
    store.addNotification({
      ...errorMessages["saveCommentFailed"],
      dismiss: {
        duration: 1000,
      },
    });
    yield put(serviceNowCommentSaveFailed());
  }
}

//get comment

function* initGetCommentsSaga(action) {
  const url = `/HelpDesk/GetComments?helpdeskId=${action.id}`;
  const requestDetails = { ...getRequestDetails };
  try {
    yield put(fetchingComments());
    let response = yield call(axios, url, requestDetails);
    if (response && response.data) {
      yield put(fetchComments(response.data));
      yield put(fetchCommentsEnd());
    } else if (response && response.status === 204) {
      store.addNotification({
        ...errorMessages["noRecordFound"],
        dismiss: {
          duration: 1000,
        },
      });
      yield put(fetchComments([]));
      yield put(fetchCommentsEnd());
    } else {
      yield put(fetchCommentsFailed(true));
      store.addNotification({
        ...errorMessages["fetchCommentsFailed"],
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    store.addNotification({
      ...errorMessages["fetchCommentsFailed"],
      dismiss: {
        duration: 1000,
      },
    });
    yield put(fetchCommentsFailed(true));
  }
}

// save comment
function* initCommentSaveSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const userId = sessionDetails && sessionDetails.userId;
  const url = `/HelpDesk/SaveComments?helpdeskId=${action.ID}&userType=supplier&Comments=${action.payload}&userId=${userId}`;
  const requestDetails = { ...postRequestDetails };
  try {
    let response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      yield put(commentSaved());
      // yield put(fetchTLogCase(action.ID))
      if (action.commentSaveSuccess) {
        action.commentSaveSuccess(response.status);
      }
    } else {
      store.addNotification({
        ...errorMessages["saveCommentFailed"],
        dismiss: {
          duration: 1000,
        },
      });
      yield put(commentSaveFailed(true));
    }
  } catch (error) {
    store.addNotification({
      ...errorMessages["saveCommentFailed"],
      dismiss: {
        duration: 1000,
      },
    });
    yield put(commentSaveFailed(true));
  }
}

// Add Attachment
function* initAddAttachmentSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const userId = sessionDetails && sessionDetails.userId;
  const url = `/HelpDesk/AddAttachment`;
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    ...action.formData,
    userId: userId,
  });
  try {
    let response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      yield put(attachmentAdded());
      if (action.callback) {
        action.callback(response.status);
      }
      const notification = buildNotification({
        message: "msp.attachmentAdded",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
      yield put(attachmentAddCompleted());
    } else {
      yield put(attachmentAddFailed(true));
      store.addNotification({
        ...errorMessages["attachmentAddingFailed"],
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    store.addNotification({
      ...errorMessages["attachmentAddingFailed"],
      dismiss: {
        duration: 1000,
      },
    });
    yield put(attachmentAddFailed(true));
  }
}

function* helpdeskOpenRequestMarkasCompleteSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const userId = sessionDetails && sessionDetails.userId;
  const url = `/HelpDesk/MarkComplete?helpdeskId=${action.helpdeskID}&userId=${userId}`;
  const requestDetails = { ...postRequestDetails };
  try {
    let response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      yield put(savedHelpdeskOpenRequestMarkAsComplete());
      const notification = buildNotification({
        message: "msp.requestMarkedComplete",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
      if (action.saveSuccess) {
        action.saveSuccess(response);
      }
    }
  } catch (error) {
    yield put(submitFailure("error"));
  }
}

function* initGetServicenowByIdSaga(action) {
  yield put(FetchingGetServiceNowQueryByID());

  const url = `/HelpDesk/GetServiceNowTicketDetails?ticketID=${action.reqID}`;
  const requestDetails = { ...getRequestDetails };
  yield put(fetchingSearchRecords());
  try {
    let response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      yield put(FetchGetServiceNowQueryByID(response.data));
      if (action.fetchServicenowByIdSuccess) {
        action.fetchServicenowByIdSuccess(response.data);
      }
    }
    if (response.status === 204) {
      const notification = buildNotification({
        message: "msp.noData",
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    }
    yield put(searchRecordsFetchingComplete());
    yield put(ServiceNowQueryByIDFetchCompleted());
  } catch (error) {
    yield put(searchRecordsFetchingComplete());
    yield put(ServiceNowQueryByIDFetchCompleted());
  }
}
