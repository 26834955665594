
import { fromJS } from 'immutable';
import { GET_RESPONSE_TOKEN ,GET_CLIENT} from './actionTypes';

export const initialState = fromJS({
  token: '',
  client: '',
  isClientSSO : false
});

function ssoLoginReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RESPONSE_TOKEN:
      return state.set('token', action.token);
    case GET_CLIENT:
      return state
        .set('client', action.client)
        .set('isClientSSO', action.isClientSSO);
    default:
      return state;
  }
}

export default ssoLoginReducer;
