export const INIT_MFA_OTP = "INIT_MFA_OTP";
export const MFA_OTP_SENT = "MFA_OTP_SENT";
export const MFA_OTP_FAILED = "MFA_OTP_FAILED";

export const INIT_AUTHENTICATE = "INIT_AUTHENTICATE";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";

export const INIT_LOGOUT = "INIT_LOGOUT";
export const LOGOUT = "LOGOUT";

export const CHECK_AUTH_TIMEOUT = "CHECK_AUTH_TIMEOUT";
export const CHECK_AUTH_STATUS = "CHECK_AUTH_STATUS";

export const NOT_YET_CONFIGURED = "NOT_YET_CONFIGURED";

export const INIT_CONFIRM_GDPR_MESSAGE = "INIT_CONFIRM_GDPR_MESSAGE";
export const CONFIRMING_GDPR_MESSAGE = "CONFIRMING_GDPR_MESSAGE";
export const GDPR_MESSAGE_CONFIRMED = "GDPR_MESSAGE_CONFIRMED";
export const GDPR_MESSAGE_CONFIRM_FAILED = "GDPR_MESSAGE_CONFIRM_FAILED";

export const ABOUT_TO_LOGOUT = "ABOUT_TO_LOGOUT";
export const TIMER_RESET_SUCCESS = "TIMER_RESET_SUCCESS";

export const INIT_REFRESH_TOKEN = "INIT_REFRESH_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const FETCHING_REFRESH_TOKEN = "FETCHING_REFRESH_TOKEN";
export const FETCH_REFRESH_TOKEN = "FETCH_REFRESH_TOKEN";
export const REFRESH_TOKEN_FETCH_FAILED = "REFRESH_TOKEN_FETCH_FAILED";

export const SSO_LOGIN_FAILED = "SSO_LOGIN_FAILED";
