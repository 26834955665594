import { ButtonWrapper, PrimaryBtn, Rows, Button } from "components/index";
import styled from "styled-components";

export const YesBtn = styled(PrimaryBtn)``;
export const NoBtn = styled(Button)``;

const DeleteConfirmMsg = styled(Rows)`
  justify-content: center;
  align-items: center;
  padding: 1em;
  padding-top: 2em;
  h1 {
    font-family: var(--ff-medium);
    text-align: center;
    font-size: var(--fs-16);
  }
  ${ButtonWrapper} {
    display: flex;
  }
`;

export default DeleteConfirmMsg;
