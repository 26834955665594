import { fromJS } from "immutable";

import {
  FETCHING_CUSTOM_LIST,
  FETCH_CUSTOM_LIST,
  CUSTOM_LIST_FETCH_COMPLETE,
  RESET_FORM_SAVED_STATE,
  CUSTOM_LIST_ITEM_SAVED,
  FETCHING_LIST_ITEM_DATA,
  FETCHING_LIST_ITEMS,
  FETCH_LIST_ITEMS,
  FETCHING_ITEM_DATA,
  FETCH_ITEM_DATA,
  GET_LANGUAGE,
  UPDATE_ITEM_DATA,
  FETCHING_PARENT_LIST,
  FETCH_PARENT_LIST,
  PARENT_LIST_FETCH_COMPLETE,
  FETCHING_CHILD_LIST,
  FETCH_CHILD_LIST,
  CHILD_LIST_FETCH_COMPLETE
} from "./actionTypes";

export const initialState = fromJS({
  fetchingCustomList: false,
  customListData: [],
  formSubmitted: false,
  fetchingListItems: false,
  fetchingListItemData: false,
  listItemsData: [],
  fetchingItemData: false,
  itemDetails: [],
  lang: [],
  listItems: [],
  parentListData: [],
  fetchingparentList: false,
  fetchingChildList: false,
  childListData: []
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //custom list
    case FETCHING_CUSTOM_LIST: {
      return state.set("fetchingCustomList", true);
    }
    case FETCH_CUSTOM_LIST: {
      return state.set("customListData", action.data);
    }
    case RESET_FORM_SAVED_STATE: {
      return state.set("formSubmitted", false);
    }
    case CUSTOM_LIST_FETCH_COMPLETE: {
      return state.set("fetchingCustomList", false);
    }
    case CUSTOM_LIST_ITEM_SAVED: {
      return state.set("formSubmitted", true); //reset formData after save
    }

    //list items
    case FETCHING_LIST_ITEMS: {
      return state.set("fetchingListItems", true);
    }
    case FETCH_LIST_ITEMS: {
      return state
        .set("listItemsData", action.data)
        .set("listItems", action.data)
        .set("fetchingListItems", false);
    }
    case FETCHING_ITEM_DATA: {
      return state.set("fetchingItemData", true);
    }
    case FETCH_ITEM_DATA: {
      return state
        .set("itemDetails", action.data)
        .set("fetchingItemData", false);
    }
    case GET_LANGUAGE: {
      return state.set("lang", action.data);
    }
    case FETCHING_LIST_ITEM_DATA: {
      return state.set("fetchingListItemData", true);
    }
    case UPDATE_ITEM_DATA: {
      return state.set("listItemsData", action.data);
    }
    case FETCHING_PARENT_LIST: {
      return state.set("fetchingparentList", true);
    }
    case FETCH_PARENT_LIST: {
      return state.set("parentListData", action.data);
    }
    case PARENT_LIST_FETCH_COMPLETE: {
      return state.set("fetchingparentList", false);
    }
    case FETCHING_CHILD_LIST: {
      return state.set("fetchingChildList", true);
    }
    case FETCH_CHILD_LIST: {
      return state.set("childListData", action.data);
    }
    case CHILD_LIST_FETCH_COMPLETE: {
      return state.set("fetchingChildList", false);
    }
    default:
      return state;
  }
};
export default reducer;
