import {
  ADVANCE_PAYMENTS_FETCH_COMPLETE,
  APPLY_FILTER,
  CLEAR_LOOKUP,
  FETCHING_ADVANCE_PAYMENTS,
  FETCH_ADVANCE_PAYMENTS,
  FETCH_ADV_PAYMENT_ACTIVE_COLS,
  INIT_ADVANCE_PAYMENTS_FETCH,
  INIT_ADV_PAYMENT_ACTIVE_COLS,
  INIT_APPLY_FILTER,
  INIT_FETCH_LINKED_INVOICES,
  SAVE_LOOKUP,
  SELECTED_SUPPLIERS,
  SET_FILTERS,
  SET_GRID_CONFIGS,
} from "./actionTypes";

export const initAdvancePaymentsFetch = (payload) => {
  return {
    type: INIT_ADVANCE_PAYMENTS_FETCH,
    payload,
  };
};

export const advancePaymentsFetching = () => {
  return {
    type: FETCHING_ADVANCE_PAYMENTS,
  };
};
export const fetchAdvancePayments = (data) => {
  return {
    type: FETCH_ADVANCE_PAYMENTS,
    data,
  };
};
export const advancePaymentsFetchComplete = () => {
  return {
    type: ADVANCE_PAYMENTS_FETCH_COMPLETE,
  };
};

export const initAdvancePaymentsActiveCols = () => {
  return {
    type: INIT_ADV_PAYMENT_ACTIVE_COLS,
  };
};
export const fetchAdvPaymentActiveCols = (data) => {
  return {
    type: FETCH_ADV_PAYMENT_ACTIVE_COLS,
    data,
  };
};

export const setGridConfig = (data) => {
  return {
    type: SET_GRID_CONFIGS,
    data,
  };
};
export const setFilters = (data) => {
  return {
    type: SET_FILTERS,
    data,
  };
};

export const setSelectedSuppliers = (data) => {
  return {
    type: SELECTED_SUPPLIERS,
    data,
  };
};

export const saveLookup = (data) => {
  return {
    type: SAVE_LOOKUP,
    data,
  };
};
export const clearLookup = () => {
  return {
    type: CLEAR_LOOKUP,
  };
};
export const applyFilter = () => ({ type: APPLY_FILTER });

export const initApplyFilter = (data) => {
  return {
    type: INIT_APPLY_FILTER,
    data,
  };
};

export const initFetchLinkedInvoices = (data) => ({
  type: INIT_FETCH_LINKED_INVOICES,
  data,
});
