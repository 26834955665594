import { fromJS } from "immutable";

import {
  FETCHING_NOTIFICATION_LIST,
  FETCH_NOTIFICATION_LIST,
} from "./actionTypes";

export const initialState = fromJS({
  notificationList: [],
  fetchingnotificationList: true,
});

const reportStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_NOTIFICATION_LIST: {
      return state.set("fetchingnotificationList", action.boolean);
    }
    case FETCH_NOTIFICATION_LIST: {
      return state.set("notificationList", action.data);
    }
    default:
      return state;
  }
};
export default reportStatusReducer;
