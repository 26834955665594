import {
  CLEAR_DATA_CAPTURE,
  CLEAR_INPUTS,
  INIT_OCR_EXTRATION,
  SET_DATA_CAPTURE,
  SET_INPUT,
  SET_INPUT_VALUE,
} from "./actionTypes";

export const setDataCapture = (data) => ({ type: SET_DATA_CAPTURE, data });
export const clearDataCapture = () => ({ type: CLEAR_DATA_CAPTURE });
export const setInput = (data) => ({ type: SET_INPUT, data });

export const initOCRExtration = (fullOCRExtract) => ({
  type: INIT_OCR_EXTRATION,
  data: fullOCRExtract,
});

export const setInputValue = (data) => ({ type: SET_INPUT_VALUE, data });

export const clearInputs = () => ({ type: CLEAR_INPUTS });
