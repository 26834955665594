export const INIT_FECTH_SUBMISSION_HISTORY = "INIT_FECTH_SUBMISSION_HISTORY";
export const FECTHING_SUBMISSION_HISTORY = "FECTHING_SUBMISSION_HISTORY";
export const SUBMISSION_HISTORY_FETCHED = "SUBMISSION_HISTORY_FETCHED";
export const SUBMISSION_HISTORY_FETCH_FAILED =
  "SUBMISSION_HISTORY_FETCH_FAILED";

export const SET_DATA_GETTER_PARAM = "SET_DATA_GETTER_PARAM";
export const SET_SSR = "SET_SSR";

export const SET_FILTER_PARAMS = "SET_FILTER_PARAMS";
export const CLEAR_FILTER_PARAMS = "CLEAR_FILTER_PARAMS";

export const SET_SKIP = "SET_SKIP";

export const APPLY_SUBMISISON_HISTORY_FILTER =
  "APPLY_SUBMISISON_HISTORY_FILTER";

export const SET_TAB = "SET_TAB";

export const SHOW_DOWNLOAD_POPUP = "SHOW_DOWNLOAD_POPUP";

export const TOGGLE_INVOICE_COPY_CHECK = "TOGGLE_INVOICE_COPY_CHECK";
export const TOGGLE_SUPPORTING_DOC_CHECK = "TOGGLE_SUPPORTING_DOC_CHECK";

export const INIT_FECTH_RESOLVED_INVOICE = "INIT_FECTH_RESOLVED_INVOICE";
export const FECTHING_RESOLVED_INVOICE = "FECTHING_RESOLVED_INVOICE";
export const RESOLVED_INVOICE_FETCHED = "RESOLVED_INVOICE_FETCHED";
export const RESOLVED_INVOICE_FETCH_FAILED = "RESOLVED_INVOICE_FETCH_FAILED";

export const CLEAR_SUBMISSION_HISTORY = "CLEAR_SUBMISSION_HISTORY";
