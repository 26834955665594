import React, { useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";

import { customStyles } from "./style";

const Select = (props) => {
  const { t } = useTranslation();
  const [visited, setVisited] = useState(false);
  useEffect(() => {
    props.resetVisited && setVisited(false);
  }, [props.resetVisited]);
  const options = props.options
    ? props.options.map((option) => ({
        label: option.value,
        value: option.id,
      }))
    : [];

  const stringVal = props.value ? props.value.toString() : "";
  const value = options.find(
    (option) => option && option.value && option.value.toString() === stringVal
  );

  const handleOnChange = (selectedOption) => {
    let value = selectedOption ? selectedOption.value.toString() : "";
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    value = isNaN(Number(strippedHtml)) ? strippedHtml : Number(strippedHtml);

    props.onChangeHandler &&
      props.onChangeHandler({
        type: "change",
        target: {
          value: selectedOption ? strippedHtml : "",
          id: props.id,
          name: props.name,
          text: selectedOption ? selectedOption.label : null,
          options: options,
          selectedIndex: options.findIndex((option) =>
            option.value === selectedOption ? strippedHtml : false
          ),
          required: props.required,
          inputType: "select",
        },
      });
  };

  const handleOnBlur = (selectedOption) => {
    setVisited(true);
    let value = selectedOption.target.value.toString();
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    value = isNaN(Number(strippedHtml)) ? strippedHtml : Number(strippedHtml);

    return props.onBlurHandler
      ? (selectedOption) =>
          props.onBlurHandler({
            type: "blur",
            target: {
              value,
              id: props.id,
              name: props.name,
              text: selectedOption.label,
              options: options,
              selectedIndex: options.findIndex(
                (option) => option.value === value
              ),
              inputType: "select",
            },
          })
      : false;
  };
  const useOnMountFocus = (ref) => {
    useEffect(() => {
      props.autoFocus && ref.current.select.focusInput();
    }, [ref]);
  };
  const selectRef = useRef();
  useOnMountFocus(selectRef);
  return (
    <>
      <ReactSelect
        ref={selectRef}
        visited={visited}
        isClearable={!props.noClear}
        onInputChange={(value) =>
          props.onSelectInputType &&
          props.onSelectInputType({
            target: {
              value,
              name: props.name,
              id: props.id,
            },
          })
        }
        isDisabled={props.disabled || false}
        required={props.required}
        isLoading={props.isLoading}
        value={value || ""}
        placeholder={t("msp.select")}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        options={options}
        customStyle={props.customStyle}
        styles={{
          ...customStyles,
        }}
        showError={props.showError}
        openMenuOnFocus={true}
        menuPlacement="auto"
        menuPortalTarget={props.inTable ? document.body : false}
        noOptionsMessage={() => t("msp.noOptions")}
        aria-label="Select"
      />
    </>
  );
};

export default Select;
