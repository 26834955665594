import PropTypes from "prop-types";
import React from "react";
import { NavItem, NavLinkStyled } from "./style";
import { useLocation } from "react-router-dom";
const MenuItem = (props) => {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <NavItem key={props.id}>
      <NavLinkStyled
        to={props.children ? () => false : props.pathname}
        exact={true}
        active={(props.pathname === currentPath).toString()}
        onClick={props.onNavLinkClick}
      >
        {props.text}
      </NavLinkStyled>
    </NavItem>
  );
};

MenuItem.propTypes = {
  children: PropTypes.any,
  id: PropTypes.any,
  pathname: PropTypes.any,
  text: PropTypes.any,
};

export default MenuItem;
