import styled from "styled-components";
import Button from "components/Buttons";

export const DropzoneContainer = styled.div`
  background: ${(props) =>
    props.disabled ? "var(--light-grey-clr)" : "var(--light-clr)"};
  width: 100%;
  border: 1px dashed rgb(196, 205, 213);
  border-radius: 0.5em;
  &:focus,
  &:hover,
  &:focus-within {
    border: 1px dashed var(--primary-clr);
    box-shadow: rgb(102 187 255 / 50%) 0px 0px 0.8rem;
    outline: none;
  }
  svg {
    min-width: 0.875em;
  }
`;

export const DropzoneLayout = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 1em;
  justify-content: center;
  flex-direction: ${(props) => (props.type === "regular" ? "column" : "row")};
  flex-wrap: ${(props) => (props.type === "regular" ? "wrap" : "nowrap")};
  gap: ${(props) => (props.type === "regular" ? "0.5em" : "1.5em")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 0.3em;
`;

export const DropzoneTitle = styled.p`
  color: var(--grey-clr);
  font-size: var(--fs-14);
  font-family: var(--ff-main);
  font-weight: var(--fw-normal);
`;

export const SmallDropzoneTitle = styled(DropzoneTitle)`
  font-size: var(--fs-14);
`;

export const BrowseFiles = styled.strong`
  color: var(--primary-clr);
  cursor: ${(props) => props.cursor};
  &:after {
    color: ${(props) => (props.required ? "var(--error-clr);" : null)};
    content: "${(props) => (props.required ? "*" : null)}";
    top: 0;
    /* padding: 0 0.2rem; */
  }
`;

export const DetailsText = styled.p`
  color: var(--primary-text-clr);
  font-size: var(--fs-12);
  font-family: var(--ff-main);
  font-weight: var(--fw-normal);
`;

export const SmallDropzoneDetailsText = styled(DetailsText)`
  font-size: 0.625rem;
`;

export const ImportantMsg = styled(DetailsText)`
  color: var(--error-clr);
`;

export const FilePreviewImg = styled.img`
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.4);
  min-width: 2em;
  width: 100%;
  max-width: 2.5rem;
  max-height: 2.5rem;
`;

export const FilePreviewLabel = styled.p`
  color: var(--dark-clr);
  font-size: var(--fs-14);
  font-family: var(--ff-main);
  font-weight: var(--fw-normal);
  text-align: start;
`;

export const ActionBtnsContainer = styled.div`
  display: flex;
  gap: 1em;
  button {
    background: none;
    font-size: var(--fs-14);
    font-family: var(--ff-main);
    padding: unset;
    outline: none;
    border: none;
  }
`;

export const ReplaceButton = styled(Button)`
  color: var(--primary-clr);
  &:hover,
  &:focus {
    border: none;
    text-decoration: underline;
    box-shadow: none;
  }
`;
export const CustomButton = styled(ReplaceButton)``;
export const RemoveButton = styled(Button)`
  color: var(--error-clr);
  &:hover,
  &:focus {
    border: none;
    text-decoration: underline;
    box-shadow: none;
  }
`;

//DropzoneWithTempalte styles
export const DropzoneTemplateBodyWrapper = styled.div`
  display: flex;
  cursor: ${(props) => props.cursor};
  &:hover,
  &:focus {
    outline: none;
  }
`;

export const DownloadTemplateWrapper = styled.div`
  display: flex;
  background: var(--light-grey-clr);
  justify-content: center;
  padding: 0.5em;
  border-radius: 0.5em 0.5em 0 0;
  gap: 0.5em;
  align-items: center;
  svg {
    width: 1rem;
    height: 1rem;
    color: var(--primary-text-clr);
  }
`;

export const DownloadText = styled.p`
  color: var(--grey-clr);
  font-size: var(--fs-14);
  font-family: var(--ff-main);
  font-weight: var(--fw-normal);
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  strong {
    color: var(--primary-clr);
    cursor: inherit;
    pointer-events: inherit;
  }
`;
