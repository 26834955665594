import { put, takeLatest, call, all } from "redux-saga/effects";

import axios from "../../../../../config/axiosCall";

import { getRequestDetails } from "../../../../../config/requestHeaders";
import {
  fetchGetAdvPaymentCols,
  fetchingGetAdvPaymentCols,
  getAdvPaymentColsFetchingComplete,
} from "./actions";
import { INIT_GET_ADV_PAYMENT_COLS } from "./actionTypes";

export default function* watchCustomListFetch() {
  yield all([takeLatest(INIT_GET_ADV_PAYMENT_COLS, getAdvPaymentColsSaga)]);
}

function* getAdvPaymentColsSaga(action) {
  try {
    yield put(fetchingGetAdvPaymentCols());
    const sessionDetails =
      JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
    const { menus = [] } = sessionDetails;
    const module = menus.find(
      (item) => item.pageElementFileName === "advancePayments"
    );

    const { moduleID } = module;
    const url = `/Module/ListSearchFields?moduleID=${moduleID}`;
    const requestDetails = { ...getRequestDetails };
    const response = yield call(axios, url, requestDetails);
    yield put(fetchGetAdvPaymentCols(response.data));
    action.payload.setFieldsToState(true);
    yield put(getAdvPaymentColsFetchingComplete());
  } catch (error) {
    yield put(getAdvPaymentColsFetchingComplete());
  }
}
