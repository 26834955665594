import styled from "styled-components";
import { Rows, Cols, IconWrapper } from "components";
export const DialogBox = styled.div`
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  height: calc(100% - 3.5rem);
  ${(props) =>
    props.centered &&
    `
   display: flex;
   align-items: center;
   min-height: calc(100% - 3.5em);
  `}
  @media (min-width: 36em) {
    max-width: ${(props) => props.maxWidth || `90%`};
    margin: 1em auto;
  }
`;

export const ModalContent = styled(Rows)`
  position: relative;
  width: 100%;
  pointer-events: auto;
  background-color: var(--light-clr);
  box-shadow: 0 0.125rem 4rem 0 rgba(0, 0, 0, 0.1);
  outline: 0;
  border-radius: 0.5em;
  max-height: 100%;
  ${(props) => props.scrollable && `overflow: hidden `};
`;

export const ModalHeader = styled(Cols)`
  padding: 1em;
  justify-content: space-between;
  align-items: center;
`;

export const CloseModal = styled(IconWrapper)`
  display: flex;
  align-items: center;
  padding: 0.25em;
  border: var(--oneDotFivePx) solid transparent;
  :hover,
  :focus-visible {
    border-color: var(--dark-clr);
  }
  svg {
    width: 1em;
    height: 1em;
  }
`;
