export const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};
export const postRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};
export const putRequestDetails = {
  method: "PUT",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};
