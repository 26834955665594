import { fromJS } from "immutable";
import { CLEANUP_EINVOICE, UPLOAD_TEMPLATE } from "./actionTypes";
export const initialState = fromJS({
  uploadedTemplate: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_TEMPLATE:
      return state.set("uploadedTemplate", fromJS(action.data));
    case CLEANUP_EINVOICE:
      return state.set("uploadedTemplate", fromJS([]));
    default:
      return state;
  }
};
export default reducer;
