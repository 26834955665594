export const SET_UPLOAD_INVOICE_FILES = "SET_UPLOAD_INVOICE_FILES";
export const SET_BACKUP_UPLOAD_INVOICE_FILES =
  "SET_BACKUP_UPLOAD_INVOICE_FILES";

export const SET_NON_PO_BULK_SUPPLIER_ID = "SET_NON_PO_BULK_SUPPLIER_ID";

export const SET_EDIT_MODE = "SET_EDIT_MODE";
export const SET_VIEW_MODE = "SET_VIEW_MODE";

export const SET_SELECTED_CARD = "SET_SELECTED_CARD";
export const SET_SELECT_ALL = "SET_SELECT_ALL";

export const SET_CURRENT_INVOICE_REF = "SET_CURRENT_INVOICE_REF";

export const INIT_DS_SAVE = "INIT_DS_SAVE";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";

export const RESET_UPLOAD_INVOICE = "RESET_UPLOAD_INVOICE";
