import { fromJS } from "immutable";

import {
  FETCHING_USER_GROUP_LIST,
  FETCH_USER_GROUP_LIST,
  USER_GROUP_LIST_FETCH_COMPLETE,
  UPDATING_USER_GROUP_STATUS,
  USER_GROUP_STATUS_UPDATE_COMPLETED,
  DELETING_USER_GROUP,
  USER_GROUP_DELETED,
  FETCHING_BASE_ROLE_LIST,
  FETCH_BASE_ROLE_LIST,
  BASE_ROLE_LIST_FETCH_COMPLETE,
  FETCHING_USER_LIST,
  FETCH_USER_LIST,
  USER_LIST_FETCH_COMPLETE,
  FETCHING_USER_GROUP_BY_ID,
  USER_GROUP_BY_ID_FETCH_COMPLETE,
  INIT_SAVE_USER_GROUP,
  USER_GROUP_SAVED,
  USER_GROUP_SAVE_FAILED,
  INIT_FETCH_USER_GROUP_LIST,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingUserGroupList: false,
  userGroupList: "",
  updatingUserGroupStatus: false,
  deletingUserGrp: false,
  fetchingBaseRoleList: false,
  baseRoleList: "",
  fetchingUserList: false,
  userList: "",
  fetchingUserGroupById: false,
  userGroupSaveStatus: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_FETCH_USER_GROUP_LIST:
      return state.set("userGroupList", "");

    case FETCHING_USER_GROUP_LIST:
      return state.set("fetchingUserGroupList", true);

    case FETCH_USER_GROUP_LIST:
      return state.set("userGroupList", action.data);

    case USER_GROUP_LIST_FETCH_COMPLETE:
      return state.set("fetchingUserGroupList", false);

    case UPDATING_USER_GROUP_STATUS:
      return state.set("updatingUserGroupStatus", true);

    case USER_GROUP_STATUS_UPDATE_COMPLETED:
      return state.set("updatingUserGroupStatus", false);

    case DELETING_USER_GROUP:
      return state.set("deletingUserGrp", true);

    case USER_GROUP_DELETED:
      return state.set("deletingUserGrp", false);

    case FETCHING_BASE_ROLE_LIST: {
      return state.set("fetchingBaseRoleList", true);
    }

    case FETCH_BASE_ROLE_LIST: {
      return state.set("baseRoleList", action.data);
    }

    case BASE_ROLE_LIST_FETCH_COMPLETE: {
      return state.set("fetchingBaseRoleList", false);
    }

    case FETCHING_USER_LIST: {
      return state.set("fetchingUserList", true);
    }

    case FETCH_USER_LIST: {
      return state.set("userList", action.data);
    }

    case USER_LIST_FETCH_COMPLETE: {
      return state.set("fetchingUserList", false);
    }

    case FETCHING_USER_GROUP_BY_ID: {
      return state.set("fetchingUserGroupById", true);
    }

    case USER_GROUP_BY_ID_FETCH_COMPLETE: {
      return state.set("fetchingUserGroupById", false);
    }

    case INIT_SAVE_USER_GROUP: {
      return state.set("userGroupSaveStatus", true);
    }

    case USER_GROUP_SAVED: {
      return state.set("userGroupSaveStatus", false);
    }

    case USER_GROUP_SAVE_FAILED: {
      return state.set("userGroupSaveStatus", false);
    }

    default:
      return state;
  }
};
export default reducer;
