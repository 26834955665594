import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";

import {
  updateUserCreationRequest,
  fetchUserCreationListStatus,
  fetchUserByIDData,
  fetchSignUpFields,
  rejectUserSuccess,
  approveUserSuccess,
  resendApprovedEmailSuccess,
  fetchUserByIDDataStatus,
  fetchingSignUpFieldsStatus,
} from "./actions";

import {
  USER_CREATION_REQUEST_FETCH,
  APPROVE_USER_REQUEST,
  REJECT_USER_REQUEST,
  GET_USER_BY_ID,
  SAVE_USER_CREATION,
  INIT_DOWNLOAD_REQ_ATTACHMENT,
  INIT_FETCH_SIGNUP_FIELDS,
  INIT_RESEND_APPROVED_EMAIL,
} from "./actionTypes";
import axios from "../../../config/axiosCall";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";

// To-do: Add once Reports Module added
// import {
//   selectAdminRoleDetails,
//   selectSelectedUserId,
// } from "../../ReportDetails/store/selectors";

export default function* watchRequestsManagement() {
  yield all([
    takeEvery(USER_CREATION_REQUEST_FETCH, getUserCreationRequestListSaga),
    takeEvery(APPROVE_USER_REQUEST, approveUserRequest),
    takeEvery(REJECT_USER_REQUEST, rejectUserRequest),
    takeEvery(GET_USER_BY_ID, getUserByIdSaga),
    takeEvery(SAVE_USER_CREATION, saveUserCreationSaga),
    takeEvery(INIT_DOWNLOAD_REQ_ATTACHMENT, downloadAttachmentSaga),
    takeEvery(INIT_FETCH_SIGNUP_FIELDS, getSignUpFieldsSaga),
    takeEvery(INIT_RESEND_APPROVED_EMAIL, resendApprovedEmailSaga),
  ]);
}

function* getUserCreationRequestListSaga(action) {
  yield put(fetchUserCreationListStatus(true));
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let languageId = sessionDetails && sessionDetails.languageId;
  let url = `/Users/ListUserCreationRequest?languageID=${languageId || "enGB"}&`;
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + data + "=" + action.data[data] + "&";
      }
    });
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(fetchUserCreationListStatus(false));
    yield put(
      updateUserCreationRequest(
        response.data.userRequest,
        response.data.statusCounts,
        response.data.totalCount
      )
    );
  } catch (err) {
    yield put(updateUserCreationRequest([]));
    yield put(fetchUserCreationListStatus(false));
  }
}

function* approveUserRequest(action) {
  yield put(approveUserSuccess(false));
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = { ...action.payload });
  try {
    const API_URL = "Users/AccountApproved";

    const response = yield call(axios, API_URL, requestDetails);
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "msp.userCompanyApproved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(approveUserSuccess(true));
    }
    if (action.callback) action.callback();
  } catch (error) {
    yield put(approveUserSuccess(false));
    if (action.callback) action.callback();
  }
}

function* rejectUserRequest(action) {
  yield put(rejectUserSuccess(false));
  const API_URL = "Users/AccountRejected";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.payload);
  try {
    const response = yield call(axios, API_URL, requestDetails);
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "msp.userCompanyRejected",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(rejectUserSuccess(true));
    }
  } catch (error) {
    yield put(rejectUserSuccess(false));
  }
}

function* getUserByIdSaga(action) {
  yield put(fetchUserByIDDataStatus(true));
  const URL =
    "/Users/GetUserCreationRequestByID?userCompanyDetailID=" +
    action.companyDetailID;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, URL, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchUserByIDData(response.data));
      if (action.callBackFunction) {
        action.callBackFunction(response.data);
      }
      yield put(fetchUserByIDDataStatus(false));
    }
  } catch (err) {
    yield put(fetchUserByIDDataStatus(true));
  }
}

function* saveUserCreationSaga(action) {
  const URL = "/Users/saveUserCreationRequest";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.data);
  try {
    const response = yield call(axios, URL, requestDetails);
    if (response && response.status === 202) {
      const notification = buildNotification({
        message: "msp.dataSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      if (action.callback) {
        return action.callback();
      }
    }
  } catch (err) {
    console.log(err);
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* downloadAttachmentSaga(action) {
  const url =
    "/Users/downloadUserRequestAttachment?userCompanyDetailID=" + action.id;
  try {
    const response = yield call(axios, url, { responseType: "blob" });
    if (response) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", action.fileName);
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "msp.attachmentDownloadSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    console.log(err);
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* getSignUpFieldsSaga(action) {
  yield put(fetchingSignUpFieldsStatus(true));
  const url = `/Login/signupfields?languageID=${action.langId}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);

    if (response && response.status === 200) {
      yield put(fetchingSignUpFieldsStatus(false));
      yield put(fetchSignUpFields(response.data));
    }
  } catch (error) {
    yield put(fetchingSignUpFieldsStatus(false));
  }
}

function* resendApprovedEmailSaga(action) {
  yield put(resendApprovedEmailSuccess(false));
  const URL = "/Users/ResendApprovedEmail";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.data);
  try {
    const response = yield call(axios, URL, requestDetails);
    if (response && response.status === 202) {
      yield put(resendApprovedEmailSuccess(true));
      const notification = buildNotification({
        message: "msp.approvedEmailSent",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    yield put(resendApprovedEmailSuccess(false));
  }
}
