import { createSelector } from "reselect";
import { initialState } from "./reducer";

export const selectClient = (state) => state.get("clients", initialState);

export const selectClientDetailsFetchStatus = () =>
  createSelector(selectClient, (VPState) =>
    VPState.get("isClientDetailsFetching")
  );
export const selectClientDetails = () =>
  createSelector(selectClient, (VPState) => VPState.get("clientDetails"));

export const selectClientsListFetchStatus = () =>
  createSelector(selectClient, (VPState) => VPState.get("fetchingClientsList"));

export const selectClientsList = () =>
  createSelector(selectClient, (VPState) => VPState.get("clientsList"));

export const selectContactListFetchStatus = () =>
  createSelector(selectClient, (VPState) => VPState.get("fetchingContactList"));

export const selectContactList = () =>
  createSelector(selectClient, (VPState) => VPState.get("contactList"));

export const selectContactSavingStatus = () =>
  createSelector(selectClient, (VPState) => VPState.get("savingContact"));

export const selectClientHelpdeskSaveStatus = () =>
  createSelector(selectClient, (VPState) =>
    VPState.get("savingClientHelpdesk")
  );

export const selectContactEmailListFetchStatus = () =>
  createSelector(selectClient, (VPState) =>
    VPState.get("fetchingContactEmailList")
  );

export const selectContactEmailList = () =>
  createSelector(selectClient, (VPState) => VPState.get("contactEmailList"));

export const selectCountryContactListFetchStatus = () =>
  createSelector(selectClient, (VPState) =>
    VPState.get("fetchingCountryContactList")
  );

export const selectCountryContactList = () =>
  createSelector(selectClient, (VPState) => VPState.get("countryContactList"));

export const selectContactDeleteStatus = () =>
  createSelector(selectClient, (VPState) => VPState.get("deletingContact"));

export const selectClientDataByIdFetchStatus = () =>
  createSelector(selectClient, (VPState) =>
    VPState.get("fetchingClientDataById")
  );

export const selectClientDataSaveStatus = () =>
  createSelector(selectClient, (VPState) => VPState.get("savingClientData"));
