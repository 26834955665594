export const INIT_LIST_HEADER_ATTRS_FETCH = "INIT_LIST_HEADER_ATTRS_FETCH";
export const FETCHING_LIST_HEADER_ATTRS = "FETCHING_LIST_HEADER_ATTRS";
export const FETCH_LIST_HEADER_ATTRS = "FETCH_LIST_HEADER_ATTRS";
export const LIST_HEADER_ATTRS_FETCH_COMPLETE =
  "LIST_HEADER_ATTRS_FETCH_COMPLETE";

export const INIT_LIST_LINE_ATTRS_FETCH = "INIT_LIST_LINE_ATTRS_FETCH";
export const FETCHING_LIST_LINE_ATTRS = "FETCHING_LIST_LINE_ATTRS";
export const FETCH_LIST_LINE_ATTRS = "FETCH_LIST_LINE_ATTRS";
export const LIST_LINE_ATTRS_FETCH_COMPLETE = "LIST_LINE_ATTRS_FETCH_COMPLETE";

export const INIT_PROFILE_LIST_FETCH = "INIT_PROFILE_LIST_FETCH";
export const FETCHING_PROFILE_LIST = "FETCHING_PROFILE_LIST";
export const FETCH_PROFILE_LIST = "FETCH_PROFILE_LIST";
export const PROFILE_LIST_FETCH_COMPLETE = "PROFILE_LIST_FETCH_COMPLETE";

export const INIT_SUBMISSION_PROFILE_FETCH = "INIT_SUBMISSION_PROFILE_FETCH";
export const FETCHING_SUBMISSION_PROFILES = "FETCHING_SUBMISSION_PROFILES";
export const FETCH_SUBMISSION_PROFILE = "FETCH_SUBMISSION_PROFILE";
export const SUBMISSION_PROFILE_FETCH_COMPLETE =
  "SUBMISSION_PROFILE_FETCH_COMPLETE";

export const INIT_SUBMISSION_PROFILE_SAVE = "INIT_SUBMISSION_PROFILE_SAVE";
export const SUBMISSION_PROFILE_SAVED = "SUBMISSION_PROFILE_SAVED";
export const SUBMISSION_PROFILE_SAVE_FAILED = "SUBMISSION_PROFILE_SAVE_FAILED";

export const INIT_SUBMISSION_PROFILES_DELETE =
  "INIT_SUBMISSION_PROFILES_DELETE";
export const SUBMISSION_PROFILE_DELETING = "SUBMISSION_PROFILE_DELETING";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAILED = "DELETE_FAILED";

export const INIT_SUBMISSION_EDIT = "INIT_SUBMISSION_EDIT";
