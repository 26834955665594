import {
  INIT_FETCH_SIGNUP_FLEX_FIELDS,
  FETCHING_SIGNUP_FLEX_FIELDS,
  FETCH_SIGNUP_FLEX_FIELDS,
  FETCH_SIGNUP_FLEX_FIELDS_COMPLETE,
  INIT_SAVE_SIGNUP_FORM,
  SIGNUP_FORM_SAVED,
  SIGNUP_FORM_SAVE_FAILED,
  RESET_SAVE_STATUS,
  INIT_CHECK_USER_EXISTS,
  USER_EXIST,
  CHECK_USER_EXISTS_STATUS,
} from "./actionTypes";

export const initFetchSignUpFlexFields = (langId) => {
  return {
    type: INIT_FETCH_SIGNUP_FLEX_FIELDS,
    langId,
  };
};

export const fetchingSignUpFlexFields = () => {
  return {
    type: FETCHING_SIGNUP_FLEX_FIELDS,
  };
};

export const fetchSignUpFlexFields = (data) => {
  return {
    type: FETCH_SIGNUP_FLEX_FIELDS,
    data: data,
  };
};

export const fetchSignUpFlexFieldsComplete = () => {
  return {
    type: FETCH_SIGNUP_FLEX_FIELDS_COMPLETE,
  };
};

export const initSaveSignUpForm = (payload, callback) => {
  return {
    type: INIT_SAVE_SIGNUP_FORM,
    payload,
    callback,
  };
};

export const signUpFormSaved = () => {
  return {
    type: SIGNUP_FORM_SAVED,
  };
};

export const signUpFormSaveFailed = () => {
  return {
    type: SIGNUP_FORM_SAVE_FAILED,
  };
};

export const resetSaveStatus = () => {
  return {
    type: RESET_SAVE_STATUS,
  };
};

export const initCheckUserExists = (payload) => {
  return {
    type: INIT_CHECK_USER_EXISTS,
    payload, //this is when user gives email and calls api
  };
};

export const userExist = (data) => {
  return {
    type: USER_EXIST,
    data, //this is response of api
  };
};

export const checkUserExistsStatus = (status) => {
  return {
    type: CHECK_USER_EXISTS_STATUS,
    status,
  };
};
