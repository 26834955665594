import {
  INIT_AUTHENTICATE,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILED,
  INIT_LOGOUT,
  LOGOUT,
  CHECK_AUTH_TIMEOUT,
  CHECK_AUTH_STATUS,
  INIT_CONFIRM_GDPR_MESSAGE,
  CONFIRMING_GDPR_MESSAGE,
  GDPR_MESSAGE_CONFIRMED,
  GDPR_MESSAGE_CONFIRM_FAILED,
  ABOUT_TO_LOGOUT,
  TIMER_RESET_SUCCESS,
  INIT_REFRESH_TOKEN,
  REFRESH_TOKEN,
  FETCHING_REFRESH_TOKEN,
  FETCH_REFRESH_TOKEN,
  REFRESH_TOKEN_FETCH_FAILED,
  NOT_YET_CONFIGURED,
  SSO_LOGIN_FAILED,
  INIT_MFA_OTP,
  MFA_OTP_FAILED,
  MFA_OTP_SENT,
} from "./actionTypes";

export const initMFAOTP = (payload) => ({ type: INIT_MFA_OTP, payload });
export const MFAFailed = () => ({ type: MFA_OTP_FAILED })
export const MFASuccess = () => ({ type: MFA_OTP_SENT })

export const initAuthenticate = (payload, loginType, msalAuthToken) => {
  return {
    type: INIT_AUTHENTICATE,
    payload,
    loginType,
    msalAuthToken,
  };
};

export const authenticationSuccess = (data) => {
  return {
    type: AUTHENTICATION_SUCCESS,
    data,
  };
};

export const authenticationFailed = (message) => {
  return {
    type: AUTHENTICATION_FAILED,
    message,
  };
};
export const notYetConfigured = () => {
  return {
    type: NOT_YET_CONFIGURED,
  };
};
export const initLogout = (callback) => {
  return {
    type: INIT_LOGOUT,
    callback,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return {
    type: CHECK_AUTH_TIMEOUT,
    expirationTime,
  };
};

export const checkAuthStatus = () => {
  return {
    type: CHECK_AUTH_STATUS,
  };
};

export const initConfirmGDPRMsg = () => {
  return {
    type: INIT_CONFIRM_GDPR_MESSAGE,
  };
};
export const confirmingGDPRMsg = () => {
  return {
    type: CONFIRMING_GDPR_MESSAGE,
  };
};
export const gdprMsgConfirmed = () => {
  return {
    type: GDPR_MESSAGE_CONFIRMED,
  };
};
export const gdprMsgConfirmFailed = () => {
  return {
    type: GDPR_MESSAGE_CONFIRM_FAILED,
  };
};

export const aboutToLogout = () => {
  return {
    type: ABOUT_TO_LOGOUT,
  };
};

export const timerResetSuccess = () => {
  return {
    type: TIMER_RESET_SUCCESS,
  };
};

export const initRefreshToken = (expirationTimeInSec) => {
  return {
    type: INIT_REFRESH_TOKEN,
    expirationTimeInSec,
  };
};
export const refreshToken = () => {
  return {
    type: REFRESH_TOKEN,
  };
};
export const fetchingRefreshToken = () => {
  return {
    type: FETCHING_REFRESH_TOKEN,
  };
};
export const fetchRefreshToken = (data) => {
  return {
    type: FETCH_REFRESH_TOKEN,
    data: data,
  };
};
export const refreshTokenFetchFailed = () => {
  return {
    type: REFRESH_TOKEN_FETCH_FAILED,
  };
};

export function ssoLoginFailed(data) {
  return {
    type: SSO_LOGIN_FAILED,
    data,
  };
}
