import React, { Component, Suspense } from "react";
import { withTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import "./App.css";
import { compose } from "redux";
import { connect } from "react-redux";

import Axios from "./config/axios";

import "react-notifications-component/dist/theme.css";
import ReactNotification from "react-notifications-component";
import { Spinner } from "./components";
import { selectIsAuthenticated } from "./container/Authentication/store/selectors";
import { createStructuredSelector } from "reselect";
import {
  checkAuthStatus,
  initConfirmGDPRMsg,
  initLogout,
} from "./container/Authentication/store/actions";
import { generateFilteredRoutes } from "./routes/generateFilteredRoutes";
import AuthRoutes from "./routes/AuthRoutes";

import { logout } from "./helper";
import { initGetClientDetails } from "./container/Administration/ClientManagement/store/actions";
import { selectClientDetails } from "./container/Administration/ClientManagement/store/selectors";

import { storage } from "./config/storage";
import GlobalStyle from "./globalStyles";

import { initGetNotification } from "./container/Notifications/store/actions";
import Layout from "components/Layout/index";
import themeChange from "helper/themeCreator";
import { jsonPrs } from "helper/index";
import { GDPRMessage } from "components/index";

const UnauthLandingPage = React.lazy(() =>
  import("./container/UnauthLandingPage")
);
const Verfication = React.lazy(() => import("./container/Verfication"));

const Registration = React.lazy(() => import("./container/Registration"));

const UpdatePasswordOnLogin = React.lazy(() =>
  import("./container/UpdatePasswordOnLogin")
);

class App extends Component {
  prevAuth = false;
  constructor(props) {
    super(props);
    this.state = {
      forceLogout: false,
      showGDPR: true,

      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount() {
    this.props.autologin();
    window.globalNanoRep &&
      window.globalNanoRep.on({
        init: function () {
          this.setConfigId(process.env.REACT_APP_CHATBOT_CONFIG_ID);
          this.setKB(process.env.REACT_APP_CHATBOT_KB);
        },
        load: function () {
          this.collapse();
        },

        collapseWidget: function () {
          const chatbot = document.getElementById("nanorep1");
          if (chatbot) chatbot.style.cssText += ";display:none !important;";
        },
      });
  }

  componentDidUpdate(prevProps) {
    // console.log(window.globalNanoRep);
    const sessionDetails = JSON.parse(storage.getItem("sessionDetails"));

    if (
      this.props.isAuthenticated &&
      this.props.isAuthenticated !== prevProps.isAuthenticated
    ) {
      this.props.getClientDetails();
      this.props.i18n.changeLanguage(sessionDetails.languageId);
      this.props.getNotification();
    }
  }

  render() {
    if (
      !this.state.forceLogout &&
      !this.props.isAuthenticated &&
      this.props.isAuthenticated !== this.prevAuth
    ) {
      logout();
      return <Spinner />;
    }
    //    const { search } = this.props.history.location || {};
    this.prevAuth = this.props.isAuthenticated;
    const middleLoader = <Spinner />;

    const session = JSON.parse(storage.getItem("sessionDetails"));
    const clientDetails = JSON.parse(storage.getItem("clientDetails")) || {};
    const clientData = this.props.clientDetails || "";
    const { token, menus } = session || {};

    let application = null;
    document.documentElement.style.overflow = "";
    document.documentElement.style.maxHeight = "";
    if (this.props.isAuthenticated) {
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.maxHeight = "100vh";

      Axios.defaults.headers.Authorization = `Bearer ${token}`;
      Axios.defaults.headers["X-App-Info"] = clientDetails.token;
      if (clientData) {
        const { client = {} } = clientData;
        const { sitePrimaryColor, isExtHelpDeskLinkUsed = false } = client;
        if (sitePrimaryColor) {
          const primaryColor = jsonPrs(sitePrimaryColor);

          themeChange(session ? session.baseRoleId : 0, primaryColor);
        }

        const routes = generateFilteredRoutes(menus, isExtHelpDeskLinkUsed);
        application = (
          <Suspense fallback={middleLoader}>
            <Switch>
              <Route path="/" component={() => AuthRoutes(routes)} />
              <Redirect to="/" />
            </Switch>
          </Suspense>
        );
      } else {
        application = middleLoader;
      }
    } else {
      application = (
        <Suspense fallback={middleLoader}>
          <Switch>
            <Route path="/app/callback" component={UnauthLandingPage} />
            <Route path="/verification" component={Verfication} />
            <Route path="/registration" component={Registration} />
            <Route
              path="/update-password-on-login"
              component={UpdatePasswordOnLogin}
            />
            <Route path="/forgot-password" component={UnauthLandingPage} />
            <Route path="/reset-password" component={UnauthLandingPage} />

            <Route path="/" component={UnauthLandingPage} />
          </Switch>
        </Suspense>
      );
    }

    return (
      <>
        <GlobalStyle />
        {this.props.isAuthenticated && session.displayGDPR ? (
          <GDPRMessage
            show={this.state.showGDPR}
            gdprTitle={session.loginGDPRLabel}
            gdprMessage={session.gdprMessage}
            confirmGDPR={this.props.confirmGDPR}
            onClose={() => this.setState({ showGDPR: false })}
          />
        ) : null}

        <ReactNotification />
        <Layout
          clientDetails={clientData.client}
          isAuthenticated={this.props.isAuthenticated}
        >
          {application}
        </Layout>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  clientDetails: selectClientDetails(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    autologin: (callback) => {
      dispatch(checkAuthStatus(callback));
    },
    logout: (callback) => {
      dispatch(initLogout(callback));
    },
    getClientDetails: () => {
      dispatch(initGetClientDetails());
    },
    confirmGDPR: () => {
      dispatch(initConfirmGDPRMsg());
    },
    getNotification: () => {
      dispatch(initGetNotification());
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(withTranslation()(App));
