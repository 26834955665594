import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderText = styled.h1`
  color: var(--grey-clr);
  font-size: var(--fs-16);
  font-family: var(--ff-semibold);
  font-weight: var(--fw-normal);
  margin: 0;
  display: flex;
  flex-grow: 1;
`;

export const Wrapper = styled(Container)`
  padding: 0.5em 0 0 0;
`;

export const HeaderContent = styled(Container)`
  display: flex;
  padding-bottom: 0.2em;
  border-bottom: var(--onePx) solid rgb(181, 192, 202);
`;

export const MainContent = styled(Container)`
  padding: 1em 1em 0 1em;
  max-height: 12.5em;
  overflow: auto;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
`;
