import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorText } from "components";
import PdfControls from "../PdfViewer/pdfControls";
import { ImageViewerWrapper, ImgPrevDiv, RubberBandDiv } from "./style";
import { Spinner } from "components/index";
import useOCREventListeners from "components/Rubberband/hooks/useOCREventListeners";
import {
  scrollImage,
  zoomInImage,
  zoomOutImage,
} from "components/Rubberband/hooks/useRubberbandMouseEvents";

const ImageViewer = ({
  onDownload = () => false,
  onPageChange = () => false,
  ocrDetails = [],
  loading = false,
}) => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (ocrDetails.length > 0 && pageNumber > ocrDetails.length) {
      setPageNumber(ocrDetails.length);
    }
  }, [pageNumber, ocrDetails]);

  const pageChange = (e, isPrev = false) => {
    const newPage = isPrev ? pageNumber - 1 : pageNumber + 1;
    setPageNumber(newPage);
    onPageChange(newPage);
  };
  const gotoPage = (e) => {
    const { value } = e.target;
    if (value <= ocrDetails.length) {
      setPageNumber(Number(value));
      onPageChange(value);
    }
  };
  let filedata = "",
    fullOCRExtract = "";
  if (ocrDetails.length > 0) {
    filedata = ocrDetails[pageNumber - 1].filedata;
    fullOCRExtract = ocrDetails[pageNumber - 1].fullOCRExtract;
  }

  useOCREventListeners(fullOCRExtract);
  return (
    <ImageViewerWrapper>
      {loading ? <Spinner /> : null}
      <ImgPrevDiv loading={loading} id="imgPrevDiv">
        <RubberBandDiv onScroll={scrollImage} id="rubberBandDiv">
          {filedata ? (
            <img
              id="docImage"
              width="100%"
              alt={t("msp.ocrExtractedFile")}
              src={`data:image/png;base64, ${filedata}`}
            />
          ) : (
            <ErrorText>{t("msp.noFileContent")}</ErrorText>
          )}
        </RubberBandDiv>
      </ImgPrevDiv>
      {filedata ? (
        <PdfControls
          currentPage={pageNumber}
          maxPage={ocrDetails.length}
          prevPage={() => pageChange(null, true)}
          nextPage={() => pageChange(null)}
          gotoPage={gotoPage}
          onZoomIn={zoomInImage}
          onZoomOut={zoomOutImage}
          onDownload={onDownload}
        />
      ) : null}
    </ImageViewerWrapper>
  );
};

export default ImageViewer;
