import React from "react";
import i18next from "i18next";

import {
  Check,
  ErrorCircle /* RedirectPopout */,
  RedirectPopout,
} from "components/SVG/index";
import { buildNotification } from "config/notification";
import { currencyFormatter } from "helper/index";
import {
  PaymentStatusWrapper,
  StatusIconWrapper,
  StatusText,
  StatusWrapper,
  TD,
  BoldText,
  ActionContainer,
  ActionText,
} from "../style";
import { IconWrapper } from "components/index";

export const cappedSupplierCount = 100;

export const staticCols = [
  {
    name: "msp.paymentsStatus",
    selector: "paymentStatus",
    filterable: true,
    width: "150px",
    cell: (event) => (
      <TD>
        {event.dataItem.StatusID === 4 
        ? (
          <>
            <StatusWrapper>
              <PaymentStatusWrapper>                
                <>
                    <StatusText>{i18next.t("msp.paid")}</StatusText>
                    <StatusIconWrapper>
                      <Check />
                    </StatusIconWrapper>
                    </>                
              </PaymentStatusWrapper>
            </StatusWrapper>
          </>
        ) : (
          <>
            <PaymentStatusWrapper>
              <StatusText>{i18next.t("msp.unpaid")}</StatusText>
              <StatusIconWrapper>
                <ErrorCircle />
              </StatusIconWrapper>
            </PaymentStatusWrapper>
          </>
        )}
      </TD>
    ),
  },
];

export const generateColumns = (
  fields,
  additionalCol,
  redirectToDocSubmission,
  code,
  initResolvefn,
  resolveRejectionDisabled
) => {
  let columns = [];
  additionalCol && columns.push(...additionalCol);
  const sortedFields =
    fields && fields.sort((a, b) => a.GridOrder - b.GridOrder);
  sortedFields &&
    sortedFields.forEach((item) => {
      let column = {
        name: item.Label,
        selector: item.FieldName,
        sortable: true,
        filterable: true,
        filterType: item.FieldName.toLowerCase().includes("date")
          ? "date"
          : "text",
        omit: false,
        width: item.ColumnWidth ? item.ColumnWidth + "px" : "100px",
        cell:
          item.FieldName.toLowerCase() === "docamount"
            ? (event) => {
                const { DocAmount } = (event && event.dataItem) || 0;
                return (
                  <TD>
                    <>
                      <BoldText>
                        {currencyFormatter(Number(DocAmount), "en-US", true) ||
                          0}
                      </BoldText>
                    </>
                  </TD>
                );
              }
            : item.FieldName.toLowerCase() === "action"
            ? (event) => {
                const { Action, StatusID, Path, SubmissionId } =
                  event.dataItem || {};
                return (
                  <TD>
                    <>
                      {Action && (
                        <ActionContainer
                          action={
                            Path !== null && parseInt(StatusID) === 7
                              ? true
                              : false
                          }
                          onClick={
                            resolveRejectionDisabled || !SubmissionId || !Path
                              ? () => null
                              : () => initResolvefn(SubmissionId)
                          }
                          title={
                            resolveRejectionDisabled
                              ? i18next.t("msp.permissionAccessMessage")
                              : !SubmissionId
                              ? i18next.t("msp.invoiceFromOtherSources")
                              : ""
                          }
                        >
                          <ActionText>{Action}</ActionText>
                          {!resolveRejectionDisabled &&
                            Path !== null &&
                            parseInt(StatusID) === 7 && (
                              <IconWrapper>
                                <RedirectPopout />
                              </IconWrapper>
                            )}
                        </ActionContainer>
                      )}
                    </>
                  </TD>
                );
              }
            : item.FieldName.toLowerCase() === "referenceid"
            ? (event) => {
                const { ReferenceId } = (event && event.dataItem) || {};
                return (
                  <TD>
                    <>
                      <BoldText
                        onClick={() => redirectToDocSubmission(ReferenceId)}
                      >
                        {ReferenceId}
                      </BoldText>
                    </>
                  </TD>
                );
              }
            : null,
      };
      columns.push(column);
    });
  return columns;
};

export const tabs = [
  {
    selectorID: 2,
    selector: "Invoice Details",
    text: "msp.invoiceDetails",
  },
];

export const messages = {
  activeColumnsFetchError: buildNotification({
    message: "msp.fetchColumnErrorMessage",
    type: "danger",
  }),
  activeColumnsSaveError: buildNotification({
    message: "msp.saveColumnErrorMessage",
    type: "danger",
  }),
  supplierListFetchError: buildNotification({
    message: "msp.fetchSupplierErrorMessage",
    type: "danger",
  }),
  activeColumnsSaveSuccess: buildNotification({
    message: "msp.columnsSavedMessage",
    type: "success",
  }),
};
