import { fromJS } from "immutable";
import {
  FETCHED_ADV_PAYMENT_COLS,
  FETCHING_ADV_PAYMENT_COLS,
  FETCH_ADV_PAYMENT_COLS,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingAdvCols: false,
  advCols: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_ADV_PAYMENT_COLS: {
      return state.set("fetchingAdvCols", true);
    }
    case FETCH_ADV_PAYMENT_COLS: {
      return state.set("advCols", fromJS(action.data));
    }
    case FETCHED_ADV_PAYMENT_COLS: {
      return state.set("fetchingAdvCols", false);
    }
    default:
      return state;
  }
};

export default reducer;
