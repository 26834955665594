import styled from "styled-components";

import { Cols } from "components";
import Overlay from "components/Overlay/index";

export const SupplierLookupContainer = styled.div`
  ${Overlay} {
    z-index: 99;
  }
`;

export const BtnWrapper = styled(Cols)`
  justify-content: flex-end;
  padding: 1em;
`;

export const DataTableContainer = styled.div`
  overflow: auto;
  padding: 0.5em 1em;
`;

export const SearchWrapper = styled(Cols)`
  padding: 0.5em 1em;
`;
