import SvgIcon from "./style";

const ShortRightArrow = () => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-630.000000, -219.000000)">
          <g transform="translate(378.000000, 144.000000)">
            <g transform="translate(251.000000, 74.000000)">
              <g transform="translate(8.000000, 8.000000) rotate(-360.000000) translate(-8.000000, -8.000000) ">
                <path d="M7.81565625,1.19065623 L7.1969375,1.80937498 C7.0505,1.95581248 7.0505,2.19324999 7.1969375,2.33971874 L12.04475,7.1875 L1.37499998,7.1875 C1.16790623,7.1875 0.999999983,7.35540625 0.999999983,7.5625 L0.999999983,8.4375 C0.999999983,8.64459375 1.16790623,8.8125 1.37499998,8.8125 L12.04475,8.8125 L7.1969375,13.6603125 C7.0505,13.80675 7.0505,14.0441875 7.1969375,14.1906563 L7.81565625,14.809375 C7.96209375,14.9558125 8.19953125,14.9558125 8.346,14.809375 L14.8901875,8.2651875 C15.036625,8.11875 15.036625,7.8813125 14.8901875,7.73484375 L8.34596875,1.19065623 C8.19953125,1.04418748 7.96209375,1.04418748 7.81565625,1.19065623 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default ShortRightArrow;
