import { dbDateFormat } from "config/index";
import moment from "moment";
import i18next from "i18next";
import { store } from "react-notifications-component";

import { storage } from "../config/storage";
import {
  authRoutes,
  // unAuthRoutes,
  commonAuthRoutes,
  // documentSubmissionClientConfigRoutes,
} from "../routes/routes";
import { buildNotification } from "config/notification";

export const getNavigatorLanguage = () => {
  switch (navigator.language) {
    case "en-US":
      return "enGB";
    case "zh-cn":
      return "chCH";
    case "ar":
      return "arAR";
    case "cs":
      return "csCZ";
    case "de":
      return "deDE";
    case "es":
      return "esES";
    case "fr":
      return "frFR";
    case "hu":
      return "huHU";
    case "id":
      return "inIN";
    case "it":
      return "itIT";
    case "ja":
      return "jpJP";
    case "ko":
      return "krKR";
    case "nl":
      return "nlNL";
    case "pl":
      return "plPL";
    case "pt":
      return "ptPT";
    case "ro":
      return "roRO";
    case "ru":
      return "ruRU";
    case "sk":
      return "slSL";
    case "sv":
      return "svSE";
    case "th":
      return "thTH";
    default:
      return "enGB";
  }
};

export const jsonStr = (json) => JSON.stringify(json);

export const jsonPrs = (jsonString) =>
  jsonString ? JSON.parse(jsonString) : "";
const sessionDetails = jsonPrs(storage.getItem("sessionDetails"));

export const { dateFormat } = sessionDetails || {};

export const logout = () => {
  const clientDetails = JSON.parse(storage.getItem("clientDetails")) || {};
  window.location.href = `/?${clientDetails.name || ""}`;
  // window.location.href = `/?${atob(clientDetails.name) || ""}`;
};

const base64ToUint8Array = (string) => {
  let raw = atob(string);
  let rawLength = raw.length;
  let array = new Uint8Array(new ArrayBuffer(rawLength));
  for (let i = 0; i < 4; i += 1) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};

const fileReader = (file) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      let arr = base64ToUint8Array(fr.result.split(",")[1]);
      let header = "";
      arr.forEach((item) => {
        header += item.toString(16);
      });
      if (header.includes("4d5a") || header.includes("5a4d")) {
        reject({
          message: `${i18next.t("msp.invalidFile")} ${file.name}, ${i18next.t(
            "msp.exeFile"
          )}`,
        });
      } else {
        resolve({
          filename: file.name,
          fileContent: fr.result.split(",")[1],
          filesize: file.size,
        });
      }
    };
    fr.readAsDataURL(file);
  });
};

export const getFileContent = async (file) => {
  try {
    return await fileReader(file);
  } catch (e) {
    throw e;
  }
};

export const handleNumberInput = (event, allowDecimal = true) => {
  if (event.key === "." && !allowDecimal) {
    event.preventDefault();
  }
  if (event.key === "." && event.target.value.split(".").length === 2) {
    event.preventDefault();
  }
  if (event.key === "e" || event.key === "+") {
    event.preventDefault();
  }
};

export const truncate = (input, maxLength) =>
  input && input.length > maxLength
    ? `${input.substring(0, maxLength)}...`
    : input;

export const listSearch = (dataObj, lookFor, cols = []) => {
  const sessionDetails = jsonPrs(storage.getItem("sessionDetails"));
  const { dateFormat } = sessionDetails || {};
  let visibleCols = [];
  // if (process.env.NODE_ENV !== "development") {
  visibleCols = cols.filter((col) => !col.omit).map((col) => col.selector);
  // }
  const copyDataObj = visibleCols.length
    ? visibleCols.reduce((result, current) => {
        result = { ...result, [current]: dataObj[current] };
        return result;
      }, {})
    : { ...dataObj };

  const keys = Object.keys(copyDataObj);

  keys.forEach((key) => {
    if (isValidDate(copyDataObj[key]))
      copyDataObj[key] = moment(copyDataObj[key]).format(dateFormat);
  });

  return copyDataObj
    ? lookFor
      ? jsonStr(Object.values(copyDataObj))
          .toLowerCase()
          .includes(lookFor.toString().toLowerCase().trim())
      : true
    : false;
};

export const toHHMMSS = (timeInSec) => {
  const sec_num = timeInSec;
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = Math.floor(sec_num - hours * 3600 - minutes * 60);

  if (hours > 0 && hours < 10) {
    hours = "0" + hours + ":";
  } else {
    hours = "";
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + minutes + ":" + seconds;
};

export const isValidDate = (value, format = dbDateFormat) => {
  return typeof value === "string" &&
    value.length > 9 &&
    (value.indexOf("/") > -1 || value.indexOf("-") > -1)
    ? moment(value, moment.ISO_8601, true).isValid()
    : false;
};

export const getRoutePathByKey = (key, params = null) => {
  const routes = [
    ...authRoutes,
    // ...unAuthRoutes,
    ...commonAuthRoutes,
    // ...documentSubmissionClientConfigRoutes,
  ];
  let foundRoute = routes.find((route) => route.key === key);
  if (params && foundRoute) {
    Object.keys(params).forEach((param) => {
      foundRoute.path = foundRoute.path.replace(
        `:${param}`,
        `${param}=${params[param]}`
      );
    });
  }

  return foundRoute ? foundRoute.path : "";
};

export const convertDateTime = (date) => {
  const currentDate = moment(moment(new Date(date)).format("YYYY-MM-DD"))._i;
  return currentDate;
};

export const currencyFormatter = (
  value,
  locale = "en-US",
  conversion = false.valueOf,
  code
) => {
  const localeValue = locale;
  const params = { minimumFractionDigits: 0, maximumFractionDigits: 2 };

  if (!isNaN(value) && value !== 0) {
    const formatter = new Intl.NumberFormat(localeValue, {
      ...params,
    });
    let result = 0;
    result = formatter.format(value);

    if (code) return code + " " + result;
    else return result;
  }
  return "";
};

export const convertToCSV = (listData, colHeaders) => {
  let result;
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const columnSelector =
    colHeaders &&
    colHeaders
      .filter((column) => !column.omit)
      .map((column) => column.selector);
  const sessionDetails = jsonPrs(storage.getItem("sessionDetails"));
  const dateFormat = sessionDetails.dateFormat
    ? sessionDetails.dateFormat
    : dbDateFormat;

  const columnName =
    colHeaders &&
    colHeaders
      .filter((column) => !column.omit)
      .map((column) => {
        let colName = column.name;
        if (typeof colName === "string" && colName.includes(`"`)) {
          colName = colName.replaceAll(/"/g, `""`);
        }
        return colName ? `"${colName}"` : "";
      });
  result = "";
  result += columnName.join(columnDelimiter);
  result += lineDelimiter;
  listData.forEach((item) => {
    let ctr = 0;
    columnSelector.forEach((selector) => {
      if (ctr > 0) result += columnDelimiter;
      if (selector.toLowerCase().includes("date") && item[selector]) {
        result += moment(item[selector]).format(dateFormat);
      } else {
        let colName = item[selector];
        if (typeof colName === "string" && colName.includes(`"`)) {
          colName = colName.replaceAll(/"/g, `""`);
        }
        if (selector.toLowerCase().includes("docamount") && item[selector] === 0)
          result += `"${colName}"`;
        else 
          result += colName ? `"${colName}"` : "";
      }
      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
};

export const download = (fileContent, filename, mimeType) => {
  if (fileContent) {
    const fileMetadata = {
      content: fileContent,
      mime: mimeType,
      name: filename,
    };
    console.log(fileMetadata);
    const url = fileMetadata.mime + fileMetadata.content;
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", fileMetadata.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

export const getRegExp = (regex) => {
  let newRegEx = false;

  if (regex) {
    const regExStr = regex ? String.raw`${regex}` : ``;
    const match = regExStr.match(/\/(.*)\/([a-z]*)/);
    if (match) {
      const [, pattern, flags] = match;
      newRegEx = new RegExp(pattern, flags);
    } else {
      newRegEx = new RegExp(regex);
    }
  }
  return newRegEx;
};

export const getRecordOutOfView = () => {
  let count = 0;
  const container = document.getElementById("items");
  if (container) container.style.position = "relative";
  const getVisibleItems = () => {
    let outOfview = 0;
    const childItems = document.getElementsByName("item");
    [...childItems].forEach((element) => {
      if (elementAfterScroll(element)) {
        outOfview += 1;
      }
    });
    count = outOfview;
  };
  getVisibleItems();
  window.addEventListener("resize", getVisibleItems);
  if (container) container.addEventListener("scroll", getVisibleItems);
  function elementAfterScroll(el) {
    const top = el.offsetTop;
    const height = el.offsetHeight;
    const style = getComputedStyle(el);
    const containerHeight = container
      ? container.scrollTop + container.clientHeight
      : 0;
    const itemHeight =
      top + height + parseInt(style.marginBottom) + parseInt(style.marginTop);
    const outofView =
      top > Math.ceil(containerHeight) ||
      itemHeight > Math.ceil(containerHeight)
        ? true
        : false;
    return outofView;
  }

  return count;
};

export const getLocale = (langKey) => {
  switch (langKey) {
    case "chCH":
      return "zh";
    case "csCZ":
      return "cs";
    case "deDE":
      return "de";
    case "enGB":
      return "en";
    case "esES":
      return "es";
    case "frFR":
      return "fr";
    case "huHU":
      return "hu";
    case "itIT":
      return "it";
    case "jpJP":
      return "ja";
    case "krKR":
      return "ko";
    case "nlNL":
      return "nl";
    case "plPL":
      return "pl";
    case "ptPT":
      return "pt";
    case "roRO":
      return "ro";
    case "ruRU":
      return "ru";
    case "slSL":
      return "sk";
    case "svSE":
      return "sv";
    case "thTH":
      return "th";
    case "trTR":
      return "tr";
    default:
      return "en";
  }
};

export const fileExtensionValidation = (file) => {
  const { name } = file || {};
  if (file && (!file.name || !/^[^.]+.[^.]+$/.test(file.name))) {
    return {
      code: "multiple-extension",
      message: `${i18next.t("msp.invalidFile")} ${name}, ${i18next.t(
        "msp.invalidFileExtension"
      )}`,
    };
  }
  return null;
};

export const handleFileErrors = (errors = [], additionalinfo = {}) => {
  const { maxSize } = additionalinfo;
  const errMsg = errors
    ? errors
        .map((error) => {
          return error.code === "file-too-large"
            ? `${i18next.t("msp.sorryMsg")}, ${i18next.t(
                "msp.fileIsLargerThan"
              )}
             ${maxSize} MB. ${i18next.t("msp.reduceSizeMsg")}`
            : error.message;
        })
        .join(", ")
    : "";
  const notification = buildNotification({
    message: errMsg,
    type: "danger",
  });

  store.addNotification({
    ...notification,
  });
};

//Databable Reset Page Index Function
export const shouldPageBeResetted = (pageConfig, currentItemCount) => {
  const { pageIndex, pageSize } = pageConfig;
  const computedPageIndex = Math.floor(currentItemCount / pageSize);
  const updatedPageIndex =
    currentItemCount % pageSize === 0
      ? computedPageIndex
      : computedPageIndex + 1;

  let shouldReset = false;

  if (pageIndex !== 1 && updatedPageIndex !== pageIndex) {
    shouldReset = true;
  }

  return shouldReset;
};

export const getGracePeriodOptions = (maxGracePeriod) => {
  const gracePeriod = isNaN(parseInt(maxGracePeriod))
    ? 0
    : parseInt(maxGracePeriod);
  return Array(gracePeriod)
    .fill()
    .map((_, index) => ({ id: index + 1, value: index + 1 }));
};

export const isJson = (str) => {
  str = typeof str !== "string" ? JSON.stringify(str) : str;

  try {
    str = JSON.parse(str);
  } catch (e) {
    return false;
  }

  return !!(typeof str === "object" && str !== null);
};

export const showNotPermittedNotification = (
  message = "Action not permitted on search results"
) => {
  const notification = buildNotification({
    message,
    type: "danger",
  });
  store.addNotification({
    ...notification,
  });
};

export const getMimeType = (fileContent = "", fileName = "") => {
  if (!fileContent || !fileName) return "";
  let arr = base64ToUint8Array(fileContent);
  let header = "";
  arr.forEach((item) => {
    header += item.toString(16);
  });
  if (header.includes("25504446")) {
    return "application/pdf";
  }
  if (header.includes("504b")) {
    if (fileName.endsWith(".docx")) {
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }
    if (fileName.endsWith(".xlsx")) {
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    if (fileName.endsWith(".zip")) {
      return "application/zip";
    }
    return "";
  }
  if (header.includes("d0cf11e0")) {
    if (fileName.endsWith(".doc")) {
      return "application/msword";
    }
    if (fileName.endsWith(".xls")) {
      return "application/vnd.ms-excel";
    }
    return "";
  }
  if (header.includes("ffd8ff")) {
    return "image/jpeg";
  }
  if (header.includes("89504e47")) {
    return "image/png";
  }
  if (header.includes("22506179")) {
    return "text/csv";
  }
  if (
    header.includes("3c526f6f") ||
    header.includes("4c6fa794") ||
    (header.includes("3c") && header.includes("3f"))
  ) {
    return "application/xml";
  }
  return "";
};
