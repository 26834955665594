import styled from "styled-components";

export const ColorPickerWrapper = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
`;

export const PickerBlock = styled.div`
  border-radius: 0.25rem;
  width: 2rem;
  height: 2rem;
  background: ${(props) => (props.bgColor ? props.bgColor : "")};
`;
