export const INIT_FETCH_SUBMITTED_INVOICE = "INIT_FETCH_SUBMITTED_INVOICE";
export const FETCHING_SUBMITTED_INVOICE = "GETING_SUBMITTED_INVOICE";
export const SUBMITTED_INVOICE_FETCH_SUCCESS =
  "SUBMITTED_INVOICE_FETCH_SUCCESS";
export const SUBMITTED_INVOICE_FETCH_FAILED = "SUBMITTED_INVOICE_FETCH_FAILED";

export const INIT_FETCH_BULK_SUBMITTED_INVOICE =
  "INIT_FETCH_BULK_SUBMITTED_INVOICE";
export const FETCHING_BULK_SUBMITTED_INVOICE =
  "FETCHING_BULK_SUBMITTED_INVOICE";
export const BULK_SUBMITTED_INVOICE_FETCH_SUCCESS =
  "BULK_SUBMITTED_INVOICE_FETCH_SUCCESS";
export const BULK_SUBMITTED_INVOICE_FETCH_FAILED =
  "BULK_SUBMITTED_INVOICE_FETCH_FAILED";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_BULK_BUCKET = "SET_BULK_BUCKET";

export const GET_LOOKUPS = "GET_LOOKUPS";
export const SET_LOOKUPS = "SET_LOOKUPS";

export const INIT_CHECK_DUPLICATE_INVOICE = "INIT_CHECK_DUPLICATE_INVOICE";
export const SET_DUPLICATE_INVOICE_STATUS = "SET_DUPLICATE_INVOICE_STATUS";
export const DUPLICATE_INVOICE_CHECK_FAILED = "DUPLICATE_INVOICE_CHECK_FAILED";

export const SHOW_LOOKUP_BY_ID = "SHOW_LOOKUP_BY_ID";
export const SET_CURRENT_LOOKUP = "SET_CURRENT_LOOKUP";

export const SET_PAGE_CONFIG = "SET_PAGE_CONFIG";
export const UPDATE_RESET_PAGE = "UPDATE_RESET_PAGE";

export const INIT_FETCH_SUPPORTING_DOCUMENTS =
  "INIT_FETCH_SUPPORTING_DOCUMENTS";
export const FETCHING_SUPPORTING_DOCUMENTS = "FETCHING_SUPPORTING_DOCUMENTS";
export const SUPPORTING_DOCUMENTS_FETCHED = "SUPPORTING_DOCUMENTS_FETCHED";
export const SUPPORTING_DOCUMENTS_FAILED = "SUPPORTING_DOCUMENTS_FAILED";

export const INIT_UPLOAD_SD = "INIT_UPLOAD_SD";
export const SUPPORTING_DOCUMENTS_UPLOAD_COMPLETED =
  "SUPPORTING_DOCUMENTS_UPLOAD_COMPLETED";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SUPPORTING_DOCUMENTS_UPLOAD_FAILED =
  "SUPPORTING_DOCUMENTS_UPLOAD_FAILED";

export const SET_DOWNLOADINGS = "SET_DOWNLOADINGS";

export const INIT_GET_TOLERANCE_VALUE = "INIT_GET_TOLERANCE_VALUE";
export const SET_TOLERANCE_VALUE = "SET_TOLERANCE_VALUE";
export const TOLERANCE_VALUE_FETCH_FAILED = "SET_TOLERANCE_VALUE";

export const UPDATE_LINE_ITEMS = "UPDATE_LINE_ITEMS";
export const CLEAN_INVOICE_DETAILS = "CLEAN_INVOICE_DETAILS";

export const FETCHING_REJECTED_INVOICE = "FETCHING_REJECTED_INVOICE";
export const REJECTED_INVOICE_FETCH_FAILED = "REJECTED_INVOICE_FETCH_FAILED";
export const REJECTED_INVOICE_FETCH_SUCCESS = "REJECTED_INVOICE_FETCH_SUCCESS";
