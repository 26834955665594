import styled from "styled-components";

const NavTabStyled = styled.ul`
  display: flex;
  overflow-x: auto;
  gap: 0.5em;
`;

export const NavTabItem = styled.li`
  font-weight: var(--fw-normal);
  color: ${(props) =>
    props.active ? `var(--dark-clr)` : `var(--primary-text-clr)`};
  font-family: ${(props) => (props.active ? `var(--ff-medium)` : ``)};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  cursor: pointer;
  gap: 0.2em;
  ::after {
    content: "";
    height: 0.25em;
    width: 100%;
    border-radius: 6.25em 6.25em 0 0;
    background: ${(props) => (props.active ? `var(--primary-gradient)` : ``)};
  }
  :hover,
  :focus {
    background-color: var(--light-grey-clr);
  }
  p {
    font-size: clamp(var(--fs-12), 4vw, var(--fs-14));
    padding: 0.5em 0.5em 0 0.5em;
  }
`;

export default NavTabStyled;
