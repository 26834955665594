import React from "react";
import SvgIcon from "./style";

const Pencil = () => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-855.000000, -842.000000)">
          <g transform="translate(855.000000, 842.000000)">
            <path d="M23.1213282,2.63606248 L21.3639844,0.878718722 C20.1924375,-0.292828156 18.2929688,-0.292875031 17.1213281,0.878718722 L0.601781222,17.3982188 L0.00707809502,22.7505938 C-0.0725156552,23.467125 0.533015596,24.0726094 1.2494531,23.9929688 L6.60182811,23.3982657 L23.121375,6.87871874 C24.292875,5.70712498 24.292875,3.80760935 23.1213282,2.63606248 Z M18.6590156,9.21965624 L8.15624999,19.7224219 L8.15624999,18.09375 L5.90624998,18.09375 L5.90624998,15.84375 L4.27757811,15.84375 L14.7803438,5.34098436 L18.6590156,9.21965624 Z M5.91314061,21.9655313 L3.14774998,22.2727969 L1.7272031,20.85225 L2.03446873,18.0868594 L2.9650781,17.15625 L4.59374998,17.15625 L4.59374998,19.40625 L6.84374999,19.40625 L6.84374999,21.0349219 L5.91314061,21.9655313 Z M22.0606407,5.81803123 L19.7196563,8.15901562 L15.8409844,4.28034373 L18.1819688,1.93935935 C18.7681406,1.35318747 19.7170313,1.35309372 20.3032969,1.93935935 L22.0606407,3.6967031 C22.6468594,4.28292186 22.6469063,5.23171873 22.0606407,5.81803123 L22.0606407,5.81803123 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Pencil;
