import {
  put,
  takeEvery,
  call,
  all,
  take,
  fork,
  delay,
  select,
} from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import axios from "../../../config/axiosCall";

import {
  getRequestDetails,
  postRequestDetails,
  putRequestDetails,
} from "../../../config/requestHeaders";

import {
  fetchingList,
  fetchList,
  fetchEditList,
  fetchingEditList,
  fetchOwnershipStatus,
  fetchingContactDetails,
  fetchContactDetails,
  fetchingAttributesDetails,
  fetchLocationDetails,
  fetchingLocationDetails,
  initEditList,
  fetchingAttributesDetailsSuccess,
  fetchAttributesDetails,
  fetchingEditListSuccess,
  setWorkFlowStepData,
  setDiffData,
  fetchNewCompanyCreation,
  setOwnershipMessage,
  takeProxyStatus,
  setWorkflowState,
  setContactDiffData,
  setLocationDiffData,
  initAttributesDetails,
  inviteRefreshComplete,
  saveLogoComplete,
  setAuditTrailList,
  setAuditDataById,
  setLogo,
  clearAuditTrailSuccess,
  initAuditTrailList,
  initGetLogo,
  fetchFlexFieldsStatus,
  setFlexFields,
  rejectRemoveCompanySuccess,
  rejectCompanySuccess,
  saveContactSuccess,
  saveLocationSuccess,
  diffDataStatus,
  locationDiffStatus,
  contactDiffStatus,
  uploadingStart,
  uploadingFinish,
  uploadingReset,
  setUploadingProgress,
  saveAttachmentSuccess,
  getLogoStatus,
  fetchListComplete,
  auditTrailListFetchStatus,
  setVmdRequestDataForHelpdesk,
  takeProxyComplete,
  savingDataShowLoader,
} from "./actions";

import {
  INIT_EMAIL_INVITE,
  INIT_LIST_FETCH,
  INIT_EDIT_LIST_FETCH,
  INIT_TAKE_OWNERSHIP,
  INIT_CONTACT_DETAILS,
  INIT_ATTRIBUTES_DETAILS,
  SAVE_CONTACT,
  SAVE_DYNAMIC_TABS_DATA,
  INIT_LOCATION_DETAILS,
  SAVE_LOCATION,
  REMOVE_CONTACT,
  REMOVE_ATTACHMENT,
  GET_WORKFLOW_STEP_DATA,
  SAVE_BULK_LOCATIONS,
  GET_DIFF_DATA,
  SAVE_WORKFLOW_STEP_DATA,
  INIT_NEW_COMPANY_CREATION,
  DOWNLOAD_VMD_ATTACHMENT,
  INIT_TAKE_PROXY,
  INIT_REJECT_REMOVE,
  INIT_REJECT,
  INIT_WOKFLOW_STATE,
  GET_CONTACT_DIFF_DATA,
  GET_LOCATION_DIFF_DATA,
  INIT_INVITE_REFRESH,
  INIT_SAVE_LOGO,
  INIT_AUDIT_TRAIL_LIST,
  INIT_AUDIT_TRAIL_DATA,
  INIT_GET_LOGO,
  INIT_CLEAR_AUDIT_TRAIL,
  SAVE_ATTACHMENT,
  INIT_FETCH_FLEX_FIELDS,
  INIT_COMBINED_SAGA_CALL,
  FETCH_EDIT_DATA_BY_NAME,
  INIT_GET_VMD_REQUEST_DATA_FOR_HELPDESK,
} from "./actionTypes";
import { createUploader } from "../../../helper/fileUploadWatcher";
import { selectEditListData } from "./selectors";

export default function* watchCustomListFetch() {
  yield all([
    takeEvery(INIT_EMAIL_INVITE, sendEmailInviteSaga),
    takeEvery(INIT_LIST_FETCH, getListDataSaga),
    takeEvery(INIT_EDIT_LIST_FETCH, getEditListDataSaga),
    takeEvery(INIT_TAKE_OWNERSHIP, initTakeOwnerShipSaga),
    takeEvery(INIT_CONTACT_DETAILS, getContactDetailsSaga),
    takeEvery(INIT_ATTRIBUTES_DETAILS, getAttributesDetailsSaga),
    takeEvery(SAVE_CONTACT, saveContactSaga),
    takeEvery(REMOVE_CONTACT, removeContactSaga),
    takeEvery(SAVE_DYNAMIC_TABS_DATA, saveDynamicTabsDataSaga),
    takeEvery(INIT_LOCATION_DETAILS, getLocationDetailsSaga),
    takeEvery(SAVE_LOCATION, saveLocationSaga),
    takeEvery(SAVE_BULK_LOCATIONS, saveBulkLocationsSaga),
    takeEvery(REMOVE_ATTACHMENT, removeAttachmentSaga),
    takeEvery(GET_WORKFLOW_STEP_DATA, getWorkFlowStepDataSaga),
    takeEvery(SAVE_WORKFLOW_STEP_DATA, saveWorkflowStepDataSaga),
    takeEvery(GET_DIFF_DATA, getDiffSaga),
    takeEvery(INIT_NEW_COMPANY_CREATION, initNewCompanyCreationSaga),
    takeEvery(DOWNLOAD_VMD_ATTACHMENT, downloadAttachmentSaga),
    takeEvery(INIT_TAKE_PROXY, takeProxySaga),
    takeEvery(INIT_REJECT_REMOVE, rejectRemoveCompanySaga),
    takeEvery(INIT_REJECT, rejectCompanySaga),
    takeEvery(INIT_WOKFLOW_STATE, getWorkflowStateSaga),
    takeEvery(GET_CONTACT_DIFF_DATA, getContactDiffDataSaga),
    takeEvery(GET_LOCATION_DIFF_DATA, getLocationDiffDataSaga),
    takeEvery(INIT_INVITE_REFRESH, initInviteRefreshSaga),
    takeEvery(INIT_SAVE_LOGO, saveLogoSaga),
    takeEvery(INIT_AUDIT_TRAIL_LIST, getAuditTrailSaga),
    takeEvery(INIT_AUDIT_TRAIL_DATA, getAuditTrailDetailsSaga),
    takeEvery(INIT_GET_LOGO, getLogoSaga),
    takeEvery(INIT_CLEAR_AUDIT_TRAIL, clearAuditTrailSaga),
    takeEvery(SAVE_ATTACHMENT, saveAttachmentSaga),
    takeEvery(INIT_FETCH_FLEX_FIELDS, getFlexFieldsSaga),
    takeEvery(INIT_COMBINED_SAGA_CALL, combinedSaga),
    takeEvery(FETCH_EDIT_DATA_BY_NAME, getEditDataByNameSaga),
    takeEvery(
      INIT_GET_VMD_REQUEST_DATA_FOR_HELPDESK,
      getVmdRequestDataForHelpdesk
    ),
  ]);
}

function* sendEmailInviteSaga(action) {
  const url = "/Supplier/inviteSupplier?emailID=" + action.data;
  const requestDetails = { ...postRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      const notification = buildNotification({
        message: "msp.emailInviteSentSuccess",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
      // yield put(emailInviteSent());
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* getListDataSaga(action) {
  yield put(fetchingList());
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let url = "/Supplier?langID=" + languageID + "&";
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + data + "=" + action.data[data] + "&";
      }
    });
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && (response.status === 200 || response.status === 202)) {
      yield put(fetchList(response.data));
    }
    yield put(fetchListComplete());
  } catch (error) {
    yield put(fetchListComplete());
  }
}

function* getEditListDataSaga(action) {
  yield put(fetchingEditList());
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url =
    "/Supplier/supplierByID?supplierID=" +
    action.id +
    "&languageID=" +
    languageID;
  let response = {};
  const requestDetails = { ...getRequestDetails };
  try {
    response = yield call(axios, url, requestDetails);
    if (response) {
      if (action.flag) {
        yield put(fetchEditList(response.data));
      }
      yield put(fetchingEditListSuccess());
      if (
        action.callback &&
        (response.status === 200 || response.status === 202)
      ) {
        action.callback(response.data);
      }
      if (response.status === 204) {
        const notification = buildNotification({
          message: "msp.noData",
          type: "danger",
        });
        store.addNotification({
          ...notification,
        });
      }
    }
  } catch (error) {
    yield put(fetchingEditListSuccess());
  }
}

function* getEditDataByNameSaga(action) {
  yield put(fetchingEditList());
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url =
    "/Supplier/getCompanyDetailsBySupplierName?supplierName=" +
    action.name +
    "&languageID=" +
    languageID;
  let response = {};
  const requestDetails = { ...getRequestDetails };
  try {
    response = yield call(axios, url, requestDetails);
    if (response) {
      if (
        action.callback &&
        (response.status === 200 || response.status === 202)
      ) {
        action.callback(response.data);
      }
      if (response.status === 204) {
        const notification = buildNotification({
          message: "msp.noData",
          type: "danger",
        });
        store.addNotification({
          ...notification,
        });
      }
    }
    yield put(fetchingEditListSuccess());
  } catch (error) {
    yield put(fetchingEditListSuccess());
  }
}

function* initTakeOwnerShipSaga(action) {
  const url = "/Supplier/updateOwnership?userOnly=true";
  const requestDetails = { ...putRequestDetails };
  yield (requestDetails.data = { listOfIds: action.id });
  try {
    const response = yield call(axios, url, requestDetails);
    let data = [];
    if (response && response.status === 200) {
      yield put(fetchOwnershipStatus());
      const keys = Object.keys(response.data);
      const idArr = action.id.split(",");
      idArr.forEach((arr) => {
        keys.find((item) => {
          return (
            parseInt(item) === parseInt(arr) &&
            data.push({
              id: parseInt(item),
              statusCode: response.data[item].statusCode,
              messageCode:
                response.data[item].value &&
                response.data[item].value.messageCode
                  ? response.data[item].value.messageCode
                  : "",
            })
          );
        });
      });
      if (action.callback) {
        action.callback(data);
      }
      yield put(setOwnershipMessage(data));
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* getAttributesDetailsSaga(action) {
  yield put(fetchingAttributesDetails());
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url = "/Supplier/attributes?languageID=" + languageID;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(fetchAttributesDetails(response.data));
      yield put(fetchingAttributesDetailsSuccess());
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* getContactDetailsSaga(action) {
  yield put(fetchingContactDetails());
  const { supId, id } = action.data;
  const url =
    "/Supplier/supplierContactID?supplierID=" +
    supId +
    "&supplierContactID=" +
    id;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(fetchContactDetails(response.data));
      if (action.callback) {
        action.callback(response.data);
      }
    } else {
      const notification = buildNotification({
        message: "msp.errorMessage",
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* saveContactSaga(action) {
  yield put(saveContactSuccess(false));
  yield put(savingDataShowLoader(true));
  const url = "/Supplier/saveContacts";
  const requestDetails = { ...putRequestDetails };
  yield (requestDetails.data = action.data);
  try {
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200 || response.status === 202) {
      yield put(saveContactSuccess(true));
      const dataList = yield select(selectEditListData());
      const newDataList = { ...dataList };
      newDataList.contacts = [...response.data];
      yield put(fetchEditList(newDataList));
      yield put(savingDataShowLoader(false));
      const notification = buildNotification({
        message: "msp.contactSaveSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    } else {
      yield put(saveContactSuccess(false));
      yield put(savingDataShowLoader(false));
    }
  } catch (error) {
    yield put(saveContactSuccess(false));
    yield put(savingDataShowLoader(false));
  }
}

function* removeContactSaga(action) {
  const url = "/Supplier/deleteContacts";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.data);
  yield put(savingDataShowLoader(true));
  try {
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200 || response.status === 202) {
      const dataList = yield select(selectEditListData());
      const newDataList = { ...dataList };
      newDataList.contacts = [...response.data];
      yield put(fetchEditList(newDataList));
      yield put(savingDataShowLoader(false));
      const notification = buildNotification({
        message: "msp.contactDeleted", //"notification.manageSupplier.contactDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* saveDynamicTabsDataSaga(action) {
  const url = "/Supplier";
  yield put(savingDataShowLoader(true));

  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.data);
  try {
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200 || response.status === 202) {
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.supId,
          () => {
            return;
          },
          true
        )
      );
      yield put(savingDataShowLoader(false));
      const notification = buildNotification({
        message: "msp.detailsSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    } else {
      yield put(savingDataShowLoader(false));
    }
  } catch (error) {
    yield put(savingDataShowLoader(false));
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* getLocationDetailsSaga(action) {
  yield put(fetchingLocationDetails());
  const { supId, id } = action.data;
  const url =
    "/Supplier/supplierLocationID?supplierID=" +
    supId +
    "&supplierLocationID=" +
    id;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(fetchLocationDetails(response.data));
      if (action.callback) {
        action.callback(response.data);
      }
    } else {
      const notification = buildNotification({
        message: "msp.errorMessage",
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* saveLocationSaga(action) {
  yield put(saveLocationSuccess(false));
  yield put(savingDataShowLoader(true));
  const url = "/Supplier/saveLocations";
  const requestDetails = { ...putRequestDetails };
  yield (requestDetails.data = action.data);
  try {
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200 || response.status === 202) {
      yield put(saveLocationSuccess(true));
      const datalist = yield select(selectEditListData());
      const newDatalist = { ...datalist };
      newDatalist.locations = [...response.data];
      yield put(fetchEditList(newDatalist));
      yield put(savingDataShowLoader(false));
      const notification = buildNotification({
        message: "msp.locationSaveSuccess", //"notification.manageSupplier.locationSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    } else {
      yield put(saveLocationSuccess(false));
      yield put(savingDataShowLoader(false));
    }
  } catch (error) {
    yield put(saveLocationSuccess(false));
    yield put(savingDataShowLoader(false));
  }
}

function* saveBulkLocationsSaga(action) {
  const url = "/Supplier/updateLocationStatus";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.data);
  yield put(savingDataShowLoader(true));
  try {
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200 || response.status === 202) {
      const datalist = yield select(selectEditListData());
      const newDatalist = { ...datalist };
      newDatalist.locations = [...response.data];
      yield put(fetchEditList(newDatalist));
      yield put(savingDataShowLoader(false));
      const notification = buildNotification({
        message: action.data.enabled
          ? "msp.locationEnableSuccess"
          : "msp.locationDisableSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* removeAttachmentSaga(action) {
  const url = "/Supplier/deleteAttachments";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.data);
  yield put(savingDataShowLoader(true));
  try {
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200 || response.status === 202) {
      const datalist = yield select(selectEditListData());
      const newDatalist = { ...datalist };
      newDatalist.attachments = [...response.data];
      yield put(fetchEditList(newDatalist));
      yield put(savingDataShowLoader(false));
      const notification = buildNotification({
        message: "msp.attachmentDeleteSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* saveAttachmentSaga(action) {
  yield put(saveAttachmentSuccess(false));
  yield put(savingDataShowLoader(true));
  const url = "/Supplier/saveAttachments";
  const [uploadPromise, chan] = yield createUploader({
    payload: action.data,
    url: url,
    method: "put",
  });
  const progression = {
    sent: 0,
    uploaded: false,
  };
  yield put(uploadingStart(progression));
  yield fork(watchOnProgress, { chan, progression });
  try {
    const response = yield call(() => uploadPromise);
    yield (progression.sent = 100);
    yield (progression.uploaded = true);
    yield put(uploadingFinish(progression));
    yield delay(1300);
    yield put(uploadingReset(progression));

    if (response.status === 200 || response.status === 202) {
      yield put(saveAttachmentSuccess(true));
      const datalist = yield select(selectEditListData());
      const newDatalist = { ...datalist };
      newDatalist.attachments = [...response.data];
      yield put(fetchEditList(newDatalist));
      yield put(savingDataShowLoader(false));
      const notification = buildNotification({
        message: "msp.attachmentAdded",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    } else {
      yield put(saveAttachmentSuccess(false));
      yield put(savingDataShowLoader(false));
    }
  } catch (error) {
    yield put(saveAttachmentSuccess(false));
    yield put(savingDataShowLoader(false));
  }
}

function* getWorkFlowStepDataSaga(action) {
  const url = "/Supplier/workflowStepForCompany?supplierID=" + action.supId;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(setWorkFlowStepData(response.data));
      if (action.callback) {
        action.callback(response.data);
      }
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* saveWorkflowStepDataSaga(action) {
  const url =
    "/Supplier/updateWorkflowState?supplierID=" +
    action.supId +
    "&stepNo=" +
    action.stepNo +
    "&stepinitialState=";
  const requestDetails = { ...putRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      if (action.callback) {
        action.callback();
      }
    }
  } catch (err) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* getDiffSaga(action) {
  yield put(diffDataStatus(false));
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url =
    "/Supplier/companyDetailsToCompare?supplierID=" +
    action.id +
    "&languageID=" +
    languageID;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(setDiffData(response.data));
      yield put(diffDataStatus(true));
    } else yield put(diffDataStatus(false));
  } catch (error) {
    yield put(diffDataStatus(false));
  }
}

function* initNewCompanyCreationSaga(action) {
  yield put(fetchNewCompanyCreation(true));
  const url = "/Supplier/newCompanyRequest";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.data);
  try {
    const response = yield call(axios, url, requestDetails);
    if (response.status === 202) {
      yield put(fetchNewCompanyCreation(false));
      const notification = buildNotification({
        message: "msp.companyRequestCreated", //"notification.manageSupplier.companyCreated",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    } else yield put(fetchNewCompanyCreation(false));
  } catch (err) {
    yield put(fetchNewCompanyCreation(false));
  }
}

function* downloadAttachmentSaga(action) {
  const url =
    "/Supplier/downloadSupplierAttachmentByID?supplierID=" +
    action.supId +
    "&supplierAttachmentID=" +
    action.attachId;
  try {
    const response = yield call(axios, url, { responseType: "blob" });
    if (response) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", action.fileName);
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "msp.attachmentDownloadSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* takeProxySaga(action) {
  const url = "/Supplier/takeProxy?supplierID=" + action.id;
  const requestDetails = { ...putRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(takeProxyStatus(response.data));
      const notification = buildNotification({
        message: "msp.proxyTaken",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(takeProxyComplete(true));
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.id,
          () => {
            return;
          },
          true
        )
      );
      if (action.callback) {
        action.callback(response.data);
      }
    }
  } catch (err) {
    yield put(takeProxyComplete(false));
    // const notification = buildNotification({
    //   message: "msp.errorMessage",
    //   type: "danger",
    // });

    // store.addNotification({
    //   ...notification,
    // });
  }
}

function* rejectCompanySaga(action) {
  let url = "";
  if (action.comment !== "") {
    url =
      "/Supplier/reject?supplierID=" +
      action.id +
      "&rejectionComment=" +
      action.comment;
  } else url = "/Supplier/reject?supplierID=" + action.id;

  const requestDetails = { ...putRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && (response.status === 202 || response.status === 200)) {
      const notification = buildNotification({
        message: "msp.companyRejected",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(rejectCompanySuccess());
    }
  } catch (err) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* rejectRemoveCompanySaga(action) {
  let url = "";
  if (action.comment !== "") {
    url =
      "/Supplier/rejectAndRemove?supplierID=" +
      action.id +
      "&rejectionComment=" +
      action.comment;
  } else url = "/Supplier/rejectAndRemove?supplierID=" + action.id;
  const requestDetails = { ...putRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && (response.status === 202 || response.status === 200)) {
      const notification = buildNotification({
        message: "msp.companyRemoved", //"notification.manageSupplier.companyRemoved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(rejectRemoveCompanySuccess());
    }
  } catch (err) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* getWorkflowStateSaga(action) {
  const url = "/CustomListItem/listkeys?typeName=__workflow_states";
  try {
    const response = yield call(axios, url);
    if (response) {
      yield put(setWorkflowState(response.data));
    }
  } catch (err) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* getContactDiffDataSaga(action) {
  yield put(contactDiffStatus(false));
  const url =
    "/Supplier/supplierContactIDToCompare?supplierID=" +
    action.supId +
    "&supplierContactID=" +
    action.contactId;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(setContactDiffData(response.data));
      yield put(contactDiffStatus(true));
    } else yield put(contactDiffStatus(false));
  } catch (error) {
    yield put(contactDiffStatus(false));
  }
}

function* getLocationDiffDataSaga(action) {
  yield put(locationDiffStatus(false));
  const url =
    "/Supplier/supplierLocationIDToCompare?supplierID=" +
    action.supId +
    "&supplierLocationID=" +
    action.locationId;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(setLocationDiffData(response.data));
      yield put(locationDiffStatus(true));
    } else yield put(locationDiffStatus(false));
  } catch (error) {
    yield put(locationDiffStatus(false));
  }
}

function* initInviteRefreshSaga(action) {
  const url = "/Supplier/inviteRefresh";
  const requestDetails = { ...putRequestDetails };
  yield (requestDetails.data = { ...action.data });
  try {
    const response = yield call(axios, url, requestDetails);
    let data = [];
    if (response && response.status === 200) {
      const keys = Object.keys(response.data);
      const idArr = action.data.listOfIds.split(",");
      idArr.forEach((arr) => {
        keys.find((item) => {
          return (
            parseInt(item) === parseInt(arr) &&
            data.push({
              id: parseInt(item),
              statusCode: response.data[item].statusCode,
              messageCode:
                response.data[item].value &&
                response.data[item].value.messageCode
                  ? response.data[item].value.messageCode
                  : "",
            })
          );
        });
      });
      if (action.callback) {
        action.callback(data);
      }
      yield put(inviteRefreshComplete());
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* watchOnProgress({ chan, progression }) {
  while (true) {
    const data = yield take(chan);
    const { loaded, total } = data;
    const progress = (loaded * 100) / total;
    progression.sent = progress > 90 ? 90 : progress;
    yield put(setUploadingProgress(progression));
  }
}

function* saveLogoSaga(action) {
  yield put(saveLogoComplete(true));
  const url = "/Supplier/saveLogo";
  const [uploadPromise, chan] = yield createUploader({
    payload: action.data,
    url: url,
    method: "put",
  });
  const progression = {
    sent: 0,
    uploaded: false,
  };
  yield put(uploadingStart(progression));
  yield fork(watchOnProgress, { chan, progression });
  try {
    const response = yield call(() => uploadPromise);
    yield (progression.sent = 100);
    yield (progression.uploaded = true);
    yield put(uploadingFinish(progression));
    yield delay(1300);
    yield put(uploadingReset(progression));

    if (response && response.status === 202) {
      yield put(initAttributesDetails());
      yield put(
        initEditList(
          action.data.supplierID,
          () => {
            return;
          },
          true
        )
      );
      yield put(initGetLogo(action.data.supplierID));
      yield put(saveLogoComplete(false));
      const notification = buildNotification({
        message: "msp.logoSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    yield put(saveLogoComplete(true));
  }
}

function* getAuditTrailSaga(action) {
  yield put(auditTrailListFetchStatus(true));
  let url = "/Supplier/AuditTrailList?";
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + data + "=" + action.data[data] + "&";
      }
    });
  let updatedData = [];
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      updatedData = response.data || [];
      // yield response.data.auditTrails &&
      //   response.data.auditTrails.map((item) => {
      //     return {
      //       ...item,
      //       createdDate: moment(item.createdDate).format("MM/DD/YYYY"),
      //     };
      //   });
      yield put(auditTrailListFetchStatus(false));
      yield put(setAuditTrailList(updatedData));
    }
  } catch (error) {
    yield put(auditTrailListFetchStatus(false));
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* getAuditTrailDetailsSaga(action) {
  let url = "/Supplier/SupplierAuditTrailByID?auditTrailID=" + action.id;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(setAuditDataById(response.data));
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* clearAuditTrailSaga() {
  const url = "/Supplier/ClearAuditTrail";
  const requestDetails = { ...postRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(clearAuditTrailSuccess());
      yield put(initAuditTrailList());
      const notification = buildNotification({
        message: "msp.auditTrailCleared",
        type: "success",
      });
      yield store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* getLogoSaga(action) {
  yield put(getLogoStatus(false));
  let url = "/Supplier/getLogo?supplierID=" + action.id;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(setLogo(response.data));
      yield put(getLogoStatus(true));
    }
  } catch (err) {
    yield put(getLogoStatus(false));
  }
}

function* getFlexFieldsSaga() {
  yield put(fetchFlexFieldsStatus(true));
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let url = "/Login/flexFields?languageID=" + languageID;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      const sortedRes = response.data.sort((a, b) =>
        a.sortOrder > b.sortOrder ? 1 : -1
      );
      yield put(setFlexFields(sortedRes));
      yield put(fetchFlexFieldsStatus(false));
    }
  } catch (err) {
    yield put(fetchFlexFieldsStatus(false));
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* combinedSaga(action) {
  yield* getAttributesDetailsSaga();
  yield* getEditListDataSaga({
    id: action.id,
    fetchSuccess: () => {
      return;
    },
    flag: true,
  });
}

function* getVmdRequestDataForHelpdesk(action) {
  let url = "/Supplier/openProcesses";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      yield put(setVmdRequestDataForHelpdesk(response.data));
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorMessage",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}
