import { put, takeEvery, call, all } from "redux-saga/effects";

import axios from "../../../../../config/axiosCall";

import { buildNotification } from "../../../../../config/notification";
import { store } from "react-notifications-component";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../../../config/requestHeaders";

import {
  INIT_GET_EMAIL_TEMPLATES_LIST,
  INIT_GET_HELPDESK_QUERY_ATTRIBUTE_LIST,
  INIT_GET_HELPDESK_QUERY_BY_ID,
  INIT_GET_HELPDESK_QUERY_LIST,
  INIT_HELPDESK_CONFIG_SAVE,
} from "./actionTypes";

import {
  fetchingHelpdeskQueryList,
  fetchHelpdeskQueryList,
  helpdeskQueryListFetchingComplete,
  fetchingEmailTemplatesList,
  fetchEmailTemplatesList,
  emailTemplatesListFetchingComplete,
  fetchingHelpdeskAttributeList,
  fetchHelpdeskAttributeList,
  HelpdeskAttributeListFetchingComplete,
  fetchingHelpdeskQueryByID,
  fetchHelpdeskQueryByID,
  HelpdeskQueryByIDFetchingComplete,
  HelpdeskConfigSavingFailed,
  HelpdeskConfigSaved,
  HelpdeskConfigSaving,
} from "../store/actions";

let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));

export default function* watchCustomListFetch() {
  yield all([
    takeEvery(INIT_GET_HELPDESK_QUERY_LIST, getHelpdeskQueryListSaga),
    takeEvery(INIT_GET_EMAIL_TEMPLATES_LIST, initGetEmailTemplatesListSaga),
    takeEvery(
      INIT_GET_HELPDESK_QUERY_ATTRIBUTE_LIST,
      getHelpdeskAttributesListSaga
    ),
    takeEvery(INIT_GET_HELPDESK_QUERY_BY_ID, getHelpdeskQueryByIDSaga),
    takeEvery(INIT_HELPDESK_CONFIG_SAVE, initHelpdeskConfigarationSaveSaga),

  ]);
}

function* getHelpdeskQueryListSaga() {
  yield put(fetchingHelpdeskQueryList());
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url = `/Common/listHelpdeskQuery?languageID=${languageID}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(fetchHelpdeskQueryList(response.data));
    yield put(helpdeskQueryListFetchingComplete());
  } catch (error) {
    yield put(helpdeskQueryListFetchingComplete());
  }
}

function* initGetEmailTemplatesListSaga() {
    yield put(fetchingEmailTemplatesList());
    const url = `/EmailTemplate/ListEmailTemplate`;
    const requestDetails = { ...getRequestDetails };
    try {
      const response = yield call(axios, url, requestDetails);
      let getUpdatedList;
      if (response.data) {
        getUpdatedList = response.data.filter(
          (item) => item.categoryLabel === "Supplier Queries"
        );
      }
      yield put(fetchEmailTemplatesList(getUpdatedList));
      yield put(emailTemplatesListFetchingComplete());
    } catch (error) {
      yield put(emailTemplatesListFetchingComplete());
    }
  }

  function* getHelpdeskAttributesListSaga() {
    yield put(fetchingHelpdeskAttributeList());
    const url = `/Module/ListHelpdeskQueryAttributes?languageID=enGb`;
    const requestDetails = { ...getRequestDetails };
    try {
      const response = yield call(axios, url, requestDetails);
      yield put(fetchHelpdeskAttributeList(response.data));
      yield put(HelpdeskAttributeListFetchingComplete());
    } catch (error) {
      yield put(HelpdeskAttributeListFetchingComplete());
    }
  }
  function* getHelpdeskQueryByIDSaga(action) {
    yield put(fetchingHelpdeskQueryByID());
    const url = `/Module/QueryDetailsById?Id=${action.queryID}&languageID=enGb`;
    const requestDetails = { ...getRequestDetails };
    try {
      const response = yield call(axios, url, requestDetails);
      if(response && response.data){
        yield put(fetchHelpdeskQueryByID(response.data));
        yield put(HelpdeskQueryByIDFetchingComplete());
        if (action.fetchSuccessHelpdeskQueryByID) {
          action.fetchSuccessHelpdeskQueryByID(response.status);
        }
     }

    } catch (error) {
      yield put(HelpdeskQueryByIDFetchingComplete());
    }
  }

  function* initHelpdeskConfigarationSaveSaga(action) {
    yield put(HelpdeskConfigSaving());
    const url = `/Module/HelpdeskSaveConfiguration`;
    const requestDetails = { ...postRequestDetails };
    yield (requestDetails.data = action.formdata);
    try {
      const response = yield call(axios, url, requestDetails);
      const notification = buildNotification({
        message: "msp.helpdeskConfigSaved",
        type: "success",
      });
      if (response && response.status) {
        yield put(HelpdeskConfigSaved());
        if (action.helpdeskConfigSaveSuccess) {
          action.helpdeskConfigSaveSuccess(response.status);
        }
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 1000,
          },
        });
      }
    } catch (error) {
      yield put(HelpdeskConfigSavingFailed());
    }
  }





