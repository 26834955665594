import { fromJS } from "immutable";

import {
  FETCH_HELPDESK_QUERY_LIST,
  HELPDESK_QUERY_LIST_FETCH_COMPLETE,
  FETCHING_HELPDESK_QUERY_LIST,
  FETCH_GET_EMAIL_TEMPLATES_LIST,
  FETCHING_GET_EMAIL_TEMPLATES_LIST,
  GET_EMAIL_TEMPLATES_LIST_FETCH_COMPLETE,
  FETCH_HELPDESK_QUERY_ATTRIBUTE_LIST,
  FETCHING_HELPDESK_QUERY_ATTRIBUTE_LIST,
  HELPDESK_QUERY_ATTRIBUTE_LIST_FETCH_COMPLETE,
  FETCHING_HELPDESK_QUERY_BY_ID,FETCH_HELPDESK_QUERY_BY_ID,HELPDESK_QUERY_BY_ID_FETCH_COMPLETE, HELPDESK_CONFIG_SAVING, HELPDESK_CONFIG_SAVED, HELPDESK_CONFIG_SAVING_FAILED
} from "./actionTypes";

export const initialState = fromJS({
  fetchingHelpdeskQueryTypeList: false,
  helpdeskQueryTypeList: [],
  fetchingEmailTemplatesList: false,
  emailTemplatesList: [],
  fetchingHelpdeskAttributeList: false,
  helpdeskAttributeList: [],
  fetchingHelpdeskQueryByID: false,
  helpdeskQueryByID: {},
  isHelpdeskConfigSaving : false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_HELPDESK_QUERY_LIST: {
      return state.set("fetchingHelpdeskQueryTypeList", true);
    }
    case FETCH_HELPDESK_QUERY_LIST: {
      return state.set("helpdeskQueryTypeList", action.data);
    }
    case HELPDESK_QUERY_LIST_FETCH_COMPLETE: {
      return state.set("fetchingHelpdeskQueryTypeList", false);
    }
    case FETCHING_GET_EMAIL_TEMPLATES_LIST: {
      return state.set("fetchingEmailTemplatesList", true);
    }
    case FETCH_GET_EMAIL_TEMPLATES_LIST: {
      return state.set("emailTemplatesList", action.data);
    }
    case GET_EMAIL_TEMPLATES_LIST_FETCH_COMPLETE: {
      return state.set("fetchingEmailTemplatesList", false);
    }
    case FETCHING_HELPDESK_QUERY_ATTRIBUTE_LIST: {
      return state.set("fetchingHelpdeskAttributeList", true);
    }
    case FETCH_HELPDESK_QUERY_ATTRIBUTE_LIST: {
      return state.set("helpdeskAttributeList", action.data);
    }
    case HELPDESK_QUERY_ATTRIBUTE_LIST_FETCH_COMPLETE: {
      return state.set("fetchingHelpdeskAttributeList", false);
    }
    case FETCHING_HELPDESK_QUERY_BY_ID: {
      return state.set("fetchingHelpdeskQueryByID", true);
    }
    case FETCH_HELPDESK_QUERY_BY_ID: {
      return state.set("helpdeskQueryByID", action.data);
    }
    case HELPDESK_QUERY_BY_ID_FETCH_COMPLETE: {
      return state.set("fetchingHelpdeskQueryByID", false);
    }
    case HELPDESK_CONFIG_SAVING: {
      return state.set("isHelpdeskConfigSaving", true);
    }
    case HELPDESK_CONFIG_SAVED: {
      return state.set("isHelpdeskConfigSaving", false); 
    }
    case HELPDESK_CONFIG_SAVING_FAILED: {
      return state.set("isHelpdeskConfigSaving", false);
    }
    default:
      return state;
  }
};

export default reducer;
