export const privacyStatementContent = {
  body: "msp.privacyMessageFour",
};
export const privacyAdditionalContent = [
  {
    header: "msp.privacySubtitle",
    content: {
      body: "msp.privacySubtitleMessage",
    },
  },
];
