import { fromJS } from "immutable";

import {
  FETCHING_LIST_HEADER_ATTRS,
  FETCH_LIST_HEADER_ATTRS,
  LIST_HEADER_ATTRS_FETCH_COMPLETE,
  FETCHING_LIST_LINE_ATTRS,
  FETCH_LIST_LINE_ATTRS,
  LIST_LINE_ATTRS_FETCH_COMPLETE,
  FETCHING_SUBMISSION_PROFILES,
  FETCH_SUBMISSION_PROFILE,
  SUBMISSION_PROFILE_FETCH_COMPLETE,
  INIT_SUBMISSION_PROFILE_SAVE,
  SUBMISSION_PROFILE_SAVED,
  SUBMISSION_PROFILE_SAVE_FAILED,
  DELETE_SUCCESS,
  DELETE_FAILED,
  SUBMISSION_PROFILE_DELETING,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingSubmissionProfiles: true,
  submissionProfiles: [],
  listHeaderAttrsFetching: false,
  listHeaderAttrs: [],
  listLineAttrsFetching: false,
  listLineAttrs: [],
  submisisonProfileSaving: false,
  submisisonProfileSaveFail: false,
  deleting: false,
  deleted: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_SUBMISSION_PROFILES: {
      return state.set("fetchingSubmissionProfiles", true);
    }
    case FETCH_SUBMISSION_PROFILE: {
      return state.set("submissionProfiles", action.data);
    }
    case SUBMISSION_PROFILE_FETCH_COMPLETE: {
      return state.set("fetchingSubmissionProfiles", false);
    }

    case FETCHING_LIST_HEADER_ATTRS: {
      return state.set("listHeaderAttrsFetching", true);
    }
    case FETCH_LIST_HEADER_ATTRS: {
      return state.set("listHeaderAttrs", action.data);
    }
    case LIST_HEADER_ATTRS_FETCH_COMPLETE: {
      return state.set("listHeaderAttrsFetching", false);
    }

    case FETCHING_LIST_LINE_ATTRS: {
      return state.set("listLineAttrsFetching", true);
    }
    case FETCH_LIST_LINE_ATTRS: {
      return state.set("listLineAttrs", action.data);
    }
    case LIST_LINE_ATTRS_FETCH_COMPLETE: {
      return state.set("listLineAttrsFetching", false);
    }

    case INIT_SUBMISSION_PROFILE_SAVE: {
      return state.set("submisisonProfileSaving", true);
    }
    case SUBMISSION_PROFILE_SAVED: {
      return state.set("submisisonProfileSaving", false);
    }
    case SUBMISSION_PROFILE_SAVE_FAILED: {
      return state
        .set("submisisonProfileSaving", false)
        .set("submisisonProfileSaveFail", true);
    }

    case SUBMISSION_PROFILE_DELETING: {
      return state.set("deleting", true);
    }
    case DELETE_SUCCESS: {
      return state
        .set("deleting", false)
        .set("deleted", [...state.get("deleted"), ...action.id]);
    }
    case DELETE_FAILED: {
      return state.set("deleting", false);
    }

    default:
      return state;
  }
};
export default reducer;
