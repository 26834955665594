import {
  CLEAN_REVIEW_INVOICE_DETAILS,
  FETCHING_OCR_DETAILS,
  FETCHING_PO_DETAILS,
  INIT_FETCH_OCR_DETAILS,
  INIT_FETCH_PO_DETAILS,
  OCR_DETAILS_FAILED,
  OCR_DETAILS_FETCHED,
  PO_DETAILS_FAILED,
  PO_DETAILS_FETCHED,
} from "./actionTypes";

export const initOCRDetailsFecth = (payload) => ({
  type: INIT_FETCH_OCR_DETAILS,
  payload,
});

export const ocrDetailsFetching = () => ({
  type: FETCHING_OCR_DETAILS,
});
export const ocrDetailsFetchComplete = (data) => ({
  type: OCR_DETAILS_FETCHED,
  data,
});

export const ocrDetailsFetchFailed = () => ({
  type: OCR_DETAILS_FAILED,
});

export const initPODetailsFetch = (payload) => ({
  type: INIT_FETCH_PO_DETAILS,
  payload,
});

export const PODetailsFetching = () => ({
  type: FETCHING_PO_DETAILS,
});
export const PODetailsFetchComplete = (data) => ({
  type: PO_DETAILS_FETCHED,
  data,
});

export const PODetailsFetchFailed = () => ({
  type: PO_DETAILS_FAILED,
});

export const cleanReviewInoiceDetails = () => ({
  type: CLEAN_REVIEW_INVOICE_DETAILS,
});
