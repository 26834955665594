import { fromJS } from "immutable";
import {
  FETCHING_SIGNUP_FLEX_FIELDS,
  FETCH_SIGNUP_FLEX_FIELDS,
  INIT_SAVE_SIGNUP_FORM,
  SIGNUP_FORM_SAVED,
  SIGNUP_FORM_SAVE_FAILED,
  RESET_SAVE_STATUS,
  USER_EXIST,
  FETCH_SIGNUP_FLEX_FIELDS_COMPLETE,
  CHECK_USER_EXISTS_STATUS,
} from "./actionTypes";

export const initialState = fromJS({
  signUpFlexFields: "",
  fetchSignUpFlexFields: false,
  signUpFormSaving: false,
  formSaveStatus: false,
  isExistingUser: false,
  checkingIfUserExists: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_SIGNUP_FLEX_FIELDS: {
      return state.set("fetchSignUpFlexFields", true);
    }
    case FETCH_SIGNUP_FLEX_FIELDS: {
      return state.set("signUpFlexFields", action.data);
    }
    case FETCH_SIGNUP_FLEX_FIELDS_COMPLETE: {
      return state.set("fetchSignUpFlexFields", false);
    }
    case INIT_SAVE_SIGNUP_FORM: {
      return state.set("signUpFormSaving", true);
    }
    case SIGNUP_FORM_SAVED: {
      return state.set("signUpFormSaving", false).set("formSaveStatus", true);
    }
    case SIGNUP_FORM_SAVE_FAILED: {
      return state.set("signUpFormSaving", false).set("formSaveStatus", false);
    }
    case RESET_SAVE_STATUS: {
      return state.set("formSaveStatus", false);
    }
    case USER_EXIST: {
      return state.set("isExistingUser", action.data); //data that has to be set from action <---> saga to selector
    }
    case CHECK_USER_EXISTS_STATUS: {
      return state.set("checkingIfUserExists", action.status);
    }

    default:
      return state;
  }
};
export default reducer;
