import { fromJS } from "immutable";
import {
  FETCHING_EMAIL_TEMPLATE_LIST,
  FETCH_EMAIL_TEMPLATE_LIST,
  EMAIL_TEMPLATE_LIST_FETCH_COMPLETE,
  FETCHING_CATEGORY_LIST,
  FETCH_CATEGORY_LIST,
  CATEGORY_LIST_FETCH_COMPLETE,
  INIT_EMAIL_TEMPLATE_SAVE,
  EMAIL_TEMPLATE_SAVED,
  EMAIL_TEMPLATE_SAVE_FAILED,
  INIT_EMAIL_TEMPLATE_DELETE,
  DELETE_SUCCESS,
  DELETE_FAILED,
  RESET_SAVE_STATUS,
  INIT_EMAIL_TEMPLATE_EDIT,
  SET_EMAIL_TEMPLATE_EDIT_DATA,
  DOWNLOADED_ATTACHMENT,
  EDIT_ATTACHMENT_FILE_COMPLETE,
  EDIT_TEMPLATE_FILE_COMPLETE,
  RESET_EDIT_STATUS,
  UPDATE_MUTATION,
} from "./actionTypes";

export const initialState = fromJS({
  emailTemplateListFetchStatus: false,
  categoryListStatus: false,
  emailTemplateList: [],
  categoryList: [],
  deleting: false,
  deleted: [],
  editing: false,
  emailTemplateSaving: false,
  emailTemplateSaved: false,
  emailTemplateSaveFail: false,
  emailTemplate: null,
  fetchForEditComplete: false,
  templateFile: "",
  attachmentFile: [],
  attachment: "",
  mutationUpdate: [],
});

const emailTemplatesreducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_EMAIL_TEMPLATE_LIST: {
      return state.set("emailTemplateListFetchStatus", true);
    }
    case FETCH_EMAIL_TEMPLATE_LIST: {
      return state.set("emailTemplateList", action.data);
    }
    case EMAIL_TEMPLATE_LIST_FETCH_COMPLETE: {
      return state.set("emailTemplateListFetchStatus", false);
    }

    case FETCHING_CATEGORY_LIST: {
      return state.set("categoryListStatus", true);
    }
    case FETCH_CATEGORY_LIST: {
      return state.set("categoryList", action.data);
    }
    case CATEGORY_LIST_FETCH_COMPLETE: {
      return state.set("categoryListStatus", false);
    }

    case INIT_EMAIL_TEMPLATE_SAVE: {
      return state.set("emailTemplateSaving", true);
    }
    case EMAIL_TEMPLATE_SAVED: {
      return state
        .set("emailTemplateSaved", true)
        .set("emailTemplateSaving", false)
        .set("editing", false);
    }
    case EMAIL_TEMPLATE_SAVE_FAILED: {
      return state
        .set("emailTemplateSaving", false)
        .set("emailTemplateSaveFail", true);
    }

    case INIT_EMAIL_TEMPLATE_DELETE: {
      return state.set("deleting", true);
    }
    case DELETE_SUCCESS: {
      return state
        .set("deleting", false)
        .set("deleted", [...state.get("deleted"), ...action.id]);
    }
    case DELETE_FAILED: {
      return state.set("deleting", false);
    }

    case RESET_SAVE_STATUS: {
      return state.set("emailTemplateSaved", false);
    }

    case RESET_EDIT_STATUS: {
      return state.set("editing", false).set("fetchForEditComplete", false);
    }

    case DOWNLOADED_ATTACHMENT:
      return state.set("attachment", action.data);

    case INIT_EMAIL_TEMPLATE_EDIT: {
      return state.set("editing", true);
    }

    case SET_EMAIL_TEMPLATE_EDIT_DATA: {
      return state
        .set("editing", false)
        .set("emailTemplate", action.data)
        .set("fetchForEditComplete", true);
    }

    case EDIT_TEMPLATE_FILE_COMPLETE: {
      return state.set("templateFile", action.data);
    }

    case EDIT_ATTACHMENT_FILE_COMPLETE: {
      return state.set("attachmentFile", action.data);
    }
    case UPDATE_MUTATION: {
      return state.set("mutationUpdate", action.data);
    }

    default:
      return state;
  }
};
export default emailTemplatesreducer;
