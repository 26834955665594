import React from "react";
import FloatingDateInput from "./FloatingDatepicker";
import LabeledDateInput from "./LabeledDatepicker";

const DateInput = React.forwardRef((props, _ref) => {
  return props.noFloating ? (
    <LabeledDateInput {...props} />
  ) : (
    <FloatingDateInput {...props} />
  );
});

export default DateInput;
