import styled from "styled-components";
import MainContentWrapper from "components/MainContentWrapper/index";
import { IconWrapper } from "components/index";

export const InvoiceStatusContent = styled(MainContentWrapper)`
  gap: 1em;
`;

export const MainSection = styled.div``;

export const GridActions = styled.div`
  display: flex;
  gap: 0.5em;
  flex-direction: column;

  @media (min-width: 36em) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin: 1em 0;
  }
`;

export const GridActionBtns = styled(GridActions)`
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  @media (min-width: 30em) {
    flex-direction: row;
    justify-content: none;
  }
`;

export const TD = styled.td``;

export const StatusIconWrapper = styled(IconWrapper)`
  display: flex;
  align-self: center;
  svg {
    height: 1em;
  }
`;

export const PaymentStatusWrapper = styled.div`
  background-color: rgb(255, 245, 247);
  height: 2em;
  border-radius: 1em;
  padding: 0.5em;
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  cursor: default;
  align-items: center;
`;

export const StatusText = styled.label`
  color: rgb(213, 0, 47);
  font-size: var(--fs-14);
  font-family: var(--ff-main);
  font-weight: var(--fw-normal);
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${PaymentStatusWrapper} {
    background-color: rgb(244, 251, 239);

    ${StatusText} {
      color: rgb(126, 195, 68);
    }
  }

  ${StatusIconWrapper} {
    svg {
      height: 1em;
      color: #649c36;
    }
  }
`;

export const BoldText = styled.label`
  color: var(--dark-clr);
  font-size: var(--fs-14);
  font-family: var(--ff-medium);
  font-weight: var(--fw-normal);
  text-align: right;
`;

export const ActionText = styled(BoldText)``;

export const ActionContainer = styled.div`
  background-color: ${(props) =>
    props.action ? "rgba(213, 0, 47, 0.06)" : "rgba(255, 233, 171, 0.39)"};
  height: 2em;
  border-radius: 0.375em;
  padding: 0.625em 0.75rem;
  display: flex;
  justify-content: center;
  margin: auto;
  cursor: default;
  gap: 0.5em;
  align-items: center;

  ${ActionText} {
    font-family: var(--ff-main);
    cursor: pointer;
    text-transform: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
