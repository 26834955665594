import React, { useEffect, useState } from "react";
import InputControl from "./style";

const Input = (props) => {
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    props.resetVisited && setVisited(false);
  }, [props.resetVisited]);

  const onInputChange = (e) => {
    let { value, maxLength, type } = e.target;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    const strOfVal = strippedHtml.toString();

    if (strOfVal.length > maxLength) {
      const validatedLength = strOfVal.substring(0, maxLength);
      e.target.value =
        type === "number" ? Number(validatedLength) : validatedLength;
    }

    props.onChangeHandler && props.onChangeHandler(e);
  };
  const handleOnBlur = (e) => {
    setVisited(true);
    let value = e.target.value;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;

    props.onBlurHandler && props.onBlurHandler(e);
  };
  const passedProps = { ...props };
  delete passedProps.onBlurHandler;
  delete passedProps.onChangeHandler;
  delete passedProps.showError;
  return (
    <>
      <InputControl
        {...passedProps}
        autoComplete="new-password"
        visited={visited || props.value}
        aria-label={props.label}
        onBlur={handleOnBlur}
        onChange={onInputChange}
        onKeyUp={props.onKeyUp}
      />
    </>
  );
};

export default Input;
