import { selectAdditionalFields } from "container/DS-common/store/selectors";
import { useMemo } from "react";
import { useSelector } from "react-redux";
export const linePONumber = "LinePONumber";

export default function useHasLinePONumber() {
  const { attributeDetails = [] } = useSelector(
    selectAdditionalFields()
  ).toJS();
  return useMemo(() => {
    return (
      attributeDetails.findIndex(
        (field) => field.attributeName === linePONumber
      ) !== -1
    );
  }, [attributeDetails]);
}
