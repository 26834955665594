import styled from "styled-components";
import { inputRules } from "config/formInputRules";
import InputAddon from "components/InputAddon/index";

const getBorderifAddon = (props) => {
  if (props.icon) {
    return `
        border-right: none;
        border-top-right-radius:0;
        border-bottom-right-radius:0;
    `;
  }
};
const InputControl = styled.input.attrs((props) => ({
  maxLength: props.maxLength ? props.maxLength : inputRules.maxLength,
  autoComplete: "off",
}))`
  width: 100%;
  height: 2.875em;
  padding-left: 0.75rem;
  outline: 0;
  border: var(--oneDotFivePx) solid var(--input-border-clr);
  border-color: ${(props) => (props.error ? "var(--error-clr)" : "")};
  border-radius: 0.5em;
  ${(props) => getBorderifAddon(props)};

  background: var(--light-clr);
  font-size: var(--fs-16);
  font-family: var(--ff-main);
  cursor: text;
  flex-shrink: 0;
  ~ ${InputAddon} {
    border-color: ${(props) => (props.error ? "var(--error-clr)" : "")};
  }
  :disabled,
  :read-only {
    background-color: var(--disabled-clr);
    cursor: not-allowed;
  }
  :focus,
  :hover {
    box-shadow: rgb(102 187 255 / 50%) 0 0 0.8rem;
    border-color: var(--primary-clr);
    outline: none;
    ${(props) => (props.icon ? `clip-path: inset(-5px 0px -5px -5px)` : "")};
  }
  &:not(:focus):invalid {
    color: ${(props) => (props.visited ? "var(--error-clr)" : "")};
    border-color: ${(props) => (props.visited ? "var(--error-clr)" : "")};
    ~ ${InputAddon} {
      border-color: ${(props) =>
        props.error || props.visited ? "var(--error-clr)" : ""};
    }
    ::placeholder {
      color: ${(props) => (props.visited ? "var(--error-clr)" : "")};
    }
  }
`;

export default InputControl;
