import React from "react";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";

import BannerStyled, { Greetings } from "./style";

import PaymentQuickUpdate from "components/PaymentQuickUpdate/index";

const Banner = (props) => {
  const { t } = useTranslation();
  const sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  const { baseRoleId } = sessionDetails || { baseRoleId: null };
  const isHome = props.history.location.pathname === "/";

  const firstName = sessionDetails ? sessionDetails.firstName : t("msp.user");

  return (
    <BannerStyled as="section">
      <Greetings>
        <strong>
          {isHome
            ? `${t("msp.welcome")} ${firstName},`
            : `${t("msp.hi")} ${firstName},`}
        </strong>
        &nbsp;
        {isHome ? t("msp.goodDay") : t("msp.helpMessage")}
      </Greetings>
      {baseRoleId === 2 || (baseRoleId === 3 && isHome) ? (
        <PaymentQuickUpdate />
      ) : null}
    </BannerStyled>
  );
};

export default withRouter(Banner);
