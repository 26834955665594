import { fromJS } from "immutable";

import {
  USER_LIST_FETCH_STATUS,
  USER_LIST,
  FETCH_USER_ROLE_LIST_STATUS,
  FETCH_USER_ROLE_LIST,
  USER_BY_ID_DATA_FETCH_STATUS,
  USER_BY_ID_DATA,
  INIT_DELETE_USER_STATUS,
  INIT_REGIONAL_SETTINGS,
  FETCH_REGIONAL_SETTINGS,
  USER_EXIST,
  INIT_USER_BY_ID_DATA,
} from "./actionTypes";

export const initialState = fromJS({
  userList: [],
  userRoleList: [],
  showFilter: false,
  isUserLoading: false,
  editData: null,
  fetchForEditComplete: false,
  fetchingUserRoleList: true,
  regionalSettings: [],
  fetchRegionalSettings: true,
  supplierList: [],
  fetchSupplierList: true,
  isExistingUser: false,
  deleteUserStatus: false,
});

function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LIST_FETCH_STATUS:
      return state.set("isUserLoading", action.boolean);
    case USER_LIST: {
      return state.set("userList", action.userList);
    }
    case INIT_DELETE_USER_STATUS:
      return state.set("deleteUserStatus", action.boolean);
    case FETCH_USER_ROLE_LIST_STATUS: {
      return state.set("fetchingUserRoleList", action.boolean);
    }
    case FETCH_USER_ROLE_LIST: {
      return state.set("userRoleList", action.userRoleList);
    }
    case INIT_USER_BY_ID_DATA: {
      return state.set("editData", null);
    }
    case USER_BY_ID_DATA_FETCH_STATUS:
      return state.set("fetchForEditComplete", action.boolean);
    case USER_BY_ID_DATA: {
      return state.set("editData", action.data);
    }
    case INIT_REGIONAL_SETTINGS: {
      return state.set("fetchingRegionalSettings", true);
    }
    case FETCH_REGIONAL_SETTINGS: {
      return state
        .set("regionalSettings", action.data)
        .set("fetchingRegionalSettings", false);
    }
    case USER_EXIST: {
      return state.set("isExistingUser", action.data);
    }
    default:
      return state;
  }
}

export default userManagementReducer;
