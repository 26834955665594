import React from "react";
import SvgIcon from "./style";

const Upload = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.16226835"
      >
        <g
          transform="translate(-845.000000, -296.000000)"
          fill="#919EAB"
          fillRule="nonzero"
        >
          <g transform="translate(701.000000, 279.000000)">
            <g transform="translate(144.000000, 14.000000)">
              <g transform="translate(-0.000000, 3.000000)">
                <g transform="translate(-0.000000, 0.000000)">
                  <path d="M34.3124904,25.0000051 L24.749996,25.0000051 L24.749996,25.5500093 C24.749996,27.6763016 22.9871183,29.4000021 20.8124983,29.4000021 L15.1875017,29.4000021 C13.0128817,29.4000021 11.250004,27.6763016 11.250004,25.5500093 L11.250004,25.0000051 L1.68750957,25.0000051 C1.23961339,24.9989113 0.809738064,25.1723969 0.493026639,25.4820699 C0.176315215,25.7917429 -0.0011133953,26.2120649 5.25770641e-06,26.6500072 L5.25770641e-06,34.3499928 C-0.0011133953,34.7879351 0.176315215,35.2082571 0.493026639,35.5179301 C0.809738064,35.8276031 1.23961339,36.0010887 1.68750957,35.9999949 L34.3124904,35.9999949 C34.7603866,36.0010887 35.1902619,35.8276031 35.5069734,35.5179301 C35.8236848,35.2082571 36.0011134,34.7879351 35.9999947,34.3499928 L35.9999947,26.6500072 C36.0011134,26.2120649 35.8236848,25.7917429 35.5069734,25.4820699 C35.1902619,25.1723969 34.7603866,24.9989113 34.3124904,25.0000051 Z M25.8749954,34.0749933 C25.0983454,34.0749933 24.4687462,33.459386 24.4687462,32.6999959 C24.4687462,31.9406058 25.0983454,31.3249985 25.8749954,31.3249985 C26.6516454,31.3249985 27.2812446,31.9406058 27.2812446,32.6999959 C27.2789279,33.4584457 26.6506837,34.0727281 25.8749954,34.0749933 L25.8749954,34.0749933 Z M30.3749927,34.0749933 C29.5983428,34.0749933 28.9687436,33.459386 28.9687436,32.6999959 C28.9687436,31.9406058 29.5983428,31.3249985 30.3749927,31.3249985 C31.1516427,31.3249985 31.7812419,31.9406058 31.7812419,32.6999959 C31.7789253,33.4584457 31.1506811,34.0727281 30.3749927,34.0749933 L30.3749927,34.0749933 Z"></path>
                  <path d="M14.1595947,25.3122174 L14.1595947,13.4977766 L8.12623852,13.4977766 C6.90168048,13.4977766 6.28940146,11.9858094 7.15622345,11.0990231 L17.6200031,0.395702312 C18.13966,-0.131900771 18.9784605,-0.131900771 19.4981174,0.395702312 L29.9687766,11.0990231 C30.8355985,11.9858094 30.2233195,13.4977766 28.9987615,13.4977766 L22.9654053,13.4977766 L22.9654053,25.3122174 C22.966505,25.7601875 22.7929046,26.1901337 22.4830266,26.5068973 C22.1731485,26.823661 21.7525482,27.0011188 21.3143158,27 L15.8106842,27 C15.3724518,27.0011188 14.9518515,26.823661 14.6419734,26.5068973 C14.3320954,26.1901337 14.158495,25.7601875 14.1595947,25.3122174 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Upload;
