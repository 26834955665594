import React from "react";
import {
  Accordion as AccordianComponent,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Panel } from "./style";

const Accordian = (props) => {
  return (
    <div>
      <AccordianComponent
        allowMultipleExpanded={props.multiExpand}
        allowZeroExpanded={props.zeroExpand}
        preExpanded={props.expandedUUID ? props.expandedUUID : []}
        onChange={props.onAccordionChange}
      >
        {props.placeholders &&
          props.placeholders.map((placeholder) => (
            <AccordionItem
              key={placeholder && placeholder.heading}
              uuid={placeholder && placeholder.uuid}
            >
              <AccordionItemHeading>
                <AccordionItemButton>
                  {placeholder && placeholder.heading}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Panel>
                  {props.children
                    ? props.children
                    : placeholder && placeholder.accBody}
                </Panel>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
      </AccordianComponent>
    </div>
  );
};

export default Accordian;
