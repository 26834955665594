import React from "react";
import { SvgLargeIcon } from "./style";

const MoneyBag = (props) => {
  return (
    <SvgLargeIcon
      focusable="false"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96 96"
      style={{ enableBackground: "new 0 0 96 96" }}
      xmlSpace="preserve"
    >
      <g>
        <g transform="translate(-832.000000, -366.000000)">
          <path
            fill="#ffffff"
            d="M894.8,388.8l8.2-13.5c2.7-4-0.2-9.3-5-9.3h-36c-4.8,0-7.7,5.3-5,9.3l8.2,13.5
			c-34.9,22.3-33.2,50.8-33.2,54.4c0,10.4,9.2,18.8,20.6,18.8h54.8c11.4,0,20.6-8.4,20.6-18.8C928,439.6,929.5,411,894.8,388.8z
			 M862,372h36l-9.2,15h-17.6L862,372z M922,443.3c0,7-6.5,12.8-14.6,12.8h-54.9c-8,0-14.6-5.7-14.6-12.8v-0.6
			c-0.2-11.2,3.8-32.4,31.7-49.6h20.6c27.9,17.1,31.8,38.4,31.7,49.7V443.3z M885.6,424.3l-9.2-2.7c-1.1-0.3-1.8-1.4-1.8-2.6
			c0-1.5,1.1-2.7,2.4-2.7h5.7c0.9,0,1.8,0.3,2.6,0.8c0.6,0.4,1.5,0.4,2.1-0.1l2.4-2.3c0.3-0.3,0.5-0.8,0.5-1.2
			c0-0.5-0.3-0.9-0.6-1.2c-1.9-1.4-4.1-2.2-6.4-2.3v-3.3c0-0.9-0.7-1.6-1.6-1.6h-3.3c-0.9,0-1.6,0.7-1.6,1.6v3.3
			c-4.8,0.1-8.7,4.2-8.7,9.1c0,4,2.7,7.6,6.4,8.8l9.2,2.7c1.1,0.3,1.8,1.4,1.8,2.6c0,1.5-1.1,2.7-2.4,2.7h-5.7
			c-0.9,0-1.8-0.3-2.6-0.8c-0.6-0.4-1.5-0.4-2.1,0.1l-2.4,2.3c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.3,0.9,0.6,1.2
			c1.9,1.4,4.1,2.2,6.4,2.3v3.3c0,0.9,0.7,1.6,1.6,1.6h3.3c0.9,0,1.6-0.7,1.6-1.6v-3.3c4.8-0.1,8.7-4.2,8.7-9.1
			C892,429,889.3,425.4,885.6,424.3L885.6,424.3z"
          />
        </g>
      </g>
    </SvgLargeIcon>
  );
};

export default MoneyBag;
