import i18next from "i18next";
export const tabs = [
  {
    selectorID: 7,
    selector: "7",
  },
  {
    selectorID: 15,
    selector: "15",
  },
  {
    selectorID: 30,
    selector: "30",
  },
  {
    selectorID: 45,
    selector: "45",
  },
  {
    selectorID: 60,
    selector: "60",
  },
];

export const lineGraphDateFormat = "DD-MMM-YYYY";

const canvas = document.createElement("canvas");
const ctx = canvas.getContext("2d");
export const gradient1 = ctx.createLinearGradient(0, 0, 300, 0);
const gradient2 = ctx.createLinearGradient(0, 0, 300, 0);
const gradient3 = ctx.createLinearGradient(0, 0, 300, 0);
const gradient4 = ctx.createLinearGradient(0, 0, 300, 0);
const gradient5 = ctx.createLinearGradient(0, 0, 300, 0);
gradient1.addColorStop(0, "#00baff");
gradient1.addColorStop(1, "#0041f0");
gradient2.addColorStop(0, "#e6b019");
gradient2.addColorStop(1, "#ffc82c");
gradient3.addColorStop(0, "#0082b3");
gradient3.addColorStop(1, "#00baff");
gradient4.addColorStop(0, "#aa0025");
gradient4.addColorStop(1, "#d5002f");
gradient5.addColorStop(0, "#98d1ff");
gradient5.addColorStop(1, "#66bbff");

const graphColor = [gradient1, gradient2, gradient3, gradient4, gradient5];

export const createChartData = (widgetKey, data) => {
  let cpyData = [];
  let labels = [];
  let statusKeys = [];
  let dataset = {};
  let labelCodes = [];

  if (widgetKey === "IC") {
    data &&
      data.forEach((element) => {
        if (element) {
          cpyData.push(parseFloat(element.RecordCount));
          labels.push(element.Label);
          statusKeys.push(element.StatusKey);
          labelCodes.push(element.Code);
        }
      });
  } else {
    data &&
      data.forEach((element) => {
        if (element) {
          cpyData.push(parseFloat(element.RecordCount));
          labels.push(element.Label);
          statusKeys.push(element.StatusKey);
        }
      });
  }

  if (widgetKey === "IR") {
    dataset = {
      labels: labels,
      datasets: [
        {
          data: cpyData,
          fill: false,
          lineTension: 0.1,
          backgroundColor: "#004dff",
          borderColor: "#004dff",
          borderWidth: 1.5,
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#004dff",
          pointBackgroundColor: "#004dff",
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#004dff",
          pointHoverBorderColor: "#00baff",
          pointHoverBorderWidth: 4,
          pointRadius: 2,
          pointHitRadius: 10,
          label: i18next.t("msp.invoiceCount"),
          statusKey: statusKeys,
        },
      ],
      widgetKey: widgetKey,
    };
  } else if (widgetKey === "IC") {
    dataset = {
      labels: labels,
      codes: labelCodes,
      datasets: [
        {
          data: cpyData,
          backgroundColor: graphColor,
          label: "",
          borderWidth: 0,
          statusKey: statusKeys,
        },
      ],
      widgetKey,
    };
  } else if (widgetKey === "IS") {
    dataset = {
      labels: labels,
      datasets: [
        {
          data: cpyData,
          backgroundColor: graphColor,
          label: "",
          borderWidth: 0,
          statusKey: statusKeys,
        },
      ],
      widgetKey: widgetKey,
    };
  } else
    dataset = {
      labels: labels,
      datasets: [
        {
          data: cpyData,
          backgroundColor: graphColor,
          label: labels,
          borderWidth: 0,
          statusKey: statusKeys,
        },
      ],
      widgetKey: widgetKey,
    };
  return dataset;
};

export const createDrilldownData = (data) => {
  let cpyData = [];
  let labels = [];
  const sortedData =
    data &&
    data.sort((a, b) =>
      parseInt(a.recordCount) < parseInt(b.recordCount) ? 1 : -1
    );
  if (sortedData && sortedData.length > 0) {
    sortedData &&
      sortedData.forEach((element) => {
        cpyData.push(parseFloat(element.recordCount));
        labels.push(element.country);
      });
    const horizontalBar = {
      labels: labels,
      datasets: [
        {
          data: cpyData,
          backgroundColor: gradient1,
          borderWidth: 0,
          barThickness: "flex", // number (pixels) or 'flex'
          maxBarThickness: 24, // number (pixels),
          datalabels: {
            color: "rgb(99, 115, 129)",
            anchor: "end",
            align: "end",
            font: {
              size: 12,
              family: "graphik",
              weight: "normal",
            },
            padding: {
              left: 16,
            },
          },
        },
      ],
    };
    return horizontalBar;
  } else {
    return {
      labels: labels,
      datasets: [],
    };
  }
};

export const setChartProps = (status) => {
  let label = "";
  let chartType = "doughnut";
  switch (status) {
    case "DS":
      label = i18next.t("msp.documentSubmissionCount");
      chartType = "doughnut";
      break;
    case "IS":
      label = i18next.t("msp.invoiceStatusCount");
      chartType = "bar";
      break;
    case "TS":
      label = i18next.t("msp.queryTypeTicketCount");
      chartType = "doughnut";
      break;
    case "NU":
      label = i18next.t("msp.newUsersCountRegistrationStatus");
      break;
    case "EU":
      label = i18next.t("msp.existingUsersCountStatus");
      break;
    case "IC":
      label = i18next.t("msp.invoiceCountryCount");
      chartType = "bar";
      break;
    case "IR":
      label = i18next.t("msp.rejectedInvoiceCount");
      chartType = "line";
      break;
    case "SC":
      label = i18next.t("msp.supplierCompanyCount");
      chartType = "doughnut";
      break;
    default:
      break;
  }
  return { label, chartType };
};
