import React from "react";

import ControlStyled, {
  Center,
  Left,
  Right,
  PageInput,
  MaxPageNumber,
  TopFaceArrow,
  BottomFaceArrow,
  ZoomOutIcon,
  ZoomInIcon,
} from "./style";

import { IconWrapper } from "components/index";
import {
  ArrowThin,
  Download,
  MinusCircle,
  PlusCircle,
} from "components/SVG/index";

const scaleBy = 0.2;

const PdfControls = (props) => {
  return (
    <ControlStyled>
      <Left>
        <IconWrapper onClick={props.onDownload}>
          <Download />
        </IconWrapper>
      </Left>
      <Center>
        <BottomFaceArrow
          disabled={props.currentPage === 1}
          onClick={props.prevPage}
        >
          <ArrowThin />
        </BottomFaceArrow>

        <PageInput
          min="1"
          max={props.maxPage}
          value={props.currentPage || ""}
          onChange={props.gotoPage}
        />
        {props.maxPage ? (
          <MaxPageNumber>/&nbsp;{props.maxPage}</MaxPageNumber>
        ) : null}

        <TopFaceArrow
          onClick={props.nextPage}
          disabled={props.currentPage >= props.maxPage}
        >
          <ArrowThin />
        </TopFaceArrow>
      </Center>
      <Right>
        <ZoomInIcon onClick={() => props.onZoomIn(scaleBy)}>
          <PlusCircle />
        </ZoomInIcon>
        <ZoomOutIcon onClick={() => props.onZoomOut(scaleBy)}>
          <MinusCircle />
        </ZoomOutIcon>
      </Right>
    </ControlStyled>
  );
};

export default PdfControls;
