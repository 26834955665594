import commonReducer from "./../store/reducers";
import commonSaga from "./../store/sagas";
import clientsReducer from "../container/Administration/ClientManagement/store/reducer";
import clientsSaga from "../container/Administration/ClientManagement/store/saga";
import registrationReducer from "../container/Registration/store/reducer";
import registrationSaga from "../container/Registration/store/saga";
import authenticationReducer from "../container/Authentication/store/reducer";
import authenticationSaga from "../container/Authentication/store/saga";
import forgotPasswordReducer from "../container/ForgotPassword/store/reducer";
import forgotPasswordSaga from "../container/ForgotPassword/store/saga";
import invoiceStatusReducer from "../container/InvoiceStatus/store/reducer";
import invoiceStatusSaga from "../container/InvoiceStatus/store/saga";
import supportReducer from "../container/Support/store/reducers";
import supportSaga from "../container/Support/store/saga";
import homePageReducer from "../container/HomePage/store/reducer";
import homePageSaga from "../container/HomePage/store/saga";
import verificationreducer from "../container/Verfication/store/reducer";
import initVerificationSaga from "../container/Verfication/store/saga";
import updatePasswordOnLoginReducer from "../container/UpdatePasswordOnLogin/store/reducer";
import updatePasswordOnLoginSaga from "../container/UpdatePasswordOnLogin/store/saga";
import adminLogsReducer from "../container/Administration/Logs/store/reducers";
import adminLogsSaga from "../container/Administration/Logs/store/saga";
import customListReducer from "../container/Administration/CustomList/store/reducers";
import customListSaga from "../container/Administration/CustomList/store/sagas";
import insightsReducer from "../container/Insights/store/reducers";
import insightsSaga from "../container/Insights/store/sagas";
import emailTemplatesReducer from "../container/Administration/EmailTemplate/store/reducers";
import emailTemplatesSaga from "../container/Administration/EmailTemplate/store/saga";
import helpReducer from "../container/Administration/Help/store/reducer";
import helpSaga from "../container/Administration/Help/store/saga";
import newsAndEventsReducer from "../container/Administration/News_Events/store/reducer";
import newsAndEventsSaga from "../container/Administration/News_Events/store/saga";
import rolePermissionReducer from "../container/Administration/RolePermissions/store/reducers";
import rolePermissionSaga from "../container/Administration/RolePermissions/store/saga";
import reconciliationReducer from "../container/Reconciliation/store/reducer";
import reconciliationSaga from "../container/Reconciliation/store/saga";
import invoiceListReducer from "../container/Analytics/InvoiceList/store/reducers";
import invoiceListSaga from "../container/Analytics/InvoiceList/store/sagas";
import invoiceStatusChangeNotificationReducer from "../container/Analytics/InvoiceStatusChangeNotification/store/reducers";
import invoiceStatusChangeNotificationSaga from "../container/Analytics/InvoiceStatusChangeNotification/store/saga";
import statisticsReducer from "../container/Analytics/Statistics/store/reducers";
import statisticsSaga from "../container/Analytics/Statistics/store/sagas";
import requestsManagementReducer from "../container/RequestManagement/store/reducers";
import requestsManagementSaga from "../container/RequestManagement/store/sagas";
import submissionProfilesReducer from "../container/Administration/ModuleConfiguration/SubmissionProfiles/store/reducers";
import submissionProfilesSaga from "../container/Administration/ModuleConfiguration/SubmissionProfiles/store/sagas";

import userGroupReducer from "../container/Administration/UserGroup/store/reducers";
import userGroupSaga from "../container/Administration/UserGroup/store/sagas";
import supplierFieldReducer from "../container/Administration/AttributeManagement/store/reducers";
import supplierFieldSaga from "../container/Administration/AttributeManagement/store/sagas";
import userManagementReducer from "../container/Administration/UserManagement/store/reducer";
import userManagementSaga from "../container/Administration/UserManagement/store/saga";
import manageUploadReducer from "../container/Administration/ManageUpload/store/reducers";
import manageUploadSaga from "../container/Administration/ManageUpload/store/saga";
import vmdReducer from "../container/ManageSupplier/store/reducer";
import vmdSaga from "../container/ManageSupplier/store/sagas";
import workflowReducer from "../container/Administration/Workflow/store/reducers";
import workflowSaga from "../container/Administration/Workflow/store/sagas";
import ssoAuthReducer from "../container/SSOAuthentication/store/reducers";

import NotificationsReducer from "../container/Notifications/store/reducer";
import NotificationsSaga from "../container/Notifications/store/saga";
import userProfileSaga from "../container/UserProfile/store/saga";
import userProfileReducer from "../container/UserProfile/store/reducer";
import helpdeskConfigReducer from "../container/Administration/ModuleConfiguration/HelpdeskConfiguration/store/reducers";
import helpdeskConfigSaga from "../container/Administration/ModuleConfiguration/HelpdeskConfiguration/store/sagas";
import searchConfigReducer from "../container/Administration/ModuleConfiguration/SearchConfiguration/store/reducers";
import searchConfigSaga from "../container/Administration/ModuleConfiguration/SearchConfiguration/store/sagas";
import advPaymentReducer from "container/AdvancePayments/store/reducers";
import advPaymentSaga from "container/AdvancePayments/store/sagas";

import advPaymentConfigReducer from "container/Administration/ModuleConfiguration/AdvancePaymentConfig/store/reducers";
import advPaymentConfigSaga from "container/Administration/ModuleConfiguration/AdvancePaymentConfig/store/sagas";

import rubberbandReducer from "../components/Rubberband/store/reducers";
import rubberbandSaga from "../components/Rubberband/store/saga";

import dsCommonReducer from "container/DS-common/store/reducers";
import dsCommonSaga from "container/DS-common/store/sagas";

import dsSubmittedCommonReducer from "container/DS-common-submitted-invoice/store/reducers";
import dsSubmittedCommonSaga from "container/DS-common-submitted-invoice/store/sagas";

import invoiceUploadReducer from "container/DS-upload-invoice/store/reducers";
import invoiceUploadSaga from "container/DS-upload-invoice/store/sagas";

import createInvoiceReducer from "container/DS-create-invoice/store/reducers";
import createInvoiceSaga from "container/DS-create-invoice/store/sagas";

import uploadedInvoiceReducer from "container/DS-Invoice-review/store/reducers";
import uploadedInvoiceSaga from "container/DS-Invoice-review/store/sagas";

import submissionHistoryReducer from "container/DS-submission-history/store/reducers";
import submissionHistorySaga from "container/DS-submission-history/store/sagas";

import generateInvoiceReducer from "container/DS-generate-invoice/store/reducers";
import generateInvoiceSaga from "container/DS-generate-invoice/store/sagas";

export const reducerDesc = [
  {
    key: "common",
    reducer: commonReducer,
  },
  {
    key: "forgotPassword",
    reducer: forgotPasswordReducer,
  },
  {
    key: "registration",
    reducer: registrationReducer,
  },
  {
    key: "authenticate",
    reducer: authenticationReducer,
  },
  {
    key: "verification",
    reducer: verificationreducer,
  },
  {
    key: "adminLogs",
    reducer: adminLogsReducer,
  },
  {
    key: "clients",
    reducer: clientsReducer,
  },
  {
    key: "homePage",
    reducer: homePageReducer,
  },
  {
    key: "customListItems",
    reducer: customListReducer,
  },
  {
    key: "insights",
    reducer: insightsReducer,
  },

  {
    key: "emailTemplates",
    reducer: emailTemplatesReducer,
  },
  {
    key: "helpdesk",
    reducer: supportReducer,
  },
  {
    key: "help",
    reducer: helpReducer,
  },
  { key: "invoiceStatus", reducer: invoiceStatusReducer },
  { key: "manageUpload", reducer: manageUploadReducer },
  {
    key: "newsAndEvents",
    reducer: newsAndEventsReducer,
  },
  {
    key: "rolePermission",
    reducer: rolePermissionReducer,
  },
  {
    key: "reconciliation",
    reducer: reconciliationReducer,
  },
  {
    key: "reportDetails",
    reducer: invoiceListReducer,
  },
  {
    key: "invoiceStatusChangeNotification",
    reducer: invoiceStatusChangeNotificationReducer,
  },
  { key: "statistics", reducer: statisticsReducer },
  {
    key: "requestsManagement",
    reducer: requestsManagementReducer,
  },
  {
    key: "submissionProfiles",
    reducer: submissionProfilesReducer,
  },
  // {
  //   key: "supplier",
  //   reducer: supplierReducer,
  // },
  {
    key: "supplierFieldsItems",
    reducer: supplierFieldReducer,
  },
  {
    key: "userGroup",
    reducer: userGroupReducer,
  },
  {
    key: "userManagement",
    reducer: userManagementReducer,
  },
  { key: "VMDModule", reducer: vmdReducer },
  {
    key: "workflow",
    reducer: workflowReducer,
  },
  {
    key: "ssoLogin",
    reducer: ssoAuthReducer,
  },

  {
    key: "notifications",
    reducer: NotificationsReducer,
  },
  {
    key: "userProfile",
    reducer: userProfileReducer,
  },
  {
    key: "updatePasswordOnLogin",
    reducer: updatePasswordOnLoginReducer,
  },
  {
    key: "helpdeskConfig",
    reducer: helpdeskConfigReducer,
  },
  {
    key: "searchConfig",
    reducer: searchConfigReducer,
  },
  {
    key: "advancePayment",
    reducer: advPaymentReducer,
  },
  {
    key: "advancePaymentCofig",
    reducer: advPaymentConfigReducer,
  },
  {
    key: "rubberband",
    reducer: rubberbandReducer,
  },
  {
    key: "dsCommonState",
    reducer: dsCommonReducer,
  },

  {
    key: "uploadDocument",
    reducer: invoiceUploadReducer,
  },

  {
    key: "createInvoice",
    reducer: createInvoiceReducer,
  },

  {
    key: "uploadedInvoices",
    reducer: uploadedInvoiceReducer,
  },

  {
    key: "submittedInvoices",
    reducer: dsSubmittedCommonReducer,
  },
  {
    key: "submissionHistory",
    reducer: submissionHistoryReducer,
  },
  {
    key: "generateInvoice",
    reducer: generateInvoiceReducer,
  },
];

export const sagaDesc = [
  { key: "common", saga: commonSaga },
  { key: "registration", saga: registrationSaga },
  { key: "authenticate", saga: authenticationSaga },
  { key: "forgotPassword", saga: forgotPasswordSaga },
  { key: "homePage", saga: homePageSaga },
  { key: "verification", saga: initVerificationSaga },
  { key: "adminLogs", saga: adminLogsSaga },
  {
    key: "clients",
    saga: clientsSaga,
  },
  {
    key: "customListItems",
    saga: customListSaga,
  },
  { key: "insights", saga: insightsSaga },

  {
    key: "emailTemplates",
    saga: emailTemplatesSaga,
  },
  { key: "helpdesk", saga: supportSaga },
  { key: "help", saga: helpSaga },
  { key: "invoiceStatus", saga: invoiceStatusSaga },
  { key: "manageUpload", saga: manageUploadSaga },
  {
    key: "newsAndEvents",
    saga: newsAndEventsSaga,
  },
  {
    key: "rolePermission",
    saga: rolePermissionSaga,
  },
  {
    key: "reconciliation",
    saga: reconciliationSaga,
  },
  {
    key: "reportDetails",
    saga: invoiceListSaga,
  },
  {
    key: "invoiceStatusChangeNotification",
    saga: invoiceStatusChangeNotificationSaga,
  },
  { key: "statistics", saga: statisticsSaga },
  {
    key: "requestsManagement",
    saga: requestsManagementSaga,
  },
  {
    key: "submissionProfiles",
    saga: submissionProfilesSaga,
  },
  // { key: "supplier", saga: supplierSaga },
  {
    key: "supplierFieldsItems",
    saga: supplierFieldSaga,
  },
  {
    key: "userGroup",
    saga: userGroupSaga,
  },
  {
    key: "userManagement",
    saga: userManagementSaga,
  },
  { key: "VMDModule", saga: vmdSaga },
  { key: "workflow", saga: workflowSaga },

  {
    key: "notifications",
    saga: NotificationsSaga,
  },
  {
    key: "userProfile",
    saga: userProfileSaga,
  },
  {
    key: "updatePasswordOnLogin",
    saga: updatePasswordOnLoginSaga,
  },
  {
    key: "helpdeskConfig",
    saga: helpdeskConfigSaga,
  },
  {
    key: "searchConfig",
    saga: searchConfigSaga,
  },
  {
    key: "advancePayment",
    saga: advPaymentSaga,
  },
  {
    key: "advancePaymentCofig",
    saga: advPaymentConfigSaga,
  },
  {
    key: "rubberband",
    saga: rubberbandSaga,
  },
  {
    key: "dsCommonState",
    saga: dsCommonSaga,
  },
  {
    key: "uploadDocument",
    saga: invoiceUploadSaga,
  },
  {
    key: "createInovice",
    saga: createInvoiceSaga,
  },

  {
    key: "submittedInvoices",
    saga: dsSubmittedCommonSaga,
  },
  {
    key: "uploadedInvoices",
    saga: uploadedInvoiceSaga,
  },
  {
    key: "submissionHistory",
    saga: submissionHistorySaga,
  },
  {
    key: "generateInvoice",
    saga: generateInvoiceSaga,
  },
];
