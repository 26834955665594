import LabledControl, {
  InputGroup,
} from "components/Inputs/LabeledInput/style";
import InputAddon from "components/InputAddon/index";
import InputControl from "components/Inputs/Input/style";
import styled from "styled-components";

const GridSearchStyled = styled(LabledControl)`
  max-height: 2rem;
  ${InputAddon} {
    border-left: var(--oneDotFivePx) solid var(--input-border-clr);
    border-right: none;
    border-radius: 1em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    max-height: 2rem;
    color: var(--grey-clr);
  }
  ${InputGroup} {
    margin: 0;
  }
  ${InputControl} {
    border-radius: 1em;
    max-height: 2rem;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-width: calc(100% - 1.75em);
    padding-right: 0.25rem;
  }
`;

export default GridSearchStyled;
