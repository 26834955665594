import { createSelector } from "reselect";
import { initialState } from "./reducers";

const selectAdvancePayments = (state) =>
  state.get("advancePayment", initialState);

export const selectAdvancePaymentsList = () =>
  createSelector(selectAdvancePayments, (VPState) =>
    VPState.get("advancePayments")
  );

export const selectAdvancePaymentsStatus = () =>
  createSelector(selectAdvancePayments, (VPState) =>
    VPState.get("fetchingAdvancePayments")
  );

export const selectAdvPaymentsActiveCols = () =>
  createSelector(selectAdvancePayments, (VPState) =>
    VPState.get("activeColumns")
  );

export const selectGridConfigs = () =>
  createSelector(selectAdvancePayments, (VPState) =>
    VPState.get("gridConfigs")
  );
export const selectFilters = () =>
  createSelector(selectAdvancePayments, (VPState) => VPState.get("filters"));
export const selectFilterApplied = () =>
  createSelector(selectAdvancePayments, (VPState) =>
    VPState.get("filterApplied")
  );

export const selectLastSavedLookup = () =>
  createSelector(selectAdvancePayments, (VPState) =>
    VPState.get("lastSavedLookup")
  );

export const selectSelectedItems = () =>
  createSelector(selectAdvancePayments, (VPState) =>
    VPState.get("selectedItems")
  );
