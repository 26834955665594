import { fromJS } from "immutable";

import {
  USER_CREATION_LIST,
  UPDATE_SUBMIT_STATUS,
  UPDATE_CONFIRM_STATUS,
  UPDATE_REQUEST_STATUS,
  FETCHING_SIGNUP_FIELDS,
  FETCH_GET_USER_BY_ID,
  APPROVE_USER_REQUEST_SUCCESS,
  REJECT_USER_REQUEST_SUCCESS,
  RESEND_APPROVED_EMAIL_SUCCESS,
  USER_CREATION_REQUEST_FETCH_STATUS,
  FETCH_GET_USER_BY_ID_STATUS,
  FETCH_SIGNUP_FIELDS,
  GET_USER_BY_ID,
} from "./actionTypes";

export const initialState = fromJS({
  requestList: [],
  isRequestListLoading: false,
  submitStatus: "",
  confirmStatus: "",
  requestStatus: "Not Approved",
  editDataStatus: true,
  editData: [],
  statusCounts: [],
  totalCount: 0,
  rejectStatus: false,
  approveStatus: false,
  resendEmailStatus: false,
  signUpFormFields: [],
  fetchSignUpFormFields: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_CREATION_REQUEST_FETCH_STATUS:
      return state.set("isRequestListLoading", action.boolean);
    case USER_CREATION_LIST: {
      return state
        .set("requestList", action.requestList)
        .set("statusCounts", action.statusCounts)
        .set("totalCount", action.totalCount);
    }
    case UPDATE_SUBMIT_STATUS:
      return state.set("submitStatus", action.status);
    case APPROVE_USER_REQUEST_SUCCESS:
      return state.set("approveStatus", action.boolean);
    case REJECT_USER_REQUEST_SUCCESS:
      return state.set("rejectStatus", action.boolean);
    case UPDATE_CONFIRM_STATUS:
      return state.set("confirmStatus", action.status);
    case UPDATE_REQUEST_STATUS:
      return state.set("requestStatus", action.requestStatus);
    case FETCH_GET_USER_BY_ID:
      return state.set("editData", action.data);
    case GET_USER_BY_ID:
      return state.set("editData", null);
    case FETCH_GET_USER_BY_ID_STATUS:
      return state.set("editDataStatus", action.boolean);
    case RESEND_APPROVED_EMAIL_SUCCESS:
      return state.set("resendEmailStatus", action.boolean);
    case FETCHING_SIGNUP_FIELDS:
      return state.set("fetchSignUpFormFields", action.boolean);
    case FETCH_SIGNUP_FIELDS:
      return state.set("signUpFormFields", action.data);
    default:
      return state;
  }
};
export default reducer;
