import axios from "./axios";
import { store } from "react-notifications-component";

import { statusCode } from "./messageConfig";
import { buildNotification } from "./notification";
import { storage } from "./storage";

const instance = axios;
/**
 * Request Wrapper with default success/error actions
 */
const onSuccess = (response) => response;

const onError = (error) => {
  const clientDetails = JSON.parse(storage.getItem("clientDetails")) || {};
  const clientName = clientDetails ? clientDetails.name : ""; //atob(clientDetails.name)

  // console.log("Request Failed:", error.config);
  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx

    const { data, status } = error.response || {};
    if (status === 401) {
      storage.removeItem("sessionDetails");
      storage.removeItem("languageId");
      storage.removeItem("i18nextLng");
      window.location.href = "/?" + clientName;
    }
    if (status === 500) {
      // do something;
    } else if (data.messageCode) {
      const msCode = data.messageCode;
      const moduleCode = msCode.split("-")[1];
      const errorCode = msCode.split("-")[2];
      if (statusCode.hasOwnProperty(moduleCode)) {
        if (moduleCode === "LC" && errorCode === "016") {
          return Promise.reject(error.response || error.message);
        }
        const item = statusCode[moduleCode].find(
          (x) => parseInt(x.code) === parseInt(errorCode)
        );

        const notification = buildNotification({
          //title: data.title ? data.title : "Some Error Occurred",
          message: item ? item.message : "msp.errorOccuredMsg", //error.message,
          type: "danger",
        });

        let updatedNotification = { ...notification };

        //for invoice already exists message, notification shouldn't dismiss
        if (
          moduleCode &&
          moduleCode === "EINV" &&
          errorCode &&
          errorCode === "001"
        ) {
          updatedNotification = {
            ...updatedNotification,
            dismiss: { duration: 0 },
          };
        }

        store.addNotification({
          ...updatedNotification,
        });
      } else {
        const notification = buildNotification({
          //title: data.title ? data.title : "Some Error Occurred",
          message: "msp.errorOccuredMsg", //error.message,
          type: "danger",
        });

        store.addNotification({
          ...notification,
        });
      }
    } else {
      const notification = buildNotification({
        //title: data.title ? data.title : "Some Error Occurred",
        message: "msp.errorOccuredMsg", //error.message,
        type: "danger",
      });

      store.addNotification({
        ...notification,
      });
    }
  } else {
    console.log("Error Message:", error.message);
  }

  return Promise.reject(error.response || error.message);
};

const apiCall = (url, config) => {
  return instance({
    url,
    ...config,
  })
    .then(onSuccess)
    .catch(onError);
};

export default apiCall;
