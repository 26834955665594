import CompactLookup from "components/CompactLookup/index";
import React from "react";

import {
  Checkbox,
  FloatingInput,
  LabeledInput,
  RadioButton,
  Datepicker,
  FloatingSelect,
  LabeledSelect,
  FloatingTextarea,
  LabeledTextarea,
  DataLookup,
  Label,
  FloatingMultiSelect,
} from "..";

const FormInput = (props) => {
  let inputElement = "";
  switch (props.inputType) {
    case "checkbox": {
      inputElement = (
        <Checkbox
          {...props.attrs}
          handleToggleCheckbox={props.onChangeHandler}
          checked={props.value}
        />
      );
      break;
    }
    case "datepicker": {
      inputElement = (
        <Datepicker
          {...props.attrs}
          dateValue={props.value}
          setDate={props.onChangeHandler}
          noFloating={props.noFloating}
          onFocusHandler={props.onFocusHandler}
          onClickHandler={props.onClickHandler}
          onBlurHandler={props.onBlurHandler}
          resetVisited={props.resetVisited}
        />
      );
      break;
    }
    case "floatingInput": {
      const attrs = {
        ...props.attrs,
        onChangeHandler: props.onChangeHandler,
        onBlurHandler: props.onBlurHandler,
        onKeyUp: props.onKeyUpHandler,
        onFocus: props.onFocusHandler,
        value: props.value || "",
        onFocusHandler: props.onFocusHandler,
        onClickHandler: props.onClickHandler,
        resetVisited: props.resetVisited,
      };

      inputElement = <FloatingInput {...attrs} />;
      break;
    }
    case "labeledInput": {
      const attrs = {
        ...props.attrs,
        onChangeHandler: props.onChangeHandler,
        onBlurHandler: props.onBlurHandler,
        onKeyUp: props.onKeyUpHandler,
        onFocus: props.onFocusHandler,
        value: props.value || "",
        onFocusHandler: props.onFocusHandler,
        onClickHandler: props.onClickHandler,
        resetVisited: props.resetVisited,
      };
      inputElement = <LabeledInput {...attrs} />;
      break;
    }
    case "radio": {
      inputElement = (
        <RadioButton
          checked={props.value}
          {...props.attrs}
          handleCheck={props.onChangeHandler}
        />
      );
      break;
    }
    case "floatingSelect": {
      inputElement = (
        <label>
          <FloatingSelect
            {...props.attrs}
            onChangeHandler={props.onChangeHandler}
            onBlurHandler={props.onBlurHandler}
            onFocus={props.onFocusHandler}
            value={props.value}
            isLoading={props.loading}
            inTable={props.inTable}
            resetVisited={props.resetVisited}
          />
        </label>
      );
      break;
    }
    case "labeledSelect": {
      inputElement = (
        <LabeledSelect
          {...props.attrs}
          onChangeHandler={props.onChangeHandler}
          onBlurHandler={props.onBlurHandler}
          onFocus={props.onFocusHandler}
          value={props.value}
          isLoading={props.loading}
          inTable={props.inTable}
          resetVisited={props.resetVisited}
        />
      );
      break;
    }
    case "floatingTextarea": {
      inputElement = (
        <FloatingTextarea
          {...props.attrs}
          value={props.value}
          onChangeHandler={props.onChangeHandler}
          onBlurHandler={props.onBlurHandler}
          noFloating={props.noFloating}
          resetVisited={props.resetVisited}
        />
      );
      break;
    }
    case "labeledTextarea": {
      inputElement = (
        <LabeledTextarea
          {...props.attrs}
          value={props.value}
          onChangeHandler={props.onChangeHandler}
          onBlurHandler={props.onBlurHandler}
          noFloating={props.noFloating}
          resetVisited={props.resetVisited}
        />
      );
      break;
    }
    case "lookup": {
      inputElement = (
        <DataLookup {...props.attrs} dataFetchStatus={props.loading} />
      );
      break;
    }
    case "compactLookup": {
      inputElement = <CompactLookup {...props} {...props.attrs} />;
      break;
    }
    case "label": {
      inputElement = <Label attrs={props.attrs} />;
      break;
    }
    case "floatingMultiSelect": {
      inputElement = (
        <FloatingMultiSelect
          {...props.attrs}
          value={props.value}
          onChangeHandler={props.onChangeHandler}
        />
      );
      break;
    }
    default: {
      const attrs = {
        ...props.attrs,
        onChangeHandler: props.onChangeHandler,
        onBlurHandler: props.onBlurHandler,
        onFocus: props.onFocusHandler,
        value: props.value || "",
        onFocusHandler: props.onFocusHandler,
        onClickHandler: props.onClickHandler,
        resetVisited: props.resetVisited,
      };
      inputElement = <LabeledInput {...attrs} />;
      break;
    }
  }

  return inputElement;
};

export default FormInput;
