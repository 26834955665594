import React, { useEffect, useRef } from "react";
import { getRoutePathByKey } from "helper/index";
import {
  Bell,
  Coin,
  QuestionCircle,
  SearchIcon,
  Arrow,
} from "components/SVG/index";
import { A } from "components/";
import { Dropdown, HeaderSeparator, VerticalSeparator } from "../style";
import GlobalSearch from "./GlobalSearch/index";
import CurrencyListDropdown from "./CurrencyDropdown/index";
import SiteActionStyled, {
  ActionItems,
  GlobalSearchDropdown,
  CurrencyDropdown,
  GlobalSearchPortal,
  NotificationDropdown,
  NotificationActionItem,
} from "./style";
import Notifications from "container/Notifications";
const SiteActions = (props) => {
  const viewHelp = getRoutePathByKey("viewHelp");
  //const isAdminModule = adminPaths.includes(history.location.pathname);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          const component = ref.current.id;

          switch (component) {
            case "currencyId":
              props.closeCurrencyDropdown();
              break;
            case "globalSearch":
              props.closeSearch();
              break;
            case "notification":
              props.closeNotification();
              break;
            default:
              break;
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  return (
    <SiteActionStyled
      searchOpened={props.searchOpened}
      currencyDropdownOpened={props.currencyDropdownOpened}
      notificationOpened={props.notificationOpened}
      hasCurrency={props.selectedCurrency}
    >
      <ActionItems
        tabIndex="0"
        id="currencyId"
        ref={props.currencyDropdownOpened ? wrapperRef : null}
      >
        <CurrencyListDropdown
          setSelectedCurrency={props.setSelectedCurrency}
          selectedCurrency={props.selectedCurrency}
          currencyList={props.currencyList}
          defaultCurrency={props.defaultCurrency}
        />
        <CurrencyDropdown
          tabIndex="-1"
          title={props.selectedCurrency}
          onClick={props.toggleCurrDropdown}
        >
          {props.selectedCurrency ? (
            <>
              {props.selectedCurrency}
              <Arrow />
            </>
          ) : (
            <Coin />
          )}
        </CurrencyDropdown>
      </ActionItems>

      <GlobalSearchPortal
        tabIndex="0"
        id="globalSearch"
        ref={props.searchOpened ? wrapperRef : null}
        onKeyPress={(e) => {
          if (e.target.id === "globalSearch" && e.key === "Enter") {
            props.togglSearchBar(e);
          }
        }}
      >
        <GlobalSearch
          show={props.searchOpened}
          onCloseSearch={(e) => {
            if (e) e.stopPropagation();
            props.closeSearch();
          }}
          invoicePaymentData={props.invoicePaymentData}
        />
        <GlobalSearchDropdown
          tabIndex="-1"
          onClick={props.togglSearchBar}
          aria-label="global-search"
        >
          <SearchIcon />
        </GlobalSearchDropdown>
      </GlobalSearchPortal>

      <ActionItems
        tabIndex="0"
        id="viewHelp"
        onClick={props.setSiteActionVisited}
      >
        <Dropdown tabIndex="-1" aria-label="faq" as={A} to={viewHelp}>
          <QuestionCircle />
        </Dropdown>
      </ActionItems>
      {/* {props.showChatBot && !isAdminModule ? (
        <ActionItems tabIndex="0" onClick={props.handleChatBot}>
          <Dropdown tabIndex="-1" aria-label="speechBubble">
            <SpeechBubble />
          </Dropdown>
        </ActionItems>
      ) : null} */}
      <NotificationActionItem
        tabIndex="0"
        id="notification"
        ref={props.notificationOpened ? wrapperRef : null}
      >
        <Notifications />
        <NotificationDropdown
          tabIndex="-1"
          onClick={props.toggleNotification}
          aria-label="notifications"
        >
          <Bell />
        </NotificationDropdown>
      </NotificationActionItem>
      <VerticalSeparator>
        <HeaderSeparator />
      </VerticalSeparator>
    </SiteActionStyled>
  );
};

export default SiteActions;
