import { createGlobalStyle, css } from "styled-components";

const GlobalStyle = createGlobalStyle`

div, input, form {
  box-sizing: border-box;
}
input{
  line-height: calc(1em + .2rem);
}
textarea {
    resize: none;
}
input:-internal-autofill-selected {
  background-color: #FFF !important;
}
button {
  cursor: pointer;
}
p, h1, h2, h3, h4, span, ul, li {
  padding:0;
  margin: 0;
  font-weight: var(--fw-normal);
}
a {
  text-decoration:none;
}
button {
  cursor: pointer;
}
img {
  display: block;
  
}
ul,
li {
  list-style: none;
}
small{
  font-size: var(--fs-12);
}
i {
  font-family: var(--ff-italic);
}

strong {
  font-family: var(--ff-medium);
  font-weight: var(--fw-normal);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  
  transition: background-color 5000s ease-in-out 0s;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
 #retryButton {
    cursor:pointer
  }
 
`;
export const small = css`
  font-size: var(--fs-12);
`;
export const italic = css`
  font-family: var(--ff-italic);
`;
export const strong = css`
  font-family: var(--ff-medium);
  font-weight: var(--fw-normal);
`;
export const bold = css`
  font-weight: var(--fw-bold);
`;

export const centered = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const icons = css`
  max-height: 2.5em;
  max-width: 2.5em;
  border: none;
  outline: none;
  fill: currentColor;
  min-width: 0.5rem;
  min-height: 0.5rem;
  pointer-events: none;
`;
export const iconsLg = css`
  min-width: 0.5rem;
  min-height: 0.5rem;
  border: none;
  outline: none;
  fill: currentColor;
`;
export const errorText = css`
  color: var(--error-clr);
  font-size: var(--fs-12);
`;
export const line = css`
  border: none;
  height: 0.0625em;
  background-color: var(--offwhite-clr);
  margin-bottom: 1.5em;
`;

export const dropdown = css`
  transition: transform 0.25s;
  color: inherit;
  background: transparent;
  border: none;
  text-align: inherit;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
`;

export const dropdownContent = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 0.25s ease-in-out 100ms;
`;

export const errorHighlight = css`
  background-color: rgba(213, 0, 47, 0.06);
  color: var(--error-clr);
  border-radius: 0.25em;
  display: inline-flex;
  align-items: center;
  padding: 0.25em;
`;

export const caretCss = css`
  position: absolute;
  top: 1.25em;
  content: " ";
  width: 0.625em;
  height: 0.625em;
  background-color: var(--light-clr);
  border-right: var(--onePx) solid rgb(220, 226, 231);
  border-bottom: var(--onePx) solid rgb(220, 226, 231);
  transform: rotate(-225deg);
`;

export default GlobalStyle;
