import React from "react";
import SvgIcon from "./style";

const QuestionCircle = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-1128.000000, -206.000000)">
          <g transform="translate(1112.000000, 54.000000)">
            <g transform="translate(8.000000, 144.000000)">
              <g transform="translate(8.000000, 8.000000)">
                <path d="M8,10.625 C7.51675,10.625 7.125,11.01675 7.125,11.5 C7.125,11.98325 7.51675,12.375 8,12.375 C8.48325,12.375 8.875,11.98325 8.875,11.5 C8.875,11.01675 8.48325,10.625 8,10.625 Z M8.2396875,9.875 L7.7396875,9.875 C7.53259375,9.875 7.3646875,9.70709375 7.3646875,9.5 L7.3646875,9.48809375 C7.3646875,7.289875 9.7846875,7.5 9.7846875,6.13159375 C9.7846875,5.50609374 9.22965625,4.87499999 7.9896875,4.87499999 C7.0789375,4.87499999 6.60640625,5.17653124 6.13934375,5.77162499 C6.01721875,5.92724999 5.79390624,5.95896874 5.63159374,5.84587499 L5.22115624,5.55993749 C5.04537499,5.43746874 5.00678124,5.19209374 5.13849999,5.02315624 C5.80181249,4.17231249 6.5888125,3.62499999 7.9896875,3.62499999 C9.6246875,3.62499999 11.0346875,4.55471874 11.0346875,6.13159375 C11.0346875,8.23828125 8.6146875,8.126875 8.6146875,9.48809375 L8.6146875,9.5 C8.6146875,9.70709375 8.44678125,9.875 8.2396875,9.875 Z M8,1.24999998 C11.7069063,1.24999998 14.75,4.25234374 14.75,8 C14.75,11.7278438 11.7309375,14.75 8,14.75 C4.27362499,14.75 1.24999998,11.7324375 1.24999998,8 C1.24999998,4.27490624 4.26881249,1.24999998 8,1.24999998 M8,0.249999981 C3.72009374,0.249999981 0.249999981,3.72134374 0.249999981,8 C0.249999981,12.2811563 3.72009374,15.75 8,15.75 C12.2799063,15.75 15.75,12.2811563 15.75,8 C15.75,3.72134374 12.2799063,0.249999981 8,0.249999981 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default QuestionCircle;
