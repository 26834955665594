import styled from "styled-components";

const getButtonVarient = (props) => {
  switch (true) {
    case props.sm:
      return `var(--fs-12)`;
    case props.lg:
      return `var(--fs-18)`;
    default:
      return `var(--fs-16)`;
  }
};
const Button = styled.button.attrs((props) => ({
  type: props.type || "button",
}))`
  text-transform: none;
  background: ${(props) =>
    props.disabled ? `var(--disabled-clr-dark)` : `var(--light-clr)`};
  color: ${(props) =>
    props.disabled ? `var(--light-clr)` : `var(--grey-clr)`};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  padding: 0.65em 1.25em;
  font-size: ${(props) => getButtonVarient(props)};
  font-family: var(--ff-medium);
  border-radius: 1.5em;
  border: 0.09375rem solid var(--dark-grey-clr);
  /* margin-bottom: 0.5em; */
  outline: none;
  :hover,
  :focus {
    box-shadow: ${(props) => (props.disabled ? "inset" : "")} 0 0 0.8rem
      currentColor;
  }
`;

export const PrimaryBtn = styled(Button)`
  border-color: transparent;
  background: ${(props) =>
    props.disabled ? `var(--disabled-clr-dark)` : `var(--primary-btn-clr)`};
  background-origin: border-box;

  color: var(--light-clr);
  text-align: center;
  :hover,
  :focus {
    box-shadow: ${(props) =>
      props.disabled
        ? `inset 0 0 20px 20px rgb(255 255 255 / 50%)`
        : `rgb(102 187 255) 0 0 0.8rem`};
  }
`;
export const SecondaryBtn = styled(Button)`
  background: ${(props) =>
    props.disabled ? `var(--disabled-clr-dark)` : `var(--light-clr);`};
  border-color: ${(props) => (props.disabled ? `` : `var(--primary-clr)`)};
  color: ${(props) =>
    props.disabled ? `var(--light-clr)` : `var(--primary-clr)`};
`;

export default Button;
