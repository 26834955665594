import { Button } from "components/index";
import { dropdownContent } from "globalStyles";
import styled from "styled-components";

export const DropdownItem = styled.li`
  background-color: ${(props) =>
    props.default || props.active
      ? `var(--dropdown-hover-clr)`
      : `var(--light-clr)`};
  span {
    color: ${(props) =>
      props.default ? "var(--primary-clr)" : "var(--dark-clr)"};
  }
  ::before {
    opacity: ${(props) => (props.default ? 1 : 0)};
    content: "";
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 0.1875rem;
    background-color: var(--primary-clr);
  }
`;
export const SetDefaultBtn = styled(Button)``;

const CurrencyDropdownStyled = styled.ul`
  max-height: 40vh;
  overflow: auto;
  ${dropdownContent}
  background-color: #fff;
  border: var(--onePx) solid #dfe3e8;
  border-radius: 0.5em;
  padding: 0.7em;
  min-width: 15rem;
  z-index: 2;
  transform: scale(0, 1);
  transform-origin: left;
  left: calc(100% + 0.25em);
  color: var(--dark-clr);
  font-size: var(--fs-12);
  font-family: var(--ff-medium);
  line-height: 1;
  align-items: flex-start;
  @media (min-width: 36em) {
    left: 0;
    top: calc(100% + 0.2em);
    transform: scale(1, 0);
    transform-origin: top;
  }

  ${DropdownItem} {
    justify-content: space-between;
    display: flex;
    padding: 0.75em;
    cursor: pointer;
    opacity: 1;
    color: var(--dark-clr);
    font-size: clamp(var(--fs-12), 1vw, var(--fs-14));
    transition: opacity 200ms ease-in-out;
    width: 100%;
    border-radius: 0.1875em;
    line-height: 1;
    position: relative;
    :hover,
    :focus {
      background-color: var(--dropdown-hover-clr);
      > ${SetDefaultBtn} {
        display: block;
      }
    }
    ${SetDefaultBtn} {
      display: none;
      color: var(--primary-clr);
      font-family: var(--ff-main);
      background: transparent;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
      :hover,
      :focus {
        box-shadow: none;
      }
    }
  }
`;

export default CurrencyDropdownStyled;
