import { Container } from "components";
import styled from "styled-components";
import GrisSearchStyled from "components/GridSearch/style";
const LayoutStyled = styled.div`
  ${(props) =>
    props.auth
      ? `
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  `
      : `width: 100%;
  height: 100%;
  padding: 1em;`}
`;

export const AuthContainer = styled(Container)`
  width: 96%;
  max-width: 81.25em;
  margin: 0 auto;
`;
export const MainContainer = styled(AuthContainer)`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${GrisSearchStyled} {
    @media (min-width: 36em) {
      max-width: 30%;
    }
    @media (min-width: 62em) {
      max-width: 20%;
    }
  }
`;
export default LayoutStyled;
