import styled from "styled-components";

const StyledRadio = styled.input.attrs(() => ({
  type: "radio",
}))`
  position: absolute;
  appearance: none;
  & + label {
    position: relative;
    padding-left: 1.75rem;
    cursor: pointer;
    line-height: 2;
    display: inline-block;
    color: var(--grey-clr);
    font-size: var(--fs-14);
  }
  & + label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    background: var(--light-clr);
    border: var(--onePx) solid rgb(181, 192, 202);
    border-radius: 100%;
  }
  & + label::after {
    content: "";
    width: 1rem;
    height: 1rem;
    background: var(--primary-clr);
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    border-radius: 100%;
    transition: all 0.2s ease;
  }
  &:focus + label,
  &:hover + label {
    font-family: var(--ff-medium);
  }
  &:checked + label::before,
  &:focus + label::before,
  &:hover + label::before {
    border: var(--border-primary-1);
    box-shadow: rgb(102 187 255 / 50%) 0px 0px 0.8rem;
  }

  &:disabled {
    &:focus + label,
    &:hover + label {
      font-family: var(--ff-main);
    }

    &:checked + label::before,
    &:focus + label::before,
    &:hover + label::before {
      border: var(--onePx) solid rgb(181, 192, 202);
      box-shadow: none;
    }

    &:checked + label::after {
      background: rgb(181, 192, 202);
    }
  }

  &:disabled + label {
    color: var(--grey-clr);
    cursor: not-allowed;
  }
  &:disabled + label:before {
    box-shadow: none;
    background: var(--input-border-clr);
  }
  :not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
`;

export default StyledRadio;
