import { END, eventChannel } from "@redux-saga/core";
import axios from "../config/axiosCall";

export function upload({ url, payload, method = "post" }, onProgress) {
  const config = {
    onUploadProgress: onProgress,
    method,
    data: Array.isArray(payload) ? [...payload] : { ...payload },
  };
  try {
    return axios(url, { ...config }).catch((e) => {
      console.log(e);
    });
  } catch (error) {
    console.log(error);
  }
}

export const createUploader = ({ payload, url, method }) => {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {}; // it's necessarily. event channel should
    // return unsubscribe function. In our case
    // it's empty function
  });

  const uploadPromise = upload({ payload, url, method }, (event) => {
    if (event.loaded === 1) {
      emit(END);
    }

    emit(event);
  });

  return [uploadPromise, chan];
};
