import React, { useEffect, useState } from "react";
import TextareaControl from "./style";

const Textarea = (props) => {
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    props.resetVisited && setVisited(false);
  }, [props.resetVisited]);

  const onInputChange = (e) => {
    let { value, maxLength } = e.target;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    const strOfVal = strippedHtml.toString();

    if (strOfVal.length > maxLength) {
      const validatedLength = strOfVal.substring(0, maxLength);
      e.target.value = validatedLength;
    }
    props.onChangeHandler && props.onChangeHandler(e);
  };
  const handleOnBlur = (e) => {
    setVisited(true);
    let { value, maxLength } = e.target;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    const strOfVal = strippedHtml.toString();

    if (strOfVal.length > maxLength) {
      const validatedLength = strOfVal.substring(0, maxLength);
      e.target.value = validatedLength;
    }
    props.onBlurHandler && props.onBlurHandler(e);
  };
  const passedProps = { ...props };
  delete passedProps.onBlurHandler;
  delete passedProps.onChangeHandler;
  delete passedProps.showError;
  return (
    <TextareaControl
      {...passedProps}
      visited={visited || props.value}
      aria-label={props.label}
      onBlur={handleOnBlur}
      onChange={onInputChange}
      rows={props.rows || 3}
    />
  );
};

export default Textarea;
