import React from "react";
import SvgIcon from "./style";

const Star = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-1128.000000, -172.000000)">
          <g transform="translate(1112.000000, 54.000000)">
            <g transform="translate(8.000000, 112.000000)">
              <g transform="translate(8.000000, 6.000000)">
                <path d="M14.6694445,5.65277777 L10.6111111,5.0611111 L8.79722222,1.38333332 C8.47222222,0.72777776 7.53055555,0.719444426 7.20277778,1.38333332 L5.38888888,5.0611111 L1.33055554,5.65277777 C0.602777759,5.75833333 0.311111092,6.65555555 0.838888871,7.16944444 L3.77499999,10.0305556 L3.08055554,14.0722222 C2.95555554,14.8027778 3.72499999,15.35 4.36944444,15.0083334 L8,13.1 L11.6305556,15.0083334 C12.275,15.3472222 13.0444445,14.8027778 12.9194445,14.0722222 L12.225,10.0305556 L15.1611111,7.16944444 C15.6888889,6.65555555 15.3972222,5.75833333 14.6694445,5.65277777 L14.6694445,5.65277777 Z M11.2722222,9.71944445 L12.0444445,14.2194445 L8,12.0972222 L3.95833332,14.2222222 L4.73055555,9.72222223 L1.45833332,6.53055555 L5.97777777,5.87499999 L8,1.77777776 L10.0222222,5.87499999 L14.5416667,6.53055555 L11.2722222,9.71944445 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Star;
