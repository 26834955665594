import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ErrorText } from "components/index";
import { CustomLabel } from "components/Label/index";
import InputControl from "../Input/style";
import InputAddon from "components/InputAddon/index";
import LabledControl, { InputGroup } from "./style";

const LabeledInput = (props) => {
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    props.resetVisited && setVisited(false);
  }, [props.resetVisited]);

  const onInputChange = (e) => {
    let { value, maxLength, type } = e.target;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    const strOfVal = strippedHtml.toString();

    if (strOfVal.length > maxLength) {
      const validatedLength = strOfVal.substring(0, maxLength);
      e.target.value =
        type === "number" ? Number(validatedLength) : validatedLength;
    }
    props.onChangeHandler && props.onChangeHandler(e);
  };
  const handleOnBlur = (e) => {
    setVisited(true);
    let value = e.target.value;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;

    props.onBlurHandler && props.onBlurHandler(e);
  };
  const passedProps = { ...props };
  delete passedProps.onBlurHandler;
  delete passedProps.onChangeHandler;
  delete passedProps.showError;
  return (
    <LabledControl
      disabled={props.disabled}
      error={props.showError}
      visited={visited || props.value}
      icon={props.icon}
      title={props.label}
    >
      {props.label ? (
        <CustomLabel required={props.required} htmlFor={props.id}>
          {props.label}
        </CustomLabel>
      ) : null}
      <InputGroup>
        <InputControl
          {...passedProps}
          error={props.showError}
          visited={visited || props.value}
          aria-label={props.label}
          onBlur={handleOnBlur}
          onChange={onInputChange}
          icon={props.icon}
          data-inputType={props.dataInputType}
        />
        {props.icon ? (
          <InputAddon
            onClick={props.onAddonClick}
            tabIndex="-1"
            disabled={props.disabled}
          >
            {props.icon}
          </InputAddon>
        ) : null}
        <ErrorText>{props.showError ? props.error : null}</ErrorText>
      </InputGroup>
    </LabledControl>
  );
};
LabeledInput.propTypes = {
  id: PropTypes.any.isRequired,
  label: PropTypes.any,
  showError: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.string,
  icon: PropTypes.object,
  onAddonClick: PropTypes.func,
  onBlurHandler: PropTypes.func,
  onChangeHandler: PropTypes.func,
  required: PropTypes.bool,
  resetVisited: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default LabeledInput;
