import React, { useState } from "react";

import { FormInput, Tooltip } from "components/index";
import { TooltipText, TooltipWrapper } from "../Tooltip/style";
import { FormControlWrapper } from "./style";

const FormControl = (props) => {
  let timeout;
  const [active, setActive] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };
  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const attrs = {
    ...props.attrs,
    error: props.error,
    showError: props.showError,
  };

  return (
    <>
      <FormControlWrapper
        as={props.as}
        gutter={props.gutter}
        grow={props.grow}
        display={props.display}
        ref={setReferenceElement}
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
        noPadding={props.noPadding} // for control inside datatable
      >
        <FormInput
          noFloating={props.noFloating}
          attrs={attrs}
          inputType={props.inputType}
          onChangeHandler={props.onChangeHandler}
          onKeyPressHandler={props.onKeyPressHandler}
          onKeyUpHandler={props.onKeyUpHandler}
          onBlurHandler={props.onBlurHandler}
          value={props.value}
          loading={props.loading}
          onClickHandler={props.onClickHandler}
          onFocusHandler={props.onFocusHandler}
          inTable={props.inTable}
          resetVisited={props.resetVisited}
        />
      </FormControlWrapper>
      {active && props.hint && (
        <Tooltip
          triggerElem={referenceElement}
          placement={props.placement}
          hw={props.hw}
        >
          <TooltipWrapper>
            <TooltipText>{props.hint}</TooltipText>
          </TooltipWrapper>
        </Tooltip>
      )}
    </>
  );
};

export default FormControl;
