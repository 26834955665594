import { put, takeEvery, takeLatest, call, all } from "redux-saga/effects";
import { store } from "react-notifications-component";
import { buildNotification } from "../../../config/notification";
import axios from "../../../config/axiosCall";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";

import {
  fetchingInvoiceList,
  fetchInvoiceList,
  invoiceListFetchingComplete,
  fetchInvoiceDetails,
  invoiceDetailsFetchComplete,
  fetchPaymentDetails,
  fetchAdvancePaymentDetails,
  fetchingPaymentsList,
  paymentsListFetchingComplete,
  fetchPaymentsList,
  setDownloadInvoice,
  paymentDetailsFetchComplete,
  advancePaymentDetailsFetchComplete,
  dfmInvoiceFetchStatus,
} from "./actions";

import {
  INIT_DOWNLOAD_INVOICE_DFM,
  INIT_FETCH_INVOICE_DETAILS,
  INIT_FETCH_INVOICE_LIST,
  INIT_FETCH_PAYMENTS_LIST,
  INIT_FETCH_ADVANCE_PAYMENT_DETAILS,
  INIT_FETCH_PAYMENT_DETAILS,
} from "./actionTypes";

export default function* watchInvoiceStatusSaga() {
  yield all([
    takeLatest(INIT_FETCH_INVOICE_LIST, getInvoiceListSaga),
    takeLatest(INIT_FETCH_INVOICE_DETAILS, getInvoiceDetailsByIDSaga),
    takeLatest(INIT_FETCH_PAYMENT_DETAILS, getPaymentDetailsByIDSaga),
    takeLatest(INIT_FETCH_ADVANCE_PAYMENT_DETAILS, getAdvancePaymentDetailsByIDSaga),
    takeEvery(INIT_DOWNLOAD_INVOICE_DFM, getDownloadInvoiceFromDFMSaga),
    takeLatest(INIT_FETCH_PAYMENTS_LIST, getPaymentsListSaga),
  ]);
}

function* getInvoiceListSaga(action) {
  let invoiceData = [];
  let url = "/Search/listInvoice";
  yield put(fetchingInvoiceList());
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.payload);
  try {
    const response = yield call(axios, url, requestDetails);
    invoiceData = response.data || [];
    yield put(
      fetchInvoiceList(
        invoiceData.fieldLabels,
        invoiceData.invoiceList,
        invoiceData.lineitems,
        invoiceData.rowCount,
        invoiceData.rowCountForAllCurrencies
      )
    );
    yield put(invoiceListFetchingComplete());
  } catch (err) {
    yield put(invoiceListFetchingComplete());
  }
}
function* getInvoiceDetailsByIDSaga(action) {
  const { invoiceNumber, invoiceId, urn, LanguageID, isArchival } =
    action.payload;
  const isArchivalVal = isArchival ? isArchival : false;
  let url = "";
  let invoiceDetails = [];
  if (action.globalSearch) {
    if (invoiceNumber)
      url = `Search/listInvoiceByID?InvoiceNumber=${invoiceNumber}&LanguageID=${LanguageID}&isArchival=${isArchivalVal}`;
    else if (urn)
      url = `Search/listInvoiceByID?urn=${urn}&LanguageID=${LanguageID}&isArchival=${isArchivalVal}`;
  } else
    url = `Search/listInvoiceByID?invoiceID=${invoiceId}&LanguageID=${LanguageID}&isArchival=${isArchivalVal}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    invoiceDetails = response.data || [];

    if (response && response.status === 200) {
      yield put(
        fetchInvoiceDetails(
          invoiceDetails.fieldLabels,
          invoiceDetails.invoiceList,
          invoiceDetails.headerItems,
          invoiceDetails.lineitems,
          invoiceDetails.rowCount
        )
      );
      if (action.callback) {
        action.callback(response);
      }
    }
    if (response.status === 204) {
      if (action.globalSearch) {
        const notification = buildNotification({
          message: "msp.noResultFound",
          type: "danger",
        });
        store.addNotification({
          ...notification,
          dismiss: { duration: 2000 },
        });
      } else {
        const notification = buildNotification({
          message: "msp.noInvoiceDetailsDisplay",
          type: "danger",
        });
        store.addNotification({
          ...notification,
        });
      }
    }
    yield put(invoiceDetailsFetchComplete());
  } catch (error) {
    yield put(invoiceDetailsFetchComplete());
  }
}

function* getPaymentsListSaga(action) {
  let paymentsData = [];
  let url = "/Search/ListRemittance";
  yield put(fetchingPaymentsList());

  const requestDetails = { ...postRequestDetails };
  requestDetails.data = action.payload;
  try {
    const response = yield call(axios, url, requestDetails);

    paymentsData = response.data || [];
    yield put(
      fetchPaymentsList(
        paymentsData.fieldLabels,
        paymentsData.remittanceList,
        paymentsData.ticketCount,
        paymentsData.totalAmount
      )
    );
    yield put(paymentsListFetchingComplete());
  } catch (err) {
    yield put(paymentsListFetchingComplete());
  }
}

function* getPaymentDetailsByIDSaga(action) {
  const { RemittanceDocNum, invoiceId, LanguageID, isArchival } =
    action.payload;
  const isArchivalVal = isArchival ? isArchival : false;
  let url = "";
  let paymentDetails = [];
  if (action.globalSearch) {
    url = `Search/listRemittanceByID?RemittanceDocNum=${RemittanceDocNum}&LanguageID=${LanguageID}&isArchival=${isArchivalVal}`;
  } else
    url = `Search/listRemittanceByID?invoiceID=${invoiceId}&LanguageID=${LanguageID}&isArchival=${isArchivalVal}`;

  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);

    paymentDetails = response.data || [];
    if (response && response.status === 200) {
      yield put(
        fetchPaymentDetails(
          paymentDetails.fieldLabels,
          paymentDetails.remittanceList,
          paymentDetails.ticketCount
        )
      );
      if (action.callback) {
        action.callback(response);
      }
    }
    if (response.status === 204) {
      const notification = buildNotification({
        message: "msp.noPaymentDetailsToDisplay",
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    }
    yield put(paymentDetailsFetchComplete());
  } catch (err) {
    yield put(paymentDetailsFetchComplete());
  }
}

function* getAdvancePaymentDetailsByIDSaga(action) {
  const { invoiceId, LanguageID, isArchival } =
    action.payload;
  const isArchivalVal = isArchival ? isArchival : false;
  let url = "";
  let advancePaymentDetails = [];
  
  url = `Search/listAdvancePaymentsByID?invoiceID=${invoiceId}&LanguageID=${LanguageID}&isArchival=${isArchivalVal}`;

  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);

    advancePaymentDetails = response.data || [];
    if (response && response.status === 200) {
      yield put(
        fetchAdvancePaymentDetails(
          advancePaymentDetails.fieldLabels,
          advancePaymentDetails.advPaymentsList,
          advancePaymentDetails.totalCount
        )
      );
    }
    yield put(advancePaymentDetailsFetchComplete());
  } catch (err) {
    yield put(advancePaymentDetailsFetchComplete());
  }
}

function* getDownloadInvoiceFromDFMSaga(action) {
  if (action.urn) {
    let url = `Search/DownloadInvoicefromDFM?URN=${action.urn}`;
    try {
      yield put(dfmInvoiceFetchStatus(true));
      const response = yield call(axios, url, { responseType: "blob" });
      if (response) {
        yield put(setDownloadInvoice(response.data));
      }
      yield put(dfmInvoiceFetchStatus(false));
    } catch (err) {
      yield put(dfmInvoiceFetchStatus(false));
    }
  } else {
    return;
  }
}
