export const INIT_CUSTOM_LIST_FETCH = "INIT_CUSTOM_LIST_FETCH";
export const FETCHING_CUSTOM_LIST = "FETCHING_CUSTOM_LIST";
export const FETCH_CUSTOM_LIST = "FETCH_CUSTOM_LIST";
export const CUSTOM_LIST_FETCH_COMPLETE = "CUSTOM_LIST_FETCH_COMPLETE";
export const INIT_DELETE_CUSTOM_LIST_ITEM = "INIT_DELETE_CUSTOM_LIST_ITEM";
export const SAVE_CUSTOM_LIST_ITEM = "SAVE_CUSTOM_LIST_ITEM";
export const RESET_FORM_SAVED_STATE = "RESET_FORM_SAVED_STATE";
export const CUSTOM_LIST_ITEM_SAVED = "CUSTOM_LIST_ITEM_SAVED";
export const INIT_FETCH_CUSTOM_LIST_ITEMS_BY_ID =
  "INIT_FETCH_CUSTOM_LIST_ITEMS_BY_ID";
export const FETCHING_LIST_ITEM_DATA = "FETCHING_LIST_ITEM_DATA";

export const INIT_LIST_ITEMS_FETCH = "INIT_LIST_ITEMS_FETCH";
export const FETCHING_LIST_ITEMS = "FETCHING_LIST_ITEMS";
export const FETCH_LIST_ITEMS = "FETCH_LIST_ITEMS";
export const FETCHING_ITEM_DATA = "FETCHING_ITEM_DATA";
export const FETCH_ITEM_DATA = "FETCH_ITEM_DATA";
export const INIT_ITEMS_FETCH = "INIT_ITEMS_FETCH";
export const DELETE_ITEM = "DELETE_ITEM";
export const INIT_LANGUAGE_FETCH = "INIT_LANGUAGE_FETCH";
export const GET_LANGUAGE = "GET_LANGUAGE";
export const SAVE_ITEM_DATA = "SAVE_ITEM_DATA";
export const UPDATE_ITEM_DATA = "UPDATE_ITEM_DATA";
export const DELETE_ITEM_LANGUAGE = "DELETE_ITEM_LANGUAGE";

export const INIT_PARENT_LIST_FETCH = "INIT_PARENT_LIST_FETCH";
export const FETCHING_PARENT_LIST = "FETCHING_PARENT_LIST";
export const FETCH_PARENT_LIST = "FETCH_PARENT_LIST";
export const PARENT_LIST_FETCH_COMPLETE = "PARENT_LIST_FETCH_COMPLETE";

export const INIT_CHILD_LIST_FETCH = "INIT_CHILD_LIST_FETCH";
export const FETCHING_CHILD_LIST = "FETCHING_CHILD_LIST";
export const FETCH_CHILD_LIST = "FETCH_CHILD_LIST";
export const CHILD_LIST_FETCH_COMPLETE = "CHILD_LIST_FETCH_COMPLETE";
