import { fromJS } from "immutable";

import {
  FETCHING_STATISTICS,
  FETCH_STATISTICS,
  STATISTICS_FETCH_COMPLETE,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingStatisticsList: true,
  statisticsList: "",
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_STATISTICS:
      return state.set("fetchingStatisticsList", true);
    case FETCH_STATISTICS:
      return state.set("statisticsList", action.data);
    case STATISTICS_FETCH_COMPLETE: {
      return state.set("fetchingStatisticsList", false);
    }

    default:
      return state;
  }
};
export default reducer;
