import styled from "styled-components";
import { dropdownContent } from "globalStyles";
import { IconWrapper } from "components";

export const NotificationList = styled.ul``;
export const Title = styled.h1``;
export const NotificationItem = styled.li`
  justify-content: space-between;
  display: flex;
  padding: 0.75em;
  cursor: default;
  color: var(--dark-clr);
  font-size: clamp(var(--fs-12), 1vw, var(--fs-14));
  transition: opacity 200ms ease-in-out;
  width: 100%;
  border-radius: 0.1875em;
  line-height: 1;
  background-color: var(--light-clr);
  flex-direction: row;
  border-bottom: 0.09375em solid rgb(233, 237, 241);
  opacity: 1;
  ${(props) =>
    props.removed &&
    `
    opacity: 0;
    ;
    padding: 0;
    
    transition:opacity 700ms ease-out;`}
`;
export const NotificationInfo = styled.section``;
export const ActionDetails = styled.div``;
export const ActionHighlight = styled.div``;
export const ActionMetadata = styled.div``;
export const RemoveNotification = styled(IconWrapper)``;
export const ViewArchive = styled.button``;
export const ArchiveItem = styled.span``;

export const Importance = styled.span`
  color: ${(props) =>
    props.type === 1
      ? `var(--error-clr)`
      : props.type === 2
      ? `var(--warning-clr)`
      : ``};
  ::before {
    background-color: ${(props) =>
      props.type === 1
        ? `var(--error-clr)`
        : props.type === 2
        ? `var(--warning-clr)`
        : ``};
  }
`;

const NotificationStyled = styled.div`
  ${dropdownContent};
  background-color: #fff;
  left: calc(100% + 0.25em);
  border: 0.0625em solid #dfe3e8;
  border-radius: 0.5em;
  padding: 0.7em;
  min-width: 12.2rem;
  transform: scale(0, 1);
  transform-origin: left;
  transition: transform 400ms ease-in-out;
  top: 0.5em;
  bottom: 0;
  overflow: hidden;
  padding: 0.5em 1em;
  min-height: 12em;
  max-height: calc(100vh - 6em);
  min-width: calc(100vw - 3.2em);
  text-align: start;
  box-shadow: 0 0.125rem 2rem 0 rgba(0, 0, 0, 0.1);

  ${Title} {
    font-size: var(--fs-16);
    color: var(--dark-clr);
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;
    position: relative;
    ${IconWrapper} {
      svg {
        width: 1em;
      }
    }
  }

  ${NotificationList} {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding-inline: 0.5em;
    overflow: auto;
    text-align: start;
    width: 100%;
    margin-bottom: 0.5em;
    ${NotificationItem} {
      :hover,
      :focus {
        background-color: var(--dropdown-hover-clr);
      }
      ${NotificationInfo} {
        display: flex;
        flex-direction: column;
        gap: 1em;
        margin-bottom: 0.5em;
        ${ActionHighlight} {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          span {
            position: relative;
            display: flex;
          }
          span::before {
            content: "";
            position: absolute;
            display: inline;
            width: 0.4em;
            height: 0.4em;
            border-radius: 50%;
            margin-left: -0.7em;
          }
          span,
          span::before {
            align-items: center;
          }
        }
        ${ActionMetadata} {
          display: flex;
          gap: 1em;
          align-items: center;
          span,
          ${ArchiveItem} {
            display: flex;
            align-items: center;
            gap: 0.5em;
            line-height: 1;
          }
          svg {
            width: 1.1em;
          }
        }
        ${ArchiveItem} {
          cursor: pointer;
          :hover,
          :focus {
            color: var(--primary-clr);
          }
        }
        p {
          font-size: var(--fs-12);
        }
        strong {
          color: var(--dark-clr);
          font-weight: var(--fw-normal);
          line-height: 1.2;
          font-size: var(--fs-14);
        }
      }
      ${RemoveNotification} {
        font-size: var(--fs-12);
        margin-left: auto;
        align-self: flex-start;
        :hover,
        :focus {
          transform: scale(1.2);
        }
        svg {
          width: 1em;
        }
      }
    }
  }
  ${ViewArchive} {
    background-color: transparent;
    text-align: start;
    padding: 0.5em;
    color: var(--primary-clr);
    font-size: var(--fs-12);
    font-family: var(--ff-medium);
    font-weight: var(--fw-normal);
    margin-top: auto;
    border: none;
  }
  @media (min-width: 36em) {
    all: unset;
    position: absolute;
    border: 0.0625em solid #dfe3e8;
    border-radius: 0.5em;
    background-color: #fff;
    top: calc(100% + 0.2em);
    min-width: 12.25rem;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 400ms ease-in-out;
    z-index: 3;
    right: 0;
    padding: 0.5em;
    text-align: start;
    ${NotificationList} {
      max-height: calc(100vh - 10em);
      min-width: 23em;
    }
  }
`;

export default NotificationStyled;
