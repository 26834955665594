import { Separator } from "components";
import { IconWrapper } from "components/index";
import styled from "styled-components";
import { AuthContainer } from "../style";

export const ContentButton = styled.button`
  padding: 0.8em;
  transform: translateY(-250%);
  position: absolute;
  &:focus {
    transform: translateY(0);
  }
`;

const HeaderStyled = styled.header`
  text-align: center;
  width: 100%;
`;

export const ContainerHeader = styled(AuthContainer)`
  position: relative;
  background: var(--banner-gradient);
  background-size: 100% 100%;
  background-position: center 0;

  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  gap: 1em;
  max-height: 3em;
  justify-content: space-between;

  ${(props) =>
    props.hasBanner
      ? `border-radius: 0;
  box-shadow: unset;`
      : `border-radius: 0 0 calc(1.5em / 2) calc(1.5em / 2);
  box-shadow: 0 0.125em 1em 0 rgb(0 0 0 / 15%);`}

  @media (min-width: 36em) {
    max-height: 3.5em;
  }
  @media (min-width: 68.75em) {
    ${(props) =>
      props.hasBanner ? `` : `border-radius: 0 0 calc(1.5em) calc(1.5em);`}
  }

`;
export const VerticalSeparator = styled.li``;
export const HeaderSeparator = styled(Separator)`
  display: none;
  @media (min-width: 36em) {
    display: block;
  }
`;

export const Dropdown = styled(IconWrapper)`
  transition: transform 0.25s;
  background: transparent;
  border: none;
  text-align: inherit;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--banner-clr);
  font-family: var(--ff-main);
  svg {
    font-size: inherit;
    height: 100%;
    width: 100%;
  }
  @media (min-width: 36em) {
    font-size: var(--fs-16);
    svg {
      width: 1em;
    }
  }
`;
export default HeaderStyled;
