import React from "react";
import SvgIcon from "./style";

const SignOutIcon = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-1128.000000, -240.000000)">
          <g transform="translate(1112.000000, 54.000000)">
            <g transform="translate(8.000000, 176.000000)">
              <g transform="translate(8.000000, 8.000000)">
                <path d="M1.49707601,2.01169589 L5.61403508,2.01169589 C5.81988304,2.01169589 5.98830409,2.18011694 5.98830409,2.3859649 L5.98830409,2.63547757 C5.98830409,2.84132552 5.81988304,3.00974658 5.61403508,3.00974658 L1.49707601,3.00974658 C1.22261207,3.00974658 0.998050665,3.23430798 0.998050665,3.50877192 L0.998050665,12.4912281 C0.998050665,12.765692 1.22261207,12.9902534 1.49707601,12.9902534 L5.61403508,12.9902534 C5.81988304,12.9902534 5.98830409,13.1586745 5.98830409,13.3645224 L5.98830409,13.6140351 C5.98830409,13.8198831 5.81988304,13.9883041 5.61403508,13.9883041 L1.49707601,13.9883041 C0.670565284,13.9883041 -2.00000002e-08,13.3177388 -2.00000002e-08,12.4912281 L-2.00000002e-08,3.50877192 C-2.00000002e-08,2.6822612 0.670565284,2.01169589 1.49707601,2.01169589 Z M10.1988304,2.61988303 L9.97738792,2.84132552 C9.83079922,2.98791422 9.83079922,3.22495126 9.97738792,3.37153995 L14.094347,7.46978557 L5.36452241,7.46978557 C5.15867446,7.46978557 4.9902534,7.63820663 4.9902534,7.84405458 L4.9902534,8.15594542 C4.9902534,8.36179337 5.15867446,8.53021443 5.36452241,8.53021443 L14.094347,8.53021443 L9.98050683,12.6284601 C9.83391813,12.7750487 9.83391813,13.0120858 9.98050683,13.1586745 L10.2019493,13.380117 C10.348538,13.5267057 10.5855751,13.5267057 10.7321637,13.380117 L15.8627681,8.26510721 C16.0093567,8.11851852 16.0093567,7.88148148 15.8627681,7.73489279 L10.7290448,2.61988303 C10.5824561,2.47329433 10.3454191,2.47329433 10.1988304,2.61988303 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default SignOutIcon;
