import { icons, iconsLg } from "globalStyles";
import styled from "styled-components";

const SvgIcon = styled.svg`
  ${icons}
`;

export const SvgLargeIcon = styled.svg`
  ${iconsLg}
`;
export default SvgIcon;
