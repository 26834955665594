import React from "react";
import SvgIcon from "./style";

const SearchIcon = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-1073.000000, -21.000000)">
          <g transform="translate(32.000000, 0.000000)">
            <g transform="translate(1041.000000, 12.000000)">
              <g transform="translate(0.000000, 9.000000)">
                <path d="M21.8070176,20.6534113 L16.2748538,15.1212476 C16.1762183,15.0226121 16.0475634,14.9711501 15.9103314,14.9711501 L15.468616,14.9711501 C16.9395712,13.380117 17.840156,11.2573099 17.840156,8.92007797 C17.840156,3.99259258 13.8475634,-2.75000003e-08 8.92007797,-2.75000003e-08 C3.99259258,-2.75000003e-08 -2.75000003e-08,3.99259258 -2.75000003e-08,8.92007797 C-2.75000003e-08,13.8475634 3.99259258,17.840156 8.92007797,17.840156 C11.2573099,17.840156 13.380117,16.9395712 14.9711501,15.4729045 L14.9711501,15.9103314 C14.9711501,16.0475634 15.0269006,16.1762183 15.1212476,16.2748538 L20.6534113,21.8070176 C20.8549708,22.008577 21.1808967,22.008577 21.3824562,21.8070176 L21.8070176,21.3824562 C22.008577,21.1808967 22.008577,20.8549708 21.8070176,20.6534113 Z M8.92007797,16.4678363 C4.74736841,16.4678363 1.37231966,13.0927875 1.37231966,8.92007797 C1.37231966,4.74736841 4.74736841,1.37231966 8.92007797,1.37231966 C13.0927875,1.37231966 16.4678363,4.74736841 16.4678363,8.92007797 C16.4678363,13.0927875 13.0927875,16.4678363 8.92007797,16.4678363 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default SearchIcon;
