import { all, takeEvery, put, call, select } from "redux-saga/effects";
import { INIT_DS_SAVE } from "./actionTypes";
import { createUploader } from "helper/fileUploadWatcher";
import { setUploadProgress } from "./actions";

import { selectUploadProgress } from "./selectors";
import {
  setIsOCRExtracted,
  setSubmissionID,
} from "container/DS-common/store/actions";
export default function* watchUploadInvoiceSubmission() {
  yield all([takeEvery(INIT_DS_SAVE, uploadInvoiceSaga)]);
}

function* uploadInvoiceSaga(action) {
  try {
    const { request, uploadID } = action.data;

    const apiURL = "/Submission/InvoiceUpload";

    const [uploadPromise] = yield createUploader({
      payload: { ...request },
      url: apiURL,
    });

    const res = yield call(() => uploadPromise);

    const { data } = res;
    if (data) {
      const { isOCRExtracted, submissionId } = data;
      if (request.flowType === "1") {
        yield put(setSubmissionID(submissionId));
        yield put(setIsOCRExtracted(isOCRExtracted));
      }
      const uploadProgress = yield select(selectUploadProgress());
      const currentUploadProgress = yield uploadProgress.toJS();

      const updatedProgress = yield currentUploadProgress.map((item) => {
        let { uploading, uploaded, id } = item;
        if (id === uploadID) {
          uploading = false;
          uploaded = true;
        }
        return {
          ...item,
          uploading,
          uploaded,
        };
      });
      yield put(setUploadProgress(updatedProgress));
    }
  } catch (err) {
    const { uploadID } = action.data;
    const uploadProgress = yield select(selectUploadProgress());
    const currentUploadProgress = yield uploadProgress.toJS();

    const updatedProgress = yield currentUploadProgress.map((item) => {
      let { uploading, failed, id } = item;
      if (id === uploadID) {
        uploading = false;
        failed = true;
      }
      return {
        ...item,
        uploading,
        failed,
      };
    });
    yield put(setUploadProgress(updatedProgress));
  }
}
