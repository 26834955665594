import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ButtonWrapper, Modal, PrimaryBtn } from "components/index";
import DeleteConfirmMsg, { YesBtn, NoBtn } from "./style";

const DeleteBtn = (props) => {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleDelete = () => {
    setShowConfirmation(false);
    props.onDelete && props.onDelete();
  };
  return (
    <>
      <Modal
        centered
        show={showConfirmation}
        preventOutterClickClose
        maxWidth="30em"
        noHeader
      >
        <DeleteConfirmMsg>
          <h1>
            {props.message ? t(props.message) : t("msp.deleteConfirmationMsg")}
          </h1>
          <ButtonWrapper>
            <YesBtn sm onClick={handleDelete}>
              {t("msp.yes")}
            </YesBtn>
            <NoBtn sm onClick={() => setShowConfirmation(false)}>
              {t("msp.no")}
            </NoBtn>
          </ButtonWrapper>
        </DeleteConfirmMsg>
      </Modal>
      {props.component ? (
        React.cloneElement(props.component, {
          disabled: props.disabled,
          onClick: () => setShowConfirmation(true),
        })
      ) : (
        <PrimaryBtn
          sm
          disabled={props.disabled}
          onClick={() => setShowConfirmation(true)}
        >
          {t("msp.delete")}
        </PrimaryBtn>
      )}
    </>
  );
};

DeleteBtn.propTypes = {
  component: PropTypes.object,
  disabled: PropTypes.bool,
  message: PropTypes.any,
  onDelete: PropTypes.func,
};

export default DeleteBtn;
