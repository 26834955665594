import { call, all, takeLatest } from "redux-saga/effects";
import { INIT_SAMPLE_TEMPLATE_DOWNLOAD } from "./actionTypes";
import { getRequestDetails } from "config/requestHeaders";
import axios from "../../../config/axiosCall";
import { buildNotification } from "config/notification";
import { store } from "react-notifications-component";

export default function* watchCreateInvoiceSagas() {
  yield all([
    takeLatest(INIT_SAMPLE_TEMPLATE_DOWNLOAD, initDownloadSampleDocSaga),
  ]);
}

function* initDownloadSampleDocSaga(action) {
  try {
    let url = "";
    let name = "";
    const { isPO, fileType } = action.payload;
    const extension = fileType || "xlsx";
    if (isPO) {
      url = "/EInvoice/POFileMetadataDownload";
      name = `SampleFilePOMetadata.${extension}`;
    } else {
      url = "/EInvoice/NONPOFileMetadataDownload";
      name = `SampleFileNonPOMetadata.${extension}`;
    }
    url += `?extension=${extension}`;
    const requestDetails = { ...getRequestDetails };
    requestDetails.responseType = "blob";
    if (url) {
      const response = yield call(axios, url, requestDetails);
      if (response && response.status !== 204) {
        const data = response.data;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        const notification = buildNotification({
          message: "msp.sampleDocDownloadedSuccess",
          type: "success",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 5000,
          },
        });
      }
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.noResponseFromServer",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 5000,
      },
    });
  }
}
