import { ErrorText } from "components/index";
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";

import { customStyles, SelectLabel, SelectPlaceholder } from "./style";

const Control = (provided) => {
  return (
    <>
      <SelectLabel
        tabIndex="-1"
        isFocused={provided.isFocused}
        isFloating={provided.isFocused || provided.hasValue}
        required={provided.selectProps.required}
      >
        {provided.selectProps.placeholder}
      </SelectLabel>
      <components.Control {...provided}>{provided.children}</components.Control>
    </>
  );
};

const Placeholder = (provided) => {
  return (
    <components.Placeholder {...provided}>
      <SelectPlaceholder required={provided.selectProps.required}>
        {provided.selectProps.placeholder}
      </SelectPlaceholder>
    </components.Placeholder>
  );
};

const FloatingSelect = (props) => {
  const { t } = useTranslation();
  const [visited, setVisited] = useState(false);
  useEffect(() => {
    props.resetVisited && setVisited(false);
  }, [props.resetVisited]);
  const options = props.options
    ? props.options.map((option) => ({
        label: option.value,
        value: option.id,
      }))
    : [];

  const stringVal = props.value ? props.value.toString() : "";
  const value = options.find(
    (option) => option && option.value && option.value.toString() === stringVal
  );

  const handleOnChange = (selectedOption) => {
    let value = selectedOption ? selectedOption.value.toString() : "";
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    value = isNaN(Number(strippedHtml)) ? strippedHtml : Number(strippedHtml);

    props.onChangeHandler &&
      props.onChangeHandler({
        type: "change",
        target: {
          value: selectedOption ? strippedHtml : "",
          id: props.id,
          name: props.name,
          text: selectedOption ? selectedOption.label : null,
          options: options,
          selectedIndex: options.findIndex((option) =>
            option.value === selectedOption ? strippedHtml : false
          ),
          required: props.required,
          inputType: "select",
        },
      });
  };

  const handleOnBlur = (selectedOption) => {
    setVisited(true);
    let value = selectedOption.target.value.toString();
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    value = isNaN(Number(strippedHtml)) ? strippedHtml : Number(strippedHtml);

    props.onBlurHandler &&
      props.onBlurHandler({
        type: "blur",
        target: {
          value,
          id: props.id,
          name: props.name,
          text: selectedOption.label,
          options: options,
          selectedIndex: options.findIndex((option) => option.value === value),
          inputType: "select",
        },
      });
  };
  const customComponents = { Control, Placeholder };

  return (
    <>
      <Select
        visited={visited}
        components={customComponents}
        isClearable
        onInputChange={(value) =>
          props.onSelectInputType &&
          props.onSelectInputType({
            target: {
              value,
              name: props.name,
              id: props.id,
            },
          })
        }
        isDisabled={props.disabled || false}
        required={props.required}
        isLoading={props.isLoading}
        value={value || ""}
        placeholder={props.placeholder}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        options={options}
        customStyle={props.customStyle}
        styles={{
          ...customStyles,
          menuPortal: (base) => ({ ...base, ...props.customStyle }),
        }}
        showError={props.showError}
        menuPlacement="auto"
        menuPortalTarget={props.inTable ? document.body : false}
        noOptionsMessage={() => t("msp.noOptions")}
        openMenuOnFocus
      />
      <ErrorText>{props.showError ? props.error : null}</ErrorText>
    </>
  );
};

export default FloatingSelect;
