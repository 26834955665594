import { PrimaryBtn, Label } from "components/index";
import styled from "styled-components";
export const LookupItemsWrapper = styled.ul``;
export const LookupItem = styled.li``;
export const RemainingDataCount = styled.li``;

const LookupContainer = styled.div`
  width: 100%;
  padding: 1em;
  outline: 0;
  border: 0.0625em solid hsl(0, 0%, 87%);
  border-radius: 0.5em;
  background: var(--light-clr);
  font-size: var(--fs-12);
  color: var(--grey-clr);
  font-family: var(--ff-medium);
  font-weight: var(--fw-normal);

  ${LookupItemsWrapper} {
    margin-block: 1em;
    width: 100%;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    max-height: 10rem;
    overflow: auto;
    padding-right: 0.5rem;
    ${LookupItem} {
      display: flex;
      gap: 0.5rem;
      padding: 0.5rem 0.75rem;
      border: 0.0625rem solid var(--primary-text-clr);
      border-radius: 1.5rem;
      background: var(--light-grey-clr);
      align-items: center;
      cursor: pointer;
      :hover,
      :focus {
        background: var(--offwhite-clr);
      }
      button {
        color: inherit;
        display: flex;
        gap: 1rem;
        align-items: center;
        :hover,
        :focus-visible {
          transform: scale(1.2);
          color: var(--primary-clr);
        }
      }
      svg {
        width: 1em;
        height: 1em;
      }
    }

    ${RemainingDataCount} {
      margin: auto 0;
      font-size: var(--fs-14);
    }
  }
`;

export const LookupTitle = styled(Label)`
  &:before {
    content: none;
  }
  &:after {
    color: ${(props) => (props.required ? "var(--error-clr)" : null)};
    content: "${(props) => (props.required ? " *" : null)}";
  }
`;

export const LookupButton = styled(PrimaryBtn)`
  margin: 0;
  span {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    svg {
      transform: rotate(-90deg);
      width: 1em;
      height: 1em;
    }
  }
`;
export default LookupContainer;
