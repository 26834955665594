import { put, takeEvery, call, all } from "redux-saga/effects";
import { store } from "react-notifications-component";
import { buildNotification } from "config/notification";

import axios from "config/axiosCall";
import { postRequestDetails } from "config/requestHeaders";

import {
  updatePasswordOnLoginSuccess,
  updatePasswordOnLoginFailed,
} from "./actions";
import { INIT_UPDATE_PASSWORD_ON_LOGIN } from "./actionTypes";

export default function* watchChangePasswordSaga() {
  yield all([
    takeEvery(INIT_UPDATE_PASSWORD_ON_LOGIN, initUpdatePasswordOnLogin),
  ]);
}

function* initUpdatePasswordOnLogin(action) {
  const url = "/Login/UpdatePasswordOnLogin";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = JSON.stringify(action.payload));
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(updatePasswordOnLoginSuccess());
      const notification = buildNotification({
        message: "msp.pwdChangeSuccess",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
      action.callBackFunction(response);
    } else {
      yield put(updatePasswordOnLoginFailed());
      action.callBackFunction("error");
    }
  } catch (error) {
    yield put(updatePasswordOnLoginFailed());
    action.callBackFunction("error");
  }
}
