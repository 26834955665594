import React from "react";
import SvgIcon from "./style";
const Cross = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-1192.000000, -247.000000)">
          <g transform="translate(119.000000, 56.000000)">
            <g transform="translate(1.000000, 168.000000)">
              <g transform="translate(1069.000000, 20.000000)">
                <path d="M11.3257813,10 L15.3320313,5.99374999 L16.1582031,5.16757811 C16.2800781,5.04570311 16.2800781,4.84765624 16.1582031,4.72578124 L15.2742188,3.84179686 C15.1523438,3.71992186 14.9542969,3.71992186 14.8324219,3.84179686 L10,8.67421875 L5.16757811,3.84140623 C5.04570311,3.71953123 4.84765624,3.71953123 4.72578124,3.84140623 L3.84140623,4.72539061 C3.71953123,4.84726561 3.71953123,5.04531249 3.84140623,5.16718749 L8.67421875,10 L3.84140623,14.8324219 C3.71953123,14.9542969 3.71953123,15.1523438 3.84140623,15.2742188 L4.72539061,16.1582031 C4.84726561,16.2800781 5.04531249,16.2800781 5.16718749,16.1582031 L10,11.3257813 L14.00625,15.3320313 L14.8324219,16.1582031 C14.9542969,16.2800781 15.1523438,16.2800781 15.2742188,16.1582031 L16.1582031,15.2742188 C16.2800781,15.1523438 16.2800781,14.9542969 16.1582031,14.8324219 L11.3257813,10 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Cross;
