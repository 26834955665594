import { put, takeEvery, call, all, take, fork } from "redux-saga/effects";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import moment from "moment";
import { createUploader } from "../../../helper/fileUploadWatcher";
import {
  reconciliationListFetchComplete,
  supplierListFetchComplete,
  saveReconciliationComplete,
  startReconciliationComplete,
  reconciliationListRequest,
  reconciledReportsFetchComplete,
  reconciliationCommentsFetchComplete,
  saveReconciliationCommentsComplete,
  suggestedInvoicesFetchComplete,
  setUploadingProgress,
  uploadingStart,
  uploadingFinish,
  uploadingReset,
} from "./actions";
import {
  RECONCILIATION_LIST_REQUEST,
  SUPPLIER_LIST_REQUEST,
  INIT_DOWNLOAD_TEMPLATE,
  SAVE_RECONCILIATION_REQUEST,
  DOWNLOAD_INPUT_FILE_ACTION,
  START_RECONCILIATION_REQUEST,
  GET_RECONCILED_REPORTS_REQUEST,
  GET_RECONCILIATION_COMMENTS_REQUEST,
  SAVE_RECONCILIATION_COMMENTS_REQUEST,
  GET_SUGGESTED_INVOICES_REQUEST,
  INIT_UPDATE_RECONCILED_DATA,
} from "./actionTypes";
import axios from "../../../config/axiosCall";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";

export default function* watchReconciliation() {
  yield all([
    takeEvery(RECONCILIATION_LIST_REQUEST, getReconciliationList),
    takeEvery(SUPPLIER_LIST_REQUEST, getSupplierList),
    takeEvery(INIT_DOWNLOAD_TEMPLATE, downloadTemplateSaga),
    takeEvery(SAVE_RECONCILIATION_REQUEST, saveReconciliation),
    takeEvery(DOWNLOAD_INPUT_FILE_ACTION, downloadInputFile),
    takeEvery(START_RECONCILIATION_REQUEST, startReconciliation),
    takeEvery(GET_RECONCILED_REPORTS_REQUEST, getReconciledReports),
    takeEvery(GET_RECONCILIATION_COMMENTS_REQUEST, getReconciliationComments),
    takeEvery(SAVE_RECONCILIATION_COMMENTS_REQUEST, saveReconciliationComments),
    takeEvery(GET_SUGGESTED_INVOICES_REQUEST, getSuggestedInvoices),
    takeEvery(INIT_UPDATE_RECONCILED_DATA, updateReconciledDataSaga),
  ]);
}

function* getReconciliationList(action) {
  let url = "/Reconciliation/ListUploadedData";
  let updatedData = [];
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.data);
  try {
    const response = yield call(axios, url, requestDetails);
    updatedData = response.data || [];
    yield put(reconciliationListFetchComplete(updatedData));
  } catch (error) {
    yield put(reconciliationListFetchComplete());
  }
}

function* getSupplierList(action) {
  const { payload } = action || {};
  let url = `/Reconciliation/GetSupplierData?`;
  payload &&
    Object.keys(payload).forEach((key) => (url += `${key}=${payload[key]}&`));
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      const { supplierData, totalCount: totalSupplierCount } =
        response.data || {
          supplierData: [],
          totalCount: 0,
        };
      yield put(supplierListFetchComplete(supplierData, totalSupplierCount));
    }
  } catch (error) {
    yield put(supplierListFetchComplete());
  }
}

function* downloadTemplateSaga() {
  const url = "/Reconciliation/DownloadTemplate";
  try {
    const response = yield call(axios, url, { responseType: "blob" });
    if (response && (response.status === 200 || response.status === 202)) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Sample.xlsx");
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "msp.templateDownlodedMsg",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* saveReconciliation(action) {
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.payload);
  const url = "/Reconciliation/save";
  const [uploadPromise, chan] = yield createUploader({
    payload: action.payload,
    url: url,
    method: "post",
  });
  const progression = {
    sent: 0,
    uploaded: false,
  };
  yield put(uploadingStart(progression));
  yield fork(watchOnProgress, { chan, progression });
  try {
    const response = yield call(() => uploadPromise, axios, requestDetails);
    yield (progression.sent = 100);
    yield (progression.uploaded = true);
    yield put(uploadingFinish(progression));
    yield put(uploadingReset(progression));

    if (response && response.status === 200) {
      yield put(saveReconciliationComplete("success"));
      const notification = buildNotification({
        message: "msp.uploadedSuccessfully",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      if (action.callback) {
        action.callback();
      }
    } else {
      yield put(saveReconciliationComplete("error"));
    }
  } catch (error) {
    yield put(saveReconciliationComplete("error"));
    const notification = buildNotification({
      message: "msp.errorOccuredMessage",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
    if (action.callback) {
      action.callback();
    }
  }
}

function* downloadInputFile(action) {
  const url = `/Reconciliation/DownloadInputFile?reconcileId=${action.reconcileId}`;
  try {
    const response = yield call(axios, url, { responseType: "blob" });
    if (response && (response.status === 200 || response.status === 202)) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", action.fileName);
      document.body.appendChild(link);
      link.click();
      const notification = buildNotification({
        message: "msp.templateDownlodedMsg",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.templatedDownloadFailed",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* startReconciliation(action) {
  const url = `/Reconciliation/StartReconciliation?reconcileId=${action.param}`;
  const requestDetails = { ...postRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);

    if (response && response.status === 200) {
      yield put(reconciliationListRequest(action.data));
      yield put(startReconciliationComplete("success"));
    }
  } catch (error) {
    yield put(startReconciliationComplete("error"));
  }
}

function* getReconciledReports(action) {
  const url = `Reconciliation/ViewReconciledReports?reconcileId=${action.reconcileId}`;
  let updatedData = {};
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    updatedData = response.data || [];
    yield put(reconciledReportsFetchComplete(updatedData));
  } catch (error) {
    yield put(reconciledReportsFetchComplete());
  }
}

function* getReconciliationComments(action) {
  const url = `Reconciliation/GetReconciliationComments?reconcileId=${action.reconcileId}`;
  let updatedData = [];
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    updatedData = yield response.data.map((item) => {
      return {
        ...item,
        time: moment(item.createdDate).format("hh:mm:ss a"),
      };
    });
    yield put(reconciliationCommentsFetchComplete(updatedData));
  } catch (error) {
    yield put(reconciliationCommentsFetchComplete());
  }
}

function* saveReconciliationComments(action) {
  const url = "Reconciliation/SaveReconciliationComments";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.payload);
  try {
    const response = yield call(axios, url, requestDetails);

    if (response && response.status === 202) {
      yield put(saveReconciliationCommentsComplete("success"));
      const notification = buildNotification({
        message: "msp.commentSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    yield put(saveReconciliationCommentsComplete("error"));
    const notification = buildNotification({
      message: "msp.errorOccuredMessage",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}
function* getSuggestedInvoices(action) {
  const url = `Reconciliation/GetMatchingInvoices?reconcileId=${action.param.reconcileId}&ID=${action.param.id}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(suggestedInvoicesFetchComplete(response.data));
  } catch (error) {
    yield put(suggestedInvoicesFetchComplete());
  }
}
function* watchOnProgress({ chan, progression }) {
  while (true) {
    const data = yield take(chan);
    const { loaded, total } = data;
    const progress = (loaded * 100) / total;
    progression.sent = progress > 90 ? 90 : progress;
    yield put(setUploadingProgress(progression));
  }
}

function* updateReconciledDataSaga(action) {
  const url = "/Reconciliation/UpdateReconciledData";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.data);
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(reconciliationListRequest());
    }
  } catch (err) {
    console.log(err);
  }
}
