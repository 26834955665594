import {
  APPLY_SUBMISISON_HISTORY_FILTER,
  CLEAR_FILTER_PARAMS,
  CLEAR_SUBMISSION_HISTORY,
  FECTHING_RESOLVED_INVOICE,
  FECTHING_SUBMISSION_HISTORY,
  INIT_FECTH_RESOLVED_INVOICE,
  INIT_FECTH_SUBMISSION_HISTORY,
  RESOLVED_INVOICE_FETCHED,
  RESOLVED_INVOICE_FETCH_FAILED,
  SET_DATA_GETTER_PARAM,
  SET_FILTER_PARAMS,
  SET_SKIP,
  SET_SSR,
  SET_TAB,
  SHOW_DOWNLOAD_POPUP,
  SUBMISSION_HISTORY_FETCHED,
  SUBMISSION_HISTORY_FETCH_FAILED,
  TOGGLE_INVOICE_COPY_CHECK,
  TOGGLE_SUPPORTING_DOC_CHECK,
} from "./actionTypes";

export const initFecthSubmissionHistory = (payload) => ({
  type: INIT_FECTH_SUBMISSION_HISTORY,
  payload,
});

export const fetchingSubmissionHistory = () => ({
  type: FECTHING_SUBMISSION_HISTORY,
});

export const submissionHistoryFetched = (data) => ({
  type: SUBMISSION_HISTORY_FETCHED,
  data,
});
export const submissionHistoryFailed = () => ({
  type: SUBMISSION_HISTORY_FETCH_FAILED,
});

export const setDataGetter = (data) => ({
  type: SET_DATA_GETTER_PARAM,
  data,
});
export const setSSR = (data) => ({
  type: SET_SSR,
  data,
});

export const setFilterParams = (data) => ({
  type: SET_FILTER_PARAMS,
  data,
});

export const clearFilterParams = () => ({
  type: CLEAR_FILTER_PARAMS,
});
export const setSkip = (data) => ({
  type: SET_SKIP,
  data,
});

export const applySubmissionHistoryFilter = (payload) => ({
  type: APPLY_SUBMISISON_HISTORY_FILTER,
  payload,
});
export const setTab = (data) => ({
  type: SET_TAB,
  data,
});
export const setDownloadPopup = (data) => ({
  type: SHOW_DOWNLOAD_POPUP,
  data,
});
export const toggleInvoiceCopyCheck = (data) => ({
  type: TOGGLE_INVOICE_COPY_CHECK,
  data,
});
export const toggleSupportingCheck = (data) => ({
  type: TOGGLE_SUPPORTING_DOC_CHECK,
  data,
});

export const initFecthRejectedInvoice = (payload) => ({
  type: INIT_FECTH_RESOLVED_INVOICE,
  payload,
});

export const fetchingRejectedInvoice = () => ({
  type: FECTHING_RESOLVED_INVOICE,
});

export const rejectedInvoiceFetched = (data) => ({
  type: RESOLVED_INVOICE_FETCHED,
  data,
});
export const rejectedInvoiceFailed = () => ({
  type: RESOLVED_INVOICE_FETCH_FAILED,
});
export const clearSubmissionHistory = () => ({
  type: CLEAR_SUBMISSION_HISTORY,
});
