import React, { useEffect, useState } from "react";
import HeaderStyled, { ContainerHeader, HeaderSeparator,} from "./style";
import { ContentButton } from "./style";
import { initLogout } from "container/Authentication/store/actions";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { compose } from "redux";

import Logo from "./Logo/index";
import Nav from "./Nav/index";
import SiteActions from "./SiteActions/index";
import UserProfile from "./UserProfile/index";
import {
  selectIsGlobalSearchActive,
  selectSelectedCurrency,
} from "store/selectors";

import { initSetSelectedCurrency, initSetDefaultCurrency } from "store/actions";
import { selectSessionDetails } from "container/Authentication/store/selectors";
import { useTranslation } from "react-i18next";

const Header = (props) => {
  // console.log(props);
  const { t } = useTranslation();
  const { logo } = props.clientDetails || {};
  const { fileData } = logo || {};
  const [searchOpened, setSearchOpened] = useState(false);
  const [currencyListOpened, setCurrencyListOpened] = useState(false);
  const [notificationOpened, setNotificationOpened] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [siteActionVisited, setSiteActionVisited] = useState(false);
  const [menuSelected, setMenuSelected] = useState(false);
  const { getCurrencies, currency } = props.sessionDetails || {
    getCurrencies: [],
  };
  const handleChatBotClick = () => {
    const showChatBot =
      props.sessionDetails && props.sessionDetails.baseRoleId
        ? props.sessionDetails.baseRoleId === 4
        : false;

    if (showChatBot) {
      const chatbot = document.getElementById("nanorep1");

      if (chatbot) {
        chatbot.style.cssText += ";display:block !important;";
        window.globalNanoRep && window.globalNanoRep.expand();
      }
    }
  };
  const handleDropdownCLick = () =>{
    setMenuToggle(!menuToggle);
    setMenuSelected(true);
  }
  
  useEffect(() => {
    if (props.isGlobalSearchActive) {
      setSearchOpened(false);
      setMenuToggle(false);
      siteActionVisited && setSiteActionVisited(false);
    }
  }, [props.isGlobalSearchActive, siteActionVisited]);

  return (
    <HeaderStyled>
      <ContainerHeader hasBanner={props.hasBanner}>
        <Logo logo={fileData} />
        <ContentButton
          onKeyUp={props.handleSkipToMain}
          onClick={props.handleSkipToMain}
          aria-label="mainContent"
        >
          {t("msp.skipToMainContent")}
        </ContentButton>
        <HeaderSeparator />

        <Nav
          searchOpened={searchOpened}
          siteActionVisited={siteActionVisited}
          menuToggle={menuToggle}
          setMenuToggle={() => {
            setMenuToggle(!menuToggle);
          }}
          handleDropdownCLick={handleDropdownCLick}
          menuSelected = {menuSelected}
          setMenuSelected = {setMenuSelected}
          onKeyPress={(e) => {
            if (e.key === 'Enter'){ 
              setMenuToggle(!menuToggle);
            }
          }}
          hideMenu={() => {
            setMenuToggle(false);
            siteActionVisited && setSiteActionVisited(false);
          }}
        />
        <SiteActions
          searchOpened={searchOpened}
          togglSearchBar={() => setSearchOpened(!searchOpened)}
          closeSearch={() => setSearchOpened(false)}
          currencyDropdownOpened={currencyListOpened}
          toggleCurrDropdown={() => setCurrencyListOpened(!currencyListOpened)}
          closeCurrencyDropdown={() => setCurrencyListOpened(false)}
          selectedCurrency={props.selectedCurrency}
          sessionDetails={props.sessionDetails}
          currencyList={getCurrencies}
          defaultCurrency={currency}
          setSelectedCurrency={props.setSelectedCurrency}
          notificationOpened={notificationOpened}
          closeNotification={() => setNotificationOpened(false)}
          toggleNotification={() => setNotificationOpened(!notificationOpened)}
          setSiteActionVisited={() => setSiteActionVisited(true)}
          handleChatBot={handleChatBotClick}
          showChatBot={
            props.sessionDetails && props.sessionDetails.baseRoleId === 4
          }
          invoicePaymentData={props.invoicePaymentData}
        />
        <UserProfile />
      </ContainerHeader>
    </HeaderStyled>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedCurrency: selectSelectedCurrency(),
  sessionDetails: selectSessionDetails(),
  isGlobalSearchActive: selectIsGlobalSearchActive(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCurrency: (data) => {
      dispatch(initSetSelectedCurrency(data));
    },
    setDefaultCurrency: (currency) => {
      // should've done in 1 dispatch with a flag to set default
      dispatch(
        initSetSelectedCurrency({
          id: currency.currencyID,
          key: currency.currency,
        })
      );
      dispatch(initSetDefaultCurrency(currency));
    },
    logout: (callback) => {
      dispatch(initLogout(callback));
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Header);
