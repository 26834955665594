import {
  Modal,
  FormControl,
  ButtonWrapper,
  Button,
  PrimaryBtn,
} from "components/index";
import React, { useState } from "react";
import { Plus } from "components/SVG";
import LanguageList from "./LanguageList";
import { AddMutationBtn, MutationControls, MutationForm } from "./style";
import { createStructuredSelector } from "reselect";
import { selectLanguageFetchStatus, selectLanguages } from "store/selectors";

import { connect } from "react-redux";
import { compose } from "redux";
import { useTranslation } from "react-i18next";

const LanguageMutation = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [editLangCode, setEditLangCode] = useState("");
  const initMutationEdit = (langID) => {
    props.onMutationEdit(langID);
    setShow(true);
    setEditLangCode(langID);
  };
  const onClosePopup = () => {
    setShow(false);
    setEditLangCode("");
    props.onClose && props.onClose();
  };
  const { languageList } = props || {};

  const options = languageList
    ? languageList
        .filter((lang) => {
          const alreadySaved = props.savedLangList.find(
            (savedLang) =>
              savedLang.langCode === lang.languageID &&
              editLangCode !== lang.languageID
          );
          return alreadySaved ? false : true;
        })
        .map((lang) => {
          return {
            id: lang.languageID,
            value: lang.label + " / " + lang.name,
          };
        })
    : [];

  return (
    <>
      <Modal
        centered
        scrollable
        maxWidth="32em"
        show={show}
        onClose={onClosePopup}
        title={editLangCode ? t("msp.editMutation") : t("msp.addMutation")}
        preventOutterClickClose
      >
        <MutationForm>
          <MutationControls>
            <FormControl
              inputType="floatingSelect"
              attrs={{
                options,
                id: "language",
                name: "language",
                placeholder: t("msp.languages"),
                required: true,
                customStyle: { zIndex: 1000 },
                disabled: !!props.disableLangDropdown,
              }}
              onBlurHandler={props.handleFieldVisited}
              value={props.selectedLang}
              onChangeHandler={props.onMutationValueChange}
              error={props.error ? props.error["language"] : false}
              showError={!!(props.visited && props.visited["language"] && props.error && props.error["language"])}
              inTable={true}
            />
            {props.controls}
          </MutationControls>
          <ButtonWrapper>
            <Button sm onClick={onClosePopup}>
              {t("msp.cancel")}
            </Button>
            <PrimaryBtn
              sm
              onClick={() => {
                props.onSave();
                onClosePopup(editLangCode);
              }}
              disabled={props.isInvalid}
            >
              {t("msp.save")}
            </PrimaryBtn>
          </ButtonWrapper>
        </MutationForm>
      </Modal>
      <AddMutationBtn sm onClick={() => setShow(true)}>
        <span>
          <Plus /> {t("msp.addMutation")}
        </span>
      </AddMutationBtn>
      <LanguageList
        onMutationEdit={initMutationEdit}
        onMutationDelete={props.onMutationDelete}
        list={props.savedLangList}
        downloadFile={props.downloadFile}
      />
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  languageFetchStatus: selectLanguageFetchStatus(),
  languageList: selectLanguages(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(LanguageMutation);
