export const INIT_MODULE_LIST_FETCH = "INIT_MODULE_LIST_FETCH";
export const FETCHING_MODULE_LIST = "FETCHING_MODULE_LIST";
export const FETCH_MODULE_LIST = "FETCH_MODULE_LIST";
export const MODULE_LIST_FETCH_COMPLETE = "MODULE_LIST_FETCH_COMPLETE";

export const INIT_ATTRIBUTE_TYPE_LIST_FETCH = "INIT_ATTRIBUTE_TYPE_LIST_FETCH";
export const FETCHING_ATTRIBUTE_TYPE_LIST = "FETCHING_ATTRIBUTE_TYPE_LIST";
export const FETCH_ATTRIBUTE_TYPE_LIST = "FETCH_ATTRIBUTE_TYPE_LIST";
export const ATTRIBUTE_TYPE_LIST_FETCH_COMPLETE = "ATTRIBUTE_TYPE_LIST_FETCH_COMPLETE";

export const INIT_ATTRIBUTES_LIST_FETCH = "INIT_ATTRIBUTES_LIST_FETCH";
export const FETCHING_ATTRIBUTES_LIST = "FETCHING_ATTRIBUTES_LIST";
export const FETCH_ATTRIBUTES_LIST = "FETCH_ATTRIBUTES_LIST";
export const ATTRIBUTES_LIST_FETCH_COMPLETE = "ATTRIBUTES_LIST_FETCH_COMPLETE";
export const UPDATED_ATTRIBUTES_LIST = "UPDATED_ATTRIBUTES_LIST";

export const INIT_GET_CUSTOM_LIST = "INIT_GET_CUSTOM_LIST";
export const FETCHING_CUSTOM_LIST = "FETCHING_CUSTOM_LIST";
export const FETCH_CUSTOM_LIST = "FETCH_CUSTOM_LIST";
export const CUSTOM_LIST_FETCH_COMPLETE = "CUSTOM_LIST_FETCH_COMPLETE";

export const INIT_GET_VMD_TAB_DATA = "INIT_GET_VMD_TAB_DATA";
export const FETCHING_VMD_TAB_DATA = "FETCHING_VMD_TAB_DATA";
export const FETCH_VMD_TAB_DATA = "FETCH_VMD_TAB_DATA";
export const VMD_TAB_DATA_FETCH_COMPLETE = "VMD_TAB_DATA_FETCH_COMPLETE";

export const INIT_GET_VMD_SECTION_DATA = "INIT_GET_VMD_SECTION_DATA";
export const FETCHING_VMD_SECTION_DATA = "FETCHING_VMD_SECTION_DATA";
export const FETCH_VMD_SECTION_DATA = "FETCH_VMD_SECTION_DATA";
export const VMD_SECTION_DATA_FETCH_COMPLETE = "VMD_SECTION_DATA_FETCH_COMPLETE";

export const INIT_GET_CREATE_COMPANY_SECTION_DATA = "INIT_GET_CREATE_COMPANY_SECTION_DATA";
export const FETCHING_CREATE_COMPANY_SECTION_DATA = "FETCHING_CREATE_COMPANY_SECTION_DATA";
export const FETCH_CREATE_COMPANY_SECTION_DATA = "FETCH_CREATE_COMPANY_SECTION_DATA";
export const CREATE_COMPANY_SECTION_DATA_FETCH_COMPLETE = "CREATE_COMPANY_SECTION_DATA_FETCH_COMPLETE";

export const INIT_GET_SUBMISSION_FIELD_PLACEMENT = "INIT_GET_SUBMISSION_FIELD_PLACEMENT";
export const FETCHING_SUBMISSION_FIELD_PLACEMENT = "FETCHING_SUBMISSION_FIELD_PLACEMENT";
export const FETCH_SUBMISSION_FIELD_PLACEMENT = "FETCH_SUBMISSION_FIELD_PLACEMENT";
export const SUBMISSION_FIELD_PLACEMENT_FETCH_COMPLETE = "SUBMISSION_FIELD_PLACEMENT_FETCH_COMPLETE";

export const INIT_GET_SEARCH_FIELD_PLACEMENT = "INIT_GET_SEARCH_FIELD_PLACEMENT";
export const FETCHING_SEARCH_FIELD_PLACEMENT = "FETCHING_SEARCH_FIELD_PLACEMENT";
export const FETCH_SEARCH_FIELD_PLACEMENT = "FETCH_SEARCH_FIELD_PLACEMENT";
export const SEARCH_FIELD_PLACEMENT_FETCH_COMPLETE = "SEARCH_FIELD_PLACEMENT_FETCH_COMPLETE";

export const INIT_GET_REGEX_LIST = "INIT_GET_REGEX_LIST";
export const FETCHING_REGEX_LIST = "FETCHING_REGEX_LIST";
export const FETCH_REGEX_LIST = "FETCH_REGEX_LIST";
export const REGEX_LIST_FETCH_COMPLETE = "REGEX_LIST_FETCH_COMPLETE";

export const INIT_GET_APPROVAL_TYPE_LIST = "INIT_GET_APPROVAL_TYPE_LIST";
export const FETCHING_APPROVAL_TYPE_LIST = "FETCHING_APPROVAL_TYPE_LIST";
export const FETCH_APPROVAL_TYPE_LIST = "FETCH_APPROVAL_TYPE_LIST";
export const APPROVAL_TYPE_LIST_FETCH_COMPLETE = "APPROVAL_TYPE_LIST_FETCH_COMPLETE";

export const INIT_GET_VALIDATION_TYPE = "INIT_GET_VALIDATION_TYPE";
export const FETCHING_VALIDATION_TYPE = "FETCHING_VALIDATION_TYPE";
export const FETCH_VALIDATION_TYPE = "FETCH_VALIDATION_TYPE";
export const VALIDATION_TYPE_FETCH_COMPLETE = "VALIDATION_TYPE_FETCH_COMPLETE";

export const INIT_ATTRIBUTES_DELETE = "INIT_ATTRIBUTES_DELETE";
export const ATTRIBUTES_DELETE_SUCCESS = "ATTRIBUTES_DELETE_SUCCESS";
export const ATTRIBUTES_DELETE_FAILED = "ATTRIBUTES_DELETE_FAILED";

export const INIT_ATTRIBUTE_EDIT = "INIT_ATTRIBUTE_EDIT";
export const SET_EDIT_ATTRIBUTE_DATA = "SET_EDIT_ATTRIBUTE_DATA";
export const SET_EDIT_ATTRIBUTE_DATA_COMPLETED = "SET_EDIT_ATTRIBUTE_DATA_COMPLETED";

export const INIT_ATTRIBUTE_SAVE = "INIT_ATTRIBUTE_SAVE";
export const ATTRIBUTE_SAVED = "ATTRIBUTE_SAVED";
export const ATTRIBUTE_SAVE_FAILED = "ATTRIBUTE_SAVE_FAILED";
export const ATTRIBUTE_FORM_SUBMISSION_STATUS = "ATTRIBUTE_FORM_SUBMISSION_STATUS";
export const RESET_ATTRIBUTE_SAVE_STATUS = "RESET_ATTRIBUTE_SAVE_STATUS";