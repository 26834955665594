import {
  GET_NOTIFICATION_DATA,
  FETCH_NOTIFICATION_DATA,
  INIT_GET_LANDING_PAGE_DATA,
  GET_LANDING_PAGE_DATA_COMPLETED,
  INIT_GET_OVERDUE_INVOICES,
  GET_OVERDUE_INVOICES_COMPLETED,
  INIT_GET_FORCAST_INVOICES,
  GET_FORCAST_INVOICES_COMPLETED,
  INIT_GET_INVOICE_HISTORY_BY_ID,
  GET_INVOICE_HISTORY_BY_ID_COMPLETED,
  GET_OVERDUE_INVOICES_FETCH_FAILED,
  GET_FORCAST_INVOICES_FETCH_FAILED,
  GET_INVOICE_HISTORY_BY_ID_FETCH_FAILED,
  GET_LANDING_PAGE_DATA_FAILED,
  INIT_GET_REJECTED_INVOICES,
  FETCHING_REJECTED_INVOICES,
  FETCH_REJECTED_INVOICES,
  FETCH_REJECTED_INVOICES_COMPLETED,
  FETCHING_REJECTED_INVOICES_FAILED,
  SET_SELECTED_DAY_INDEX,
  // INIT_RESOLVE_REJECTED_INVOICES,
  // REJECTED_INVOICES_RESOLVING,
  // REJECTED_INVOICES_RESOLVED,
  // REJECTED_INVOICES_RESOLVING_FAILED,
  IS_HEADER_IMG_CLICKED,
} from "./actionTypes";

export const setSelectedDayIndex = (index) => ({
  type: SET_SELECTED_DAY_INDEX,
  payload: index,
});

export const initGetNotification = () => {
  return {
    type: GET_NOTIFICATION_DATA,
  };
};
export const fetchNotificationData = (data) => {
  return {
    type: FETCH_NOTIFICATION_DATA,
    data: data,
  };
};
export const initGetRejectedInvoices = (currencyID) => {
  return {
    type: INIT_GET_REJECTED_INVOICES,
    currencyID,
  };
};
export const fetchingRejectedInvoices = () => {
  return {
    type: FETCHING_REJECTED_INVOICES,
  };
};

export const fetchRejectedInvoices = (data) => {
  return {
    type: FETCH_REJECTED_INVOICES,
    data,
  };
};
export const fetchRejectedInvoicesCompleted = () => {
  return {
    type: FETCH_REJECTED_INVOICES_COMPLETED,
  };
};
export const fetchingRejectedInvoicesFailed = (data) => {
  return {
    type: FETCHING_REJECTED_INVOICES_FAILED,
    data,
  };
};
export const initLandingPageData = (noOfDays, currencyID) => {
  return {
    type: INIT_GET_LANDING_PAGE_DATA,
    noOfDays,
    currencyID,
  };
};
export const getLandingPageDataFailed = (data) => {
  return {
    type: GET_LANDING_PAGE_DATA_FAILED,
    data,
  };
};
export const getLandingPageDataCompleted = (data) => {
  return {
    type: GET_LANDING_PAGE_DATA_COMPLETED,
    data,
  };
};
export const initOverduePaymentsData = (currencyID) => {
  return {
    type: INIT_GET_OVERDUE_INVOICES,
    currencyID,
  };
};
export const getOverduePaymentsDataCompleted = (data) => {
  return {
    type: GET_OVERDUE_INVOICES_COMPLETED,
    data,
  };
};
export const getOverduePaymentsDataFetchFailed = (data) => {
  return {
    type: GET_OVERDUE_INVOICES_FETCH_FAILED,
    data,
  };
};
export const initForcastPaymentsData = (currencyID) => {
  return {
    type: INIT_GET_FORCAST_INVOICES,
    currencyID,
  };
};
export const getForcastPaymentsDataCompleted = (data) => {
  return {
    type: GET_FORCAST_INVOICES_COMPLETED,
    data,
  };
};
export const getForcastPaymentsDataFetchFailed = (data) => {
  return {
    type: GET_FORCAST_INVOICES_FETCH_FAILED,
    data,
  };
};
export const initGetInvoiceHistoryById = (invoiceId) => {
  return {
    type: INIT_GET_INVOICE_HISTORY_BY_ID,
    invoiceId,
  };
};
export const getInvoiceHistoryByIdCompleted = (data) => {
  return {
    type: GET_INVOICE_HISTORY_BY_ID_COMPLETED,
    data,
  };
};
export const getInvoiceHistoryByIdFetchFailed = (data) => {
  return {
    type: GET_INVOICE_HISTORY_BY_ID_FETCH_FAILED,
    data,
  };
};

// export const initResolveRejectedInvoices = (invoiceID,resolveSuccess,userId) => {
//   return {
//     type: INIT_RESOLVE_REJECTED_INVOICES,
//     invoiceID,
//     resolveSuccess,
//     userId
//   };
// };
// export const rejectedInvoicesResolving = () => {
//   return {
//     type: REJECTED_INVOICES_RESOLVING
//   };
// };

// export const rejectedInvoicesResolved = ()=>{
//   return {
//     type : REJECTED_INVOICES_RESOLVED
//   };
// };
// export const rejectedInvoicesResolvingFailed = (data)=>{
//   return {
//     type : REJECTED_INVOICES_RESOLVING_FAILED,
//     data
//   }
// }

export const isHeaderImgClicked = (data) => {
  return {
    type: IS_HEADER_IMG_CLICKED,
    data,
  };
};
