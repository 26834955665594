import { Cols, IconWrapper, Label } from "components/index";
import { dropdownContent } from "globalStyles";
import styled from "styled-components";
import { Dropdown } from "../style";
import {
  ProfileAction,
  ProfileOption,
  ProfileOptionItem,
} from "./UserProfileOptions/style";

export const CloseButton = styled.button``;
export const SubmitButton = styled.button``;
export const UserName = styled(Dropdown)``;
export const SubModuleTitle = styled(Label)`
  font-size: var(--fs-12);
  color: var(--dark-clr);
`;

export const ButtonWrapper = styled.div`
  margin-left: auto;
  text-align: end;

  button {
    vertical-align: middle;
  }
  ${CloseButton}, ${SubmitButton} {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--grey-clr);
    :hover,
    :focus {
      transform: scale(1.2);
      color: var(--primary-clr);
    }
    :disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }

    font-family: var(--ff-medium);
    font-size: var(--fs-12);
  }
  button + button {
    margin-left: 1em;
  }
`;

export const SuccessIcon = styled(IconWrapper)`
  color: var(--success-clr);
  span {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.25em;
      width: 1em;
    }
  }
`;
export const UserProfileOptionStyled = styled.ul`
  ${dropdownContent}
  background-color: #fff;
  border: 0.0625em solid #dfe3e8;
  border-radius: 0.5em;
  padding: 0.7em;
  min-width: 12.2rem;
  z-index: 5;
  right: 0;
  top: calc(100% + 0.2em);
  color: var(--dark-clr);
  font-size: var(--fs-12);
  font-family: var(--ff-medium);
  line-height: 1;
  align-items: flex-start;

  ${(props) => props.show && `transform: scale(1, 1);`}
  ${ProfileOption} {
    width: 100%;
    cursor: pointer;
    :hover,
    :focus {
      background-color: var(--dropdown-hover-clr);
      border-radius: 0.1875em;
    }
    ${ProfileOptionItem} {
      padding: 0.5rem 0;
      flex: 1;
      color: inherit;
      font-family: inherit;
      display: flex;
      gap: 1em;
      padding-left: 0.5em;
      align-items: center;

      span {
        order: 2;
      }
      ${ProfileAction} {
        order: 3;
        margin-left: auto;
        background-color: transparent;
        font-size: inherit;
        color: var(--primary-clr);
        border: none;
        display: none;
        :disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
        :not(:disabled) {
          :hover,
          :focus {
            font-weight: var(--fw-bold);
          }
        }
      }
      svg {
        order: 1;
        width: 1.2em;
      }
    }
  }
`;

const UserProfileStyled = styled.div`
  color: var(--light-clr);

  position: relative;
  display: flex;
  order: 4;
  padding-right: 0.5em;
  :focus,
  :focus-within::not(${UserName}) {
    ${UserProfileOptionStyled} {
      transform: scale(1, 1);
    }
  }
  ${Dropdown} {
    font-family: var(--ff-medium);
    color: inherit;
    gap: 0.5em;
    svg {
      transition: all 400ms ease-in-out;
      ${(props) => props.show && `transform: rotate(-180deg)`};
      order: 1;
      width: 1em;
      margin-top: 0.1em;
    }
  }
`;

export const ConfirmActionButtons = styled(Cols)`
  justify-content: center;
  margin-block: 1em;
  gap: 1em;
`;
export default UserProfileStyled;
