import React from "react";

import SwicthStyled, { HiddenCheckbox, Slider } from "./style";

const Switch = (props) => {
  const onToggle = (event) => {
    props.onChangeHandler({
      target: {
        checked: event.currentTarget.checked,
        id: props.id,
        name: props.name,
        value: props.value,
      },
    });
  };
  return (
    <SwicthStyled htmlFor={props.id} checked={props.checked}>
      <HiddenCheckbox {...props} onChange={onToggle} />
      <Slider></Slider>
    </SwicthStyled>
  );
};

export default Switch;
