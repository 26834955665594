export const INIT_USER_MANAGEMENT_LIST = "INIT_USER_MANAGEMENT_LIST";
export const USER_LIST = "USER_LIST";
export const USER_LIST_FETCH_STATUS = "USER_LIST_FETCH_STATUS";

export const INIT_DELETE_USER = "INIT_DELETE_USER";
export const INIT_DELETE_USER_STATUS = "INIT_DELETE_USER_STATUS";

export const INIT_USER_BY_ID_DATA = "INIT_USER_BY_ID_DATA";
export const USER_BY_ID_DATA_FETCH_STATUS = "INIT_USER_BY_ID_DATA_STATUS";
export const USER_BY_ID_DATA = "USER_BY_ID_DATA";

export const INIT_SAVE_USER_BY_ID_DATA = "INIT_SAVE_USER_BY_ID_DATA";

export const GET_USER_BASE_ROLE_ACTION = "GET_USER_BASE_ROLE_ACTION";

export const INIT_USER_ROLE_LIST = "INIT_USER_ROLE_LIST";
export const FETCH_USER_ROLE_LIST = "FETCH_USER_ROLE_LIST";
export const FETCH_USER_ROLE_LIST_STATUS = "FETCH_USER_ROLE_LIST_STATUS";

export const INIT_REGIONAL_SETTINGS = "INIT_REGIONAL_SETTINGS";
export const FETCH_REGIONAL_SETTINGS = "FETCH_REGIONAL_SETTINGS";

export const INIT_CHECK_FOR_EXISTING_USER = "INIT_CHECK_FOR_EXISTING_USER";
export const USER_EXIST = "USER_EXIST";

export const INIT_UPDATE_FAILED_PASSWORD_COUNT =
  "INIT_UPDATE_FAILED_PASSWORD_COUNT";
