import { fromJS } from "immutable";

import {
  INIT_EMAIL_INVITE,
  EMAIL_INVITE_SENT,
  FETCHING_LIST,
  FETCH_LIST,
  FETCHING_EDIT_LIST,
  FETCH_EDIT_LIST,
  INIT_TAKE_OWNERSHIP,
  SET_OWNERSHIP_DATA,
  FETCH_OWNERSHIP_STATUS,
  FETCHING_CONTACT_DETAILS,
  FETCH_CONTACT_DETAILS,
  FETCHING_ATTRIBUTES_DETAILS,
  FETCH_ATTRIBUTES_DETAILS,
  FETCHING_LOCATION_DETAILS,
  FETCH_LOCATION_DETAILS,
  FETCHING_ATTACHMENT_DETAILS,
  FETCH_ATTACHMENT_DETAILS,
  FETCHING_ATTRIBUTES_DETAILS_SUCCESS,
  FETCHING_EDIT_LIST_SUCCESS,
  SET_WORKFLOW_STEP_DATA,
  GET_WORKFLOW_STEP_DATA,
  SET_DIFF_DATA,
  FETCH_NEW_COMPANY_CREATION,
  INIT_TAKE_PROXY,
  TAKE_PROXY_STATUS,
  TAKE_PROXY_COMPLETE,
  INIT_WOKFLOW_STATE,
  SET_WORKFLOW_STATE,
  SET_CONTACT_DIFF_DATA,
  SET_LOCATION_DIFF_DATA,
  INIT_INVITE_REFRESH,
  INVITE_REFRESH_COMPLETE,
  SAVE_LOGO_COMPLETE,
  AUDIT_TRAIL_LIST_FETCH_STATUS,
  SET_AUDIT_TRAIL_LIST,
  INIT_AUDIT_TRAIL_DATA,
  SET_AUDIT_TRAIL_DATA,
  SET_LOGO,
  INIT_CLEAR_AUDIT_TRAIL,
  CLEAR_AUDIT_TRAIL_SUCCESS,
  UPDATE_LIST_DATA,
  UPDATE_AUDIT_TRAIL_LIST,
  FETCH_FLEX_FIELDS_STATUS,
  SET_FLEX_FIELDS,
  INIT_CLEAR_STORE,
  INIT_REJECT,
  INIT_REJECT_REMOVE,
  REJECT_REMOVE_SUCCESS,
  REJECT_SUCCESS,
  SAVE_ATTACHMENT_STATUS,
  SAVE_CONTACT_STATUS,
  SAVE_LOCATION_STATUS,
  GET_DIFF_DATA_STATUS,
  GET_CONTACT_DIFF_DATA_STATUS,
  GET_LOCATION_DIFF_DATA_STATUS,
  INVOICE_UPLOADING,
  SET_UPLOAD_PROGRESSION,
  INVOICE_UPLOAD_COMPLETE,
  INVOICE_UPLOAD_RESET,
  GET_LOGO_STATUS,
  FETCH_LIST_COMPLETE,
  SET_MANAGE_SUPPLIER_EDIT_MODE,
  INIT_GET_VMD_REQUEST_DATA_FOR_HELPDESK,
  INIT_SET_VMD_REQUEST_DATA_FOR_HELPDESK,
  HANDLE_CANCEL_BUTTON,
  SHOW_LOADER_ON_SAVE,
} from "./actionTypes";

export const initialState = fromJS({
  emailInviteSent: false,
  fetchingList: true,
  listData: [],
  statusCount: [],
  totalCount: 0,
  fetchingEditList: true,
  editListData: [],
  isOwnership: false,
  ownedCompanies: [],
  ownershipData: [],
  fetchOwnership: true,
  fetchingContactDetails: true,
  contactData: [],
  fetchingAttributesDetails: true,
  attributesData: [],
  fetchingLocationDetails: true,
  locationData: [],
  fetchingAttachmentDetails: true,
  attachmentData: [],
  fetchingWorkflowStepData: true,
  workflowStepData: {},
  fetchingDiffData: false,
  diffData: [],
  fetchingNewCmpnyStatus: false,
  fetchingTakeProxyStatus: true,
  fetchingWorkflowState: true,
  workflowState: [],
  fetchingContactDiffData: false,
  contactDiffData: [],
  fetchingLocationDiffData: false,
  locationDiffData: [],
  inviteRefreshDone: false,
  fetchSaveLogo: true,
  fetchingAuditTrailList: true,
  auditTrailData: [],
  auditTrailList: [],
  fetchingAuditTrailDetails: true,
  auditTrailDetails: [],
  fetchLogoStatus: false,
  logoData: {},
  fetchClearAuditStatus: true,
  fetchFlexFieldStatus: false,
  flexFields: [],
  rejectCompanyStatus: true,
  rejectRemoveCompanyStatus: true,
  saveContactStatus: false,
  saveLocationStatus: false,
  saveAttachmentStatus: false,
  uploadProgress: [],
  isEditMode: false,
  vmdRequestDataFetchStatus: false,
  vmdRequestData: [],
  isCancel: false,
  showLoaderOnSave: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_EMAIL_INVITE: {
      return state.set("emailInviteSent", false);
    }
    case EMAIL_INVITE_SENT: {
      return state.set("emailInviteSent", true);
    }
    case FETCHING_LIST: {
      return state.set("fetchingList", true);
    }
    case FETCH_LIST: {
      return state
        .set("listData", action.data.companies)
        .set("statusCount", action.data.statusCounts)
        .set("totalCount", action.data.totalCount);
    }
    case UPDATE_LIST_DATA: {
      return state
        .set("listData", action.data)
        .set("statusCount", action.statusCounts)
        .set("totalCount", action.totalCount);
    }
    case FETCH_LIST_COMPLETE: {
      return state.set("fetchingList", false);
    }
    case FETCHING_EDIT_LIST: {
      return state.set("fetchingEditList", true);
    }
    case FETCH_EDIT_LIST: {
      return state.set("editListData", action.data);
    }
    case FETCHING_EDIT_LIST_SUCCESS: {
      return state.set("fetchingEditList", false);
    }
    case INIT_TAKE_OWNERSHIP: {
      return state.set("fetchOwnership", true);
    }
    case SET_OWNERSHIP_DATA: {
      return state.set("ownershipData", action.data);
    }
    case FETCH_OWNERSHIP_STATUS: {
      return state.set("fetchOwnership", false);
    }
    case FETCHING_ATTRIBUTES_DETAILS: {
      return state.set("fetchingAttributesDetails", true);
    }
    case FETCH_ATTRIBUTES_DETAILS: {
      return state
        .set("attributesData", action.data)
        .set("fetchingAttributesDetails", false);
    }
    case FETCHING_ATTRIBUTES_DETAILS_SUCCESS: {
      return state.set("fetchingAttributesDetails", false);
    }
    case FETCHING_CONTACT_DETAILS: {
      return state.set("fetchingContactDetails", true);
    }
    case FETCH_CONTACT_DETAILS: {
      return state
        .set("contactData", action.data)
        .set("fetchingContactDetails", false);
    }
    case FETCHING_LOCATION_DETAILS: {
      return state.set("fetchingLocationDetails", true);
    }
    case FETCH_LOCATION_DETAILS: {
      return state
        .set("locationData", action.data)
        .set("fetchingLocationDetails", false);
    }
    case FETCHING_ATTACHMENT_DETAILS: {
      return state.set("fetchingAttachmentDetails", true);
    }
    case FETCH_ATTACHMENT_DETAILS: {
      return state
        .set("attachmentData", action.data)
        .set("fetchingAttachmentDetails", false);
    }
    case GET_WORKFLOW_STEP_DATA: {
      return state.set("fetchingWorkflowStepData", true);
    }
    case SET_WORKFLOW_STEP_DATA: {
      return state
        .set("workflowData", action.data)
        .set("fetchingWorkflowStepData", false);
    }
    case GET_DIFF_DATA_STATUS: {
      return state.set("fetchingDiffData", action.boolean);
    }
    case SET_DIFF_DATA: {
      return state.set("diffData", action.data);
    }
    case FETCH_NEW_COMPANY_CREATION: {
      return state.set("fetchingNewCmpnyStatus", action.boolean);
    }
    case INIT_TAKE_PROXY: {
      return state.set("fetchingTakeProxyStatus", true);
    }
    case TAKE_PROXY_STATUS: {
      return state.set("fetchingTakeProxyStatus", false);
    }
    case TAKE_PROXY_COMPLETE: {
      return state.set("fetchingTakeProxyStatus", false);
    }
    case INIT_WOKFLOW_STATE: {
      return state.set("fetchingWorkflowState", true);
    }
    case SET_WORKFLOW_STATE: {
      return state
        .set("workflowState", action.data)
        .set("fetchingWorkflowState", false);
    }
    case GET_CONTACT_DIFF_DATA_STATUS: {
      return state.set("fetchingContactDiffData", action.boolean);
    }
    case SET_CONTACT_DIFF_DATA: {
      return state.set("contactDiffData", action.data);
    }
    case GET_LOCATION_DIFF_DATA_STATUS: {
      return state.set("fetchingLocationDiffData", action.boolean);
    }
    case SET_LOCATION_DIFF_DATA: {
      return state.set("locationDiffData", action.data);
    }
    case INIT_INVITE_REFRESH: {
      return state.set("inviteRefreshDone", false);
    }
    case INVITE_REFRESH_COMPLETE: {
      return state.set("inviteRefreshDone", true);
    }
    case SAVE_LOGO_COMPLETE: {
      return state.set("fetchSaveLogo", action.boolean);
    }
    case AUDIT_TRAIL_LIST_FETCH_STATUS: {
      return state.set("fetchingAuditTrailList", action.boolean);
    }
    case SET_AUDIT_TRAIL_LIST: {
      return state
        .set("auditTrailData", action.data)
        .set("auditTrailList", action.data);
    }
    case UPDATE_AUDIT_TRAIL_LIST: {
      return state.set("auditTrailData", action.data);
    }
    case INIT_AUDIT_TRAIL_DATA: {
      return state.set("fetchingAuditTrailDetails", true);
    }
    case SET_AUDIT_TRAIL_DATA: {
      return state
        .set("auditTrailDetails", action.data)
        .set("fetchingAuditTrailDetails", false);
    }
    case INIT_CLEAR_AUDIT_TRAIL: {
      return state.set("fetchClearAuditStatus", true);
    }
    case CLEAR_AUDIT_TRAIL_SUCCESS: {
      return state.set("fetchClearAuditStatus", false);
    }
    case SET_LOGO: {
      return state.set("logoData", action.data);
    }
    case GET_LOGO_STATUS:
      return state.set("fetchLogoStatus", action.boolean);
    case FETCH_FLEX_FIELDS_STATUS: {
      return state.set("fetchFlexFieldStatus", action.boolean);
    }
    case SET_FLEX_FIELDS: {
      return state.set("flexFields", action.data);
    }
    case INIT_CLEAR_STORE: {
      return state
        .set("editListData", [])
        .set("fetchingEditList", true)
        .set("workflowData", [])
        .set("fetchingWorkflowStepData", true);
    }
    case INIT_REJECT: {
      return state.set("rejectCompanyStatus", true);
    }
    case REJECT_SUCCESS: {
      return state.set("rejectCompanyStatus", false);
    }
    case INIT_REJECT_REMOVE: {
      return state.set("rejectRemoveCompanyStatus", true);
    }
    case REJECT_REMOVE_SUCCESS: {
      return state.set("rejectRemoveCompanyStatus", false);
    }
    case SAVE_CONTACT_STATUS:
      return state.set("saveContactStatus", action.boolean);
    case SAVE_LOCATION_STATUS:
      return state.set("saveLocationStatus", action.boolean);
    case SAVE_ATTACHMENT_STATUS:
      return state.set("saveAttachmentStatus", action.boolean);
    case INVOICE_UPLOADING: {
      return state.set("uploadProgress", [
        ...state.get("uploadProgress"),
        action.data,
      ]);
    }
    case SET_UPLOAD_PROGRESSION: {
      const uploadProgress = state.get("uploadProgress");
      const updatedUploadProgress = uploadProgress.map((progress) => {
        return {
          ...progress,
          sent: progress.sent,
        };
      });

      return state.set("uploadProgress", updatedUploadProgress);
    }
    case INVOICE_UPLOAD_COMPLETE: {
      const uploadProgress = state.get("uploadProgress");
      const updatedUploadProgress = uploadProgress.map((progress) => {
        return {
          ...progress,
          sent: progress.sent,
          uploaded: progress.uploaded,
        };
      });
      return state.set("uploadProgress", updatedUploadProgress);
    }
    case INVOICE_UPLOAD_RESET: {
      return state.set("uploadProgress", []);
    }
    case SET_MANAGE_SUPPLIER_EDIT_MODE: {
      return state.set("isEditMode", action.isEditMode);
    }
    case INIT_GET_VMD_REQUEST_DATA_FOR_HELPDESK: {
      return state.set("vmdRequestDataFetchStatus", true);
    }
    case INIT_SET_VMD_REQUEST_DATA_FOR_HELPDESK: {
      return state.set("vmdRequestData", action.data);
    }
    case HANDLE_CANCEL_BUTTON: {
      return state.set("isCancel", action.isCancel);
    }
    case SHOW_LOADER_ON_SAVE: {
      return state.set("showLoaderOnSave", action.dataSaveStatus);
    }
    default:
      return state;
  }
};
export default reducer;
