export const createColor = (apiColor) => {
  const actualColor = {
    hue: apiColor.hue,
    sat: apiColor.sat,
    light: apiColor.light,
  };
  let darkColor = actualColor;
  let lightColor = { ...actualColor, light: "70" };
  if (actualColor.light >= 50) {
    darkColor = { ...actualColor, light: "30" };
    lightColor = actualColor;
  }
  return `linear-gradient(-225deg, hsl(${lightColor.hue}, ${lightColor.sat}%, ${lightColor.light}%), hsl(${darkColor.hue}, ${darkColor.sat}%, ${darkColor.light}%))`;
};

const themeChange = (baseRoleID, apiColor) => {
  const root = document.querySelector(":root");
  let color = {
    hue: process.env.REACT_APP_THEME_HUE || 132,
    sat: process.env.REACT_APP_THEME_SAT || "100",
    light: process.env.REACT_APP_THEME_LIGHT || "50",
  };
  if (process.env.NODE_ENV !== "development") {
    color = apiColor;
  }

  switch (baseRoleID) {
    case 2:
    case 3: {
      const themeGradient = createColor(color);
      const btnGradient = `linear-gradient(-225deg, hsl(${apiColor.hue}, ${apiColor.sat}%, ${apiColor.light}%) 0 ,var(--accenture-clr) 100%)`;

      if (process.env.NODE_ENV !== "development") {
        root.style.setProperty("--primary-btn-clr", btnGradient);
        root.style.setProperty("--banner-gradient", themeGradient);
        root.style.setProperty("--banner-clr-hs", `${color.hue} ${color.sat}%`);
        root.style.setProperty("--banner-clr-hue", `${color.hue}`);
        root.style.setProperty("--banner-clr-sat", `${color.sat}%`);
      } else {
        root.style.setProperty(
          "--primary-clr",
          `hsl(${color.hue},${color.sat}%,${color.light}%)`
        );
        root.style.setProperty("--primary-gradient", themeGradient);
        root.style.setProperty(
          "--primary-clr-hs",
          `${color.hue} ${color.sat}%`
        );
        root.style.setProperty("--primary-clr-hue", `${color.hue}`);
        root.style.setProperty("--primary-clr-sat", `${color.sat}%`);
      }

      break;
    }
    default: {
      break;
    }
  }
};

export default themeChange;
