import { buildNotification } from "config/notification";
import { store } from "react-notifications-component";
import { put, select, all, takeLatest } from "redux-saga/effects";
import { setInputValue } from "./actions";
import { INIT_OCR_EXTRATION } from "./actionTypes";
import { selectDataCapture, selectInput } from "./selectors";
export default function* watchOCRExtraction() {
  yield all([takeLatest(INIT_OCR_EXTRATION, initOCRExtractedData)]);
}

function* initOCRExtractedData(actions) {
  try {
    let dataCapture = yield select(selectDataCapture());

    dataCapture = dataCapture.toJS();

    const input = yield select(selectInput());

    if (!input) return;
    let currentFullOCR = actions.data;
    currentFullOCR = currentFullOCR ? JSON.parse(currentFullOCR) : [];

    let data = "";
    let x1;
    let x2;
    let y1;
    let y2;

    if (dataCapture.X1 > dataCapture.X2 && dataCapture.Y1 > dataCapture.Y2) {
      x1 = dataCapture.X2;
      x2 = dataCapture.X1;
      y1 = dataCapture.Y2;
      y2 = dataCapture.Y1;
    } else if (
      dataCapture.X1 < dataCapture.X2 &&
      dataCapture.Y1 < dataCapture.Y2
    ) {
      x1 = dataCapture.X1;
      x2 = dataCapture.X2;
      y1 = dataCapture.Y1;
      y2 = dataCapture.Y2;
    } else if (
      dataCapture.X1 > dataCapture.X2 &&
      dataCapture.Y1 < dataCapture.Y2
    ) {
      x1 = dataCapture.X2;
      x2 = dataCapture.X1;
      y1 = dataCapture.Y1;
      y2 = dataCapture.Y2;
    } else if (
      dataCapture.X1 < dataCapture.X2 &&
      dataCapture.Y1 > dataCapture.Y2
    ) {
      x1 = dataCapture.X1;
      x2 = dataCapture.X2;
      y1 = dataCapture.Y2;
      y2 = dataCapture.Y1;
    }
    for (const element of currentFullOCR) {
      if (element.XCoOrdinate >= x1 && element.XCoOrdinate <= x2) {
        if (element.YCoOrdinate >= y1 && element.YCoOrdinate <= y2) {
          data = data + element.CharacterValue;
        }
      }
    }

    data = data.replace(/\n/g, "").trim();

    yield put(setInputValue(data));
  } catch (error) {
    const notification = buildNotification({
      message: `msp.errorOccuredMsg`,
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}
