import React from "react";
import SvgIcon from "./style";
const Reprocess = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="currentColor"
        d="M15.375,0.249992575 L15.0625,0.249992575 C14.8554063,0.249992575 14.6875,0.417906231 14.6875,0.624999982 L14.6875,4.08203124 C13.337625,1.78271873 10.8362813,0.241093731 7.97521875,0.249992575 C3.71312499,0.263374981 0.243343731,3.75028124 0.24999042,8.012375 C0.256687481,12.2869063 3.72393749,15.7499926 8,15.7499926 C9.9976875,15.7499926 11.8188125,14.9941563 13.1930625,13.752875 C13.3528438,13.6085625 13.360375,13.3603438 13.208125,13.2080938 L12.9872188,12.9871875 C12.8465,12.8464688 12.6200625,12.839875 12.4721563,12.973 C11.2880625,14.0386875 9.72078125,14.6875 8,14.6875 C4.32049999,14.6875 1.31249998,11.7084063 1.31249998,8 C1.31249998,4.32049999 4.29159374,1.31249998 8,1.31249998 C10.5894375,1.31249998 12.8355313,2.78365624 13.9465313,4.93749999 L10.375,4.93749999 C10.1679063,4.93749999 10,5.10540624 10,5.31249999 L10,5.62499999 C10,5.83209374 10.1679063,6 10.375,6 L15.375,6 C15.5820938,6 15.7499904,5.83209374 15.7499904,5.62499999 L15.7499904,0.624999982 C15.7499904,0.417906231 15.5820938,0.249992575 15.375,0.249992575 Z"
      ></path>
    </SvgIcon>
  );
};
export default Reprocess;
