import {
  INIT_FETCH_INVOICE_LIST,
  FETCHING_INVOICE_LIST,
  FETCH_INVOICE_LIST,
  INVOICE_LIST_FETCH_COMPLETE,
  INIT_FETCH_INVOICE_DETAILS,
  FETCH_INVOICE_DETAILS,
  INVOICE_DETAILS_FETCH_COMPLETE,
  INIT_FETCH_PAYMENT_DETAILS,
  INIT_FETCH_ADVANCE_PAYMENT_DETAILS,
  FETCH_PAYMENT_DETAILS,
  FETCH_ADVANCE_PAYMENT_DETAILS,
  PAYMENT_DETAILS_FETCH_COMPLETE,
  ADVANCE_PAYMENT_DETAILS_FETCH_COMPLETE,
  INIT_FETCH_PAYMENTS_LIST,
  FETCHING_PAYMENTS_LIST,
  FETCH_PAYMENTS_LIST,
  PAYMENTS_LIST_FETCH_COMPLETE,
  INIT_DOWNLOAD_INVOICE_DFM,
  SET_INVOICE_DFM,
  INIT_SUPPLIER_LIST,
  FETCHING_SUPPLIER_LIST,
  FETCH_SUPPLIER_LIST,
  FETCH_SUPPLIER_LIST_COMPLETED,
  FETCH_SUPPLIER_LIST_FAILED,
  DFM_INVOICE_FETCH_STATUS,
} from "./actionTypes";

export const initFetchInvoiceList = (payload, callback) => {
  return {
    type: INIT_FETCH_INVOICE_LIST,
    isInvoiceLoading: true,
    payload,
    callback,
  };
};
export const fetchingInvoiceList = () => {
  return {
    type: FETCHING_INVOICE_LIST,
  };
};
export const fetchInvoiceList = (
  fieldLabels,
  invoiceData,
  lineitems,
  rowCount,
  rowCountForAllCurrencies
) => {
  return {
    type: FETCH_INVOICE_LIST,
    fieldLabels,
    invoiceData,
    lineitems,
    rowCount,
    rowCountForAllCurrencies,
  };
};
export const invoiceListFetchingComplete = () => {
  return {
    type: INVOICE_LIST_FETCH_COMPLETE,
  };
};

export const initFetchInvoiceDetails = (payload, globalSearch, callback) => {
  return {
    type: INIT_FETCH_INVOICE_DETAILS,
    payload,
    globalSearch,
    callback,
  };
};
export const fetchInvoiceDetails = (
  fieldLabels,
  invoiceData,
  headerItems,
  lineitems,
  rowCount
) => {
  return {
    type: FETCH_INVOICE_DETAILS,
    fieldLabels,
    invoiceData,
    headerItems,
    lineitems,
    rowCount,
  };
};
export const invoiceDetailsFetchComplete = () => {
  return {
    type: INVOICE_DETAILS_FETCH_COMPLETE,
  };
};

export const initFetchPaymentsList = (payload, callback) => {
  return {
    type: INIT_FETCH_PAYMENTS_LIST,
    isPaymentsLoading: true,
    payload,
    callback,
  };
};
export const fetchingPaymentsList = () => {
  return {
    type: FETCHING_PAYMENTS_LIST,
  };
};
export const fetchPaymentsList = (
  fieldLabels,
  remittanceList,
  ticketCount,
  totalAmount
) => {
  return {
    type: FETCH_PAYMENTS_LIST,
    fieldLabels,
    remittanceList,
    ticketCount,
    totalAmount,
  };
};
export const paymentsListFetchingComplete = () => {
  return {
    type: PAYMENTS_LIST_FETCH_COMPLETE,
  };
};

export const initFetchPaymentDetails = (payload, globalSearch, callback) => {
  return {
    type: INIT_FETCH_PAYMENT_DETAILS,
    payload,
    globalSearch,
    callback,
  };
};
export const initFetchAdvancePaymentDetails = (payload) => {
  return {
    type: INIT_FETCH_ADVANCE_PAYMENT_DETAILS,
    payload
  };
};

export const fetchPaymentDetails = (
  fieldLabels,
  remittanceList,
  ticketCount
) => {
  return {
    type: FETCH_PAYMENT_DETAILS,
    fieldLabels,
    remittanceList,
    ticketCount,
  };
};

export const fetchAdvancePaymentDetails = (
  fieldLabels,
  advPaymentsList,
  totalCount
) => {
  return {
    type: FETCH_ADVANCE_PAYMENT_DETAILS,
    fieldLabels,
    advPaymentsList,
    totalCount,
  };
};

export const paymentDetailsFetchComplete = () => {
  return {
    type: PAYMENT_DETAILS_FETCH_COMPLETE,
  };
};

export const advancePaymentDetailsFetchComplete = () => {
  return {
    type: ADVANCE_PAYMENT_DETAILS_FETCH_COMPLETE,
  };
};

export const initDownloadInvoiceDFM = (urn, clientName) => {
  return {
    type: INIT_DOWNLOAD_INVOICE_DFM,
    urn,
    clientName,
  };
};
export const setDownloadInvoice = (data) => {
  return {
    type: SET_INVOICE_DFM,
    data,
  };
};
export const dfmInvoiceFetchStatus = (bool) => {
  return {
    type: DFM_INVOICE_FETCH_STATUS,
    bool,
  };
};

export const initSupplierList = (data) => {
  return {
    type: INIT_SUPPLIER_LIST,
    data,
  };
};

export const fetchingSupplierList = () => {
  return {
    type: FETCHING_SUPPLIER_LIST,
  };
};

export const fetchSupplierList = (data) => {
  return {
    type: FETCH_SUPPLIER_LIST,
    data,
  };
};

export const fetchSupplierListComplete = () => {
  return {
    type: FETCH_SUPPLIER_LIST_COMPLETED,
  };
};

export const fetchSupplierListFailed = (data) => {
  return {
    type: FETCH_SUPPLIER_LIST_FAILED,
    data,
  };
};
