import zh from "date-fns/locale/zh-CN";
import cs from "date-fns/locale/cs";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-GB";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import hu from "date-fns/locale/hu";
import it from "date-fns/locale/it";
import ja from "date-fns/locale/ja";
import ko from "date-fns/locale/ko";
import nl from "date-fns/locale/nl";
import pl from "date-fns/locale/pl";
import pt from "date-fns/locale/pt";
import ro from "date-fns/locale/ro";
import ru from "date-fns/locale/ru";
import sk from "date-fns/locale/sl";
import sv from "date-fns/locale/sv";
import th from "date-fns/locale/th";
import tr from "date-fns/locale/tr";

export const getTranslationFile = {
  zh,
  cs,
  de,
  en,
  es,
  fr,
  hu,
  it,
  ja,
  ko,
  nl,
  pl,
  pt,
  ro,
  ru,
  sk,
  sv,
  th,
  tr,
};
