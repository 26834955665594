export const USER_CREATION_REQUEST_FETCH = "USER_CREATION_REQUEST_FETCH";
export const USER_CREATION_REQUEST_FETCH_STATUS = "USER_CREATION_REQUEST_FETCH_STATUS"
export const USER_CREATION_LIST = "USER_CREATION_LIST";

export const APPROVE_USER_REQUEST = "APPROVE_USER_REQUEST";
export const APPROVE_USER_REQUEST_SUCCESS = "APPROVE_USER_REQUEST_SUCCESS";
export const REJECT_USER_REQUEST = "REJECT_USER_REQUEST";
export const REJECT_USER_REQUEST_SUCCESS = "REJECT_USER_REQUEST_SUCCESS";
export const SAVE_USER_CREATION = "SAVE_USER_CREATION";

export const UPDATE_SUBMIT_STATUS = "UPDATE_SUBMIT_STATUS";

export const CONFIRM_ACCOUNT = "CONFIRM_ACCOUNT";

export const UPDATE_CONFIRM_STATUS = "UPDATE_CONFIRM_STATUS";

export const UPDATE_REQUEST_STATUS = "UPDATE_REQUEST_STATUS";

export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const FETCH_GET_USER_BY_ID = "FETCH_GET_USER_BY_ID";
export const FETCH_GET_USER_BY_ID_STATUS = "FETCH_GET_USER_BY_ID_STATUS";

export const INIT_DOWNLOAD_REQ_ATTACHMENT = "INIT_DOWNLOAD_REQ_ATTACHMENT";

export const INIT_FETCH_SIGNUP_FIELDS = "INIT_FETCH_SIGNUP_FIELDS";
export const FETCHING_SIGNUP_FIELDS = "FETCHING_SIGNUP_FIELDS";
export const FETCH_SIGNUP_FIELDS = "FETCH_SIGNUP_FIELDS";

export const INIT_RESEND_APPROVED_EMAIL = "INIT_RESEND_APPROVED_EMAIL";
export const RESEND_APPROVED_EMAIL_SUCCESS = "RESEND_APPROVED_EMAIL_SUCCESS";