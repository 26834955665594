import PropTypes from "prop-types";
import DateRangeFilter from "components/DateRangeFilter/index";
import { A } from "components/index";
import { Back } from "components/SVG/index";
import React from "react";

import ModuleHeaderStyled, {
  BackButton,
  ModuleHeaderActionButtons,
  ModuleHeaderTitle,
} from "./style";

const ModuleHeader = (props) => {
  return (
    <ModuleHeaderStyled>
      {props.backUrl ? (
        <BackButton
          as={A}
          to={props.backUrl}
          onClick={props.handleGoingBack}
          aria-label="backTo"
        >
          <Back />
        </BackButton>
      ) : null}
      <ModuleHeaderTitle>{props.title}</ModuleHeaderTitle>
      {props.datePicker ? (
        <DateRangeFilter applyDateRangeFilter={props.applyDateRangeFilter} />
      ) : null}
      <ModuleHeaderActionButtons>
        {props.rightContent}
      </ModuleHeaderActionButtons>
    </ModuleHeaderStyled>
  );
};

ModuleHeader.propTypes = {
  applyDateRangeFilter: PropTypes.func,
  backUrl: PropTypes.any,
  datePicker: PropTypes.bool,
  handleGoingBack: PropTypes.func,
  rightContent: PropTypes.object,
  title: PropTypes.string,
};

export default ModuleHeader;
