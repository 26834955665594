import { Rows } from "components";
import styled from "styled-components";
import { ButtonWrapper, IconWrapper } from "components/index";
import MainContentWrapper from "components/MainContentWrapper/index";

export const ResolvedIcon = styled(IconWrapper)`
  color: ${(props) =>
    props.disabled ? "var(--disabled-clr-dark)" : "var(--accenture-clr)"};
  svg {
    width: 1em;
  }
`;
export const ListActions = styled(Rows)`
  justify-content: space-between;
  margin-top: 1em;
  @media (min-width: 36em) {
    flex-direction: row;
  }
`;
export const AttachmentBtn = styled(IconWrapper)`
  width: 1rem;
`;

const ListWrapper = styled(MainContentWrapper)``;

export const BtnWrapper = styled(ButtonWrapper)``;
export default ListWrapper;
