import styled from "styled-components";

import { Rows, IconWrapper } from "components/index";
import {
  AssignGroupActions,
  AssignGroups,
} from "components/DocumentSubmisison/UploadInvoice/AssignGroupCards/style";

export const LanguageCard = styled(AssignGroups)``;

export const LanguageContainer = styled(Rows)``;
export const LanguageCardButtons = styled(AssignGroupActions)``;
export const LanguageCode = styled.div`
  grid-area: lang-code;
`;
export const MetaData = styled(Rows)`
  color: var(--grey-clr);
  ${IconWrapper} {
    margin: 0 0.5em;
    svg {
      width: 0.8em;
    }
  }
`;
const LangListStyled = styled(Rows)`
  margin-top: 0.5em;
  ${LanguageCard} {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 0;
    flex: 0 0 100%;
    ${LanguageContainer} {
      justify-content: space-between;
      padding: 1em;
      gap: 1em;
      ${LanguageCode} {
        display: inline-flex;
        align-items: center;
        width: fit-content;
        height: fit-content;
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: var(--primary-clr);
        color: var(--light-clr);
        font-family: var(--ff-medium);
        text-transform: uppercase;
      }
    }
    ${LanguageCardButtons} {
      position: relative;
      ${IconWrapper} {
        padding: 0;
        img,
        svg {
          width: 1.3em;
        }
      }
    }
  }

  @media (min-width: 32em) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    ${LanguageCard} {
      flex-basis: calc(50% - 1em);
      max-width: 18.75em;
      ${LanguageContainer} {
        display: grid;
        grid-auto-flow: columns;
        grid-template-areas: ".  lang-code";
      }
    }
  }
  @media (min-width: 48em) {
    justify-content: flex-start;

    ${LanguageCard} {
      flex-basis: calc(33% - 1em);
    }
  }
  @media (min-width: 62em) {
    gap: 2rem;
    ${LanguageCard} {
      flex-basis: calc(33% - 2em);
    }
  }
`;

export default LangListStyled;
