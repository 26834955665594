import styled from "styled-components";

export const FormControlWrapper = styled.div`
  width: 100%;
  display: ${(props) => props.display || "inline-block"};
  padding: ${(props) => (props.noPadding ? 0 : `0 0.5em 1em 0`)};
  @media (min-width: 48em) {
    width: ${(props) => `calc(100% * (${props.grow || 10} / 10))`};
  }
`;
