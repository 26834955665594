import { put, takeEvery, call, all, delay } from "redux-saga/effects";
import { buildNotification } from "../../../../config/notification";
import { store } from "react-notifications-component";
import axios from "../../../../config/axiosCall";
import {
  getRequestDetails,
  postRequestDetails,
  putRequestDetails,
} from "../../../../config/requestHeaders";
import {
  fetchingPageElements,
  fetchPageElements,
  PageElementsFetchingComplete,
  fetchingAccessLevel,
  fetchAccessLevel,
  accessLevelFetchingComplete,
  profileRoleSave,
  formSubmissionStatusAction,
  setDataForEdit,
  deleteSuccess,
  deleteFailed,
  saveProfileRoleFailed,
  fetchingLoginGDPROptions,
  fetchLoginGDPROptions,
  LoginGDPROptionsFetchingComplete,
  fetchingApplicationGDPROptions,
  fetchApplicationGDPROptions,
  ApplicationGDPROptionsFetchingComplete,
} from "../store/actions";

import {
  INIT_GET_PAGE_ELEMENTS,
  INIT_GET_ACCESS_LEVEL,
  INIT_PROFILE_ROLE_SAVE,
  INIT_PROFILE_PERMISSION_EDIT,
  INIT_USER_ROLES_DELETE,
  INIT_GET_APPLICATION_GDPR_OPTIONS,
  INIT_GET_LOGIN_GDPR_OPTIONS,
} from "./actionTypes";

export default function* watchProfilePermissions() {
  yield all([
    takeEvery(INIT_GET_PAGE_ELEMENTS, getPageElementsSaga),
    takeEvery(INIT_GET_ACCESS_LEVEL, getAccessLevelListSaga),
    takeEvery(INIT_PROFILE_ROLE_SAVE, saveProfileRoleSaga),
    takeEvery(INIT_PROFILE_PERMISSION_EDIT, initEditSaga),
    takeEvery(INIT_USER_ROLES_DELETE, initUserRoleDeleteSaga),
    takeEvery(
      INIT_GET_APPLICATION_GDPR_OPTIONS,
      initApplicationGDPROptionsSaga
    ),
    takeEvery(INIT_GET_LOGIN_GDPR_OPTIONS, initLoginGDPROptionsSaga),
  ]);
}

function* getPageElementsSaga() {
  yield put(fetchingPageElements());
  const url = "/Users/PageElements";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const data =
      response && response.data && response.data.length
        ? response.data.map((item) => ({
            ...item,
            selected: false,
          }))
        : [];
    yield put(fetchPageElements(data));
    yield put(PageElementsFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorOccuredMessage",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(PageElementsFetchingComplete());
  }
}

function* getAccessLevelListSaga() {
  yield put(fetchingAccessLevel());
  const url = "/Users/listAccessLevel";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(fetchAccessLevel(response.data));
    yield put(accessLevelFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorOccuredMessage",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(accessLevelFetchingComplete());
  }
}

function* saveProfileRoleSaga(action) {
  const url = "/Users/saverole";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    userId: action.userId,
    ...action.formdata,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      if (response.status) {
        yield put(profileRoleSave());
        yield put(formSubmissionStatusAction(response.statusText));
        if (action.saveSuccess) {
          action.saveSuccess(response.status);
        }
        if (action.isEditMode) {
          const notification = buildNotification({
            message: "msp.roleUpdatedSuccessfully",
            type: "success",
          });
          store.addNotification({
            ...notification,
            dismiss: {
              duration: 1000,
            },
          });
        } else {
          const notification = buildNotification({
            message: "msp.newUserRoleCreatedSuccessfully",
            type: "success",
          });
          store.addNotification({
            ...notification,
            dismiss: {
              duration: 1000,
            },
          });
        }
      } else {
        if (action.saveFailure) {
          action.saveFailure(true);
          const { data } = response && response.config;
          const { roleName } = data ? JSON.parse(data) : {};
          const errorMessage = roleName && `msp.roleAlreadyExists`;
          const notification = buildNotification({
            message: errorMessage,
            type: "danger",
          });
          store.addNotification({
            ...notification,
            dismiss: {
              duration: 1000,
            },
          });
          yield put(saveProfileRoleFailed());
        }
      }
    }
  } catch (error) {
    yield put(saveProfileRoleFailed());
  }
}

function* initEditSaga(action) {
  const url = `/Users/roleById?roleId=${action.id}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      yield put(setDataForEdit(response.data));
      yield delay(1000);
      if (action.callback) {
        action.callback(response.status);
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* initUserRoleDeleteSaga(action) {
  const url = `Users/DeleteProfile`;
  const requestDetails = { ...putRequestDetails };

  yield (requestDetails.data = {
    userId: action.userId,
    listOfIds: action.id,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteSuccess(id));
      if (action.callback) {
        action.callback(response.status);
      }
      const notification = buildNotification({
        message: "msp.userRoleDeletedSuccessfully",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorOccuredMessage",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(deleteFailed());
  }
}

function* initLoginGDPROptionsSaga() {
  yield put(fetchingLoginGDPROptions());
  const url = "/CustomListItem/listkeys?typeName=__Login_GDPR_Messages";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const updateResponse =
      response && response.data && response.data.length > 0
        ? response.data.map((item) => ({
            id: item.id,
            value: item.label,
          }))
        : [];
    yield put(fetchLoginGDPROptions(updateResponse));
    yield put(LoginGDPROptionsFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorOccuredMessage",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(LoginGDPROptionsFetchingComplete());
  }
}

function* initApplicationGDPROptionsSaga() {
  yield put(fetchingApplicationGDPROptions());
  const url = "/CustomListItem/listkeys?typeName=__Application_GDPR_Messages";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const updateResponse =
      response && response.data && response.data.length > 0
        ? response.data.map((item) => ({
            id: item.id,
            value: item.label,
          }))
        : [];
    yield put(fetchApplicationGDPROptions(updateResponse));
    yield put(ApplicationGDPROptionsFetchingComplete());
  } catch (error) {
    const notification = buildNotification({
      message: "msp.errorOccuredMessage",
      type: "danger",
    });
    store.addNotification({
      ...notification,
      dismiss: {
        duration: 1000,
      },
    });
    yield put(ApplicationGDPROptionsFetchingComplete());
  }
}
