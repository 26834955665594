import styled from "styled-components";

import { NavLink } from "react-router-dom";
import DropdownStyled, { DropdownContent } from "./Submenu/style";

export const NavLinkStyled = styled(NavLink)`
  display: flex;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
`;

export const NavItem = styled.li`
  :hover,
  :focus,
  :focus-visible,
  :focus-within {
    & > ${DropdownContent} {
      position: relative;
      transform: scale(1, 1);
      @media (min-width: 68.75em) {
        position: absolute;
        transform: scale(1, 1);
      }
      li {
        opacity: 1;
      }
    }
    ${DropdownStyled} {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  ${NavLinkStyled},
  ${DropdownStyled} {
    color: var(--primary-text-clr);
    padding: 1em;
    font-family: var(--ff-medium);
    font-size: clamp(var(--fs-12), 1vw, var(--fs-16));
    font-weight: var(--fw-normal);
    width: 100%;
    cursor: pointer;
    :hover,
    :focus {
      background: var(--dropdown-hover-clr);
      border-radius: 0.1875em;
      @media (min-width: 68.75em) {
        border-radius: 0;
        background-color: transparent;
        opacity: 0.7;
      }
    }
  }
  ${DropdownContent} {
    padding-left: 1em;
    height: fit-content;
  }
  @media (min-width: 68.75em) {
    position: relative;
    align-content: center;
  }
`;

export const NavItems = styled.ul`
  @media (min-width: 68.75em) {
    gap: 1em;
    width: 100%;
  }
`;
const NavStyled = styled.nav`
  order: 2;
  display: block;
  position: absolute;
  top: 100%;
  left: 3em;
  right: 0;
  width: calc(100vw - 3em);
  height: calc(100vh - 3.5em);
  padding: 0.5em 1em 0.5em 0;
  background-color: #fff;
  text-align: left;
  transform: scale(0, 1);
  transform-origin: left;
  transition: transform 400ms ease-in-out;
  overflow: auto;
  z-index: 5;
  @media (min-width: 36em) {
    left: 0;
    width: calc(100vw - 0.2em);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
  @media (min-width: 68.75em) {
    width: unset;
    order: 2;
    padding: 0;
    position: relative;
    height: auto;
    transform: scale(1, 1);
    background-color: transparent;
    box-shadow: none;
    max-height: inherit;
    overflow: visible;
    z-index: 2;
    display: ${(props) => (props.searchOpened ? `none` : `flex`)};
    align-items: center;

    ${NavItems}, ${NavItem}, ${DropdownContent} {
      display: flex;
      align-items: center;
    }

    ${NavLinkStyled} {
      opacity: 1;
    }
    ${NavLinkStyled}, ${DropdownStyled} {
      opacity: 1;
      padding: 0;
      color: var(--light-clr);
    }
    ${NavItem}, ${NavLinkStyled}, ${DropdownStyled} {
      font-size: var(--fs-16);
      font-family: var(--ff-medium);
      font-weight: var(--fw-normal);
    }
    ${DropdownContent} {
      background: rgb(255, 255, 255);
      border-radius: 0.8rem;
      box-shadow: 0 0.2rem 3.2rem 0 rgb(0 0 0 / 10%);
      overflow: auto;
      top: calc(100% + 0.25em);
      padding: 0.5em;
      min-width: 12.25rem;
      max-height: calc(100vh - 6em);
      margin: 0.25em;
      align-items: stretch;

      ${NavLinkStyled} {
        padding: 0.84em 0.5em;
        opacity: 1;
        display: block;
        color: var(--dark-clr);
        text-align: start;
        transition: opacity 100ms ease-in-out;
        font-size: var(--fs-12);
        :hover,
        :focus {
          background-color: var(--dropdown-hover-clr);
        }
      }
    }
  }
`;

export default NavStyled;
