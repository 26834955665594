import { Checkbox } from "@progress/kendo-react-inputs";
import RadioButtonComponent from "../../RadioButton";

export const getSelectableRow = (props, mode, selectFunc) => {
  if (mode) {
    return mode !== "single"
      ? {
          filterable: false,
          selector: "selected",
          headerSelectionValue: false,
          cell: (row) => (
            <td data-selected="true">
              <Checkbox
                label="&nbsp;"
                name="selectRow"
                checked={row.dataItem.selected}
                value={row.dataItem.selected}
                disabled={row.dataItem.disableSelect}
                onChange={(event) =>
                  selectFunc({
                    ...event,
                    dataItem: row.dataItem,
                  })
                }
              />
            </td>
          ),
        }
      : {
          filterable: false,
          name: "  ",
          selector: "radio",
          headerSelectionValue: false,
          cell: (row) => (
            <td data-selected="true">
              <RadioButtonComponent
                id={row.dataItem[props.dataItemKey]}
                name="selectRow"
                checked={row.dataItem.selected}
                value={true}
                label="&nbsp;"
                handleCheck={(event) =>
                  selectFunc({
                    ...event,
                    dataItem: row.dataItem,
                  })
                }
              />
            </td>
          ),
        };
  }
  return null;
};
