import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router";
import { buildNotification } from "config/notification";
import { getRoutePathByKey } from "helper";

import { storage } from "config/storage";
import { zeroPad } from "container/Support/config/index";
import {
  selectGlobalSearchResults,
  selectGlobalSearchStatus,
  selectIsGlobalSearchActive,
} from "store/selectors";
import { selectInvoiceDetailsFetchStatus } from "container/InvoiceStatus/store/selectors";

import { selectHelpdeskDetailsByIdListStatus } from "container/Support/store/selectors";
import {
  initGlobalSearch,
  isGlobalSearchActive,
  resetGlobalSearch,
} from "store/actions";
import { fetchTLogCase, initGetServiceNowQueryByID } from "container/Support/store/actions";
import { initFetchInvoiceDetails } from "container/InvoiceStatus/store/actions";
import { Cross } from "components/SVG/index";
import { Input, Select, Spinner } from "components/index";
import Loader from "components/ResolveRejectionLoading/index";
import GlobalSearchStyled, {
  customStyle,
  DesktopIcon,
  DesktopSearchHint,
  MobileIcon,
  MobileSearchHint,
  TopTenRecordsInfo,
  NoRecords,
  SearchContent,
  SearchContentItem,
  SearchContentItemInfo,
  SearchInput,
  SearchInputWrapper,
  SearchOptions,
  StyledOverlay,
} from "./style";
import { selectInvoiceFetching } from "container/DS-common-submitted-invoice/store/selectors";
import { getInvoiceDetails } from "container/DS-common-submitted-invoice/store/services";

const GlobalSearch = (props) => {
  const { t } = useTranslation();
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const isExtHelpDeskLinkUsed  = (sessionDetails && sessionDetails.isExtHelpDeskLinkUsed) || false;
  const language =
    (sessionDetails &&
      sessionDetails.languageId !== "" &&
      sessionDetails.languageId) ||
    "enGB";
  let menus = sessionDetails ? sessionDetails.menus : []; 
  let isSubmissionPermission;
  if(menus){
    isSubmissionPermission = menus.find(
      (item) => item.pageElementFileName === "documentSubmission"
    )
  }
  let dropdownOptions;
  if (isSubmissionPermission)
  {
    dropdownOptions = [
        { id: 1, value: t("msp.invoiceNumber") },
        { id: 2, value: t("msp.ticketID") },
        { id: 3, value: t("msp.submissionID") },
        { id: 4, value: "URN" },
      ];
  }
  else
  { // URN and Submission ID is out of scope since submission permission not present
    dropdownOptions = [
      { id: 1, value: t("msp.invoiceNumber") },
      { id: 2, value: t("msp.ticketID") }
    ];
  }

  const [dropdownSelection, setDropdownSelection] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [showHint, setShowHint] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);

  useEffect(() => {
    if (props.isGlobalSearchActive) {
      setInputValue("");
      setDropdownSelection("");
    }
  }, [props.isGlobalSearchActive]);

  const handleDropdown = ({ target }) => {
    const { value } = target;
    if (value !== dropdownSelection) {
      setInputValue("");
      props.searchResults &&
        props.searchResults.length > 0 &&
        props.resetGlobalSearch();
      setShowSearchResults(false);
      setDropdownSelection(value);
    }
  };

  const handleInput = (e) => {
    const { value } = e.target;
    if (value !== inputValue) {
      !showHint && setShowHint(true);
      showSearchResults && setShowSearchResults(false);
      props.searchResults &&
        props.searchResults.length > 0 &&
        props.resetGlobalSearch();
      setInputValue(value);
    }
  };

  const handleInputFocus = () => {
    if (!showSearchResults && !showHint) {
      setShowHint(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!showSearchResults && inputValue && inputValue.trim()) {
      // helpdesk ticket - 2
      if (dropdownSelection === "2") {
        if (isExtHelpDeskLinkUsed)
        {
          
          if (
            inputValue.trim().length > 4 &&
            inputValue.trim().toLowerCase().startsWith("ritm")
          ) {
            setShowHint(false);
            setShowSearchResults(true);
            props.initGlobalSearch({
              searchCategory: "5", //external helpdesk
              searchValue: inputValue.trim(),
              LanguageID: language,
            });
          }
        }else {
        if (
          inputValue.trim().length > 3 &&
          inputValue.trim().toLowerCase().startsWith("msp")
        ) {
          setShowHint(false);
          setShowSearchResults(true);
          props.initGlobalSearch({
            searchCategory: dropdownSelection,
            searchValue: inputValue.trim().substring(3),
            LanguageID: language,
          });
        }}
      } else if (inputValue.trim().length >= 3) {
        setShowHint(false);
        setShowSearchResults(true);
        props.initGlobalSearch({
          searchCategory: dropdownSelection,
          searchValue: inputValue.trim(),
          LanguageID: language,
        });
      }
    }
  };

  const showSelectedRecord = (value) => {
    switch (dropdownSelection) {
      case "1":
        const payload = {
          invoiceNumber: value,
          LanguageID: language,
        };
        props.showInvoiceDetails(payload, true, props.invoicePaymentData);

        break;
      case "2":
        if (isExtHelpDeskLinkUsed)
        {
          props.getServiceNowQueryByID(
            value,
            redirectToExternalHelpdesk,
          );
        }
        else{
        props.showHelpdeskDetailsById(
          value.substring(3),
          redirectToHelpdesk,
          true
        );
      }

        break;
      case "3":
        getSubmissionById(value);

        break;
      case "4":
        const urnPayload = {
          urn: value,
          LanguageID: language,
        };
        props.showInvoiceDetails(urnPayload, true, props.invoicePaymentData);

        break;
      default:
        props.history.push("/");
        break;
    }
  };


  const redirectToExternalHelpdesk = (data) => {
    const { number } = data;
    let state = {};
    state = {
      ritmNo: number,
    };
    props.history.push({
      pathname: "/support/open/service-now-details",
      state,
    });
    props.globalSearchActiveStatus(true);
    resetControls();
  };

  const redirectToHelpdesk = (_, data) => {
    const helpdeskDetails = getRoutePathByKey("supportOpenDetail");
    const { helpdeskId, queryId, statusLabel } = data.helpDeskViewModel;
    let state = {};
    state = {
      helpdeskId,
      queryID: queryId,
      statusLabel,
      searchId: helpdeskId ? `MSP${zeroPad(helpdeskId, 7)}` : "",
    };
    props.history.push({
      pathname: helpdeskDetails,
      state,
    });
    props.globalSearchActiveStatus(true);
    resetControls();
  };

  const getSubmissionById = async (refNum) => {
    try {
      const res = await getInvoiceDetails({ refNum });
      const {
        submissionId = 0,
        bulkUploadReferenceNumber = 0,
        flowType = 0,
      } = res;

      if (submissionId || bulkUploadReferenceNumber) {
        let pathname = "/document-submission/invoice-review",
          search = "";
        search = `ref=${submissionId}`;
        if (bulkUploadReferenceNumber)
          search = +`bulkRef=${bulkUploadReferenceNumber}`;
        if (flowType > 0) {
          if (flowType > 2) {
            pathname = "/document-submission/invoice-generation";
          }
          props.history.push({ pathname, search });
          props.globalSearchActiveStatus(true);
          resetControls();
        }
      } else {
        const notification = buildNotification({
          message: "msp.noResultFound",
          type: "danger",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 2000,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadingData =
    props.loadingInvoiceDetails ||
    props.loadingSupportRequestDetails ||
    props.loadingSubmissionDetails;

  const resetControls = (e) => {
    setInputValue("");
    setShowHint(false);
    setShowSearchResults(false);
    props.searchResults &&
      props.searchResults.length > 0 &&
      props.resetGlobalSearch();
    setDropdownSelection("");
    props.globalSearchActiveStatus(false);
    props.onCloseSearch(e);
  };

  const hintCondition =
    !inputValue ||
    (inputValue && !inputValue.trim() && inputValue.trim().length < 3) ||
    (inputValue && inputValue.trim() && inputValue.trim().length < 3);
  let desktopHint = hintCondition
    ? t("msp.globalSearchMsg")
    : t("msp.enterSearchMsg");
  let mobileHint = hintCondition
    ? t("msp.globalSearchMsg")
    : "Hit search button to search";
  // helpdesk ticket - 2
  if (dropdownSelection === "2") {
    if(isExtHelpDeskLinkUsed)
    {
      if (hintCondition) {
        desktopHint = t("msp.startWithRITM");
        mobileHint = t("msp.startWithRITM");
        } else {
        if (
          inputValue.trim().length <= 4 ||
          !inputValue.trim().toLowerCase().startsWith("ritm")
        ) {
          desktopHint = t("msp.startWithRITM");
          mobileHint = t("msp.startWithRITM");
        }
      }
    } 
    else{
      if (hintCondition) {
        desktopHint = "Ticket number should start with `MSP`: e.g. MSP123";
        mobileHint = "Ticket number should start with `MSP`: e.g. MSP123";
        } else {
        if (
          inputValue.trim().length <= 3 ||
          !inputValue.trim().toLowerCase().startsWith("msp")
        ) {
          desktopHint = "Ticket number should start with `MSP`: e.g. MSP123";
          mobileHint = "Ticket number should start with `MSP`: e.g. MSP123";
        }
      }
  }
  }

  let searchContent = null;
  if (dropdownSelection) {
    if (showHint) {
      searchContent = (
        <SearchContent>
          <SearchContentItem>
            <MobileSearchHint>{mobileHint}</MobileSearchHint>
            <DesktopSearchHint>{desktopHint}</DesktopSearchHint>
          </SearchContentItem>
        </SearchContent>
      );
    } else if (showSearchResults) {
      searchContent = (
        <SearchContent>
          {props.loadingSearchResults ? (
            <Spinner />
          ) : props.searchResults && props.searchResults.length > 0 ? (
            <>
              <TopTenRecordsInfo>{t("msp.globalSearchRecordsMsg")}</TopTenRecordsInfo>
              {props.searchResults.map((searchItem) => (
                <SearchContentItemInfo
                  key={searchItem.id}
                  tabIndex="0"
                  onClick={() => showSelectedRecord(searchItem.value)}
                  onKeyPress={(e) =>
                    e.key === "Enter" && showSelectedRecord(searchItem.value)
                  }
                >
                  {searchItem.value}
                </SearchContentItemInfo>
              ))}
            </>
          ) : (
            <NoRecords>{t("msp.noSearchResult")}</NoRecords>
          )}
        </SearchContent>
      );
    }
  }
  return (
    <>
      {/* to only show loader if global search is active */}
      {props.show && loadingData ? (
        <StyledOverlay>
          <Loader />
        </StyledOverlay>
      ) : null}
      <GlobalSearchStyled
        tabIndex="-1"
        show={props.show}
        as="form"
        onSubmit={handleSubmit}
        resetGlobalSearch={() => resetControls()}
      >
        <SearchInputWrapper tabIndex="-1">
          <SearchOptions>
            <Select
              onChangeHandler={handleDropdown}
              options={dropdownOptions}
              value={dropdownSelection}
              customStyle={customStyle}
            />
          </SearchOptions>
          <SearchInput>
            <Input
              name="globalSearchInput"
              id="globalSearchInput"
              placeholder={`${t("msp.search")}...`}
              disabled={!parseInt(dropdownSelection)}
              onChangeHandler={handleInput}
              value={inputValue}
              aria-label={t("msp.search")}
              onFocus={handleInputFocus}
            />
          </SearchInput>
          {searchContent}
        </SearchInputWrapper>

        <MobileIcon
          sm
          type="submit"
          disabled={
            !parseInt(dropdownSelection) || inputValue === "" || loadingData
          }
        >
          {loadingData ? (
            <Spinner style={{ height: "1rem", width: "1rem" }} />
          ) : (
            t("msp.search")
          )}
        </MobileIcon>
        <DesktopIcon onClick={resetControls} aria-label="crossButton">
          <Cross />
        </DesktopIcon>
      </GlobalSearchStyled>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isGlobalSearchActive: selectIsGlobalSearchActive(),
  loadingInvoiceDetails: selectInvoiceDetailsFetchStatus(),
  loadingSubmissionDetails: selectInvoiceFetching(),
  loadingSupportRequestDetails: selectHelpdeskDetailsByIdListStatus(),
  searchResults: selectGlobalSearchResults(),
  loadingSearchResults: selectGlobalSearchStatus(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceNowQueryByID: (id, callback) => {
      dispatch(initGetServiceNowQueryByID(id, callback));  
    },
    showHelpdeskDetailsById: (id, callback, globalSearch) => {
      dispatch(fetchTLogCase(id, callback, globalSearch));
    },
    showInvoiceDetails: (payload, globalSearch, callback) => {
      dispatch(initFetchInvoiceDetails(payload, globalSearch, callback));
    },

    globalSearchActiveStatus: (bool) => {
      dispatch(isGlobalSearchActive(bool));
    },
    initGlobalSearch: (payload) => {
      dispatch(initGlobalSearch(payload));
    },
    resetGlobalSearch: () => {
      dispatch(resetGlobalSearch());
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(GlobalSearch);
