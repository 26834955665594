import "react-pdf/dist/esm/Page/TextLayer.css";
import "./style.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import { pure } from "recompose";
import { ErrorText, Spinner } from "components/index";
import PdfControls from "./pdfControls";
import PDFViewerWrapper from "./style";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = (props) => {
  const { t } = useTranslation();
  const [pdf, setPdf] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scaleBy, setScale] = useState(1);

  useEffect(() => {
    const data = props.data;

    if (data && !props.isBlob) {
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      setPdf(new Blob([byteArray], { type: "application/pdf" }));
    } else {
      setPdf(data);
    }
  }, [props.isBlob, props.data]);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    if (pageNumber > numPages) {
      setPageNumber(numPages);
    }
    if (props.onLoadSuccess) props.onLoadSuccess(numPages);
  };
  const pageChange = (e, isPrev = false) => {
    const newPage = isPrev ? pageNumber - 1 : pageNumber + 1;
    setPageNumber(newPage);
    if (props.onPageChange) props.onPageChange(newPage);
  };
  const gotoPage = (e) => {
    const { value } = e.target;
    if (value <= numPages) {
      setPageNumber(Number(value));
      if (props.onPageChange) props.onPageChange(value);
    }
  };
  const scale = (value, zoomOut) => {
    zoomOut ? setScale(scaleBy - value) : setScale(scaleBy + value);
  };

  return props.loading ? (
    <Spinner />
  ) : (
    <>
      <PDFViewerWrapper id={props.id}>
        {pdf && pdf.size > 0 ? (
          <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber || 1} scale={scaleBy} />
          </Document>
        ) : (
          <ErrorText>{t("msp.noData")}</ErrorText>
        )}
      </PDFViewerWrapper>
      {numPages ? (
        <PdfControls
          currentPage={pageNumber}
          maxPage={numPages}
          prevPage={() => pageChange(null, true)}
          nextPage={() => pageChange(null)}
          gotoPage={gotoPage}
          onZoomIn={(value) => scale(value)}
          onZoomOut={(value) => scale(value, true)}
          onDownload={props.onDownload}
        />
      ) : null}
    </>
  );
};

export default pure(PdfViewer);
