import React, { useEffect, useState } from "react";
import { ErrorText } from "components/index";
import InputAddon from "components/InputAddon/index";
import { InputGroup } from "components/Inputs/LabeledInput/style";
import { Cross, SearchIcon } from "components/SVG/index";

import {
  InputAddOnStyled,
  InputControlStyled,
  LabeledControlStyled,
} from "./style";
import { CustomLabel } from "components/Label/index";
import { useTranslation } from "react-i18next";

const CompactLookup = (props) => {
  const { t } = useTranslation();
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    props.resetVisited && setVisited(false);
  }, [props.resetVisited]);

  const onInputChange = (e) => {
    let { value, maxLength, type } = e.target;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    const strOfVal = strippedHtml.toString();

    if (strOfVal.length > maxLength) {
      const validatedLength = strOfVal.substring(0, maxLength);
      e.target.value =
        type === "number" ? Number(validatedLength) : validatedLength;
    }
    props.onChangeHandler && props.onChangeHandler(e);
  };
  const handleOnBlur = (e) => {
    setVisited(true);
    let value = e.target.value;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;

    props.onBlurHandler && props.onBlurHandler(e);
  };
  const passedProps = {
    ...props,
    placeholder: props.placeholder
      ? `${t("msp.search")} ${props.placeholder}`
      : t("msp.search"),
  };
  delete passedProps.onBlurHandler;
  delete passedProps.onChangeHandler;
  delete passedProps.showError;
  const validValue =
    props.value !== "" && props.value !== undefined && props.value !== null;
  const validationCondition = validValue && !props.disabled;
  const { label = null, required, id, options = {} } = props;

  let displayValue = "";

  try {
    const { cols = [], data = [] } = options;

    if (cols.length > 1 && data.length) {
      const selector = cols[0].selector;
      const displayValueSelector = cols[1].selector;
      const isExist = data.find(
        (item) => item[selector].toString() === props.value.toString()
      );

      if (isExist) displayValue = isExist[displayValueSelector];
    }
  } catch (error) {
    console.log(error);
  }

  return (
    <LabeledControlStyled
      disabled={props.disabled}
      error={props.showError}
      visited={visited || validValue}
      icon={validationCondition}
      title={label}
    >
      {label ? (
        <CustomLabel required={required} htmlFor={id}>
          {label}
        </CustomLabel>
      ) : null}
      <InputGroup>
        <InputAddOnStyled
          onClick={props.onAddonClick}
          tabIndex="-1"
          disabled={props.disabled}
          title={label ? `${t("msp.search")} ${label}` : t("msp.search")}
          data-identifier={props.addOnIdentifier}
          id={`open-${passedProps.id}`}
          name={`open-${passedProps.name}`}
        >
          <SearchIcon />
        </InputAddOnStyled>
        <InputControlStyled
          {...passedProps}
          value={displayValue}
          error={props.showError}
          visited={visited || validValue}
          aria-label={label}
          onBlur={handleOnBlur}
          onChange={onInputChange}
          icon={validationCondition}
        />
        {validationCondition ? (
          <InputAddon
            onClick={props.onClear}
            tabIndex="-1"
            title="Clear"
            data-identifier={props.clearIdentifier}
            id={`clear-${passedProps.id}`}
            name={`clear-${passedProps.name}`}
          >
            <Cross />
          </InputAddon>
        ) : null}

        <ErrorText>{props.showError ? props.error : null}</ErrorText>
      </InputGroup>
    </LabeledControlStyled>
  );
};

export default CompactLookup;
