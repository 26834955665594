import {
  put,
  call,
  all,
  takeLatest,
  select,
  take,
  fork,
} from "redux-saga/effects";

import {
  fetchingSubmittedInvoice,
  setLookups,
  submittedInvoiceFetchFailed,
  submittedInvoiceFetchSuccess,
  setDuplicateInvoiceCheck,
  suppotingDocumentsFetching,
  suppotingDocumentsFetchComplete,
  suppotingDocumentsFetchFailed,
  setUploadProgress,
  supportingDocUploadCompleted,
  supportingDocUploadFailed,
  initSuppotingDocumentsFecth,
  setTolaranceValue,
  tolaranceValueFecthFailed,
  bulkSubmittedInvoiceFetchFailed,
  bulkSubmittedInvoiceFetchSuccess,
} from "../store/actions";

import {
  GET_LOOKUPS,
  INIT_CHECK_DUPLICATE_INVOICE,
  INIT_FETCH_BULK_SUBMITTED_INVOICE,
  INIT_FETCH_SUBMITTED_INVOICE,
  INIT_FETCH_SUPPORTING_DOCUMENTS,
  INIT_GET_TOLERANCE_VALUE,
  INIT_UPLOAD_SD,
  UPDATE_LINE_ITEMS,
} from "./actionTypes";

import axios from "../../../config/axiosCall";

import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";
import { selectInvoice, selectLookups } from "./selectors";
import { createUploader } from "helper/fileUploadWatcher";
import { buildNotification } from "config/notification";
import { store } from "react-notifications-component";
import { getInvoiceDetails } from "./services";
import { linePONumber } from "../hooks/useHasLinePONumber";

export default function* watchSubmittedDocuments() {
  yield all([
    takeLatest(INIT_FETCH_SUBMITTED_INVOICE, initGetSubmittedInvoice),
    takeLatest(INIT_FETCH_BULK_SUBMITTED_INVOICE, initGetBulkSubmittedInvoice),
    takeLatest(GET_LOOKUPS, getLookUpSaga),
    takeLatest(INIT_CHECK_DUPLICATE_INVOICE, initDuplicateValidationSaga),
    takeLatest(INIT_FETCH_SUPPORTING_DOCUMENTS, getSupportingAttachmentsSaga),
    takeLatest(INIT_UPLOAD_SD, initUploadSupportingAttachmentsSaga),
    takeLatest(INIT_GET_TOLERANCE_VALUE, getAbsToleranceValueSaga),
    takeLatest(UPDATE_LINE_ITEMS, updateLineItemsSaga),
  ]);
}

function* initGetSubmittedInvoice({ payload }) {
  try {
    yield put(fetchingSubmittedInvoice());

    const response = yield call(getInvoiceDetails, payload);
    const { poNumber = "", lineFields = [] } = response;

    const newLineFields = lineFields.map((line) => ({
      ...line,
      lineAttributes: line.lineAttributes.map((attr) => {
        let value = attr.attributeValue;
        if (attr.attributeName === linePONumber && !attr.attributeValue) {
          value = poNumber;
        }
        return {
          ...attr,
          attributeValue: value,
        };
      }),
    }));

    yield put(
      submittedInvoiceFetchSuccess({ ...response, lineFields: newLineFields })
    );
  } catch (error) {
    yield put(submittedInvoiceFetchFailed());
  }
}
function* initGetBulkSubmittedInvoice({ payload }) {
  const bulkUploadReferenceNumber = payload;
  const url = `/Submission/submissionByBulkReferenceNumber?ReferenceNumber=${bulkUploadReferenceNumber}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    let bulkInvoice = [];
    if (response.status === 200) {
      const urlParams = yield new URLSearchParams(window.location.search);
      const submissionID = yield urlParams.get("ref");
      bulkInvoice = yield response.data;
      if (submissionID) {
        bulkInvoice = [];
        let firstInvoice = yield response.data.find(
          (item) => item.submissionID.toString() === submissionID
        );

        let restInvoice = yield response.data.filter(
          (item) => item.submissionID.toString() !== submissionID
        );
        bulkInvoice = yield bulkInvoice.concat(firstInvoice);
        bulkInvoice = yield bulkInvoice.concat(restInvoice);
      }

      yield put(bulkSubmittedInvoiceFetchSuccess(bulkInvoice));
    }
  } catch (error) {
    yield put(bulkSubmittedInvoiceFetchFailed());
    console.log(error);
  }
}

function* getLookUpSaga({ payload }) {
  try {
    const requestDetails = { ...getRequestDetails };
    let response = null;
    const { lookups = [], taxRates = {} } = payload;
    let existingLookups = yield select(selectLookups());
    existingLookups = yield existingLookups.toJS();
    if (Object.keys(taxRates).length) {
      const { countryID, attributeID } = yield taxRates;
      const url =
        yield `/PurchaseOrder/getTaxCodeDetailsByCountryCode?countryID=${countryID}`;
      response = yield call(axios, url, requestDetails);
      const { data } = yield response;

      let cols = yield data.fieldLabels;
      const selector = yield cols.find((col) => col.FieldName === "taxCode");
      const displayName = yield cols.find((col) => col.FieldName === "taxRate");
      const restCols = yield cols.filter(
        (col) => col.FieldName !== "taxRate" && col.FieldName !== "taxCode"
      );

      cols = [].concat(selector).concat(displayName).concat(restCols);
      const newCols = cols.map((item) => {
        return {
          selector: item.FieldName,
          name: item.Label,
        };
      });

      existingLookups = yield {
        ...existingLookups,
        [attributeID]: {
          cols: newCols,
          data: data.taxDetails,
        },
      };
    }

    for (let attributeID of lookups) {
      const url = yield `common/LookUpByID?attributeID=${attributeID}`;

      response = yield call(axios, url, requestDetails);
      if (response) {
        const { data } = response;

        const cols = Object.keys(data[0]).map((col, index) => {
          return {
            selector: col,
            name: col,
            omit: index === 0,
          };
        });

        existingLookups = yield {
          ...existingLookups,
          [attributeID]: {
            cols,
            data,
          },
        };
      }
    }
    yield put(setLookups(existingLookups));
  } catch (error) {
    console.log(error);
  }
}

function* initDuplicateValidationSaga(action) {
  const url = yield "/EInvoice/ValidateInvoices";
  const requestDetails = yield { ...postRequestDetails };
  requestDetails.data = yield action.payload;
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      const { data } = yield response;

      yield put(setDuplicateInvoiceCheck(!data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getSupportingAttachmentsSaga({ payload: submissionID }) {
  try {
    if (!submissionID) return;
    yield put(suppotingDocumentsFetching());
    const url = `/Submission/GetSupportingDocs?submissionID=${submissionID}`;
    const requestDetails = { ...getRequestDetails };
    const response = yield call(axios, url, requestDetails);
    if (response) {
      let data = [];
      if (response.data) data = response.data;

      yield put(suppotingDocumentsFetchComplete(data));
    }
  } catch (error) {
    yield put(suppotingDocumentsFetchFailed());
  }
}
function* watchProgress({ chan }) {
  while (true) {
    const data = yield take(chan);
    const { loaded, total } = data;
    let progress = (loaded * 100) / total;
    yield put(setUploadProgress(progress > 90 ? 90 : progress));
  }
}
function* initUploadSupportingAttachmentsSaga({ payload }) {
  try {
    const apiURL = "/Submission/AddSupportingDoc";
    const [uploadPromise, chan] = yield createUploader({
      payload,
      url: apiURL,
    });
    let progression = 0;

    yield put(setUploadProgress(progression));
    yield fork(watchProgress, { chan });
    const res = yield call(() => uploadPromise);
    progression = yield 100;
    if (res.status === 200) {
      yield put(setUploadProgress(progression));
      yield put(supportingDocUploadCompleted());
      yield put(initSuppotingDocumentsFecth(payload[0].submissionId));
      const notification = buildNotification({
        message: "msp.recordInsertSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (err) {
    console.log(err);
    yield put(supportingDocUploadFailed());
  }
}

function* getAbsToleranceValueSaga({ payload }) {
  try {
    const { countryId, currencyId } = payload;
    if (!countryId && !currencyId) return;
    const url = `Submission/GetAbsoluteToleranceValue?countryId=${countryId}&currencyId=${currencyId}`;
    const requestDetails = { ...getRequestDetails };
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200) yield put(setTolaranceValue(response.data));
  } catch (error) {
    yield put(tolaranceValueFecthFailed());
  }
}
function* updateLineItemsSaga({ payload }) {
  try {
    const invoice = yield select(selectInvoice());
    const { lineFields = [] } = invoice.toJS();
    const newLines = lineFields.map((field) => {
      return {
        ...field,
        lineAttributes: field.lineAttributes.map((line) => {
          let value = line.attributeValue;
          if (line.attributeName === "CreditDebit") {
            value = line.attributeValue ? line.attributeValue : payload;
          }
          return {
            ...line,
            attributeValue: value,
          };
        }),
      };
    });
    yield put(
      submittedInvoiceFetchSuccess({ ...invoice.toJS(), lineFields: newLines })
    );
  } catch (error) {
    yield put(tolaranceValueFecthFailed());
  }
}
