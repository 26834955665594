import React from "react";
import SvgIcon from "./style";

const ErrorCircle = () => {
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 18 18"
      xmlSpace="preserve"
      focusable="false"
    >
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="var(--error-clr)"
            d="M9,1.4c4.2,0,7.6,3.4,7.6,7.6
			c0,4.2-3.4,7.6-7.6,7.6c-4.2,0-7.6-3.4-7.6-7.6C1.4,4.8,4.8,1.4,9,1.4 M9,0.3C4.2,0.3,0.3,4.2,0.3,9c0,4.8,3.9,8.7,8.7,8.7
			s8.7-3.9,8.7-8.7C17.7,4.2,13.8,0.3,9,0.3z M8.6,4.5h0.8c0.2,0,0.4,0.2,0.4,0.4l-0.2,5.9c0,0.2-0.2,0.4-0.4,0.4H8.8
			c-0.2,0-0.4-0.2-0.4-0.4L8.2,4.9C8.2,4.7,8.4,4.5,8.6,4.5z M9,12c-0.5,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S9.5,12,9,12z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default ErrorCircle;
