import React from "react";
import { useTranslation } from "react-i18next";
import { privacyAdditionalContent, privacyStatementContent } from "./content";
import PrivacyPolicy, { AdditionalContent } from "./style";

const PrivacyStatement = (props) => {
  const { t } = useTranslation();
  return (
    <PrivacyPolicy>
      <p>
        <i>{t(privacyStatementContent.body)}</i>
      </p>

      {privacyAdditionalContent.map((item, i) => {
        return (
          <AdditionalContent key={i}>
            <h1>{t(item.header)}</h1>
            <p>{t(item.content.body)}</p>
          </AdditionalContent>
        );
      })}
    </PrivacyPolicy>
  );
};

export default PrivacyStatement;
