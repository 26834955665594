import { Rows } from "components/index";
import styled from "styled-components";

export const PrimaryContent = styled(Rows)`
  gap: 0.5em;
`;
export const AdditionalInformation = styled.ul``;

const TermsCondition = styled(Rows)`
  line-height: 1.3;
  padding: 0 1em 1em 1em;
  gap: 1em;
  overflow: auto;

  h1 {
    font-size: clamp(var(--fs-14), 3vw, var(--fs-18));
    /* var(--fs-32); */
  }
  h2 {
    font-size: clamp(var(--fs-12), 3vw, var(--fs-14));
  }
  p {
    font-size: clamp(var(--fs-12), 3vw, var(--fs-16));
  }
  ${PrimaryContent} {
  }
  ${AdditionalInformation} {
    padding: 0 1em;

    li {
      list-style: square;
      + li {
        margin-top: 1em;
      }
    }
  }
`;

export default TermsCondition;
