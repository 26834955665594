import React, { useEffect, useState } from "react";
import FloatingControl from "./style";
import Label from "components/Label";
import InputControl from "../Input/style";
import PropTypes from "prop-types";
import InputAddon from "components/InputAddon/index";
import { ErrorText } from "components/index";

const FloatingInput = (props) => {
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    props.resetVisited && setVisited(false);
  }, [props.resetVisited]);

  const onInputChange = (e) => {
    let { value, maxLength, type } = e.target;

    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    const strOfVal = strippedHtml.toString();

    if (strOfVal.length > maxLength) {
      const validatedLength = strOfVal.substring(0, maxLength);
      e.target.value =
        type === "number" ? Number(validatedLength) : validatedLength;
    }

    props.onChangeHandler && props.onChangeHandler(e);
  };
  const handleOnBlur = (e) => {
    setVisited(true);
    let value = e.target.value;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;

    props.onBlurHandler && props.onBlurHandler(e);
  };
  const passedProps = { ...props };
  delete passedProps.onBlurHandler;
  delete passedProps.onChangeHandler;
  delete passedProps.showError;

  return (
    <FloatingControl
      disabled={props.disabled}
      error={props.showError}
      visited={visited || props.value}
      icon={props.icon}
      title={props.placeholder}
    >
      <InputControl
        {...passedProps}
        error={props.showError}
        visited={visited || props.value}
        placeholder="&nbsp;"
        aria-label={props.placeholder}
        onBlur={handleOnBlur}
        onChange={onInputChange}
        icon={props.icon}
      />
      <Label htmlFor={props.id} required={props.required}>
        {props.placeholder}
      </Label>
      {props.icon ? (
        <InputAddon
          onClick={props.onAddonClick}
          tabIndex="0"
          disabled={props.disabled}
          aria-label={props.ariaLabel || "input-icon"}
        >
          {props.icon}
        </InputAddon>
      ) : null}
      <ErrorText>{props.showError ? props.error : null}</ErrorText>
    </FloatingControl>
  );
};
FloatingInput.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  id: PropTypes.any.isRequired,
  onAddonClick: PropTypes.func,
  onBlurHandler: PropTypes.func,
  onChangeHandler: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  resetVisited: PropTypes.bool,
  showError: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.string,
};
export default FloatingInput;
