import { Rows } from "components/index";
import styled from "styled-components";
import PDFViewerWrapper from "../PdfViewer/style";

export const ImgPrevDiv = styled(Rows)`
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
  visibility: ${(props) => (props.loading ? `none` : `visible`)};
`;
export const RubberBandDiv = styled.div`
  overflow: auto;
`;
export const ImageViewerWrapper = styled(PDFViewerWrapper)`
  overflow: hidden;
  cursor: pointer;
`;
