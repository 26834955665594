import styled from "styled-components";
import { IconWrapper } from "components/index";
import { dropdown, dropdownContent } from "globalStyles";

const DropdownStyled = styled(IconWrapper)`
  ${dropdown}

  img,
  svg {
    width: 1em;
    margin-top: 0.1em;
    transition: all 400ms ease-in-out;
  }
  @media (min-width: 62em) {
    :hover,
    :focus {
      border-radius: 0;
      background-color: transparent;
    }
  }
`;

export const DropdownContent = styled.ul`
  ${dropdownContent}

  li {
    opacity: 0;
    transition: opacity 100ms ease-in-out;
  }
`;

export default DropdownStyled;
