import React from "react";
import SvgIcon from "./style";

const EyeClose = (props) => {
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 14"
    >
      <g>
        <g transform="translate(0.000000, -1.000000)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#637381"
            d="M15.8,13.4L0.9,1.7C0.8,1.6,0.7,1.6,0.6,1.6S0.4,1.7,0.4,1.8
			L0.1,2.1C0,2.2,0,2.3,0,2.4c0,0.1,0.1,0.2,0.1,0.3l14.9,11.7c0.1,0.1,0.2,0.1,0.3,0.1s0.2-0.1,0.3-0.1l0.2-0.3
			c0.1-0.1,0.1-0.2,0.1-0.3C16,13.5,15.9,13.4,15.8,13.4z M7.4,5.3l3.4,2.6C10.7,6.4,9.5,5.2,8,5.2C7.8,5.2,7.6,5.2,7.4,5.3z
			 M8.6,10.7L5.2,8.1c0.1,1.5,1.3,2.7,2.8,2.7C8.2,10.8,8.4,10.8,8.6,10.7L8.6,10.7z M8,4.4c2.5,0,4.7,1.4,5.9,3.6
			c-0.3,0.5-0.7,1.1-1.1,1.5l0.9,0.7c0.5-0.6,1-1.2,1.3-1.9c0.1-0.2,0.1-0.5,0-0.7C13.7,5,11.1,3.2,8,3.2c-0.9,0-1.8,0.2-2.6,0.5
			l1.2,0.9C7,4.5,7.5,4.4,8,4.4z M8,11.6c-2.5,0-4.7-1.4-5.9-3.6c0.3-0.5,0.7-1.1,1.1-1.5L2.2,5.8C1.7,6.3,1.3,7,0.9,7.6
			c-0.1,0.2-0.1,0.5,0,0.7c1.4,2.6,4,4.4,7.1,4.4c0.9,0,1.8-0.2,2.6-0.5l-1.2-0.9C9,11.5,8.5,11.6,8,11.6z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default EyeClose;
