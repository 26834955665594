import { fromJS } from "immutable";

import {
  FETCH_NOTIFICATION_DATA,
  FETCHING_NOTIFICATION_DATA,
  FETCHING_NOTIFICATION_COMPLETED,
  FETCHING_NOTIFICATION_PREFERENCES,
  FETCH_NOTIFICATION_PREFERENCES_COMPLETED,
  FETCH_NOTIFICATION_PREFERENCES,
  FETCH_ARCHIVE_DATA,
  FETCHING_ARCHIVE_DATA,
  FETCH_ARCHIVE_DATA_COMPLETED,
} from "./actionTypes";

export const initialState = fromJS({
  notificationData: [],
  notificationDataFetchStatus: false,
  notificationPreferencesFetchStatus: false,
  notificationPreferences: [],
  archiveData: [],
  archivedDataFetchStatus: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_DATA: {
      return state.set("notificationData", action.data);
    }
    case FETCHING_NOTIFICATION_DATA: {
      return state.set("notificationDataFetchStatus", true);
    }
    case FETCHING_NOTIFICATION_COMPLETED: {
      return state.set("notificationDataFetchStatus", false);
    }
    case FETCHING_NOTIFICATION_PREFERENCES: {
      return state.set("notificationPreferencesFetchStatus", true);
    }
    case FETCH_NOTIFICATION_PREFERENCES: {
      return state.set("notificationPreferences", action.data);
    }
    case FETCH_NOTIFICATION_PREFERENCES_COMPLETED: {
      return state.set("notificationPreferencesFetchStatus", false);
    }
    case FETCH_ARCHIVE_DATA: {
      return state.set("archiveData", action.data);
    }
    case FETCHING_ARCHIVE_DATA: {
      return state.set("archivedDataFetchStatus", true);
    }
    case FETCH_ARCHIVE_DATA_COMPLETED: {
      return state.set("archivedDataFetchStatus", false);
    }

    default:
      return state;
  }
};
export default reducer;
