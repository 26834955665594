import { FloatingInput } from "components/index";
import React, { useRef, useState } from "react";
import { useEffect } from "react";

import { ChromePicker } from "react-color";
import { usePopper } from "react-popper";

import convertToHex from "hsl-to-hex";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ColorPickerWrapper, PickerBlock } from "./style";

const ColorPicker = (props) => {
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState({});
  const [hex, setHex] = useState("");
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { t } = useTranslation();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: props.placement || "auto",
  });
  useEffect(() => {
    const value = props.value && {
      h: props.value.hue,
      s: props.value.sat / 100,
      l: props.value.light / 100,
      a: props.value.alpha,
    };
    props.value &&
      setHex(convertToHex(props.value.hue, props.value.sat, props.value.light));
    setColor(value);
  }, [props.value]);
  const handleChangeComplete = (color) => {
    const { hsl } = color;
    setColor(hsl);
    props.onColorChange &&
      props.onColorChange({
        target: {
          value: {
            hue: hsl.h,
            sat: hsl.s * 100,
            light: hsl.l * 100,
            alpha: hsl.a,
          },
          id: props.id,
          name: props.name,
        },
      });
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (wrapperRef && !wrapperRef.current.contains(event.target)) {
          setShowPicker(false);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <ColorPickerWrapper>
      <FloatingInput
        id={props.id}
        name={props.name}
        placeholder={props.label || t("msp.color")}
        required={props.required}
        onChangeHandler={() => setShowPicker(true)}
        onBlurHandler={() => setShowPicker(true)}
        onKeyPress={() => setShowPicker(true)}
        onFocus={() => setShowPicker(true)}
        value={hex && hex.toUpperCase()}
        icon={
          color && (
            <PickerBlock
              onClick={() => setShowPicker(true)}
              ref={setReferenceElement}
              bgColor={`hsla(${color.h},${color.s * 100}%,${color.l * 100}%,${
                color.a
              })`}
            />
          )
        }
      />
      <div
        ref={setPopperElement}
        style={{
          ...styles.popper,
          zIndex: showPicker ? 2 : -1,
          opacity: showPicker ? 1 : 0,
        }}
        {...attributes.popper}
      >
        <div ref={wrapperRef}>
          <ChromePicker color={color} onChange={handleChangeComplete} />
        </div>
      </div>
    </ColorPickerWrapper>
  );
};

ColorPicker.propTypes = {
  id: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
  label: PropTypes.any,
  value: PropTypes.any.isRequired,
  icon: PropTypes.any.isRequired,
  placement: PropTypes.any,
  onColorChange: PropTypes.func.isRequired,
};

export default ColorPicker;
