export const GET_ANNOUNCEMENT_DETAILS = "GET_ANNOUNCEMENT_DETAILS";
export const UPDATE_ANNOUNCEMENT_DETAILS = "UPDATE_ANNOUNCEMENT_DETAILS";
export const INIT_NEWS_AND_EVENTS_EDIT = "INIT_NEWS_AND_EVENTS_EDIT";
export const INIT_NEWS_AND_EVENTS_EDIT_FETCHING= "INIT_NEWS_AND_EVENTS_EDIT_FETCHING";
export const SET_NEWS_AND_EVENTS_EDIT_DATA = "SET_NEWS_AND_EVENTS_EDIT_DATA";
export const EDIT_NEWS_AND_EVENTS_DATA_FETCHED =
  "EDIT_NEWS_AND_EVENTS_DATA_FETCHED";
export const INIT_NEWSANDEVENT_DELETE = "INIT_NEWSANDEVENT_DELETE";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAILED = "DELETE_FAILED";
export const SAVE_ANNOUNCEMENT_DATA = "SAVE_ANNOUNCEMENT_DATA";
export const SAVE_ANNOUNCEMENT_DATA_COMPLETED = "SAVE_ANNOUNCEMENT_DATA_COMPLETED";
export const INIT_ITEMS_FETCH = "INIT_ITEMS_FETCH";

export const BACK_TO_LIST = "BACK_TO_LIST";
export const FORM_SUBMISSION_STATUS = "FORM_SUBMISSION_STATUS";
export const RESET_FORM_SUBMISSION_STATUS = "RESET_FORM_SUBMISSION_STATUS";
export const INIT_CATEGORY_LIST_FETCH = "INIT_CATEGORY_LIST_FETCH";
export const FETCHING_CATEGORY_LIST = "FETCHING_CATEGORY_LIST";
export const FETCH_CATEGORY_LIST = "FETCH_CATEGORY_LIST";
export const CATEGORY_LIST_FETCH_COMPLETE = "CATEGORY_LIST_FETCH_COMPLETE";
export const INIT_IMPORTANCE_LIST_FETCH = "INIT_IMPORTANCE_LIST_FETCH";
export const FETCHING_IMPORTANCE_LIST = "FETCHING_IMPORTANCE_LIST";
export const FETCH_IMPORTANCE_LIST = "FETCH_IMPORTANCE_LIST";
export const IMPORTANCE_LIST_FETCH_COMPLETE = "IMPORTANCE_LIST_FETCH_COMPLETE";
