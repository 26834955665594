import PropTypes from "prop-types";
import React, { useState } from "react";
import { store } from "react-notifications-component";
import { useTranslation } from "react-i18next";

import { buildNotification } from "config/notification";
import { storage } from "config/storage";
import { convertToCSV } from "helper";
import { ExportFile } from "components/SVG/";
import { GDPRMessage } from "components/";
import { ExportBtn } from "./style";

const Export = (props) => {
  const { t } = useTranslation();
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const [showGDPR, setShowGDPR] = useState(false);

  const exportToCsv = () => {
    const { listData, columnHeaders } = props;
    if (columnHeaders && listData && listData.length > 0) {
      const link = document.createElement("a");
      let csv = convertToCSV(listData, columnHeaders);
      if (csv == null) return;
      const filename = `${props.fileName}.csv` || "data.csv";
      const universalBOM = "\uFEFF";
      csv = universalBOM + csv;
      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }
      const encodedCSV = encodeURI(csv);
      const sanitizedCSV = encodedCSV.replaceAll("#", "%23");
      link.setAttribute("href", sanitizedCSV);
      link.setAttribute("download", filename);
      link.click();
    } else {
      const notification = buildNotification({
        message: props.errMsg || "msp.noDataToExport",
        type: "warning",
      });
      store.addNotification({
        ...notification,
      });
    }
  };
  return (
    <>
      <ExportBtn sm onClick={() => setShowGDPR(true)} disabled={props.disabled}>
        <span>
          <ExportFile />
          {t("msp.export")}
        </span>
      </ExportBtn>
      <GDPRMessage
        appGDPR
        show={showGDPR}
        gdprTitle={sessionDetails.applicationGDPRLabel}
        gdprMessage={sessionDetails.applicationGDPRMessage}
        confirmGDPR={() => exportToCsv()}
        onClose={() => setShowGDPR(false)}
      />
    </>
  );
};

Export.propTypes = {
  columnHeaders: PropTypes.array.isRequired,
  disabled: PropTypes.any,
  errMsg: PropTypes.string,
  fileName: PropTypes.any,
  listData: PropTypes.array.isRequired,
};

export default Export;
