import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Checkbox, FloatingInput } from "components/index";
import { OptionsWrapper, ControlWrapper, Divider } from "./style";
import { listSearch } from "../../../helper/index";
import { Arrow } from "components/SVG";

const FloatingMultiSelect = (props) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [visited, setVisited] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [search, setSearch] = useState("");
  const options =
    props && props.options && props.options.length > 0 ? props.options : [];

  const updatedOptions = options
    .map((item) =>
      selectedIds.includes(item.value)
        ? {
            ...item,
            selected: true,
          }
        : {
            ...item,
            selected: false,
          }
    )
    .filter((item) => {
      return listSearch(item, search); //ask how to omit searching from id and selected
    })
    .sort((a, b) => Number(b.selected) - Number(a.selected));

  const idxOfLastOption = updatedOptions
    .map((elem) => elem.selected)
    .lastIndexOf(true);

  useEffect(() => {
    //on load if there is any pre selectedvalue present
    setSelectedOptions(
      props.value && props.value.length > 0 ? props.value : []
    );
    setSelectedIds(
      props.value && props.value.length > 0
        ? props.value.map((item) => item.value)
        : []
    );
  }, [props]);

  const handleOnChange = (item) => {
    let cpySelectedIds = [...selectedIds];
    let cpySelectedOptions = [...selectedOptions];
    const findIndex = cpySelectedOptions.findIndex(
      (option) => option.value === item.value
    );
    if (findIndex === -1) {
      cpySelectedOptions.push(item);
      cpySelectedIds.push(item.value);
    } else {
      cpySelectedOptions.splice(findIndex, 1);
      cpySelectedIds.splice(findIndex, 1);
    }
    setSelectedIds(cpySelectedIds);
    setSelectedOptions(cpySelectedOptions);
    setSearch("");
    props.onChangeHandler &&
      props.onChangeHandler({
        type: "change",
        target: {
          value: cpySelectedOptions,
          id: props.id,
          name: props.name,
          required: props.required,
          options: options,
        },
      });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setShowDropdown(true);
  };

  const useOutsideHandler = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false);
          setVisited(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideHandler(wrapperRef);
  return (
    <>
      <ControlWrapper ref={wrapperRef}>
        <FloatingInput
          placeholder={props.placeholder ? props.placeholder : t("msp.select")}
          id={props.id}
          type="text"
          required={props.required}
          error={props.error}
          showError={props.showError}
          value={
            props.disabled
              ? props.value && props.value.length
                ? props.value.map((item) => item.label).join(",")
                : ""
              : search
              ? search
              : selectedOptions.length < 1
              ? ""
              : selectedOptions.length === 1
              ? selectedOptions[0].label
              : `${selectedOptions.length} ${t("msp.selected")}`
          }
          icon={<Arrow />}
          onChangeHandler={handleSearch}
          onAddonClick={() => setShowDropdown(!showDropdown)}
          disabled={props.disabled || false}
          onClick={() => {
            setShowDropdown(!showDropdown);
            setVisited(!visited);
          }}
        />
        {showDropdown && !props.disabled ? (
          <OptionsWrapper>
            <ul>
              {updatedOptions && updatedOptions.length > 0 ? (
                updatedOptions.map((item, i) => (
                  <>
                    <li key={item.id || i}>
                      <Checkbox
                        id={item.id || i}
                        label={item.label}
                        checked={selectedIds.includes(item.value)}
                        handleToggleCheckbox={() => handleOnChange(item)}
                      />
                    </li>
                    <Divider
                      style={{
                        display:
                          i !== updatedOptions.length - 1 &&
                          idxOfLastOption !== -1 &&
                          idxOfLastOption === i
                            ? "block"
                            : "none",
                      }}
                    />
                  </>
                ))
              ) : (
                <span> {t("msp.noOptions")} </span>
              )}
            </ul>
          </OptionsWrapper>
        ) : null}
      </ControlWrapper>
    </>
  );
};

FloatingMultiSelect.propTypes = {
  error: PropTypes.any,
  id: PropTypes.any,
  name: PropTypes.any,
  onChangeHandler: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.any,
  required: PropTypes.any,
  value: PropTypes.array,
};

export default FloatingMultiSelect;
