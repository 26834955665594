import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as workerTimers from "worker-timers";

import { userLogoutTimeInSecs } from "config";
import { buildNotification } from "config/notification";
import {
  aboutToLogout,
  initLogout,
  timerResetSuccess,
} from "container/Authentication/store/actions";
import { selectAboutToLogout } from "container/Authentication/store/selectors";
import LogoutMessage from "../Messages/LogoutMessage";
import { Dimmer } from "./style";
import { getOS } from "helper/getOS";

let handleOpacity = null;
let handleIdleTimer = null;
const OS = getOS();
let setTimeoutFunc = workerTimers.setTimeout;
let clearTimeoutFunc = workerTimers.clearTimeout;
let setIntervalFunc = workerTimers.setInterval;
let clearIntervalFunc = workerTimers.clearInterval;
if (OS === "iOS" || OS === "MacOS") {
  setTimeoutFunc = setTimeout;
  clearTimeoutFunc = clearTimeout;
  setIntervalFunc = setInterval;
  clearIntervalFunc = clearInterval;
}

const Timer = (props) => {
  const [idleTime, setIdleTime] = useState(120);
  const [opacity, setOpacity] = useState(0);
  const [sessionTime, setSessionTime] = useState(-1);

  useEffect(() => {
    setSessionTime(userLogoutTimeInSecs);
    const handleIdleUser = () => {
      setIdleTime(120);
      setOpacity(0);
      setSessionTime(userLogoutTimeInSecs);
    };
    const clearDimmer = () => {
      setOpacity(0);
    };
    document.addEventListener("click", handleIdleUser);
    document.addEventListener("keypress", handleIdleUser);
    document.addEventListener("mouseover", clearDimmer);
    handleIdleUser();
    return () => {
      document.removeEventListener("click", handleIdleUser);
      document.removeEventListener("keypress", handleIdleUser);
      document.removeEventListener("mouseover", clearDimmer);
    };
  }, []);

  useEffect(() => {
    if (handleOpacity) {
      handleOpacity = clearTimeoutFunc(handleOpacity);
    }
    if (handleIdleTimer) {
      handleIdleTimer = clearTimeoutFunc(handleIdleTimer);
    }
    if (idleTime > 0) {
      handleIdleTimer = setTimeoutFunc(() => {
        setIdleTime(idleTime - 1);
      }, 1000);
    }
    if (sessionTime === 0) {
      props.logout(() => {
        const clientDetails =
          JSON.parse(sessionStorage.getItem("clientDetails")) || {};
        const sessionDetails =
          JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
        if (!sessionDetails.isAuthenticated) {
          window.location.href = "/?" + clientDetails.name;
        }
        const notification = buildNotification({
          message: "msp.loggedOffDueToInactivity",
          type: "success",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 5000,
          },
        });
      });
    }
    if (idleTime === 0) {
      if (opacity < 99) {
        handleOpacity = setTimeoutFunc(() => {
          setOpacity(opacity + 1);
        }, 1000);
      }
      if (sessionTime > -1) {
        if (sessionTime === 120) {
          props.aboutToLogout();
        }
        const handleSessionTime = setIntervalFunc(() => {
          setSessionTime(sessionTime - 1);
        }, 1000);
        return () => clearIntervalFunc(handleSessionTime);
      }
    }
  }, [opacity, idleTime, sessionTime, props]);

  return (
    <>
      {props.showLogoutPopup ? (
        <LogoutMessage show={true} onClose={props.timerResetSuccess} />
      ) : null}
      {idleTime === 0 ? (
        <Dimmer opacity={String(opacity).padStart(2, "0")}></Dimmer>
      ) : null}
    </>
  );
};

Timer.propTypes = {
  aboutToLogout: PropTypes.func,
  logout: PropTypes.func,
  showLogoutPopup: PropTypes.any,
  timerResetSuccess: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  showLogoutPopup: selectAboutToLogout(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    logout: (callback) => {
      dispatch(initLogout(callback));
    },
    aboutToLogout: () => {
      dispatch(aboutToLogout());
    },
    timerResetSuccess: () => {
      dispatch(timerResetSuccess());
    },
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Timer);
