import PropTypes from "prop-types";
import { createRef, useEffect, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import moment, { isMoment } from "moment";
import { store } from "react-notifications-component";
import { useTranslation } from "react-i18next";

import DateInput from "./DateInputs";
import { storage } from "config/storage";
import { buildNotification } from "config/notification";

import { getLocale, jsonPrs } from "helper/index";
import { getTranslationFile } from "./config.js";

let timerId;

export default function Datepicker(props) {
  let duplicateNotification = null;

  const sessionDetails = jsonPrs(storage.getItem("sessionDetails"));
  const { dateFormat, languageId } = sessionDetails || { languageId: "enGB" };

  const locale = getLocale(languageId);
  const translationFile = getTranslationFile[locale];

  const { t } = useTranslation();

  registerLocale(locale, {
    ...translationFile,
    options: { ...translationFile.options, weekStartsOn: 1 },
  });

  let minDate = null;
  if (!props.noMinDate) {
    if (props.minDate) {
      minDate = props.minDate;
    } else if (sessionDetails && sessionDetails.defaultMinDate) {
      minDate = sessionDetails.defaultMinDate;
    }
  }

  const ref = createRef();
  const [inputDate, setInputDate] = useState(null);
  const [selected, setSelected] = useState(moment());
  useEffect(() => {
    const dateValue =
      props.dateValue && !moment.isMoment(props.dateValue)
        ? moment(props.dateValue, dateFormat)
        : props.dateValue;
    setSelected(dateValue);
    if (dateValue && dateValue.isValid()) {
      setInputDate(dateValue.format(dateFormat));
    } else if (dateValue && !dateValue.isValid()) {
      setInputDate("");
    } else {
      setInputDate(dateValue);
    }
  }, [props.dateValue, dateFormat]);

  const handleCalendarOpen = () => {
    removeNavigationText();
  };
  const delayFunctionExecution = (funcRef, delay) => {
    timerId && clearTimeout(timerId);

    timerId = setTimeout(funcRef, delay);
  };
  const handleInputDateValidation = (value) => {
    const date = moment(value, dateFormat, true);

    if (value.length === 10) {
      if (date.isValid()) {
        if (props.noMinDate) {
          if (props.setDate)
            props.setDate({
              target: { id: props.id, name: props.name, value: moment(date) },
            });
        } else {
          let parsedMinDate = minDate;

          if (minDate) {
            if (isMoment(minDate)) {
              parsedMinDate = minDate;
            } else {
              parsedMinDate = moment(minDate);
            }
          }
          if (parsedMinDate && date.isSameOrAfter(parsedMinDate, "day")) {
            if (props.setDate)
              props.setDate({
                target: { id: props.id, name: props.name, value: moment(date) },
              });
            if (duplicateNotification)
              store.removeNotification(duplicateNotification);
            duplicateNotification = null;
          } else {
            if (parsedMinDate) {
              const notification = buildNotification({
                message: `${t("msp.dateShouldBeAfter")} ${parsedMinDate.format(
                  dateFormat
                )}`,
                type: "danger",
              });
              duplicateNotification = store.addNotification({
                ...notification,
                dismiss: {
                  duration: 5000,
                },
              });
            }

            setInputDate("");
            if (props.setDate)
              props.setDate({
                target: { id: props.id, name: props.name, value: "" },
              });
          }
        }
      } else {
        const notification = buildNotification({
          message: "msp.invalidDateFormat",
          type: "danger",
        });
        if (duplicateNotification) {
          store.removeNotification(duplicateNotification);
        }
        duplicateNotification = store.addNotification({
          ...notification,
          dismiss: {
            duration: 2000,
          },
        });
        setInputDate("");
        if (props.setDate)
          props.setDate({
            target: { id: props.id, name: props.name, value: "" },
          });
      }
    } else {
      const notification = buildNotification({
        message: "msp.invalidDate",
        type: "danger",
      });
      if (duplicateNotification) {
        store.removeNotification(duplicateNotification);
      }
      duplicateNotification = store.addNotification({
        ...notification,
        dismiss: {
          duration: 2000,
        },
      });
      setInputDate("");
      if (props.setDate)
        props.setDate({
          target: { id: props.id, name: props.name, value: "" },
        });
    }
  };
  const handleDateInputChange = (value) => {
    setInputDate(value);
    delayFunctionExecution(() => handleInputDateValidation(value), 1500);
  };
  const handleDatePickerDateChange = (date) => {
    setInputDate(moment(date).format(dateFormat));
    if (props.setDate)
      props.setDate({
        target: { id: props.id, name: props.name, value: moment(date) },
      });
    props.onClickOutside && props.onClickOutside();
  };
  const removeNavigationText = () => {
    const monthButtons = document.getElementsByClassName(
      "react-datepicker__navigation"
    );

    Array.from(monthButtons).forEach((el) => {
      el.innerHTML = "";
      el.ariaLabel = "";
      el.innerText = "";
    });
  };

  // const CalendarContainer = ({ children }) => {
  //   const el = document.getElementById("calendar-portal");
  //   return <Portal container={el}>{children}</Portal>;
  // };

  removeNavigationText();
  return (
    <ReactDatePicker
      onClickOutside={props.onClickOutside}
      onInputClick={props.onInputClick}
      // popperContainer={CalendarContainer}
      locale={locale}
      inline={props.inline}
      open={props.open}
      id={props.id}
      name={props.name}
      selected={selected && selected.toDate()}
      openToDate={selected && selected.toDate()}
      onChange={(date) => handleDatePickerDateChange(date)}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      showPopperArrow={false}
      //  shouldCloseOnSelect={false}
      onCalendarOpen={handleCalendarOpen}
      placement={props.position || "auto"}
      popperPlacement={props.position || "auto"}
      popperModifiers={{
        offset: {
          enabled: true,
          offset: ".5rem, .10rem",
        },
        preventOverflow: {
          enabled: true,
          escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
          boundariesElement: "scrollParent",
        },
      }}
      autoComplete="off"
      placeholderText={props.placeholder}
      minDate={minDate ? moment(minDate).toDate() : ""}
      maxDate={props.maxDate ? moment(props.maxDate).toDate() : ""}
      todayButton={t("msp.today")}
      selectsStart={props.selectsStart}
      selectsEnd={props.selectsEnd}
      startDate={props.startDate}
      endDate={props.endDate}
      disabled={props.disabled}
      required={props.required}
      //  onBlur={props.onBlurHandler}
      customInput={
        <DateInput
          disabled={props.disabled}
          hint={props.placeholder}
          onInputChange={handleDateInputChange}
          //  onBlurHandler={props.onBlurHandler}
          inputValue={inputDate}
          ref={ref}
          id={props.id}
          name={props.name}
          required={props.required}
          showError={props.showError}
          noFloating={props.noFloating}
          label={props.label}
          onFocusHandler={props.onFocusHandler}
          customInput={props.customInput}
          icon={props.icon}
        />
      }
      showDisabledMonthNavigation
    />
  );
}

Datepicker.propTypes = {
  customInput: PropTypes.any,
  dateValue: PropTypes.any,
  disabled: PropTypes.any,
  endDate: PropTypes.any,
  icon: PropTypes.any,
  id: PropTypes.any,
  inline: PropTypes.any,
  label: PropTypes.any,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  name: PropTypes.any,
  noFloating: PropTypes.any,
  noMinDate: PropTypes.any,
  onClickOutside: PropTypes.func,
  onFocusHandler: PropTypes.any,
  onInputClick: PropTypes.any,
  open: PropTypes.any,
  placeholder: PropTypes.any,
  position: PropTypes.string,
  required: PropTypes.any,
  selectsEnd: PropTypes.any,
  selectsStart: PropTypes.any,
  setDate: PropTypes.func,
  showError: PropTypes.any,
  startDate: PropTypes.any,
};
