import PropTypes from "prop-types";
import { FormInput } from "components/";
import {
  Container,
  HeaderContent,
  HeaderText,
  InputWrapper,
  MainContent,
  Wrapper,
} from "./style";
import { useTranslation } from "react-i18next";
export default function ActiveColumns({
  activeColumns = [],
  value = {},
  onActiveColumnsChange = () => {},
}) {
  const { t } = useTranslation();
  let columns = "";
  columns = (
    <Wrapper>
      <HeaderContent>
        <HeaderText>{t("msp.activeColumns")}</HeaderText>
      </HeaderContent>
      <MainContent as="ul" aria-label="active-columns">
        {activeColumns && activeColumns.length > 0
          ? activeColumns.map((control) => {
              const { id = "" } = control.attrs || {};
              let inputValue = "";
              if (value[id]) inputValue = value[id];
              return (
                <InputWrapper aria-label="active-column" as="li" key={id}>
                  <FormInput
                    inputType="checkbox"
                    value={inputValue}
                    checked={inputValue}
                    attrs={control.attrs}
                    onChangeHandler={(event) =>
                      onActiveColumnsChange(event, "activateColumns")
                    }
                  />
                </InputWrapper>
              );
            })
          : null}
      </MainContent>
    </Wrapper>
  );
  return <Container>{columns}</Container>;
}

ActiveColumns.propTypes = {
  activeColumns: PropTypes.array,
  onActiveColumnsChange: PropTypes.func,
  value: PropTypes.object,
};
