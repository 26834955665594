import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectHomePage = (state) => state.get("homePage", initialState);

const selectNotificationData = () =>
  createSelector(selectHomePage, (VPState) => VPState.get("notificationData"));
const selectLandingPageDataFetching = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("fetchingLandingPageData")
  );
const selectLandingPageData = () =>
  createSelector(selectHomePage, (VPState) => VPState.get("landingPageData"));
const selectOverduePaymentsDataFetching = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("fetchingOverduePaymentsData")
  );
const selectOverduePaymentsData = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("overduePaymentsData")
  );
const selectForcastPaymentsDataFetching = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("fetchingForcastPaymentsData")
  );
const selectForcastPaymentsData = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("forcastPaymentsData")
  );
const selectInvoiceHistoryByIdFetching = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("fetchingInvoiceHistoryById")
  );
const selectInvoiceHistoryByIdData = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("invoiceHistoryByIdData")
  );
// const selectRejectedInvoicesResolving = () =>
// createSelector(selectHomePage, (VPState) =>
//   VPState.get("isRejectedInvoiceResolving")
// );
const selectGetLandingPageDataFailed = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("getLandingPageDataFailed")
  );
const selectOverdueInvoiceFetchFailed = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("overdueInvoicesFetchFailed")
  );
const selectForcastInvoiceFetchFailed = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("forcastInvoicesFetchFailed")
  );
const selectInvoiceHistoryByIdFetchFailed = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("invoiceHistoryByIdDataFetchFailed")
  );
// const selectRejectedInvoiceResolvingFailed = () =>
// createSelector(selectHomePage, (VPState) =>
//   VPState.get("isRejectedInvoiceResolvingFailed")
// );

const selectIsRejectedInvoicesFetching = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("isRejectedInvoicesFetching")
  );
const selectRejectedInvoices = () =>
  createSelector(selectHomePage, (VPState) => VPState.get("rejectedInvoices"));
const selectRejectedInvoicesFetchingFailed = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("isRejectedInvoicesFetchingFailed")
  );
const selectIsHeaderImageClicked = () =>
  createSelector(selectHomePage, (VPState) =>
    VPState.get("isHeaderImgClicked")
  );

const selectSelectedDayIndex = () =>
  createSelector(selectHomePage, (homePageState) =>
    homePageState.get('selectedDayIndex')
  );

export {
  selectInvoiceHistoryByIdFetching,
  selectNotificationData,
  selectLandingPageDataFetching,
  selectLandingPageData,
  selectOverduePaymentsData,
  selectOverduePaymentsDataFetching,
  selectForcastPaymentsDataFetching,
  selectForcastPaymentsData,
  // selectRejectedInvoicesResolving,
  selectInvoiceHistoryByIdData,
  selectGetLandingPageDataFailed,
  selectOverdueInvoiceFetchFailed,
  selectForcastInvoiceFetchFailed,
  selectInvoiceHistoryByIdFetchFailed,
  // selectRejectedInvoiceResolvingFailed,
  selectIsRejectedInvoicesFetching,
  selectRejectedInvoices,
  selectRejectedInvoicesFetchingFailed,
  selectIsHeaderImageClicked,
  selectSelectedDayIndex,
};
