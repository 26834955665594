import { call, all, takeLatest, put } from "redux-saga/effects";
import { INIT_CREATE_INVOICE_PREVIEW } from "./actionTypes";
import { postRequestDetails } from "config/requestHeaders";
import axios from "../../../config/axiosCall";

import {
  createInvoicePreviewFailed,
  createdInvoicePreview,
  creatingInvoicePreview,
} from "./actions";

export default function* watchCreateInvoiceSagas() {
  yield all([takeLatest(INIT_CREATE_INVOICE_PREVIEW, initCreateInvoiceSaga)]);
}

function* initCreateInvoiceSaga({ payload }) {
  try {
    const url = "/EInvoice/InvoiceCreation";
    const requestDetails = { ...postRequestDetails };
    requestDetails.data = yield payload;
    yield put(creatingInvoicePreview());
    const response = yield call(axios, url, requestDetails);

    if (response && response.status === 200) {
      yield put(createdInvoicePreview(response.data));
    }
  } catch (error) {
    yield put(createInvoicePreviewFailed());
  }
}
