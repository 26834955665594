import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  InviteOthersForm,
  InviteToWrapper,
  OR,
  ReadonlyAddress,
} from "./style";
import { ErrorText, Spinner } from "components";
import { CheckCircleSolid } from "components/SVG/index";
import {
  ButtonWrapper,
  CloseButton,
  SubmitButton,
  SubModuleTitle,
  SuccessIcon,
} from "../style";

const InviteOthers = (props) => {
  const { t } = useTranslation();
  return (
    <InviteOthersForm onSubmit={props.handleInviteSupplier}>
      <SubModuleTitle>{t("msp.inviteOthers")}</SubModuleTitle>
      <InviteToWrapper>
        <label>{t("msp.smallTo")}</label>
        <input
          autoComplete="off"
          id="emailTo"
          aria-label={t("msp.invitationHelpText")}
          value={props.emailTo}
          onChange={props.handleMailtoChange}
        />
      </InviteToWrapper>
      {props.error ? <ErrorText as="span"> {props.error} </ErrorText> : null}
      <OR>{t("msp.Or")}</OR>
      <ReadonlyAddress
        id="registrationLink"
        readOnly
        defaultValue={`${window.location.origin}/registration?${props.clientName}`}
        aria-label={t("msp.linktoRegisterHelpText")}
        title={t("msp.linktoRegisterHelpText")}
      />
      <ButtonWrapper>
        <CloseButton type="reset" onClick={props.closeInvite}>
          {t("msp.close")}
        </CloseButton>
        {props.success ? (
          <SuccessIcon
            disabled={
              props.error !== "" || props.emailTo === "" || props.sending
            }
          >
            <span>
              {t("msp.sent")} <CheckCircleSolid />
            </span>
          </SuccessIcon>
        ) : (
          <SubmitButton
            type="submit"
            disabled={
              props.error !== "" || props.emailTo === "" || props.sending
            }
          >
            {props.sending ? <Spinner /> : t("msp.send")}
          </SubmitButton>
        )}
      </ButtonWrapper>
    </InviteOthersForm>
  );
};

InviteOthers.propTypes = {
  clientName: PropTypes.any,
  closeInvite: PropTypes.any,
  emailTo: PropTypes.string,
  error: PropTypes.string,
  handleInviteSupplier: PropTypes.any,
  handleMailtoChange: PropTypes.any,
  sending: PropTypes.any,
  success: PropTypes.any,
};

export default InviteOthers;
