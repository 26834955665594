import styled, { keyframes } from "styled-components";
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const Spinner = styled.div`
  border: 0.125rem solid #f3f3f3; /* Light grey */
  border-top: 0.125rem solid var(--primary-clr); /* Blue */
  border-radius: 50%;
  width: ${(props) => (props.width ? props.width : "1.25rem")};
  height: ${(props) => (props.height ? props.height : "1.25rem")};
  animation: ${rotate} 2s linear infinite;
  align-self: center;
  margin: auto;
  display: flex;
`;
export default Spinner;
