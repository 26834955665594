import {
  INIT_FORGOT_PASSWORD_EMAIL,
  FORGOT_PASSWORD_EMAIL_SUCCESS,
  FORGOT_PASSWORD_EMAIL_FAILURE,
  RESET_PROPS_AFTER_SUCCESS,
  INIT_CHECK_USER_EXIST,
  FETCH_CHECK_USER_EXIST_COMPLETED,
  INIT_LIST_SECURITY_QUESTION,
  FETCHING_LIST_SECURITY_QUESTION,
  FETCHED_LIST_SECURITY_QUESTION,
  FETCH_LIST_SECURITY_QUESTION_COMPLETED,
  INIT_SUBMIT_QNA,
  SUBMITTING_QNA,
  SUBMITTING_QNA_SUCCESS,
  SUBMITTING_QNA_FAILED,
  INIT_SUBMIT_RESET_PASSWORD,
  SUBMITTING_RESET_PASSWORD,
  SUBMITTING_RESET_PASSWORD_SUCCESS,
  SUBMITTING_RESET_PASSWORD_FAILED,
  GET_USER_DETAILS,
  GET_CLIENT_LANGUAGES,
  FETCHING_USER_DETAIL,
  FETCHING_USER_DETAIL_SUCCESS,
  FETCHING_USER_DETAIL_FAILED,
  INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL,
  INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL_SUCCESS,
  INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL_FAILED,
  INIT_FORGOT_PASS_MFA_OTP,
  FORGOT_PASS_MFA_OTP_SENT,
  FORGOT_PASS_MFA_OTP_FAILED,
} from "./actionTypes";

export function initForgotPasswordEmail(data, callback) {
  return {
    type: INIT_FORGOT_PASSWORD_EMAIL,
    data,
    callback,
  };
}

export function forgotPasswordEmailSuccess() {
  return {
    type: FORGOT_PASSWORD_EMAIL_SUCCESS,
  };
}

export function forgotPasswordEmailFailure() {
  return {
    type: FORGOT_PASSWORD_EMAIL_FAILURE,
  };
}

export function resetPropsAfterSuccess() {
  return {
    type: RESET_PROPS_AFTER_SUCCESS,
  };
}

export function initCheckUserExist(userName, callback) {
  return {
    type: INIT_CHECK_USER_EXIST,
    userName,
    callback,
  };
}

export function fetchCheckUserExistCompleted() {
  return {
    type: FETCH_CHECK_USER_EXIST_COMPLETED,
  };
}

export const initListSecurityQuestions = () => {
  return {
    type: INIT_LIST_SECURITY_QUESTION,
  };
};

export const fetchingListSecurityQuestions = () => {
  return {
    type: FETCHING_LIST_SECURITY_QUESTION,
  };
};
export const fetchedListSecurityQuestions = (data) => {
  return {
    type: FETCHED_LIST_SECURITY_QUESTION,
    data,
  };
};
export const fetchListSecurityQuestionsCompleted = () => {
  return {
    type: FETCH_LIST_SECURITY_QUESTION_COMPLETED,
  };
};

export const initSubmitQnA = (data, callback) => {
  return {
    type: INIT_SUBMIT_QNA,
    data,
    callback,
  };
};

export function submittingQnA() {
  return {
    type: SUBMITTING_QNA,
  };
}

export function submittingQnASuccess() {
  return {
    type: SUBMITTING_QNA_SUCCESS,
  };
}

export function submittingQnAFailed() {
  return {
    type: SUBMITTING_QNA_FAILED,
  };
}

export function initSubmitResetPassword(data, callback) {
  return {
    type: INIT_SUBMIT_RESET_PASSWORD,
    data,
    callback,
  };
}

export function submittingResetPassword() {
  return {
    type: SUBMITTING_RESET_PASSWORD,
  };
}

export function submittingResetPasswordSuccess() {
  return {
    type: SUBMITTING_RESET_PASSWORD_SUCCESS,
  };
}

export function submittingResetPasswordFailed() {
  return {
    type: SUBMITTING_RESET_PASSWORD_FAILED,
  };
}

export function initUserDetails(token, callback) {
  return {
    type: GET_USER_DETAILS,
    token,
    callback,
  };
}

export function initClientLanguages(data) {
  return {
    type: GET_CLIENT_LANGUAGES,
    data
  };
}

export function fetchingUserDetail() {
  return {
    type: FETCHING_USER_DETAIL,
  };
}

export function fetchingUserDetailSuccess() {
  return {
    type: FETCHING_USER_DETAIL_SUCCESS,
  };
}

export function fetchingUserDetailFailed() {
  return {
    type: FETCHING_USER_DETAIL_FAILED,
  };
}

export function initSubmitResetPasswordByEmail(data, callback) {
  return {
    type: INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL,
    data,
    callback,
  };
}

export function submitPasswordByEmailSuccess() {
  return {
    type: INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL_SUCCESS,
  };
}

export function submitPasswordByEmailFailed() {
  return {
    type: INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL_FAILED,
  };
}

export const initSecurityOTP = (payload) => ({
  type: INIT_FORGOT_PASS_MFA_OTP,
  payload,
});
export const securityOTPFailed = () => ({ type: FORGOT_PASS_MFA_OTP_FAILED });
export const securityOTPSuccess = () => ({ type: FORGOT_PASS_MFA_OTP_SENT });
