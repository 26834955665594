import styled from "styled-components";
import { SecondaryBtn } from "components/";
import { line } from "globalStyles";

export const PopupContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
  padding: 1.25em;
  max-height: 75vh;
`;

export const ShowDetailsBtn = styled(SecondaryBtn)`
  display: flex;
  @media (min-width: 48em) {
    display: none;
  } ;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: var(--light-clr);
  box-shadow: 0 0.125em 1.25em 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.7em;
  margin: 0 0 0.5em 0;
  padding: 0.5em;
  overflow-y: auto;
  gap: 0.25em;
  max-height: 100%;
  flex: 1;
  &:nth-child(2) {
    margin-left: 0.625em;
    padding: 0.75em 1em;
    gap: 1em;
  }
`;

export const PDFSection = styled(Section)`
  display: ${(props) => (props.showDetails ? "none" : "flex")};
  @media (min-width: 48em) {
    display: flex;
  } ;
`;

export const DataSection = styled(Section)`
  display: ${(props) => (props.showDetails ? "flex" : "none")};

  @media (min-width: 48em) {
    display: flex;
  } ;
`;

export const Title = styled.p`
  color: var(--dark-clr);
  font-size: (var(--fs-18), 3.5vw, var(--fs-20));
  font-family: var(--ff-medium);
  font-weight: var(--normal);
  text-align: left;
`;

export const SectionContent = styled.div`
  margin: 1.6em 0 1em 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: 40em) {
    flex-direction: row;
  }
`;

export const Container = styled.div`
  width: 28%;
  margin: 0 1.6em 2.5em 0;
`;

export const Heading = styled.p`
  color: rgb(99, 115, 129);
  font-size: var(--fs-14);
  font-family: var(--ff-main);
  font-weight: var(--normal);
  margin-bottom: 0.75em;
`;

export const SubData = styled.p`
  color: ${(props) => props.color};
  font-size: var(--fs-16);
  font-family: var(--ff-main);
  font-weight: var(--normal);
`;

export const Right = styled.p`
  color: #15181b;
  font-size: var(--fs-14);
  font-family: var(--ff-main);
  font-weight: var(--normal);
`;

export const Left = styled.p`
  color: #637381;
  font-size: var(--fs-14);
  font-family: var(--ff-medium);
  font-weight: var(--normal);
  margin-right: 1.5em;
`;

export const AmountSection = styled.div`
  margin: 1em 0 1em auto;
`;

export const BalanceWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const BalRight = styled.p`
  color: var(--dark-clr);
  font-size: (var(--fs-16), 3.5vw, var(--fs-18));
  font-family: var(--ff-semibold);
  font-weight: var(--normal);
`;

export const HorizontalLine = styled.hr`
  ${line};
  background-color: #979797;
  opacity: 100%;
  width: 100%;
  margin: 0.5em 0;
`;
