export const INIT_EMAIL_TEMPLATE_LIST = "INIT_EMAIL_TEMPLATE_LIST";
export const FETCHING_EMAIL_TEMPLATE_LIST = "FETCHING_EMAIL_TEMPLATE_LIST";
export const FETCH_EMAIL_TEMPLATE_LIST = "FETCH_EMAIL_TEMPLATE_LIST";
export const EMAIL_TEMPLATE_LIST_FETCH_COMPLETE =
  "EMAIL_TEMPLATE_LIST_FETCH_COMPLETE";

export const INIT_CATEGORY_LIST = "INIT_CATEGORY_LIST";
export const FETCHING_CATEGORY_LIST = "FETCHING_CATEGORY_LIST";
export const FETCH_CATEGORY_LIST = "FETCH_CATEGORY_LIST";
export const CATEGORY_LIST_FETCH_COMPLETE = "CATEGORY_LIST_FETCH_COMPLETE";

export const INIT_EMAIL_TEMPLATE_EDIT = "INIT_EMAIL_TEMPLATE_EDIT";
export const SET_EMAIL_TEMPLATE_EDIT_DATA = "SET_EMAIL_TEMPLATE_EDIT_DATA";

export const INIT_EMAIL_TEMPLATE_SAVE = "INIT_EMAIL_TEMPLATE_SAVE";
export const EMAIL_TEMPLATE_SAVED = "EMAIL_TEMPLATE_SAVED";
export const EMAIL_TEMPLATE_SAVE_FAILED = "EMAIL_TEMPLATE_SAVE_FAILED";

export const RESET_SAVE_STATUS = "RESET_SAVE_STATUS";
export const RESET_EDIT_STATUS = "RESET_EDIT_STATUS";

export const INIT_EMAIL_TEMPLATE_DELETE = "INIT_EMAIL_TEMPLATE_DELETE";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAILED = "DELETE_FAILED";

export const EDIT_TEMPLATE_FILE = "EDIT_TEMPLATE_FILE";
export const EDIT_ATTACHMENT_FILE = "EDIT_ATTACHMENT_FILE";
export const EDIT_TEMPLATE_FILE_COMPLETE = "EDIT_TEMPLATE_FILE_COMPLETE";
export const EDIT_ATTACHMENT_FILE_COMPLETE = "EDIT_ATTACHMENT_FILE_COMPLETE";

export const DOWNLOADED_ATTACHMENT = "DOWNLOADED_ATTACHMENT";
export const UPDATE_MUTATION = "UPDATE_MUTATION";
