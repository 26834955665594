import { call, put, all, takeEvery } from "redux-saga/effects";
import { store } from "react-notifications-component";
import { buildNotification } from "../../../config/notification";
import axios from "../../../config/axiosCall";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";
import {
  checkUserExistsStatus,
  fetchingSignUpFlexFields,
  fetchSignUpFlexFields,
  fetchSignUpFlexFieldsComplete,
  signUpFormSaved,
  signUpFormSaveFailed,
  userExist,
} from "./actions";
import {
  INIT_FETCH_SIGNUP_FLEX_FIELDS,
  INIT_SAVE_SIGNUP_FORM,
  INIT_CHECK_USER_EXISTS,
} from "./actionTypes";

export default function* watchRegistrationSaga() {
  yield all([
    takeEvery(INIT_FETCH_SIGNUP_FLEX_FIELDS, getSignUpFlexFieldsSaga),
    takeEvery(INIT_SAVE_SIGNUP_FORM, initSaveSignUpFormSaga),
    takeEvery(INIT_CHECK_USER_EXISTS, initCheckForExistingUSerSaga),
  ]);
}

function* getSignUpFlexFieldsSaga(action) {
  const url = `/Login/flexFields?languageID=${action.langId}`;
  yield put(fetchingSignUpFlexFields());
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchSignUpFlexFields(response.data));
      yield put(fetchSignUpFlexFieldsComplete());
    }
  } catch (error) {
    yield put(fetchSignUpFlexFieldsComplete());
  }
}

function* initSaveSignUpFormSaga(action) {
  const url = "/Login/createaccount";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.payload);
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      yield put(signUpFormSaved());
      const notification = buildNotification({
        message: "msp.requestSubmittedSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });

      if (action.callback) action.callback(response);
    } else {
      yield put(signUpFormSaveFailed());
    }
  } catch (error) {
    if (action.callback) action.callback(error);
    yield put(signUpFormSaveFailed());
  }
}

function* initCheckForExistingUSerSaga(action) {
  const url = `/Users/UserAlreadyExists?userName=${action.payload}`;
  yield put(checkUserExistsStatus(true));
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(userExist(response.data));

      if (response.data && response.data.token !== null) {
        const notification = buildNotification({
          message: "msp.errorInvalidEmail",
          type: "warning",
        });
        store.addNotification({
          ...notification,
        });
      }
    }
    yield put(checkUserExistsStatus(false));
  } catch (error) {
    yield put(checkUserExistsStatus(false));
  }
}
