export const INIT_FETCH_USER_GROUP_LIST = "INIT_FETCH_USER_GROUP_LIST";
export const FETCHING_USER_GROUP_LIST = "FETCHING_USER_GROUP_LIST";
export const FETCH_USER_GROUP_LIST = "FETCH_USER_GROUP_LIST";
export const USER_GROUP_LIST_FETCH_COMPLETE = "USER_GROUP_LIST_FETCH_COMPLETE";

export const INIT_UPDATE_USER_GROUP_STATUS = "INIT_UPDATE_USER_GROUP_STATUS";
export const UPDATING_USER_GROUP_STATUS = "UPDATING_USER_GROUP_STATUS";
export const USER_GROUP_STATUS_UPDATE_COMPLETED =
  "USER_GROUP_STATUS_UPDATE_COMPLETED";

export const INIT_DELETE_USER_GROUP = "INIT_DELETE_USER_GROUP";
export const DELETING_USER_GROUP = "DELETING_USER_GROUP";
export const USER_GROUP_DELETED = "USER_GROUP_DELETED";

export const INIT_FETCH_BASE_ROLE_LIST = "INIT_FETCH_BASE_ROLE_LIST";
export const FETCHING_BASE_ROLE_LIST = "FETCHING_BASE_ROLE_LIST";
export const FETCH_BASE_ROLE_LIST = "FETCH_BASE_ROLE_LIST";
export const BASE_ROLE_LIST_FETCH_COMPLETE = "BASE_ROLE_LIST_FETCH_COMPLETE";

export const INIT_FETCH_USER_LIST = "INIT_FETCH_USER_LIST";
export const FETCHING_USER_LIST = "FETCHING_USER_LIST";
export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const USER_LIST_FETCH_COMPLETE = "USER_LIST_FETCH_COMPLETE";

export const INIT_FETCH_USER_GROUP_BY_ID = "INIT_FETCH_USER_GROUP_BY_ID";
export const FETCHING_USER_GROUP_BY_ID = "FETCHING_USER_GROUP_BY_ID";
export const USER_GROUP_BY_ID_FETCH_COMPLETE =
  "USER_GROUP_BY_ID_FETCH_COMPLETE";

export const INIT_SAVE_USER_GROUP = "INIT_SAVE_USER_GROUP";
export const USER_GROUP_SAVED = "USER_GROUP_SAVED";
export const USER_GROUP_SAVE_FAILED = "USER_GROUP_SAVE_FAILED";
