import { fromJS } from "immutable";
import {
  RESET_UPLOAD_INVOICE,
  SET_BACKUP_UPLOAD_INVOICE_FILES,
  SET_CURRENT_INVOICE_REF,
  SET_EDIT_MODE,
  SET_NON_PO_BULK_SUPPLIER_ID,
  SET_SELECTED_CARD,
  SET_SELECT_ALL,
  SET_UPLOAD_INVOICE_FILES,
  SET_UPLOAD_PROGRESS,
  SET_VIEW_MODE,
} from "./actionTypes";
const defaultState = {
  files: [],
  backupFiles: [],
  nonPoBulkSupplierID: "",
  editMode: false,
  viewMode: false,
  selectedCard: null,
  selectAll: false,
  currentInvoiceRef: null,
  uploadProgress: [],
};
export const initialState = fromJS({
  ...defaultState,
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UPLOAD_INVOICE_FILES:
      return state.set("files", fromJS(action.data));
    case SET_BACKUP_UPLOAD_INVOICE_FILES: {
      return state.set("backupFiles", fromJS(action.data));
    }
    case SET_NON_PO_BULK_SUPPLIER_ID:
      return state.set("nonPoBulkSupplierID", action.data);
    case SET_EDIT_MODE:
      return state.set("editMode", action.data);
    case SET_VIEW_MODE:
      return state.set("viewMode", action.data);
    case SET_SELECTED_CARD: {
      return state.set("selectedCard", action.data);
    }
    case SET_SELECT_ALL: {
      return state.set("selectAll", action.data);
    }
    case SET_CURRENT_INVOICE_REF: {
      return state.set("currentInvoiceRef", action.data);
    }
    case SET_UPLOAD_PROGRESS: {
      return state.set("uploadProgress", fromJS(action.data));
    }

    case RESET_UPLOAD_INVOICE: {
      return state
        .set("files", fromJS([]))
        .set("backupFiles", fromJS([]))
        .set("nonPoBulkSupplierID", "")
        .set("editMode", false)
        .set("viewMode", false)
        .set("selectedCard", null)
        .set("selectAll", false)
        .set("currentInvoiceRef", null)
        .set("uploadProgress", fromJS([]));
    }

    default:
      return state;
  }
};

export default reducer;
