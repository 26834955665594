import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NavTabStyled, { NavTabItem } from "./style";

const NavTab = (props) => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActive] = useState(-1); // default is always all
  useEffect(() => {
    if (props.tabs && props.tabs.length > 0) {
      const allCount =
        props.tabs &&
        props.tabs.reduce((result, curVal) => {
          return result + Number(curVal.count);
        }, 0);

      const allTabs = {
        text: t("msp.all"),
        selector: -1,
        count: allCount,
      };
      const isActiveTabExist =
        props.tabs &&
        props.tabs.findIndex((item) => {
          const active = props.active ? props.active.toString() : "";
          return item.selector.toString() === active;
        });

      isActiveTabExist > -1 ? setActive(props.active) : setActive(-1);

      if (props.showAll) setTabs([allTabs, ...props.tabs]);
      else setTabs([...props.tabs]);
    } else {
      props.showAll &&
        setTabs([
          {
            text: t("msp.all"),
            selector: -1,
            count: 0,
          },
        ]);
    }
  }, [props, t]);

  const changeActive = (selector) => {
    setActive(selector.selector);
    props.changeActive && props.changeActive(selector);
  };
  const handleKeyHit = (e, selector) => {
    if (e.keyCode === 13) changeActive(selector);
  };
  return tabs && tabs.length > 0 ? (
    <div>
      <NavTabStyled>
        {tabs.map((tab, key) => {
          return (
            <NavTabItem
              key={key}
              active={tab.selector.toString() === activeTab.toString()}
              onKeyDown={(e) =>
                handleKeyHit(e, {
                  selector: tab.selector,
                })
              }
              onClick={() =>
                changeActive({
                  selector: tab.selector,
                })
              }
            >
              <p>
                {t(tab.text)} {props.showCount ? `(${tab.count})` : null}
              </p>
            </NavTabItem>
          );
        })}
      </NavTabStyled>
    </div>
  ) : null;
};

export default NavTab;
