import React, { useState } from "react";
import { usePopper } from "react-popper";
import { TipWrapper, TooltipArrow } from "./style";

const Tooltip = (props) => {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } = usePopper(props.triggerElem, popperElement, {
    placement: props.placement ? props.placement : "auto",
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },

      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <TipWrapper
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      zIndex={props.zIndex}
    >
      {props.children}
      <TooltipArrow ref={setArrowElement} style={styles.arrow} hw={props.hw} />
    </TipWrapper>
  );
};
export default Tooltip;