import PropTypes from "prop-types";
import { IconWrapper, Spinner } from "components/index";
import Arrow from "components/SVG/Arrow";
import Cross from "components/SVG/Cross";
import React from "react";
import { useTranslation } from "react-i18next";
import LookupContainer, {
  LookupItemsWrapper,
  LookupItem,
  LookupButton,
  LookupTitle,
  RemainingDataCount,
} from "./style";

const DataLookup = (props) => {
  const { t } = useTranslation();
  return (
    <LookupContainer>
      <LookupTitle htmlFor={props.id} required={props.required}>
        {t(props.label)}
      </LookupTitle>
      <LookupItemsWrapper>
        {props.dataFetchStatus ? (
          <Spinner />
        ) : props.data && props.data.length ? (
          <>
            {props.data.map((item, index) => {
              return (
                <LookupItem key={index}>
                  <span>{item.text}</span>
                  {props.restrictDataRemoval ? null : (
                    <IconWrapper
                      onClick={() =>
                        props.removeData ? props.removeData(item) : false
                      }
                      disabled={props.disabled || false}
                    >
                      <Cross />
                    </IconWrapper>
                  )}
                </LookupItem>
              );
            })}
            {props.remainingDataCount && props.remainingDataCount > 0 ? (
              <RemainingDataCount>{`+ ${props.remainingDataCount}`}</RemainingDataCount>
            ) : null}
          </>
        ) : null}
      </LookupItemsWrapper>
      <LookupButton
        sm
        onClick={props.openLookup}
        disabled={props.disabled || false}
      >
        <span>
          {t(props.btnText)} <Arrow />
        </span>
      </LookupButton>
    </LookupContainer>
  );
};

DataLookup.propTypes = {
  btnText: PropTypes.any,
  data: PropTypes.any,
  dataFetchStatus: PropTypes.any,
  id: PropTypes.any,
  label: PropTypes.any,
  openLookup: PropTypes.any,
  removeData: PropTypes.func,
  required: PropTypes.any,
  remaningDataCount: PropTypes.any,
  restrictDataRemoval: PropTypes.any,
};

export default DataLookup;
