import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDataCapture,
  initOCRExtration,
  setDataCapture,
} from "../store/actions";
import { selectDataCapture } from "../store/selectors";

let isRubberBandStarted = false;

let rubberElement = null;
let mousePositions = {
  startX: null,
  startY: null,
  scrollX: 0,
  scrollY: 0,
};

let canvasOffSet = null;

let Dimensions = {
  // width: 2472,
  // height: 3224,
};

let imageInitWidth = 0;
let imageinitHeight = 0;

let formatCoeffs = {
  widthCoeff: 0,
  heightCoeff: 0,
};

let zoomAmount = 0;

export function useMouseDownEvent() {
  let dataCapture = useSelector(selectDataCapture());
  dataCapture = dataCapture.toJS();
  const dispatch = useDispatch();
  return useCallback(
    (e) => {
      e.preventDefault();

      let prevELement = document.getElementById("rubberBand");
      if (prevELement !== null) {
        e.currentTarget.removeChild(prevELement);
      }
      isRubberBandStarted = true;
      mousePositions.startX = e.clientX;
      mousePositions.startY = e.clientY;

      let rubberBand = document.createElement("div");
      rubberBand.id = "rubberBand";
      rubberBand.style.position = "absolute";
      rubberBand.style.border = ".1rem solid #FF0000";
      let position = document.getElementById(e.currentTarget.id);
      canvasOffSet = position;

      rubberBand.style.top = parseInt(e.clientY) + "px";
      rubberBand.style.left = parseInt(e.clientX) + "px";
      e.currentTarget.appendChild(rubberBand);
      rubberElement = rubberBand;

      //calculating the co-ordinates for rubberband...
      dataCapture.X1 =
        parseInt(e.clientX) -
        parseInt(position.offsetLeft) +
        mousePositions.scrollX;
      dataCapture.Y1 =
        parseInt(e.clientY) -
        parseInt(position.offsetTop) +
        mousePositions.scrollY;

      //formating the co-ordinates...
      let docImage = document.getElementById("docImage");
      Dimensions.width = docImage.naturalWidth;
      Dimensions.height = docImage.naturalHeight;

      let ImageWidth = docImage.width;
      let ImageHeight = docImage.height;

      //removing zoom amount...
      if (zoomAmount !== 0) {
        dataCapture.X1 = dataCapture.X1 / (1 + zoomAmount / 100);
        dataCapture.Y1 = dataCapture.Y1 / (1 + zoomAmount / 100);
        ImageWidth = ImageWidth / (1 + zoomAmount / 100);
        ImageHeight = ImageHeight / (1 + zoomAmount / 100);
      }

      formatCoeffs.widthCoeff = Dimensions.width / ImageWidth;
      formatCoeffs.heightCoeff = Dimensions.height / ImageHeight;
      dataCapture.X1 = formatCoeffs.widthCoeff * dataCapture.X1;
      dataCapture.Y1 = formatCoeffs.heightCoeff * dataCapture.Y1;
      dispatch(setDataCapture(dataCapture));
    },
    [dataCapture, dispatch]
  );
}

export function useMouseUpEvent(fullOCRExtract) {
  let dataCapture = useSelector(selectDataCapture());
  dataCapture = dataCapture.toJS();
  const dispatch = useDispatch();

  return useCallback(
    (e) => {
      if (isRubberBandStarted) {
        if (rubberElement.clientWidth < 5 && rubberElement.clientHeight < 5) {
          dispatch(clearDataCapture());
          return canceleRubberband(e);
        }

        isRubberBandStarted = false;
        rubberElement = null;
        let position = document.getElementById(e.currentTarget.id);
        dataCapture.X2 =
          parseInt(e.clientX) -
          parseInt(position.offsetLeft) +
          mousePositions.scrollX;
        dataCapture.Y2 =
          parseInt(e.clientY) -
          parseInt(position.offsetTop) +
          mousePositions.scrollY;

        //removing zoom amount...
        if (zoomAmount !== 0) {
          dataCapture.X2 = dataCapture.X2 / (1 + zoomAmount / 100);
          dataCapture.Y2 = dataCapture.Y2 / (1 + zoomAmount / 100);
        }

        //formatting the co-ordinates...
        dataCapture.X2 = formatCoeffs.widthCoeff * dataCapture.X2;
        dataCapture.Y2 = formatCoeffs.heightCoeff * dataCapture.Y2;
      }
      dispatch(setDataCapture(dataCapture));
      dispatch(initOCRExtration(fullOCRExtract));
    },
    [dataCapture, dispatch, fullOCRExtract]
  );
}

export function useMouseMoveEvent() {
  return useCallback((e) => {
    let width;
    let height;
    if (isRubberBandStarted && rubberElement !== null) {
      if (
        e.clientX > mousePositions.startX &&
        e.clientY > mousePositions.startY
      ) {
        width = parseInt(e.clientX) - parseInt(mousePositions.startX);
        height = parseInt(e.clientY) - parseInt(mousePositions.startY);
        rubberElement.style.width = width + "px";
        rubberElement.style.height = height + "px";
      } else if (
        e.clientX < mousePositions.startX &&
        e.clientY > mousePositions.startY
      ) {
        rubberElement.style.left =
          parseInt(e.clientX) - parseInt(canvasOffSet.offsetLeft) + "px";
        rubberElement.style.bottom =
          parseInt(e.clientY) - parseInt(canvasOffSet.offsetTop) + "px";
        width = parseInt(mousePositions.startX) - parseInt(e.clientX);
        height = parseInt(e.clientY) - parseInt(mousePositions.startY);
        rubberElement.style.width = width + "px";
        rubberElement.style.height = height + "px";
      } else if (
        e.clientX > mousePositions.startX &&
        e.clientY < mousePositions.startY
      ) {
        rubberElement.style.right =
          parseInt(e.clientX) - parseInt(canvasOffSet.offsetLeft) + "px";
        rubberElement.style.top =
          parseInt(e.clientY) - parseInt(canvasOffSet.offsetTop) + "px";
        width = parseInt(e.clientX) - parseInt(mousePositions.startX);
        height = parseInt(mousePositions.startY) - parseInt(e.clientY);
        rubberElement.style.width = width + "px";
        rubberElement.style.height = height + "px";
      } else if (
        e.clientX < mousePositions.startX &&
        e.clientY < mousePositions.startY
      ) {
        rubberElement.style.left =
          parseInt(e.clientX) - parseInt(canvasOffSet.offsetLeft) + "px";
        rubberElement.style.top =
          parseInt(e.clientY) - parseInt(canvasOffSet.offsetTop) + "px";
        width = parseInt(mousePositions.startX) - parseInt(e.clientX);
        height = parseInt(mousePositions.startY) - parseInt(e.clientY);
        rubberElement.style.width = width + "px";
        rubberElement.style.height = height + "px";
      }
    }
  }, []);
}

// func

export const zoomInImage = (e) => {
  let docImage = document.getElementById("docImage");

  if (imageinitHeight === 0 && imageInitWidth === 0) {
    imageinitHeight = docImage.offsetHeight;
    imageInitWidth = docImage.offsetWidth;
  }

  let presentWidth = docImage.offsetWidth;
  let presentHeight = docImage.offsetHeight;

  if (presentWidth + parseInt(imageInitWidth * 0.2) <= 2 * imageInitWidth) {
    docImage.style.width = presentWidth + imageInitWidth * 0.2 + "px";

    if (
      presentHeight + parseInt(imageinitHeight * 0.2) <=
      2 * imageinitHeight
    ) {
      docImage.style.height = presentHeight + imageinitHeight * 0.2 + "px";
      zoomAmount = zoomAmount + 20;
    }
  }
};

export const zoomOutImage = (e) => {
  let docImage = document.getElementById("docImage");

  if (imageinitHeight === 0 && imageInitWidth === 0) {
    imageinitHeight = docImage.offsetHeight;
    imageInitWidth = docImage.offsetWidth;
  }

  let presentWidth = docImage.offsetWidth;
  let presentHeight = docImage.offsetHeight;

  if (presentWidth - parseInt(imageInitWidth * 0.2) >= imageInitWidth) {
    docImage.style.width = presentWidth - imageInitWidth * 0.2 + "px";

    if (presentHeight - parseInt(imageinitHeight * 0.2) >= imageinitHeight) {
      docImage.style.height = presentHeight - imageinitHeight * 0.2 + "px";
      zoomAmount = zoomAmount - 20;
    }
  }
};

export const scrollImage = (e) => {
  mousePositions.scrollX = e.currentTarget.scrollLeft;

  mousePositions.scrollY = e.currentTarget.scrollTop;
};
const canceleRubberband = (e) => {
  isRubberBandStarted = false;
  rubberElement = null;

  let prevELement = document.getElementById("rubberBand");
  if (prevELement !== null) {
    e.currentTarget.removeChild(prevELement);
  }
  mousePositions = {
    startX: null,
    startY: null,
    scrollX: 0,
    scrollY: 0,
  };
  return false;
};
