import React from "react";
import SvgIcon from "./style";

const Download = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-486.000000, -258.000000)" fill="currentColor">
          <g transform="translate(86.000000, 258.000000)">
            <g transform="translate(400.000000, 0.000000)">
              <path d="M14.125,13.5 C14.125,13.84375 13.84375,14.125 13.5,14.125 C13.15625,14.125 12.875,13.84375 12.875,13.5 C12.875,13.15625 13.15625,12.875 13.5,12.875 C13.84375,12.875 14.125,13.15625 14.125,13.5 Z M11.5,12.875 C11.15625,12.875 10.875,13.15625 10.875,13.5 C10.875,13.84375 11.15625,14.125 11.5,14.125 C11.84375,14.125 12.125,13.84375 12.125,13.5 C12.125,13.15625 11.84375,12.875 11.5,12.875 Z M16,11.375 L16,14.625 C16,15.384375 15.384375,16 14.625,16 L1.37499998,16 C0.615624982,16 -2.00000002e-08,15.384375 -2.00000002e-08,14.625 L-2.00000002e-08,11.375 C-2.00000002e-08,10.615625 0.615624982,9.99999998 1.37499998,9.99999998 L4.48124999,9.99999998 L2.71874999,8.2375 C1.93124998,7.45 2.49062499,6.103125 3.60312499,6.103125 L5.24999999,6.103125 L5.24999999,1.24999998 C5.24999999,0.559374981 5.80937499,-2.00000002e-08 6.49999998,-2.00000002e-08 L9.49999998,-2.00000002e-08 C10.190625,-2.00000002e-08 10.75,0.559374981 10.75,1.24999998 L10.75,6.103125 L12.396875,6.103125 C13.509375,6.103125 14.065625,7.45 13.28125,8.2375 L11.51875,9.99999998 L14.625,9.99999998 C15.384375,9.99999998 16,10.615625 16,11.375 Z M7.821875,11.928125 C7.91875,12.025 8.078125,12.025 8.175,11.928125 L12.571875,7.53125 C12.728125,7.375 12.61875,7.103125 12.39375,7.103125 L9.75,7.103125 L9.75,1.24999998 C9.75,1.11249998 9.6375,0.999999982 9.5,0.999999982 L6.5,0.999999982 C6.3625,0.999999982 6.25,1.11249998 6.25,1.24999998 L6.25,7.103125 L3.60312499,7.103125 C3.38124999,7.103125 3.26874999,7.371875 3.42499999,7.53125 L7.821875,11.928125 Z M15,11.375 C15,11.16875 14.83125,11 14.625,11 L10.51875,11 L8.884375,12.634375 C8.396875,13.121875 7.603125,13.121875 7.115625,12.634375 L5.48124999,11 L1.37499998,11 C1.16874998,11 0.999999982,11.16875 0.999999982,11.375 L0.999999982,14.625 C0.999999982,14.83125 1.16874998,15 1.37499998,15 L14.625,15 C14.83125,15 15,14.83125 15,14.625 L15,11.375 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Download;
