// export const INIT_GET_BASE_ROLE = "INIT_GET_BASE_ROLE";
// export const FETCHING_GET_BASE_ROLE = "FETCHING_GET_BASE_ROLE";
// export const FETCH_BASE_ROLE = "FETCH_BASE_ROLE";
// export const GET_BASE_ROLE_COMPLETED = "GET_BASE_ROLE_COMPLETED";

export const INIT_GET_PAGE_ELEMENTS = "INIT_GET_PAGE_ELEMENTS";
export const FETCHING_PAGE_ELEMENTS = "FETCHING_PAGE_ELEMENTS";
export const FETCH_PAGE_ELEMENTS = "FETCH_PAGE_ELEMENTS";
export const GET_PAGE_ELEMENTS_COMPLETED = "GET_PAGE_ELEMENTS_COMPLETED";

export const INIT_GET_ACCESS_LEVEL = "INIT_GET_ACCESS_LEVEL";
export const FETCHING_ACCESS_LEVEL = "FETCHING_ACCESS_LEVEL";
export const FETCH_ACCESS_LEVEL = "FETCH_ACCESS_LEVEL";
export const GET_ACCESS_LEVEL_COMPLETED = "GET_ACCESS_LEVEL_COMPLETED";

// export const INIT_GET_PROFILE_LIST = "INIT_GET_PROFILE_LIST";
// export const FETCHING_PROFILE_LIST = "FETCHING_PROFILE_LIST";
// export const FETCH_PROFILE_LIST = "FETCH_PROFILE_LIST";
// export const GET_PROFILE_LIST_COMPLETED = "GET_PROFILE_LIST_COMPLETED";
// export const UPDATED_PROFILE_LIST = "UPDATED_PROFILE_LIST";

export const INIT_PROFILE_ROLE_SAVE = "INIT_PROFILE_ROLE_SAVE";
export const SAVE_PROFILE_ROLE = "SAVE_PROFILE_ROLE";
export const SAVE_PROFILE_ROLE_FAILED = "SAVE_PROFILE_ROLE_FAILED";

export const FORM_SUBMISSION_STATUS = "FORM_SUBMISSION_STATUS";
export const RESET_FORM_SUBMISSION_STATUS = "RESET_FORM_SUBMISSION_STATUS";

export const INIT_PROFILE_PERMISSION_EDIT = "INIT_PROFILE_PERMISSION_EDIT";
export const SET_PROFILE_PERMISSION_EDIT_DATA =
  "SET_PROFILE_PERMISSION_EDIT_DATA";
  export const SET_PROFILE_PERMISSION_EDIT_DATA_FAILED =
  "SET_PROFILE_PERMISSION_EDIT_DATA_FAILED";

export const INIT_USER_ROLES_DELETE = "INIT_USER_ROLES_DELETE";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAILED = "DELETE_FAILED";

export const INIT_GET_LOGIN_GDPR_OPTIONS = "INIT_GET_LOGIN_GDPR_OPTIONS";
export const FETCHING_LOGIN_GDPR_OPTIONS = "FETCHING_LOGIN_GDPR_OPTIONS";
export const FETCH_LOGIN_GDPR_OPTIONS = "FETCH_LOGIN_GDPR_OPTIONS";
export const GET_LOGIN_GDPR_OPTIONS_COMPLETED = "GET_LOGIN_GDPR_OPTIONS_COMPLETED";


export const INIT_GET_APPLICATION_GDPR_OPTIONS = "INIT_GET_APPLICATION_GDPR_OPTIONS";
export const FETCHING_APPLICATION_GDPR_OPTIONS = "FETCHING_APPLICATION_GDPR_OPTIONS";
export const FETCH_APPLICATION_GDPR_OPTIONS = "FETCH_APPLICATION_GDPR_OPTIONS";
export const GET_APPLICATION_GDPR_OPTIONS_COMPLETED = "GET_APPLICATION_GDPR_OPTIONS_COMPLETED";
