export const GET_NOTIFICATION_DATA = "GET_NOTIFICATION_DATA";
export const FETCH_NOTIFICATION_DATA = "FETCH_NOTIFICATION_DATA";
export const FETCHING_NOTIFICATION_DATA = "FETCHING_NOTIFICATION_DATA";
export const FETCHING_NOTIFICATION_COMPLETED =
  "FETCHING_NOTIFICATION_COMPLETED";
export const GET_NOTIFICATION_PREFERENCES = "GET_NOTIFICATION_PREFERENCES";
export const FETCHING_NOTIFICATION_PREFERENCES =
  "FETCHING_NOTIFICATION_PREFERENCES";
export const FETCH_NOTIFICATION_PREFERENCES_COMPLETED =
  "FETCH_NOTIFICATION_PREFERENCES_COMPLETED";
export const FETCH_NOTIFICATION_PREFERENCES = "FETCH_NOTIFICATION_PREFERENCES";
export const INIT_SUBMIT_NOTIFICATION_PREFERENCES =
  "INIT_SUBMIT_NOTIFICATION_PREFERENCES";
export const INIT_UPDATE_NOTIFICATION = "INIT_UPDATE_NOTIFICATION";
export const INIT_GET_ARCHIVED_ANNOUNCEMENT = "INIT_GET_ARCHIVED_ANNOUNCEMENT";
export const FETCH_ARCHIVE_DATA = "FETCH_ARCHIVE_DATA";
export const FETCHING_ARCHIVE_DATA = "FETCHING_ARCHIVE_DATA";
export const FETCH_ARCHIVE_DATA_COMPLETED = "FETCH_ARCHIVE_DATA_COMPLETED";
