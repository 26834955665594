import { put, call, all, takeLatest } from "redux-saga/effects";

import {
  ocrDetailsFetchComplete,
  ocrDetailsFetchFailed,
  ocrDetailsFetching,
  PODetailsFetching,
  PODetailsFetchComplete,
  PODetailsFetchFailed,
} from "../store/actions";

import { INIT_FETCH_OCR_DETAILS, INIT_FETCH_PO_DETAILS } from "./actionTypes";

import axios from "../../../config/axiosCall";

import { getRequestDetails } from "../../../config/requestHeaders";

export default function* watchSubmittedDocuments() {
  yield all([
    takeLatest(INIT_FETCH_OCR_DETAILS, initOCRDetailsFetchSaga),
    takeLatest(INIT_FETCH_PO_DETAILS, initGetPODetailsByNumberSaga),
  ]);
}

function* initOCRDetailsFetchSaga(action) {
  const invoiceID = action.payload;
  const url = `EInvoice/GetFullOCRDetails?SubmissionId=${invoiceID}`;
  const docExtrationsUrl = `/EInvoice/GetDocExtractedData?SubmissionId=${invoiceID}`;
  yield put(ocrDetailsFetching());
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const docRes = yield call(axios, docExtrationsUrl, requestDetails);
    let docExtractedData = {},
      ocrDetails = {};
    if (docRes.data) docExtractedData = yield docRes.data;
    if (response.data) ocrDetails = yield response.data;
    yield put(
      ocrDetailsFetchComplete({
        docExtractedData,
        ocrDetails,
      })
    );
  } catch (error) {
    yield put(ocrDetailsFetchFailed());
  }
}

function* initGetPODetailsByNumberSaga({ payload }) {
  try {
    const { poNumber, supplierID } = payload;
    if (!poNumber) return;
    yield put(PODetailsFetching());

    const url = `/PurchaseOrder/getDetailsByOrderNumber?orderNumber=${poNumber}&supplierId=${supplierID}`;
    const requestDetails = { ...getRequestDetails };
    const response = yield call(axios, url, requestDetails);
    yield put(PODetailsFetchComplete(response.data));
  } catch (error) {
    yield put(PODetailsFetchFailed());
  }
}
