import React from "react";

const HomePage = React.lazy(() => import("../container/HomePage"));
const Insights = React.lazy(() => import("../container/Insights"));
const Support = React.lazy(() => import("../container/Support"));
const SupportRequestsList = React.lazy(() =>
  import("../container/Support/RequestsList/")
);
const SupportRequestDetails = React.lazy(() =>
  import("../container/Support/RequestDetails")
);
const SupportServiceNowDetails = React.lazy(() =>
  import("../container/Support/ServiceNowDetails")
);
const NewRequests = React.lazy(() =>
  import("../container/Support/NewRequests/")
);
const ManageSupplier = React.lazy(() => import("../container/ManageSupplier"));
const ManageSupplierList = React.lazy(() =>
  import("../container/ManageSupplier/ManageSupplierList")
);
const ManageSupplierDetails = React.lazy(() =>
  import("../container/ManageSupplier/ManageSupplierDetails")
);
const CreateCompany = React.lazy(() =>
  import("../container/ManageSupplier/CreateCompany")
);
const AuditTrail = React.lazy(() =>
  import("../container/ManageSupplier/AuditTrail/AuditTrailList")
);
const DocumentSubmission = React.lazy(() => import("../container/DS-landing"));
const UploadInvoice = React.lazy(() =>
  import("../container/DS-upload-invoice")
);

const CreateInvoice = React.lazy(() =>
  import("../container/DS-create-invoice")
);
const SubmissionHistory = React.lazy(() =>
  import("../container/DS-submission-history/")
);
const ViewHelp = React.lazy(() =>
  import("../container/Administration/Help/ViewHelp/")
);
const InvoiceStatus = React.lazy(() => import("../container/InvoiceStatus"));

/* const Administration = React.lazy(() =>
  import("../container/Administration/components/LandingPage")
); */
const ManageUpload = React.lazy(() =>
  import("../container/Administration/ManageUpload")
);
const ModuleConfig = React.lazy(() =>
  import("../container/Administration/ModuleConfiguration")
);
const AddSubmissionProfiles = React.lazy(() =>
  import(
    "../container/Administration/ModuleConfiguration/SubmissionProfiles/AddSubmissionProfiles"
  )
);
const CustomListMgmt = React.lazy(() =>
  import("../container/Administration/CustomList")
);
const CustomList = React.lazy(() =>
  import("../container/Administration/CustomList/ListItems")
);
const AddCustomListItems = React.lazy(() =>
  import("../container/Administration/CustomList/AddItems")
);
const ClientsList = React.lazy(() =>
  import("../container/Administration/ClientManagement/ClientsList")
);
const ClientSubmit = React.lazy(() =>
  import("../container/Administration/ClientManagement")
);
const AttributeManagement = React.lazy(() =>
  import("../container/Administration/AttributeManagement/index")
);
const RolePermissions = React.lazy(() =>
  import("../container/Administration/RolePermissions")
);
const AddProfileRole = React.lazy(() =>
  import("../container/Administration/RolePermissions/addProfileRole")
);
const UserManagement = React.lazy(() =>
  import("../container/Administration/UserManagement")
);
const AddUser = React.lazy(() =>
  import("../container/Administration/UserManagement/AddUser")
);
const EmailTemplateList = React.lazy(() =>
  import("../container/Administration/EmailTemplate/list")
);
const AddEmailTemplates = React.lazy(() =>
  import("../container/Administration/EmailTemplate/AddEmailTemplate")
);
const NewsAndEvents = React.lazy(() =>
  import("../container/Administration/News_Events")
);
const Addnewsandevents = React.lazy(() =>
  import("../container/Administration/News_Events/AddNewsEvents")
);
const Workflow = React.lazy(() =>
  import("../container/Administration/Workflow")
);
const AddWorkflow = React.lazy(() =>
  import("../container/Administration/Workflow/AddWorkflow")
);
const AdminLogs = React.lazy(() => import("../container/Administration/Logs"));
// const EmailLogsEmail = React.lazy(() =>
//   import("../container/Administration/Logs/EmailLogDetails")
// );
const UserGroup = React.lazy(() =>
  import("../container/Administration/UserGroup")
);
const AddUserGroup = React.lazy(() =>
  import("../container/Administration/UserGroup/AddUserGroup")
);
const Help = React.lazy(() => import("../container/Administration/Help"));
const AddEditFaqMaterial = React.lazy(() =>
  import("../container/Administration/Help/AddEditPages/AddEditFaqMaterial")
);
const AddEditTrainingMaterial = React.lazy(() =>
  import(
    "../container/Administration/Help/AddEditPages/AddEditTrainingMaterial"
  )
);
const AddEditTrainingVideo = React.lazy(() =>
  import("../container/Administration/Help/AddEditPages/AddEditTrainingVideo")
);
const RequestManagement = React.lazy(() =>
  import("../container/RequestManagement")
);
const RequestDetails = React.lazy(() =>
  import("../container/RequestManagement/RequestDetails")
);

const Analytics = React.lazy(() => import("../container/Analytics"));
const AddInvoiceReport = React.lazy(() =>
  import("../container/Analytics/InvoiceList/AddInvoiceReport")
);
export const ResolveRejections = React.lazy(() =>
  import("../container/DS-resolve-rejections")
);

export const PageNotFound = React.lazy(() => import("components/404Notfound"));

const AddAttributes = React.lazy(() =>
  import("../container/Administration/AttributeManagement/AddAttributes")
);

export const UserProfile = React.lazy(() => import("../container/UserProfile"));
export const Reconciliation = React.lazy(() =>
  import("../container/Reconciliation")
);
export const CreateReconciliation = React.lazy(() =>
  import("../container/Reconciliation/CreateReconciliation")
);
export const InvoiceDetails = React.lazy(() =>
  import("../container/Reconciliation/InvoiceDetails")
);
export const POFlip = React.lazy(() =>
  import("../container/DS-create-with-po/")
);

export const EForm = React.lazy(() =>
  import("../container/DS-generate-invoice/")
);
export const InvoiceReview = React.lazy(() =>
  import("../container/DS-Invoice-review")
);

export const AdvancePayments = React.lazy(() =>
  import("../container/AdvancePayments")
);
export const commonAuthRoutes = [
  {
    path: "/",
    name: "Home",
    Component: HomePage,
    Title: "Home Page",
  },
  {
    path: "/view-help",
    name: "route.viewHelp",
    Component: ViewHelp,
    title: "route.viewHelp",
    key: "viewHelp",
  },
];

export const adminRoutes = [
  {
    path: "/administration",
    name: "Administration",
    key: "administration",
    Component: PageNotFound,
    title: "Administration",
  },
  {
    path: "/manage-upload",
    name: "Manage Upload",
    key: "manageUpload",
    Component: ManageUpload,
    title: "Manage Upload",
  },
  {
    path: "/module-attribute-configuration",
    name: "Module & Attribute Configuration ",
    key: "ModuleAttributeConfiguration",
  },
  {
    path: "/module-config",
    name: "Module Config",
    key: "moduleConfig",
    Component: ModuleConfig,
    title: "Module Config",
  },
  {
    path: "/module-config/submission-profiles-add",
    name: "Add",
    Component: AddSubmissionProfiles,
    title: "Add Submission Profiles",
    key: "addSubmissionProfile",
  },
  {
    path: "/module-config/submission-profiles-edit",
    name: "Edit",
    Component: AddSubmissionProfiles,
    title: "Edit Submission Profiles",
    key: "editSubmissionProfile",
  },
  {
    path: "/custom-list-management",
    name: "Custom List Management",
    key: "customListManagement",
    Component: CustomListMgmt,
    title: "Custom List Management",
  },
  {
    path: "/custom-list-management/custom-list-items",
    name: "Custom List Items",
    Component: CustomList,
    title: "Custom List Items",
    key: "customListItems",
  },
  {
    path: "/custom-list-management/custom-list-items/add-list-items",
    name: "Add List Items",
    Component: AddCustomListItems,
    title: "Add List Items",
    key: "addCustomListItems",
  },
  {
    path: "/custom-list-management/custom-list-items/edit-list-items",
    name: "Edit List Items",
    Component: AddCustomListItems,
    title: "Edit List Items",
    key: "editCustomListItems",
  },
  {
    path: "/clients",
    name: "Client List",
    key: "clients",
    Component: ClientsList,
    title: "Client List",
  },
  {
    path: "/clients/list",
    name: "Client List",
    Component: ClientsList,
    title: "Client List",
    key: "clientList",
  },
  {
    path: "/clients/add",
    name: "Add Client",
    Component: ClientSubmit,
    title: "Add Client",
    key: "addClients",
  },
  {
    path: "/clients/edit",
    name: "Edit Client",
    Component: ClientSubmit,
    title: "Edit Client",
    key: "clientsEdit",
  },
  {
    path: "/attribute",
    name: "Attribute Management",
    key: "attributesManagement",
    Component: AttributeManagement,
    title: "Attribute Management",
  },
  {
    path: "/attribute/add",
    name: "Add Attribute",
    key: "addAttributes",
    Component: AddAttributes,
    title: "Attribute Management",
  },
  {
    path: "/attribute/edit",
    name: "Edit Attribute",
    key: "editAttributes",
    Component: AddAttributes,
    title: "Attribute Management",
  },
  {
    path: "/role-permissions",
    name: "Profile Permissions",
    key: "userDetailsProfiles",
    Component: RolePermissions,
    title: "Profile Permissions",
  },
  {
    path: "/role-permissions-list",
    name: "Profile Permissions",
    Component: RolePermissions,
    title: "Profile Permissions",
    key: "userDetailsProfilesList",
  },
  {
    path: "/role-permissions/add",
    name: "Add",
    Component: AddProfileRole,
    title: "Add",
    key: "addUserDetailsProfile",
  },
  {
    path: "/role-permissions/edit",
    name: "Edit",
    Component: AddProfileRole,
    title: "Edit",
    key: "editUserDetailsProfile",
  },
  {
    path: "/users",
    name: "User Management",
    key: "userManagement",
    Component: UserManagement,
    title: "User Management",
  },
  {
    path: "/users-list",
    name: "User Management",
    Component: UserManagement,
    title: "User Management",
    key: "userDetails",
  },
  {
    path: "/users/add",
    name: "Add User Details",
    Component: AddUser,
    title: "User Management",
    key: "addUserDetails",
  },
  {
    path: "/users/edit",
    name: "Edit User Details",
    Component: AddUser,
    title: "User Management",
    key: "editUserDetails",
  },
  {
    path: "/email-templates",
    name: "Email Templates",
    key: "emailTemplates",
    Component: EmailTemplateList,
    title: "Email Templates",
  },
  {
    path: "/email-templates/list",
    name: "Email Templates",
    Component: EmailTemplateList,
    title: "Email Templates",
    key: "emailTemplatesList",
  },
  {
    path: "/email-templates/add",
    name: "Add Email Template",
    Component: AddEmailTemplates,
    title: "Add Email Template",
    key: "addEmailTemplate",
  },
  {
    path: "/email-templates/edit",
    name: " Edit Email Template",
    Component: AddEmailTemplates,
    title: "Edit Email Template",
    key: "editEmailTemplate",
  },
  {
    path: "/news-and-events",
    name: "News & Events",
    key: "newsAndEvents",
    Component: NewsAndEvents,
    title: "News & Events",
  },
  {
    path: "/news-and-events-list",
    name: "News & Events",
    Component: NewsAndEvents,
    title: "News & Events",
    key: "newsAndEventsList",
  },
  {
    path: "/news-and-events/add",
    name: "Add News & Events",
    Component: Addnewsandevents,
    title: "Add News & Events",
    key: "addNewsAndEvents",
  },
  {
    path: "/news-and-events/edit",
    name: "Edit News & Events",
    Component: Addnewsandevents,
    title: "Edit News & Events",
    key: "editNewsAndEvents",
  },
  {
    path: "/workflow",
    name: "Workflow",
    key: "workflow",
    Component: Workflow,
    title: "Workflow",
  },
  {
    path: "/workflow/list",
    name: "Workflow",
    Component: Workflow,
    title: "Workflow",
    key: "workflowList",
  },
  {
    path: "/workflow/add",
    name: "Add Workflow",
    Component: AddWorkflow,
    title: "Add Workflow",
    key: "addWorkflow",
  },
  {
    path: "/workflow/edit",
    name: "Edit Workflow",
    Component: AddWorkflow,
    title: "Edit Workflow",
    key: "workflowEdit",
  },
  {
    path: "/logs",
    name: "Admin Logs",
    key: "adminLogs",
    Component: AdminLogs,
    title: "Admin Logs",
  },
  // {
  //   path: "/logs/email-log-details",
  //   name: "Email Logs Description",
  //   Component: EmailLogsEmail,
  //   title: "Email Logs Description",
  //   key: "emailLogsDetails",
  // },
  {
    path: "/user-group",
    name: "User Group",
    key: "userDetailsGroup",
    Component: UserGroup,
    title: "User Group",
  },
  {
    path: "/user-group-list",
    name: "User Group",
    Component: UserGroup,
    title: "User Group",
    key: "userDetailsGroupList",
  },
  {
    path: "/user-group/add",
    name: "Add",
    Component: AddUserGroup,
    title: "Add",
    key: "addUserDetailsGroup",
  },
  {
    path: "/user-group/edit",
    name: "Edit",
    Component: AddUserGroup,
    title: "Edit",
    key: "editUserDetailsGroup",
  },
  {
    path: "/help",
    name: "Help",
    Component: Help,
    title: "Help",
    key: "help",
  },
  {
    path: "/help/edit/faq",
    name: "Edit FAQ", //"route.help.editFaq",
    Component: AddEditFaqMaterial,
    title: "route.help.editFaq",
    key: "editFaqMaterial",
  },
  {
    path: "/help/add/faq",
    name: "Add FAQ", //"route.help.addFaq",
    Component: AddEditFaqMaterial,
    title: "route.help.addFaq",
    key: "addFaqMaterial",
  },
  {
    path: "/help/edit/training-material",
    name: "route.help.editTm",
    Component: AddEditTrainingMaterial,
    title: "route.help.editTm",
    key: "editTrainingMaterial",
  },
  {
    path: "/help/add/training-material",
    name: "route.help.addTm",
    Component: AddEditTrainingMaterial,
    title: "route.help.addTm",
    key: "addTrainingMaterial",
  },
  {
    path: "/help/edit/training-video",
    name: "route.help.editTv",
    Component: AddEditTrainingVideo,
    title: "route.help.editTv",
    key: "editTrainingVideo",
  },
  {
    path: "/help/add/training-video",
    name: "route.help.addTv",
    Component: AddEditTrainingVideo,
    title: "route.help.addTv",
    key: "addTrainingVideo",
  },
];

export const authRoutes = [
  {
    path: "/invoice-status",
    name: "Invoice Status",
    key: "invoiceStatus",
    Component: InvoiceStatus,
    title: "Invoice Status",
  },
  {
    path: "/document-submission",
    name: "Document Submission",
    key: "documentSubmission",
    Component: DocumentSubmission,
    title: "Document Submission",
  },
  {
    path: "/document-submission/upload-invoice",
    name: "Submit invoice",
    key: "DocumentSubmissionUploadInvoice",
    Component: UploadInvoice,
    title: "Submit Invoice",
  },
  {
    path: "/document-submission/create-invoice",
    name: "Create invoice",
    key: "DocumentSubmissionCreateInvoice",
    Component: CreateInvoice,
    title: "Create Invoice",
  },
  /* {
    path: "/document-submission/resolve-rejections",
    name: "Document Review",
    key: "DocumentSubmissionResolveRejection",
    Component: ResolveRejections,
    title: "Document Review",
  }, */

  {
    path: "/document-submission/invoice-submission-history",
    name: "Submission History",
    key: "DocumentSubmissionInvoiceSubmissionHistory",
    Component: SubmissionHistory,
    title: "Submission History",
  },

  {
    path: "/utility/",
    name: "Utility",
    key: "utilities",
    Component: PageNotFound,
    title: "Utility",
  },
  {
    path: "/insights",
    name: "Report Dashboard",
    key: "ReportDashboard",
    Component: Insights,
    title: "Reporting Dashboard",
  },
  {
    path: "/support",
    name: "Support",
    key: "support",
    Component: Support,
    title: "Support",
  },

  {
    path: "/support/list",
    name: "List",
    key: "supportList",
    Component: SupportRequestsList,
    title: "List",
  },

  {
    path: "/support/new-ticket",
    name: "New ticket",
    key: "supportNew",
    Component: NewRequests,
    title: "New Ticket",
  },
  {
    path: "/support/open/details",
    name: "Support Open Details",
    key: "supportOpenDetail",
    Component: SupportRequestDetails,
    title: "Support Details",
  },
  {
    path: "/support/open/service-now-details",
    name: "Support Open service now Details",
    key: "supportOpenServiceNowDetails",
    Component: SupportServiceNowDetails,
    title: "Support Service Now Details",
  },
  {
    path: "/manage-supplier",
    name: "Manage Supplier",
    key: "manageSupplier",
    Component: ManageSupplier,
    title: "Manage Supplier",
  },
  {
    path: "/manage-supplier/list",
    name: "Manage Supplier List",
    key: "manageSupplierList",
    Component: ManageSupplierList,
    title: "Manage Supplier List",
  },
  {
    path: "/manage-supplier/details",
    name: "Company Details",
    key: "companyDetails",
    Component: ManageSupplierDetails,
    title: "Company Details",
  },
  {
    path: "/manage-supplier/create-new-company",
    name: "Create New Company",
    key: "createNewCompany",
    Component: CreateCompany,
    title: "Create New Company",
  },
  {
    path: "/manage-supplier/audit-trail",
    name: "Audit Trail",
    Component: AuditTrail,
    title: "Audit Trail",
    key: "auditTrail",
  },
  {
    path: "/reconciliation",
    name: "Reconciliation",
    Component: Reconciliation,
    Title: "Reconciliation",
    key: "reconciliation",
  },
  {
    path: "/create-reconciliation",
    name: " Create Reconciliation",
    Component: CreateReconciliation,
    Title: "Create Reconciliation",
    key: "createReconciliation",
  },
  {
    path: "/reconciliation/reconciliation-details",
    name: " Reconciliation Details",
    Component: InvoiceDetails,
    Title: "Reconciliation Details",
    key: "reconciliationDetails",
  },
  {
    path: "/request-management",
    name: "Request Management",
    Component: RequestManagement,
    title: "Request Management",
    key: "requestManagement",
  },
  {
    path: "/request-management/details",
    name: "Request Details",
    Component: RequestDetails,
    title: "Request Details",
    key: "requestManagementDetails",
  },
  {
    path: "/analytics",
    name: "analytics",
    Component: Analytics,
    title: "Analytics",
    key: "analytics",
  },
  {
    path: "/analytics/add-new-report",
    name: "analytics",
    Component: AddInvoiceReport,
    title: "Analytics",
    key: "analyticsNewReport",
  },
  {
    path: "/analytics/edit-report",
    name: "analytics",
    Component: AddInvoiceReport,
    title: "Analytics",
    key: "analyticsEditReport",
  },
  {
    path: "/advance-payments",
    name: "Advance Payments",
    Component: AdvancePayments,
    title: "Advance Payments",
    key: "advancePayments",
  },

  ...adminRoutes,
];
