import { Cols, IconWrapper } from "components/index";
import styled from "styled-components";

export const Left = styled.div``;
export const Center = styled.div``;
export const Right = styled.div``;

export const TopFaceArrow = styled(IconWrapper)``;
export const BottomFaceArrow = styled(IconWrapper)``;
export const ZoomInIcon = styled(IconWrapper)``;
export const ZoomOutIcon = styled(IconWrapper)``;

export const PageInput = styled.input`
  border: none;
  background-color: transparent;
  width: 1ch;
  color: var(--light-clr);
  padding: 0;
  font-size: var(--fs-12);
  font-family: var(--ff-main);
  font-weight: normal;
  outline: none;
`;

export const MaxPageNumber = styled.span`
  border: none;
  background-color: transparent;
  color: var(--light-clr);
  padding: 0;
  outline: none;
  cursor: default;
`;
const ControlStyled = styled(Cols)`
  max-width: 19rem;
  margin: 0 auto;
  min-width: 100%;
  @media (min-width: 36em) {
    min-width: 30em;
  }

  ${Left}, ${Center}, ${Right} {
    flex-grow: 1;
    text-align: center;
    position: relative;
    ::after {
      position: absolute;
      content: "";
      width: var(--onePx);
      height: 100%;
      background-color: var(--light-clr);
      right: 100%;
      top: 0;
      bottom: 0;
    }
  }
  ${Center} {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  background-color: var(--dark-clr);
  border-radius: 0.5rem;
  font-size: var(--fs-12);

  align-items: center;

  ${IconWrapper} {
    margin: 0.5em;
    flex-grow: 1;
    color: var(--light-clr);
    align-items: center;
    svg {
      width: 1.2em;
    }
  }
  ${ZoomInIcon}, ${ZoomOutIcon} {
    svg {
      margin-top: 0.1875rem;
    }
  }
  ${TopFaceArrow} {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export default ControlStyled;
