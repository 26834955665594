import { IconWrapper } from "components/index";
import { Archive, Clock, Cross, Slider } from "components/SVG/index";
import { storage } from "config/storage";
import { jsonPrs } from "helper/index";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "components";
import Preferences from "./Preferences/index";
import NotificationStyled, {
  ActionDetails,
  ActionHighlight,
  ActionMetadata,
  ArchiveItem,
  Importance,
  NotificationInfo,
  NotificationItem,
  NotificationList,
  RemoveNotification,
  Title,
  ViewArchive,
} from "./style";

const Notifications = (props) => {
  const sessionDetails = jsonPrs(storage.getItem("sessionDetails")) || {};

  const { baseRoleId } = sessionDetails || {};
  const [showPreference, setShowPreference] = useState(false);
  const { t } = useTranslation();
  return (
    <NotificationStyled tabIndex="-1">
      {props.fetching ? (
        <Spinner />
      ) : (
        <>
          <Title>
            {props.showArchive ? t("msp.archives") : t("msp.notifications")}
            {baseRoleId === 3 ? (
              <>
                <IconWrapper
                  tabIndex="0"
                  onClick={() => setShowPreference(!showPreference)}
                >
                  <Slider />
                </IconWrapper>
                <Preferences
                  handlePreferenceToggle={props.onPreferenceToggle}
                  show={showPreference}
                  data={props.preferences}
                />
              </>
            ) : null}
          </Title>

          <NotificationList>
            {props.data
              ? props.data.map((item) => {
                  return (
                    <NotificationItem key={item.id} removed={item.removed}>
                      <NotificationInfo>
                        <ActionHighlight>
                          <Importance type={item.importanceID}>
                            {item.importance}
                          </Importance>
                        </ActionHighlight>
                        <ActionDetails>
                          <p>
                            <strong>{item.title}</strong>
                          </p>
                          <p>
                            <small>{item.subtitle}</small>
                          </p>
                        </ActionDetails>
                        <ActionMetadata>
                          <span>
                            <Clock />
                            <small> {item.displayDate}</small>
                          </span>
                          {props.showArchive ? null : (
                            <ArchiveItem
                              onClick={() =>
                                props.moveToArchive({
                                  notificationId: item.id,
                                  notificationType: item.notificationType,
                                  isArchive: 1,
                                  Active: 1,
                                })
                              }
                            >
                              <Archive />
                              <small> {t("msp.archive")}</small>
                            </ArchiveItem>
                          )}
                        </ActionMetadata>
                      </NotificationInfo>
                      {props.showArchive ? null : (
                        <RemoveNotification
                          onClick={() =>
                            props.moveToArchive({
                              notificationId: item.id,
                              notificationType: item.notificationType,
                              isArchive: 1,
                              Active: 0,
                            })
                          }
                          aria-label="crossButton"
                        >
                          <Cross />
                        </RemoveNotification>
                      )}
                    </NotificationItem>
                  );
                })
              : null}
          </NotificationList>

          <ViewArchive onClick={props.toggleNotificationMode}>
            {props.showArchive
              ? t("msp.backToNotifications")
              : t("msp.viewArchives")}
          </ViewArchive>
        </>
      )}
    </NotificationStyled>
  );
};

export default Notifications;
