import { put, takeEvery, call, all } from "redux-saga/effects";
import axios from "../../../../config/axiosCall";
import { buildNotification } from "../../../../config/notification";
import { store } from "react-notifications-component";
import {
  getRequestDetails,
  postRequestDetails,
  putRequestDetails,
} from "../../../../config/requestHeaders";

import {
  INIT_MODULE_LIST_FETCH,
  INIT_ATTRIBUTE_TYPE_LIST_FETCH,
  INIT_ATTRIBUTES_LIST_FETCH,
  INIT_GET_CUSTOM_LIST,
  INIT_GET_VMD_TAB_DATA,
  INIT_GET_VMD_SECTION_DATA,
  INIT_GET_CREATE_COMPANY_SECTION_DATA,
  INIT_GET_SUBMISSION_FIELD_PLACEMENT,
  INIT_GET_SEARCH_FIELD_PLACEMENT,
  INIT_ATTRIBUTES_DELETE,
  INIT_ATTRIBUTE_EDIT,
  INIT_ATTRIBUTE_SAVE,
  INIT_GET_REGEX_LIST,
  INIT_GET_VALIDATION_TYPE,
  INIT_GET_APPROVAL_TYPE_LIST,
} from "./actionTypes";

import {
  fetchingModuleList,
  fetchModuleList,
  moduleListFetchingComplete,
  fetchingAttributeTypeList,
  fetchAttributeTypeList,
  attributeTypeListFetchingComplete,
  fetchingAttributesList,
  fetchAttributesList,
  attributesListFetchingComplete,
  fetchingCustomList,
  fetchCustomList,
  customListFetchingComplete,
  fetchingVMDTabData,
  fetchVMDTabData,
  VMDTabDataFetchingComplete,
  fetchingVMDSectionData,
  fetchVMDSectionData,
  VMDSectionDataFetchingComplete,
  fetchingCreateCompanySectionData,
  fetchCreateCompanySectionData,
  createCompanySectionDataFetchingComplete,
  fetchingSubmissionFieldPlacement,
  fetchSubmissionFieldPlacement,
  SubmissionFieldPlacementFetchingComplete,
  fetchingSearchFieldPlacement,
  SearchFieldPlacementFetchingComplete,
  fetchSearchFieldPlacement,
  attributesDeleteSuccess,
  attributesDeleteFailed,
  attributeSaved,
  attributeSaveFailed,
  fetchingApprovalTypeList,
  fetchApprovalTypeList,
  approvalTypeListFetchingComplete,
  fetchingRegExList,
  fetchRegExList,
  regExListFetchingComplete,
  fetchingValidationType,
  fetchValidationType,
  validationTypeFetchingComplete,
  setDataForAttributeEditCompleted,
} from "../store/actions";

export default function* watchCustomListFetch() {
  yield all([
    takeEvery(INIT_MODULE_LIST_FETCH, getModuleListSaga),
    takeEvery(INIT_ATTRIBUTE_TYPE_LIST_FETCH, getAttributeTypeListSaga),
    takeEvery(INIT_ATTRIBUTES_LIST_FETCH, getAttributesListSaga),
    takeEvery(INIT_GET_CUSTOM_LIST, getCustomListSaga),
    takeEvery(INIT_GET_VMD_TAB_DATA, getVMDTabDataSaga),
    takeEvery(INIT_GET_VMD_SECTION_DATA, getVMDSectionSaga),
    takeEvery(
      INIT_GET_CREATE_COMPANY_SECTION_DATA,
      getCreateCompanySectionSaga
    ),
    takeEvery(
      INIT_GET_SUBMISSION_FIELD_PLACEMENT,
      getSubmissionFieldPlacementSaga
    ),
    takeEvery(INIT_GET_SEARCH_FIELD_PLACEMENT, getSearchFieldPlacementSaga),
    takeEvery(INIT_ATTRIBUTES_DELETE, initAttributeDeleteSaga),
    takeEvery(INIT_ATTRIBUTE_EDIT, initAttributeEditSaga),
    takeEvery(INIT_ATTRIBUTE_SAVE, initAttributeSaveSaga),
    takeEvery(INIT_GET_REGEX_LIST, getRegExListSaga),
    takeEvery(INIT_GET_VALIDATION_TYPE, getValidationTypeSaga),
    takeEvery(INIT_GET_APPROVAL_TYPE_LIST, getApprovalTypeListSaga),
  ]);
}

function* getModuleListSaga() {
  yield put(fetchingModuleList());
  const url = "/Attribute/listModule";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const updatedData =
      response && response.data && response.data.length > 0
        ? response.data
        : [];
    yield put(fetchModuleList(updatedData));
    yield put(moduleListFetchingComplete());
  } catch (error) {
    yield put(moduleListFetchingComplete());
  }
}

function* getAttributeTypeListSaga() {
  yield put(fetchingAttributeTypeList());
  const url = "/Attribute/listAttributeType";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const updatedData =
      response && response.data && response.data.length > 0
        ? response.data.filter(
            (item) =>
              item.attributeTypeID !== "2"
          )
        : [];
    yield put(fetchAttributeTypeList(updatedData));
    yield put(attributeTypeListFetchingComplete());
  } catch (error) {
    yield put(attributeTypeListFetchingComplete());
  }
}

function* getAttributesListSaga() {
  yield put(fetchingAttributesList());
  const url = "/Attribute";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);

    const updatedData =
      response && response.data && response.data.length > 0
        ? response.data
        : [];
    yield put(fetchAttributesList(updatedData));
    // yield put(updatedAttributesList(updatedData));
    yield put(attributesListFetchingComplete());
  } catch (error) {
    yield put(attributesListFetchingComplete());
  }
}

function* getCustomListSaga() {
  yield put(fetchingCustomList());
  const url = "/CustomList/list";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const updatedData =
      response && response.data && response.data.length > 0
        ? response.data
        : [];
    yield put(fetchCustomList(updatedData));
    yield put(customListFetchingComplete());
  } catch (error) {
    yield put(customListFetchingComplete());
  }
}

function* getVMDTabDataSaga() {
  yield put(fetchingVMDTabData());
  const url = "/Common/listVMDTabData";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const updatedData =
      response && response.data && response.data.length > 0
        ? response.data
        : [];
    yield put(fetchVMDTabData(updatedData));
    yield put(VMDTabDataFetchingComplete());
  } catch (error) {
    yield put(VMDTabDataFetchingComplete());
  }
}

function* getVMDSectionSaga(action) {
  yield put(fetchingVMDSectionData());
  const url = `/CustomListItem/listkeys?customListItemParentID=${action.id}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const updatedData =
      response && response.data && response.data.length > 0
        ? response.data
        : [];
    yield put(fetchVMDSectionData(updatedData));
    yield put(VMDSectionDataFetchingComplete());
  } catch (error) {
    yield put(VMDSectionDataFetchingComplete());
  }
}

function* getCreateCompanySectionSaga() {
  yield put(fetchingCreateCompanySectionData());
  const url = "/Common/listkeys?typeName=__CreateCompany";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const updatedData =
      response && response.data && response.data.length > 0
        ? response.data
        : [];
    yield put(fetchCreateCompanySectionData(updatedData));
    yield put(createCompanySectionDataFetchingComplete());
  } catch (error) {
    yield put(createCompanySectionDataFetchingComplete());
  }
}

function* getSubmissionFieldPlacementSaga() {
  yield put(fetchingSubmissionFieldPlacement());
  const url = "/Common/submissionFieldPlacement";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const updatedData =
      response && response.data && response.data.length > 0
        ? response.data
        : [];
    yield put(fetchSubmissionFieldPlacement(updatedData));
    yield put(SubmissionFieldPlacementFetchingComplete());
  } catch (error) {
    yield put(SubmissionFieldPlacementFetchingComplete());
  }
}

function* getSearchFieldPlacementSaga() {
  yield put(fetchingSearchFieldPlacement());
  const url = "/Common/listSearchFieldPlacement";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const updatedData =
      response && response.data && response.data.length > 0
        ? response.data
        : [];
    yield put(fetchSearchFieldPlacement(updatedData));
    yield put(SearchFieldPlacementFetchingComplete());
  } catch (error) {
    yield put(SearchFieldPlacementFetchingComplete());
  }
}

function* initAttributeDeleteSaga(action) {
  const url = "/Attribute";
  const requestDetails = { ...putRequestDetails };

  yield (requestDetails.data = {
    listOfIds: action.id,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200) {
      const id = action.id.split(",").map((id) => id);
      const notification = buildNotification({
        message: "msp.attributeDeletedSuccessfully",
        type: "success",
      });
      yield put(attributesDeleteSuccess(id));
      if (action.deleteSuccess) {
        action.deleteSuccess(response.status);
      }

      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(attributesDeleteFailed());
  }
}

function* initAttributeEditSaga(action) {
  const url = `/Attribute/attributeByID?attributeID=${parseInt(action.id)}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response) {
      // yield put(setDataForAttributeEdit(response.data));
      yield put(setDataForAttributeEditCompleted(response.status));
      if (action.callback) {
        action.callback(response.status, response.data);
      }
    }
  } catch (error) {
    // yield put(submissionProfileSaveFailed());
    console.log(error);
  }
}

function* initAttributeSaveSaga(action) {
  const url = `/Attribute`;
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.formdata);
  try {
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200) {
      yield put(attributeSaved());
      // yield put(attributeSaveFormStatus(response.status));
      if (action.callback) {
        action.callback(response.status);
      }
      if (action.isEditMode) {
        const notification = buildNotification({
          message: "msp.attributeUpdated",
          type: "success",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 1000,
          },
        });
      } else {
        const notification = buildNotification({
          message: "msp.attributeCreated",
          type: "success",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 1000,
          },
        });
      }
    }
  } catch (error) {
    yield put(attributeSaveFailed());
    //console.log(error);
  }
}

function* getRegExListSaga(action) {
  yield put(fetchingRegExList());
  const url = `/Common/listRegEx`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(fetchRegExList(response.data));
    yield put(regExListFetchingComplete());
  } catch (error) {
    yield put(regExListFetchingComplete());
  }
}

function* getApprovalTypeListSaga(action) {
  yield put(fetchingApprovalTypeList());
  const url = `/Common/listApprovalType`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(fetchApprovalTypeList(response.data));
    yield put(approvalTypeListFetchingComplete());
  } catch (error) {
    yield put(approvalTypeListFetchingComplete());
  }
}

function* getValidationTypeSaga(action) {
  yield put(fetchingValidationType());
  const url = `/Common/ValidationType`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(fetchValidationType(response.data));
    yield put(validationTypeFetchingComplete());
  } catch (error) {
    yield put(validationTypeFetchingComplete());
  }
}
