import {
    INIT_VERIFICATION_DATA,
    VERIFICATION_DATA,
    VERIFICATION_FAILED,
    GET_TOKEN_DETAILS,
    FETCHING_TOKEN_DETAIL,
    FETCHING_TOKEN_DETAIL_SUCCESS,
    FETCHING_TOKEN_DETAIL_FAILED
} from "./actionTypes";
export const initVerificationData=(data,callBackFunction)=>{
    return{
        type: INIT_VERIFICATION_DATA,
        data,
        callBackFunction
    }
}
export const verificationData=() =>{
    return{
        type: VERIFICATION_DATA,
    }
}
export const verificationFailed=()=>{
    return{
        type:VERIFICATION_FAILED,
    }
}

export function initTokenDetails(token, callback) {
    return {
      type: GET_TOKEN_DETAILS,
      token,
      callback
    }
}

export function fetchingTokenDetail() {
    return {
        type: FETCHING_TOKEN_DETAIL,
    }
}

export function fetchingTokenDetailSuccess() {
    return {
        type: FETCHING_TOKEN_DETAIL_SUCCESS
    }
}

export function fetchingTokenDetailFailed() {
    return {
        type: FETCHING_TOKEN_DETAIL_FAILED
    }
}