import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import { Cols } from "components/Div";
import { DropzoneContainer } from "./style";
import Regular from "./Regular";
import Small from "./Small";
import { fileExtensionValidation } from "helper/index";

const DropzoneComponent = (props) => {
  const { t } = useTranslation();
  let [replaceButtonClicked, setReplaceButtonClicked] = useState(false);
  const maxSize = props.maxSize ? props.maxSize * 1048576 : 10 * 1048576; // mb to byte

  const HandleReplaceBtnClick = (replaceButtonRef) => {
    useEffect(() => {
      const checkClickOnReplaceBtn = (event) => {
        const { current } = replaceButtonRef;
        setReplaceButtonClicked(
          current && current.contains(event.target) ? true : false
        );
      };
      document.addEventListener("mousedown", checkClickOnReplaceBtn);
      return () => {
        document.removeEventListener("mousedown", checkClickOnReplaceBtn);
      };
    }, [replaceButtonRef]);
  };
  const replaceButtonRef = useRef(null);
  HandleReplaceBtnClick(replaceButtonRef);
  const { type, files } = props;
  const disabled =
    type === "small"
      ? props.customDisableSmall
        ? props.customDisableSmall
        : replaceButtonClicked
        ? false
        : files && Object.keys(files).length > 0
        ? true
        : false
      : props.disabled;

  return (
    <Cols>
      <Dropzone
        onDrop={props.onDrop}
        onDropAccepted={props.onDropAccepted}
        onDropRejected={props.onDropRejected}
        accept={props.acceptableExt}
        disabled={disabled}
        maxFiles={props.maxFiles}
        maxSize={maxSize}
        multiple={props.multiple}
        noClick={props.noClick}
        noDrag={props.noDrag}
        validator={fileExtensionValidation}
      >
        {({
          isDragActive,
          isDragAccept,
          isDragReject,
          acceptedFiles,
          rejectedFiles,
          getInputProps,
          getRootProps,
          inputRef,
        }) => {
          const removeAll = () => {
            acceptedFiles.length = 0;
            acceptedFiles.splice(0, acceptedFiles.length);
            inputRef.current.value = "";
          };

          props.uploaded && removeAll();
          return (
            <React.Fragment>
              <DropzoneContainer
                {...getRootProps()}
                isDragAccept={isDragAccept}
                isDragActive={isDragActive}
                isDragReject={isDragReject}
                border="none"
                type={props.type}
                customPadding={props.customPadding}
                tabIndex="0"
                disabled={disabled}
              >
                <input
                  {...getInputProps()}
                  aria-label={t("msp.fileUploader")}
                />
                {props.type === "small" ? (
                  <Small
                    filePreview={files}
                    replaceButtonRef={replaceButtonRef}
                    removeHandler={props.removeHandler}
                    noRemoveBtn={props.noRemoveBtn}
                    noReplaceBtn={props.noReplaceBtn}
                    showCustomBtn={props.showCustomBtn}
                    customHandler={props.customHandler}
                    customText={props.customText}
                    required={props.required}
                    title={props.title}
                    maxSize={props.maxSize || 10}
                    disabled={disabled}
                  />
                ) : (
                  <Regular
                    required={props.required}
                    acceptedFiles={props.acceptedFiles}
                    desc={props.desc}
                    maxSize={props.maxSize || 10}
                    disabled={disabled}
                  />
                )}
              </DropzoneContainer>
            </React.Fragment>
          );
        }}
      </Dropzone>
    </Cols>
  );
};

export default DropzoneComponent;
