import styled from "styled-components";
import { Rows, Cols, ErrorText } from "components";
import { ButtonWrapper } from "components/index";

export const DateRangeMobile = styled(Rows)`
  margin-bottom: 1em;
  @media (min-width: 36em) {
    display: none;
  }
`;

export const DateRangeDesktop = styled(Cols)`
  display: none;
  gap: 2em;
  max-width: 30em;
  margin-bottom: 1em;
  @media (min-width: 36em) {
    display: flex;
  }
`;

export const DateRangeBtnWrapper = styled(ButtonWrapper)`
  display: flex;
  justify-content: flex-end;
  @media (min-width: 36em) {
    > ${ErrorText} {
      font-size: var(--fs-14);
    }
  }
`;
