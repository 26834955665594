import React from "react";
import { useTranslation } from "react-i18next";
import "react-notifications-component/dist/theme.css";
import {
  NotificationWrapper,
  NotificationTextWrapper,
  NotificationText,
  CloseIconWrapper,
} from "./style";
import { Cross } from "../SVG/index";
import "./Notification.css";

const Notification = (props) => {
  const { t } = useTranslation();
  return (
    <NotificationWrapper type={props.type}>
      <NotificationTextWrapper>
        <NotificationText type={props.type}>
          {t(props.message)}
        </NotificationText>
      </NotificationTextWrapper>
      <CloseIconWrapper type={props.type}>
        <Cross/>
      </CloseIconWrapper>
    </NotificationWrapper>
  );
};

export default Notification;
