export const INIT_FETCH_OCR_DETAILS = "INIT_FETCH_OCR_DETAILS";
export const FETCHING_OCR_DETAILS = "FETCHING_OCR_DETAILS";
export const OCR_DETAILS_FETCHED = "OCR_DETAILS_FETCHED";
export const OCR_DETAILS_FAILED = "OCR_DETAILS_FAILED";

export const INIT_FETCH_PO_DETAILS = "INIT_FETCH_PO_DETAILS";
export const FETCHING_PO_DETAILS = "FETCHING_PO_DETAILS";
export const PO_DETAILS_FETCHED = "PO_DETAILS_FETCHED";
export const PO_DETAILS_FAILED = "PO_DETAILS_FAILED";

export const CLEAN_REVIEW_INVOICE_DETAILS = "CLEAN_REVIEW_INVOICE_DETAILS";
