import { put, takeEvery, call, all } from "redux-saga/effects";

import axios from "../../../../../config/axiosCall";

import { buildNotification } from "../../../../../config/notification";
import { store } from "react-notifications-component";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../../../config/requestHeaders";

import {
  INIT_GET_SEARCH_FIELDS_LIST,
  INIT_SEARCH_CONFIG_SAVE,
} from "./actionTypes";

import {
  fetchingGetSearchFieldsList,
  fetchGetSearchFieldsList,
  getSearchFieldsListFetchingComplete,
  searchConfigSaving,
  searchConfigSaved,
  searchConfigSavedFailed,
} from "../store/actions";

export default function* watchCustomListFetch() {
  yield all([
    takeEvery(INIT_GET_SEARCH_FIELDS_LIST, getSearchFieldsListSaga),
    takeEvery(INIT_SEARCH_CONFIG_SAVE, initSearchConfigarationSaveSaga),
  ]);
}

function* getSearchFieldsListSaga(action) {
  yield put(fetchingGetSearchFieldsList());
  const url = `/Module/ListSearchFields?moduleID=5`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(fetchGetSearchFieldsList(response.data));
    action.payload.setFieldsToState(true);
    yield put(getSearchFieldsListFetchingComplete());
  } catch (error) {
    yield put(getSearchFieldsListFetchingComplete());
  }
}

function* initSearchConfigarationSaveSaga(action) {
  yield put(searchConfigSaving());
  const url = `/Module/SearchSaveConfiguration`;
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = action.formdata);
  try {
    let response = yield call(axios, url, requestDetails);
    if (response) {
      const notification = buildNotification({
        message: "msp.searchConfigSaved",
        type: "success",
      });
      yield put(searchConfigSaved());
      if (action.searchConfigSaveSuccess) {
        action.searchConfigSaveSuccess(response.status);
      }
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(searchConfigSavedFailed());
  }
}
