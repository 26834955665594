import {
  INIT_WORKFLOW_LIST_FETCH,
  FETCHING_WORKFLOW_LIST,
  FETCH_WORKFLOW_LIST,
  WORKFLOW_LIST_FETCH_COMPLETE,
  INIT_DELETE_WORKFLOW,
  DELETING_WORKFLOW,
  WORKFLOW_DELETED,
  INIT_CONDITION_LIST_FETCH,
  FETCHING_CONDITION_LIST,
  FETCH_CONDITION_LIST,
  CONDITION_LIST_FETCH_COMPLETE,
  INIT_FETCH_WORKFLOW_BY_ID,
  FETCHING_WORKFLOW_BY_ID,
  FETCH_WORKFLOW_BY_ID,
  WORKFLOW_BY_ID_FETCH_COMPLETE,
  INIT_WORKFLOW_SAVE,
  WORKFLOW_SAVED,
  WORKFLOW_SAVE_FAILED,
} from "./actionTypes";

export const initFetchWorkflowList = () => {
  return {
    type: INIT_WORKFLOW_LIST_FETCH,
  };
};

export const fetchingWorkflowList = () => {
  return {
    type: FETCHING_WORKFLOW_LIST,
  };
};

export const fetchWorkflowList = (data) => {
  return {
    type: FETCH_WORKFLOW_LIST,
    data,
  };
};

export const workFlowListFetchComplete = () => {
  return {
    type: WORKFLOW_LIST_FETCH_COMPLETE,
  };
};

export const initDeleteWorkflows = (payload, callback) => {
  return {
    type: INIT_DELETE_WORKFLOW,
    payload,
    callback,
  };
};
export const deletingWorkflow = () => {
  return {
    type: DELETING_WORKFLOW,
  };
};
export const workflowDeleted = () => {
  return {
    type: WORKFLOW_DELETED,
  };
};

export const initFetchConditionList = () => {
  return {
    type: INIT_CONDITION_LIST_FETCH,
  };
};

export const fetchingConditionList = () => {
  return {
    type: FETCHING_CONDITION_LIST,
  };
};

export const fetchConditionList = (data) => {
  return {
    type: FETCH_CONDITION_LIST,
    data,
  };
};

export const conditionListFetchComplete = () => {
  return {
    type: CONDITION_LIST_FETCH_COMPLETE,
  };
};

export const initGetWorkflowById = (id, callback) => {
  return {
    type: INIT_FETCH_WORKFLOW_BY_ID,
    id,
    callback,
  };
};

export const fetchingWorkflowById = () => {
  return {
    type: FETCHING_WORKFLOW_BY_ID,
  };
};

export const fetchWorkflowById = (data) => {
  return {
    type: FETCH_WORKFLOW_BY_ID,
    data,
  };
};

export const workflowByIdFetchingComplete = () => {
  return {
    type: WORKFLOW_BY_ID_FETCH_COMPLETE,
  };
};

export const initSaveWorkflow = (payload, callback) => {
  return {
    type: INIT_WORKFLOW_SAVE,
    payload,
    callback,
  };
};

export const workflowSaved = () => {
  return {
    type: WORKFLOW_SAVED,
  };
};

export const workflowSaveFailed = () => {
  return {
    type: WORKFLOW_SAVE_FAILED,
  };
};
