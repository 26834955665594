import InputAddon from "components/InputAddon/index";
import Label from "components/Label";

import styled from "styled-components";
import InputControl from "../Input/style";

const FloatingControl = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  ${InputControl} {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }
  ${InputControl} {
    height: 3.5em;
    padding-top: 1rem;
    &:not(:focus):invalid {
      & + ${Label} {
        color: ${(props) => (props.visited ? "var(--error-clr)" : "")};
      }
    }
  }

  ${Label} {
    font-family: var(--ff-main);
    padding-left: 1rem;
    padding-right: 0.5rem;
    color: ${(props) =>
      props.disabled
        ? "var(--primary-text-clr)"
        : props.error
        ? "var(--error-clr)"
        : "var(--grey-clr)"};
    pointer-events: none;
    position: absolute;
    transform: translate(0, 1.2em) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
    max-width: calc(100% - (0.65rem));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:focus-within ${Label} {
    color: var(--primary-clr);
  }
  &:hover ${InputControl}, &:focus-within ${InputControl} {
    ${(props) => (props.icon ? `clip-path: inset(-5px 0px -5px -5px)` : "")};
  }
  &:hover
    ${InputAddon},
    &:focus-within
    ${InputAddon},
    &:hover
    ${InputControl}
    ~ ${InputAddon},
    &:focus-within
    ${InputControl}
    ~ ${InputAddon},
    &:hover
    ${InputControl},
    &:focus-within
    ${InputControl} {
    box-shadow: rgb(102 187 255 / 50%) 0.25em 0 0.8rem;
    border-color: var(--primary-clr);
    outline: none;
  }

  &:focus-within
    ${Label},
    &
    input:not(:placeholder-shown)
    + ${Label},
    &
    input:-webkit-autofill
    + label {
    transform: translate(0, 0.5em) scale(0.75);
    font-family: var(--ff-medium);
  }
`;

export default FloatingControl;
