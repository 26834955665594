import {
  INIT_FETCH_USER_GROUP_LIST,
  FETCHING_USER_GROUP_LIST,
  FETCH_USER_GROUP_LIST,
  USER_GROUP_LIST_FETCH_COMPLETE,
  INIT_UPDATE_USER_GROUP_STATUS,
  UPDATING_USER_GROUP_STATUS,
  USER_GROUP_STATUS_UPDATE_COMPLETED,
  INIT_DELETE_USER_GROUP,
  DELETING_USER_GROUP,
  USER_GROUP_DELETED,
  INIT_FETCH_BASE_ROLE_LIST,
  FETCHING_BASE_ROLE_LIST,
  FETCH_BASE_ROLE_LIST,
  BASE_ROLE_LIST_FETCH_COMPLETE,
  INIT_FETCH_USER_LIST,
  FETCHING_USER_LIST,
  FETCH_USER_LIST,
  USER_LIST_FETCH_COMPLETE,
  INIT_FETCH_USER_GROUP_BY_ID,
  FETCHING_USER_GROUP_BY_ID,
  USER_GROUP_BY_ID_FETCH_COMPLETE,
  INIT_SAVE_USER_GROUP,
  USER_GROUP_SAVED,
  USER_GROUP_SAVE_FAILED,
} from "./actionTypes";

export const initUserGroupList = (payload) => {
  return {
    type: INIT_FETCH_USER_GROUP_LIST,
    payload,
  };
};
export const fetchingUserGroupList = () => {
  return {
    type: FETCHING_USER_GROUP_LIST,
  };
};

export const fetchUserGroupList = (data) => {
  return {
    type: FETCH_USER_GROUP_LIST,
    data,
  };
};

export const userGroupListFetchingComplete = () => {
  return {
    type: USER_GROUP_LIST_FETCH_COMPLETE,
  };
};

export const initUpdateUserGroupStatus = (payload, callback) => {
  return {
    type: INIT_UPDATE_USER_GROUP_STATUS,
    payload,
    callback,
  };
};

export const updatingUserGroupStatus = () => {
  return { type: UPDATING_USER_GROUP_STATUS };
};

export const userGroupStatusUpdated = () => {
  return { type: USER_GROUP_STATUS_UPDATE_COMPLETED };
};

export const initDeleteUserGroup = (payload, callback) => {
  return {
    type: INIT_DELETE_USER_GROUP,
    payload,
    callback,
  };
};

export const deletingUserGroup = () => {
  return {
    type: DELETING_USER_GROUP,
  };
};

export const userGroupDeleted = () => {
  return {
    type: USER_GROUP_DELETED,
  };
};

export const initBaseRoleList = () => {
  return {
    type: INIT_FETCH_BASE_ROLE_LIST,
  };
};
export const fetchingBaseRoleList = () => {
  return {
    type: FETCHING_BASE_ROLE_LIST,
  };
};

export const fetchBaseRoleList = (data) => {
  return {
    type: FETCH_BASE_ROLE_LIST,
    data,
  };
};

export const baseRoleListFetchingComplete = () => {
  return {
    type: BASE_ROLE_LIST_FETCH_COMPLETE,
  };
};

export const initUserList = (payload, callback) => {
  return {
    type: INIT_FETCH_USER_LIST,
    payload,
    callback,
  };
};
export const fetchingUserList = () => {
  return {
    type: FETCHING_USER_LIST,
  };
};

export const fetchUserList = (data) => {
  return {
    type: FETCH_USER_LIST,
    data,
  };
};

export const userListFetchingComplete = () => {
  return {
    type: USER_LIST_FETCH_COMPLETE,
  };
};

export const initGetUserGroupById = (payload, callback) => {
  return {
    type: INIT_FETCH_USER_GROUP_BY_ID,
    payload,
    callback,
  };
};

export const fetchingUserGroupById = () => {
  return {
    type: FETCHING_USER_GROUP_BY_ID,
  };
};

export const userGroupByIdFetchingComplete = () => {
  return {
    type: USER_GROUP_BY_ID_FETCH_COMPLETE,
  };
};

export const initSaveUserGroup = (payload, callback) => {
  return {
    type: INIT_SAVE_USER_GROUP,
    payload,
    callback,
  };
};

export const userGroupSaved = () => {
  return {
    type: USER_GROUP_SAVED,
  };
};

export const userGroupSaveFailed = () => {
  return {
    type: USER_GROUP_SAVE_FAILED,
  };
};
