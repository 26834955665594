import PropTypes from "prop-types";
import { IconWrapper, Img } from "components/index";
import React from "react";
import { useTranslation } from "react-i18next";
import LangListStyled, {
  LanguageCard,
  LanguageCardButtons,
  LanguageCode,
  LanguageContainer,
  MetaData,
} from "./style";
import { Trash, DownloadArrow } from "components/SVG/index";
import EditIcon from "assets/images/Common/edit.svg";
const LanguageList = (props) => {
  const { t } = useTranslation();
  return props.list ? (
    <LangListStyled>
      {props.list.map((item) => {
        return (
          <LanguageCard key={item.langCode}>
            <LanguageContainer>
              <LanguageCode>{item.lang}</LanguageCode>
              <MetaData>
                {item.details
                  ? item.details.map((detail, key) => {
                      return (
                        <div key={key}>
                          <p>{detail.label}</p>
                          <p>
                            <strong>{detail.value}</strong>
                            {detail.downloadableContent ? (
                              <IconWrapper
                                onClick={() =>
                                  props.downloadFile(
                                    detail.value,
                                    item.langCode,
                                    detail.label
                                  )
                                }
                              >
                                <DownloadArrow />
                              </IconWrapper>
                            ) : null}
                          </p>
                        </div>
                      );
                    })
                  : null}
              </MetaData>
            </LanguageContainer>
            <LanguageCardButtons>
              <IconWrapper
                onClick={() => props.onMutationEdit(item.langCode)}
                aria-label={t("msp.edit")}
              >
                <Img src={EditIcon} alt={t("msp.edit")} />
              </IconWrapper>
              <IconWrapper
                onClick={() => props.onMutationDelete(item.langCode)}
                aria-label={t("msp.delete")}
              >
                <Trash />
              </IconWrapper>
            </LanguageCardButtons>
          </LanguageCard>
        );
      })}
    </LangListStyled>
  ) : null;
};

LanguageList.propTypes = {
  list: PropTypes.array,
  onMutationDelete: PropTypes.func,
  onMutationEdit: PropTypes.func,
};

export default LanguageList;
