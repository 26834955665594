import React from "react";
import SvgIcon from "./style";

const PencilSquare = () => {
  return (
    <SvgIcon
      viewBox="0 0 20 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill-rule="evenodd">
        <g transform="translate(-92.000000, -281.000000)">
          <g transform="translate(92.000000, 280.000000)">
            <path d="M14.5069445,12.0659722 L15.2013889,11.3715278 C15.3333333,11.2395833 15.5555556,11.3333333 15.5555556,11.5173611 L15.5555556,17.2222222 C15.5555556,18.1423611 14.8090278,18.8888889 13.8888889,18.8888889 L1.66666665,18.8888889 C0.746527755,18.8888889 -2.50000003e-08,18.1423611 -2.50000003e-08,17.2222222 L-2.50000003e-08,4.99999999 C-2.50000003e-08,4.0798611 0.746527755,3.33333332 1.66666665,3.33333332 L11.8159722,3.33333332 C12,3.33333332 12.09375,3.55902776 11.9618056,3.68749998 L11.2673611,4.38194443 C11.2291667,4.42013887 11.1736111,4.44444443 11.1215278,4.44444443 L1.66666665,4.44444443 C1.36111109,4.44444443 1.11111109,4.69444443 1.11111109,4.99999999 L1.11111109,17.2222222 C1.11111109,17.5277778 1.36111109,17.7777778 1.66666665,17.7777778 L13.8888889,17.7777778 C14.1944445,17.7777778 14.4444445,17.5277778 14.4444445,17.2222222 L14.4444445,12.2118056 C14.4444445,12.15625 14.4652778,12.1041667 14.5069445,12.0659722 Z M19.5729167,5.42708332 L8.72222222,16.2777778 L5.25347221,16.6631945 C4.78819443,16.7152778 4.39583332,16.3229167 4.44791665,15.8576389 L4.83333332,12.3888889 L15.6840278,1.53819442 C16.2534722,0.968749977 17.1770834,0.968749977 17.7465278,1.53819442 L19.5729167,3.36458332 C20.1423611,3.93402776 20.1423611,4.85763888 19.5729167,5.42708332 L19.5729167,5.42708332 Z M16.3229167,7.10763888 L14.0069445,4.79166665 L5.89583332,12.8993056 L5.60763888,15.5069445 L8.21527777,15.21875 L16.3229167,7.10763888 Z M18.7881945,4.14930554 L16.9618056,2.32291665 C16.8298611,2.1909722 16.6076389,2.18402776 16.4722222,2.32291665 L14.7916667,4.00347221 L17.1076389,6.31944444 L18.7881945,4.63888888 C18.9236111,4.50694443 18.9236111,4.28472221 18.7881945,4.14930554 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default PencilSquare;
