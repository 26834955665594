import styled from "styled-components";

const MainContentWrapper = styled.section`
  padding: 0.5rem 0.75rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
`;

export default MainContentWrapper;
