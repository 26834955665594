import { fromJS } from "immutable";
import {
  FETCHING_BULK_UPLOAD_TYPES,
  FETCH_BULK_UPLOAD_TYPES,
  BULK_UPLOAD_TYPES_FETCH_COMPLETE,
  FETCHING_RECENT_UPLOADED_FILES,
  FETCH_RECENT_UPLOADED_FILES,
  RECENT_UPLOADED_FILES_FETCH_COMPLETE,
  FETCHING_UPLOADED_FILES_LIST,
  FETCH_UPLOADED_FILES_LIST,
  UPLOADED_FILES_LIST_FETCH_COMPLETE,
  FETCHING_TEMPLATE,
  TEMPLATE_FETCH_COMPLETE,
  FETCHING_BULK_UPLOAD_FILE,
  BULK_UPLOAD_FILE_FETCH_COMPLETE,
  SENDING_EMAIL,
  EMAIL_SENT,
  FETCHING_BULK_UPLOAD_FILE_STATUS_LIST,
  FETCH_BULK_UPLOAD_FILE_STATUS_LIST,
  BULK_UPLOAD_FILE_STATUS_LIST_FETCH_COMPLETE,
  BULK_USER_UPLOADING,
  SET_BULK_USER_UPLOAD_PROGRESSION,
  BULK_USER_UPLOAD_COMPLETE,
  BULK_USER_UPLOAD_RESET,
  EMAIL_SENT_SUCCESS,
  EMAIL_SENT_FAILED,
} from "./actionTypes";
export const initialState = fromJS({
  fetchingBulkUploadTypes: false,
  bulkUploadTypes: [],
  fetchingRecentUploadedFiles: false,
  recentUploadedFiles: "",
  fetchingUploadedFilesList: false,
  uploadedFilesList: "",
  fetchingTemplate: false,
  sendingEmail: false,
  emailSentList: [],
  fetchingBulkUploadFile: false,
  bulkUserCreation: false,
  fetchingBulkUploadFileStatusList: false,
  bulkUploadFileStatusList: "",
  uploadProgress: [],
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_BULK_UPLOAD_TYPES: {
      return state.set("fetchingBulkUploadTypes", true);
    }

    case FETCH_BULK_UPLOAD_TYPES: {
      return state.set("bulkUploadTypes", action.data);
    }

    case BULK_UPLOAD_TYPES_FETCH_COMPLETE: {
      return state.set("fetchingBulkUploadTypes", false);
    }

    case FETCHING_RECENT_UPLOADED_FILES: {
      return state.set("fetchingRecentUploadedFiles", true);
    }

    case FETCH_RECENT_UPLOADED_FILES: {
      return state.set("recentUploadedFiles", action.data);
    }

    case RECENT_UPLOADED_FILES_FETCH_COMPLETE: {
      return state.set("fetchingRecentUploadedFiles", false);
    }

    case FETCHING_UPLOADED_FILES_LIST: {
      return state.set("fetchingUploadedFilesList", true);
    }

    case FETCH_UPLOADED_FILES_LIST: {
      return state.set("uploadedFilesList", action.data);
    }

    case UPLOADED_FILES_LIST_FETCH_COMPLETE: {
      return state.set("fetchingUploadedFilesList", false);
    }

    case FETCHING_TEMPLATE: {
      return state.set("fetchingTemplate", true);
    }

    case TEMPLATE_FETCH_COMPLETE: {
      return state.set("fetchingTemplate", false);
    }
    case FETCHING_BULK_UPLOAD_FILE: {
      return state.set("fetchingBulkUploadFile", true);
    }

    case BULK_UPLOAD_FILE_FETCH_COMPLETE: {
      return state.set("fetchingBulkUploadFile", false);
    }

    case SENDING_EMAIL: {
      return state.set("sendingEmail", true);
    }

    case EMAIL_SENT: {
      return state.set("emailSentList", [
        ...state.get("emailSentList"),
        action.fileDataID,
      ]);
    }

    case EMAIL_SENT_SUCCESS:
    case EMAIL_SENT_FAILED: {
      return state.set("sendingEmail", false);
    }

    case FETCHING_BULK_UPLOAD_FILE_STATUS_LIST: {
      return state.set("fetchingBulkUploadFileStatusList", true);
    }

    case FETCH_BULK_UPLOAD_FILE_STATUS_LIST: {
      return state.set("bulkUploadFileStatusList", action.data);
    }

    case BULK_UPLOAD_FILE_STATUS_LIST_FETCH_COMPLETE: {
      return state.set("fetchingBulkUploadFileStatusList", false);
    }

    case BULK_USER_UPLOADING: {
      return state.set("uploadProgress", [
        ...state.get("uploadProgress"),
        action.data,
      ]);
    }

    case SET_BULK_USER_UPLOAD_PROGRESSION: {
      const uploadProgress = state.get("uploadProgress");
      const updatedUploadProgress = uploadProgress.map((progress) => {
        return {
          ...progress,
          sent: progress.sent,
        };
      });

      return state.set("uploadProgress", updatedUploadProgress);
    }

    case BULK_USER_UPLOAD_COMPLETE: {
      const uploadProgress = state.get("uploadProgress");
      const updatedUploadProgress = uploadProgress.map(() => {
        return {
          sent: 100,
          uploaded: true,
        };
      });
      return state.set("uploadProgress", updatedUploadProgress);
    }

    case BULK_USER_UPLOAD_RESET: {
      return state.set("uploadProgress", []);
    }

    default:
      return state;
  }
};
export default reducer;
