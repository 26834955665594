import { fromJS } from "immutable";
import {
  RECONCILIATION_LIST_REQUEST,
  RECONCILIATION_LIST_FETCH_COMPLETE,
  SUPPLIER_LIST_FETCH_COMPLETE,
  SAVE_RECONCILIATION_COMPLETE,
  RESET_SAVE_RECONCILIATION_STATUS,
  SAVE_RECONCILIATION_REQUEST,
  START_RECONCILIATION_REQUEST,
  START_RECONCILIATION_COMPLETE,
  GET_RECONCILED_REPORTS_REQUEST,
  RECONCILED_REPORTS_FETCH_COMPLETE,
  GET_RECONCILIATION_COMMENTS_REQUEST,
  RECONCILIATION_COMMENTS_FETCH_COMPLETE,
  SAVE_RECONCILIATION_COMMENTS_REQUEST,
  SAVE_RECONCILIATION_COMMENTS_COMPLETE,
  GET_SUGGESTED_INVOICES_REQUEST,
  SUGGESTED_INVOICES_FETCH_COMPLETE,
  TEMPLATE_UPLOADING,
  SET_UPLOAD_PROGRESSION,
  TEMPLATE_UPLOAD_COMPLETE,
  TEMPLATE_UPLOAD_RESET,
  SUPPLIER_LIST_REQUEST,
} from "./actionTypes";

export const initialState = fromJS({
  isReconcileLoading: false,
  reconciliationList: [],
  reconcialiationData: [],
  isSupplierLoading: false,
  supplierList: [],
  totalSupplierCount: 0,
  isUploading: false,
  uploadStatus: "",
  isStartReconciliation: false,
  reconciliationStatus: "",
  isReconciledReportsLoading: false,
  reconcileDetails: [],
  isReconciliationCommentLoading: false,
  reconciliationComments: [],
  isSavingComments: false,
  isSuggestedInvoicesLoading: false,
  suggestedInvoiceList: [],
  uploadProgress: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECONCILIATION_LIST_REQUEST:
      return state.set("isReconcileLoading", true);

    case RECONCILIATION_LIST_FETCH_COMPLETE:
      return state
        .set("reconciliationList", action.reconciliationList)
        .set("reconciliationData", action.reconciliationList)
        .set("isReconcileLoading", false);

    case SUPPLIER_LIST_REQUEST:
      return state.set("isSupplierLoading", true);

    case SUPPLIER_LIST_FETCH_COMPLETE:
      return state
        .set("supplierList", action.supplierData)
        .set("totalSupplierCount", action.totalSupplierCount)
        .set("isSupplierLoading", false);

    case SAVE_RECONCILIATION_REQUEST:
      return state.set("isUploading", true);

    case SAVE_RECONCILIATION_COMPLETE:
      return state.set("isUploading", false).set("uploadStatus", action.status);

    case RESET_SAVE_RECONCILIATION_STATUS:
      return state.set("uploadStatus", "");
    case START_RECONCILIATION_REQUEST:
      return state.set("isStartReconciliation", true);
    case START_RECONCILIATION_COMPLETE:
      return state
        .set("isStartReconciliation", false)
        .set("reconciliationStatus", action.status);
    case GET_RECONCILED_REPORTS_REQUEST:
      return state.set("isReconciledReportsLoading", true);
    case RECONCILED_REPORTS_FETCH_COMPLETE:
      return state
        .set("isReconciledReportsLoading", false)
        .set("reconcileDetails", action.reconcileDetails);
    case GET_RECONCILIATION_COMMENTS_REQUEST:
      return state.set("isReconciliationCommentLoading", true);
    case RECONCILIATION_COMMENTS_FETCH_COMPLETE:
      return state
        .set("isReconciliationCommentLoading", false)
        .set("reconciliationComments", action.reconciliationComments);
    case SAVE_RECONCILIATION_COMMENTS_REQUEST:
      return state.set("isSavingComments", true);
    case SAVE_RECONCILIATION_COMMENTS_COMPLETE:
      return state
        .set("isSavingComments", false)
        .set("commentSaveStatus", action.commentSaveStatus);
    case GET_SUGGESTED_INVOICES_REQUEST:
      return state.set("isSuggestedInvoicesLoading", true);
    case SUGGESTED_INVOICES_FETCH_COMPLETE:
      return state
        .set("isSuggestedInvoicesLoading", false)
        .set("suggestedInvoiceList", action.suggestedInvoiceList);
    case TEMPLATE_UPLOADING: {
      return state.set("uploadProgress", [
        ...state.get("uploadProgress"),
        action.data,
      ]);
    }
    case SET_UPLOAD_PROGRESSION: {
      const uploadProgress = state.get("uploadProgress");
      const updatedUploadProgress = uploadProgress.map((progress) => {
        return {
          ...progress,
          sent: progress.sent,
        };
      });

      return state.set("uploadProgress", updatedUploadProgress);
    }
    case TEMPLATE_UPLOAD_COMPLETE: {
      const uploadProgress = state.get("uploadProgress");
      const updatedUploadProgress = uploadProgress.map((progress) => {
        return {
          ...progress,
          sent: progress.sent,
          uploaded: progress.uploaded,
        };
      });
      return state.set("uploadProgress", updatedUploadProgress);
    }
    case TEMPLATE_UPLOAD_RESET: {
      return state.set("uploadProgress", []);
    }

    default:
      return state;
  }
};

export default reducer;
