import { fromJS } from "immutable";
import {
  INIT_UPDATE_PASSWORD_ON_LOGIN,
  UPDATE_PASSWORD_ON_LOGIN_SUCCESS,
  UPDATE_PASSWORD_ON_LOGIN_FAILED,
} from "./actionTypes";

export const initialState = fromJS({
  updatePasswordOnLogin: false,
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_UPDATE_PASSWORD_ON_LOGIN: {
      return state.set("updatePasswordOnLogin", true);
    }
    case UPDATE_PASSWORD_ON_LOGIN_SUCCESS: {
      return state.set("updatePasswordOnLogin", false);
    }
    case UPDATE_PASSWORD_ON_LOGIN_FAILED: {
      return state.set("updatePasswordOnLogin", false);
    }
    default:
      return state;
  }
};

export default reducer;
