import {
  SET_FORM_VALUES,
  CLEAR_FORM_VALUES,
  SET_ISPO,
  INIT_COUNTRY_LIST_FETCH,
  FETCHING_COUNTRY_LIST,
  FETCH_COUNTRY_LIST,
  COUNTRY_LIST_FETCH_COMPLETE,
  INIT_PROFILE_LIST_FETCH,
  FETCHING_PROFILE_LIST,
  FETCH_PROFILE_LIST,
  PROFILE_LIST_FETCH_COMPLETE,
  INIT_SUPPLIER_LIST_FETCH,
  FETCHING_SUPPLIER_LIST,
  FETCH_SUPPLIER_LIST,
  SUPPLIER_LIST_FETCH_COMPLETE,
  INIT_PO_NUMBER_FETCH,
  FETCHING_PO_NUMBER,
  FETCH_PO_NUMBER,
  PO_NUMBER_FETCH_COMPLETE,
  INIT_FETCH_ADDITIONAL_FIELDS,
  FECTHING_ADDITIONAL_FIELDS,
  FETCH_ADDITIONAL_FIELDS,
  ADDITIONAL_FIELDS_FETCH_COMPLETE,
  CLEAR_ADDITIONAL_FIELDS,
  INIT_STANDARD_FIELDS_FETCH,
  FETCHING_STANDARD_FIELDS,
  FETCH_STANDARD_FIELDS,
  STANDARD_FIELDS_FETCH_COMPLETE,
  INIT_DOCUMENT_TYPES_FETCH,
  FETCHING_DOCUMENT_TYPES,
  FETCH_DOCUMENT_TYPES,
  DOCUMENT_TYPES_FETCH_COMPLETE,
  SET_VISITED,
  RESET_VISITED,
  SET_CLIENT_REF_VALUES,
  RESET_COMMON_DS_VALUES,
  SET_SUBMISSION_ID,
  SET_IS_OCR_EXTRACTED,
  SET_PO_FILTERS,
} from "./actionTypes";

export const setFormValues = (data) => ({ type: SET_FORM_VALUES, data });
export const clearFormValues = () => ({ type: CLEAR_FORM_VALUES });

export const setIsPO = (data) => ({ type: SET_ISPO, data });

export const initStandardFieldsFetch = () => ({
  type: INIT_STANDARD_FIELDS_FETCH,
});
export const fetchingStandardFields = () => ({
  type: FETCHING_STANDARD_FIELDS,
});

export const fetchStandardFieldsList = (data) => ({
  type: FETCH_STANDARD_FIELDS,
  data: data,
});

export const standardFieldsFetchingComplete = () => ({
  type: STANDARD_FIELDS_FETCH_COMPLETE,
});

export const initCountryDropdownList = () => ({
  type: INIT_COUNTRY_LIST_FETCH,
});

export const fetchingCountry = () => ({
  type: FETCHING_COUNTRY_LIST,
});

export const fetchCountryDropdownList = (data) => ({
  type: FETCH_COUNTRY_LIST,
  data: data,
});

export const countryFetchingComplete = () => ({
  type: COUNTRY_LIST_FETCH_COMPLETE,
});

export const initProfileDropdownList = (countryID) => ({
  type: INIT_PROFILE_LIST_FETCH,
  payload: { countryID },
});
export const fetchingProfiles = () => ({
  type: FETCHING_PROFILE_LIST,
});
export const fetchProfileDropdownList = (data) => ({
  type: FETCH_PROFILE_LIST,
  data: data,
});
export const profileFetchingComplete = () => ({
  type: PROFILE_LIST_FETCH_COMPLETE,
});

export const initSupplierDropdownList = (payload) => ({
  type: INIT_SUPPLIER_LIST_FETCH,
  payload,
});

export const fetchingSuppliers = () => ({
  type: FETCHING_SUPPLIER_LIST,
});
export const fetchSupplierDropdownList = (data) => ({
  type: FETCH_SUPPLIER_LIST,
  data: data,
});
export const supplierFetchingComplete = () => ({
  type: SUPPLIER_LIST_FETCH_COMPLETE,
});

export const initFetchPONumberList = (payload) => ({
  type: INIT_PO_NUMBER_FETCH,
  payload,
});

export const fetchingPONumbers = () => ({
  type: FETCHING_PO_NUMBER,
});
export const fetchPONumbers = (data) => ({
  type: FETCH_PO_NUMBER,
  data: data,
});
export const poNumbersFetchComplete = () => ({
  type: PO_NUMBER_FETCH_COMPLETE,
});

export const initFetchAdditionalFields = (data) => ({
  type: INIT_FETCH_ADDITIONAL_FIELDS,
  data,
});

export const fetchAdditionalingFields = () => ({
  type: FECTHING_ADDITIONAL_FIELDS,
});

export const fetchAdditionalFields = (data) => ({
  type: FETCH_ADDITIONAL_FIELDS,
  data,
});
export const additionalingFieldsFetchComplete = () => ({
  type: ADDITIONAL_FIELDS_FETCH_COMPLETE,
});

export const clearAdditionalFields = () => ({
  type: CLEAR_ADDITIONAL_FIELDS,
});

export const initDocumentTypesFetch = () => ({
  type: INIT_DOCUMENT_TYPES_FETCH,
});
export const fetchingDocumentTypes = () => ({
  type: FETCHING_DOCUMENT_TYPES,
});
export const fetchDocumentTypes = (data) => ({
  type: FETCH_DOCUMENT_TYPES,
  data,
});
export const fetchDocumentTypesCompleted = () => ({
  type: DOCUMENT_TYPES_FETCH_COMPLETE,
});

export const setVisited = (data) => ({
  type: SET_VISITED,
  data,
});
export const resetVisited = (data) => ({
  type: RESET_VISITED,
  data,
});

export const setClientRefValues = (data) => ({
  type: SET_CLIENT_REF_VALUES,
  data,
});

export const setSubmissionID = (data) => {
  return {
    type: SET_SUBMISSION_ID,
    data,
  };
};

export const setIsOCRExtracted = (data) => {
  return {
    type: SET_IS_OCR_EXTRACTED,
    data,
  };
};

export const resetCommonDSValues = () => ({
  type: RESET_COMMON_DS_VALUES,
});

export const setPOFilters = (data) => ({ type: SET_PO_FILTERS, data });
