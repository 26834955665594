import { createSelector } from "reselect";
import { initialState } from "./reducers";

const selectDsCommonState = (state) => state.get("dsCommonState", initialState);

export const selectDSFormValues = () =>
  createSelector(selectDsCommonState, (VPState) => VPState.get("formValues"));

export const selectIsPO = () =>
  createSelector(selectDsCommonState, (VPState) => VPState.get("isPO"));

export const selectStandardFieldsFecthStatus = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("fetchingStandardFields")
  );
export const selectStandardFields = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("standardFields")
  );

export const selectCountryDropdownList = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("countryDropdownList")
  );
export const selcteCountryFetchStatus = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("fetchingCountry")
  );
export const selectSupplierDropdownList = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("supplierDropdownList")
  );

export const selectSupplierFetchStatus = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("fetchingSupplier")
  );

export const selectProfileDropdownList = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("profileDropdownList")
  );
export const selectProfileFetchStatus = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("fetchingProfile")
  );

export const selectPONumberFetchStatus = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("fetchingPONumber")
  );
export const selectPONumberList = () =>
  createSelector(selectDsCommonState, (VPState) => VPState.get("poNumberList"));
export const selectAdditionalFields = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("additionalFields")
  );
export const selectAdditionalFieldsFetchStatus = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("fetchingAdditionalFields")
  );

export const selectDocumentTypesFetchStatus = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("fetchingDocumentTypes")
  );
export const selectDocumentTypeList = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("documentTypes")
  );

export const selectVisited = () =>
  createSelector(selectDsCommonState, (VPState) => VPState.get("visited"));
export const selectResetVisited = () =>
  createSelector(selectDsCommonState, (VPState) => VPState.get("resetVisited"));

export const selectClientRefValues = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("clientRefValues")
  );

export const selectPoFilters = () =>
  createSelector(selectDsCommonState, (VPState) => VPState.get("poListFilter"));

export const selectSubmissionID = () =>
  createSelector(selectDsCommonState, (VPState) => VPState.get("submissionID"));
export const selectIsOCRExtracted = () =>
  createSelector(selectDsCommonState, (VPState) =>
    VPState.get("isOCRExtracted")
  );
