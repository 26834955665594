import { Arrow } from "components/SVG/index";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import UserProfileStyled, {
  ConfirmActionButtons,
  UserName,
  UserProfileOptionStyled,
} from "./style";

import UserProfileOptions from "./UserProfileOptions/index";
import InviteOthers from "./InviteOthers/index";
import ChangeLanguage from "./ChangeLanguage/index";
import { Modal, PrimaryBtn, SecondaryBtn } from "components/index";
import { initLogout } from "container/Authentication/store/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { PublicClientApplication } from "@azure/msal-browser";
import { selectSessionDetails } from "container/Authentication/store/selectors";
import { storage } from "config/storage";
import { jsonPrs } from "helper/index";
import { initEmailInvite } from "container/ManageSupplier/store/actions";
import { emailRegex } from "config/regexConfig";
import { initUpdateUserLanguage } from "container/UserProfile/store/actions";
import { selectClientDetails } from "container/Administration/ClientManagement/store/selectors";

const UserProfile = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [activeSubmodule, setActiveSubmodule] = useState(null);
  const [confirmActionModal, setConfirmActionModal] = useState(false);

  const [emailTo, setEmailTo] = useState("");
  const [error, setError] = useState("");
  const [inProgress, setInprogress] = useState(false);
  const [success, setSuccess] = useState(false);

  const [langId, setLangId] = useState("");

  const { firstName, languageId, baseRoleId } = props.sessionDetails || {};
  const clientDetails = storage.getItem("clientDetails");
  const { name: clientName } = clientDetails ? jsonPrs(clientDetails) : {};
  const languages = storage.getItem("languages");
  const languageList = languages ? jsonPrs(languages) : {};
  const filteredLanguage = languageList.find(
    (item) => item.languageID === languageId
  );
  const { client = {} } = props.clientDetails || {};
  const { isInviteOthersActive = true } = client || {};

  const { label: languageName } = filteredLanguage || {};
  let component = null;

  useEffect(() => {
    setLangId(languageId);
  }, [languageId]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          !confirmActionModal && setShow(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const toggleSubmoduleDisplay = (module) => {
    setActiveSubmodule(module);
  };

  const handleMailtoChange = ({ target }) => {
    const { value } = target;

    const emailError =
      (value.length < 1 ? t("msp.emailRequiredError") : "") ||
      (!value.match(emailRegex) ? t("msp.incorrectValueFormat") : "");
    setEmailTo(value);
    setError(emailError);
  };

  const handleInviteSupplier = (e) => {
    e.preventDefault();
    if (error) return false;
    setInprogress(true);
    props.sendEmailInvite(emailTo, afterSubmit);
  };

  const onSelectionChange = ({ target }) => {
    const { value } = target;
    setLangId(value);
  };

  const handleLanguageChage = (e) => {
    e.preventDefault();
    setInprogress(true);
    props.saveLanguageChange({ languageId: langId }, (response) => {
      setConfirmActionModal(true);
      toggleSubmoduleDisplay("");
      afterSubmit(response);
    });
  };
  const afterSubmit = (response) => {
    setInprogress(false);
    if (response.status === 200 || response.status === 202) {
      setEmailTo("");
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
  };
  const onSSOLogout = () => {
    const isSsoLogin = JSON.parse(storage.getItem("isSsoLogin")) || null;
    if (isSsoLogin !== null) {
      const getStoredMsalConfig =
        JSON.parse(storage.getItem("configMsal")) || null;
      const configMsal = getStoredMsalConfig
        ? new PublicClientApplication(getStoredMsalConfig)
        : null;
      configMsal &&
        configMsal
          .handleRedirectPromise()
          .then(() => {
            configMsal.logoutRedirect().catch((e) => {
              console.error(e);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      storage.removeItem("configMsal");
      storage.removeItem("isSsoLogin");
    }
  };
  switch (activeSubmodule) {
    case "inviteOthers":
      component = isInviteOthersActive ? (
        <InviteOthers
          emailTo={emailTo}
          handleMailtoChange={handleMailtoChange}
          handleInviteSupplier={handleInviteSupplier}
          clientName={clientName}
          closeInvite={() => toggleSubmoduleDisplay(null)}
          sending={inProgress}
          success={success}
          error={error}
        />
      ) : null;
      break;
    case "changeLang":
      component = (
        <ChangeLanguage
          closeLang={() => toggleSubmoduleDisplay(null)}
          handleLanguageChage={handleLanguageChage}
          onSelectionChange={onSelectionChange}
          saving={inProgress}
          success={success}
          langId={langId}
          prevLangId={languageId}
        />
      );
      break;
    default:
      component = (
        <UserProfileOptions
          languageName={languageName}
          clientName={clientName}
          isInviteOthersActive={isInviteOthersActive}
          showInviteOthers={() => toggleSubmoduleDisplay("inviteOthers")}
          showLanguageOptions={() => toggleSubmoduleDisplay("changeLang")}
          show={show}
          logout={() => props.logout(onSSOLogout)}
          disableLangChange={baseRoleId === 1 || baseRoleId === 2}
        />
      );
      break;
  }
  return (
    <>
      <UserProfileStyled
        // onMouseEnter={() => setShow(true)}
        // onMouseLeave={() => setShow(false)}
        show={show}
        ref={wrapperRef}
      >
        <UserName onClick={() => setShow(!show)}>
          {firstName}
          <Arrow />
        </UserName>
        <UserProfileOptionStyled
          as={activeSubmodule === "inviteOthers" ? "div" : ""}
          show={show}
        >
          {component}
        </UserProfileOptionStyled>
      </UserProfileStyled>
      <Modal
        maxWidth="30em"
        centered
        title={t("msp.confirmAction")}
        show={confirmActionModal}
      >
        <p>{t("msp.languageChangeInfo")}</p>
        <ConfirmActionButtons>
          <PrimaryBtn sm onClick={() => props.logout(onSSOLogout)}>
            {t("msp.signOut")}
          </PrimaryBtn>
          <SecondaryBtn sm onClick={() => setConfirmActionModal(false)}>
            {t("msp.stayLoggedIn")}
          </SecondaryBtn>
        </ConfirmActionButtons>
      </Modal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  sessionDetails: selectSessionDetails(),
  clientDetails: selectClientDetails(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmailInvite: (mailTo, callback) => {
      dispatch(initEmailInvite(mailTo, callback));
    },
    saveLanguageChange: (payload, callback) => {
      dispatch(initUpdateUserLanguage(payload, callback));
    },
    logout: (callback) => {
      dispatch(initLogout(callback));
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(UserProfile);
