import styled from "styled-components";
const StyledCheckbox = styled.input.attrs(() => ({
  type: "checkbox",
}))`
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  background-color: var(--light-clr);
  border: var(--onePx) solid var(--disabled-clr-dark);
  border-radius: 0.25rem;
  vertical-align: top;
  appearance: none;

  &,
  & + label {
    cursor: pointer;
    position: relative;
  }
  & + label {
    width: fit-content;
    padding: 0;
    display: inline;
    margin-inline-start: 4px;
    color: var(--grey-clr);
    font-size: var(--fs-14);
  }
  &:disabled {
    box-shadow: none;
    background-color: var(--input-border-clr);
    & + label {
      cursor: not-allowed;
    }
  }
  &:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M3,8 l3,3 l7-7'/%3e%3c/svg%3e");
  }
  &:not(:disabled) {
    &:checked {
      background-color: var(--primary-clr);
    }
    &:focus,
    &:hover {
      border: var(--border-primary-1);
      box-shadow: rgb(102 187 255 / 50%) 0px 0px 0.8rem;
    }
  }
`;

export default StyledCheckbox;
