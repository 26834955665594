export const INIT_GET_EMAIL_TEMPLATES_LIST = "INIT_GET_EMAIL_TEMPLATES_LIST";
export const FETCHING_GET_EMAIL_TEMPLATES_LIST =
  "FETCHING_GET_EMAIL_TEMPLATES_LIST";
export const FETCH_GET_EMAIL_TEMPLATES_LIST = "FETCH_GET_EMAIL_TEMPLATES_LIST";
export const GET_EMAIL_TEMPLATES_LIST_FETCH_COMPLETE =
  "GET_EMAIL_TEMPLATES_LIST_FETCH_COMPLETE";

  export const INIT_GET_HELPDESK_QUERY_LIST = "INIT_GET_HELPDESK_QUERY_LIST";
export const FETCHING_HELPDESK_QUERY_LIST = "FETCHING_HELPDESK_QUERY_LIST";
export const FETCH_HELPDESK_QUERY_LIST = "FETCH_HELPDESK_QUERY_LIST";
export const HELPDESK_QUERY_LIST_FETCH_COMPLETE =
  "HELPDESK_QUERY_LIST_FETCH_COMPLETE";

  export const INIT_GET_HELPDESK_QUERY_ATTRIBUTE_LIST =
  "INIT_GET_HELPDESK_QUERY_ATTRIBUTE_LIST";
export const FETCHING_HELPDESK_QUERY_ATTRIBUTE_LIST =
  "FETCHING_HELPDESK_QUERY_ATTRIBUTE_LIST";
export const FETCH_HELPDESK_QUERY_ATTRIBUTE_LIST =
  "FETCH_HELPDESK_QUERY_ATTRIBUTE_LIST";
export const HELPDESK_QUERY_ATTRIBUTE_LIST_FETCH_COMPLETE =
  "HELPDESK_QUERY_ATTRIBUTE_LIST_FETCH_COMPLETE";

  export const INIT_GET_HELPDESK_QUERY_BY_ID = "INIT_GET_HELPDESK_QUERY_BY_ID";
export const FETCHING_HELPDESK_QUERY_BY_ID = "FETCHING_HELPDESK_QUERY_BY_ID";
export const FETCH_HELPDESK_QUERY_BY_ID = "FETCH_HELPDESK_QUERY_BY_ID";
export const HELPDESK_QUERY_BY_ID_FETCH_COMPLETE =
  "HELPDESK_QUERY_BY_ID_FETCH_COMPLETE";

  export const INIT_HELPDESK_CONFIG_SAVE = "INIT_HELPDESK_CONFIG_SAVE";
  export const HELPDESK_CONFIG_SAVING = "HELPDESK_CONFIG_SAVING";
  export const HELPDESK_CONFIG_SAVED = "HELPDESK_CONFIG_SAVED";
  export const HELPDESK_CONFIG_SAVING_FAILED = "HELPDESK_CONFIG_SAVING_FAILED";


