import { INIT_GET_SEARCH_FIELDS_LIST,FETCHING_SEARCH_FIELDS_LIST,FETCH_GET_SEARCH_FIELDS_LIST,GET_SEARCH_FIELDS_LIST_FETCH_COMPLETE, INIT_SEARCH_CONFIG_SAVE, SEARCH_CONFIG_SAVING_FAILED, SEARCH_CONFIG_SAVED, SEARCH_CONFIG_SAVING } from "./actionTypes";


export const initGetSearchFieldsList = () => {
    return {
      type: INIT_GET_SEARCH_FIELDS_LIST,
    };
  };
  
  export const fetchingGetSearchFieldsList = () => {
    return {
      type: FETCHING_SEARCH_FIELDS_LIST,
    };
  };
  
  export const fetchGetSearchFieldsList = (data) => {
    return {
      type: FETCH_GET_SEARCH_FIELDS_LIST,
      data: data,
    };
  };
  
  
  export const getSearchFieldsListFetchingComplete = () => {
    return {
      type: GET_SEARCH_FIELDS_LIST_FETCH_COMPLETE,
    };
  };

  export const initSearchConfigarationSave = (
    formdata,
    searchConfigSaveSuccess
  ) => {
    return {
      type: INIT_SEARCH_CONFIG_SAVE,
      formdata,
      searchConfigSaveSuccess,
    };
  };
  
  export const searchConfigSaving = () => {
    return {
      type: SEARCH_CONFIG_SAVING,
    };
  };
  
  export const searchConfigSaved = () => {
    return {
      type: SEARCH_CONFIG_SAVED,
    };
  };
  
  export const searchConfigSavedFailed = () => {
    return {
      type: SEARCH_CONFIG_SAVING_FAILED,
    };
  };