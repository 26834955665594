import {
  USER_CREATION_REQUEST_FETCH,
  USER_CREATION_LIST,
  APPROVE_USER_REQUEST,
  REJECT_USER_REQUEST,
  UPDATE_SUBMIT_STATUS,
  FETCH_GET_USER_BY_ID_STATUS,
  UPDATE_CONFIRM_STATUS,
  UPDATE_REQUEST_STATUS,
  GET_USER_BY_ID,
  FETCH_GET_USER_BY_ID,
  SAVE_USER_CREATION,
  INIT_DOWNLOAD_REQ_ATTACHMENT,
  INIT_FETCH_SIGNUP_FIELDS,
  FETCHING_SIGNUP_FIELDS,
  FETCH_SIGNUP_FIELDS,
  APPROVE_USER_REQUEST_SUCCESS,
  REJECT_USER_REQUEST_SUCCESS,
  INIT_RESEND_APPROVED_EMAIL,
  RESEND_APPROVED_EMAIL_SUCCESS,
  USER_CREATION_REQUEST_FETCH_STATUS,
} from "./actionTypes";

export function getUserCreationRequestList(data) {
  return {
    type: USER_CREATION_REQUEST_FETCH,
    data,
  };
}

export function updateUserCreationRequest(
  requestList,
  statusCounts,
  totalCount
) {
  return {
    type: USER_CREATION_LIST,
    requestList,
    statusCounts,
    totalCount,
  };
}

export const fetchUserCreationListStatus = (boolean) => {
  return {
    type: USER_CREATION_REQUEST_FETCH_STATUS,
    boolean,
  };
};

export function approveUserRequest(payload, callback) {
  return {
    type: APPROVE_USER_REQUEST,
    payload,
    callback,
  };
}

export const approveUserSuccess = (boolean) => {
  return {
    type: APPROVE_USER_REQUEST_SUCCESS,
    boolean,
  };
};

export function rejectUserRequest(payload, callback) {
  return {
    type: REJECT_USER_REQUEST,
    payload,
    callback,
  };
}

export const rejectUserSuccess = (boolean) => {
  return {
    type: REJECT_USER_REQUEST_SUCCESS,
    boolean,
  };
};

export function updateSubmitStatus(status) {
  return {
    type: UPDATE_SUBMIT_STATUS,
    status,
  };
}

export function updateConfirmStatus(status) {
  return {
    type: UPDATE_CONFIRM_STATUS,
    status,
  };
}

export function updateRequestStatus(requestStatus) {
  return {
    type: UPDATE_REQUEST_STATUS,
    requestStatus,
  };
}

export function getUserById(companyDetailID, callBackFunction) {
  return {
    type: GET_USER_BY_ID,
    companyDetailID,
    callBackFunction,
  };
}

export const fetchUserByIDData = (data) => {
  return {
    type: FETCH_GET_USER_BY_ID,
    data,
  };
};

export const fetchUserByIDDataStatus = (boolean) => {
  return {
    type: FETCH_GET_USER_BY_ID_STATUS,
    boolean,
  };
};

export const saveUserCreation = (data, callback) => {
  return {
    type: SAVE_USER_CREATION,
    data,
    callback,
  };
};

export const initDownloadReqAttachment = (id, fileName) => {
  return {
    type: INIT_DOWNLOAD_REQ_ATTACHMENT,
    id,
    fileName,
  };
};

export const initFetchSignUpFields = (langId) => {
  return {
    type: INIT_FETCH_SIGNUP_FIELDS,
    langId,
  };
};

export const fetchingSignUpFieldsStatus = (boolean) => {
  return {
    type: FETCHING_SIGNUP_FIELDS,
    boolean,
  };
};

export const fetchSignUpFields = (data) => {
  return {
    type: FETCH_SIGNUP_FIELDS,
    data: data,
  };
};

export const initResendApprovedEmail = (data) => {
  return {
    type: INIT_RESEND_APPROVED_EMAIL,
    data,
  };
};

export const resendApprovedEmailSuccess = (boolean) => {
  return {
    type: RESEND_APPROVED_EMAIL_SUCCESS,
    boolean,
  };
};
