import {
  INIT_BULK_UPLOAD_TYPES_FETCH,
  FETCHING_BULK_UPLOAD_TYPES,
  FETCH_BULK_UPLOAD_TYPES,
  BULK_UPLOAD_TYPES_FETCH_COMPLETE,
  INIT_RECENT_UPLOADED_FILES_FETCH,
  FETCHING_RECENT_UPLOADED_FILES,
  FETCH_RECENT_UPLOADED_FILES,
  RECENT_UPLOADED_FILES_FETCH_COMPLETE,
  INIT_UPLOADED_FILES_LIST_FETCH,
  FETCHING_UPLOADED_FILES_LIST,
  FETCH_UPLOADED_FILES_LIST,
  UPLOADED_FILES_LIST_FETCH_COMPLETE,
  INIT_TEMPLATE_FETCH,
  FETCHING_TEMPLATE,
  TEMPLATE_FETCH_COMPLETE,
  INIT_BULK_UPLOAD_FILE_FETCH,
  FETCHING_BULK_UPLOAD_FILE,
  BULK_UPLOAD_FILE_FETCH_COMPLETE,
  INIT_SEND_EMAIL,
  SENDING_EMAIL,
  EMAIL_SENT,
  INIT_BULK_UPLOAD_FILE_STATUS_LIST_FETCH,
  FETCHING_BULK_UPLOAD_FILE_STATUS_LIST,
  FETCH_BULK_UPLOAD_FILE_STATUS_LIST,
  BULK_UPLOAD_FILE_STATUS_LIST_FETCH_COMPLETE,
  INIT_BULK_USER_UPLOAD,
  BULK_USER_UPLOADING,
  SET_BULK_USER_UPLOAD_PROGRESSION,
  BULK_USER_UPLOAD_COMPLETE,
  BULK_USER_UPLOAD_RESET,
  EMAIL_SENT_SUCCESS,
  EMAIL_SENT_FAILED,
} from "./actionTypes";

export const initBulkUploadTypes = () => {
  return {
    type: INIT_BULK_UPLOAD_TYPES_FETCH,
  };
};

export const fetchingBulkUploadTypes = () => {
  return {
    type: FETCHING_BULK_UPLOAD_TYPES,
  };
};

export const fetchBulkUploadTypes = (data) => {
  return {
    type: FETCH_BULK_UPLOAD_TYPES,
    data,
  };
};

export const bulkUploadTypesFetchingComplete = () => {
  return {
    type: BULK_UPLOAD_TYPES_FETCH_COMPLETE,
  };
};

export const initRecentUploadedFiles = (payload) => {
  return {
    type: INIT_RECENT_UPLOADED_FILES_FETCH,
    payload,
  };
};

export const fetchingRecentUploadedFiles = () => {
  return {
    type: FETCHING_RECENT_UPLOADED_FILES,
  };
};

export const fetchRecentUploadedFiles = (data) => {
  return {
    type: FETCH_RECENT_UPLOADED_FILES,
    data,
  };
};

export const recentUploadedFilesFetchingComplete = () => {
  return {
    type: RECENT_UPLOADED_FILES_FETCH_COMPLETE,
  };
};

export const initUploadedFilesList = (payload) => {
  return {
    type: INIT_UPLOADED_FILES_LIST_FETCH,
    payload,
  };
};

export const fetchingUploadedFilesList = () => {
  return {
    type: FETCHING_UPLOADED_FILES_LIST,
  };
};

export const fetchUploadedFilesList = (data) => {
  return {
    type: FETCH_UPLOADED_FILES_LIST,
    data,
  };
};

export const uploadedFilesListFetchingComplete = () => {
  return {
    type: UPLOADED_FILES_LIST_FETCH_COMPLETE,
  };
};

export const initDownloadTemplate = (payload) => {
  return {
    type: INIT_TEMPLATE_FETCH,
    payload,
  };
};

export const fetchingTemplate = () => {
  return {
    type: FETCHING_TEMPLATE,
  };
};

export const templateFetchingComplete = () => {
  return {
    type: TEMPLATE_FETCH_COMPLETE,
  };
};

export const initDownloadBulkUploadFile = (payload, callback) => {
  return {
    type: INIT_BULK_UPLOAD_FILE_FETCH,
    payload,
    callback,
  };
};

export const fetchingBulkUploadFile = () => {
  return {
    type: FETCHING_BULK_UPLOAD_FILE,
  };
};

export const bulkUploadFileFetchingComplete = () => {
  return {
    type: BULK_UPLOAD_FILE_FETCH_COMPLETE,
  };
};

export const initSendEmail = (payload, callback) => {
  return {
    type: INIT_SEND_EMAIL,
    payload,
    callback,
  };
};

export const sendingEmail = () => {
  return {
    type: SENDING_EMAIL,
  };
};

export const emailSent = (fileDataID) => {
  return {
    type: EMAIL_SENT,
    fileDataID,
  };
};
export const emailSentSuccess = () => {
  return {
    type: EMAIL_SENT_SUCCESS,
  };
};
export const emailSentFailed = () => {
  return {
    type: EMAIL_SENT_FAILED,
  };
};

export const initBulkUploadFileStatusList = () => {
  return {
    type: INIT_BULK_UPLOAD_FILE_STATUS_LIST_FETCH,
  };
};

export const fetchingBulkUploadFileStatusList = () => {
  return {
    type: FETCHING_BULK_UPLOAD_FILE_STATUS_LIST,
  };
};

export const fetchBulkUploadFileStatusList = (data) => {
  return {
    type: FETCH_BULK_UPLOAD_FILE_STATUS_LIST,
    data,
  };
};

export const bulkUploadFileStatusListFetchingComplete = () => {
  return {
    type: BULK_UPLOAD_FILE_STATUS_LIST_FETCH_COMPLETE,
  };
};

export const initBulkUserSave = (payload, callback) => {
  return {
    type: INIT_BULK_USER_UPLOAD,
    payload,
    callback,
  };
};

export const bulkUserUploading = (progression) => {
  return {
    type: BULK_USER_UPLOADING,
    data: progression,
  };
};

export const setBulkUserUploadProgress = (progression) => {
  return {
    type: SET_BULK_USER_UPLOAD_PROGRESSION,
    data: progression,
  };
};

export const bulkUserUploaded = (progression) => {
  return {
    type: BULK_USER_UPLOAD_COMPLETE,
    data: progression,
  };
};

export const bulkUserUploadReset = () => {
  return {
    type: BULK_USER_UPLOAD_RESET,
  };
};
