import React from "react";

import { Progress, Progressbar } from "./style";

const ProgressBar = (props) => {
  const progress = props.progress || 0;
  return (
    <Progressbar>
      <Progress
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: `${progress}%` }}
      />
    </Progressbar>
  );
};

export default ProgressBar;
