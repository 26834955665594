export const termsOfUseContent = [
  {
    header: "msp.acceptanceOfTermsTitle",
    content: {
      body: "msp.acceptanceOfTermsMessage",
    },
  },
  {
    header: "msp.serviceProvidedTitle",
    content: {
      body: "msp.serviceProvidedMessage",
    },
  },
  {
    header: "msp.userCodesTitle",
    content: {
      body: "msp.userCodesMessage",
    },
  },
  {
    header: "msp.privacyTitle",
    content: {
      body: "msp.privacyMessage",
    },
  },
  {
    header: "msp.reservationRightTitle",
    content: {
      body: "msp.reservationRightMessage",
    },
  },
  {
    header: "msp.prohibitedCommunicationTitle",
    content: {
      body: "msp.prohibitedCommunicationMessageOne",
      childBody: [
        {
          body: "msp.prohibitedCommunicationMessageTwo",
        },
        {
          body: "msp.prohibitedCommunicationMessageThree",
        },
      ],
    },
  },
  {
    header: "msp.warrantiesLimitationTitle",
    content: {
      body: "msp.warrantiesLimitationMessage",
    },
  },
  {
    header: "msp.representationWarrantiesTitle",
    content: {
      body: "msp.representationWarrantiesMessage",
    },
  },
  {
    header: "msp.confidentialityTitle",
    content: {
      body: "msp.confidentialityMessage",
    },
  },
  {
    header: "msp.terminationTitle",
    content: {
      body: "msp.terminationMessage",
    },
  },
  {
    header: "msp.assignmentTitle",
    content: {
      body: "msp.assignmentMessage",
    },
  },
  {
    header: "msp.agreementSeverabilityTitle",
    content: {
      body: "msp.agreementSeverabilityMessage",
    },
  },
  {
    header: "msp.waiverTitle",
    content: {
      body: "msp.waiverMessageOne",
    },
  },
  {
    header: "",
    content: {
      body: "msp.waiverMessageTwo",
      childBody: [
        {
          body: "msp.waiverMessageThree",
        },
        {
          body: "msp.waiverMessageFour",
        },
      ],
    },
  },
];

export const policy = [
  {
    header: "msp.waiverMessageFive",
    body: "msp.waiverMessageSix",
  },
  {
    header: "msp.policyEnforcementTitle",
    body: "msp.policyEnforcementMessage",
  },
  {
    header: "msp.changePolicyTitle",
    body: "msp.changePolicyMessage",
  },
];

export const additionalInformation = [
  {
    body: "msp.privacyMessageOne",
  },
  {
    body: "msp.privacyMessageTwo",
  },
  {
    body: "msp.privacyMessageThree",
  },
];
