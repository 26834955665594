export const UPDATE_SEARCHED_LOG_LIST = "UPDATE_SEARCHED_LOG_LIST";
export const UPDATE_DATA_TO_DISPLAY = "UPDATE_DATA_TO_DISPLAY";
export const FETCHING_LOG_STATUS = "FETCHING_LOG_STATUS";
export const FETCHING_LOG_COMPLETE = "FETCHING_LOG_COMPLETE";
export const REPROCESSED_SAVED_OR_FAILED = "REPROCESSED_SAVED_OR_FAILED";
export const INIT_EMAIL_LOG = "INIT_EMAIL_LOG";
export const FETCHING_EMAIL_LIST = "FETCHING_EMAIL_LIST";
export const FETCH_EMAIL_LOG_LIST = "FETCH_EMAIL_LOG_LIST";
export const EMAIL_LOG_FETCH_COMPLETE = "EMAIL_LOG_FETCH_COMPLETE";
export const INIT_FETCH_EMAIL_LOG_DESCRIPTION_BY_ID =  "INIT_FETCH_EMAIL_LOG_DESCRIPTION_BY_ID";
export const FETCHING_EMAIL_LOG_DESCRIPTION = "FETCHING_EMAIL_LOG_DESCRIPTION";
export const FETCH_EMAIL_LOG_DESCRIPTION = "FETCH_EMAIL_LOG_DESCRIPTION";
export const EMAIL_LOG_DESCRIPTION_FETCH_COMPLETE = "EMAIL_LOG_DESCRIPTION_FETCH_COMPLETE";
export const INIT_CLEAR_EMAIL_LOG = "INIT_CLEAR_EMAIL_LOG";
export const CLEARING_EMAIL_LOG = "CLEARING_EMAIL_LOG";
export const CLEARED_EMAIL_LOG = "CLEARED_EMAIL_LOG";
export const CLEAR_EMAIL_LOG_FAILED = "CLEAR_EMAIL_LOG_FAILED";
export const INIT_LOGS_LIST = "INIT_LOGS_LIST";
export const FETCH_LOGS_LIST = "FETCH_LOGS_LIST";
export const INIT_REPROCESS_SUBMISSION = "INIT_REPROCESS_SUBMISSION";
export const RESET_LOG_LIST = "RESET_LOG_LIST";