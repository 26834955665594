import React from "react";
import { SvgLargeIcon } from "./style";

const RightArrowCircle = () => {
  return (
    <SvgLargeIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path d="M32,0L32,0c17.7,0,32,14.3,32,32l0,0c0,17.7-14.3,32-32,32l0,0C14.3,64,0,49.7,0,32l0,0C0,14.3,14.3,0,32,0z" />
        </g>

        <g transform="translate(12.000000, 12.000000)">
          <g
            transform="translate(20.000000, 20.000000) rotate(-360.000000) translate(-20.000000, -20.000000) "
            fill="var(--light-clr)"
          >
            <path d="M14.5,19.3l9.2-9.1c0.4-0.4,1-0.4,1.3,0l0.6,0.6c0.4,0.4,0.4,1,0,1.3l-8,7.8l8,7.8c0.4,0.4,0.4,1,0,1.3L25,29.7c-0.4,0.4-1,0.4-1.3,0l-9.2-9.1C14.1,20.3,14.1,19.7,14.5,19.3z" />
          </g>
        </g>
      </g>
    </SvgLargeIcon>
  );
};

export default RightArrowCircle;
