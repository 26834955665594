import React from "react";
import SvgIcon from "./style";
const UserCircle = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-1128.000000, -77.000000)">
          <g transform="translate(1112.000000, 54.000000)">
            <g transform="translate(8.000000, 15.000000)">
              <g transform="translate(8.000000, 8.000000)">
                <path d="M8,0.249999981 C3.71874999,0.249999981 0.249999981,3.71874999 0.249999981,8 C0.249999981,12.28125 3.71874999,15.75 8,15.75 C12.28125,15.75 15.75,12.28125 15.75,8 C15.75,3.71874999 12.28125,0.249999981 8,0.249999981 Z M12,13.425 C10.878125,14.253125 9.496875,14.75 7.99999999,14.75 C6.503125,14.75 5.12187499,14.253125 3.99999999,13.425 L3.99999999,13 C3.99999999,11.896875 4.89687499,11 5.99999999,11 C6.346875,11 6.859375,11.35625 7.99999999,11.35625 C9.14375,11.35625 9.65,11 9.99999999,11 C11.103125,11 12,11.896875 12,13 L12,13.425 Z M12.95625,12.565625 C12.74375,11.115625 11.509375,10 10,10 C9.359375,10 9.05,10.35625 8,10.35625 C6.95,10.35625 6.64375,10 6,10 C4.49062499,10 3.25624999,11.115625 3.04374999,12.565625 C1.93437498,11.3625 1.24999998,9.7625 1.24999998,8 C1.24999998,4.27812499 4.27812499,1.24999998 8,1.24999998 C11.721875,1.24999998 14.75,4.27812499 14.75,8 C14.75,9.7625 14.065625,11.3625 12.95625,12.565625 Z M8,3.74999999 C6.48125,3.74999999 5.24999999,4.98124999 5.24999999,6.5 C5.24999999,8.01875 6.48125,9.24999999 8,9.24999999 C9.51875,9.24999999 10.75,8.01875 10.75,6.5 C10.75,4.98124999 9.51875,3.74999999 8,3.74999999 Z M8,8.24999999 C7.034375,8.24999999 6.25,7.465625 6.25,6.5 C6.25,5.53437499 7.034375,4.74999999 8,4.74999999 C8.965625,4.74999999 9.75,5.53437499 9.75,6.5 C9.75,7.465625 8.965625,8.24999999 8,8.24999999 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default UserCircle;
