export const UPLOAD_TEMPLATE = "UPLOAD_TEMPLATE";

export const INIT_SAMPLE_TEMPLATE_DOWNLOAD = "INIT_SAMPLE_TEMPLATE_DOWNLOAD";
export const DOWNLOADING_SAMPLE_TEMPLATE = "DOWNLOADING_SAMPLE_TEMPLATE";
export const DOWNLOAD_SAMPLE_TEMPLATE_SUCCESS =
  "DOWNLOAD_SAMPLE_TEMPLATE_SUCCESS";
export const DOWNLOAD_SAMPLE_TEMPLATE_FAILED =
  "DOWNLOAD_SAMPLE_TEMPLATE_FAILED";

export const CLEANUP_EINVOICE = "CLEANUP_EINVOICE";
