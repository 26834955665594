import Overlay from "components/Overlay/index";
import styled from "styled-components";

export const BusyOverlayStyled = styled(Overlay)`
  backdrop-filter: blur(0.1em);
`;
const BusyLoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    font-size: var(--fs-20);
    font-family: var(--ff-light);
    color: var(--grey-clr);
    font-weight: var(--fw-normal);
    margin-bottom: 1em;
  }
  @media (min-width: 36em) {
    h1 {
      font-size: clamp(var(--fs-20), 3vw, var(--fs-32));
    }
  }
`;
export default BusyLoaderWrapper;
