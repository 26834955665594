import React from "react";
import SvgIcon from "./style";

const Attachment = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-96.000000, -436.000000)">
          <g transform="translate(32.000000, 373.000000)">
            <g transform="translate(64.000000, 63.000000)">
              <path d="M4.65956249,15.9999922 C3.62593749,15.9999922 2.59228124,15.6065625 1.80540623,14.8196563 C0.231531231,13.245875 0.231531231,10.685125 1.80534373,9.111375 L9.9950625,0.921812482 C11.2241563,-0.307281271 13.2240625,-0.307281271 14.4531875,0.921812482 C15.6822813,2.15093749 15.6822813,4.15078124 14.4531875,5.37984374 L7.496,12.3369063 C6.61171875,13.2213125 5.17265624,13.221375 4.28818749,12.3369063 C3.40381249,11.4525 3.40381249,10.0135 4.28818749,9.129125 L9.61009375,3.80731249 C9.75653125,3.66087499 9.99396875,3.66087499 10.1404063,3.80731249 L10.3173125,3.98421874 C10.46375,4.13065624 10.46375,4.36809374 10.3173125,4.51456249 L4.99543749,9.83634375 C4.50103124,10.33075 4.50103124,11.1351875 4.99546874,11.629625 C5.48987499,12.124 6.29434375,12.124 6.78875,11.629625 L13.7459375,4.67256249 C14.5850313,3.83346874 14.5850313,2.46815624 13.7459375,1.62899998 C12.9067188,0.789906232 11.5414375,0.789968732 10.7023438,1.62899998 L2.51262499,9.818625 C1.32881248,11.0024375 1.32881248,12.9285938 2.51262499,14.1124063 C3.69649999,15.2961875 5.62271874,15.2962188 6.8065,14.1124375 L13.6109688,7.30809375 C13.7574063,7.16165625 13.9948438,7.16165625 14.1412813,7.30809375 L14.3181875,7.485 C14.464625,7.6314375 14.464625,7.868875 14.3181875,8.01534375 L7.51375,14.8196563 C6.726875,15.6065625 5.69321874,15.9999922 4.65956249,15.9999922 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Attachment;
