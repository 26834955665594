import PropTypes from "prop-types";
import React from "react";

import LookupList from "./LookupList";
import { SupplierLookupContainer } from "./style";

const SupplierLookup = (props) => {
  const lookupProps = {
    data: props.data,
    loading: props.loading,
    filterable: props.filterable,
    total: props.total,
    ssr: props.ssr,
    onPageChange: props.onPageChange,
    columns: props.columns,
    headerSelectionValue: props.headerSelectionValue,
    skip: props.skip,
    selectableRows: props.selectableRows,
  };
  return (
    <SupplierLookupContainer>
      <LookupList
        onClose={props.closeLookup}
        show={props.showLookup}
        lookupProps={lookupProps}
        handleSaveSupplier={props.handleSaveSupplier}
        onSelectionChange={props.onSelectionChange}
        onHeaderSelectionChange={props.onHeaderSelectionChange}
        handleLookupSearch={props.handleLookupSearch}
        searchValue={props.searchValue}
        handleResetLookupSearch={props.handleResetLookupSearch}
        searchHint={props.searchHint}
        hideSaveBtn={props.hideSaveBtn}
      />
    </SupplierLookupContainer>
  );
};

SupplierLookup.propTypes = {
  closeLookup: PropTypes.any,
  columns: PropTypes.any,
  data: PropTypes.any,
  filterable: PropTypes.any,
  handleLookupSearch: PropTypes.any,
  handleResetLookupSearch: PropTypes.any,
  handleSaveSupplier: PropTypes.any,
  headerSelectionValue: PropTypes.any,
  loading: PropTypes.any,
  onHeaderSelectionChange: PropTypes.any,
  onPageChange: PropTypes.any,
  onSelectionChange: PropTypes.any,
  skip: PropTypes.any,
  searchHint: PropTypes.any,
  searchValue: PropTypes.any,
  showLookup: PropTypes.any,
  ssr: PropTypes.any,
  total: PropTypes.any,
};

export default SupplierLookup;
