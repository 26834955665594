import { fromJS } from "immutable";
import {
  INIT_AUTHENTICATE,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILED,
  LOGOUT,
  CONFIRMING_GDPR_MESSAGE,
  GDPR_MESSAGE_CONFIRMED,
  GDPR_MESSAGE_CONFIRM_FAILED,
  ABOUT_TO_LOGOUT,
  TIMER_RESET_SUCCESS,
  FETCHING_REFRESH_TOKEN,
  FETCH_REFRESH_TOKEN,
  REFRESH_TOKEN_FETCH_FAILED,
  NOT_YET_CONFIGURED,
  SSO_LOGIN_FAILED,
  INIT_MFA_OTP,
  MFA_OTP_SENT,
  MFA_OTP_FAILED,
} from "./actionTypes";

export const initialState = fromJS({
  authenticating: false,
  numberOfWrongAttempts: 0,
  isAuthenticated: false,
  notYetConfigured: false,
  errorMessage: "",
  sessionDetails: {},
  loggingOut: false,
  confirmingGDPRMsg: false,
  gdprConfirmed: false,
  aboutToLogout: false,
  fetchRefreshToken: false,
  fetchRefreshTokenFailed: false,
  isSsoLoginFailed: false,
  MFAOTPSent: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_MFA_OTP:
      return state.set("authenticating", true);
    case MFA_OTP_SENT:
      return state.set("authenticating", false).set("MFAOTPSent", true);
    case MFA_OTP_FAILED:
      return state.set("authenticating", false);
    case INIT_AUTHENTICATE: {
      return state
        .set("authenticating", true)
        .set("numberOfWrongAttempts", state.numberOfWrongAttempts + 1);
    }
    case AUTHENTICATION_SUCCESS: {
      return state
        .set("authenticating", false)
        .set("numberOfWrongAttempts", 0)
        .set("isAuthenticated", true)
        .set("sessionDetails", action.data)
        .set("errorMessage", "")
        .set("loggingOut", false)
        .set("MFAOTPSent", false);
    }
    case AUTHENTICATION_FAILED: {
      return state
        .set("authenticating", false)
        .set("isAuthenticated", false)
        .set("errorMessage", action.message);
    }
    case NOT_YET_CONFIGURED: {
      return state.set("notYetConfigured", true);
    }
    case LOGOUT: {
      return state
        .set("sessionDetails", {})
        .set("isAuthenticated", false)
        .set("aboutToLogout", false);
    }
    case CONFIRMING_GDPR_MESSAGE:
      return state.set("confirmingGDPRMsg", true);
    case GDPR_MESSAGE_CONFIRMED:
      return state.set("confirmingGDPRMsg", false).set("gdprConfirmed", true);
    case GDPR_MESSAGE_CONFIRM_FAILED:
      return state
        .set("confirmingGDPRMsg", false)
        .set("gdprConfirmed", false)
        .set("errorMessage", action.message);

    case ABOUT_TO_LOGOUT:
      return state.set("aboutToLogout", true);
    case TIMER_RESET_SUCCESS:
      return state.set("aboutToLogout", false);

    case FETCHING_REFRESH_TOKEN:
      return state
        .set("fetchRefreshToken", true)
        .set("fetchRefreshTokenFailed", false);
    case FETCH_REFRESH_TOKEN:
      return state
        .set("sessionDetails", action.data)
        .set("fetchRefreshToken", false)
        .set("fetchRefreshTokenFailed", false);
    case REFRESH_TOKEN_FETCH_FAILED:
      return state
        .set("fetchRefreshToken", false)
        .set("fetchRefreshTokenFailed", true);

    case SSO_LOGIN_FAILED:
      return state.set("isSsoLoginFailed", action.data);

    default:
      return state;
  }
};

export default reducer;
