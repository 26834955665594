export const INIT_SET_COMPANY_DETAILS_DATA = "INIT_SET_COMPANY_DETAILS_DATA";
export const INIT_UPDATE_PASSWORD = "INIT_UPDATE_PASSWORD";
export const UPDATING_PASSWORD = "UPDATING_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "IUPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED";
export const INIT_UPDATE_SECURITY_QUESTION = "INIT_UPDATE_SECURITY_QUESTION";
export const UPDATING_SECURITY_QUESTION = "UPDATING_SECURITY_QUESTION";
export const UPDATE_SECURITY_QUESTION_SUCCESS =
  "IUPDATE_SECURITY_QUESTION_SUCCESS";
export const UPDATE_SECURITY_QUESTION_FAILED =
  "UPDATE_SECURITY_QUESTION_FAILED";
export const INIT_GET_USER_ADDRESS_LIST = "INIT_GET_USER_ADDRESS_LIST";
export const FETCH_USER_ADDRESS_LIST = "FETCH_USER_ADDRESS_LIST";
export const FETCH_USER_ADDRESS_LIST_END = "FETCH_USER_ADDRESS_LIST_END";
export const INIT_GET_USER_ADDRESS_BY_ADDRESS_ID =
  "INIT_GET_USER_ADDRESS_BY_ADDRESS_ID";
export const FETCH_USER_ADDRESS_BY_ID_DATA = "FETCH_USER_ADDRESS_BY_ID_DATA";
export const FETCH_USER_ADDRESS_BY_ID_END = "FETCH_USER_ADDRESS_BY_ID_END";
export const INIT_DELETE_USER_ADDRESS = "INIT_DELETE_USER_ADDRESS";
export const DELETE_USER_ADDRESS_END = "DELETE_USER_ADDRESS_END";
export const INIT_SAVE_USER_ADDRESS = "INIT_SAVE_USER_ADDRESS";
export const SAVE_USER_ADDRESS_END = "SAVE_USER_ADDRESS_END";
export const SAVE_USER_COMPANY_DETAIL_DATA = "SAVE_USER_COMPANY_DETAIL_DATA";
export const SAVED_USER_COMPANY_DETAIL_DATA = "SAVED_USER_COMPANY_DETAIL_DATA";
export const INIT_UPDATE_USER_LANGUAGE = "INIT_UPDATE_USER_LANGUAGE";
export const UPDATING_USER_LANGUAGE = "UPDATING_USER_LANGUAGE";
export const UPDATED_USER_LANGUAGE = "UPDATED_USER_LANGUAGE";
export const INIT_CURRENCY_MAPPING_FETCH = "INIT_CURRENCY_MAPPING_FETCH";
export const CURRENCY_MAPPING_FETCHING = "CURRENCY_MAPPING_FETCHING";
export const CURRENCY_MAPPING_FETCH = "CURRENCY_MAPPING_FETCH";
export const CURRENCY_MAPPING_FETCH_COMPLETE =
  "CURRENCY_MAPPING_FETCH_COMPLETE";

export const INIT_PROFILE_MFA_OTP = "INIT_PROFILE_MFA_OTP";
export const PROFILE_MFA_OTP_SENT = "PROFILE_MFA_OTP_SENT";
export const PROFILE_MFA_OTP_FAILED = "PROFILE_MFA_OTP_FAILED";
