import styled from "styled-components";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--light-clr);
  box-shadow: 0 0.125rem 2rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.5em;
  padding: 1em;
  overflow-y: auto;
`;

export default Page;
