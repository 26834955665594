import { useTranslation } from "react-i18next";
import {
  GlobeIcon,
  LinkIcon,
  QuestionCircle,
  SignOutIcon,
  Star,
  UserCircle,
} from "components/SVG/index";
import { getRoutePathByKey } from "helper/index";
import React from "react";
import { ProfileAction, ProfileOption, ProfileOptionItem } from "./style";

const UserProfileOptions = (props) => {
  const { t } = useTranslation();
  const viewHelpPath = getRoutePathByKey("viewHelp");
  return (
    <>
      <ProfileOption>
        <ProfileOptionItem
          to={{
            pathname: "/my-profile",
            state: {
              fromMyAccnt: true,
            },
          }}
        >
          <span>{t("msp.userProfile")}</span>
          <UserCircle />
        </ProfileOptionItem>
      </ProfileOption>
      {props.isInviteOthersActive ? (
        <ProfileOption onClick={props.showInviteOthers}>
          <ProfileOptionItem as="span">
            <span>{t("msp.inviteOthers")}</span>
            <LinkIcon />
          </ProfileOptionItem>
        </ProfileOption>
      ) : null}

      <ProfileOption>
        <ProfileOptionItem as="span">
          <span>{props.languageName}</span>
          <GlobeIcon />
          <ProfileAction
            onClick={props.showLanguageOptions}
            disabled={props.disableLangChange}
          >
            {t("msp.change")}
          </ProfileAction>
        </ProfileOptionItem>
      </ProfileOption>
      <ProfileOption>
        <ProfileOptionItem as="span">
          <span>{props.clientName}</span>
          <Star />
          {/* <ProfileAction>Change</ProfileAction> */}
        </ProfileOptionItem>
      </ProfileOption>
      <ProfileOption>
        <ProfileOptionItem to={viewHelpPath}>
          <span>{t("msp.help")}</span>
          <QuestionCircle />
        </ProfileOptionItem>
      </ProfileOption>
      <ProfileOption onClick={props.logout}>
        <ProfileOptionItem to={() => false}>
          <span>{t("msp.signOut")}</span>
          <SignOutIcon />
        </ProfileOptionItem>
      </ProfileOption>
    </>
  );
};

export default UserProfileOptions;
