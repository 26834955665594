import PropTypes from "prop-types";
import React, { useEffect } from "react";
import NavStyled, { NavItems } from "./style";
import { NavToggle, NavToggler } from "./mobile-nav";
import { generateFilteredRoutes } from "routes/generateFilteredRoutes";

import { createStructuredSelector } from "reselect";
import { selectSessionDetails } from "container/Authentication/store/selectors";

import { connect } from "react-redux";
import { compose } from "redux";

import { useTranslation } from "react-i18next";

import { Hamburger } from "components/SVG";
import Submenu from "./Submenu";
import MenuItem from "./MenuItem";

const Nav = (props) => {
  const { t } = useTranslation();
  const { menus } = props.sessionDetails || {};
  let menuItems = generateFilteredRoutes(menus);

  useEffect(() => {
    props.siteActionVisited && props.hideMenu();
  }, [props]);

  const sortedMenus = menuItems.sort((curr, prev) => {
    return curr.order - prev.order;
  });

  const menuArray = sortedMenus
    .filter((menu) => menu.isMenu)
    .map((menu) => ({
      pathname: menu.path,
      text: menu.name,
      id: menu.pageElementId,
      parentID: menu.parentPageElementId,
      isMenu: menu.isMenu,
    }));
  const nest = (items, parent = 0) => {
    const nested = [];

    Object.values(items).forEach((item) => {
      if (item.isMenu) {
        const treeItem = {
          ...item,
        };
        if (item.parentID === parent) {
          const children = nest(items, item.id);

          if (children.length) {
            treeItem.children = children;
          }

          nested.push(treeItem);
        }
      }
    });

    return nested;
  };

  const nestedMenus = nest(menuArray);

  return (
    <>
      <NavToggler
        htmlFor="navToggle"
        tabIndex="0"
        onKeyPress={props.onKeyPress}
      >
        <Hamburger />
      </NavToggler>
      <NavToggle
        checked={props.menuToggle}
        onChange={props.setMenuToggle}
      />

      <NavStyled id="nav" searchOpened={props.searchOpened} role="navigation">
        <NavItems>
          {nestedMenus && nestedMenus.length
            ? nestedMenus.map((menu) => {
              return menu.children ? (
                <React.Fragment key={menu.id}>
                  <Submenu 
                  {...menu} 
                  onNavLinkClick={props.handleDropdownCLick}
                   menuSelected = {props.menuSelected}
                   setMenuSelected = {props.setMenuSelected}/>
                </React.Fragment>
              ) : (
                <MenuItem
                  {...menu}
                  key={menu.id}
                  onNavLinkClick={props.setMenuToggle}
                />
              );
            })
            : t("msp.noAccessToModules")}
        </NavItems>
      </NavStyled>
    </>
  );
};

Nav.propTypes = {
  sessionDetails: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  sessionDetails: selectSessionDetails(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Nav);
