import { fromJS } from "immutable";

import {
  CLEAR_FILTER_PARAMS,
  CLEAR_SUBMISSION_HISTORY,
  FECTHING_RESOLVED_INVOICE,
  FECTHING_SUBMISSION_HISTORY,
  RESOLVED_INVOICE_FETCHED,
  RESOLVED_INVOICE_FETCH_FAILED,
  SET_DATA_GETTER_PARAM,
  SET_FILTER_PARAMS,
  SET_SKIP,
  SET_SSR,
  SET_TAB,
  SHOW_DOWNLOAD_POPUP,
  SUBMISSION_HISTORY_FETCHED,
  SUBMISSION_HISTORY_FETCH_FAILED,
  TOGGLE_INVOICE_COPY_CHECK,
  TOGGLE_SUPPORTING_DOC_CHECK,
} from "./actionTypes";
import moment from "moment";
import { dbDateFormat } from "config/index";

export const defaultDataGetter = {
  dateFrom: moment().subtract(30, "days").format(dbDateFormat),
  dateTo: moment().format(dbDateFormat),

  pageSize: undefined,
};
export const initialState = fromJS({
  submittedInvoices: {},
  fetching: false,
  dataGetterParams: { ...defaultDataGetter },
  filterParams: {},
  isSSR: false,
  skip: 0,
  tabSelected: -1,
  showDocDownloadPopup: false,
  invoiceCopyChecked: false,
  supportingDocCheck: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FECTHING_SUBMISSION_HISTORY:
    case FECTHING_RESOLVED_INVOICE:
      return state.set("fetching", true);
    case SUBMISSION_HISTORY_FETCHED:
    case RESOLVED_INVOICE_FETCHED:
      return state
        .set("submittedInvoices", fromJS(action.data))
        .set("fetching", false);
    case SUBMISSION_HISTORY_FETCH_FAILED:
    case RESOLVED_INVOICE_FETCH_FAILED:
      return state.set("fetching", false);
    case SET_DATA_GETTER_PARAM:
      return state.set("dataGetterParams", fromJS(action.data));
    case SET_SSR:
      return state.set("isSSR", action.data);
    case SET_FILTER_PARAMS:
      return state.set("filterParams", fromJS(action.data));
    case CLEAR_FILTER_PARAMS:
      return state.set("filterParams", fromJS({}));
    case SET_SKIP:
      return state.set("skip", action.data);
    case SET_TAB:
      return state.set("tabSelected", action.data);
    case SHOW_DOWNLOAD_POPUP:
      return state.set("showDocDownloadPopup", action.data);
    case TOGGLE_INVOICE_COPY_CHECK:
      return state.set("invoiceCopyChecked", action.data);
    case TOGGLE_SUPPORTING_DOC_CHECK:
      return state.set("supportingDocCheck", action.data);
    case CLEAR_SUBMISSION_HISTORY:
      return state
        .set("submittedInvoices", fromJS({}))
        .set("fetching", false)
        .set("dataGetterParams", fromJS(defaultDataGetter))
        .set("filterParams", fromJS({}))
        .set("isSSR", false)
        .set("skip", "0")
        .set("tabSelected", -1)
        .set("showDocDownloadPopup", false)
        .set("invoiceCopyChecked", false)
        .set("supportingDocCheck", false);

    default:
      return state;
  }
};
export default reducer;
