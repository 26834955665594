import React from "react";
import { useTranslation } from "react-i18next";
import {
  DropzoneLayout,
  BrowseFiles,
  DetailsText,
  DropzoneTitle,
  ImportantMsg,
} from "./style";
import Upload from "components/SVG/Upload";

const Regular = (props) => {
  const { t } = useTranslation();
  return (
    <DropzoneLayout type="regular" disabled={props.disabled}>
      <Upload />
      <DropzoneTitle>
        {t("msp.dropFilesText")}&nbsp;
        <BrowseFiles
          required={props.required}
          cursor={props.disabled ? "not-allowed" : "pointer"}
        >
          {t("msp.browseFiles")}
        </BrowseFiles>
      </DropzoneTitle>
      <DetailsText>
        {props.acceptedFiles} {t("msp.fileSizeLimit", "...")}&nbsp;
        {props.maxSize}MB
      </DetailsText>
      {props.desc ? <ImportantMsg>{t(props.desc)}</ImportantMsg> : null}
    </DropzoneLayout>
  );
};

export default Regular;
