import React from "react";
import { useTranslation } from "react-i18next";
import {
  SmallDropzoneDetailsText,
  FilePreviewLabel,
  RemoveButton,
  ReplaceButton,
  CustomButton,
  DropzoneLayout,
  RightContent,
  SmallDropzoneTitle,
  BrowseFiles,
  FilePreviewImg,
  ActionBtnsContainer,
} from "./style";
import Upload from "components/SVG/Upload";

const Small = (props) => {
  const { t } = useTranslation();
  const { filePreview } = props;
  return (
    <DropzoneLayout type="small" disabled={props.disabled}>
      {filePreview ? (
        <>
          {filePreview.content ? (
            <FilePreviewImg
              src={`data:image/*;base64,${filePreview.content}`}
              alt="File Image"
            />
          ) : null}
          <RightContent>
            <FilePreviewLabel>{filePreview.name}</FilePreviewLabel>
            <ActionBtnsContainer>
              {props.noReplaceBtn ? null : (
                <ReplaceButton ref={props.replaceButtonRef}>
                  {props.replaceText ? t(props.replaceText) : t("msp.replace")}
                </ReplaceButton>
              )}
              {props.noRemoveBtn ? null : (
                <RemoveButton onClick={props.removeHandler}>
                  {t("msp.remove")}
                </RemoveButton>
              )}
              {props.showCustomBtn ? (
                <CustomButton onClick={props.customHandler}>
                  {t(props.customText)}
                </CustomButton>
              ) : null}
            </ActionBtnsContainer>
          </RightContent>
        </>
      ) : (
        <>
          <Upload />
          <RightContent>
            <SmallDropzoneTitle>
              {t(props.title)}&nbsp;
              <BrowseFiles required={props.required}>
                {t("msp.browseFiles")}
              </BrowseFiles>
            </SmallDropzoneTitle>
            <SmallDropzoneDetailsText>
              {t("msp.fileSizeLimit", "...")}&nbsp;
              {props.maxSize}MB
            </SmallDropzoneDetailsText>
          </RightContent>
        </>
      )}
    </DropzoneLayout>
  );
};

export default Small;
