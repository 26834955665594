import { fromJS } from "immutable";
import {
  INIT_VERIFICATION_DATA,
  VERIFICATION_DATA,
  VERIFICATION_FAILED,
  FETCHING_TOKEN_DETAIL,
    FETCHING_TOKEN_DETAIL_SUCCESS,
    FETCHING_TOKEN_DETAIL_FAILED,
} from "./actionTypes";

export const initialState = fromJS({
  verifying: false,
  verified: false,
  verifyFail: false,
  isTokenFetched: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_VERIFICATION_DATA: {
      return state.set("verifying", true);
    }
    case VERIFICATION_DATA: {
      return state.set("verified", true).set("verifying", false);
    }
    case VERIFICATION_FAILED: {
      return state.set("verifying", false).set("verifyFail", true);
    }
    case FETCHING_TOKEN_DETAIL: {
      return state.set("isTokenFetched", true);
    }

      case FETCHING_TOKEN_DETAIL_SUCCESS:{
      return state.set("isTokenFetched", false);
      }

      case FETCHING_TOKEN_DETAIL_FAILED:{
      return state.set("isTokenFetched", false);
      }
    default:
      return state;
  }
};
export default reducer;
