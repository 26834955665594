import InputAddon from "components/InputAddon/index";
import Label, { CustomLabel } from "components/Label";

import styled from "styled-components";
import InputControl from "../Input/style";

export const InputGroup = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: stretch;
  flex-wrap: wrap;
`;
const LabledControl = styled.div`
  width: 100%;
  ${Label}, ${CustomLabel} {
    color: ${(props) =>
      props.disabled
        ? "var(--primary-text-clr)"
        : props.error
        ? "var(--error-clr)"
        : "var(--grey-clr)"};
    pointer-events: none;
    font-family: var(--ff-main);
    max-width: calc(100% - (0.65rem));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    display: block;
  }
  ${InputGroup} {
    margin-top: 0.25em;
    ${InputControl} {
      width: 1%;
      flex: 1 1 auto;
      min-width: 0;
      position: relative;
    }
  }
  &:hover ${InputControl}, &:focus-within ${InputControl} {
    ${(props) => (props.icon ? `clip-path: inset(-5px 0px -5px -5px)` : "")};
  }
  &:hover {
    ${InputGroup} {
      ${InputAddon} {
        border-color: var(--primary-clr);
      }
    }
  }
  &:hover
    ${InputAddon},
    &:focus-within
    ${InputAddon},
    &:hover
    ${InputControl}
    ~ ${InputAddon},
    &:focus-within
    ${InputControl}
    ~ ${InputAddon},
    &:hover
    ${InputControl},
    &:focus-within
    ${InputControl} {
    box-shadow: rgb(102 187 255 / 50%) 0.25em 0 0.8rem;
    border-color: var(--primary-clr);
    outline: none;
  }
`;

export default LabledControl;
