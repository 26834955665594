import React from "react";
import SvgIcon from "./style";
const Back = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      focusable="false"
    >
      <path d="M14.6 22.6c.8.8 2 .8 2.8 0L27 13c.8-.8.8-2 0-2.8s-2-.8-2.8 0L16 18.4l-8.2-8.2c-.8-.8-2-.8-2.8 0s-.8 2 0 2.8c-.2 0 9.6 9.6 9.6 9.6z"></path>
    </SvgIcon>
  );
};

export default Back;
