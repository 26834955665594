import React from "react";
import { pure } from "recompose";
import { PrimaryBtn, SecondaryBtn, Modal } from "components/index";
import { useTranslation } from "react-i18next";

import { ContentWrapper, Message, ButtonWrapper } from "./style";

const ConfirmDelete = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      centered
      noHeader
      show={props.show}
      preventOutterClickClose
      maxWidth="70vh"
    >
      <ContentWrapper>
        <Message>{t("msp.deleteMessage")}</Message>
        <ButtonWrapper>
          <SecondaryBtn sm onClick={props.decline}>
            {t("msp.no")}
          </SecondaryBtn>
          <PrimaryBtn sm onClick={props.confirm}>
            {t("msp.yes")}
          </PrimaryBtn>
        </ButtonWrapper>
      </ContentWrapper>
    </Modal>
  );
};

export default pure(ConfirmDelete);
