import {
  INIT_CUSTOM_LIST_FETCH,
  FETCHING_CUSTOM_LIST,
  FETCH_CUSTOM_LIST,
  CUSTOM_LIST_FETCH_COMPLETE,
  INIT_DELETE_CUSTOM_LIST_ITEM,
  SAVE_CUSTOM_LIST_ITEM,
  RESET_FORM_SAVED_STATE,
  CUSTOM_LIST_ITEM_SAVED,
  INIT_FETCH_CUSTOM_LIST_ITEMS_BY_ID,
  FETCHING_LIST_ITEM_DATA,
  INIT_LIST_ITEMS_FETCH,
  FETCHING_LIST_ITEMS,
  FETCH_LIST_ITEMS,
  FETCHING_ITEM_DATA,
  FETCH_ITEM_DATA,
  INIT_ITEMS_FETCH,
  DELETE_ITEM,
  GET_LANGUAGE,
  INIT_LANGUAGE_FETCH,
  SAVE_ITEM_DATA,
  UPDATE_ITEM_DATA,
  DELETE_ITEM_LANGUAGE,
  INIT_PARENT_LIST_FETCH,
  FETCHING_PARENT_LIST,
  FETCH_PARENT_LIST,
  PARENT_LIST_FETCH_COMPLETE,
  INIT_CHILD_LIST_FETCH,
  FETCHING_CHILD_LIST,
  FETCH_CHILD_LIST,
  CHILD_LIST_FETCH_COMPLETE,
} from "./actionTypes";

//Custom List Management Data
export const initCustomListData = () => {
  return {
    type: INIT_CUSTOM_LIST_FETCH,
  };
};
export const fetchingCustomList = () => {
  return {
    type: FETCHING_CUSTOM_LIST,
  };
};
export const fetchCustomList = (data) => {
  return {
    type: FETCH_CUSTOM_LIST,
    data: data,
  };
};
export const customListFetchingComplete = () => {
  return {
    type: CUSTOM_LIST_FETCH_COMPLETE,
  };
};
export const initDeleteCustomListItem = (payload, callback) => {
  return {
    type: INIT_DELETE_CUSTOM_LIST_ITEM,
    payload,
    callback,
  };
};
export const saveCustomListItem = (rowData, saveSuccess) => {
  return {
    type: SAVE_CUSTOM_LIST_ITEM,
    data: rowData,
    saveSuccess,
  };
};
export const resetFormSavedState = () => {
  return {
    type: RESET_FORM_SAVED_STATE,
  };
};
export const customListItemSaved = () => {
  return {
    type: CUSTOM_LIST_ITEM_SAVED,
  };
};
export const initFetchListDataById = (id, fetchSuccess) => {
  return {
    type: INIT_FETCH_CUSTOM_LIST_ITEMS_BY_ID,
    id,
    callback: fetchSuccess,
  };
};
export const fetchingListItemData = () => {
  return {
    type: FETCHING_LIST_ITEM_DATA,
  };
};

//List Items Data
export const initListItemData = (custId) => {
  return {
    type: INIT_LIST_ITEMS_FETCH,
    custId,
  };
};
export const fetchingListItems = () => {
  return {
    type: FETCHING_LIST_ITEMS,
  };
};
export const fetchListItems = (data) => {
  return {
    type: FETCH_LIST_ITEMS,
    data: data,
  };
};
export const initItemData = (id, fetchSuccess) => {
  return {
    type: INIT_ITEMS_FETCH,
    id,
    callback: fetchSuccess,
  };
};
export const fetchingItemData = () => {
  return {
    type: FETCHING_ITEM_DATA,
  };
};
export const fetchItemData = (data) => {
  return {
    type: FETCH_ITEM_DATA,
    data,
  };
};
export const deleteItem = (data) => {
  return {
    type: DELETE_ITEM,
    data,
  };
};
export const fetchLanguages = () => {
  return {
    type: INIT_LANGUAGE_FETCH,
  };
};
export const getLanguages = (data) => {
  return {
    type: GET_LANGUAGE,
    data,
  };
};
export const saveItemData = (data, callback) => {
  return {
    type: SAVE_ITEM_DATA,
    data,
    callback,
  };
};
export function updateItemSearch(data) {
  return {
    type: UPDATE_ITEM_DATA,
    data,
  };
}

export const deleteItemLanguage = (
  itemId,
  langKey,
  deleteSuccess,
  langData
) => {
  return {
    type: DELETE_ITEM_LANGUAGE,
    itemId,
    langKey,
    callback: deleteSuccess,
    langData,
  };
};
export const initparentListData = () => {
  return {
    type: INIT_PARENT_LIST_FETCH,
  };
};
export const fetchingParentList = () => {
  return {
    type: FETCHING_PARENT_LIST,
  };
};
export function fetchparentList(data) {
  return {
    type: FETCH_PARENT_LIST,
    data,
  };
}
export const parentListFetchingComplete = () => {
  return {
    type: PARENT_LIST_FETCH_COMPLETE,
  };
};
export const initChildListData = (custId) => {
  return {
    type: INIT_CHILD_LIST_FETCH,
    custId,
  };
};
export const fetchingChildList = () => {
  return {
    type: FETCHING_CHILD_LIST,
  };
};
export function fetchChildList(data) {
  return {
    type: FETCH_CHILD_LIST,
    data,
  };
}
export const childListFetchingComplete = () => {
  return {
    type: CHILD_LIST_FETCH_COMPLETE,
  };
};
