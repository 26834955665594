import {
  INIT_REPORTS_DETAILS,
  FETCHING_REPORTS_DETAILS,
  FETCH_REPORT_DETAILS,
  INIT_PAYMENT_FORECAST_DETAILS,
  FETCHING_PAYMENT_FORECAST_DETAILS,
  FETCH_PAYMENT_FORECAST_DETAILS,
  REPORT_DETAILS_FETCH_COMPLETE,
  PAYMENT_FORECAST_DETAILS_FETCH_COMPLETE,
  INIT_FETCH_SUMMARY_DATA,
  FETCH_SUMMARY_DATA_COMPLETE,
  SET_SUMMARY_DATA,
  INIT_FETCH_DRILLDOWN_DATA,
  SET_DRILLDOWN_DATA,
  FETCH_DRILLDOWN_DATA_COMPLETE,
} from "./actionTypes";

export const initReportFetch = (data) => {
  return {
    type: INIT_REPORTS_DETAILS,
    data,
  };
};

export const fetchingReportDetails = () => {
  return {
    type: FETCHING_REPORTS_DETAILS,
  };
};

export const fetchReportDetails = (data) => {
  return {
    type: FETCH_REPORT_DETAILS,
    data,
  };
};

export const fetchReportDetailsComplete = () => {
  return {
    type: REPORT_DETAILS_FETCH_COMPLETE,
  };
};

export const initPaymentForecastFetch = (day, currencyID) => {
  return {
    type: INIT_PAYMENT_FORECAST_DETAILS,
    day,
    currencyID,
  };
};

export const fetchingPaymentForecastDetails = () => {
  return {
    type: FETCHING_PAYMENT_FORECAST_DETAILS,
  };
};

export const fetchPaymentForecastDetails = (data) => {
  return {
    type: FETCH_PAYMENT_FORECAST_DETAILS,
    data,
  };
};
export const fetchPaymentForecastDetailsComplete = (data) => {
  return {
    type: PAYMENT_FORECAST_DETAILS_FETCH_COMPLETE,
  };
};
export const initFetchSummaryData = (payload) => {
  return {
    type: INIT_FETCH_SUMMARY_DATA,
    payload,
  };
};
export const fetchSummaryDataComplete = (boolean) => {
  return {
    type: FETCH_SUMMARY_DATA_COMPLETE,
    boolean,
  };
};
export const setSummaryData = (data) => {
  return {
    type: SET_SUMMARY_DATA,
    data,
  };
};
export const initFetchDrillDownChartData = (
  widgetKey,
  statusKey,
  fromDate,
  toDate,
  label
) => {
  return {
    type: INIT_FETCH_DRILLDOWN_DATA,
    widgetKey,
    statusKey,
    fromDate,
    toDate,
    label,
  };
};

export const setDrillDownData = (data, key, label) => {
  return {
    type: SET_DRILLDOWN_DATA,
    data,
    key,
    label,
  };
};

export const fetchdrillDownDataComplete = (boolean) => {
  return {
    type: FETCH_DRILLDOWN_DATA_COMPLETE,
    boolean,
  };
};
