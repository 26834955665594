import { storage } from "config/storage";
import { isValidDate } from "helper/index";
import moment from "moment";
import { getGridDateFormat } from "./gridDateFormat";

const getSession = () => {
  return JSON.parse(storage.getItem("sessionDetails")) || {};
};
export const getGridData = (data) => {
  const sessionDetails = getSession();
  const { dateFormat } = sessionDetails || {};
  let dateCols = [];

  const gridData = data.map((dataItem) => {
    const cpyDataItem = { ...dataItem };
    //commented the below code, so as the container should handle selected rows
    // let findSelected = false;
    /* if (dataItemKey) {
          findSelected = selectedRows.find(
            (item) => item[dataItemKey] === dataItem[dataItemKey]
          );
        } */
    const keys = cpyDataItem && Object.keys(cpyDataItem);

    let obj = {};
    keys &&
      keys.forEach((key) => {
        if (isValidDate(cpyDataItem[key])) {
          obj[key] = "";
          const date = moment(
            moment(cpyDataItem[key]).format(dateFormat),
            dateFormat
          );

          if (date.isValid()) {
            dateCols.push(key);
            obj[key] = date.toDate();
          }
        } else obj[key] = cpyDataItem[key];
      });

    return { ...obj }; //, selected: findSelected ? findSelected.selected : false };
  });
  return {
    gridData,
    dateCols,
  };
};

export const getGridCols = (cols, dateCols) => {
  const sessionDetails = getSession();
  const { dateFormat } = sessionDetails || {};
  return cols.map((col) => {
    return {
      ...col,
      width: col.selector === "selected" ? "50px" : col.width,
      format:
        dateCols.indexOf(col.selector) > -1
          ? `{0:${getGridDateFormat(dateFormat)}}`
          : null,
    };
  });
};
