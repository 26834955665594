export const customStyles = {
  container: (base, state) => {
    const { customStyle } = state.selectProps;
    const { container } = customStyle || {};
    return {
      ...base,
      position: "relative",
      boxSizing: "border-box",
      width: "100%",
      ...container,
    };
  },
  control: (base, state) => {
    const { showError, isDisabled, visited, value, required, customStyle } =
      state.selectProps;
    const { control } = customStyle || {};
    return {
      ...base,
      borderStyle: "solid",
      borderWidth: `var(--oneDotFivePx)`,
      background: isDisabled ? "var(--disabled-clr)" : "",
      height: "1rem",
      borderRadius: ".5em",
      borderColor: state.isFocused
        ? `var(--primary-clr)`
        : showError || (visited && !value && required)
        ? `var(--error-clr)`
        : `var(--input-border-clr)`,
      boxShadow: state.isFocused
        ? "0 0 0.8rem 0 rgba(102, 187, 255, 0.5);"
        : "none",
      ...control,
    };
  },
  singleValue: (base, state) => ({
    ...base,
    color: state.selectProps.isDisabled
      ? "rgb(181,192,202)"
      : "rgb(21, 24, 27)",
  }),

  input: (base) => ({
    ...base,
  }),
  indicatorSeparator: () => ({
    display: "none",
    backgroundColor: "transparent",
  }),

  menu: (base, state) => {
    const { customStyle } = state.selectProps;
    const { menu } = customStyle || {};
    return {
      ...base,
      padding: "1em",
      left: 0,
      zIndex: 2,
      ...menu,
    };
  },
  menuList: (base) => ({
    ...base,
    maxHeight: "20rem",
  }),

  option: (base, state) => ({
    ...base,
    borderRadius: "0.25em",
    backgroundColor: state.isSelected
      ? "#e7eaee"
      : state.isFocused
      ? "var(--dropdown-hover-clr)"
      : `var(--light-clr)`,
    color: "var(--dark-clr)",
  }),
};
