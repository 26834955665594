import { createSelector } from "reselect";
import { initialState } from "./reducers";

const selectSubmittedInvoices = (state) =>
  state.get("submittedInvoices", initialState);

export const selectInvoiceFetching = () =>
  createSelector(selectSubmittedInvoices, (VPState) => VPState.get("fetching"));

export const selectInvoice = () =>
  createSelector(selectSubmittedInvoices, (VPState) => VPState.get("invoice"));

export const selectBulkInvoices = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("bulkInvoices")
  );

export const selectCurrentPage = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("currentPage")
  );

export const selectBulkBucket = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("bulkBucket")
  );

export const selectLookups = () =>
  createSelector(selectSubmittedInvoices, (VPState) => VPState.get("lookups"));

export const selectIsDuplicateInvoice = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("isDuplicateInvoice")
  );
export const selectShowLookupByID = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("showLookupByID")
  );

export const selectCurrentLookupSelection = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("currentLookupSelection")
  );
export const selectSubmittedStatus = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("submitted")
  );

export const selectPageConfig = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("pageConfig")
  );
export const selectResetPage = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("resetPage")
  );

export const selectSupportingDocuments = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("supportingDocumets")
  );
export const selectFetchingSuppotingDocuments = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("fetchnigSupportingDocuments")
  );
export const selectUploadProgress = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("uploadProgress")
  );
export const selectUploadStatus = () =>
  createSelector(selectSubmittedInvoices, (VPState) => VPState.get("uploaded"));

export const selectDownloadings = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("downloading")
  );
export const selectToleranceValue = () =>
  createSelector(selectSubmittedInvoices, (VPState) =>
    VPState.get("tolerance")
  );
