import { fromJS } from "immutable";
import {
  CREATED_INVOICE_PREVIEW,
  CREATING_INVOICE_PREVIEW,
  CREATE_INVOICE_PREVIEW_FAILED,
} from "./actionTypes";
export const initialState = fromJS({
  invoicePreview: {},
  loadingPreview: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATING_INVOICE_PREVIEW:
      return state.set("loadingPreview", true);
    case CREATED_INVOICE_PREVIEW:
      return state
        .set("invoicePreview", fromJS(action.data))
        .set("loadingPreview", false);
    case CREATE_INVOICE_PREVIEW_FAILED:
      return state.set("loadingPreview", false);
    default:
      return state;
  }
};
export default reducer;
