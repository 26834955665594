import styled from "styled-components";

import { Cols } from "components";
import Overlay from "components/Overlay/index";
import InputAddon from "components/InputAddon/index";
import InputControl from "components/Inputs/Input/style";
import LabledControl, {
  InputGroup,
} from "components/Inputs/LabeledInput/style";

//Lookup

const getBorderifAddon = (props) => {
  if (props.icon) {
    return `
        border-right: none;
        border-top-right-radius:0;
        border-bottom-right-radius:0;
    `;
  }
};

export const InputAddOnStyled = styled(InputAddon)`
  border: var(--oneDotFivePx) solid var(--input-border-clr);
  border-radius: 0.5rem;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${(props) =>
    props.disabled ? "var(--disabled-clr)" : "var(--light-clr)"};
  clip-path: inset(-8px 0px -8px -8px);
  & > * {
    pointer-events: none;
  }
`;

export const LabeledControlStyled = styled(LabledControl)`
  &:hover ${InputControl}, &:focus-within ${InputControl} {
    ${(props) => (props.icon ? `clip-path: inset(-8px 0px -8px 0px)` : "")};
  }
  > ${InputGroup} > ${InputAddOnStyled} {
    border-color: ${(props) => (props.error ? "var(--error-clr)" : "")};
  }
`;

export const InputControlStyled = styled(InputControl)`
  background-color: ${(props) =>
    props.disabled ? "var(--disabled-clr)" : "var(--light-grey-clr)"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "default")};
  border: var(--oneDotFivePx) solid var(--input-border-clr);
  border-color: ${(props) => (props.error ? "var(--error-clr)" : "")};
  border-radius: 0.5em;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  ${(props) => getBorderifAddon(props)};
  clip-path: inset(-8px -8px -8px 0px);
  :focus,
  :hover {
    box-shadow: rgb(102 187 255 / 50%) 0 0 0.8rem;
    border-color: var(--primary-clr);
    outline: none;
    ${(props) => (props.icon ? `clip-path: inset(-8px 0px -8px 0px)` : "")};
  }

  & + ${InputAddon} {
    svg {
      width: 0.75em;
      height: 0.75em;
    }
  }
`;

//LookupList
export const ModalWrapper = styled.div`
  ${Overlay} {
    z-index: 99;
  }
`;

export const BtnWrapper = styled(Cols)`
  justify-content: flex-end;
  padding: 1em;
`;

export const DataTableContainer = styled.div`
  overflow: auto;
  padding: 0.5em 1em;
`;

export const SearchWrapper = styled(Cols)`
  padding: 0.5em 1em;
`;
