import {
  INIT_FETCH_CATEGORY,
  FETCHING_CATEGORY,
  FETCH_CATEGORY,
  CATEGORY_FETCH_COMPLETE,
  INIT_FETCH_FAQ_LIST,
  FETCHING_FAQ_LIST,
  FETCH_FAQ_LIST,
  FAQ_LIST_FETCH_COMPLETE,
  INIT_FETCH_FAQ_BY_ID,
  FETCHING_FAQ_BY_ID,
  FETCH_FAQ_BY_ID,
  FAQ_BY_ID_FETCH_COMPLETE,
  INIT_SAVE_FAQ,
  FAQ_SAVED,
  FAQ_SAVE_FAILED,
  INIT_DELETE_FAQ,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAILED,
  INIT_FETCH_TRAINING_MATERIAL_LIST,
  FETCHING_TRAINING_MATERIAL_LIST,
  FETCH_TRAINING_MATERIAL_LIST,
  TRAINING_MATERIAL_LIST_FETCH_COMPLETE,
  INIT_FETCH_TRAINING_MATERIAL_BY_ID,
  FETCHING_TRAINING_MATERIAL_BY_ID,
  FETCH_TRAINING_MATERIAL_BY_ID,
  TRAINING_MATERIAL_BY_ID_FETCH_COMPLETE,
  INIT_SAVE_TRAINING_MATERIAL,
  TRAINING_MATERIAL_SAVED,
  TRAINING_MATERIAL_SAVE_FAILED,
  INIT_DELETE_TRAINING_MATERIAL,
  DELETE_TRAINING_MATERIAL_SUCCESS,
  DELETE_TRAINING_MATERIAL_FAILED,
  INIT_FETCH_TRAINING_VIDEO_LIST,
  FETCHING_TRAINING_VIDEO_LIST,
  FETCH_TRAINING_VIDEO_LIST,
  TRAINING_VIDEO_LIST_FETCH_COMPLETE,
  INIT_FETCH_TRAINING_VIDEO_BY_ID,
  FETCHING_TRAINING_VIDEO_BY_ID,
  FETCH_TRAINING_VIDEO_BY_ID,
  TRAINING_VIDEO_BY_ID_FETCH_COMPLETE,
  INIT_SAVE_TRAINING_VIDEO,
  TRAINING_VIDEO_SAVED,
  TRAINING_VIDEO_SAVE_FAILED,
  INIT_DELETE_TRAINING_VIDEO,
  DELETE_TRAINING_VIDEO_SUCCESS,
  DELETE_TRAINING_VIDEO_FAILED,
} from "./actionTypes";

//get category id
export const initFetchCategory = () => {
  return {
    type: INIT_FETCH_CATEGORY,
  };
};
export const fetchingCategory = () => {
  return {
    type: FETCHING_CATEGORY,
  };
};
export const fetchCategory = (data) => {
  return {
    type: FETCH_CATEGORY,
    data,
  };
};
export const categoryFetchComplete = () => {
  return {
    type: CATEGORY_FETCH_COMPLETE,
  };
};

//FAQ Questions
//Faq listing
export const initFetchFaqList = (data) => {
  return {
    type: INIT_FETCH_FAQ_LIST,
    data,
  };
};
export const fetchingFaqList = () => {
  return {
    type: FETCHING_FAQ_LIST,
  };
};
export const fetchFaqList = (data) => {
  return {
    type: FETCH_FAQ_LIST,
    data: data,
  };
};
export const faqListFetchingComplete = () => {
  return {
    type: FAQ_LIST_FETCH_COMPLETE,
  };
};

//faq by id
export const initFetchFaqById = (id) => {
  return {
    type: INIT_FETCH_FAQ_BY_ID,
    id,
  };
};
export const fetchingFaqById = () => {
  return {
    type: FETCHING_FAQ_BY_ID,
  };
};
export const fetchFaqById = (data) => {
  return {
    type: FETCH_FAQ_BY_ID,
    data,
  };
};
export const faqByIdFetchComplete = () => {
  return {
    type: FAQ_BY_ID_FETCH_COMPLETE,
  };
};

//save faq
export const initSaveFaq = (payload, saveSuccess) => {
  return {
    type: INIT_SAVE_FAQ,
    payload,
    saveSuccess,
  };
};
export const faqSaved = () => {
  return {
    type: FAQ_SAVED,
  };
};
export const faqSaveFailed = () => {
  return {
    type: FAQ_SAVE_FAILED,
  };
};

//delete faq
export const initDeleteFaq = (id) => {
  return {
    type: INIT_DELETE_FAQ,
    id,
  };
};
export const deleteFaqSuccess = (id) => {
  return {
    type: DELETE_FAQ_SUCCESS,
    id,
  };
};
export const deleteFaqFailed = () => {
  return {
    type: DELETE_FAQ_FAILED,
  };
};

//Traning Materials
//Training listing
export const initFetchTrainingMaterialList = (data) => {
  return {
    type: INIT_FETCH_TRAINING_MATERIAL_LIST,
    data,
  };
};
export const fetchingTrainingMaterialList = () => {
  return {
    type: FETCHING_TRAINING_MATERIAL_LIST,
  };
};
export const fetchTrainingMaterialList = (data) => {
  return {
    type: FETCH_TRAINING_MATERIAL_LIST,
    data: data,
  };
};
export const trainingMaterialListFetchingComplete = () => {
  return {
    type: TRAINING_MATERIAL_LIST_FETCH_COMPLETE,
  };
};

//training by id
export const initFetchTrainingMaterialById = (id) => {
  return {
    type: INIT_FETCH_TRAINING_MATERIAL_BY_ID,
    id,
  };
};
export const fetchingTrainingMaterialById = () => {
  return {
    type: FETCHING_TRAINING_MATERIAL_BY_ID,
  };
};
export const fetchTrainingMaterialById = (data) => {
  return {
    type: FETCH_TRAINING_MATERIAL_BY_ID,
    data,
  };
};
export const trainingMaterialByIdFetchComplete = () => {
  return {
    type: TRAINING_MATERIAL_BY_ID_FETCH_COMPLETE,
  };
};

//save training
export const initSaveTrainingMaterial = (payload, saveSuccess) => {
  return {
    type: INIT_SAVE_TRAINING_MATERIAL,
    payload,
    saveSuccess,
  };
};
export const trainingMaterialSaved = () => {
  return {
    type: TRAINING_MATERIAL_SAVED,
  };
};
export const trainingMaterialSaveFailed = () => {
  return {
    type: TRAINING_MATERIAL_SAVE_FAILED,
  };
};

//delete training material
export const initDeleteTrainingMaterial = (id) => {
  return {
    type: INIT_DELETE_TRAINING_MATERIAL,
    id,
  };
};
export const deleteTrainingMaterialSuccess = (id) => {
  return {
    type: DELETE_TRAINING_MATERIAL_SUCCESS,
    id,
  };
};
export const deleteTrainingMaterialFailed = () => {
  return {
    type: DELETE_TRAINING_MATERIAL_FAILED,
  };
};

//Traning Videos
//Training listing
export const initFetchTrainingVideoList = (data) => {
  return {
    type: INIT_FETCH_TRAINING_VIDEO_LIST,
    data,
  };
};
export const fetchingTrainingVideoList = () => {
  return {
    type: FETCHING_TRAINING_VIDEO_LIST,
  };
};
export const fetchTrainingVideoList = (data) => {
  return {
    type: FETCH_TRAINING_VIDEO_LIST,
    data: data,
  };
};
export const trainingVideoListFetchingComplete = () => {
  return {
    type: TRAINING_VIDEO_LIST_FETCH_COMPLETE,
  };
};

//training by id
export const initFetchTrainingVideoById = (id) => {
  return {
    type: INIT_FETCH_TRAINING_VIDEO_BY_ID,
    id,
  };
};
export const fetchingTrainingVideoById = () => {
  return {
    type: FETCHING_TRAINING_VIDEO_BY_ID,
  };
};
export const fetchTrainingVideoById = (data) => {
  return {
    type: FETCH_TRAINING_VIDEO_BY_ID,
    data,
  };
};
export const trainingVideoByIdFetchComplete = () => {
  return {
    type: TRAINING_VIDEO_BY_ID_FETCH_COMPLETE,
  };
};

//save training
export const initSaveTrainingVideo = (payload, saveSuccess) => {
  return {
    type: INIT_SAVE_TRAINING_VIDEO,
    payload,
    saveSuccess,
  };
};
export const trainingVideoSaved = () => {
  return {
    type: TRAINING_VIDEO_SAVED,
  };
};
export const trainingVideoSaveFailed = () => {
  return {
    type: TRAINING_VIDEO_SAVE_FAILED,
  };
};

//delete training material
export const initDeleteTrainingVideo = (id) => {
  return {
    type: INIT_DELETE_TRAINING_VIDEO,
    id,
  };
};
export const deleteTrainingVideoSuccess = (id) => {
  return {
    type: DELETE_TRAINING_VIDEO_SUCCESS,
    id,
  };
};
export const deleteTrainingVideoFailed = () => {
  return {
    type: DELETE_TRAINING_VIDEO_FAILED,
  };
};
