import React from "react";
import SvgIcon from "./style";

const ArrowCircle = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-56.000000, -436.000000)">
          <g transform="translate(32.000000, 373.000000)">
            <g transform="translate(24.000000, 63.000000)">
              <path d="M8,0.249999981 C3.71874999,0.249999981 0.249999981,3.71874999 0.249999981,8 C0.249999981,12.28125 3.71874999,15.75 8,15.75 C12.28125,15.75 15.75,12.28125 15.75,8 C15.75,3.71874999 12.28125,0.249999981 8,0.249999981 Z M14.75,8 C14.75,11.709375 11.746875,14.75 8,14.75 C4.29062499,14.75 1.24999998,11.746875 1.24999998,8 C1.24999998,4.29062499 4.25312499,1.24999998 8,1.24999998 C11.709375,1.24999998 14.75,4.25312499 14.75,8 Z M8.265625,10.70625 L11.859375,7.109375 C12.00625,6.9625 12.00625,6.725 11.859375,6.578125 L11.6375,6.35625 C11.490625,6.209375 11.253125,6.209375 11.10625,6.35625 L8,9.46875 L4.89062499,6.359375 C4.74374999,6.2125 4.50624999,6.2125 4.35937499,6.359375 L4.13749999,6.58125 C3.99062499,6.728125 3.99062499,6.965625 4.13749999,7.1125 L7.73125,10.709375 C7.88125,10.853125 8.11875,10.853125 8.265625,10.70625 L8.265625,10.70625 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default ArrowCircle;
