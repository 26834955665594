import PropTypes from "prop-types";
import Arrow from "components/SVG/Arrow";
import React from "react";
import MenuItem from "../MenuItem";
import { NavItem } from "../style";

import DropdownStyled, { DropdownContent } from "./style";

const Submenu = (props) => {
  return (
    <NavItem>
      <DropdownStyled onMouseEnter={props.setMenuSelected(false)}>
        {props.text}
        <Arrow />
      </DropdownStyled>
      {!props.menuSelected && <DropdownContent>
        {props.children && props.children.length
          ? props.children.map((menu) => <MenuItem key={menu.id} {...menu} onNavLinkClick={props.onNavLinkClick} />)
          : null}
      </DropdownContent>}
    </NavItem>
  );
};

Submenu.propTypes = {
  children: PropTypes.array,
  text: PropTypes.string,
};

export default Submenu;
