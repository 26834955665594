import React, { useEffect, useRef, useState } from "react";

import {
  FilterWrapper,
  FilterHeader,
  HeaderText,
  FilterFooter,
  FilterBody,
  FilterButton,
} from "./style";

import { usePopper } from "react-popper";
import { useTranslation } from "react-i18next";

import { Button, IconWrapper, PrimaryBtn } from "components/index";
import { Cross, FilterIcon } from "components/SVG/index";

const Filter = (props) => {
  const [show, setShow] = useState(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { t } = useTranslation();

  const customModifier = React.useMemo(
    () => ({
      name: "computeStyles",
      options: {
        roundOffsets: ({ x, y }) => {
          return {
            x: window.screen.availWidth / 16 > 36 ? x : 0,
            y: window.screen.availWidth / 16 > 36 ? y : 0,
          };
        },
      },
    }),
    []
  );

  const popperAttr = usePopper(referenceElement, popperElement, {
    placement: "auto-end",
    modifiers: [customModifier],
  });

  const { styles, attributes } = popperAttr;

  const useOutsideAlerter = (ref, holdOpenState) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(holdOpenState || false);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, holdOpenState]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.holdOpenState);
  return (
    <>
      <FilterButton onClick={() => setShow(true)} ref={setReferenceElement} sm>
        <span>
          <FilterIcon />
          {t("msp.filter")}
        </span>
      </FilterButton>

      <div
        ref={setPopperElement}
        style={{
          ...styles.popper,
          zIndex: show ? 5 : -1,
        }}
        {...attributes.popper}
      >
        <FilterWrapper ref={wrapperRef} show={show}>
          <FilterHeader>
            <HeaderText>{t("msp.filters")}</HeaderText>
            <IconWrapper
              onClick={() => setShow(false)}
              aria-label="cross-button"
            >
              <Cross />
            </IconWrapper>
          </FilterHeader>
          <FilterBody>{props.component}</FilterBody>
          <FilterFooter>
            <Button sm onClick={props.onClearFilter}>
              {t("msp.clear")}
            </Button>
            <PrimaryBtn sm onClick={props.onFilter}>
              {t("msp.apply")}
            </PrimaryBtn>
          </FilterFooter>
        </FilterWrapper>
      </div>
    </>
  );
};

export default Filter;
