import React, { useState, createContext, useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import { withTranslation } from "react-i18next";
import { getNavigatorLanguage } from "../helper";
import { storage } from "../config/storage";

export const LanguageContext = createContext();

const local = getNavigatorLanguage();

// onpage load set dropdown value ----> testing for now
let defaultDropdownValue = local;

const LanguageWrapper = (props) => {
  const [dropdownValue, setDropdownValue] = useState(defaultDropdownValue);

  //const [messages, setMessages] = useState(lang);

  const selectLanguage = (event) => {
    const newLocale = event.target.value;
    props.i18n.changeLanguage(newLocale);
    storage.setItem("languageId", newLocale);
    setDropdownValue(newLocale);
  };

  useEffect(() => {
    storage.setItem("languageId", defaultDropdownValue);
    const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
    const sessionLanguage = sessionDetails && sessionDetails.languageId;
    const lang = !!sessionLanguage ? sessionLanguage : defaultDropdownValue;
    props.i18n.changeLanguage(lang);
  }, [props.i18n]);

  return (
    <LanguageContext.Provider value={{ dropdownValue, selectLanguage }}>
      <I18nextProvider i18n={i18n}>{props.children}</I18nextProvider>
    </LanguageContext.Provider>
  );
};

export default withTranslation()(LanguageWrapper);
