import { fromJS } from "immutable";

import {
  ADVANCE_PAYMENTS_FETCH_COMPLETE,
  APPLY_FILTER,
  CLEAR_LOOKUP,
  FETCHING_ADVANCE_PAYMENTS,
  FETCH_ADVANCE_PAYMENTS,
  FETCH_ADV_PAYMENT_ACTIVE_COLS,
  SAVE_LOOKUP,
  SELECTED_SUPPLIERS,
  SET_FILTERS,
  SET_GRID_CONFIGS,
} from "./actionTypes";

export const initialState = fromJS({
  advancePayments: {
    cols: [],
    rows: [],
    totalCount: 0,
  },
  activeColumns: [],
  fetchingAdvancePayments: false,
  fetchingInvoices: false,
  gridConfigs: {},
  filterApplied: false,
  filters: {},
  lastSavedLookup: {
    isAllSuppliersSelected: false,
    deselectedSuppliers: [],
    selectedItem: [],
  },
  selectedItems: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_ADVANCE_PAYMENTS:
      return state.set("fetchingAdvancePayments", true);
    case FETCH_ADVANCE_PAYMENTS:
      return state.set("advancePayments", fromJS(action.data));
    case FETCH_ADV_PAYMENT_ACTIVE_COLS:
      return state.set("activeColumns", fromJS(action.data));
    case ADVANCE_PAYMENTS_FETCH_COMPLETE:
      return state.set("fetchingAdvancePayments", false);
    case SET_GRID_CONFIGS:
      return state.set("gridConfigs", fromJS(action.data));
    case SET_FILTERS:
      return state.set("filters", fromJS(action.data));
    case APPLY_FILTER:
      return state.set("filterApplied", true);
    case SELECTED_SUPPLIERS:
      return state.set("selectedItems", fromJS(action.data));
    case SAVE_LOOKUP:
      return state.set("lastSavedLookup", fromJS(action.data));
    case CLEAR_LOOKUP:
      return state
        .set(
          "lastSavedLookup",
          fromJS({
            isAllSuppliersSelected: false,
            deselectedSuppliers: [],
            selectedItem: [],
          })
        )
        .set("selectedItems", fromJS([]))
        .set("filterApplied", false);
    default:
      return state;
  }
};
export default reducer;
