import React from "react";
import SvgIcon from "./style";

const UserCheck = () => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
      <path
        d="M636.6 159.6a12 12 0 0 1-.1 16.8L495.2 316.6a11.86 11.86 0 0 1-16.8-.1l-81.7-82.3a11.86 11.86 0 0 1 .1-16.8l28.1-27.9a11.86 11.86 0 0 1 16.8.1l45.5 45.8 104.8-104a11.86 11.86 0 0 1 16.8.1z"
        style={{ opacity: "0.4" }}
      />
      <path d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm89.6 32h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-41.6A134.43 134.43 0 0 0 313.6 288z" />
    </SvgIcon>
  );
};

export default UserCheck;
