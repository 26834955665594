import React, { useMemo } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ReactComponent as DoubleLeftArrow } from "assets/images/Pagination/doubleLeftArrow.svg";
import { ReactComponent as LeftArrow } from "assets/images/Pagination/leftArrow.svg";
import { ReactComponent as DoubleRightArrow } from "assets/images/Pagination/doubleRightArrow.svg";
import { ReactComponent as RightArrow } from "assets/images/Pagination/rightArrow.svg";

import { Cols } from "components";

import {
  customStyles,
  PageNumber,
  PageText,
  Pages,
  PageDropdownWrapper,
  SVGWrapper,
} from "./style";

const Pagination = (props) => {
  const { t } = useTranslation();
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const usePagination = ({
    totalCount,
    pageSize,
    siblingCount,
    currentPage,
  }) => {
    const paginationRange = useMemo(() => {
      const totalPageCount = Math.ceil(totalCount / pageSize);

      const totalPageNumbers = siblingCount + 3;

      if (totalPageNumbers >= totalPageCount) {
        return range(1, totalPageCount);
      }

      const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
      const rightSiblingIndex = Math.min(
        currentPage + siblingCount,
        totalPageCount
      );

      const shouldShowLeftDots = leftSiblingIndex > 2;
      const shouldShowRightDots = rightSiblingIndex <= totalPageCount - 2;

      if (!shouldShowLeftDots && shouldShowRightDots) {
        const leftItemCount = 2 + 2 * siblingCount;
        const leftRange = range(1, leftItemCount);

        return [...leftRange, "dots"];
      }

      if (shouldShowLeftDots && !shouldShowRightDots) {
        const rightItemCount = 2 + 2 * siblingCount;
        const rightRange = range(
          totalPageCount - rightItemCount + 1,
          totalPageCount
        );
        return ["dots", ...rightRange];
      }

      if (shouldShowLeftDots && shouldShowRightDots) {
        let middleRange = range(leftSiblingIndex, rightSiblingIndex);
        return ["dots", ...middleRange, "dots"];
      }
    }, [totalCount, pageSize, siblingCount, currentPage]);
    return paginationRange;
  };

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (!paginationRange || currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const totalPageCount = Math.ceil(totalCount / pageSize);

  const onNext = () => {
    currentPage < totalPageCount && onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    currentPage !== 1 && onPageChange(currentPage - 1);
  };

  const onClickDots = (isLeftDot) => {
    const computedPage = isLeftDot
      ? currentPage - 2 * siblingCount
      : currentPage + 2 * siblingCount;
    onPageChange(computedPage);
  };

  const normalizePageNoList = range(1, totalPageCount).map((item) => ({
    value: item,
    label: item < 10 ? `0${item}` : `${item}`,
  }));
  return (
    <Cols>
      <Pages as="ul" range={paginationRange}>
        <li>
          <SVGWrapper
            disabled={currentPage === 1}
            onClick={() => onPageChange(1)}
            title={currentPage !== 1 ? t("msp.pagerFirstPage") : ""}
          >
            <DoubleLeftArrow title="" />
          </SVGWrapper>
        </li>
        <li>
          <SVGWrapper
            disabled={currentPage === 1}
            onClick={onPrevious}
            title={currentPage !== 1 ? t("msp.pagerFirstPage") : ""}
          >
            <LeftArrow title="" />
          </SVGWrapper>
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === "dots") {
            const isLeftDot = index < paginationRange.length - 1;
            return (
              <li key={index} onClick={() => onClickDots(isLeftDot)}>
                &#8230;
              </li>
            );
          }

          return (
            <li key={index}>
              <PageNumber
                onClick={() => onPageChange(pageNumber)}
                active={pageNumber === currentPage}
              >
                {pageNumber}
              </PageNumber>
            </li>
          );
        })}
        <li>
          <SVGWrapper
            disabled={currentPage === totalPageCount}
            onClick={onNext}
            title={currentPage !== totalPageCount ? t("msp.pagerNextPage") : ""}
          >
            <RightArrow title="" />
          </SVGWrapper>
        </li>
        <li>
          <SVGWrapper
            disabled={currentPage === totalPageCount}
            onClick={() => onPageChange(totalPageCount)}
            title={currentPage !== totalPageCount ? t("msp.pagerLastPage") : ""}
          >
            <DoubleRightArrow title="" />
          </SVGWrapper>
        </li>
      </Pages>
      <PageDropdownWrapper>
        <PageText>{t("msp.page")}</PageText>
        <Select
          options={normalizePageNoList}
          value={normalizePageNoList.find((item) => item.value === currentPage)}
          onChange={(selectedObj) => {
            onPageChange(selectedObj.value);
          }}
          styles={customStyles}
          menuPlacement="top"
        />
      </PageDropdownWrapper>
    </Cols>
  );
};

export default Pagination;
