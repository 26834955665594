export const INIT_WORKFLOW_LIST_FETCH = "INIT_WORKFLOW_LIST_FETCH";
export const FETCHING_WORKFLOW_LIST = "FETCHING_WORKFLOW_LIST";
export const FETCH_WORKFLOW_LIST = "FETCH_WORKFLOW_LIST";
export const WORKFLOW_LIST_FETCH_COMPLETE = "WORKFLOW_LIST_FETCH_COMPLETE";

export const INIT_DELETE_WORKFLOW = "INIT_DELETE_WORKFLOW";
export const DELETING_WORKFLOW = "DELETING_WORKFLOW";
export const WORKFLOW_DELETED = "WORKFLOW_DELETED";

export const INIT_CONDITION_LIST_FETCH = "INIT_CONDITION_LIST_FETCH";
export const FETCHING_CONDITION_LIST = "FETCHING_CONDITION_LIST";
export const FETCH_CONDITION_LIST = "FETCH_CONDITION_LIST";
export const CONDITION_LIST_FETCH_COMPLETE = "CONDITION_LIST_FETCH_COMPLETE";

export const INIT_FETCH_WORKFLOW_BY_ID = "INIT_FETCH_WORKFLOW_BY_ID";
export const FETCHING_WORKFLOW_BY_ID = "FETCHING_WORKFLOW_BY_ID";
export const FETCH_WORKFLOW_BY_ID = "FETCH_WORKFLOW_BY_ID";
export const WORKFLOW_BY_ID_FETCH_COMPLETE = "WORKFLOW_BY_ID_FETCH_COMPLETE";

export const INIT_WORKFLOW_SAVE = "INIT_WORKFLOW_SAVE";
export const WORKFLOW_SAVED = "WORKFLOW_SAVED";
export const WORKFLOW_SAVE_FAILED = "WORKFLOW_SAVE_FAILED";
