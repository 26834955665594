import React, { useEffect, useState } from "react";
import FloatingControl from "./style";

import TextareaControl from "../Textarea/style";
import { ErrorText, Label } from "components/index";

const FloatingTextarea = (props) => {
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    props.resetVisited && setVisited(false);
  }, [props.resetVisited]);

  const onInputChange = (e) => {
    let { value, maxLength } = e.target;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    const strOfVal = strippedHtml.toString();

    if (strOfVal.length > maxLength) {
      const validatedLength = strOfVal.substring(0, maxLength);
      e.target.value = validatedLength;
    }
    props.onChangeHandler && props.onChangeHandler(e);
  };
  const handleOnBlur = (e) => {
    setVisited(true);
    let { value, maxLength } = e.target;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    const strOfVal = strippedHtml.toString();

    if (strOfVal.length > maxLength) {
      const validatedLength = strOfVal.substring(0, maxLength);
      e.target.value = validatedLength;
    }
    props.onBlurHandler && props.onBlurHandler(e);
  };
  const passedProps = { ...props };
  delete passedProps.onBlurHandler;
  delete passedProps.onChangeHandler;
  delete passedProps.showError;
  return (
    <FloatingControl
      disabled={props.disabled}
      error={props.showError}
      visited={visited || props.value}
    >
      <TextareaControl
        {...passedProps}
        error={props.showError}
        placeholder="&nbsp;"
        aria-label={props.placeholder}
        onBlur={handleOnBlur}
        onChange={onInputChange}
      />
      <Label htmlFor={props.id} required={props.required}>
        {props.placeholder}
      </Label>
      <ErrorText>{props.showError ? props.error : null}</ErrorText>
    </FloatingControl>
  );
};

export default FloatingTextarea;
