import React from "react";
import SvgIcon from "./style";
const PlusCircle = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-457.000000, -904.000000)">
          <g transform="translate(190.000000, 894.000000)">
            <g transform="translate(267.000000, 10.000000)">
              <path d="M8.99999999,5.859375 L8.99999999,6.140625 C8.99999999,6.2953125 8.87343751,6.421875 8.71875001,6.421875 L6.421875,6.421875 L6.421875,8.71875001 C6.421875,8.87343751 6.2953125,8.99999999 6.140625,8.99999999 L5.859375,8.99999999 C5.7046875,8.99999999 5.578125,8.87343751 5.578125,8.71875001 L5.578125,6.421875 L3.28124999,6.421875 C3.12656249,6.421875 2.99999999,6.2953125 2.99999999,6.140625 L2.99999999,5.859375 C2.99999999,5.7046875 3.12656249,5.578125 3.28124999,5.578125 L5.578125,5.578125 L5.578125,3.28124999 C5.578125,3.12656249 5.7046875,2.99999999 5.859375,2.99999999 L6.140625,2.99999999 C6.2953125,2.99999999 6.421875,3.12656249 6.421875,3.28124999 L6.421875,5.578125 L8.71875001,5.578125 C8.87343751,5.578125 8.99999999,5.7046875 8.99999999,5.859375 Z M11.8125,6 C11.8125,9.21093751 9.21093751,11.8125 6,11.8125 C2.78906249,11.8125 0.187499976,9.21093751 0.187499976,6 C0.187499976,2.78906249 2.78906249,0.187499976 6,0.187499976 C9.21093751,0.187499976 11.8125,2.78906249 11.8125,6 Z M11.0625,6 C11.0625,3.18984374 8.78203126,0.937499979 6,0.937499979 C3.18984374,0.937499979 0.937499979,3.21796874 0.937499979,6 C0.937499979,8.81015626 3.21796874,11.0625 6,11.0625 C8.81015626,11.0625 11.0625,8.78203126 11.0625,6 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default PlusCircle;
