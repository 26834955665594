import { all, takeLatest, call, put, select } from "redux-saga/effects";
import {
  INIT_COUNTRY_LIST_FETCH,
  INIT_DOCUMENT_TYPES_FETCH,
  INIT_FETCH_ADDITIONAL_FIELDS,
  INIT_PO_NUMBER_FETCH,
  INIT_PROFILE_LIST_FETCH,
  INIT_STANDARD_FIELDS_FETCH,
  INIT_SUPPLIER_LIST_FETCH,
} from "./actionTypes";
import {
  additionalingFieldsFetchComplete,
  clearAdditionalFields,
  countryFetchingComplete,
  fetchAdditionalFields,
  fetchAdditionalingFields,
  fetchCountryDropdownList,
  fetchDocumentTypes,
  fetchDocumentTypesCompleted,
  fetchPONumbers,
  fetchProfileDropdownList,
  fetchStandardFieldsList,
  fetchSupplierDropdownList,
  fetchingCountry,
  fetchingDocumentTypes,
  fetchingPONumbers,
  fetchingProfiles,
  fetchingStandardFields,
  fetchingSuppliers,
  poNumbersFetchComplete,
  profileFetchingComplete,
  standardFieldsFetchingComplete,
  supplierFetchingComplete,
} from "./actions";
import { getRequestDetails } from "config/requestHeaders";
import axios from "../../../config/axiosCall";
import { selectSessionDetails } from "container/Authentication/store/selectors";
import { getAdditionalFields } from "./services";

export default function* watchCommonDS() {
  yield all([
    takeLatest(INIT_STANDARD_FIELDS_FETCH, getStandardFieldsSaga),
    takeLatest(INIT_COUNTRY_LIST_FETCH, getCountryDropdownListSaga),
    takeLatest(INIT_SUPPLIER_LIST_FETCH, getSupplierSaga),
    takeLatest(INIT_PROFILE_LIST_FETCH, getProfileDropdownListSaga),
    takeLatest(INIT_PO_NUMBER_FETCH, initFecthPoNumberSaga),
    takeLatest(INIT_DOCUMENT_TYPES_FETCH, initDocumentTypesSaga),
    takeLatest(INIT_FETCH_ADDITIONAL_FIELDS, getAdditionalFieldsSaga),
  ]);
}
function* getStandardFieldsSaga(action) {
  yield put(fetchingStandardFields());
  const url = `/Submission/GetStandardFields`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const data = yield response.data;
    yield put(fetchStandardFieldsList(data));
    yield put(standardFieldsFetchingComplete());
  } catch (error) {
    yield put(standardFieldsFetchingComplete());
  }
}
function* getCountryDropdownListSaga() {
  yield put(fetchingCountry());
  const url = `/Submission/country?langKey=enGB`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const data = yield response.data &&
      response.data.map((item) => ({
        id: item.countryId,
        value: item.label,
      }));
    yield put(fetchCountryDropdownList(data));
    yield put(countryFetchingComplete());
  } catch (error) {
    yield put(countryFetchingComplete());
  }
}

function* getSupplierSaga(action) {
  yield put(fetchingSuppliers());
  if (!action.payload) {
    yield put(fetchSupplierDropdownList([]));
    yield put(supplierFetchingComplete());
    return true;
  }
  const url = `/Submission/suppliers?&countryID=${action.payload}`;

  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    var data = [];
    if (response && response.status !== 204)
    {
       data = yield response.data &&
        response.data.map((item) => ({
          value: item.supplierName,
          id: item.supplierID,
        }));
    }
    yield put(fetchSupplierDropdownList(data));
    yield put(supplierFetchingComplete());
  } catch (error) {
    yield put(supplierFetchingComplete());
  }
}
function* getProfileDropdownListSaga(action) {
  yield put(fetchingProfiles());
  const { countryID } = action.payload;
  if (!countryID) {
    yield put(fetchProfileDropdownList([]));
    yield put(clearAdditionalFields());
    yield put(profileFetchingComplete());
    return false;
  }
  const url = `/Submission/profiles?countryID=${countryID}&langKey=enGB`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const data = yield response.data.length > 0
      ? response.data
          .map((item) => ({
            value: item.name ? item.name : item.description,
            id: item.id,
            isAttachment: item.isAttachmentRequired,
            active: item.active,
            isPOInvoice: item.isPOInvoice,
          }))
          .filter((item) => item.active)
      : [];

    yield put(fetchProfileDropdownList(data));
    yield put(profileFetchingComplete());
  } catch (error) {
    yield put(profileFetchingComplete());
  }
}
function* initFecthPoNumberSaga(action) {
  yield put(fetchingPONumbers());
  let url = `/PurchaseOrder/list?`;
  if (!action.payload || !action.payload.supplierId) {
    yield put(fetchPONumbers([]));
    yield put(poNumbersFetchComplete());
    return true;
  } else {
    const { payload } = action || {};
    if (Object.keys(payload).length > 0) {
      Object.keys(payload).forEach((key) => {
        if (key && payload[key] !== undefined && payload[key] !== null) {
          url += `${key}=${payload[key]}&`;
        }
      });
    }
  }
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const data = yield response.data
      ? response.data.map((item) => ({
          id: item.poNumber,
          value: item.poNumber,
          companyCode: item.companyCode,
          vendorNumber: item.vendorNumber,
          amount: parseFloat(item.amount),
          status: item.status,
        }))
      : [];
    yield put(fetchPONumbers(data));
    yield put(poNumbersFetchComplete());
  } catch (error) {
    yield put(poNumbersFetchComplete());
  }
}
function* initDocumentTypesSaga() {
  yield put(fetchingDocumentTypes());
  const url = "/Common/DocumentType";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    yield put(fetchDocumentTypes(response.data));
    yield put(fetchDocumentTypesCompleted());
  } catch (error) {
    yield put(fetchDocumentTypesCompleted());
  }
}
function* getAdditionalFieldsSaga(action) {
  try {
    const profileId = action.data;
    const sessionDetails = yield select(selectSessionDetails());
    const { languageId } = sessionDetails;
    let fields = [];
    if (!profileId) {
      yield put(fetchAdditionalFields([]));
      yield put(additionalingFieldsFetchComplete());
      return;
    }

    yield put(fetchAdditionalingFields());

    const requestDetails = { ...getRequestDetails };

    const response = yield call(
      getAdditionalFields,
      { languageId, profileId },
      requestDetails
    );
    if (response && response.data) {
      fields = yield response.data;
    }

    yield put(fetchAdditionalFields(fields));
    yield put(additionalingFieldsFetchComplete());
  } catch (error) {
    yield put(additionalingFieldsFetchComplete());
  }
}
