import {
  CREATED_INVOICE_PREVIEW,
  CREATE_INVOICE_PREVIEW_FAILED,
  CREATING_INVOICE_PREVIEW,
  INIT_CREATE_INVOICE_PREVIEW,
} from "./actionTypes";

export const initCreateInvoicePreview = (payload) => ({
  type: INIT_CREATE_INVOICE_PREVIEW,
  payload,
});
export const creatingInvoicePreview = () => ({
  type: CREATING_INVOICE_PREVIEW,
});
export const createdInvoicePreview = (data) => ({
  type: CREATED_INVOICE_PREVIEW,
  data,
});

export const createInvoicePreviewFailed = () => ({
  type: CREATE_INVOICE_PREVIEW_FAILED,
});
