import React from "react";

import { useTranslation } from "react-i18next";
import BusyLoaderWrapper, { BusyOverlayStyled } from "./style";

const BusyOverlay = (props) => {
  const { t } = useTranslation();

  const msg = props.msg ? props.msg : t("msp.savingDataOverlayMsg");
  return (
    <BusyOverlayStyled>
      <BusyLoaderWrapper>
        <h1>{msg}</h1>
      </BusyLoaderWrapper>
    </BusyOverlayStyled>
  );
};

export default BusyOverlay;
