import { fromJS } from "immutable";

import {
  FETCH_MODULE_LIST,
  FETCHING_MODULE_LIST,
  MODULE_LIST_FETCH_COMPLETE,
  FETCHING_ATTRIBUTE_TYPE_LIST,
  FETCH_ATTRIBUTE_TYPE_LIST,
  ATTRIBUTE_TYPE_LIST_FETCH_COMPLETE,
  FETCHING_ATTRIBUTES_LIST,
  FETCH_ATTRIBUTES_LIST,
  ATTRIBUTES_LIST_FETCH_COMPLETE,
  FETCHING_CUSTOM_LIST,
  FETCH_CUSTOM_LIST,
  CUSTOM_LIST_FETCH_COMPLETE,
  FETCHING_VMD_TAB_DATA,
  FETCH_VMD_TAB_DATA,
  VMD_TAB_DATA_FETCH_COMPLETE,
  FETCHING_VMD_SECTION_DATA,
  FETCH_VMD_SECTION_DATA,
  VMD_SECTION_DATA_FETCH_COMPLETE,
  FETCHING_SEARCH_FIELD_PLACEMENT,
  FETCH_SEARCH_FIELD_PLACEMENT,
  SEARCH_FIELD_PLACEMENT_FETCH_COMPLETE,
  FETCHING_SUBMISSION_FIELD_PLACEMENT,
  FETCH_SUBMISSION_FIELD_PLACEMENT,
  SUBMISSION_FIELD_PLACEMENT_FETCH_COMPLETE,
  INIT_ATTRIBUTES_DELETE,
  ATTRIBUTES_DELETE_SUCCESS,
  ATTRIBUTES_DELETE_FAILED,
  INIT_ATTRIBUTE_EDIT,
  SET_EDIT_ATTRIBUTE_DATA,
  INIT_ATTRIBUTE_SAVE,
  ATTRIBUTE_SAVE_FAILED,
  ATTRIBUTE_SAVED,
  RESET_ATTRIBUTE_SAVE_STATUS,
  FETCHING_APPROVAL_TYPE_LIST,
  FETCH_APPROVAL_TYPE_LIST,
  FETCHING_REGEX_LIST,
  FETCH_REGEX_LIST,
  FETCHING_VALIDATION_TYPE,
  FETCH_VALIDATION_TYPE,
  VALIDATION_TYPE_FETCH_COMPLETE,
  REGEX_LIST_FETCH_COMPLETE,
  APPROVAL_TYPE_LIST_FETCH_COMPLETE,
  ATTRIBUTE_FORM_SUBMISSION_STATUS,
  SET_EDIT_ATTRIBUTE_DATA_COMPLETED,
  FETCHING_CREATE_COMPANY_SECTION_DATA,
  FETCH_CREATE_COMPANY_SECTION_DATA,
  CREATE_COMPANY_SECTION_DATA_FETCH_COMPLETE,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingSupplierFields: false,
  supplierFieldData: [],
  fetchingModuleList: false,
  moduleList: [],
  fetchingAttributeTypeList: false,
  attributeTypeList: [],
  fetchingAttributesList: false,
  attributesList: [],
  updatedAttributeList: [],
  fetchingHelpdeskAttributeList: false,
  helpdeskAttributeList: [],
  updateHelpdeskAttributeList: [],
  selectAttribute: [],
  fetchingAttributeHierarchyList: false,
  attributeHierarchyList: [],
  fetchingAttributeHierarchyLevel: false,
  attributeHierarchyLevel: [],
  fetchingCustomList: false,
  customList: [],
  fetchingVmdTabData: false,
  vmdTabData: [],
  fetchingVmdSectionData: false,
  vmdSectionData: [],
  fetchingCreateCompanySectionData: false,
  createCompanySectionData: [],
  fetchingSubmissionFieldPlacement: false,
  submissionFieldPlacement: [],
  fetchingSearchFieldPlacement: false,
  searchFieldPlacement: [],
  fetchingHelpdeskQueryByID: false,
  helpdeskQueryByID: {},
  attributeDeleting: false,
  attributeDeleted: [],
  attributeEditing: false,
  attributeEditData: null,
  fetchForEditComplete: false,
  attributeSaving: false,
  attributeFormSubmissionStatus: "",
  attributeSaveFailed: false,
  fetchingSearchFieldsList: false,
  searchFieldsList: [],
  updatedSearchFieldsList: [],
  fetchingEmailTemplatesList: false,
  emailTemplatesList: [],
  helpdeskConfigarationSaving: false,
  helpdeskFormSubmissionStatus: "",
  helpdeskConfigarationSaveFailed: false,
  searchConfigarationSaving: false,
  searchFormSubmissionStatus: "",
  searchConfigarationSaveFailed: false,
  tab: null,
  fetchingRegExList: false,
  regExList: [],
  fetchingApprovalTypeList: false,
  approvalTypeList: [],
  fetchingHelpdeskQueryTypeList: false,
  helpdeskQueryTypeList: [],
  fetchingValidationType: false,
  validationType: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_MODULE_LIST: {
      return state.set("fetchingModuleList", true);
    }
    case FETCH_MODULE_LIST: {
      return state.set("moduleList", action.data);
    }
    case MODULE_LIST_FETCH_COMPLETE: {
      return state.set("fetchingModuleList", false);
    }
    case FETCHING_ATTRIBUTE_TYPE_LIST: {
      return state.set("fetchingAttributeTypeList", true);
    }
    case FETCH_ATTRIBUTE_TYPE_LIST: {
      return state.set("attributeTypeList", action.data);
    }
    case ATTRIBUTE_TYPE_LIST_FETCH_COMPLETE: {
      return state.set("fetchingAttributeTypeList", false);
    }
    case FETCHING_ATTRIBUTES_LIST: {
      return state.set("fetchingAttributesList", true);
    }
    case FETCH_ATTRIBUTES_LIST: {
      return state.set("attributesList", action.data);
    }
    case ATTRIBUTES_LIST_FETCH_COMPLETE: {
      return state.set("fetchingAttributesList", false);
    }
    case FETCHING_CUSTOM_LIST: {
      return state.set("fetchingCustomList", true);
    }
    case FETCH_CUSTOM_LIST: {
      return state.set("customList", action.data);
    }
    case CUSTOM_LIST_FETCH_COMPLETE: {
      return state.set("fetchingCustomList", false);
    }

    case FETCHING_VMD_TAB_DATA: {
      return state.set("fetchingVmdTabData", true);
    }
    case FETCH_VMD_TAB_DATA: {
      return state.set("vmdTabData", action.data);
    }
    case VMD_TAB_DATA_FETCH_COMPLETE: {
      return state.set("fetchingVmdTabData", false);
    }

    case FETCHING_VMD_SECTION_DATA: {
      return state.set("fetchingVmdSectionData", true);
    }
    case FETCH_VMD_SECTION_DATA: {
      return state.set("vmdSectionData", action.data);
    }
    case VMD_SECTION_DATA_FETCH_COMPLETE: {
      return state.set("fetchingVmdSectionData", false);
    }

    case FETCHING_CREATE_COMPANY_SECTION_DATA: {
      return state.set("fetchingCreateCompanySectionData", true);
    }
    case FETCH_CREATE_COMPANY_SECTION_DATA: {
      return state.set("createCompanySectionData", action.data);
    }
    case CREATE_COMPANY_SECTION_DATA_FETCH_COMPLETE: {
      return state.set("fetchingCreateCompanySectionData", false);
    }

    case FETCHING_SUBMISSION_FIELD_PLACEMENT: {
      return state.set("fetchingSubmissionFieldPlacement", true);
    }
    case FETCH_SUBMISSION_FIELD_PLACEMENT: {
      return state.set("submissionFieldPlacement", action.data);
    }
    case SUBMISSION_FIELD_PLACEMENT_FETCH_COMPLETE: {
      return state.set("fetchingSubmissionFieldPlacement", false);
    }
    case FETCHING_SEARCH_FIELD_PLACEMENT: {
      return state.set("fetchingSearchFieldPlacement", true);
    }
    case FETCH_SEARCH_FIELD_PLACEMENT: {
      return state.set("searchFieldPlacement", action.data);
    }
    case SEARCH_FIELD_PLACEMENT_FETCH_COMPLETE: {
      return state.set("fetchingSearchFieldPlacement", false);
    }
    case INIT_ATTRIBUTES_DELETE: {
      return state.set("attributeDeleting", true);
    }
    case ATTRIBUTES_DELETE_SUCCESS: {
      return state
        .set("attributeDeleting", false)
        .set("attributeDeleted", [
          ...state.get("attributeDeleted"),
          ...action.id,
        ]);
    }
    case ATTRIBUTES_DELETE_FAILED: {
      return state.set("attributeDeleting", false);
    }
    case INIT_ATTRIBUTE_EDIT: {
      return state.set("attributeEditing", true);
    }
    case SET_EDIT_ATTRIBUTE_DATA: {
      return state.set("attributeEditData", action.data);
    }
    case SET_EDIT_ATTRIBUTE_DATA_COMPLETED: {
      return state
        .set("fetchForEditComplete", action.data)
        .set("attributeEditing", false);
    }
    case INIT_ATTRIBUTE_SAVE: {
      return state.set("attributeSaving", true);
    }
    case ATTRIBUTE_SAVED: {
      return state.set("attributeSaving", false).set("attributeEditing", false);
    }
    case ATTRIBUTE_SAVE_FAILED: {
      return state
        .set("attributeSaving", false)
        .set("attributeSaveFailed", true);
    }
    case ATTRIBUTE_FORM_SUBMISSION_STATUS: {
      return state.set("attributeFormSubmissionStatus", action.data);
    }
    case RESET_ATTRIBUTE_SAVE_STATUS: {
      return state.set("attributeFormSubmissionStatus", false);
    }
    case FETCHING_REGEX_LIST: {
      return state.set("fetchingRegExList", true);
    }
    case FETCH_REGEX_LIST: {
      return state.set("regExList", action.data);
    }
    case REGEX_LIST_FETCH_COMPLETE: {
      return state.set("fetchingRegExList", false);
    }

    case FETCHING_APPROVAL_TYPE_LIST: {
      return state.set("fetchingApprovalTypeList", true);
    }
    case FETCH_APPROVAL_TYPE_LIST: {
      return state.set("approvalTypeList", action.data);
    }
    case APPROVAL_TYPE_LIST_FETCH_COMPLETE: {
      return state.set("fetchingApprovalTypeList", false);
    }

    case FETCHING_VALIDATION_TYPE: {
      return state.set("fetchingValidationType", true);
    }
    case FETCH_VALIDATION_TYPE: {
      return state.set("validationType", action.data);
    }
    case VALIDATION_TYPE_FETCH_COMPLETE: {
      return state.set("fetchingValidationType", false);
    }
    default:
      return state;
  }
};

export default reducer;
