import Label from "components/Label";
import styled from "styled-components";

const FloatingControl = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  :before {
    content: "";
    position: absolute;
    top: var(--oneDotFivePx);
    left: var(--oneDotFivePx);
    border-radius: 0.5em;
    width: calc(100% - 0.75rem); /* to show scrollbar */
    height: 1.1em;
    background: ${(props) =>
      props.disabled ? "var(--disabled-clr)" : "var(--light-clr)"};
  }

  textarea {
    width: 100%;
    padding: 1rem;
    padding-top: 1.5rem;
    padding-left: 0.75rem;
    outline: 0;
    border-radius: 0.5em;

    background: ${(props) =>
      props.disabled ? "var(--disabled-clr)" : "var(--light-clr)"};
    font-size: var(--fs-16);
    margin-bottom: 1em;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};
    :not(:focus):invalid {
      color: ${(props) => (props.visited ? "var(--error-clr)" : "")};
      border: ${(props) =>
        props.visited ? "var(--onePx) solid var(--error-clr)" : ""};
      & + ${Label} {
        color: ${(props) => (props.visited ? "var(--error-clr)" : "")};
      }
    }
    :hover,
    :focus {
      border: var(--border-primary-1);
      box-shadow: rgb(102 187 255 / 50%) 0px 0px 0.8rem;
    }
  }

  ${Label} {
    font-family: var(--ff-main);
    padding-left: 1rem;
    padding-right: 0.5rem;
    color: ${(props) =>
      props.disabled
        ? "var(--primary-text-clr)"
        : props.error
        ? "var(--error-clr)"
        : "var(--grey-clr)"};
    pointer-events: none;
    position: absolute;
    transform: translate(0, 1.2em) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
    max-width: calc(100% - (0.65rem));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:focus-within ${Label} {
    color: var(--primary-clr);
  }
  &:focus-within ${Label}, & textarea:not(:placeholder-shown) + ${Label} {
    transform: translate(0, 0.5em) scale(0.75);
    font-family: var(--ff-medium);
  }
`;

export default FloatingControl;
