export const SET_FORM_VALUES = "SET_FORM_VALUES";
export const CLEAR_FORM_VALUES = "CLEAR_FORM_VALUES";

export const SET_ISPO = "SET_ISPO";

export const INIT_STANDARD_FIELDS_FETCH = "INIT_STANDARD_FIELD_FETCH";
export const FETCHING_STANDARD_FIELDS = "FETCHING_STANDARD_FIELDS";
export const FETCH_STANDARD_FIELDS = "FETCH_STANDARD_FIELDS";
export const STANDARD_FIELDS_FETCH_COMPLETE = "STANDARD_FIELDS_FETCH_COMPLETE";

export const INIT_COUNTRY_LIST_FETCH = "INIT_COUNTRY_LIST_FETCH";
export const FETCHING_COUNTRY_LIST = "FETCHING_COUNTRY_LIST";
export const FETCH_COUNTRY_LIST = "FETCH_COUNTRY_LIST";
export const COUNTRY_LIST_FETCH_COMPLETE = "COUNTRY_LIST_FETCH_COMPLETE";

export const INIT_SUPPLIER_LIST_FETCH = "INIT_SUPPLIER_LIST_FETCH";
export const FETCHING_SUPPLIER_LIST = "FETCHING_SUPPLIER_LIST";
export const FETCH_SUPPLIER_LIST = "FETCH_SUPPLIER_LIST";
export const SUPPLIER_LIST_FETCH_COMPLETE = "SUPPLIER_LIST_FETCH_COMPLETE";

export const INIT_PROFILE_LIST_FETCH = "INIT_PROFILE_LIST_FETCH";
export const FETCHING_PROFILE_LIST = "FETCHING_PROFILE_LIST";
export const FETCH_PROFILE_LIST = "FETCH_PROFILE_LIST";
export const PROFILE_LIST_FETCH_COMPLETE = "PROFILE_LIST_FETCH_COMPLETE";

export const INIT_PO_NUMBER_FETCH = "INIT_PO_NUMBER_FETCH";
export const FETCHING_PO_NUMBER = "FETCHING_PO_NUMBER";
export const FETCH_PO_NUMBER = "FETCH_PO_NUMBER";
export const PO_NUMBER_FETCH_COMPLETE = "PO_NUMBER_FETCH_COMPLETE";

export const INIT_FETCH_ADDITIONAL_FIELDS = "INIT_FETCH_ADDITIONAL_FIELDS";
export const FECTHING_ADDITIONAL_FIELDS = "FECTHING_ADDITIONAL_FIELDS";
export const FETCH_ADDITIONAL_FIELDS = "FETCH_ADDITIONAL_FIELDS";
export const ADDITIONAL_FIELDS_FETCH_COMPLETE =
  "ADDITIONAL_FIELDS_FETCH_COMPLETE";
export const CLEAR_ADDITIONAL_FIELDS = "CLEAR_ADDITIONAL_FIELDS";

export const INIT_DOCUMENT_TYPES_FETCH = "INIT_DOCUMENT_TYPES_FETCH";
export const FETCHING_DOCUMENT_TYPES = "FETCHING_DOCUMENT_TYPES";
export const FETCH_DOCUMENT_TYPES = "FETCH_DOCUMENT_TYPES";
export const DOCUMENT_TYPES_FETCH_COMPLETE = "DOCUMENT_TYPES_FETCH_COMPLETE";

export const SET_VISITED = "SET_VISITED";
export const RESET_VISITED = "RESET_VISITED";

export const SET_CLIENT_REF_VALUES = "SET_CLIENT_REF_VALUES";

export const SET_SUBMISSION_ID = "SET_SUBMISSION_ID";
export const SET_IS_OCR_EXTRACTED = "SET_IS_OCR_EXTRACTED";

export const RESET_COMMON_DS_VALUES = "RESET_COMMON_DS_VALUES";

export const SET_PO_FILTERS = "SET_PO_FILTERS";
