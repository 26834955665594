import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectAuthenticate = (state) => state.get("authenticate", initialState);

export const selectMFAOTPSentStatus = () =>
  createSelector(selectAuthenticate, (VPState) => VPState.get("MFAOTPSent"));

export const selectAuthenticatingLogin = () =>
  createSelector(selectAuthenticate, (VPState) =>
    VPState.get("authenticating")
  );

export const selectNumberOfWrongAttempts = () =>
  createSelector(selectAuthenticate, (VPState) =>
    VPState.get("numberOfWrongAttempts")
  );
export const selectNotYetConfigured = () =>
  createSelector(selectAuthenticate, (VPState) =>
    VPState.get("notYetConfigured")
  );
export const selectIsAuthenticated = () =>
  createSelector(selectAuthenticate, (VPState) =>
    VPState.get("isAuthenticated")
  );

export const selectSessionDetails = () =>
  createSelector(selectAuthenticate, (VPState) =>
    VPState.get("sessionDetails")
  );

export const selectErrorMessage = () =>
  createSelector(selectAuthenticate, (VPState) => VPState.get("errorMessage"));

export const selectAboutToLogout = () =>
  createSelector(selectAuthenticate, (VPState) => VPState.get("aboutToLogout"));

export const selectIsSsoLoginfailed = () =>
  createSelector(selectAuthenticate, (VPState) =>
    VPState.get("isSsoLoginFailed")
  );
