import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { usePopper } from "react-popper";

import { useTranslation } from "react-i18next";

import DateRange from "./DateRange/index";
import DaterangePickerStyled, { CollapsedContainer } from "./style";
import { DatepickerIcon } from "components/SVG/index";
import { selectClientDetails } from "container/Administration/ClientManagement/store/selectors";

const DateRangeFilter = (props) => {
  const { t } = useTranslation();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const popperAttr = usePopper(referenceElement, popperElement, {
    placement: "auto",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 16],
        },
      },
    ],
  });
  const { styles, attributes, update } = popperAttr;

  const [showDateRange, setShowDateRange] = useState(false);
  const [dateDiff, setDateDiff] = useState(30);

  const { client } = props.clientDetails || {};
  const { dateRangeMonths } = client || { dateRangeMonths: 5 };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          wrapperRef &&
          !Array.from(event.target.classList)
            .toString()
            .includes("datepicker") &&
          !wrapperRef.current.contains(event.target)
        ) {
          setShowDateRange(false);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const onApplyDateRange = ({ startDate, endDate }) => {
    props.applyDateRangeFilter &&
      props.applyDateRangeFilter({ startDate, endDate });
    let numberOfDays = endDate.diff(startDate, "days");
    setDateDiff(numberOfDays === 0 ? 1 : numberOfDays);
    setShowDateRange(false);
  };
  return (
    <div ref={wrapperRef}>
      <DaterangePickerStyled
        onClick={() => {
          setShowDateRange(!showDateRange);
          update();
        }}
        ref={setReferenceElement}
      >
        <span>
          {t("msp.last")} {dateDiff} {t("msp.days")}
        </span>
        <DatepickerIcon />
      </DaterangePickerStyled>

      <CollapsedContainer
        ref={setPopperElement}
        style={{
          ...styles.popper,
        }}
        {...attributes.popper}
        show={showDateRange}
      >
        <DateRange
          range={dateRangeMonths}
          applyDateRangeFilter={({ startDate, endDate }) =>
            onApplyDateRange({ startDate, endDate })
          }
        />
      </CollapsedContainer>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  clientDetails: selectClientDetails(),
});
const withConnect = connect(mapStateToProps);

export default compose(withConnect)(DateRangeFilter);
