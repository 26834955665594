import React from "react";
import SvgIcon from "./style";

const FilterIcon = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-1254.000000, -289.000000)">
          <g transform="translate(1238.000000, 281.000000)">
            <g transform="translate(16.000000, 8.000000)">
              <path d="M14.4985,-2.00000002e-08 L1.50178123,-2.00000002e-08 C0.16934373,-2.00000002e-08 -0.502937521,1.61659373 0.441124981,2.56065624 L5.50000107,7.6213125 L5.50000107,13 C5.50000107,13.472125 5.72228124,13.9167188 6.1,14.25 L8.1,15.7208125 C9.07728125,16.4037813 10.5000011,15.768875 10.5000011,14.5208125 L10.5000011,7.6213125 L15.5591563,2.56065624 C16.5013125,1.61849998 15.8335938,-2.00000002e-08 14.4985,-2.00000002e-08 Z M8.99999998,6.99999998 L8.99999998,14.5 L6.99999998,13 L6.99999998,6.99999998 L1.49999998,1.49999998 L14.5,1.49999998 L8.99999998,6.99999998 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default FilterIcon;
