import { createSelector } from "reselect";
import { initialState } from "./reducers";

const selectReportDetails = (state) => state.get("reportDetails", initialState);

const selectSavingReportDetails = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("savingReportDetails")
  );
const selectFrquencyDropdownListFetchStatus = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("fetchingFrqeuencyList")
  );
const selectFrquencyDropdownList = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("frequencyDropdownList")
  );

const selectCurrencyDropdownList = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("currencyDropdownList")
  );

const formSubmissionStatus = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("formSubmissionStatus")
  );
const selectReportDetailsListStatus = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("fetchingReportDetailsList")
  );
const selcteReportDetailsList = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("reportDetailsList")
  );

const selectUpdatedReportDetailsList = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("updatedReportDetailsList")
  );

const selectReportDetailsById = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("reportDetailsbyIDList")
  );
const selectReportDetailsByIdListStatus = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("fetchingReportDetailsbyID")
  );
const selectReportConfigDeleting = () =>
  createSelector(selectReportDetails, (VPState) => VPState.get("deleting"));
const selectReportConfigDeleted = () =>
  createSelector(selectReportDetails, (VPState) => VPState.get("deleted"));
const selectFetchingUserList = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("fetchingUserList")
  );
const selectUserList = () =>
  createSelector(selectReportDetails, (VPState) => VPState.get("userList"));
const selectUserListFetchStatus = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("fetchUserList")
  );
const selectAdminRoleDetails = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("adminRole")
  );
const selectSelectedUserId = () =>
  createSelector(selectReportDetails, (VPState) =>
    VPState.get("selectedUserId")
  );

export {
  formSubmissionStatus,
  selectSavingReportDetails,
  selectReportDetailsListStatus,
  selcteReportDetailsList,
  selectFrquencyDropdownListFetchStatus,
  selectFrquencyDropdownList,
  selectCurrencyDropdownList,
  selectReportDetailsById,
  selectReportDetailsByIdListStatus,
  selectReportConfigDeleted,
  selectReportConfigDeleting,
  selectUpdatedReportDetailsList,
  selectFetchingUserList,
  selectUserListFetchStatus,
  selectUserList,
  selectSelectedUserId,
  selectAdminRoleDetails,
};
