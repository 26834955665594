import React from "react";
import SvgIcon from "./style";

const Delete = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 14 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-1151.000000, -367.000000)">
          <g transform="translate(1134.000000, 359.000000)">
            <g transform="translate(16.000000, 8.000000)">
              <path d="M14.5,2.49999938 L11.925,2.49999938 L10.8625,0.728124982 C10.5911515,0.275981732 10.1023171,-0.000470767369 9.575,-6.20236831e-07 L6.425,-6.20236831e-07 C5.89768293,-0.000470767369 5.40884847,0.275981732 5.13749999,0.728124982 L4.07499999,2.49999938 L1.49999998,2.49999938 C1.22385761,2.49999938 0.999999983,2.72385761 0.999999983,2.99999938 L0.999999983,3.49999938 C0.999999983,3.77614236 1.22385761,3.99999938 1.49999998,3.99999938 L1.99999998,3.99999938 L2.66249999,14.59375 C2.71199687,15.3841519 3.36742481,15.9999994 4.15937499,15.9999994 L11.840625,15.9999994 C12.6325752,15.9999994 13.2880031,15.3841519 13.3375,14.59375 L14,3.99999938 L14.5,3.99999938 C14.7761424,3.99999938 15,3.77614236 15,3.49999938 L15,2.99999938 C15,2.72385761 14.7761424,2.49999938 14.5,2.49999938 Z M6.425,1.49999998 L9.575,1.49999998 L10.175,2.49999998 L5.82499999,2.49999998 L6.425,1.49999998 Z M11.840625,14.5 L4.15968749,14.5 L3.50343749,3.99999999 L12.4971875,3.99999999 L11.840625,14.5 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Delete;
