import { createSelector } from "reselect";
import { initialState } from "./reducers";

const selectCommon = (state) => state.get("common", initialState);

export const selectLanguageFetchStatus = () =>
  createSelector(selectCommon, (VPState) => VPState.get("fetchingLanguage"));
export const selectLanguages = () =>
  createSelector(selectCommon, (VPState) => VPState.get("languages"));

export const selectSupplierFieldFetchStatus = () =>
  createSelector(selectCommon, (VPState) =>
    VPState.get("fecthingSupplierField")
  );
export const selectSupplierFields = () =>
  createSelector(selectCommon, (VPState) => VPState.get("supplierFields"));

export const selectCountryStatus = () =>
  createSelector(selectCommon, (VPState) => VPState.get("fetchingCountry"));
export const selectCountries = () =>
  createSelector(selectCommon, (VPState) => VPState.get("countries"));

export const selectSubmissionFieldsStatus = () =>
  createSelector(selectCommon, (VPState) =>
    VPState.get("fetchingSubmissionFields")
  );
export const selectSubmissionFields = () =>
  createSelector(selectCommon, (VPState) => VPState.get("submissionFields"));

export const selectTimeZonesStatus = () =>
  createSelector(selectCommon, (VPState) => VPState.get("fetchingTimeZones"));

export const selectTimeZones = () =>
  createSelector(selectCommon, (VPState) => VPState.get("timeZones"));

export const selectSubmissionStatusFetchStatus = () =>
  createSelector(selectCommon, (VPState) =>
    VPState.get("fetchingSubmissionStatus")
  );
export const selectSubmissionStatus = () =>
  createSelector(selectCommon, (VPState) => VPState.get("submissionStatus"));

export const selectClientPlacementConfigStatus = () =>
  createSelector(selectCommon, (VPState) =>
    VPState.get("clientPlacementConfigStatus")
  );

export const selectClientPlacementConfig = () =>
  createSelector(selectCommon, (VPState) =>
    VPState.get("clientPlacementConfig")
  );

export const selectValidationTypeFetchStatus = () =>
  createSelector(selectCommon, (VPState) =>
    VPState.get("fetchingValidationTypes")
  );
export const selectValidationTypes = () =>
  createSelector(selectCommon, (VPState) => VPState.get("validationTypes"));

export const selectRegexFetchStatus = () =>
  createSelector(selectCommon, (VPState) => VPState.get("fetchingRegex"));
export const selectRegexList = () =>
  createSelector(selectCommon, (VPState) => VPState.get("regexList"));

export const selectCurrencyFetchingStatus = () =>
  createSelector(selectCommon, (VPState) => VPState.get("fetchingCurrency"));
export const selectCurrencyList = () =>
  createSelector(selectCommon, (VPState) => VPState.get("currencyList"));

export const selectDownloading = () =>
  createSelector(selectCommon, (VPState) => VPState.get("downloading"));
export const selectDownloadFailed = () =>
  createSelector(selectCommon, (VPState) => VPState.get("isDownloadFailed"));

export const selectPreviewing = () =>
  createSelector(selectCommon, (VPState) => VPState.get("previewing"));
export const selectPreviewFailed = () =>
  createSelector(selectCommon, (VPState) => VPState.get("isPreviewFailed"));

export const selectSelectedCurrency = () =>
  createSelector(selectCommon, (VPState) => VPState.get("selectedCurrency"));

export const selectSelectedCurrencyObj = () =>
  createSelector(selectCommon, (VPState) => VPState.get("selectedCurrencyObj"));

export const selectFetchingSupplierLookupList = () =>
  createSelector(selectCommon, (VPState) =>
    VPState.get("fetchingSupplierLookupList")
  );

export const selectSupplierTotalCount = () =>
  createSelector(selectCommon, (subState) =>
    subState.get("totalSupplierCount")
  );
export const selectSupplierLookupCols = () =>
  createSelector(selectCommon, (subState) =>
    subState.get("supplierLookupCols")
  );

export const selectActiveColumnsSaving = () =>
  createSelector(selectCommon, (VPState) =>
    VPState.get("isActiveColumnsSaving")
  );
export const selectSupplierLookupList = () =>
  createSelector(selectCommon, (VPState) => VPState.get("supplierLookupList"));

export const selectActiveColumnsFetchStatus = () =>
  createSelector(selectCommon, (VPState) =>
    VPState.get("fetchingActiveColumns")
  );
export const selectActiveColumnsList = () =>
  createSelector(selectCommon, (VPState) => VPState.get("activeColumnsList"));

export const selectIsGlobalSearchActive = () =>
  createSelector(selectCommon, (VPState) =>
    VPState.get("globalSearchActiveStatus")
  );

export const selectInvoiceStatus = () =>
  createSelector(selectCommon, (VPState) => VPState.get("invoiceStatus"));

export const selectInvoiceStatusFetchingStatus = () =>
  createSelector(selectCommon, (VPState) =>
    VPState.get("fetchingInvoiceStatus")
  );

export const selectGlobalSearchResults = () =>
  createSelector(selectCommon, (VPState) => VPState.get("searchResults"));

export const selectGlobalSearchStatus = () =>
  createSelector(selectCommon, (VPState) => VPState.get("searching"));
