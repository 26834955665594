import { put, takeEvery, call, all } from "redux-saga/effects";
import { buildNotification } from "../../../../config/notification";
import { store } from "react-notifications-component";

import {
  updateAnnouncementDetails,
  setDataForEdit,
  deleteSuccess,
  deletFailed,
  formSubmissionStatusAction,
  fetchingCategoryList,
  fetchCategoryList,
  fetchingImportanceList,
  fetchImportanceList,
  getDataForEditFetching,
  editDataFetched,
  saveItemDataCompleted,
} from "./actions";

import {
  GET_ANNOUNCEMENT_DETAILS,
  INIT_NEWSANDEVENT_DELETE,
  SAVE_ANNOUNCEMENT_DATA,
  INIT_CATEGORY_LIST_FETCH,
  INIT_IMPORTANCE_LIST_FETCH,
  INIT_NEWS_AND_EVENTS_EDIT,
} from "./actionTypes";

import axios from "../../../../config/axiosCall";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../../config/requestHeaders";

let languageId = "";
let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
if (sessionDetails) {
  languageId = sessionDetails.languageId;
}

export default function* watchNewsAndEvents() {
  yield all([
    takeEvery(GET_ANNOUNCEMENT_DETAILS, getAnnouncementListSaga),
    takeEvery(INIT_NEWS_AND_EVENTS_EDIT, initEditSaga),
    takeEvery(INIT_NEWSANDEVENT_DELETE, initNewsandEventDelete),
    takeEvery(SAVE_ANNOUNCEMENT_DATA, saveSaga),
    takeEvery(INIT_CATEGORY_LIST_FETCH, getCategoryListSaga),
    takeEvery(INIT_IMPORTANCE_LIST_FETCH, getImportanceListSaga),
  ]);
}

function* getAnnouncementListSaga(action) {
  const { FromDate, ToDate } = action.param || {};
  // const url =
  //   "/Announcement?LangKey=" + languageId + "&Filter=" + action.param.filter;
  let url = `/Announcement?LangKey=${languageId}&Filter=${action.param.filter}&fromDate=${FromDate}&toDate=${ToDate}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);

    yield put(updateAnnouncementDetails(response.data));
  } catch (error) {
    yield put(updateAnnouncementDetails());
  }
}

function* initEditSaga(action) {
  yield put(getDataForEditFetching());
  const url = `/Announcement/detailsById?AnnouncementID=${action.id}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      if (action && action.fetchDataSuccess) {
        action.fetchDataSuccess(response.data);
      }
      yield put(setDataForEdit(response.data));
    }
    yield put(editDataFetched());
  } catch (error) {
    yield put(editDataFetched());
  }
}

function* initNewsandEventDelete(action) {
  const url = `Announcement/delete?AnnouncementIdList=${action.id}&UserId=${action.userId}`;
  const requestDetails = { ...postRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if ((response && response.status === 200) || response.status === 201) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteSuccess(id));
      if (action.callback) {
        action.callback();
      }
      const notification = buildNotification({
        message: "msp.announcementDeleted",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 5000,
        },
      });
    }
  } catch (error) {
    yield put(deletFailed());
  }
}

function* saveSaga(action) {
  const url = "/Announcement/Save";

  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    ...action.data,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      if (action.saveSuccess) {
        action.saveSuccess(true);
      }
      yield put(formSubmissionStatusAction(response.statusText));
      const notification = buildNotification({
        message: "msp.newsAndEventsSaved",
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      yield put(saveItemDataCompleted());
    }
  } catch (error) {
    yield put(saveItemDataCompleted());
  }
}

function* getCategoryListSaga(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langKey =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "";
  yield put(fetchingCategoryList());
  
  const url = `/CustomListItem/listkeys?typeName=__News_Events_Categories&langKey=${langKey}`;
  try {
    const response = yield call(axios, url, {
      method: "GET",
    });
    if (response) {
      yield put(fetchCategoryList(response.data));
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.axiosError",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* getImportanceListSaga(action) {
  let sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  let langKey =
    sessionDetails && sessionDetails.languageId
      ? sessionDetails.languageId
      : "";
  yield put(fetchingImportanceList());
  const url = `/CustomListItem/listkeys?typeName=__News_Events_Importance&langKey=${langKey}`;
  try {
    const response = yield call(axios, url, {
      method: "GET",
    });
    if (response) {
      yield put(fetchImportanceList(response.data));
    }
  } catch (error) {
    const notification = buildNotification({
      message: "msp.axiosError",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}
