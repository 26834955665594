import { fromJS } from "immutable";

import {
  FORM_SUBMISSION_STATUS,
  RESET_FORM_SUBMISSION_STATUS,
  FETCHING_REPORT_DETAILS_LIST,
  FETCH_REPORT_DETAILS_LIST,
  FETCH_REPORT_DETAILS_LIST_COMPLETED,
  FETCHING_FREQUENCY_LIST,
  FETCH_FREQUENCY_LIST,
  FREQUENCY_LIST_FETCH_COMPLETE,
  FETCHING_CURRENCY_LIST,
  FETCH_CURRENCY_LIST,
  CURRENCY_LIST_FETCH_COMPLETE,
  FETCHING_REPORT_DETAILS_BY_ID,
  FETCH_REPORT_DETAILS_BY_ID,
  FETCH_REPORT_DETAILS_BY_ID_COMPLETED,
  DELETE_SUCCESS,
  INIT_DELETE_REPORT_CONFIG,
  DELETE_FAILED,
  INIT_UPDATE_REPORT_STATUS,
  SAVE_UPDATED_REPORT_STATUS,
  UPDATE_REPORT_DETAILS_LIST,
  FETCHING_USER_LIST,
  FETCH_USER_LIST,
  FETCH_USER_LIST_COMPLETED,
  ADMIN_ROLE_DETAILS,
  SAVING_REPORT_DETAILS,
  SAVE_REPORT_DETAILS_COMPLETED,
  SAVE_REPORT_DETAILS_FAILED,
  INIT_REPORT_DETAILS_BY_ID,
} from "./actionTypes";

export const initialState = fromJS({
  formSubmissionStatus: "",
  savingReportDetails: false,
  updatingReportStatus: false,
  deleting: false,
  deleted: [],
  reportListFilterConditions: {
    frequency: "",
    status: "",
  },
  fetchingReportDetailsList: false,
  reportDetailsList: [],
  updatedReportDetailsList: [],
  fetchingFrqeuencyList: false,
  frequencyDropdownList: [],
  fetchingCurrencyList: false,
  currencyDropdownList: [],
  fetchingReportDetailsbyID: false,
  reportDetailsbyIDList: [],
  userList: [],
  fetchUserList: true,
  userRoleList: [],
  isUserRolefetching: false,
  adminRole: false,
  selectedUserId: "",
});

const reportDetailsreducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVING_REPORT_DETAILS: {
      return state.set("savingReportDetails", true);
    }
    case SAVE_REPORT_DETAILS_COMPLETED: {
      return state.set("savingReportDetails", false);
    }
    case SAVE_REPORT_DETAILS_FAILED: {
      return state.set("savingReportDetails", false);
    }
    case INIT_UPDATE_REPORT_STATUS: {
      return state.set("updatingReportStatus", true);
    }
    case SAVE_UPDATED_REPORT_STATUS: {
      return state.set("updatingReportStatus", false);
    }
    case FETCHING_FREQUENCY_LIST: {
      return state.set("fetchingFrqeuencyList", true);
    }
    case FETCH_FREQUENCY_LIST: {
      return state.set("frequencyDropdownList", action.data);
    }
    case FREQUENCY_LIST_FETCH_COMPLETE: {
      return state.set("fetchingFrqeuencyList", false);
    }

    case FETCHING_CURRENCY_LIST: {
      return state.set("fetchingCurrencyList", true);
    }
    case FETCH_CURRENCY_LIST: {
      return state.set("currencyDropdownList", action.data);
    }
    case CURRENCY_LIST_FETCH_COMPLETE: {
      return state.set("fetchingCurrencyList", false);
    }

    case FORM_SUBMISSION_STATUS: {
      return state.set("formSubmissionStatus", action.data);
    }
    case RESET_FORM_SUBMISSION_STATUS: {
      return state.set("formSubmissionStatus", false);
    }
    case FETCHING_REPORT_DETAILS_LIST: {
      return state.set("fetchingReportDetailsList", true);
    }
    case FETCH_REPORT_DETAILS_LIST: {
      return state.set("reportDetailsList", action.data);
    }
    case UPDATE_REPORT_DETAILS_LIST: {
      return state.set("updatedReportDetailsList", action.data);
    }
    case FETCH_REPORT_DETAILS_LIST_COMPLETED: {
      return state.set("fetchingReportDetailsList", false);
    }

    case INIT_REPORT_DETAILS_BY_ID: {
      return state.set("reportDetailsbyIDList", []);
    }

    case FETCHING_REPORT_DETAILS_BY_ID: {
      return state.set("fetchingReportDetailsbyID", true);
    }
    case FETCH_REPORT_DETAILS_BY_ID: {
      return state.set("reportDetailsbyIDList", action.data);
    }
    case FETCH_REPORT_DETAILS_BY_ID_COMPLETED: {
      return state.set("fetchingReportDetailsbyID", false);
    }
    case INIT_DELETE_REPORT_CONFIG: {
      return state.set("deleting", true);
    }
    case DELETE_SUCCESS: {
      return state
        .set("deleting", false)
        .set("deleted", [...state.get("deleted"), ...action.id]);
    }
    case DELETE_FAILED: {
      return state.set("deleting", false);
    }

    case FETCHING_USER_LIST: {
      return state.set("fetchUserList", true);
    }
    case FETCH_USER_LIST: {
      return state.set("userList", action.data);
    }
    case FETCH_USER_LIST_COMPLETED: {
      return state.set("fetchUserList", false);
    }
    case ADMIN_ROLE_DETAILS: {
      return state
        .set("adminRole", action.isRoleAdmin)
        .set("selectedUserId", action.selectedUserId);
    }

    default:
      return state;
  }
};
export default reportDetailsreducer;
