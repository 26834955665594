import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectInvoiceStatus = (state) => state.get("invoiceStatus", initialState);

export const selectInvoiceListFetchStatus = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("fetchingInvoiceList")
  );
export const selectInvoiceColHeaders = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("invoiceColHeaders")
  );
export const selectInvoiceList = () =>
  createSelector(selectInvoiceStatus, (VPState) => VPState.get("invoiceList"));
export const selectRowCount = () =>
  createSelector(selectInvoiceStatus, (VPState) => VPState.get("rowCount"));
export const selectInvoiceLineItems = () =>
  createSelector(selectInvoiceStatus, (VPState) => VPState.get("lineItems"));
export const selectTotalMulticurrencyCount = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("totalMulticurrencyCount")
  );

export const selectInvoiceDetailsFetchStatus = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("fetchingInvoiceDetails")
  );
export const selectExpandedHeaders = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("expandedHeaders")
  );
export const selectInvoiceDetails = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("invoiceDetails")
  );
export const selectInvoiceDetailsHeaderItems = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("invoiceHeaderItems")
  );
export const selectInvoiceDetailsLineItems = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("invoiceLineItems")
  );
export const selectInvoiceDetailsRowCount = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("invoiceRowCount")
  );

export const selectPaymentsListFetchStatus = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("fetchingPaymentsList")
  );
export const selectPaymentColHeaders = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("paymentColHeaders")
  );
export const selectPaymentsList = () =>
  createSelector(selectInvoiceStatus, (VPState) => VPState.get("paymentsList"));
export const selectTicketCount = () =>
  createSelector(selectInvoiceStatus, (VPState) => VPState.get("ticketCount"));

export const selectTotalAmount = () =>
  createSelector(selectInvoiceStatus, (VPState) => VPState.get("totalAmount"));

export const selectPaymentDetailsFetchStatus = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("fetchingPaymentDetails")
  );
export const selectExpandedPaymentHeaders = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("expandedPaymentHeaders")
  );
export const selectPaymentDetails = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("paymentDetails")
  );
export const selectPaymentTicketCount = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("paymentTicketCount")
  );
  export const selectExpandedAdvancePaymentHeaders = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("expandedAdvancePaymentHeaders")
  );
export const selectAdvancePaymentDetails = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("advancePaymentDetails")
  );
export const selectAdvancePaymentTotalCount = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("advancePaymentTotalCount")
  );

export const selectFetchingSupplierList = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("fetchingSupplierList")
  );

export const selectSupplierList = () =>
  createSelector(selectInvoiceStatus, (VPState) => VPState.get("supplierList"));

export const selectActiveColumnsFetchFailed = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("activeColumnsFetchFailed")
  );

export const selectActiveColumnsSaveFailed = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("activeColumnsSaveFailed")
  );

export const selectFetchingSupplierListFailed = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("fetchSupplierListFailed")
  );

export const selectDFMInvoice = () =>
  createSelector(selectInvoiceStatus, (VPState) => VPState.get("dfmInvoice"));
export const selectDFMInvoiceFetchStatus = () =>
  createSelector(selectInvoiceStatus, (VPState) =>
    VPState.get("dfmInvoiceFetchStatus")
  );
