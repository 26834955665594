import styled from "styled-components";

export const Cols = styled.div`
  display: flex;
  gap: 0.5em;
`;
export const Rows = styled(Cols)`
  flex-direction: column;
`;

export const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 0.5em;
`;
