import styled from "styled-components";
import { IconWrapper } from "components";

export const Div = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Shrink = styled(Div)`
  flex-shrink: 0;
`;

export const Col = styled(Div)`
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  display: inline-flex;
  color: var(--light-clr);
  background: var(--dark-clr);
  border-radius: 0.125rem;
  padding: 0.625em 1.125em;
  flex-direction: column;
  flex-grow: 1;
`;

export const TextWrapper = styled.p`
  display: flex;
  flex-direction: row;
  margin: 0.25em 0;
`;

export const AmountValue = styled.span`
  color: var(--light-clr);
  font-size: var(--fs-12);
  font-family: var(--ff-main);
  font-weight: normal;
  letter-spacing: var(--ls-35);
`;

export const AmtText = styled.p`
  small {
    margin-left: 0.5em;
  }
`;

export const CoinIconWrapper = styled(IconWrapper)`
  svg {
    height: 1em;
  }
`;

export const CurrencyCode = styled(AmountValue)``;

export const TotalValue = styled(AmountValue)`
  font-family: var(--ff-medium);
  font-weight: var(--fw-normal);
  margin: 0 0.5em;
`;

export const TotalInvoices = styled(TotalValue)`
  margin: 0;
  margin-left: auto;
`;
