import {
  RECONCILIATION_LIST_REQUEST,
  RECONCILIATION_LIST_FETCH_COMPLETE,
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_FETCH_COMPLETE,
  INIT_DOWNLOAD_TEMPLATE,
  SAVE_RECONCILIATION_REQUEST,
  SAVE_RECONCILIATION_COMPLETE,
  RESET_SAVE_RECONCILIATION_STATUS,
  DOWNLOAD_INPUT_FILE_ACTION,
  START_RECONCILIATION_COMPLETE,
  START_RECONCILIATION_REQUEST,
  GET_RECONCILED_REPORTS_REQUEST,
  RECONCILED_REPORTS_FETCH_COMPLETE,
  GET_RECONCILIATION_COMMENTS_REQUEST,
  RECONCILIATION_COMMENTS_FETCH_COMPLETE,
  SAVE_RECONCILIATION_COMMENTS_REQUEST,
  SAVE_RECONCILIATION_COMMENTS_COMPLETE,
  GET_SUGGESTED_INVOICES_REQUEST,
  SUGGESTED_INVOICES_FETCH_COMPLETE,
  TEMPLATE_UPLOADING,
  SET_UPLOAD_PROGRESSION,
  TEMPLATE_UPLOAD_COMPLETE,
  TEMPLATE_UPLOAD_RESET,
  INIT_UPDATE_RECONCILED_DATA,
} from "./actionTypes";

export function reconciliationListRequest(data) {
  return {
    type: RECONCILIATION_LIST_REQUEST,
    data,
  };
}

export function reconciliationListFetchComplete(reconciliationList) {
  return {
    type: RECONCILIATION_LIST_FETCH_COMPLETE,
    reconciliationList,
  };
}

export function supplierListRequest(payload) {
  return {
    type: SUPPLIER_LIST_REQUEST,
    payload,
  };
}

export function supplierListFetchComplete(supplierData, totalSupplierCount) {
  return {
    type: SUPPLIER_LIST_FETCH_COMPLETE,
    supplierData,
    totalSupplierCount,
  };
}

export const initDownloadTemplate = () => {
  return {
    type: INIT_DOWNLOAD_TEMPLATE,
  };
};

export function saveReconciliationRequest(payload, callback) {
  return {
    type: SAVE_RECONCILIATION_REQUEST,
    payload,
    callback,
  };
}

export function saveReconciliationComplete(status) {
  return {
    type: SAVE_RECONCILIATION_COMPLETE,
    status,
  };
}

export const resetSaveReconciliationStatus = () => {
  return {
    type: RESET_SAVE_RECONCILIATION_STATUS,
  };
};

export function downloadInputFileAction(reconcileId, fileName) {
  return {
    type: DOWNLOAD_INPUT_FILE_ACTION,
    reconcileId,
    fileName,
  };
}
export function startReconciliation(param, data) {
  return {
    type: START_RECONCILIATION_REQUEST,
    param,
    data,
  };
}

export function startReconciliationComplete(status) {
  return {
    type: START_RECONCILIATION_COMPLETE,
    status,
  };
}

export function getReconciledReportsRequest(reconcileId) {
  return {
    type: GET_RECONCILED_REPORTS_REQUEST,
    reconcileId,
  };
}

export function reconciledReportsFetchComplete(reconcileDetails) {
  return {
    type: RECONCILED_REPORTS_FETCH_COMPLETE,
    reconcileDetails,
  };
}

export function getReconciliationComments(reconcileId) {
  return {
    type: GET_RECONCILIATION_COMMENTS_REQUEST,
    reconcileId,
  };
}

export function reconciliationCommentsFetchComplete(reconciliationComments) {
  return {
    type: RECONCILIATION_COMMENTS_FETCH_COMPLETE,
    reconciliationComments,
  };
}
export function saveReconciliationComments(payload) {
  return {
    type: SAVE_RECONCILIATION_COMMENTS_REQUEST,
    payload,
  };
}

export function saveReconciliationCommentsComplete(commentSaveStatus) {
  return {
    type: SAVE_RECONCILIATION_COMMENTS_COMPLETE,
    commentSaveStatus,
  };
}
export function getSuggestedInvoices(param) {
  return {
    type: GET_SUGGESTED_INVOICES_REQUEST,
    param,
  };
}

export function suggestedInvoicesFetchComplete(suggestedInvoiceList) {
  return {
    type: SUGGESTED_INVOICES_FETCH_COMPLETE,
    suggestedInvoiceList,
  };
}
export const uploadingStart = (progression) => {
  return {
    type: TEMPLATE_UPLOADING,
    data: progression,
  };
};

export const setUploadingProgress = (progression) => {
  return {
    type: SET_UPLOAD_PROGRESSION,
    data: progression,
  };
};

export const uploadingFinish = (progression) => {
  return {
    type: TEMPLATE_UPLOAD_COMPLETE,
    data: progression,
  };
};

export const uploadingReset = (progression) => {
  return {
    type: TEMPLATE_UPLOAD_RESET,
    data: progression,
  };
};
export const initUpdateReconciledData = (data, listParam) => {
  return {
    type: INIT_UPDATE_RECONCILED_DATA,
    data,
    listParam,
  };
};
