import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "components";

import { CheckCircleSolid } from "components/SVG/index";
import { Spinner } from "components/index";
import { ChangeLangForm } from "./style";
import { storage } from "config/storage";
import { jsonPrs } from "helper/index";
import {
  ButtonWrapper,
  CloseButton,
  SubmitButton,
  SubModuleTitle,
  SuccessIcon,
} from "../style";

const ChangeLanguage = (props) => {
  const { t } = useTranslation();
  const languages = storage.getItem("languages");
  const languageList = languages ? jsonPrs(languages) : {};
  const normalizedLangList = languageList
    .map((item) => ({
      value: item.label,
      id: item.languageID,
      selected: props.langId === item.languageID,
    }))
    .sort((a, b) => Number(b.selected) - Number(a.selected));

  const isDisabled = props.langId === "" || props.langId === props.prevLangId;

  return (
    <>
      <ChangeLangForm onSubmit={props.handleLanguageChage}>
        <SubModuleTitle>{t("msp.changeLanguage")}</SubModuleTitle>
        <Select
          noClear={true}
          required
          options={normalizedLangList}
          value={props.langId}
          onChangeHandler={props.onSelectionChange}
        />
        <ButtonWrapper>
          <CloseButton type="reset" onClick={props.closeLang}>
            {t("msp.cancel")}
          </CloseButton>
          {props.success ? (
            <SuccessIcon>
              <span>
                {t("msp.updated")} <CheckCircleSolid />
              </span>
            </SuccessIcon>
          ) : (
            <SubmitButton type="submit" disabled={isDisabled}>
              {props.saving ? <Spinner /> : t("msp.save")}
            </SubmitButton>
          )}
        </ButtonWrapper>
      </ChangeLangForm>
    </>
  );
};

ChangeLanguage.propTypes = {
  closeLang: PropTypes.any,
  handleLanguageChage: PropTypes.any,
  langId: PropTypes.string,
  onSelectionChange: PropTypes.any,
  prevLangId: PropTypes.any,
  saving: PropTypes.any,
  success: PropTypes.any,
};

export default ChangeLanguage;
