import PropTypes from "prop-types";
import Overlay from "components/Overlay/index";
import Cross from "components/SVG/Cross";
import React, { useEffect, useRef } from "react";
import { DialogBox, ModalContent, ModalHeader, CloseModal } from "./style";
import { Heading } from "components/index";

const Modal = (props) => {
  const closeModal = (e) => props.onClose && props.onClose(e);

  const useOverlayClickAlerter = (ref) => {
    useEffect(() => {
      if (!props.preventOutterClickClose) {
        const handleClickOutside = (event) => {
          if (ref.current && !ref.current.contains(event.target)) {
            closeModal(event);
          }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    });
  };
  const wrapperRef = useRef(null);
  useOverlayClickAlerter(wrapperRef);

  return props.show ? (
    <Overlay>
      <DialogBox
        ref={wrapperRef}
        centered={props.centered}
        maxWidth={props.maxWidth}
      >
        <ModalContent scrollable={props.scrollable}>
          {props.noHeader ? null : (
            <ModalHeader>
              <Heading>{props.title}</Heading>
              <CloseModal onClick={closeModal}>
                <Cross />
              </CloseModal>
            </ModalHeader>
          )}
          {props.children}
        </ModalContent>
      </DialogBox>
    </Overlay>
  ) : null;
};

Modal.propTypes = {
  centered: PropTypes.any,
  children: PropTypes.any,
  maxWidth: PropTypes.any,
  noHeader: PropTypes.any,
  onClose: PropTypes.func,
  preventOutterClickClose: PropTypes.any,
  scrollable: PropTypes.any,
  show: PropTypes.any,
  title: PropTypes.any,
};

export default Modal;
