import styled from "styled-components";

export const Dimmer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => `#000000${props.opacity}`};
  z-index: 99999;
`;
