import { fromJS } from "immutable";
import {
  FETCHING_INVOICE_LIST,
  FETCH_INVOICE_LIST,
  INVOICE_LIST_FETCH_COMPLETE,
  INIT_FETCH_INVOICE_DETAILS,
  FETCH_INVOICE_DETAILS,
  INVOICE_DETAILS_FETCH_COMPLETE,
  INIT_FETCH_PAYMENT_DETAILS,
  INIT_FETCH_ADVANCE_PAYMENT_DETAILS,
  FETCH_PAYMENT_DETAILS,
  FETCH_ADVANCE_PAYMENT_DETAILS,
  ADVANCE_PAYMENT_DETAILS_FETCH_COMPLETE,
  PAYMENT_DETAILS_FETCH_COMPLETE,
  FETCHING_PAYMENTS_LIST,
  FETCH_PAYMENTS_LIST,
  PAYMENTS_LIST_FETCH_COMPLETE,
  FETCH_SUPPLIER_LIST,
  FETCHING_SUPPLIER_LIST,
  FETCH_SUPPLIER_LIST_COMPLETED,
  FETCH_SUPPLIER_LIST_FAILED,
  SET_INVOICE_DFM,
  DFM_INVOICE_FETCH_STATUS,
  INIT_FETCH_PAYMENTS_LIST,
  INIT_FETCH_INVOICE_LIST,
  INIT_DOWNLOAD_INVOICE_DFM,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingInvoiceList: false,
  invoiceColHeaders: [],
  invoiceList: [],
  lineItems: [],
  rowCount: [],
  totalMulticurrencyCount: [],
  fetchingInvoiceDetails: false,
  expandedHeaders: [],
  invoiceDetails: [],
  invoiceHeaderItems: [],
  invoiceLineItems: [],
  invoiceRowCount: [],
  fetchingPaymentsList: false,
  paymentColHeaders: [],
  paymentsList: [],
  ticketCount: [],
  totalAmount: 0,
  fetchingPaymentDetails: false,
  fetchingAdvancePaymentDetails: false,
  expandedPaymentHeaders: [],
  paymentDetails: [],
  paymentTicketCount: [],

  expandedAdvancePaymentHeaders: [],
  advancePaymentDetails: [],
  advancePaymentTotalCount: [],

  supplierList: [],
  fetchSupplierList: true,

  fetchSupplierListFailed: false,
  dfmInvoice: "",
  dfmInvoiceFetchStatus: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_FETCH_INVOICE_LIST: {
      return state
        .set("invoiceColHeaders", [])
        .set("invoiceList", [])
        .set("lineItems", [])
        .set("rowCount", [])
        .set("totalMulticurrencyCount", []);
    }
    case FETCHING_INVOICE_LIST: {
      return state.set("fetchingInvoiceList", true);
    }
    case FETCH_INVOICE_LIST: {
      return state
        .set("invoiceColHeaders", action.fieldLabels)
        .set("invoiceList", action.invoiceData)
        .set("lineItems", action.lineitems)
        .set("rowCount", action.rowCount)
        .set("totalMulticurrencyCount", action.rowCountForAllCurrencies);
    }
    case INVOICE_LIST_FETCH_COMPLETE: {
      return state.set("fetchingInvoiceList", false);
    }

    case INIT_FETCH_INVOICE_DETAILS: {
      return state
        .set("fetchingInvoiceDetails", true)
        .set("invoiceDetails", [])
        .set("invoiceHeaderItems", [])
        .set("invoiceLineItems", [])
        .set("invoiceRowCount", []);
    }
    case FETCH_INVOICE_DETAILS: {
      return state
        .set("expandedHeaders", action.fieldLabels)
        .set("invoiceDetails", action.invoiceData)
        .set("invoiceHeaderItems", action.headerItems)
        .set("invoiceLineItems", action.lineitems)
        .set("invoiceRowCount", action.rowCount);
    }
    case INVOICE_DETAILS_FETCH_COMPLETE: {
      return state.set("fetchingInvoiceDetails", false);
    }

    case INIT_FETCH_PAYMENTS_LIST: {
      return state
        .set("paymentColHeaders", [])
        .set("paymentsList", [])
        .set("ticketCount", 0)
        .set("totalAmount", 0);
    }

    case FETCHING_PAYMENTS_LIST: {
      return state.set("fetchingPaymentsList", true);
    }
    case FETCH_PAYMENTS_LIST: {
      return state
        .set("paymentColHeaders", action.fieldLabels)
        .set("paymentsList", action.remittanceList)
        .set("ticketCount", action.ticketCount)
        .set("totalAmount", action.totalAmount);
    }
    case PAYMENTS_LIST_FETCH_COMPLETE: {
      return state.set("fetchingPaymentsList", false);
    }

    case INIT_FETCH_PAYMENT_DETAILS: {
      return state.set("fetchingPaymentDetails", true);
    }
    case INIT_FETCH_ADVANCE_PAYMENT_DETAILS: {
      return state.set("fetchingAdvancePaymentDetails", true);
    }
    case FETCH_PAYMENT_DETAILS: {
      return state
        .set("expandedPaymentHeaders", action.fieldLabels)
        .set("paymentDetails", action.remittanceList)
        .set("paymentTicketCount", action.ticketCount);
    }
    case FETCH_ADVANCE_PAYMENT_DETAILS: {
      return state
        .set("expandedAdvancePaymentHeaders", action.fieldLabels)
        .set("advancePaymentDetails", action.advPaymentsList)
        .set("advancePaymentTotalCount", action.totalCount);
    }
    case PAYMENT_DETAILS_FETCH_COMPLETE: {
      return state.set("fetchingPaymentDetails", false);
    }
    case ADVANCE_PAYMENT_DETAILS_FETCH_COMPLETE: {
      return state.set("fetchingAdvancePaymentDetails", false);
    }    
    case FETCHING_SUPPLIER_LIST: {
      return state.set("fetchingSupplierList", true);
    }
    case FETCH_SUPPLIER_LIST: {
      return state.set("supplierList", action.data);
    }
    case FETCH_SUPPLIER_LIST_COMPLETED: {
      return state.set("fetchingSupplierList", false);
    }
    case FETCH_SUPPLIER_LIST_FAILED: {
      return state
        .set("fetchingSupplierList", false)
        .set("fetchSupplierListFailed", action.data);
    }
    case INIT_DOWNLOAD_INVOICE_DFM: {
      return state.set("dfmInvoice", "");
    }
    case SET_INVOICE_DFM: {
      return state.set("dfmInvoice", action.data);
    }
    case DFM_INVOICE_FETCH_STATUS: {
      return state.set("dfmInvoiceFetchStatus", action.bool);
    }
    default:
      return state;
  }
};

export default reducer;
