import { fromJS } from "immutable";
import {
  FETCHING_GET_CLIENT_DETAILS,
  FETCH_GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_FETCH_COMPLETED,
  FETCHING_CLIENTS_LIST,
  FETCH_CLIENTS_LIST,
  CLIENTS_LIST_FETCHING_COMPLETE,
  FETCHING_CONTACT_LIST,
  FETCH_CONTACT_LIST,
  CONTACT_LIST_FETCHING_COMPLETE,
  INIT_DELETE_CONTACT,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DELETE_FAILED,
  INIT_SAVE_CONTACT,
  CONTACT_SAVE_SUCCESS,
  CONTACT_SAVE_FAILED,
  INIT_SAVE_CLIENT_DATA,
  FETCHING_CLIENT_DATA_BY_ID,
  CLIENT_DATA_BY_ID_FETCH_COMPLETE,
  CLIENT_DATA_SAVE_SUCCESS,
  CLIENT_DATA_SAVE_FAILED,
  FETCHING_CONTACT_EMAIL_LIST,
  FETCH_CONTACT_EMAIL_LIST,
  CONTACT_EMAIL_LIST_FETCHING_COMPLETE,
  FETCHING_COUNTRY_CONTACT_LIST,
  FETCH_COUNTRY_CONTACT_LIST,
  COUNTRY_CONTACT_LIST_FETCHING_COMPLETE,
  INIT_SAVE_CLIENT_HELPDESK_DATA,
  CLIENT_HELPDESK_DATA_SAVE_SUCCESS,
  CLIENT_HELPDESK_DATA_SAVE_FAILED,
} from "./actionTypes";

export const initialState = fromJS({
  isClientDetailsFetching: false,
  clientDetails: "",
  fetchingClientsList: "",
  clientsList: [],
  fetchingContactList: false,
  contactList: [],
  savingContact: false,
  deletingContact: false,
  fetchingClientDataById: false,
  savingClientData: false,
  fetchingContactEmailList: false,
  contactEmailList: [],
  fetchingCountryContactList: false,
  countryContactList: [],
  savingClientHelpdesk: false,
});

function loginPageReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_GET_CLIENT_DETAILS:
      return state.set("isClientDetailsFetching", true);
    case FETCH_GET_CLIENT_DETAILS:
      return state.set("clientDetails", action.data);
    case GET_CLIENT_DETAILS_FETCH_COMPLETED:
      return state.set("isClientDetailsFetching", false);
    case FETCHING_CLIENTS_LIST: {
      return state.set("fetchingClientsList", true);
    }

    case FETCH_CLIENTS_LIST: {
      return state.set("clientsList", action.data);
    }

    case CLIENTS_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingClientsList", false);
    }

    case FETCHING_CONTACT_LIST: {
      return state.set("fetchingContactList", true);
    }

    case FETCH_CONTACT_LIST: {
      return state.set("contactList", action.data);
    }

    case CONTACT_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingContactList", false);
    }

    case INIT_SAVE_CONTACT: {
      return state.set("savingContact", true);
    }

    case FETCHING_CLIENT_DATA_BY_ID: {
      return state.set("fetchingClientDataById", true);
    }
    case CLIENT_DATA_BY_ID_FETCH_COMPLETE: {
      return state.set("fetchingClientDataById", false);
    }

    case CONTACT_SAVE_SUCCESS: {
      return state.set("savingContact", false);
    }

    case CONTACT_SAVE_FAILED: {
      return state.set("savingContact", false);
    }

    case INIT_SAVE_CLIENT_DATA: {
      return state.set("savingClientData", true);
    }

    case CLIENT_DATA_SAVE_SUCCESS: {
      return state.set("savingClientData", false);
    }

    case CLIENT_DATA_SAVE_FAILED: {
      return state.set("savingClientData", false);
    }

    case INIT_SAVE_CLIENT_HELPDESK_DATA: {
      return state.set("savingClientHelpdesk", true);
    }

    case CLIENT_HELPDESK_DATA_SAVE_SUCCESS: {
      return state.set("savingClientHelpdesk", false);
    }

    case CLIENT_HELPDESK_DATA_SAVE_FAILED: {
      return state.set("savingClientHelpdesk", false);
    }

    case FETCHING_CONTACT_EMAIL_LIST: {
      return state.set("fetchingContactEmailList", true);
    }

    case FETCH_CONTACT_EMAIL_LIST: {
      return state.set("contactEmailList", action.data);
    }

    case CONTACT_EMAIL_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingContactEmailList", false);
    }

    case FETCHING_COUNTRY_CONTACT_LIST: {
      return state.set("fetchingCountryContactList", true);
    }

    case FETCH_COUNTRY_CONTACT_LIST: {
      return state.set("countryContactList", action.data);
    }

    case COUNTRY_CONTACT_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingCountryContactList", false);
    }

    case INIT_DELETE_CONTACT: {
      return state.set("deletingContact", true);
    }

    case CONTACT_DELETE_SUCCESS: {
      return state.set("deletingContact", false);
    }

    case CONTACT_DELETE_FAILED: {
      return state.set("deletingContact", false);
    }

    default:
      return state;
  }
}

export default loginPageReducer;
