import React from "react";
import { useTranslation } from "react-i18next";
import { additionalInformation, policy, termsOfUseContent } from "./content";
import TermsCondition, { AdditionalInformation, PrimaryContent } from "./style";

const TermsOfUse = (props) => {
  const { t } = useTranslation();
  return (
    <TermsCondition as="section">
      <p>
        <strong>{t("msp.termsHeaderMessage")}</strong>
      </p>
      <PrimaryContent>
        <h1>{t("msp.agreementTitle")}</h1>
        <p>{t("msp.agreementMessage")}</p>
      </PrimaryContent>

      {termsOfUseContent.map((item, i) => {
        return (
          <PrimaryContent key={i}>
            <h1>{t(item.header)}</h1>
            <p>{t(item.content.body)}</p>
          </PrimaryContent>
        );
      })}

      <AdditionalInformation>
        {additionalInformation.map((item, i) => {
          return (
            <li key={i}>
              <p>{t(item.body)}</p>
            </li>
          );
        })}
      </AdditionalInformation>
      {policy.map((item, i) => {
        return (
          <PrimaryContent key={i}>
            <h1>{t(item.header)}</h1>
            <p>{t(item.body)}</p>
          </PrimaryContent>
        );
      })}
    </TermsCondition>
  );
};

export default TermsOfUse;
