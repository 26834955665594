import React from "react";
import SvgIcon from "./style";
const WarningYellow = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 40 35"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-660.000000, -380.000000)">
          <g transform="translate(340.000000, 348.000000)">
            <g transform="translate(320.001838, 32.000000)">
              <path d="M18.4619401,10.9333994 L20.8877881,10.9333994 C21.1201228,10.9333994 21.304624,11.1247339 21.2977906,11.3570686 L20.7852875,24.7504829 C20.7784541,24.9691509 20.593953,25.1468186 20.375285,25.1468186 L18.9744432,25.1468186 C18.7557752,25.1468186 18.5712741,24.9759843 18.5644407,24.7504829 L18.0519376,11.3570686 C18.0451043,11.1247339 18.2296054,10.9333994 18.4619401,10.9333994 Z M19.6782808,26.5134935 C18.6191077,26.5134935 17.7649359,27.3676654 17.7649359,28.4268384 C17.7649359,29.4860115 18.6191077,30.3401833 19.6782808,30.3401833 C20.7374539,30.3401833 21.5916257,29.4860115 21.5916257,28.4268384 C21.5916257,27.3676654 20.7374539,26.5134935 19.6782808,26.5134935 Z M38.9142304,30.0668484 L22.5209646,1.64000991 C21.2636237,-0.54666997 18.0997713,-0.54666997 16.835597,1.64000991 L0.442331226,30.0668484 C-0.815009705,32.2466949 0.756666459,34.9868781 3.28501507,34.9868781 L36.0783799,34.9868781 C38.5930618,34.9868781 40.1784047,32.2535282 38.9142304,30.0668484 Z M36.0783799,32.8001982 L3.2781817,32.8001982 C2.43767662,32.8001982 1.91150677,31.8913594 2.32834262,31.1601883 L18.7284417,2.73334985 C19.1452776,2.00901214 20.2044506,2.00217877 20.6212865,2.73334985 L37.0213856,31.1601883 C37.4450548,31.884526 36.918885,32.8001982 36.0783799,32.8001982 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default WarningYellow;
