import { CustomLabel } from "components/Label/index";
import styled from "styled-components";

export const SelectLabel = styled(CustomLabel)`
  font-family: var(--ff-main);
  max-width: calc(100% - (0.65rem));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  display: block;
`;

export const customStyles = {
  container: (base, state) => ({
    ...base,
    position: "relative",
    boxSizing: "border-box",
    width: "100%",
    marginTop: ".25rem",
  }),
  control: (base, state) => {
    const { showError, isDisabled, visited, value, required } =
      state.selectProps;
    return {
      ...base,
      borderStyle: "solid",
      borderWidth: `var(--oneDotFivePx)`,
      background: isDisabled ? "var(--disabled-clr)" : "",
      height: "2.875em",
      borderRadius: ".5em",
      borderColor: state.isFocused
        ? `var(--primary-clr)`
        : showError || (visited && !value && required)
        ? `var(--error-clr)`
        : `var(--input-border-clr)`,
      boxShadow: state.isFocused
        ? "0 0 0.8rem 0 rgba(102, 187, 255, 0.5);"
        : "none",
    };
  },
  singleValue: (base, state) => ({
    ...base,
    color: state.selectProps.isDisabled
      ? "rgb(181,192,202)"
      : "rgb(21, 24, 27)",
  }),

  input: (base) => ({
    ...base,
  }),
  indicatorSeparator: () => ({
    display: "none",
    backgroundColor: "transparent",
  }),

  menu: (base) => ({
    ...base,
    padding: "1em",
    left: 0,
    zIndex: 3,
  }),
  menuList: (base, state) => {
    const { customStyle } = state.selectProps;
    const { menuList } = customStyle || {};
    return {
      ...base,
      maxHeight: "20rem",
      ...menuList,
    };
  },

  option: (base, state) => ({
    ...base,
    borderRadius: "0.25em",
    backgroundColor: state.isSelected
      ? "#e7eaee"
      : state.isFocused
      ? "var(--dropdown-hover-clr)"
      : `var(--light-clr)`,
    color: "var(--dark-clr)",
  }),
};
