import styled from "styled-components";

export const Div = styled.div``;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  gap: 1em;

  svg {
    height: 2.5em;
    fill: var(--warning-clr);
  }
`;

export const Title = styled.h1`
  color: var(--dark-clr);
  font-size: clamp(var(--fs-18), 3.5vw, var(--fs-24));
  font-family: var(--ff-semibold);
  font-weight: var(--fw-normal);
  text-align: center;
  justify-content: center;
  display: flex;
`;

export const Message = styled.p`
  color: var(--grey-clr);
  font-size: clamp(var(--fs-12), 4vw, var(--fs-16));
  font-family: graphik;
  font-family: var(--ff-main);
  font-weight: var(--fw-normal);
  margin: 1.25em auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 2em;
  align-items: center;
`;

export const WarningText = styled.label`
  color: rgb(21, 24, 27);
  font-size: clamp(var(--fs-12), 2vw, var(--fs-14));
  font-family: var(--ff-medium);
  font-weight: var(--fw-normal);
`;

export const WarningWrapper = styled.div`
  overflow: auto;
  max-height: 30vh;
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;
