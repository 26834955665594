import React from "react";
import SvgIcon from "./style";

const UserTimes = () => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
      <path
        d="M635.2 194.4L589.6 240l45.6 45.6a16.11 16.11 0 0 1 0 22.8l-22.8 22.8a16.11 16.11 0 0 1-22.8 0L544 285.6l-45.6 45.6a16.11 16.11 0 0 1-22.8 0l-22.8-22.8a16.11 16.11 0 0 1 0-22.8l45.6-45.6-45.6-45.6a16.11 16.11 0 0 1 0-22.8l22.8-22.8a16.11 16.11 0 0 1 22.8 0l45.6 45.6 45.6-45.6a16.11 16.11 0 0 1 22.8 0l22.8 22.8a16.11 16.11 0 0 1 0 22.8z"
        style={{ opacity: "0.4" }}
      />
      <path d="M313.6 288h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-41.6A134.43 134.43 0 0 0 313.6 288zM224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128z" />
    </SvgIcon>
  );
};

export default UserTimes;
