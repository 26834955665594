export const INIT_FETCH_INVOICE_LIST = "INIT_FETCH_INVOICE_LIST";
export const FETCHING_INVOICE_LIST = "FETCHING_INVOICE_LIST";
export const FETCH_INVOICE_LIST = "FETCH_INVOICE_LIST";
export const INVOICE_LIST_FETCH_COMPLETE = "INVOICE_LIST_FETCH_COMPLETE";

export const INIT_FETCH_INVOICE_DETAILS = "INIT_FETCH_INVOICE_DETAILS";
export const FETCH_INVOICE_DETAILS = "FETCH_INVOICE_DETAILS";
export const INVOICE_DETAILS_FETCH_COMPLETE = "INVOICE_DETAILS_FETCH_COMPLETE";

export const INIT_FETCH_PAYMENT_DETAILS = "INIT_FETCH_PAYMENT_DETAILS";
export const INIT_FETCH_ADVANCE_PAYMENT_DETAILS = "INIT_FETCH_ADVANCE_PAYMENT_DETAILS";
export const FETCH_PAYMENT_DETAILS = "FETCH_PAYMENT_DETAILS";
export const FETCH_ADVANCE_PAYMENT_DETAILS = "FETCH_ADVANCE_PAYMENT_DETAILS";
export const PAYMENT_DETAILS_FETCH_COMPLETE = "PAYMENT_DETAILS_FETCH_COMPLETE";
export const ADVANCE_PAYMENT_DETAILS_FETCH_COMPLETE = "ADVANCE_PAYMENT_DETAILS_FETCH_COMPLETE";

export const INIT_FETCH_PAYMENTS_LIST = "INIT_FETCH_PAYMENTS_LIST";
export const FETCHING_PAYMENTS_LIST = "FETCHING_PAYMENTS_LIST";
export const FETCH_PAYMENTS_LIST = "FETCH_PAYMENTS_LIST";
export const PAYMENTS_LIST_FETCH_COMPLETE = "PAYMENTS_LIST_FETCH_COMPLETE";

export const INIT_DOWNLOAD_INVOICE_DFM = "INIT_DOWNLOAD_INVOICE_DFM";
export const SET_INVOICE_DFM = "SET_INVOICE_DFM";
export const DFM_INVOICE_FETCH_STATUS = "DFM_INVOICE_FETCH_STATUS";

export const INIT_SUPPLIER_LIST = "INIT_SUPPLIER_LIST";
export const FETCHING_SUPPLIER_LIST = "FETCHING_SUPPLIER_LIST";
export const FETCH_SUPPLIER_LIST = "FETCH_SUPPLIER_LIST";
export const FETCH_SUPPLIER_LIST_COMPLETED = "FETCH_SUPPLIER_LIST_COMPLETED";
export const FETCH_SUPPLIER_LIST_FAILED = "FETCH_SUPPLIER_LIST_FAILED";

