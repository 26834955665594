import React, { Component } from "react";

import { Modal, GridSearch, Button, PrimaryBtn } from "components/index";

import { listSearch } from "helper";
import {
  SearchContainer,
  List,
  CheckboxContainer,
  ListContainer,
  SCheckbox,
  BtnWrapper,
} from "./style";
import { withTranslation } from "react-i18next";
class SubmissionCountriesLookup extends Component {
  state = { search: "", selectedItems: [] };

  componentDidMount() {
    const { selectedCountries } = this.props || [];
    this.setState({
      selectedItems: selectedCountries,
    });
  }

  handleSearch = (e) => {
    const { value } = e.target;
    this.setState({ search: value });
  };

  handleCheckboxChange = (e) => {
    const { id, checked, name } = e.target;
    let selectedIds = [...this.state.selectedItems] || [];
    const index = selectedIds.findIndex(
      (item) => parseInt(item.countryId) === parseInt(id)
    );
    if (checked && index === -1) {
      selectedIds.push({ id: parseInt(id), name });
    } else {
      selectedIds.splice(index, 1);
    }
    this.setState({
      selectedItems: selectedIds,
    });
  };

  render() {
    const { countries, readOnly } = this.props;
    const { selectedItems } = this.state;
    let filteredCountry = countries
      ? countries.filter((item) => {
          return listSearch(item, this.state.search);
        })
      : [];
    const halfLength = filteredCountry && Math.ceil(filteredCountry.length / 2);
    const firstHalf = filteredCountry.slice(0, halfLength);
    const secondHalf = filteredCountry.slice(
      halfLength,
      filteredCountry.length
    );
    return (
      <Modal
        title={this.props.t("msp.invoiceSubmissionCountried")}
        show={this.props.showLookup}
        onClose={this.props.closeLookup}
      >
        <SearchContainer>
          <GridSearch
            onChangeHandler={this.handleSearch}
            value={this.state.search}
          />
        </SearchContainer>
        <ListContainer>
          <List>
            {firstHalf &&
              firstHalf.map((country, index) => {
                const idx =
                  selectedItems &&
                  selectedItems.findIndex(
                    (item) => parseInt(item.id) === parseInt(country.id)
                  );
                return (
                  <CheckboxContainer key={index}>
                    <SCheckbox
                      id={country.id}
                      name={country.label}
                      label={country.label}
                      checked={idx !== -1 ? true : false}
                      handleToggleCheckbox={(e) => this.handleCheckboxChange(e)}
                      disabled={!!readOnly}
                    />
                  </CheckboxContainer>
                );
              })}
          </List>
          <List>
            {secondHalf &&
              secondHalf.map((country, index) => {
                const idx =
                  selectedItems &&
                  selectedItems.findIndex(
                    (item) => parseInt(item.id) === parseInt(country.id)
                  );
                return (
                  <CheckboxContainer key={index}>
                    <SCheckbox
                      id={country.id}
                      name={country.label}
                      label={country.label}
                      checked={idx !== -1 ? true : false}
                      handleToggleCheckbox={(e) => this.handleCheckboxChange(e)}
                      disabled={!!readOnly}
                    />
                  </CheckboxContainer>
                );
              })}
          </List>
        </ListContainer>
        <BtnWrapper>
          <Button sm onClick={this.props.closeLookup}>
            {this.props.t("msp.cancel")}
          </Button>
          {readOnly ? null : (
            <PrimaryBtn
              sm
              onClick={() => this.props.handleSave(selectedItems)}
              disabled={!this.state.selectedItems.length}
            >
              {this.props.t("msp.save")}
            </PrimaryBtn>
          )}
        </BtnWrapper>
      </Modal>
    );
  }
}

export default withTranslation()(SubmissionCountriesLookup);
