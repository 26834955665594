import styled from "styled-components";
import { SecondaryBtn } from "components";
export const FilterButton = styled(SecondaryBtn)`
  span {
    display: flex;
  }
  svg {
    margin-right: 0.5em;
    height: 1em;
  }
`;
export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--light-clr);
  max-width: 22.062em;
  max-height: 87vh;
  overflow: hidden;
  box-shadow: 0 0.125em 2em 0 rgba(0, 0, 0, 0.1);
  margin: 0.5em;
  margin-top: 0;
  border-radius: 0.25em;
  font-size: var(--fs-16);
  justify-content: space-between;
  opacity: ${(props) => (props.show ? "1" : "0")};
  z-index: ${(props) => (props.show ? "10" : "-1")};
  transition: all 0.3s ease-out;
  top: ${(props) => props.top || ""};
  @media (min-width: 36em) {
    min-width: 20em;
  }
`;

export const FilterHeader = styled.div`
  display: flex;
  padding: 1em 1.5em;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: flex-start;
  svg {
    width: 1em;
  }
`;

export const HeaderText = styled.h1`
  color: var(--dark-clr);
  font-size: var(--fs-18);
  font-family: var(--ff-semibold);
  font-weight: var(--fw-normal);
`;

export const FilterBody = styled.div`
  background-color: var(--light-grey-clr);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 1em 1.5em;
`;

export const FilterFooter = styled.div`
  display: flex;
  padding: 1em 1.5em;
  flex-shrink: 0;
  justify-content: space-between;
`;
