import React from "react";
import SvgIcon from "./style";

const AssignedBadge = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-382.000000, -535.000000)" fillRule="nonzero">
          <g transform="translate(382.000000, 535.000000)">
            <g transform="translate(0.015564, -0.000000)">
              <path
                d="M15.9688716,8.00000002 C15.9688716,6.85811231 15.260617,5.83597777 14.1914397,5.43501947 C14.6630511,4.39577751 14.4409359,3.1730289 13.6339535,2.36604651 C12.8269711,1.55906413 11.6042225,1.33694895 10.5649806,1.80856032 C10.1641164,0.739270003 9.14196055,0.0308412552 8.00000002,0.0308412552 C6.85803949,0.0308412552 5.83588363,0.739270003 5.43501947,1.80856032 C4.39577751,1.33694895 3.1730289,1.55906413 2.36604651,2.36604651 C1.55906413,3.1730289 1.33694895,4.39577751 1.80856032,5.43501947 C0.739270003,5.83588363 0.0308412552,6.85803949 0.0308412552,8.00000002 C0.0308412552,9.14196055 0.739270003,10.1641164 1.80856032,10.5649806 C1.33694895,11.6042225 1.55906413,12.8269711 2.36604651,13.6339535 C3.1730289,14.4409359 4.39577751,14.6630511 5.43501947,14.1914397 C5.83588363,15.26073 6.85803949,15.9691588 8.00000002,15.9691588 C9.14196055,15.9691588 10.1641164,15.26073 10.5649806,14.1914397 C11.6042225,14.6630511 12.8269711,14.4409359 13.6339535,13.6339535 C14.4409359,12.8269711 14.6630511,11.6042225 14.1914397,10.5649806 C15.260617,10.1640223 15.9688716,9.14188773 15.9688716,8.00000002 L15.9688716,8.00000002 Z M11.4614786,6.62256811 L7.38365761,10.6692607 C7.24944499,10.8023349 7.03279261,10.801499 6.89961091,10.667393 L4.5425681,8.29136189 C4.47856555,8.22689506 4.44280349,8.13963744 4.44315123,8.04879595 C4.44350407,7.95795447 4.47993799,7.87097523 4.54443581,7.80700391 L5.35408562,7.00389107 C5.41855244,6.93988852 5.50581007,6.90412646 5.59665155,6.90447674 C5.68749304,6.90482703 5.77447227,6.94126096 5.83844359,7.00575877 L7.15050585,8.32840469 L10.1761868,5.3270039 C10.3103994,5.1939297 10.5270518,5.19476566 10.6602335,5.32887161 L11.4639689,6.13821013 C11.5278894,6.20275929 11.5635398,6.29006341 11.5630773,6.38090505 C11.5626057,6.4717467 11.5260595,6.55867963 11.4614786,6.62256811 L11.4614786,6.62256811 Z"
                opacity="0.4"
              ></path>
              <path d="M11.4614786,6.62256811 L7.38365761,10.6692607 C7.24944499,10.8023349 7.03279261,10.801499 6.89961091,10.667393 L4.5425681,8.29136189 C4.47856555,8.22689506 4.44280349,8.13963744 4.44315123,8.04879595 C4.44350407,7.95795447 4.47993799,7.87097523 4.54443581,7.80700391 L5.35408562,7.00389107 C5.41855244,6.93988852 5.50581007,6.90412646 5.59665155,6.90447674 C5.68749304,6.90482703 5.77447227,6.94126096 5.83844359,7.00575877 L7.15050585,8.32840469 L10.1761868,5.3270039 C10.3103994,5.1939297 10.5270518,5.19476566 10.6602335,5.32887161 L11.4639689,6.13821013 C11.5279715,6.20267696 11.5637335,6.28993458 11.5633858,6.38077607 C11.5630329,6.47161755 11.526599,6.55859679 11.4621012,6.62256811 L11.4614786,6.62256811 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default AssignedBadge;
