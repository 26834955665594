import React, { useEffect, useState } from "react";

import { InputGroup } from "components/Inputs/LabeledInput/style";

import InputControl from "components/Inputs/Input/style";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InputAddon from "components/InputAddon/index";
import { SearchIcon } from "components/SVG";
import GridSearchStyled from "./style";
import { Tooltip } from "components";
import { TooltipText, TooltipWrapper } from "../Tooltip/style";

const GridSearch = (props) => {
  const [visited, setVisited] = useState(false);
  const { t } = useTranslation();

  let timeout;
  const [active, setActive] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };
  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  useEffect(() => {
    props.resetVisited && setVisited(false);
  }, [props.resetVisited]);

  const onInputChange = (e) => {
    let value = e.target.value;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;

    props.onChangeHandler && props.onChangeHandler(e);
  };
  const handleOnBlur = (e) => {
    setVisited(true);
    let value = e.target.value;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;

    props.onBlurHandler && props.onBlurHandler(e);
  };
  const passedProps = { ...props };
  delete passedProps.onBlurHandler;
  delete passedProps.onChangeHandler;
  delete passedProps.showError;
  return (
    <GridSearchStyled
      disabled={props.disabled}
      visited={visited || props.value}
      ref={setReferenceElement}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      <InputGroup>
        <InputAddon tabIndex="-1" disabled={props.disabled} aria-label="search-icon">
          <SearchIcon />
        </InputAddon>

        <InputControl
          {...passedProps}
          placeholder={t(props.placeholder) || `${t("msp.search")}...`}
          visited={visited || props.value}
          aria-label={t("msp.searchInList")}
          onBlur={handleOnBlur}
          onChange={onInputChange}
        />
      </InputGroup>
      {active && props.hint && (
        <Tooltip
          triggerElem={referenceElement}
          placement={props.placement}
          hw={props.hw}
        >
          <TooltipWrapper>
            <TooltipText>{props.hint}</TooltipText>
          </TooltipWrapper>
        </Tooltip>
      )}
    </GridSearchStyled>
  );
};

GridSearch.propTypes = {
  disabled: PropTypes.any,
  onBlurHandler: PropTypes.func,
  onChangeHandler: PropTypes.func,
  resetVisited: PropTypes.func,
  showError: PropTypes.any,
  value: PropTypes.any,
};

export default GridSearch;
