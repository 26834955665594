import {
  GET_NOTIFICATION_DATA,
  FETCH_NOTIFICATION_DATA,
  FETCHING_NOTIFICATION_DATA,
  FETCHING_NOTIFICATION_COMPLETED,
  GET_NOTIFICATION_PREFERENCES,
  FETCHING_NOTIFICATION_PREFERENCES,
  FETCH_NOTIFICATION_PREFERENCES_COMPLETED,
  FETCH_NOTIFICATION_PREFERENCES,
  INIT_SUBMIT_NOTIFICATION_PREFERENCES,
  INIT_UPDATE_NOTIFICATION,
  INIT_GET_ARCHIVED_ANNOUNCEMENT,
  FETCH_ARCHIVE_DATA,
  FETCHING_ARCHIVE_DATA,
  FETCH_ARCHIVE_DATA_COMPLETED,
} from "./actionTypes";

export const initGetNotification = () => {
  return {
    type: GET_NOTIFICATION_DATA,
  };
};
export const fetchingNotificationList = () => {
  return {
    type: FETCHING_NOTIFICATION_DATA,
  };
};
export const fetchNotificationCompleted = () => {
  return {
    type: FETCHING_NOTIFICATION_COMPLETED,
  };
};
export const fetchNotificationData = (data) => {
  return {
    type: FETCH_NOTIFICATION_DATA,
    data: data,
  };
};
export const initGetNotificationPreferences = (userId) => {
  return {
    type: GET_NOTIFICATION_PREFERENCES,
    userId,
  };
};
export const fetchingNotificationPreferences = () => {
  return {
    type: FETCHING_NOTIFICATION_PREFERENCES,
  };
};
export const fetchNotificationPreferencesCompleted = () => {
  return {
    type: FETCH_NOTIFICATION_PREFERENCES_COMPLETED,
  };
};
export const fetchNotificationPreferences = (data) => {
  return {
    type: FETCH_NOTIFICATION_PREFERENCES,
    data,
  };
};
export const initSubmitNotificationPreferences = (data) => {
  return {
    type: INIT_SUBMIT_NOTIFICATION_PREFERENCES,
    data,
  };
};
export const initUpdateNotification = (data, callback) => {
  return {
    type: INIT_UPDATE_NOTIFICATION,
    data,
    callback,
  };
};
export const initGetArchivedAnnouncements = () => {
  return {
    type: INIT_GET_ARCHIVED_ANNOUNCEMENT,
  };
};
export const fetchArchiveData = (data) => {
  return {
    type: FETCH_ARCHIVE_DATA,
    data,
  };
};

export const fetchingArchivedNotification = () => {
  return {
    type: FETCHING_ARCHIVE_DATA,
  };
};

export const fetchArchivedNotificationCompleted = () => {
  return {
    type: FETCH_ARCHIVE_DATA_COMPLETED,
  };
};
