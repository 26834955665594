import { fromJS } from "immutable";

import {
  CLEAN_REVIEW_INVOICE_DETAILS,
  FETCHING_OCR_DETAILS,
  FETCHING_PO_DETAILS,
  OCR_DETAILS_FAILED,
  OCR_DETAILS_FETCHED,
  PO_DETAILS_FAILED,
  PO_DETAILS_FETCHED,
} from "./actionTypes";

export const initialState = fromJS({
  docExtractedData: {},
  ocrDetails: {},
  fetchingOCRDetails: false,
  PODetails: {},
  fetchingPODetails: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_OCR_DETAILS:
      return state.set("fetchingOCRDetails", true);
    case OCR_DETAILS_FETCHED:
      const { docExtractedData, ocrDetails } = action.data;
      return state
        .set("fetchingOCRDetails", false)
        .set("docExtractedData", fromJS(docExtractedData))
        .set("ocrDetails", fromJS(ocrDetails));
    case OCR_DETAILS_FAILED:
      return state.set("fetchingOCRDetails", false);

    case FETCHING_PO_DETAILS:
      return state.set("fetchingPODetails", true);
    case PO_DETAILS_FETCHED:
      return state
        .set("PODetails", fromJS(action.data))
        .set("fetchingPODetails", false);
    case PO_DETAILS_FAILED:
      return state.set("fetchingPODetails", false);

    case CLEAN_REVIEW_INVOICE_DETAILS:
      return state
        .set("docExtractedData", fromJS({}))
        .set("ocrDetails", fromJS({}))
        .set("PODetails", fromJS({}));

    default:
      return state;
  }
};
export default reducer;
