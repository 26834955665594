import Switch from "components/Switch/index";
import React from "react";
import { useTranslation } from "react-i18next";
import PreferencesStyled, { PreferencesList, Preference } from "./style";
const Preferences = (props) => {
  const { t } = useTranslation();
  return (
    <PreferencesStyled show={props.show}>
      <h1>{t("msp.notificationPreferences")}</h1>
      <PreferencesList>
        {props.data
          ? props.data.map((item) => (
              <Preference key={item.statusID}>
                <span>
                  <strong>{item.label}</strong>
                </span>
                <Switch
                  id={`header#${item.statusID}`}
                  name={item.statusID}
                  checked={item.checked}
                  onChangeHandler={props.handlePreferenceToggle}
                />
              </Preference>
            ))
          : null}
      </PreferencesList>
    </PreferencesStyled>
  );
};

export default Preferences;
