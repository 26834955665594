import styled from "styled-components";

export const CloseWrapper = styled.div``;
export const Header = styled.section``;
export const Content = styled.section``;
export const Footer = styled.section``;

export const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1em 2em;
  gap: 0.5em;
  ${CloseWrapper} {
    align-self: flex-end;
  }
`;

export const ContentWrapper = styled.div`
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0.125em 2em 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
