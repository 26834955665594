export const INIT_ADVANCE_PAYMENTS_FETCH = "INIT_ADVANCE_PAYMENTS_FETCH";
export const FETCHING_ADVANCE_PAYMENTS = "FETCHING_ADVANCE_PAYMENTS";
export const FETCH_ADVANCE_PAYMENTS = "FETCH_ADVANCE_PAYMENTS";
export const ADVANCE_PAYMENTS_FETCH_COMPLETE =
  "ADVANCE_PAYMENTS_FETCH_COMPLETE";

export const INIT_ADV_PAYMENT_ACTIVE_COLS = "INIT_ADV_PAYMENT_ACTIVE_COLS";
export const FETCH_ADV_PAYMENT_ACTIVE_COLS = "FETCH_ADV_PAYMENT_ACTIVE_COLS";

export const SET_GRID_CONFIGS = "SET_GRID_CONFIGS";

export const SET_FILTERS = "SET_FILTERS";

export const SAVE_LOOKUP = "SAVE_LOOKUP";
export const CLEAR_LOOKUP = "CLEAR_LOOKUP";
export const SELECTED_SUPPLIERS = "SELECTED_SUPPLIERS";

export const APPLY_FILTER = "APPLY_FILTER";
export const INIT_APPLY_FILTER = "INIT_APPLY_FILTER";

export const INIT_FETCH_LINKED_INVOICES = "INIT_FETCH_LINKED_INVOICES";
