import styled from "styled-components";

import { Container } from "components/index";

export const VerificationContainer = styled(Container)`
  text-align: center;
  margin: 0 auto;
  width: 100vw;
  max-width: 96%;
`;

export const VerificationTitle = styled.div`
  h2 {
    color: rgb(21, 24, 27);
    font-size: var(--fs-24);
    font-family: var(--ff-semibold);
    font-weight: var(--fw-bold);
    text-align: center;
    letter-spacing: -0.035rem;
    line-height: 1.16;
  }
`;

export const SVGContainer = styled.div`
  background: var(--primary-gradient);
  width: 5.5em;
  height: 5.5em;
  box-shadow: 0 0.2rem 1.6rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  margin: 0 auto 0.5em auto;
  svg {
    width: 4em;
    height: 4em;
    margin: 0 auto;
  }
`;

export const VerificationDetails = styled.section`
  text-align: center;
  margin-top: 1em;
  img {
    width: 2.375em;
    height: 2.5em;
    margin: 0 auto;
  }
  @media (min-width: 36em) {
    background: rgb(255, 255, 255);
    border: var(--border-light-1);
    box-shadow: rgb(0 0 0 / 10%) 0 0.125em 2em 0;
    border-radius: 1em;
    max-width: 37.5em;
    padding: 0.5em 2.5em 1.5em;
    margin: 0 auto;
    margin-top: 1em;
  }
`;

export const Label = styled.p`
  color: rgb(33, 43, 54);
  font-size: var(--fs-18);
  font-family: var(--ff-medium);
  font-weight: var(--fw-normal);
  letter-spacing: -0.035rem;
  line-height: 1.5;
`;

export const Desc = styled.p`
  color: rgb(99, 115, 129);
  font-size: var(--fs-16);
  font-family: var(--ff-main);
  font-weight: var(--fw-normal);
  letter-spacing: -0.035rem;
  line-height: 1.375;
`;

export const Email = styled(Label)`
  font-size: var(--fs-16);
  line-height: 1.375;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 2.5em 0;
  input {
    width: 1.5em;
    height: 2em;
    background: rgb(249, 250, 251);
    border: 0.0625rem solid rgb(33, 43, 54);
    border-radius: 0.4rem;
    color: rgb(33, 43, 54);
    font-size: var(--fs-28);
    font-family: var(--ff-semibold);
    font-weight: var(--fw-bold);
    text-align: center;
    letter-spacing: -0.035rem;
    line-height: 2;
    :hover, :focus {
        box-shadow: rgb(102 187 255 / 50%) 0.25em 0 0.8rem;
        outline: none;
    }
  }
  @media (min-width: 25em) {
    gap: 1em !important;
  }
  @media (min-width: 36em) {
    gap: 1.6em !important;
  }
  @media (min-width: 48em) {
    input {
      width: 2em !important;
      height: 3.25em !important;
    }
  }
`;
