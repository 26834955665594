import styled from "styled-components";

const Container = styled.div`
  max-width: 82.5rem;
  width: 100%;
  margin: 0 auto;
  @media (min-width: 36em) {
    max-width: 33.75rem;
  }
  @media (min-width: 48em) {
    max-width: 45rem;
  }
  @media (min-width: 62em) {
    max-width: 60rem;
  }
  @media (min-width: 75em) {
    max-width: 71rem;
  }
  @media (min-width: 87.5em) {
    max-width: 82rem;
  }
`;

export default Container;
