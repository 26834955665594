import React from "react";
import SvgIcon from "./style";

const TrashCan = () => {
  return (
    <SvgIcon
      viewBox="0 0 18 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-853.000000, -328.000000)">
          <g transform="translate(472.000000, 200.000000)">
            <g transform="translate(380.000000, 128.000000)">
              <g>
                <path d="M18.4375,2.49999997 L14.375,2.49999997 L13.0625,0.749999977 C12.708398,0.277864021 12.1526699,-2.50000003e-08 11.5625,-2.50000003e-08 L8.4375,-2.50000003e-08 C7.84733005,-2.50000003e-08 7.29160196,0.277864021 6.93749999,0.749999977 L5.62499999,2.49999997 L1.56249998,2.49999997 C1.38991099,2.49999997 1.24999998,2.639911 1.24999998,2.81249998 L1.24999998,3.43749998 C1.24999998,3.61008897 1.38991099,3.74999998 1.56249998,3.74999998 L2.30078123,3.74999998 L3.59765623,18.2929688 C3.6845824,19.2594061 4.49450504,20 5.46484374,20 L14.5351563,20 C15.505495,20 16.3154176,19.2594061 16.4023438,18.2929688 L17.6992188,3.74999998 L18.4375,3.74999998 C18.610089,3.74999998 18.75,3.61008897 18.75,3.43749998 L18.75,2.81249998 C18.75,2.639911 18.610089,2.49999997 18.4375,2.49999997 Z M7.93749999,1.49999998 C8.0559353,1.34310295 8.24092119,1.25061 8.4375,1.24999998 L11.5625,1.24999998 C11.7590788,1.25061 11.9440647,1.34310295 12.0625,1.49999998 L12.8125,2.49999998 L7.18749999,2.49999998 L7.93749999,1.49999998 Z M15.15625,18.1796875 C15.1295689,18.5026452 14.8592129,18.7508966 14.5351563,18.75 L5.46484374,18.75 C5.14078705,18.7508966 4.87043108,18.5026452 4.84374999,18.1796875 L3.55468748,3.74999998 L16.4453125,3.74999998 L15.15625,18.1796875 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default TrashCan;
