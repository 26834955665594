import React from "react";
import SvgIcon from "./style";

const RedirectPopout = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        style={{ opacity: 0.4 }}
        d="M10.9,8.8h0.9c0.2,0,0.4,0.2,0.4,0.4v3.5c0,0.7-0.6,1.3-1.3,1.3H1.3C0.6,14,0,13.4,0,12.7V3.1
	c0-0.7,0.6-1.3,1.3-1.3h4.4c0.2,0,0.4,0.2,0.4,0.4v0.9c0,0.2-0.2,0.4-0.4,0.4H1.8v8.8h8.8V9.2C10.5,8.9,10.7,8.8,10.9,8.8z"
      />
      <path
        d="M13.2,6.1h-0.5c-0.4,0-0.8-0.3-0.8-0.8v0L12,3.5l-6.8,6.8c-0.3,0.3-0.7,0.3-0.9,0l0,0L3.7,9.7
	C3.4,9.4,3.4,9,3.7,8.7l0,0L10.5,2L8.7,2C8.5,2,8.3,2,8.1,1.8C8,1.7,7.9,1.5,7.9,1.3V0.8C7.9,0.3,8.2,0,8.6,0H13c0.6,0,1,0.5,1,1
	v4.3C14,5.8,13.7,6.1,13.2,6.1L13.2,6.1z"
      />
    </SvgIcon>
  );
};

export default RedirectPopout;
