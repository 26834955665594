import {
  INIT_USER_MANAGEMENT_LIST,
  USER_LIST_FETCH_STATUS,
  USER_LIST,
  INIT_SAVE_USER_BY_ID_DATA,
  INIT_USER_BY_ID_DATA,
  USER_BY_ID_DATA,
  USER_BY_ID_DATA_FETCH_STATUS,
  INIT_DELETE_USER,
  INIT_DELETE_USER_STATUS,
  INIT_USER_ROLE_LIST,
  FETCH_USER_ROLE_LIST,
  FETCH_USER_ROLE_LIST_STATUS,
  INIT_REGIONAL_SETTINGS,
  FETCH_REGIONAL_SETTINGS,
  INIT_CHECK_FOR_EXISTING_USER,
  USER_EXIST,
  INIT_UPDATE_FAILED_PASSWORD_COUNT,
} from "./actionTypes";

export const initUserManagementList = (data) => {
  return {
    type: INIT_USER_MANAGEMENT_LIST,
    data,
  };
};

export const setUserList = (userList) => {
  return {
    type: USER_LIST,
    userList,
  };
};

export const userListFetchStatus = (boolean) => {
  return {
    type: USER_LIST_FETCH_STATUS,
    boolean,
  };
};

export const saveUserByIdData = (data, callback) => {
  return {
    type: INIT_SAVE_USER_BY_ID_DATA,
    data,
    callback,
  };
};

export const initUserByIdData = (userId, callBack) => {
  return {
    type: INIT_USER_BY_ID_DATA,
    userId,
    callBack,
  };
};

export const setUserByIdData = (data) => {
  return {
    type: USER_BY_ID_DATA,
    data,
  };
};

export const fetchUserByIdDataStatus = (boolean) => {
  return {
    type: USER_BY_ID_DATA_FETCH_STATUS,
    boolean,
  };
};

export const initDeleteUser = (data, callback) => {
  return {
    type: INIT_DELETE_USER,
    data,
    callback,
  };
};

export const deleteUserStatus = (boolean) => {
  return {
    type: INIT_DELETE_USER_STATUS,
    boolean,
  };
};

export const initUserRole = () => {
  return {
    type: INIT_USER_ROLE_LIST,
  };
};

export const fetchUserRoleDropdownList = (userRoleList) => {
  return {
    type: FETCH_USER_ROLE_LIST,
    userRoleList,
  };
};

export const fetchUserRoleListStatus = (boolean) => {
  return {
    type: FETCH_USER_ROLE_LIST_STATUS,
    boolean,
  };
};

export const initRegionalSettings = () => {
  return {
    type: INIT_REGIONAL_SETTINGS,
  };
};

export const fetchRegionalSettings = (data) => {
  return {
    type: FETCH_REGIONAL_SETTINGS,
    data,
  };
};

export const initCheckExistingUser = (data, callback) => {
  return {
    type: INIT_CHECK_FOR_EXISTING_USER,
    data,
    callback,
  };
};

export const userExist = (data) => {
  return {
    type: USER_EXIST,
    data,
  };
};

export const updateFailedPasswordCount = (userId, callback) => {
  return {
    type: INIT_UPDATE_FAILED_PASSWORD_COUNT,
    userId,
    callback,
  };
};
