import { fromJS } from "immutable";

import {
  FETCHING_REJECTED_INVOICES,
  FETCHING_REJECTED_INVOICES_FAILED,
  FETCH_NOTIFICATION_DATA,
  FETCH_REJECTED_INVOICES,
  FETCH_REJECTED_INVOICES_COMPLETED,
  GET_FORCAST_INVOICES_COMPLETED,
  GET_FORCAST_INVOICES_FETCH_FAILED,
  GET_INVOICE_HISTORY_BY_ID_COMPLETED,
  GET_INVOICE_HISTORY_BY_ID_FETCH_FAILED,
  GET_LANDING_PAGE_DATA_COMPLETED,
  GET_LANDING_PAGE_DATA_FAILED,
  GET_OVERDUE_INVOICES_COMPLETED,
  GET_OVERDUE_INVOICES_FETCH_FAILED,
  INIT_GET_FORCAST_INVOICES,
  INIT_GET_INVOICE_HISTORY_BY_ID,
  INIT_GET_LANDING_PAGE_DATA,
  INIT_GET_OVERDUE_INVOICES,
  IS_HEADER_IMG_CLICKED,
  SET_SELECTED_DAY_INDEX,
  // REJECTED_INVOICES_RESOLVED,
  // REJECTED_INVOICES_RESOLVING,
  // REJECTED_INVOICES_RESOLVING_FAILED,
} from "./actionTypes";

export const initialState = fromJS({
  notificationData: [],
  fetchingLandingPageData: false,
  landingPageData: [],
  fetchingOverduePaymentsData: false,
  overduePaymentsData: [],
  fetchingForcastPaymentsData: false,
  forcastPaymentsData: [],
  fetchingInvoiceHistoryById: false,
  invoiceHistoryByIdData: [],
  isRejectedInvoiceResolving: false,
  isRejectedInvoiceResolvingFailed: false,
  overdueInvoicesFetchFailed: false,
  forcastInvoicesFetchFailed: false,
  invoiceHistoryByIdDataFetchFailed: false,
  getLandingPageDataFailed: false,
  isRejectedInvoicesFetching: false,
  rejectedInvoices: [],
  isRejectedInvoicesFetchingFailed: false,
  isHeaderImgClicked: false,
  selectedDayIndex: 1,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_DATA: {
      return state.set("notificationData", action.data);
    }
    case FETCHING_REJECTED_INVOICES: {
      return state.set("isRejectedInvoicesFetching", true);
    }
    case FETCH_REJECTED_INVOICES: {
      return state
        .set("isRejectedInvoicesFetching", false)
        .set("rejectedInvoices", action.data);
    }
    case FETCH_REJECTED_INVOICES_COMPLETED: {
      return state.set("isRejectedInvoicesFetching", false);
    }
    case FETCHING_REJECTED_INVOICES_FAILED: {
      return state
        .set("isRejectedInvoicesFetching", false)
        .set("isRejectedInvoicesFetchingFailed", action.data);
    }
    case INIT_GET_LANDING_PAGE_DATA: {
      return state.set("fetchingLandingPageData", true);
    }
    case GET_LANDING_PAGE_DATA_COMPLETED: {
      return state
        .set("fetchingLandingPageData", false)
        .set("landingPageData", action.data);
    }
    case GET_LANDING_PAGE_DATA_FAILED: {
      return state
        .set("fetchingLandingPageData", false)
        .set("getLandingPageDataFailed", action.data);
    }
    case INIT_GET_OVERDUE_INVOICES: {
      return state.set("fetchingOverduePaymentsData", true);
    }
    case GET_OVERDUE_INVOICES_COMPLETED: {
      return state
        .set("fetchingOverduePaymentsData", false)
        .set("overduePaymentsData", action.data);
    }
    case GET_OVERDUE_INVOICES_FETCH_FAILED: {
      return state
        .set("fetchingOverduePaymentsData", false)
        .set("overdueInvoicesFetchFailed", action.data);
    }
    case INIT_GET_FORCAST_INVOICES: {
      return state.set("fetchingForcastPaymentsData", true);
    }
    case GET_FORCAST_INVOICES_COMPLETED: {
      return state
        .set("fetchingForcastPaymentsData", false)
        .set("forcastPaymentsData", action.data);
    }
    case GET_FORCAST_INVOICES_FETCH_FAILED: {
      return state
        .set("fetchingForcastPaymentsData", false)
        .set("forcastInvoicesFetchFailed", action.data);
    }
    case INIT_GET_INVOICE_HISTORY_BY_ID: {
      return state.set("fetchingInvoiceHistoryById", true);
    }
    case GET_INVOICE_HISTORY_BY_ID_COMPLETED: {
      return state
        .set("fetchingInvoiceHistoryById", false)
        .set("invoiceHistoryByIdData", action.data);
    }
    case GET_INVOICE_HISTORY_BY_ID_FETCH_FAILED: {
      return state
        .set("fetchingInvoiceHistoryById", false)
        .set("invoiceHistoryByIdDataFetchFailed", action.data);
    }
    // case REJECTED_INVOICES_RESOLVING: {
    //   return state.set("isRejectedInvoiceResolving", true);
    // }
    // case REJECTED_INVOICES_RESOLVED: {
    //   return state.set("isRejectedInvoiceResolving", false);
    // }
    // case REJECTED_INVOICES_RESOLVING_FAILED: {
    //   return state
    //     .set("isRejectedInvoiceResolving", false)
    //     .set("isRejectedInvoiceResolvingFailed", action.data);
    // }
    case IS_HEADER_IMG_CLICKED: {
      return state.set("isHeaderImgClicked", action.data);
    }
    case SET_SELECTED_DAY_INDEX:  {
      return state.set('selectedDayIndex', action.payload);
    }
    
    default:
      return state;
  }
};
export default reducer;
