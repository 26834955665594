import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Modal, PrimaryBtn } from "components/";
import { WarningYellow } from "components/SVG/index";
import { ButtonWrapper, ContentWrapper, Message, Title } from "./style";

const GDPRMessage = (props) => {
  const { t } = useTranslation();
  return props.show ? (
    <Modal centered noHeader show preventOutterClickClose maxWidth="50vw">
      <ContentWrapper role="Modal">
        <WarningYellow />
        <div>
          <Title>{props.gdprTitle}</Title>
          <Message>
            <span dangerouslySetInnerHTML={{ __html: props.gdprMessage }} />
          </Message>
        </div>
        <ButtonWrapper>
          {props.appGDPR ? (
            <Button
              aria-label="decline"
              sm
              onClick={() => {
                props.onClose();
              }}
            >
              {t("msp.decline")}
            </Button>
          ) : null}
          <PrimaryBtn
            aria-label="confirmGDPR"
            sm
            onClick={() => {
              props.onClose();
              props.confirmGDPR();
            }}
          >
            {t("msp.confirm")}
          </PrimaryBtn>
        </ButtonWrapper>
      </ContentWrapper>
    </Modal>
  ) : null;
};

GDPRMessage.propTypes = {
  appGDPR: PropTypes.any,
  confirmGDPR: PropTypes.func,
  gdprMessage: PropTypes.any,
  gdprTitle: PropTypes.any,
  onClose: PropTypes.func,
  show: PropTypes.any,
};

export default GDPRMessage;
