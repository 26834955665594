import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  display: block;
  z-index: ${(props) => props.overlayZIndex || "1000"};
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1.2rem);
  transition: opacity 0.15s linear;
`;

export default Overlay;
