import styled from "styled-components";
export const Slider = styled.div``;

const SwicthStyled = styled.label`
  display: inline-block;
  width: 2.5rem;
  height: 1.5rem;
  position: relative;

  ${Slider} {
    border-radius: 2.5rem;
    background-color: var(--light-clr);
    border: var(--onePx) solid var(--input-border-clr);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
    svg {
      fill: none;
      stroke: red;
      stroke-width: 0.125rem;
      visibility: hidden;
    }
    ::before {
      border-radius: 50%;
      background-color: var(--disabled-clr-dark);
      border: var(--onePx) solid var(--input-border-clr);
      content: "";
      height: 1rem;
      width: 1rem;
      top: 0.25rem;
      left: 0.25rem;
      bottom: 0.25rem;
      position: absolute;
      transition: 0.4s;
    }
  }
  input:checked + ${Slider} {
    background-color: var(--primary-clr);
    svg {
      visibility: visible;
    }
    ::before {
      background-color: var(--light-clr);
      content: url("data:image/svg+xml;charset=UTF-8, <svg viewBox='0 0 24 24' fill='none' stroke='rgb(33, 43, 54)' stroke-width='0.125rem' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><polyline points='20 6 9 17 4 12'></polyline></svg>");
      transform: translateX(1rem);
    }
  }
`;

export default SwicthStyled;

export const HiddenCheckbox = styled.input.attrs(() => ({
  type: "checkbox",
}))`
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
`;
