import styled from "styled-components";

const Label = styled.label`
  font-size: var(--fs-16);
  font-family: var(--ff-medium);
  color: var(--grey-clr);
  pointer-events: none;
  &:before {
    color: ${(props) => (props.required ? "var(--error-clr)" : null)};
    content: "${(props) => (props.required ? "*" : null)}";
    top: 0;
    padding: 0 0.2rem;
    float: right;
  }
`;

export const CustomLabel = styled.label`
  font-size: var(--fs-16);
  font-family: var(--ff-medium);
  color: var(--grey-clr);
  pointer-events: none;
  &:after {
    color: ${(props) => (props.required ? "var(--error-clr)" : null)};
    content: "${(props) => (props.required ? "*" : null)}";
    top: 0;
    padding: 0 0.2rem;
  }
`;

export default Label;
