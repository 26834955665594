import React, { useState, useRef } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Timer from "../Timer";
import Banner from "./Header/Banner/index";
import Header from "./Header/index";
import Footer from "./Footer/index";
import LayoutStyled, { MainContainer } from "./style";
import DetailedView from "components/InvoiceStatus/DetailedViewPopup/";
import { storage } from "config/storage";
import { compose } from "recompose";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectDFMInvoice,
  selectExpandedHeaders,
  selectInvoiceDetails,
  selectInvoiceDetailsHeaderItems,
  selectInvoiceDetailsLineItems,
  selectPaymentDetails,
  selectAdvancePaymentDetails,
  selectExpandedPaymentHeaders,
  selectExpandedAdvancePaymentHeaders,
} from "container/InvoiceStatus/store/selectors";
import {
  initDownloadInvoiceDFM,
  initFetchPaymentDetails,
  initFetchAdvancePaymentDetails
} from "container/InvoiceStatus/store/actions";
import { isGlobalSearchActive } from "store/actions";

const Layout = (props) => {
  const content = useRef(null);
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const language =
    (sessionDetails &&
      sessionDetails.languageId !== "" &&
      sessionDetails.languageId) ||
    "enGB";
  const clientDetails = JSON.parse(storage.getItem("clientDetails")) || {};
  const clientName = clientDetails ? clientDetails.name : "";
  let menus = sessionDetails ? sessionDetails.menus : []; 
  let isAdvancePaymentPermission;
  if(menus){
    isAdvancePaymentPermission = menus.find(
      (item) => item.pageElementFileName === "advancePayments"
    )
  }
  const shouldHaveBanner =
    props.history.location.pathname === "/" ||
    props.history.location.pathname === "/support";

  const [showInvoiceDetailsModal, setShowInvoiceDetailsModal] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [amountDetails, setAmountDetails] = useState({});

  const handleDetailsPopup = (res) => {
    const { invoiceList } = (res && res.data) || {};
    const {
      InvoiceId,
      URN,
      HasRemittance = false,
      Total,
      Balance,
      PAID,
      AdvancePaid
    } = (invoiceList && invoiceList[0]) || {};
    const payload = {
      invoiceId: InvoiceId,
      LanguageID: language,
    };
    InvoiceId && props.showPaymentDetails(payload, false);
    
    isAdvancePaymentPermission && props.getAdvancePaymentDetails(payload);
    props.downloadInvoice(URN, clientName);

    setInvoiceStatus(HasRemittance);
    setAmountDetails({
      total: Total || 0,
      balance: Balance || 0,
      paid: PAID || 0,
      advancePaid: AdvancePaid || 0,
    });
    props.globalSearchActiveStatus(true);
    invoiceList.length > 0 && setShowInvoiceDetailsModal(true);
  };

  const handleDetailsPopupClose = () => {
    setShowInvoiceDetailsModal(false);
    setAmountDetails({});
    setInvoiceStatus("");
    props.globalSearchActiveStatus(false);
  };

  const handleSkipToMain = (event) => {
    if (event.type === "keyup" && event.key !== "Enter") return false;
    content.current.tabIndex = -1;
    content.current.focus();
    setTimeout(() => content.current.removeAttribute("tabindex"), 1000);
  };
  const authHeader = (
    <>
      <Header
        hasBanner={shouldHaveBanner}
        clientDetails={props.clientDetails}
        invoicePaymentData={handleDetailsPopup}
        handleSkipToMain={handleSkipToMain}
      />

      {process.env.NODE_ENV !== "development" ? <Timer /> : null}

      {shouldHaveBanner ? <Banner /> : null}
    </>
  );
  const authFooter = <Footer clientDetails={props.clientDetails} />;
  return (
    <LayoutStyled auth={props.isAuthenticated}>
      {props.isAuthenticated ? authHeader : null}
      <MainContainer ref={content} as="main" id="main">
        {props.children}
      </MainContainer>
      <DetailedView
        show={showInvoiceDetailsModal}
        paymentDetails={props.paymentDetails || {}}
        advancePaymentDetails={props.advancePaymentDetails || {}}
        invoiceDetails={props.invoiceDetails || {}}
        invoiceColHeaders={props.expandedHeaders || {}}
        invoiceHeaderItems={props.invoiceHeaderItems || []}
        invoiceLineitems={props.invoiceLineitems || {}}
        dfmInvoice={props.dfmInvoice || {}}
        status={invoiceStatus}
        amountDetails={amountDetails}
        onClose={handleDetailsPopupClose}
        lineCols={props.lineCols}
        lineColsAdvPayment={props.lineColsAdvPayment}
      />

      {props.isAuthenticated ? authFooter : null}
    </LayoutStyled>
  );
};

const mapStateToProps = createStructuredSelector({
  expandedHeaders: selectExpandedHeaders(),
  invoiceDetails: selectInvoiceDetails(),
  invoiceHeaderItems: selectInvoiceDetailsHeaderItems(),
  invoiceLineitems: selectInvoiceDetailsLineItems(),
  paymentDetails: selectPaymentDetails(),
  advacnePaymentDetails: selectAdvancePaymentDetails(),
  dfmInvoice: selectDFMInvoice(),
  lineCols: selectExpandedPaymentHeaders(),
  lineColsAdvPayment: selectExpandedAdvancePaymentHeaders(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    showPaymentDetails: (payload, globalSearch, callback) => {
      dispatch(initFetchPaymentDetails(payload, globalSearch, callback));
    },
    getAdvancePaymentDetails: (payload) => {
      dispatch(initFetchAdvancePaymentDetails(payload));
    },
    downloadInvoice: (urn, clientName) => {
      dispatch(initDownloadInvoiceDFM(urn, clientName));
    },
    globalSearchActiveStatus: (bool) => {
      dispatch(isGlobalSearchActive(bool));
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

Layout.propTypes = {
  children: PropTypes.any,
  isAuthenticated: PropTypes.any,
};
export default compose(withConnect, withRouter)(Layout);
