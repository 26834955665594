import React, { useState } from "react";
import PropTypes from "prop-types";
import { currencyFormatter } from "helper";
import { Tooltip } from "components";
import {
  AmtText,
  AmountValue,
  TextWrapper,
  ContentWrapper,
  Div,
  Shrink,
} from "./style";

const CurrencyFormatter = (props) => {  
  const { value, code, delay, shrink = true} = props || "";
  let timeout;
  const [active, setActive] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };
  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  const amount =
    (value &&
      currencyFormatter(
        Number(value),
        "en-US",
        false, // conversion,
        false
      )) ||
    0;

  return (
    <>
      <Shrink>
        <Div
          ref={setReferenceElement}
          onMouseEnter={showTip}
          onMouseLeave={hideTip}
        >
          <AmtText>
            {amount.length > 6 && shrink ? `${amount.slice(0, 6)}...` : amount}
            <small>{code ? code : ""} </small>
          </AmtText>
        </Div>
      </Shrink>
      {active && amount.length > 6  && (
        <Tooltip triggerElem={referenceElement} placement="bottom">
          <ContentWrapper>
            <TextWrapper>
              <AmountValue>
                {amount}
              </AmountValue>
            </TextWrapper>
          </ContentWrapper>
        </Tooltip>
      )}
    </>
  );
};

CurrencyFormatter.propTypes = {
  value: PropTypes.any,
  code: PropTypes.any,
  delay: PropTypes.any,
};

export default CurrencyFormatter;
