import styled from "styled-components";
import { Header, Content, Footer } from "components/Popup/style";
import { AmtText } from "components/CurrencyFormatter/style";
import { IconWrapper, Rows } from "components/index";

export const HeaderBtns = styled.div``;
export const Title = styled.h1``;

export const PopupHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  margin: 1em 1em 0.5em 1em;
  flex-direction: column;
  @media (min-width: 36em) {
    flex-direction: row;
  }

  ${Title} {
    color: rgb(33, 43, 54);
    font-size: clamp(var(--fs-18), 3vw, var(--fs-24));
    font-family: var(--ff-semibold);
    font-weight: var(--fw-bold);
    letter-spacing: -0.0219rem;
    line-height: 1;
  }

  ${HeaderBtns} {
    display: flex;
    gap: 0.3em;
  }
`;

export const GridWrapper = styled.div``;

export const PopupContent = styled(Content)`
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: 70vh;
  flex-grow: 1;
  ${GridWrapper} {
    min-height: 20.5em;
    height: 100%;
    overflow: auto;
  }
`;

export const Wrapper = styled.div``;

export const Label = styled.p``;

export const Value = styled.p``;

export const PopupFooter = styled(Footer)`
  background: rgb(255, 255, 255);
  box-shadow: 0 -0.125em 2em 0 rgba(0, 0, 0, 0.1);
  border-radius: 0 0 0.5em 0.5em;
  display: flex;
  justify-content: center;
  gap: 0.5em;
  padding: 1em;
  flex-wrap: wrap;
  @media (min-width: 36em) {
    justify-content: space-evenly;
    gap: 2em;
    padding: 1.5em;
  }

  ${Wrapper} {
    display: flex;
    gap: 2em;
    align-self: center;
    text-align: left;
  }

  ${Label} {
    color: rgb(31, 43, 55);
    font-size: clamp(var(--fs-12), 4vw, var(--fs-14));
    font-family: var(--ff-main);
    font-weight: var(--fw-normal);
    letter-spacing: -0.018rem;
    line-height: 0.75;
    align-self: center;
  }

  ${Value} {
    font-size: clamp(var(--fs-16), 2vw, var(--fs-20));
    font-family: var(--ff-medium);
    letter-spacing: -0.026rem;

    ${AmtText} {
      margin: 0;
    }
  }
`;
export const ContentWrapper = styled(Rows)`
  gap: 0;
`;

export const ExportIconWrapper = styled(IconWrapper)`
  margin-left: auto;
  margin-right: 1rem;
  svg {
    width: 1.2em;
    color: var(--grey-clr);
  }
`;
