import { createSelector } from "reselect";
import { initialState } from "./reducers";

export const selectHelpDeskDomain = (state) =>
  state.get("helpdesk", initialState);

export const selectListFetchError = () =>
  createSelector(selectHelpDeskDomain, (substate) =>
    substate.get("listFetchError")
  );

// export const selectCaseType = () =>
//   createSelector(selectHelpDeskDomain, substate => substate.get('caseType'));
export const selectRowCount = () =>
  createSelector(selectHelpDeskDomain, (substate) => substate.get("rowCount"));

export const selectIsPending = () =>
  createSelector(selectHelpDeskDomain, (VPState) => VPState.get("isPending"));

export const selectOpenCases = () =>
  createSelector(selectHelpDeskDomain, (VPState) => VPState.get("openCases"));

export const selectClosedCases = () =>
  createSelector(selectHelpDeskDomain, (VPState) => VPState.get("closedCases"));

export const selectInitCases = () =>
  createSelector(selectHelpDeskDomain, (VPState) => VPState.get("initCases"));

export const selectCases = () =>
  createSelector(selectHelpDeskDomain, (VPState) => VPState.get("Cases"));
export const selectUpdatedCases = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("updatedCases")
  );

export const selectAttachment = () =>
  createSelector(selectHelpDeskDomain, (substate) =>
    substate.get("attachment")
  );

export const selectIsRequestSaving = () =>
  createSelector(selectHelpDeskDomain, (substate) =>
    substate.get("isRequestSaving")
  );
export const selectIsRequestFailed = () =>
  createSelector(selectHelpDeskDomain, (substate) =>
    substate.get("isRequestFailed")
  );
export const selectQueryTypeList = () =>
  createSelector(selectHelpDeskDomain, (substate) =>
    substate.get("queryTypeList")
  );

export const selectQueryTypeListFetchError = () =>
  createSelector(selectHelpDeskDomain, (substate) =>
    substate.get("queryTypeListFetchError")
  );
export const selectQueryFieldsListFetchStatus = () =>
  createSelector(selectHelpDeskDomain, (substate) =>
    substate.get("fetchingQueryFieldsList")
  );
export const selectQueryFieldsListFetchingFailed = () =>
  createSelector(selectHelpDeskDomain, (substate) =>
    substate.get("fetchingQueryFieldsListFailed")
  );
export const selectQueryFieldsList = () =>
  createSelector(selectHelpDeskDomain, (substate) =>
    substate.get("queryFieldsList")
  );

export const selectTLogCase = () =>
  createSelector(selectHelpDeskDomain, (substate) => substate.get("TLogCase"));
export const selectHelpdeskDetailsByIdFailed = () =>
  createSelector(selectHelpDeskDomain, (substate) =>
    substate.get("fetchingHelpdeskByIdFailed")
  );
export const selectHelpdeskDetailsByIdListStatus = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("fetchingHelpdeskDetailsbyID")
  );

export const selectAdditionalColumns = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("additionalColumns")
  );

export const selectCommentSaving = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("submisisonCommentSaving")
  );
export const selectCommentSaved = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("submisisonCommentSaved")
  );

export const selectComments = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("getCommentsData")
  );
  export const selectCommentsFetchStatus = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("fetchingComments")
  );
  
export const selectGetCommentsFailed = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("fetchCommentFailed")
  );

export const selectSaveCommentsFailed = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("commentSaveFailed")
  );
export const selectAttachmentSaving = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("isAttachmentSaving")
  );

export const selectAttachmentSavingFailed = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("attachmentAddFailed")
  );
export const selectAttachmentSaved = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("attachmentSaved")
  );

export const selectHelpdeskOpenRequestMarkAsCompleteStatus = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("openRequestMarkAsCompleteStatus")
  );

export const selectHelpdeskQueryFetching = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("isHelpdeskQueryFetching")
  );
export const selectHelpdeskQuery = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("helpdeskQuery")
  );

export const selectLegendList = () =>
  createSelector(selectHelpDeskDomain, (VPState) => VPState.get("legendList"));

export const selectServiceNowQueryByIdFetchStatus = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("isServiceQueryByIDFething")
  );
export const selectServiceNowQueryById = () =>
  createSelector(selectHelpDeskDomain, (VPState) =>
    VPState.get("serviceNowQueryById")
  );
