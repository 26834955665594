import zhNumbers from "cldr-numbers-full/main/zh/numbers.json";
import zhCurrencies from "cldr-numbers-full/main/zh/currencies.json";
import zhCaGregorian from "cldr-dates-full/main/zh/ca-gregorian.json";
import zhDateFields from "cldr-dates-full/main/zh/dateFields.json";
import zhTimeZoneNames from "cldr-dates-full/main/zh/timeZoneNames.json";

/* import csNumbers from "cldr-numbers-full/main/cs/numbers.json";
import csCurrencies from "cldr-numbers-full/main/cs/currencies.json";
import csCaGregorian from "cldr-dates-full/main/cs/ca-gregorian.json";
import csDateFields from "cldr-dates-full/main/cs/dateFields.json";
import csTimeZoneNames from "cldr-dates-full/main/cs/timeZoneNames.json"; */

import deNumbers from "cldr-numbers-full/main/de/numbers.json";
import deCurrencies from "cldr-numbers-full/main/de/currencies.json";
import deCaGregorian from "cldr-dates-full/main/de/ca-gregorian.json";
import deDateFields from "cldr-dates-full/main/de/dateFields.json";
import deTimeZoneNames from "cldr-dates-full/main/de/timeZoneNames.json";

import enNumbers from "cldr-numbers-full/main/en/numbers.json";
import enCurrencies from "cldr-numbers-full/main/en/currencies.json";
import enCaGregorian from "cldr-dates-full/main/en/ca-gregorian.json";
import enDateFields from "cldr-dates-full/main/en/dateFields.json";
import enTimeZoneNames from "cldr-dates-full/main/en/timeZoneNames.json";

import esNumbers from "cldr-numbers-full/main/es/numbers.json";
import esCurrencies from "cldr-numbers-full/main/es/currencies.json";
import esCaGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import esDateFields from "cldr-dates-full/main/es/dateFields.json";
import esTimeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import frNumbers from "cldr-numbers-full/main/fr/numbers.json";
import frCurrencies from "cldr-numbers-full/main/fr/currencies.json";
import frCaGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import frDateFields from "cldr-dates-full/main/fr/dateFields.json";
import frTimeZoneNames from "cldr-dates-full/main/fr/timeZoneNames.json";

/* import huNumbers from "cldr-numbers-full/main/hu/numbers.json";
import huCurrencies from "cldr-numbers-full/main/hu/currencies.json";
import huCaGregorian from "cldr-dates-full/main/hu/ca-gregorian.json";
import huDateFields from "cldr-dates-full/main/hu/dateFields.json";
import huTimeZoneNames from "cldr-dates-full/main/hu/timeZoneNames.json"; */

import itNumbers from "cldr-numbers-full/main/it/numbers.json";
import itCurrencies from "cldr-numbers-full/main/it/currencies.json";
import itCaGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import itDateFields from "cldr-dates-full/main/it/dateFields.json";
import itTimeZoneNames from "cldr-dates-full/main/it/timeZoneNames.json";

import jaNumbers from "cldr-numbers-full/main/ja/numbers.json";
import jaCurrencies from "cldr-numbers-full/main/ja/currencies.json";
import jaCaGregorian from "cldr-dates-full/main/ja/ca-gregorian.json";
import jaDateFields from "cldr-dates-full/main/ja/dateFields.json";
import jaTimeZoneNames from "cldr-dates-full/main/ja/timeZoneNames.json";

/* import koNumbers from "cldr-numbers-full/main/ko/numbers.json";
import koCurrencies from "cldr-numbers-full/main/ko/currencies.json";
import koCaGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import koDateFields from "cldr-dates-full/main/ko/dateFields.json";
import koTimeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";

import nlNumbers from "cldr-numbers-full/main/nl/numbers.json";
import nlCurrencies from "cldr-numbers-full/main/nl/currencies.json";
import nlCaGregorian from "cldr-dates-full/main/nl/ca-gregorian.json";
import nlDateFields from "cldr-dates-full/main/nl/dateFields.json";
import nlTimeZoneNames from "cldr-dates-full/main/nl/timeZoneNames.json";

import plNumbers from "cldr-numbers-full/main/pl/numbers.json";
import plCurrencies from "cldr-numbers-full/main/pl/currencies.json";
import plCaGregorian from "cldr-dates-full/main/pl/ca-gregorian.json";
import plDateFields from "cldr-dates-full/main/pl/dateFields.json";
import plTimeZoneNames from "cldr-dates-full/main/pl/timeZoneNames.json"; */

import ptNumbers from "cldr-numbers-full/main/pt/numbers.json";
import ptCurrencies from "cldr-numbers-full/main/pt/currencies.json";
import ptCaGregorian from "cldr-dates-full/main/pt/ca-gregorian.json";
import ptDateFields from "cldr-dates-full/main/pt/dateFields.json";
import ptTimeZoneNames from "cldr-dates-full/main/pt/timeZoneNames.json";

/* import roNumbers from "cldr-numbers-full/main/ro/numbers.json";
import roCurrencies from "cldr-numbers-full/main/ro/currencies.json";
import roCaGregorian from "cldr-dates-full/main/ro/ca-gregorian.json";
import roDateFields from "cldr-dates-full/main/ro/dateFields.json";
import roTimeZoneNames from "cldr-dates-full/main/ro/timeZoneNames.json";

import ruNumbers from "cldr-numbers-full/main/ru/numbers.json";
import ruCurrencies from "cldr-numbers-full/main/ru/currencies.json";
import ruCaGregorian from "cldr-dates-full/main/ru/ca-gregorian.json";
import ruDateFields from "cldr-dates-full/main/ru/dateFields.json";
import ruTimeZoneNames from "cldr-dates-full/main/ru/timeZoneNames.json";

import skNumbers from "cldr-numbers-full/main/sk/numbers.json";
import skCurrencies from "cldr-numbers-full/main/sk/currencies.json";
import skCaGregorian from "cldr-dates-full/main/sk/ca-gregorian.json";
import skDateFields from "cldr-dates-full/main/sk/dateFields.json";
import skTimeZoneNames from "cldr-dates-full/main/sk/timeZoneNames.json";

import svNumbers from "cldr-numbers-full/main/sv/numbers.json";
import svCurrencies from "cldr-numbers-full/main/sv/currencies.json";
import svCaGregorian from "cldr-dates-full/main/sv/ca-gregorian.json";
import svDateFields from "cldr-dates-full/main/sv/dateFields.json";
import svTimeZoneNames from "cldr-dates-full/main/sv/timeZoneNames.json";

import thNumbers from "cldr-numbers-full/main/th/numbers.json";
import thCurrencies from "cldr-numbers-full/main/th/currencies.json";
import thCaGregorian from "cldr-dates-full/main/th/ca-gregorian.json";
import thDateFields from "cldr-dates-full/main/th/dateFields.json";
import thTimeZoneNames from "cldr-dates-full/main/th/timeZoneNames.json";

import trNumbers from "cldr-numbers-full/main/tr/numbers.json";
import trCurrencies from "cldr-numbers-full/main/tr/currencies.json";
import trCaGregorian from "cldr-dates-full/main/tr/ca-gregorian.json";
import trDateFields from "cldr-dates-full/main/tr/dateFields.json";
import trTimeZoneNames from "cldr-dates-full/main/tr/timeZoneNames.json"; */

export const getMiscellaneousExports = {
  zh: {
    numbers: zhNumbers,
    currencies: zhCurrencies,
    caGregorian: zhCaGregorian,
    dateFields: zhDateFields,
    timeZoneNames: zhTimeZoneNames,
  },
  /* cs: {
    numbers: csNumbers,
    currencies: csCurrencies,
    caGregorian: csCaGregorian,
    dateFields: csDateFields,
    timeZoneNames: csTimeZoneNames,
  }, */
  de: {
    numbers: deNumbers,
    currencies: deCurrencies,
    caGregorian: deCaGregorian,
    dateFields: deDateFields,
    timeZoneNames: deTimeZoneNames,
  },
  en: {
    numbers: enNumbers,
    currencies: enCurrencies,
    caGregorian: enCaGregorian,
    dateFields: enDateFields,
    timeZoneNames: enTimeZoneNames,
  },
  es: {
    numbers: esNumbers,
    currencies: esCurrencies,
    caGregorian: esCaGregorian,
    dateFields: esDateFields,
    timeZoneNames: esTimeZoneNames,
  },
  fr: {
    numbers: frNumbers,
    currencies: frCurrencies,
    caGregorian: frCaGregorian,
    dateFields: frDateFields,
    timeZoneNames: frTimeZoneNames,
  },
  /* hu: {
    numbers: huNumbers,
    currencies: huCurrencies,
    caGregorian: huCaGregorian,
    dateFields: huDateFields,
    timeZoneNames: huTimeZoneNames,
  }, */
  it: {
    numbers: itNumbers,
    currencies: itCurrencies,
    caGregorian: itCaGregorian,
    dateFields: itDateFields,
    timeZoneNames: itTimeZoneNames,
  },
  ja: {
    numbers: jaNumbers,
    currencies: jaCurrencies,
    caGregorian: jaCaGregorian,
    dateFields: jaDateFields,
    timeZoneNames: jaTimeZoneNames,
  },
  /*  ko: {
    numbers: koNumbers,
    currencies: koCurrencies,
    caGregorian: koCaGregorian,
    dateFields: koDateFields,
    timeZoneNames: koTimeZoneNames,
  }, 
  nl: {
    numbers: nlNumbers,
    currencies: nlCurrencies,
    caGregorian: nlCaGregorian,
    dateFields: nlDateFields,
    timeZoneNames: nlTimeZoneNames,
  },
    pl: {
    numbers: plNumbers,
    currencies: plCurrencies,
    caGregorian: plCaGregorian,
    dateFields: plDateFields,
    timeZoneNames: plTimeZoneNames,
  }, */
  pt: {
    numbers: ptNumbers,
    currencies: ptCurrencies,
    caGregorian: ptCaGregorian,
    dateFields: ptDateFields,
    timeZoneNames: ptTimeZoneNames,
  },
  /* ro: {
    numbers: roNumbers,
    currencies: roCurrencies,
    caGregorian: roCaGregorian,
    dateFields: roDateFields,
    timeZoneNames: roTimeZoneNames,
  }, */
  /*  ru: {
    numbers: ruNumbers,
    currencies: ruCurrencies,
    caGregorian: ruCaGregorian,
    dateFields: ruDateFields,
    timeZoneNames: ruTimeZoneNames,
  },
  sk: {
    numbers: skNumbers,
    currencies: skCurrencies,
    caGregorian: skCaGregorian,
    dateFields: skDateFields,
    timeZoneNames: skTimeZoneNames,
  },
  sv: {
    numbers: svNumbers,
    currencies: svCurrencies,
    caGregorian: svCaGregorian,
    dateFields: svDateFields,
    timeZoneNames: svTimeZoneNames,
  },
  th: {
    numbers: thNumbers,
    currencies: thCurrencies,
    caGregorian: thCaGregorian,
    dateFields: thDateFields,
    timeZoneNames: thTimeZoneNames,
  },
  tr: {
    numbers: trNumbers,
    currencies: trCurrencies,
    caGregorian: trCaGregorian,
    dateFields: trDateFields,
    timeZoneNames: trTimeZoneNames,
  }, */
};
