import { getRequestDetails, postRequestDetails } from "config/requestHeaders";
import axios from "../../../config/axiosCall";
export const getAdditionalFields = async ({ languageId, profileId }) => {
  const response = await axios(
    `/Submission/attributes?languageID=${languageId}&ProfileID=${profileId}`,
    {
      ...getRequestDetails,
    }
  );

  if (response.status === 200 || response.status === 202) {
    return response;
  }
  throw new Error("Failed to save data. Please try again");
};

export const downloadPOInvoice = async ({ poNumber, supplierID }) => {
  const response = await axios(
    `/EInvoice/PODownload?PONumber=${poNumber}&Supplierid=${supplierID}`,
    {
      ...postRequestDetails,
    }
  );

  if (response.status === 200 || response.status === 202) {
    return response;
  }
  throw new Error("Failed to save data. Please try again");
};
