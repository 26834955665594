import styled from "styled-components";
import { SecondaryBtn } from "components/index";

export const ExportBtn = styled(SecondaryBtn)`
  span {
    display: flex;
  }
  svg {
    height: 1em;
    margin-right: 0.5em;
  }
`;
