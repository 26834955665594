import styled from "styled-components";
import { Cols, PrimaryBtn, IconWrapper } from "components/index";
import Overlay from "components/Overlay/index";
import { dropdownContent } from "globalStyles";

export const DesktopIcon = styled(IconWrapper)``;
export const MobileIcon = styled(PrimaryBtn)``;
export const SearchInputWrapper = styled(Cols)``;
export const SearchOptions = styled.div``;
export const SearchInput = styled.div``;
export const SearchContent = styled.ul``;
export const SearchContentItem = styled.li``;
export const DesktopSearchHint = styled.span``;
export const MobileSearchHint = styled.span``;
export const SearchContentItemInfo = styled(SearchContentItem)``;
export const NoRecords = styled(SearchContentItem)``;
export const TopTenRecordsInfo = styled(SearchContentItem)``;
export const customStyle = {
  container: {
    textAlign: "left",
    zIndex: 3,
  },
  control: {
    height: "2.875rem",
    "@media (min-width: 36em)": {
      height: "calc(2.875rem - 1rem)",
      border: "none",
      boxShadow: "none",
    },
  },
};

export const StyledOverlay = styled(Overlay)`
  display: none;
  @media (min-width: 36em) {
    display: block;
    backdrop-filter: blur(0.5em);
  }
`;

const GlobalSearchStyled = styled.div`
  ${dropdownContent}
  background: rgb(255, 255, 255);
  border: var(--border-light-1);
  box-shadow: 0 0.2rem 3.2rem 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  width: clamp(12.5rem, calc(100vw - 3.2em), 100vw);
  gap: 0.5em;
  flex-wrap: wrap;
  transform: scale(0, 1);
  left: calc(100% + 0.25em);
  border-radius: 0.5em;
  padding: 0.7em;
  min-width: 12.2rem;
  flex-wrap: wrap;
  transform-origin: left;
  transition: transform 400ms ease-in-out;
  z-index: 2;
  flex-wrap: wrap;
  @media (min-width: 36em) {
    all: unset;
    background: rgb(255, 255, 255);
    border: var(--border-light-1);
    box-shadow: 0 0.2rem 3.2rem 0 rgb(0 0 0 / 10%);
    border-radius: 2.8rem;
    padding: 0 1.6rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0 1em;
    transform: scale(0, 0);
    transform-origin: center;
    transition: transform 400ms ease-in-out;
    position: absolute;
    z-index: 2;
    margin: 0.5em;
    ${MobileIcon} {
      display: none;
    }
  }

  ${DesktopIcon} {
    display: none;
    @media (min-width: 36em) {
      display: flex;
      font-size: var(--fs-14);
      color: var(--primary-text-clr);
      svg {
        height: 1em;
        width: 1em;
      }
    }
  }

  ${PrimaryBtn} {
    margin: 0;
    margin-left: auto;
    font-size: var(--fs-12);
  }
  ${SearchInputWrapper} {
    gap: 1em;
    width: 100%;
    flex-wrap: wrap;
    position: relative;
    @media (min-width: 36em) {
      all: unset;
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
    }
    ${SearchOptions}, ${SearchInput} {
      min-width: 3.125rem;
      flex-basis: 100%;
    }
    ${SearchOptions} {
      @media (min-width: 36em) {
        max-width: 11rem;
      }
    }
    ${SearchInput} {
      flex-basis: 100%;
      @media (min-width: 36em) {
        input {
          max-height: 2.375rem;
          border: none;
          box-shadow: none;
          border-radius: 0;
        }
      }
    }
    ${SearchContent} {
      position: absolute;
      top: calc(100% + 3.35rem);
      display: flex;
      flex-direction: column;
      width: 100%;
      border: var(--onePx) solid var(--offwhite-clr);
      box-shadow: 0 0.125em 2em rgb(0 0 0 / 10%);
      background-color: var(--light-clr);
      border-radius: 0 0 0.5rem 0.5rem;
      overflow: auto;
      color: var(--grey-clr);
      font-size: var(--fs-14);
      padding: 0.75rem;
      gap: 0.5rem;
      z-index: 2;
      @media (min-width: 36em) {
        top: calc(100% + 0.12rem);
      }
      ${SearchContentItem} {
        padding: 0.25rem;
        text-overflow: ellipsis;
        cursor: pointer;
        color: var(--grey-clr);
        font-size: var(--fs-14);
        text-align: left;
        font-family: var(--ff-main);
        font-weight: var(--fw-normal);
        ${DesktopSearchHint} {
          display: none;
        }
        @media (min-width: 36em) {
          ${DesktopSearchHint} {
            display: block;
          }
          ${MobileSearchHint} {
            display: none;
          }
        }
      }
      ${SearchContentItemInfo} {
        color: rgba(4, 30, 66, 1);
        :hover,
        :focus {
          font-weight: var(--fw-bold);
        }
      }
      ${TopTenRecordsInfo} {
        font-size: var(--fs-12);
      }
      ${NoRecords} {
        color: var(--error-clr);
      }
    }
  }
`;

export default GlobalSearchStyled;
