export const INIT_REPORTS_DETAILS = "INIT_REPORTS_DETAILS";
export const FETCHING_REPORTS_DETAILS = "FETCHING_REPORTS_DETAILS";
export const FETCH_REPORT_DETAILS = "FETCH_REPORT_DETAILS";
export const REPORT_DETAILS_FETCH_COMPLETE = "REPORT_DETAILS_FETCH_COMPLETE";

export const INIT_PAYMENT_FORECAST_DETAILS = "INIT_PAYMENT_FORECAST_DETAILS";
export const FETCHING_PAYMENT_FORECAST_DETAILS =
  "FETCHING_PAYMENT_FORECAST_DETAILS";
export const FETCH_PAYMENT_FORECAST_DETAILS = "FETCH_PAYMENT_FORECAST_DETAILS";
export const PAYMENT_FORECAST_DETAILS_FETCH_COMPLETE =
  "PAYMENT_FORECAST_DETAILS_FETCH_COMPLETE";

export const INIT_FETCH_SUMMARY_DATA = "INIT_FETCH_SUMMARY_DATA";
export const FETCH_SUMMARY_DATA_COMPLETE = "FETCH_SUMMARY_DATA_COMPLETE";
export const SET_SUMMARY_DATA = "SET_SUMMARY_DATA";

export const INIT_FETCH_DRILLDOWN_DATA = "INIT_FETCH_DRILLDOWN_DATA";
export const SET_DRILLDOWN_DATA = "SET_DRILLDOWN_DATA";
export const FETCH_DRILLDOWN_DATA_COMPLETE = "FETCH_DRILLDOWN_DATA_COMPLETE";