import { put, takeEvery, call, all, select } from "redux-saga/effects";
import { buildNotification } from "../../../../config/notification";
import { store } from "react-notifications-component";
import {
  formSubmissionStatusAction,
  fetchingReportDetailsList,
  fetchReportDetailsList,
  fetchReportDetailsListCompleted,
  fetchingFrqeuencyDropdownList,
  fetchFrqeuencyDropdownList,
  frequencyFetchingComplete,
  fetchingCurrencyDropdownList,
  fetchCurrencyDropdownList,
  currencyFetchingComplete,
  fetchingReportDetailsbyID,
  fetchReportDetailsbyID,
  fetchReportDetailsbyIDCompleted,
  saveReportDetailsData,
  deleteSuccess,
  deleteFailed,
  saveUpdatedReportStatus,
  updateReportDetailsList,
  fetchingUserList,
  fetchUserList,
  fetchUserListComplete,
  savingReportDetailsData,
  saveReportDetailsDataCompleted,
  saveReportDetailsDataFailed,
} from "./actions";

import {
  INIT_FREQUENCY_LIST_FETCH,
  INIT_CURRENCY_LIST_FETCH,
  INIT_REPORT_DETAILS_LIST,
  INIT_REPORT_DETAILS_BY_ID,
  INIT_REPORT_DETAILS_SAVE,
  INIT_DELETE_REPORT_CONFIG,
  INIT_UPDATE_REPORT_STATUS,
  INIT_USER_LIST,
  // INIT_USER_ROLE_LIST,
} from "./actionTypes";
import axios from "../../../../config/axiosCall";
import moment from "moment";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../../config/requestHeaders";
import { selcteReportDetailsList } from "./selectors";

export default function* watchDocHistoryListFetch() {
  yield all([
    takeEvery(INIT_CURRENCY_LIST_FETCH, getCurrencyDropdownListSaga),
    takeEvery(INIT_FREQUENCY_LIST_FETCH, getFrqeuencyDropdownListSaga),
    takeEvery(INIT_REPORT_DETAILS_SAVE, saveReportDetailsSaga),
    takeEvery(INIT_REPORT_DETAILS_LIST, getReportDetailsListSaga),
    takeEvery(INIT_REPORT_DETAILS_BY_ID, getReportDetailsByIdSaga),
    takeEvery(INIT_DELETE_REPORT_CONFIG, initReportConfigDeleteSaga),

    takeEvery(INIT_UPDATE_REPORT_STATUS, updateReportStatusSaga),
    takeEvery(INIT_USER_LIST, getUserListSaga),
  ]);
}

function* getFrqeuencyDropdownListSaga() {
  yield put(fetchingFrqeuencyDropdownList());
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url = "/Common/listfrequency?languageID=" + languageID;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const data = yield response.data.map((item) => ({
      value: item.label,
      id: item.id,
    }));
    yield put(fetchFrqeuencyDropdownList(data));
    yield put(frequencyFetchingComplete());
  } catch (error) {
    yield put(frequencyFetchingComplete());
  }
}

function* getCurrencyDropdownListSaga() {
  yield put(fetchingCurrencyDropdownList());
  const url = "/Common/listcurrency";
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    const data = yield response.data.map((item) => ({
      value: item.label,
      id: item.key,
    }));
    yield put(fetchCurrencyDropdownList(data));
    yield put(frequencyFetchingComplete());
  } catch (error) {
    yield put(currencyFetchingComplete());
  }
}

function* saveReportDetailsSaga(action) {
  yield put(savingReportDetailsData());
  const url = "/Report/saveReport";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    ...action.data,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      yield put(saveReportDetailsData());
      yield put(formSubmissionStatusAction(response.statusText));
      if (action.saveSuccess) {
        action.saveSuccess(response.status);
      }
      if (action.isEditMode) {
        const notification = buildNotification({
          message: "msp.reportDetailsUpdated",
          type: "success",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 1000,
          },
        });
      } else {
        const notification = buildNotification({
          message: "msp.reportDetailsCreated",
          type: "success",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 1000,
          },
        });
      }
      yield put(saveReportDetailsDataCompleted());
    }
  } catch (error) {
    yield put(saveReportDetailsDataFailed());
  }
}
function* updateReportStatusSaga(action) {
  const url = "/Report/changereportstatus";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    ...action.formData,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(saveUpdatedReportStatus());
      const notification = buildNotification({
        message: "msp.reportStatusUpdated",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
      if (action.formData && action.formData.invoiceReportID) {
        const reportsList = yield select(selcteReportDetailsList());
        const updatedReportsList = reportsList
          ? [...reportsList].map((report) => {
              if (report.invoiceReportID === action.formData.invoiceReportID) {
                return { ...report, enabled: action.formData.enabled };
              }
              return report;
            })
          : [];
        yield put(fetchReportDetailsList(updatedReportsList));
      }
      if (action.updateSuccess) {
        yield action.updateSuccess();
      }
    }
  } catch (error) {
    console.log(error);
  }
}

// Report list
function* getReportDetailsListSaga(action) {
let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
let languageId = sessionDetails && sessionDetails.languageId;
  let url;
  url = `/Report/listreports?langKey=${languageId || "enGB"}`;

  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + "&" + data + "=" + action.data[data];
      }
    });
  yield put(fetchingReportDetailsList());
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      const updatedList =
        response && response.data.length > 0
          ? response.data.map((item) => ({
              ...item,
              firstOccurence: moment(item.firstOccurence).format("MM/DD/YYYY"),
            }))
          : [];
      yield put(fetchReportDetailsList(updatedList));
    } else if (response && response.status === 204) {
      yield put(fetchReportDetailsList([]));
      yield put(updateReportDetailsList([]));
    }

    yield put(fetchReportDetailsListCompleted());
  } catch (error) {
    yield put(fetchReportDetailsListCompleted());
    //action.payload.callback(false)
  }
}

function* getReportDetailsByIdSaga(action) {
  yield put(fetchingReportDetailsbyID());
  const url =
    parseInt(action.baseRoleId) !== 3
      ? `/Report/reportById?reportId=${action.invoiceReportID}&reportUserID=${action.reportUserID}`
      : `/Report/reportById?reportId=${action.invoiceReportID}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status) {
      yield put(fetchReportDetailsbyID(response.data));
      if (action.callback) {
        action.callback(response.data);
      }
      yield put(fetchReportDetailsbyIDCompleted());
    }
  } catch (error) {
    yield put(fetchReportDetailsbyIDCompleted());
  }
}

function* initReportConfigDeleteSaga(action) {
  const url = `/Report/DeleteReport`;
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    userId: action.userId,
    listOfIds: action.id,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    const notification = buildNotification({
      message: "msp.reportDetailsDeleted",
      type: "success",
    });
    if (response && response.status === 200) {
      const id = action.id.split(",").map((id) => parseInt(id));
      yield put(deleteSuccess(id));
      if (action.deleteSuccess) {
        action.deleteSuccess(response.status);
      }

      store.addNotification({
        ...notification,
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(deleteFailed());
  }
}

function* getUserListSaga(action) {
  yield put(fetchingUserList());

  let url = "/Users?approved=true&baseRoleID=3";
  let keys = action.data && Object.keys(action.data);
  keys &&
    keys.forEach((data) => {
      if (action.data[data]) {
        url = url + "&" + data + "=" + action.data[data];
      }
    });
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchUserList(response.data));
      //yield put(updateUserList(response.data))
      yield put(fetchUserListComplete());
    }
  } catch (err) {
    yield put(fetchUserListComplete());
  }
}
