import React from "react";
import SvgIcon from "./style";

const DownloadFile = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        xmlns="http://www.w3.org/2000/svg"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
      >
        <g transform="translate(-1181.000000, -252.000000)">
          <g transform="translate(32.000000, 192.000000)">
            <g transform="translate(1147.000000, 60.000000)">
              <path d="M16.9492188,3.82734373 L13.6726563,0.550781226 C13.3210938,0.199218725 12.8445313,-2.50000003e-08 12.3488281,-2.50000003e-08 L4.37460936,-2.50000003e-08 C3.33984373,0.00390622501 2.49999998,0.843749977 2.49999998,1.8785156 L2.49999998,18.1253906 C2.49999998,19.1601563 3.33984373,20 4.37460936,20 L15.6253906,20 C16.6601563,20 17.5,19.1601563 17.5,18.1253906 L17.5,5.15507811 C17.5,4.65937499 17.3007813,4.17890624 16.9492188,3.82734373 Z M12.5011719,1.27304685 C12.6105469,1.3003906 12.7082031,1.3550781 12.7902344,1.43710935 L16.0667969,4.71367186 C16.1488281,4.79570311 16.2035156,4.89335936 16.2308594,5.00273436 L12.5011719,5.00273436 L12.5011719,1.27304685 L12.5011719,1.27304685 Z M16.2503906,18.1253906 C16.2503906,18.4691406 15.9691406,18.7503906 15.6253906,18.7503906 L4.37460936,18.7503906 C4.03085936,18.7503906 3.74960936,18.4691406 3.74960936,18.1253906 L3.74960936,1.8785156 C3.74960936,1.5347656 4.03085936,1.24999998 4.37460936,1.24999998 L11.2511719,1.24999998 L11.2511719,5.31523436 C11.2511719,5.83476561 11.6691406,6.24999998 12.1886719,6.24999998 L16.2503906,6.24999998 L16.2503906,18.1253906 Z M10.625,8.4375 C10.625,8.26484375 10.4851563,8.125 10.3125,8.125 L9.6875,8.125 C9.51484375,8.125 9.375,8.26484375 9.375,8.4375 L9.375,11.8757813 L7.31796874,11.8757813 C6.88828124,11.8757813 6.51367187,12.1261719 6.34140624,12.5289063 C6.16562499,12.9398438 6.24843749,13.4125 6.55390624,13.7324219 L9.21484375,16.5363281 C9.4234375,16.7546875 9.70195312,16.8757813 9.99960937,16.8757813 C10.2972656,16.8757813 10.5757813,16.7550781 10.784375,16.5359375 L13.4453125,13.7332031 C13.7503906,13.4128906 13.8335938,12.9398438 13.6578125,12.5289063 C13.4855469,12.1261719 13.1109375,11.8757813 12.68125,11.8757813 L10.625,11.8757813 L10.625,8.4375 Z M12.2984375,13.1257813 L10,15.5476563 L7.70156249,13.1257813 L12.2984375,13.1257813 Z" />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default DownloadFile;
