import {
  INIT_LANGUAGE_FETCH,
  LANGUAGE_FETCHING,
  LANGUAGE_FETCH,
  LANGUAGE_FETCH_COMPLETE,
  INIT_SUPPLIER_FIELD_FETCH,
  SUPPLIER_FIELD_FETCHING,
  SUPPLIER_FIELD_FETCH,
  SUPPLIER_FIELD_FETCH_COMPLETE,
  INIT_COUNTRY_FETCH,
  COUNTRY_FETCHING,
  COUNTRY_FETCH,
  COUNTRY_FETCH_COMPLETE,
  INIT_SUBMISSION_FIELDS_FETCH,
  SUBMISSION_FIELDS_FETCHING,
  SUBMISSION_FIELDS_FETCH,
  SUBMISSION_FIELDS_FETCH_COMPLETE,
  INIT_TIME_ZONES_FETCH,
  TIME_ZONES_FETCHING,
  TIME_ZONES_FETCH,
  TIME_ZONES_FETCH_COMPLETE,
  INIT_SUBMISSION_STAUS_FETCH,
  SUBMISSION_STATUS_FETCHING,
  SUBMISSION_STATUS_FETCH,
  SUBMISSION_STATUS_FETCH_COMPLETE,
  INIT_DOWNLOAD,
  DOWNLOADING,
  DOWNLOAD,
  DOWNLOAD_COMPLETE,
  INIT_PREVIEW,
  PREVIEWING,
  PREVIEW,
  PREVIEW_COMPLETE,
  PREVIEW_FAILED,
  INIT_CLIENT_PLACEMENT_CONFIG_STATUS_FETCH,
  CLIENT_PLACEMENT_CONFIG_FETCHING,
  CLIENT_PLACEMENT_CONFIG_FETCH,
  CLIENT_PLACEMENT_CONFIG_FETCH_COMPLETE,
  INIT_VALIDATION_TYPES_FETCH,
  VALIDATION_TYPES_FETCHING,
  VALIDATION_TYPES_FETCH,
  VALIDATION_TYPES_FETCHED,
  INIT_REGEX_FETCH,
  REGEX_FETCHING,
  REGEX_FETCH,
  REGEX_FETCHED,
  CURRENCY_FETCH_COMPLETE,
  CURRENCY_FETCH,
  CURRENCY_FETCHING,
  INIT_CURRENCY_FETCH,
  DOWNLOAD_FAILED,
  INIT_SET_SELECTED_CURRENCY,
  INIT_SET_DEFAULT_CURRENCY,
  SETTING_DEFAULT_CURRENCY,
  SET_DEFAULT_CURRENCY_COMPLETED,
  INIT_SUPPLIER_LOOKUP_LIST,
  FETCH_SUPPLIER_LOOKUP_LIST,
  FETCH_SUPPLIER_LOOKUP_LIST_COMPLETED,
  FETCHING_SUPPLIER_LOOKUP_LIST,
  INIT_FETCH_ACTIVE_COLUMNS,
  FETCHING_ACTIVE_COLUMNS,
  FETCH_ACTIVE_COLUMNS,
  ACTIVE_COLUMNS_FETCH_COMPLETE,
  ACTIVE_COLUMNS_FETCH_FAILED,
  INIT_SAVE_ACTIVE_COLUMNS,
  SAVING_ACTIVE_COLUMNS,
  ACTIVE_COLUMNS_SAVED,
  ACTIVE_COLUMNS_SAVE_FAILED,
  IS_GLOBAL_SEARCH_ACTIVE,
  INIT_GET_SELECTED_SUPPLIER_LIST,
  INIT_INVOICE_STATUS_FETCH,
  INVOICE_STATUS_FETCHING,
  INVOICE_STATUS_FETCH,
  INVOICE_STATUS_FETCH_COMPLETE,
  INIT_GLOBAL_SEARCH,
  SEARCH_RECORDS_FETCH,
  SEARCH_RECORDS_FETCHING,
  SEARCH_RECORDS_FETCHED,
  RESET_SEARCH,
} from "./actionTypes";

export const initLanguageFetch = () => {
  return {
    type: INIT_LANGUAGE_FETCH,
  };
};

export const languageFetching = () => {
  return {
    type: LANGUAGE_FETCHING,
  };
};
export const fetchLanguages = (data) => {
  return {
    type: LANGUAGE_FETCH,
    data: data,
  };
};
export const languageFetchComplete = () => {
  return {
    type: LANGUAGE_FETCH_COMPLETE,
  };
};

export const initSupplierFieldsFetch = () => {
  return {
    type: INIT_SUPPLIER_FIELD_FETCH,
  };
};

export const supplierFieldFetching = () => {
  return {
    type: SUPPLIER_FIELD_FETCHING,
  };
};
export const fetchSupplierFields = (data) => {
  return {
    type: SUPPLIER_FIELD_FETCH,
    data: data,
  };
};
export const supplierFieldFetchComplete = () => {
  return {
    type: SUPPLIER_FIELD_FETCH_COMPLETE,
  };
};

export const initCountryFetch = () => {
  return {
    type: INIT_COUNTRY_FETCH,
  };
};

export const countryFetching = () => {
  return {
    type: COUNTRY_FETCHING,
  };
};
export const fetchCountry = (data) => {
  return {
    type: COUNTRY_FETCH,
    data: data,
  };
};
export const countryFetchComplete = () => {
  return {
    type: COUNTRY_FETCH_COMPLETE,
  };
};
export const initSubmissionFieldsFetch = () => {
  return {
    type: INIT_SUBMISSION_FIELDS_FETCH,
  };
};

export const submissionFieldsFetching = () => {
  return {
    type: SUBMISSION_FIELDS_FETCHING,
  };
};
export const fetchSubmissionFields = (data) => {
  return {
    type: SUBMISSION_FIELDS_FETCH,
    data: data,
  };
};
export const submissionFieldsFetchComplete = () => {
  return {
    type: SUBMISSION_FIELDS_FETCH_COMPLETE,
  };
};

export const initTimeZonesFetch = () => {
  return {
    type: INIT_TIME_ZONES_FETCH,
  };
};

export const timeZonesFetching = () => {
  return {
    type: TIME_ZONES_FETCHING,
  };
};

export const fetchTimeZones = (data) => {
  return {
    type: TIME_ZONES_FETCH,
    data: data,
  };
};

export const timeZonesFetchComplete = () => {
  return {
    type: TIME_ZONES_FETCH_COMPLETE,
  };
};

export const initSubmissionStatusFetch = () => {
  return {
    type: INIT_SUBMISSION_STAUS_FETCH,
  };
};

export const submissionStatusFetching = () => {
  return {
    type: SUBMISSION_STATUS_FETCHING,
  };
};

export const fetchSubmissionStatus = (data) => {
  return {
    type: SUBMISSION_STATUS_FETCH,
    data: data,
  };
};

export const submissionStatusFetchComplete = () => {
  return {
    type: SUBMISSION_STATUS_FETCH_COMPLETE,
  };
};

export const initDownload = (payload, callback) => {
  return {
    type: INIT_DOWNLOAD,
    payload,
    callback,
  };
};

export const downloading = () => {
  return {
    type: DOWNLOADING,
  };
};

export const download = (data) => {
  return {
    type: DOWNLOAD,
    data: data,
  };
};

export const downloadComplete = () => {
  return {
    type: DOWNLOAD_COMPLETE,
  };
};

export const downloadFailed = (data) => {
  return {
    type: DOWNLOAD_FAILED,
    data,
  };
};

export const initPreview = (payload, callback) => {
  return {
    type: INIT_PREVIEW,
    payload,
    callback,
  };
};

export const previewing = () => {
  return {
    type: PREVIEWING,
  };
};

export const preview = (data) => {
  return {
    type: PREVIEW,
    data: data,
  };
};

export const previewComplete = () => {
  return {
    type: PREVIEW_COMPLETE,
  };
};

export const previewFailed = (data) => {
  return {
    type: PREVIEW_FAILED,
    data,
  };
};

export const initClientPlacementConfigStatusFetch = () => {
  return {
    type: INIT_CLIENT_PLACEMENT_CONFIG_STATUS_FETCH,
  };
};
export const clientPlacementConfigFetching = () => {
  return {
    type: CLIENT_PLACEMENT_CONFIG_FETCHING,
  };
};
export const clientPlacementConfigFetch = (data) => {
  return {
    type: CLIENT_PLACEMENT_CONFIG_FETCH,
    data,
  };
};
export const clientPlacementConfigFetchComplete = () => {
  return {
    type: CLIENT_PLACEMENT_CONFIG_FETCH_COMPLETE,
  };
};

export const initValidationTypeFetch = () => {
  return {
    type: INIT_VALIDATION_TYPES_FETCH,
  };
};

export const validationTypeFetching = () => {
  return {
    type: VALIDATION_TYPES_FETCHING,
  };
};
export const validationTypeFetch = (data) => {
  return {
    type: VALIDATION_TYPES_FETCH,
    data: data,
  };
};
export const validationTypeFetched = () => {
  return {
    type: VALIDATION_TYPES_FETCHED,
  };
};

export const initRegexFetch = () => {
  return {
    type: INIT_REGEX_FETCH,
  };
};

export const regexFetching = () => {
  return {
    type: REGEX_FETCHING,
  };
};
export const regexFetch = (data) => {
  return {
    type: REGEX_FETCH,
    data: data,
  };
};
export const regexFetched = () => {
  return {
    type: REGEX_FETCHED,
  };
};

export const initCurrencyFetch = () => {
  return {
    type: INIT_CURRENCY_FETCH,
  };
};

export const currencyFetching = () => {
  return {
    type: CURRENCY_FETCHING,
  };
};
export const currencyFetch = (data) => {
  return {
    type: CURRENCY_FETCH,
    data: data,
  };
};
export const currencyFetchCompleted = () => {
  return {
    type: CURRENCY_FETCH_COMPLETE,
  };
};

export const initSetSelectedCurrency = (data) => {
  return {
    type: INIT_SET_SELECTED_CURRENCY,
    data,
  };
};
export const initSetDefaultCurrency = (data) => {
  return {
    type: INIT_SET_DEFAULT_CURRENCY,
    data,
  };
};
export const settingDefaultCurrency = () => {
  return {
    type: SETTING_DEFAULT_CURRENCY,
  };
};
export const setDefaultCurrencyCompleted = () => {
  return {
    type: SET_DEFAULT_CURRENCY_COMPLETED,
  };
};
export const initSupplierLookupList = (data) => {
  return {
    type: INIT_SUPPLIER_LOOKUP_LIST,
    data,
  };
};
export const fetchSupplierLookupList = (data) => {
  return {
    type: FETCH_SUPPLIER_LOOKUP_LIST,
    data,
  };
};
export const fetchSupplierLookupListComplete = () => {
  return {
    type: FETCH_SUPPLIER_LOOKUP_LIST_COMPLETED,
  };
};
export const fetchingSupplierLookupList = () => {
  return {
    type: FETCHING_SUPPLIER_LOOKUP_LIST,
  };
};

export const initFetchActiveColumns = (payload) => {
  return {
    type: INIT_FETCH_ACTIVE_COLUMNS,
    payload,
  };
};
export const fetchingActiveColumns = () => {
  return {
    type: FETCHING_ACTIVE_COLUMNS,
  };
};
export const fetchActiveColumns = (data) => {
  return {
    type: FETCH_ACTIVE_COLUMNS,
    data,
  };
};
export const activeColumnsFetchingCompleted = () => {
  return {
    type: ACTIVE_COLUMNS_FETCH_COMPLETE,
  };
};
export const activeColumnsFetchFailed = (data) => {
  return {
    type: ACTIVE_COLUMNS_FETCH_FAILED,
    data,
  };
};

export const initSaveActiveColumns = (payload, callback) => {
  return {
    type: INIT_SAVE_ACTIVE_COLUMNS,
    payload,
    callback,
  };
};
export const savingActiveColumns = () => {
  return {
    type: SAVING_ACTIVE_COLUMNS,
  };
};

export const activeColumnsSavingCompleted = () => {
  return {
    type: ACTIVE_COLUMNS_SAVED,
  };
};
export const activeColumnsSaveFailed = (data) => {
  return {
    type: ACTIVE_COLUMNS_SAVE_FAILED,
    data,
  };
};

export const isGlobalSearchActive = (bool) => {
  return {
    type: IS_GLOBAL_SEARCH_ACTIVE,
    bool,
  };
};

export const initGetSelectedSupplierList = (payload, callback) => {
  return {
    type: INIT_GET_SELECTED_SUPPLIER_LIST,
    payload,
    callback,
  };
};

export const initInvoiceStatusFetch = () => {
  return {
    type: INIT_INVOICE_STATUS_FETCH,
  };
};

export const invoiceStatusFetching = () => {
  return {
    type: INVOICE_STATUS_FETCHING,
  };
};
export const invoiceStatusFetch = (data) => {
  return {
    type: INVOICE_STATUS_FETCH,
    data: data,
  };
};
export const invoiceStatusFetchCompleted = () => {
  return {
    type: INVOICE_STATUS_FETCH_COMPLETE,
  };
};
export const initGlobalSearch = (payload) => {
  return {
    type: INIT_GLOBAL_SEARCH,
    payload,
  };
};

export const resetGlobalSearch = () => {
  return {
    type: RESET_SEARCH,
  };
};

export const fetchingSearchRecords = () => {
  return {
    type: SEARCH_RECORDS_FETCHING,
  };
};
export const fetchSearchRecords = (data) => {
  return {
    type: SEARCH_RECORDS_FETCH,
    data: data,
  };
};
export const searchRecordsFetchingComplete = () => {
  return {
    type: SEARCH_RECORDS_FETCHED,
  };
};
