import { getMiscellaneousExports } from "./miscallaneousExports";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import { load, loadMessages } from "@progress/kendo-react-intl";
import { getTranslationFile } from "./index";

export const getLocalization = (languageId, locale) => {
  const { numbers, currencies, caGregorian, dateFields, timeZoneNames } =
    getMiscellaneousExports[locale] || {};
  load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
  );
  loadMessages(getTranslationFile[languageId], languageId);
};
