import "./css/kendoGridStyles.css";
import "assets/css/font-awesome.css";

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { GridColumn, Grid, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { process } from "@progress/kendo-data-query";
import { useTranslation } from "react-i18next";

import { ColumnMenu } from "./columnMenu";
import { getLocale } from "../../helper";
import Loader from "./Loader";

import { getLocalization } from "./config/translation/localization";
import { storage } from "config/storage";
import { getSelectableRow } from "./config/selectableRows";
import { maxPageSize } from "config/index";
import { getGridCols, getGridData } from "./config/index";
import { DatePickerPropsContext } from '@progress/kendo-react-dateinputs';

const Datatable = (props) => {
  const [dataState, setDataState] = useState({
    take: maxPageSize,
    skip: 0,
    sort: [],
  });

  const { t } = useTranslation();

  const {
    columns = [],
    data = [],
    loading,
    ssr,
    // dataItemKey,
    customPaginationComponent,
    multiple,
    customClass,
    selectableRows,
    detailComponent,
    // selectedRows = [],
    total: totalDataCount,
    skip = 0,
    take = maxPageSize,
    resetPage,
    expandChange,
    onItemChange,
  } = props;

  /** skip and resetPage both param is required for now as for SSR module skip value is stored 
   if we can handle skip and take from every module we can omit resetPage */
  useEffect(() => {
    setDataState((prevState) => {
      return { ...prevState, skip: resetPage ? 0 : skip };
    });
  }, [resetPage, skip]);
  /** for maintaining each module item per page count for multiple module
   using single datatable component as source */
  useEffect(() => {
    setDataState((prevState) => {
      return { ...prevState, take };
    });
  }, [take]);
  const onFilterChange = (event) => {
    const { filter } = event;
    setDataState({ ...dataState, filter, skip: 0 });
  };
  const onPageChange = (event) => {
    setDataState({ ...dataState, ...event.page });
    props.onPageChange && props.onPageChange({ ...event.page });
  };
  const onSortChange = (event) => {
    setDataState({ ...dataState, sort: [...event.sort] });
  };
  const dataStateChange = (event) => {
    // ssr
    setDataState({ ...event.dataState });
    props.onPageChange(event.dataState);
  };
  const onSelectionChange = (event) => {
    let checked = event.target.checked;
    if (selectableRows !== "single")
      checked = event.syntheticEvent.target.checked;

    const { dataItem } = event;

    props.onSelectionChange &&
      props.onSelectionChange({ ...dataItem, selected: checked }, checked);
  };
  const onHeaderSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    props.onHeaderSelectionChange && props.onHeaderSelectionChange(checked);
  };

  const defaultFilterOperators = {
    text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
    numeric: [{ text: "grid.filterEqOperator", operator: "eq" }],
    date: [
      { text: "grid.filterEqOperator", operator: "eq" },
      { text: "grid.filterLteOperator", operator: "lte" },
      { text: "grid.filterGteOperator", operator: "gte" },
    ],
    boolean: [{ text: "grid.filterEqOperator", operator: "eq" }],
  };

  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const { languageId } = sessionDetails || {};
  const locale = getLocale(languageId);
  getLocalization(languageId, locale);

  let gridData = data ? [...data] : [];
  let gridCols = columns ? [...columns] : [];

  let onPageChangeHandler = {
    onPageChange: onPageChange,
  };

  let { gridData: formattedData, dateCols } = getGridData(gridData);
  const ds = { ...dataState };
  delete ds.take;
  delete ds.skip;
  let result = ssr
    ? process(formattedData.slice(0), ds)
    : process(formattedData.slice(0), dataState);

  gridCols = getGridCols(gridCols, dateCols);
  const selectableRow = getSelectableRow(
    props,
    selectableRows,
    onSelectionChange
  );
  const updatedCols = selectableRow
    ? [{ ...selectableRow }, ...gridCols]
    : [...gridCols];
  if (ssr) {
    onPageChangeHandler = {
      onDataStateChange: dataStateChange,
    };
  }
  const headerSelectionValue = ssr
    ? props.headerSelectionValue
    : formattedData.length > 0 &&
      formattedData
        .filter((item) => !item.disableSelect) // filtering selection disabled items
        .findIndex((item) => !item.selected) === -1;

        const datePickerProps = React.useCallback(
          (datePickerProps) => ({
            ...datePickerProps,
            format: 'MM/dd/yyyy',
          }),
          []
        );      
  return (
    <Fragment>
      {loading && <Loader />}
      <LocalizationProvider language={languageId}>
        <IntlProvider locale={locale}>
        <DatePickerPropsContext.Provider value={datePickerProps}>
          <Grid
            style={{
              height: "auto",
              width: "100%",
              margin: "1rem auto",
              textAlign: "end",
              cursor: "pointer",
              border: 0,
              borderWidth: "0 0",
              paddingRight: "1rem",
            }}
            data={result}
            total={dataState.filter ? null : totalDataCount}
            {...dataState}
            {...onPageChangeHandler}
            pager={customPaginationComponent}
            sortable={{
              mode: multiple ? "multiple" : "single",
            }}
            onSortChange={onSortChange}
            resizable
            reorderable={false}
            scrollable="scrollable"
            pageable={
              data.length > 0
                ? { buttonCount: 4, pageSizes: [10, 20, 50, 100] }
                : null
            }
            filterOperators={defaultFilterOperators}
            onFilterChange={onFilterChange}
            selectedField={
              selectableRows && selectableRows !== "single" && "selected"
            }
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            onRowClick={props.onRowClick}
            expandField={"expanded"}
            detail={detailComponent}
            className={customClass}
            onExpandChange={expandChange}
            onItemChange={onItemChange}
            editField="inEdit"
          >
            {updatedCols &&
              updatedCols.length > 0 &&
              updatedCols.map((column, i) => {
                let width =
                  column.selector === "selected" || column.selector === "radio"
                    ? "50px"
                    : "100px";
                if (column.width) width = column.width;
                return column.omit ? null : (
                  <GridColumn
                    field={column.selector}
                    title={
                      typeof column.name === "string"
                        ? t(column.name)
                        : column.name
                    }
                    filter={column.filterType ? column.filterType : "text"}
                    columnMenu={column.filterable ? ColumnMenu : null}
                    filterable={column.filterable}
                    filterCell={column.filterCell}
                    width={width}
                    cell={column.cell}
                    key={i}
                    format={column.format}
                    footerCell={column.footerCell}
                    headerSelectionValue={headerSelectionValue}
                    sortable={column.sortable}
                  />
                );
              })}
            <GridNoRecords>
              {loading ? t("msp.loadingData") : t("msp.noRecordsAvailable")}
            </GridNoRecords>
          </Grid>
          </DatePickerPropsContext.Provider>
        </IntlProvider>
      </LocalizationProvider>
    </Fragment>
  );
};

Datatable.propTypes = {
  columns: PropTypes.array,
  customClass: PropTypes.string,
  customPaginationComponent: PropTypes.any,
  data: PropTypes.array,
  dataItemKey: PropTypes.string,
  detailComponent: PropTypes.any,
  headerSelectionValue: PropTypes.bool,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  onHeaderSelectionChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowClick: PropTypes.func,
  onSelectionChange: PropTypes.func,
  resetPage: PropTypes.any,
  selectableRows: PropTypes.string,
  selectedRows: PropTypes.array,
  ssr: PropTypes.bool,
  take: PropTypes.number,
  total: PropTypes.number,
  skip: PropTypes.number,
};

export default Datatable;
