import PropTypes from "prop-types";
import React from "react";
import SiteTitle from "./style";

const Logo = (props) => {
  return (
    <SiteTitle to="/">
      <h1 tabIndex="-1">My Supplier Portal</h1>
      {props.logo ? (
        <img
          tabIndex="-1"
          src={`data:image/*;base64, ${props.logo}`}
          alt="Client logo"
        />
      ) : null}
    </SiteTitle>
  );
};

Logo.propTypes = {
  logo: PropTypes.string,
};

export default Logo;
