import { call, put, all, takeLatest } from "redux-saga/effects";
import { storage } from "config/storage";
import axios from "../../../config/axiosCall";
import { buildNotification } from "config/notification";
import { store } from "react-notifications-component";
import {
  getRequestDetails,
  postRequestDetails,
} from "../../../config/requestHeaders";
import {
  INIT_FORGOT_PASSWORD_EMAIL,
  INIT_CHECK_USER_EXIST,
  INIT_LIST_SECURITY_QUESTION,
  INIT_SUBMIT_QNA,
  INIT_SUBMIT_RESET_PASSWORD,
  GET_USER_DETAILS,
  GET_CLIENT_LANGUAGES,
  INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL,
  INIT_FORGOT_PASS_MFA_OTP,
} from "./actionTypes";
import {
  forgotPasswordEmailSuccess,
  forgotPasswordEmailFailure,
  fetchCheckUserExistCompleted,
  fetchingListSecurityQuestions,
  fetchedListSecurityQuestions,
  fetchListSecurityQuestionsCompleted,
  submittingQnA,
  submittingQnASuccess,
  submittingQnAFailed,
  submittingResetPassword,
  submittingResetPasswordSuccess,
  submittingResetPasswordFailed,
  fetchingUserDetail,
  fetchingUserDetailSuccess,
  fetchingUserDetailFailed,
  submitPasswordByEmailSuccess,
  submitPasswordByEmailFailed,
  securityOTPSuccess,
  securityOTPFailed,
} from "./actions";
export default function* rootLoginSaga() {
  yield all([
    takeLatest(INIT_FORGOT_PASSWORD_EMAIL, initForgotPasswordEmailSaga),
    takeLatest(INIT_CHECK_USER_EXIST, initCheckUserExistSaga),
    takeLatest(INIT_LIST_SECURITY_QUESTION, initListSecurityQuestionsSaga),
    takeLatest(INIT_SUBMIT_QNA, initSubmitQnASaga),
    takeLatest(INIT_SUBMIT_RESET_PASSWORD, initSubmitResetPasswordSaga),
    takeLatest(GET_USER_DETAILS, getUserDetailSaga),
    takeLatest(GET_CLIENT_LANGUAGES, getClientLanguagesSaga),
    takeLatest(INIT_SUBMIT_RESET_PASSWORD_BY_EMAIL, submitPasswordByEmailSaga),
    takeLatest(INIT_FORGOT_PASS_MFA_OTP, initQnAOTPSaga),
  ]);
}

function* initForgotPasswordEmailSaga(action) {
  const url = "/Login/forgotPassword";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = JSON.stringify(action.data));
  try {
    //const response = yield call(axios, url, requestDetails);
    yield call(axios, url, requestDetails);
    yield put(forgotPasswordEmailSuccess());
    action.callback("success");
  } catch (err) {
    action.callback("failed");
    yield put(forgotPasswordEmailFailure());
  }
}

function* initCheckUserExistSaga(action) {
  const url = `/Users/UserAlreadyExists?userName=${action.userName}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      action.callback(response.data);
      yield put(fetchCheckUserExistCompleted());
    } else {
      yield put(fetchCheckUserExistCompleted());
    }
  } catch (error) {
    yield put(fetchCheckUserExistCompleted());
  }
}

function* initListSecurityQuestionsSaga(action) {
  const sessionDetails = yield JSON.parse(
    storage.getItem("sessionDetails")
  ) || {};
  let { languageId } = yield sessionDetails;
  if (!languageId)
  {
    languageId = yield storage.getItem("languageId") || 'enGB';    
  }
  yield put(fetchingListSecurityQuestions());
  const url = `/Login/ListSecurityQuestion?langID=${languageId}`;
  const requestDetails = { ...getRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchedListSecurityQuestions(response.data));
    }
    yield put(fetchListSecurityQuestionsCompleted());
  } catch (error) {
    yield put(fetchListSecurityQuestionsCompleted("Failed to Fetch Data"));
  }
}

function* initQnAOTPSaga(action) {
  try {
    let url = `/Login/ValidateSecurityAnswerSendMFAOTP`;
    if (action.payload.token) url += `?token=${action.payload.token}`;
    const requestDetails = {
      ...postRequestDetails,
    };

    requestDetails.data = yield { ...action.payload, token: undefined };
    yield call(axios, url, requestDetails);

    yield put(securityOTPSuccess());
  } catch (error) {
    yield put(securityOTPFailed());
  }
}

function* initSubmitQnASaga(action) {
  yield put(submittingQnA());

  try {
    let url = `/Login/ValidateSecurityAnswer`;
    if (action.data.token) url += `?token=${action.data.token}`;
    const requestDetails = {
      ...postRequestDetails,
    };

    requestDetails.data = yield { ...action.data, token: undefined };
    const response = yield call(axios, url, requestDetails);

    if (response) {
      yield put(submittingQnASuccess());
      action.callback("success");
    } else {
      yield put(submittingQnAFailed());
      action.callback("error");
    }
  } catch (error) {
    yield put(submittingQnAFailed());
    action.callback("error");
  }
}

function* initSubmitResetPasswordSaga(action) {
  yield put(submittingResetPassword());
  let url = "/Login/UpdatePasswordSecurityAnswer";
  if (action.data.qaToken) url += `?token=${action.data.qaToken}`;
  const requestDetails = { ...postRequestDetails };
  requestDetails.data = yield {
    password: btoa(action.data.password),
    confirmPassword: btoa(action.data.confirmPassword),
    userName: action.data.userName,
    clientName: action.data.clientName,
  };
  try {
    const response = yield call(axios, url, requestDetails);

    if (response.status === 202) {
      yield put(submittingResetPasswordSuccess());
      action.callback("success");
    } else {
      action.callback("failed");
      yield put(submittingResetPasswordFailed());
    }
  } catch (error) {
    action.callback("failed");

    yield put(submittingResetPasswordFailed());
  }
}
function* getClientLanguagesSaga(action) {
    const url = `/ClientValidation?clientName=${action.data}&time=${new Date().getTime()}`;

    try{
      const response = yield call(axios, url);
      if (response && response.status === 200 && response.data && response.data.languages){        
          const languages = response.data.languages;
          storage.setItem("languages", JSON.stringify(languages));
          const event = new Event('storageLanguagesChanged');
          window.dispatchEvent(event);
        }
      else{
        const notification = buildNotification({
          message: "Unable to load languages",
          type: "warning",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 2000,
          },
        });
      }      
    }
    catch (error) {
      const notification = buildNotification({
        message: "Unable to load languages",
        type: "warning",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 2000,
        },
      });
    }
    
}
function* getUserDetailSaga(action) {
  yield put(fetchingUserDetail());
  const url = "/Login/resetPassword?token=" + action.token;
  const requestDetails = { ...postRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchingUserDetailSuccess());
      action.callback("success", response.data.userName, response.data.clientName);
    } else {
      // action.callback("failed");
      yield put(fetchingUserDetailFailed());
    }
  } catch (error) {
    yield put(fetchingUserDetailFailed());
    action.callback("failed");
  }
}

function* submitPasswordByEmailSaga(action) {
  const url = "/Login/UpdatePasswordOTP";
  const requestDetails = { ...postRequestDetails };
  yield (requestDetails.data = {
    password: btoa(action.data.password),
    confirmPassword: btoa(action.data.confirmPassword),
    userName: action.data.userName,
    token: action.data.token,
  });
  try {
    const response = yield call(axios, url, requestDetails);
    if (response.status === 202) {
      yield put(submitPasswordByEmailSuccess());

      action.callback(response);
    } else {
      action.callback("failed");
      yield put(submitPasswordByEmailFailed());
    }
  } catch (err) {
    yield put(submitPasswordByEmailFailed());

    action.callback("failed");
  }
}
