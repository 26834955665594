import {
  INIT_UPDATE_PASSWORD_ON_LOGIN,
  UPDATE_PASSWORD_ON_LOGIN_SUCCESS,
  UPDATE_PASSWORD_ON_LOGIN_FAILED,
} from "./actionTypes";

export function initUpdatePasswordOnLogin(payload, callBackFunction) {
  return {
    type: INIT_UPDATE_PASSWORD_ON_LOGIN,
    payload,
    callBackFunction,
  };
}

export function updatePasswordOnLoginSuccess() {
  return {
    type: UPDATE_PASSWORD_ON_LOGIN_SUCCESS,
  };
}
export function updatePasswordOnLoginFailed() {
  return {
    type: UPDATE_PASSWORD_ON_LOGIN_FAILED,
  };
}
