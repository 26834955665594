import React from "react";
import SvgIcon from "./style";

const EyeOpen = (props) => {
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 12"
    >
      <g>
        <g transform="translate(0.000000, -2.000000)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#637381"
            d="M8,4.9C7.7,4.9,7.4,4.9,7.1,5c0.1,0.2,0.2,0.5,0.2,0.8
                c0,0.9-0.7,1.6-1.6,1.6C5.5,7.3,5.3,7.3,5,7.1c-0.4,1.3,0.1,2.7,1.2,3.4s2.6,0.7,3.6-0.1S11.4,8.3,11,7S9.3,4.9,8,4.9L8,4.9z
                 M15.9,7.6C14.4,4.7,11.4,2.7,8,2.7s-6.4,2-7.9,4.9C0,7.8,0,8.2,0.1,8.4c1.5,2.9,4.5,4.9,7.9,4.9s6.4-2,7.9-4.9
                C16,8.2,16,7.8,15.9,7.6z M8,12c-2.7,0-5.3-1.5-6.6-4C2.7,5.5,5.3,4,8,4s5.3,1.5,6.6,4C13.3,10.5,10.7,12,8,12z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default EyeOpen;
