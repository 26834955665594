import { fromJS } from "immutable";

import {
  FETCHING_PAYMENT_FORECAST_DETAILS,
  FETCHING_REPORTS_DETAILS,
  FETCH_PAYMENT_FORECAST_DETAILS,
  FETCH_REPORT_DETAILS,
  PAYMENT_FORECAST_DETAILS_FETCH_COMPLETE,
  REPORT_DETAILS_FETCH_COMPLETE,
  FETCH_SUMMARY_DATA_COMPLETE,
  SET_SUMMARY_DATA,
  FETCH_DRILLDOWN_DATA_COMPLETE,
  SET_DRILLDOWN_DATA,
} from "./actionTypes";

export const initialState = fromJS({
  fetchReportDetails: [],
  fethcingReportsDetails: false,
  forecastDetails: [],
  fetchingForecastDetails: false,
  summaryData: {},
  fetchingSummaryDataComplete: false,
  drilldownData: [],
  fetchingDrilldownDataComplete: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_REPORTS_DETAILS: {
      return state.set("fethcingReportsDetails", true);
    }
    case FETCH_REPORT_DETAILS: {
      return state
        .set("fetchReportDetails", action.data)
        .set("fethcingReportsDetails", false);
    }
    case REPORT_DETAILS_FETCH_COMPLETE: {
      return state.set("fethcingReportsDetails", false);
    }
    case FETCHING_PAYMENT_FORECAST_DETAILS: {
      return state.set("fetchingForecastDetails", true);
    }
    case FETCH_PAYMENT_FORECAST_DETAILS: {
      return state
        .set("forecastDetails", action.data)
        .set("fetchingForecastDetails", false);
    }
    case PAYMENT_FORECAST_DETAILS_FETCH_COMPLETE: {
      return state.set("fetchingForecastDetails", false);
    }
    case FETCH_SUMMARY_DATA_COMPLETE: return state.set("fetchingSummaryDataComplete", action.boolean)
    case SET_SUMMARY_DATA: return state.set("summaryData", action.data)
    case FETCH_DRILLDOWN_DATA_COMPLETE: return state.set("fetchingDrilldownDataComplete", action.boolean)
    case SET_DRILLDOWN_DATA: return state.set("drilldownData", action.data)
    default:
      return state;
  }
};

export default reducer;
