import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SecondaryBtn } from "..";
import { Centered, CloseWrapper, ContentWrapper } from "./style";
import Overlay from "components/Overlay/";

const Popup = (props) => {
  const { t } = useTranslation();
  const closePopup = (e) => props.onClose && props.onClose(e);

  const useOverlayClickAlerter = (ref) => {
    useEffect(() => {
      if (!props.preventOutterClickClose) {
        const handleClickOutside = (event) => {
          if (ref.current && !ref.current.contains(event.target)) {
            closePopup(event);
          }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    });
  };
  const wrapperRef = useRef(null);
  useOverlayClickAlerter(wrapperRef);

  return props.show ? (
    <>
      <Overlay style={{ zIndex: "98" }}>
        <Centered>
          <CloseWrapper>
            <SecondaryBtn sm onClick={closePopup}>
              {t("msp.close")}
            </SecondaryBtn>
          </CloseWrapper>
          <ContentWrapper>{props.children}</ContentWrapper>
        </Centered>
      </Overlay>
    </>
  ) : null;
};

Popup.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  preventOutterClickClose: PropTypes.any,
  show: PropTypes.any,
};

export default Popup;
