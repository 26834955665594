import { buildNotification } from "../../../config/notification";

export const errorMessages = {
  listFetchError: buildNotification({
    message: "msp.fetchListdataError",
    type: "danger",
  }),
  helpdeskDetailsByIDFailed: buildNotification({
    message: "msp.fetchQueryDetailsError",
    type: "danger",
  }),
  queryTypeListFetchError: buildNotification({
    message: "msp.fetchQueryTypeError",
    type: "danger",
  }),
  getHelpdeskQueryFieldsFailed: buildNotification({
    message: "msp.fetchQueryFieldError",
    type: "danger",
  }),

  fetchCommentsFailed: buildNotification({
    message: "msp.getCommentError",
    type: "danger",
  }),
  saveCommentFailed: buildNotification({
    message: "msp.saveCommentError",
    type: "danger",
  }),
  attachmentAddingFailed: buildNotification({
    message: "msp.saveAttachmentError",
    type: "danger",
  }),
  isSubmitRequestFailed: buildNotification({
    message: "msp.submitRequestError",
    type: "danger",
  }),
  isDownloadAttachmentFailed: buildNotification({
    message: "msp.downloadAttachmentError",
    type: "danger",
  }),
  noRecordFound: buildNotification({
    message: "msp.noDatafound",
    type: "warning",
  }),
  noResultFound: buildNotification({
    message: "msp.noResultFound",
    type: "danger",
  }),
};

export const errorKeys = [
  "getHelpdeskQueryFieldsFailed",
  "saveCommentFailed",
  "attachmentAddingFailed",
  "isSubmitRequestFailed",
  "isDownloadAttachmentFailed",
];

export const resetErrors = [
  {
    errorKey: "getHelpdeskQueryFieldsFailed",
    dispatchKey: "resetGetHelpdeskQueryFieldsFailed",
    resetAfterEveryRender: true,
  },
  {
    errorKey: "saveCommentFailed",
    dispatchKey: "resetSaveCommentFailed",
    resetAfterEveryRender: true,
  },
  {
    errorKey: "attachmentAddingFailed",
    dispatchKey: "resetAttachmentAddingFailed",
    resetAfterEveryRender: true,
  },
  {
    errorKey: "isSubmitRequestFailed",
    dispatchKey: "resetIsSubmitRequestFailed",
    resetAfterEveryRender: true,
  },
  {
    errorKey: "isDownloadAttachmentFailed",
    dispatchKey: "resetDownloadAttachmentFailed",
    resetAfterEveryRender: true,
  },
];
