import {
  call,
  put,
  all,
  takeLatest,
  takeEvery,
  delay,
} from "redux-saga/effects";

import { store } from "react-notifications-component";
import { default as axiosInstance } from "../../../config/axios";
import axios from "../../../config/axiosCall";
import { buildNotification } from "../../../config/notification";
import {
  postRequestDetails,
  putRequestDetails,
} from "../../../config/requestHeaders";
import { userLogoutTimeInSecs } from "../../../config";

import {
  authenticationSuccess,
  authenticationFailed,
  initLogout,
  logout,
  checkAuthTimeout,
  confirmingGDPRMsg,
  gdprMsgConfirmed,
  gdprMsgConfirmFailed,
  refreshToken,
  fetchingRefreshToken,
  initRefreshToken,
  fetchRefreshToken,
  refreshTokenFetchFailed,
  notYetConfigured,
  ssoLoginFailed,
  MFASuccess,
  MFAFailed,
} from "./actions";
import {
  INIT_AUTHENTICATE,
  INIT_LOGOUT,
  CHECK_AUTH_TIMEOUT,
  CHECK_AUTH_STATUS,
  INIT_CONFIRM_GDPR_MESSAGE,
  INIT_REFRESH_TOKEN,
  REFRESH_TOKEN,
  INIT_MFA_OTP,
} from "./actionTypes";
import { storage } from "../../../config/storage";

export default function* watchAuthentication() {
  yield all([
    takeLatest(INIT_MFA_OTP, initMFAOTPSaga),
    takeLatest(INIT_AUTHENTICATE, initAuthenticateSaga),
    takeEvery(CHECK_AUTH_STATUS, checkAuthStatusSaga),
    takeLatest(CHECK_AUTH_TIMEOUT, checkAuthTimeoutSaga),
    takeLatest(INIT_LOGOUT, logoutSaga),
    takeLatest(INIT_CONFIRM_GDPR_MESSAGE, initConfirmGDPRMsgSaga),
    takeLatest(INIT_REFRESH_TOKEN, initRefreshTokenSaga),
    takeLatest(REFRESH_TOKEN, getRefreshTokenSaga),
  ]);
}

function* initMFAOTPSaga(action) {
  const url = "/Login/sendLoginOTP";
  try {
    const requestDetails = { ...postRequestDetails };
    yield (requestDetails.data = {
      userName: btoa(action.payload.userName),
      password: btoa(action.payload.password),
      ClientName: btoa(action.payload.ClientName),
      ClientID: action.payload.ClientID,
    });
    yield call(axios, url, requestDetails);

    yield put(MFASuccess());
  } catch (e) {
    const { data } = e || {};
    if (data && data.messageCode === "MSP-LC-016") {
      yield put(notYetConfigured());
    }
    yield put(MFAFailed());
  }
}
function* initAuthenticateSaga(action) {
  let sessionDetails = {};
  try {
    const url =
      action.loginType === "ssoLogin"
        ? `/Login/ssologin`
        : `/Login/authenticate`;
    const requestDetails = { ...postRequestDetails };

    if (action.loginType === "ssoLogin") {
      const clientDetails =
        JSON.parse(sessionStorage.getItem("clientDetails")) || {};
      yield (requestDetails.headers = {
        "Content-Type": "application/json",
        "X-App-Info": clientDetails.token,
        clientName: btoa(clientDetails.name),
        isClientSSO: false,
      });
      yield (requestDetails.data = {
        token: action.msalAuthToken,
      });
    } else {
      yield (requestDetails.data = {
        userName: btoa(action.payload.userName),
        password: btoa(action.payload.password),
        ClientName: btoa(action.payload.ClientName),
        ClientID: action.payload.ClientID,
        otp: action.payload.otp,
      });
    }
    const response = yield call(axios, url, requestDetails);
    const user = response.data;
    let sessionStartsAt = new Date(new Date().getTime());
    // convert minute to millisec to new Date() obj
    if (user) {
      const tokenExpirationTimeInSecs =
        user.authenticationTokenExpiryMinutes * 60;
      let expirationDate = new Date(
        new Date().getTime() + tokenExpirationTimeInSecs * 1000
      );

      sessionDetails = {
        isAuthenticated: true,
        userName: user.userName,
        firstName: user.firstName,
        lastName: user.lastName,
        roleId: user.roleID,
        baseRoleId: user.baseRoleID,
        userId: user.userID,
        loginId: user.loginId,
        menus: user.menus,
        buttons: user.buttons,
        token: user.authenticationToken,
        primaryEmail: user.primaryEmail,
        languageId: user.languageID,
        lastPwdChangedOn: user.lastPwdChangedOn,
        passwordExpiryDuration: user.passwordExpiryDuration,
        suppliers: user.suppliers,
        encryptedUserName: user.encryptedUserName,
        displayGDPR: user.displayGDPR,
        loginGDPRLabel: user.loginGDPRLabel,
        gdprMessage: user.gdprMessage,
        applicationGDPRLabel: user.applicationGDPRLabel,
        applicationGDPRMessage: user.applicationGDPRMessage,
        maxReport: user.maxReport,
        getCurrencies: user.getCurrencies,
        currencyID: user.currencyID,
        currency: user.currency,
        sessionStartsAt,
        userLogoutTimeInSecs,
        authenticationTokenExpiryMinutes: user.authenticationTokenExpiryMinutes,
        tokenExpirationTimeInSecs,
        expirationDate,
      };
      storage.setItem("sessionDetails", JSON.stringify(sessionDetails));
      yield put(authenticationSuccess(sessionDetails));
      yield put(checkAuthTimeout(tokenExpirationTimeInSecs));
      yield put(initRefreshToken(tokenExpirationTimeInSecs - 60));
    } else {
      yield put(authenticationFailed("Username or Password is wrong"));
      if (action.loginType === "ssoLogin") {
        yield put(ssoLoginFailed(true));
      }
    }
  } catch (err) {
    const { data } = err || {};
    if (data && data.messageCode === "MSP-LC-016") {
      yield put(notYetConfigured());
    }
    storage.setItem("sessionDetails", JSON.stringify(sessionDetails));
    yield put(authenticationFailed(""));
    if (action.loginType === "ssoLogin") {
      yield put(ssoLoginFailed(true));
    }
  }
}

function* initConfirmGDPRMsgSaga(action) {
  yield put(confirmingGDPRMsg());
  const url = "/Users/ConfirmGDPRSettings";
  const requestDetails = { ...postRequestDetails };

  try {
    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 202) {
      yield put(gdprMsgConfirmed());
      const notification = buildNotification({
        message: "msp.GDPRConfirmed",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    yield put(gdprMsgConfirmFailed());
  }
}

function* checkAuthStatusSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("sessionDetails")) || {};
  const { token, expirationDate } = sessionDetails;
  if (!token) {
    yield put(initLogout());
  } else {
    const expirationTime = new Date(expirationDate);
    if (expirationTime < new Date()) {
      yield put(initLogout());
    } else {
      yield put(authenticationSuccess(sessionDetails));
      const timeInSecond =
        (expirationTime.getTime() - new Date().getTime()) / 1000;
      yield put(checkAuthTimeout(timeInSecond));
      yield put(initRefreshToken(timeInSecond - 60));
    }
  }
}

function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime * 1000); // converts to millisec
  yield put(initLogout());
}

function* logoutSaga(action) {
  const sessionDetails = yield sessionStorage.getItem("sessionDetails");

  try {
    let response = yield { status: false };
    if (sessionDetails) {
      const sessionObj = JSON.parse(sessionDetails);
      const url = `/Login/UpdateLogOut?userName=${btoa(sessionObj.userName)}&token=${sessionObj.token}`;
      const requestDetails = yield { ...putRequestDetails };

      response = yield call(axios, url, requestDetails);
      if (response.status === 200) {
        yield put(logout());
        yield storage.removeItem("sessionDetails");
        yield storage.removeItem("languageId", "");
        yield storage.removeItem("i18nextLng", "");
        if (action.callback) {
          yield action.callback();
        }
      }
    }
  } catch (e) {}
}

function* initRefreshTokenSaga(action) {
  yield delay(action.expirationTimeInSec * 1000);
  yield put(refreshToken());
}

function* getRefreshTokenSaga(action) {
  yield put(fetchingRefreshToken());
  const sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  const { tokenExpirationTimeInSecs } = sessionDetails;
  const oldRefreshToken = sessionDetails.token;
  const url = `/Login/refreshToken?token=${oldRefreshToken}`;
  const requestDetails = { ...postRequestDetails };
  try {
    const response = yield call(axios, url, requestDetails);

    if (response && response.status) {
      let expirationDate = new Date(
        new Date().getTime() + tokenExpirationTimeInSecs * 1000
      );
      let sessionStartsAt = new Date(new Date().getTime());
      const token = response.data;
      const updatedSessionDetails = {
        ...sessionDetails,
        token,
        expirationDate,
        sessionStartsAt,
      };
      sessionStorage.setItem(
        "sessionDetails",
        JSON.stringify(updatedSessionDetails)
      );

      yield put(fetchRefreshToken(updatedSessionDetails));
      yield put(checkAuthTimeout(tokenExpirationTimeInSecs));
      yield put(initRefreshToken(tokenExpirationTimeInSecs - 60));
      axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
    } else {
      yield put(refreshTokenFetchFailed());
    }
  } catch (error) {
    yield put(refreshTokenFetchFailed());
  }
}
