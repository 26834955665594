import React from "react";
import SvgIcon from "./style";
//stroke='rgb(33, 43, 54)'
const Phone = () => {
    return (
        <SvgIcon 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 512 512"
            >
            <path
            d="M18.92 351.2l108.5-46.52c12.78-5.531 27.77-1.801 36.45 8.98l44.09 
            53.82c69.25-34 125.5-90.31 159.5-159.5l-53.81-44.04c-10.75-8.781-14.41-23.69-8.974-36.47l46.51-108.5c6.094-13.91 21.1-21.52 
            35.79-18.11l100.8 23.25c14.25 3.25 24.22 15.8 24.22 30.46c0 252.3-205.2 457.5-457.5 
            457.5c-14.67 0-27.18-9.968-30.45-24.22l-23.25-100.8C-2.571 372.4 5.018 357.2 18.92 351.2z"/>
        </SvgIcon>
    );
};

export default Phone;