import { IconWrapper } from "components/index";
import styled from "styled-components";

export const CollapsedContainer = styled.div`
  min-width: 96%;
  background: var(--light-clr);
  border: var(--onePx) solid var(--light-grey-clr);
  box-shadow: 0 0 0.875rem 0 rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  padding: 1rem;
  margin-top: 0.75rem;
  right: 0;
  left: 0;
  transition: all 0.3s ease-out;
  max-height: 96vh;
  opacity: ${(props) => (props.show ? "1" : "0")};
  z-index: ${(props) => (props.show ? "4" : "-1")};
  position: absolute;
  @media (min-width: 36em) {
    min-width: 36em;
  }
`;
const DaterangePickerStyled = styled(IconWrapper)`
  display: flex;
  align-items: center;
  gap: 0.25em;
  span {
    font-size: var(--fs-12);
    font-family: var(--ff-main);
    font-weight: var(--fw-normal);
  }
  svg {
    height: 0.875em;
    width: 0.875em;
  }
  @media (min-width: 36em) {
    span {
      font-size: clamp(var(--fs-12), 2vw, var(--fs-16));
    }
  }
`;
export default DaterangePickerStyled;
