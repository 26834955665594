import { put, takeEvery, call, all } from "redux-saga/effects";

import {
  fetchdrillDownDataComplete,
  fetchingPaymentForecastDetails,
  fetchingReportDetails,
  fetchPaymentForecastDetails,
  fetchPaymentForecastDetailsComplete,
  fetchReportDetails,
  fetchReportDetailsComplete,
  fetchSummaryDataComplete,
  setDrillDownData,
  setSummaryData,
} from "./actions";

import {
  INIT_PAYMENT_FORECAST_DETAILS,
  INIT_REPORTS_DETAILS,
  INIT_FETCH_SUMMARY_DATA,
  INIT_FETCH_DRILLDOWN_DATA,
} from "./actionTypes";

import axios from "../../../config/axiosCall";
import { setChartProps } from "../config";

export default function* watchReportDetailsFetch() {
  yield all([
    takeEvery(INIT_REPORTS_DETAILS, getReportDetailsSaga),
    takeEvery(INIT_PAYMENT_FORECAST_DETAILS, getPaymentForecastSaga),
    takeEvery(INIT_FETCH_SUMMARY_DATA, getSummaryDataSaga),
    takeEvery(INIT_FETCH_DRILLDOWN_DATA, getDrilldownDataSaga),
  ]);
}

function* getReportDetailsSaga(action) {
  const { invoiceStartDate, invoiceToDate, currencyID } = action.data;
  const url = `/dashboard/dashboardInvoiceStatus?startDate=${invoiceStartDate}&endDate=${invoiceToDate}&currencyID=${currencyID}`;
  yield put(fetchingReportDetails());
  try {
    const response = yield call(axios, url);
    if (response && response.status === 200) {
      yield put(fetchReportDetails(response.data));
    } else {
      yield put(fetchReportDetailsComplete());
    }
  } catch (err) {
    yield put(fetchReportDetailsComplete());
  }
}

function* getPaymentForecastSaga(action) {
  const noOfDays = action.day;
  const currencyID = action.currencyID;
  const url = `/dashboard/dashboardInvoiceDataForEachDay?noOfDays=${noOfDays}&currencyID=${currencyID}`;
  yield put(fetchingPaymentForecastDetails());
  try {
    const response = yield call(axios, url);
    if (response && response.status === 200) {
      yield put(fetchPaymentForecastDetails(response.data));
    } else {
      yield put(fetchPaymentForecastDetailsComplete());
    }
  } catch (err) {
    yield put(fetchPaymentForecastDetailsComplete());
  }
}

function* getSummaryDataSaga(action) {
  const { startDate, endDate, currencyID } = action.payload || {};
  yield put(fetchSummaryDataComplete(false));
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  const url = `/Dashboard/summary?startDate=${startDate}&endDate=${endDate}&currencyID=${currencyID}&languageID=${languageID}`;
  try {
    const response = yield call(axios, url);
    if (response) {
      const { data } = response || [];
      const updatedData =
        data &&
        data.length > 0 &&
        data
          .sort((curr, prev) => {
            return curr.widgetSortOrder - prev.widgetSortOrder;
          })
          .map((item) => {
            const chartProps = setChartProps(item.widgetKey);
            return {
              ...item,
              label: chartProps.label,
              chartType: chartProps.chartType,
              widgetMetricsData: JSON.parse(item.widgetMetricsData),
            };
          });
      yield put(setSummaryData(updatedData));
      yield put(fetchSummaryDataComplete(true));
       yield put(fetchdrillDownDataComplete(true));
    }
  } catch (err) {
    yield put(fetchSummaryDataComplete(false));
  }
}

function* getDrilldownDataSaga(action) {
  yield put(fetchdrillDownDataComplete(false));
  let sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  let languageID =
    sessionDetails &&
    sessionDetails.languageId &&
    sessionDetails.languageId !== ""
      ? sessionDetails.languageId
      : "enGB";
  let url = "";
  if (action.widgetKey === "IR") {
    url =
      "/Dashboard/widgetById?widgetKey=" +
      action.widgetKey +
      "&statusKey=" +
      action.statusKey +
      "&startDate=" +
      action.fromDate +
      "&endDate=" +
      action.toDate +
      "&languageID=" +
      languageID +
      "&rejectedReason=" +
      action.label;
  } else {
    url =
      "/Dashboard/widgetById?widgetKey=" +
      action.widgetKey +
      "&statusKey=" +
      action.statusKey +
      "&startDate=" +
      action.fromDate +
      "&endDate=" +
      action.toDate +
      "&languageID=" +
      languageID;
  }
  try {
    const response = yield call(axios, url);
    if (response) {
      yield put(
        setDrillDownData(response.data, action.widgetKey, action.label)
      );
      yield put(fetchdrillDownDataComplete(true));
    }
  } catch (err) {
    yield put(fetchdrillDownDataComplete(false));
  }
}
