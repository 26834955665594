import { dropdownContent } from "globalStyles";
import styled from "styled-components";
export const PreferencesList = styled.ul``;
export const Preference = styled.li``;

const PreferencesStyled = styled.section`
  ${dropdownContent};
  inset: unset;
  min-width: 12rem;
  z-index: 3;
  background-color: #fff;
  position: absolute;
  border: 0.0625em solid #dfe3e8;
  border-radius: 0.5em;
  transform: ${(props) => (props.show ? `scale(1, 1)` : `scale(0, 0)`)};
  transform-origin: right top;
  transition: transform 400ms ease-in-out;
  right: 0;
  top: 100%;
  padding: 0.75em;
  text-align: start;
  box-shadow: 0 0.125rem 2rem 0 rgba(0, 0, 0, 0.1);
  font-size: var(--fs-12);
  color: var(--dark-clr);
  font-family: var(--ff-medium);
  font-weight: var(--fw-normal);
  h1 {
    font-size: inherit;
    font-weight: inherit;
  }
  ${PreferencesList} {
    padding-block: 1em;
    ${Preference} {
      font-size: var(--fs-14);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    ${Preference} + ${Preference} {
      margin-top: 1em;
    }
  }
`;

export default PreferencesStyled;
