import { A } from "components";
import styled from "styled-components";
const SiteTitle = styled(A)`
  display: flex;
  align-items: center;
  gap: 1em;
  margin: 0 auto;
  img {
    order: 1;
    height: 1.3em;
    @media (min-width: 62em) {
      height: 2.5em;
    }
  }
  h1 {
    order: 2;
    color: var(--light-clr);
    font-size: clamp(var(--fs-12), 1vw, var(--fs-16));
    font-family: var(--ff-medium);
    font-weight: var(--fw-normal);
    @media (min-width: 48em) {
      font-size: var(--fs-16);
    }
  }
`;

export default SiteTitle;
