import styled from "styled-components";
import { textAreaRules } from "config/formInputRules";
const TextareaControl = styled.textarea.attrs((props) => ({
  maxLength:props.maxLength?props.maxLength:textAreaRules.maxLength,
  autoComplete: "off",
}))`
  width: 100%;
  padding: 1em;
  padding-left: 0.75rem;
  outline: 0;
  border: var(--oneDotFivePx) solid var(--input-border-clr);
  border-color: ${(props) => (props.error ? "var(--error-clr)" : "")};
  border-radius: 0.5em;
  user-select: ${(props) => (props.disabled ? "none" : "all")};
  background: ${(props) =>
    props.disabled ? "var(--disabled-clr)" : "var(--light-clr)"};
  font-size: var(--fs-16);
  margin-bottom: 1em;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};
  font-family: var(--ff-main);
  :focus,
  :hover {
    box-shadow: rgb(102 187 255 / 50%) 0px 0px 0.8rem;
    border-color: var(--primary-clr);
    outline: none;
  }
  :not(:focus):invalid {
    color: ${(props) => (props.visited ? "var(--error-clr)" : "")};
    border-color: ${(props) => (props.visited ? "var(--error-clr)" : "")};
    ::placeholder {
      color: ${(props) => (props.visited ? "var(--error-clr)" : "")};
    }
  }
`;

export default TextareaControl;
