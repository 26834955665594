import styled from "styled-components";

import { Checkbox, Cols } from "components/index";

export const SearchContainer = styled.div`
  padding: 1em 1em 0 1em;
`;

export const ListContainer = styled(Cols)`
  overflow-y: auto;
  padding: 1em;
`;

export const List = styled(Cols)`
  flex-wrap: wrap;
`;

export const CheckboxContainer = styled.div`
  flex: 1 0 100%;
  margin-bottom: 1em;

  @media only screen and (min-width: 992px) {
    flex: 0 0 49%;
  }
`;

export const SCheckbox = styled(Checkbox)`
  & + label {
    color: rgb(33, 43, 54);
    font-size: clamp(var(--fs-12), 2vw, var(--fs-16));
    font-family: var(--ff-main);
    font-weight: var(--fw-normal);
  }
`;

export const BtnWrapper = styled(Cols)`
  justify-content: flex-end;
  padding: 1em;
`;
