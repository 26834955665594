import { createSelector } from "reselect";
import { initialState } from "./reducers";

const selectUploadDocument = (state) =>
  state.get("uploadDocument", initialState);

export const selectUploadInvoiceFiles = () =>
  createSelector(selectUploadDocument, (VPState) => VPState.get("files"));
export const selectBackupUploadInvoiceFiles = () =>
  createSelector(selectUploadDocument, (VPState) => VPState.get("backupFiles"));

export const selectNonPOBulkSupplierID = () =>
  createSelector(selectUploadDocument, (VPState) =>
    VPState.get("nonPoBulkSupplierID")
  );

export const selectEditMode = () =>
  createSelector(selectUploadDocument, (VPState) => VPState.get("editMode"));

export const selectViewMode = () =>
  createSelector(selectUploadDocument, (VPState) => VPState.get("viewMode"));

export const selectSelectedCard = () =>
  createSelector(selectUploadDocument, (VPState) =>
    VPState.get("selectedCard")
  );

export const selectSelectAll = () =>
  createSelector(selectUploadDocument, (VPState) => VPState.get("selectAll"));

export const selectCurrentInvoiceRef = () =>
  createSelector(selectUploadDocument, (VPState) =>
    VPState.get("currentInvoiceRef")
  );

export const selectUploadProgress = () =>
  createSelector(selectUploadDocument, (VPState) =>
    VPState.get("uploadProgress")
  );
