import React from "react";
import SvgIcon from "./style";

const Slider = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 16 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1178.000000, -84.000000)" fill="#637381">
          <g transform="translate(821.000000, 59.000000)">
            <g transform="translate(357.000000, 24.000000)">
              <path d="M15.5,2.24999999 L8.99999998,2.24999999 L8.99999998,1.49999998 C8.99999998,1.22499998 8.775,0.999999983 8.49999998,0.999999983 L7.49999998,0.999999983 C7.225,0.999999983 6.99999998,1.22499998 6.99999998,1.49999998 L6.99999998,2.24999999 L0.49999998,2.24999999 C0.224999981,2.24999999 -2.00000002e-08,2.47499999 -2.00000002e-08,2.74999999 L-2.00000002e-08,3.24999999 C-2.00000002e-08,3.52499999 0.224999981,3.74999999 0.49999998,3.74999999 L6.99999998,3.74999999 L6.99999998,4.49999998 C6.99999998,4.77499999 7.225,4.99999998 7.49999998,4.99999998 L8.49999998,4.99999998 C8.775,4.99999998 8.99999998,4.77499999 8.99999998,4.49999998 L8.99999998,3.74999999 L15.5,3.74999999 C15.775,3.74999999 16,3.52499999 16,3.24999999 L16,2.74999999 C16,2.47499999 15.775,2.24999999 15.5,2.24999999 Z M15.5,12.25 L4.99999998,12.25 L4.99999998,11.5 C4.99999998,11.225 4.77499999,11 4.49999998,11 L3.49999998,11 C3.22499999,11 2.99999998,11.225 2.99999998,11.5 L2.99999998,12.25 L0.49999998,12.25 C0.224999981,12.25 -2.00000002e-08,12.475 -2.00000002e-08,12.75 L-2.00000002e-08,13.25 C-2.00000002e-08,13.525 0.224999981,13.75 0.49999998,13.75 L2.99999998,13.75 L2.99999998,14.5 C2.99999998,14.775 3.22499999,15 3.49999998,15 L4.49999998,15 C4.77499999,15 4.99999998,14.775 4.99999998,14.5 L4.99999998,13.75 L15.5,13.75 C15.775,13.75 16,13.525 16,13.25 L16,12.75 C16,12.475 15.775,12.25 15.5,12.25 Z M15.5,7.25 L13,7.25 L13,6.5 C13,6.225 12.775,6 12.5,6 L11.5,6 C11.225,6 11,6.225 11,6.5 L11,7.25 L0.49999998,7.25 C0.224999981,7.25 -2.00000002e-08,7.475 -2.00000002e-08,7.75 L-2.00000002e-08,8.25 C-2.00000002e-08,8.525 0.224999981,8.75 0.49999998,8.75 L11,8.75 L11,9.5 C11,9.775 11.225,10 11.5,10 L12.5,10 C12.775,10 13,9.775 13,9.5 L13,8.75 L15.5,8.75 C15.775,8.75 16,8.525 16,8.25 L16,7.75 C16,7.475 15.775,7.25 15.5,7.25 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Slider;
