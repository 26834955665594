import React from "react";
import SvgIcon from "./style";

const Plus = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-1115.000000, -291.000000)">
          <g transform="translate(1097.000000, 281.000000)">
            <g transform="translate(16.000000, 8.000000)">
              <path d="M13.5,6.99999998 L8.99999998,6.99999998 L8.99999998,2.49999998 C8.99999998,2.22374999 8.77625,1.99999998 8.49999998,1.99999998 L7.49999998,1.99999998 C7.22375,1.99999998 6.99999998,2.22374999 6.99999998,2.49999998 L6.99999998,6.99999998 L2.49999998,6.99999998 C2.22374999,6.99999998 1.99999998,7.22375 1.99999998,7.49999998 L1.99999998,8.49999999 C1.99999998,8.77625 2.22374999,8.99999999 2.49999998,8.99999999 L6.99999998,8.99999999 L6.99999998,13.5 C6.99999998,13.77625 7.22375,14 7.49999998,14 L8.49999998,14 C8.77625,14 8.99999998,13.77625 8.99999998,13.5 L8.99999998,8.99999999 L13.5,8.99999999 C13.77625,8.99999999 14,8.77625 14,8.49999999 L14,7.49999998 C14,7.22375 13.77625,6.99999998 13.5,6.99999998 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default Plus;
