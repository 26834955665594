import React, { useEffect, useState } from "react";

import LabledControl from "./style";
import { ErrorText } from "components/index";

import TextareaControl from "../Textarea/style";
import { CustomLabel } from "components/Label/";

const LabeledTextarea = (props) => {
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    props.resetVisited && setVisited(false);
  }, [props.resetVisited]);

  const onInputChange = (e) => {
    let { value, maxLength } = e.target;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    const strOfVal = strippedHtml.toString();

    if (strOfVal.length > maxLength) {
      const validatedLength = strOfVal.substring(0, maxLength);
      e.target.value = validatedLength;
    }
    props.onChangeHandler && props.onChangeHandler(e);
  };
  const handleOnBlur = (e) => {
    setVisited(true);
    let { value, maxLength } = e.target;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    const strOfVal = strippedHtml.toString();

    if (strOfVal.length > maxLength) {
      const validatedLength = strOfVal.substring(0, maxLength);
      e.target.value = validatedLength;
    }
    props.onBlurHandler && props.onBlurHandler(e);
  };
  const passedProps = { ...props };
  delete passedProps.onBlurHandler;
  delete passedProps.onChangeHandler;
  delete passedProps.showError;
  return (
    <LabledControl
      disabled={props.disabled}
      error={props.showError}
      visited={visited || props.value}
    >
      <CustomLabel required={props.required} htmlFor={props.id}>
        {props.label}
      </CustomLabel>
      <TextareaControl
        {...passedProps}
        error={props.showError}
        visited={visited || props.value}
        aria-label={props.label}
        onBlur={handleOnBlur}
        onChange={onInputChange}
        rows={props.rows || 2}
      />
      <ErrorText>{props.showError ? props.error : null}</ErrorText>
    </LabledControl>
  );
};

export default LabeledTextarea;
