import styled from "styled-components";
export const NotificationWrapper = styled.div`
  display: flex;
  background-color: ${(props) =>
    props.type === "success"
      ? "rgb(126, 195, 68)"
      : props.type === "warning"
      ? "rgb(255, 200, 44)"
      : props.type === "danger"
      ? "rgb(213, 0, 47)"
      : null};
  border-radius: 0 0 1rem 1rem;
  width: 100%;
  margin: 0 5%;
  padding: 0.3rem 0.8rem 0.3rem 0.3rem;
  position: relative;
  top: -1.25rem;
  /* @media (min-width: 30em){
    padding: 0.68rem 1.85rem;
    margin:0 8%;
  }
  @media (min-width: 36em){
    margin:0 10%;
  }
  @media (min-width: 48em){
    margin:0 12%;
  }
  @media (min-width: 62em){
    margin:0 15%;
  } */
`;

export const NotificationTextWrapper = styled.div`
  flex-grow: 1;
  padding: 0.3rem 0.8rem 0.3rem 0.3rem;
  @media (min-width: 30em) {
    padding: 0.68rem 1.85rem;
  }
`;

export const NotificationText = styled.p`
  color: ${(props) =>
    props.type === "danger" ? "rgb(255, 255, 255)" : " rgb(21, 24, 27)"};
  font-size: clamp(var(--fs-12), 3.5vw, var(--fs-16));
  font-family: graphik;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.02rem;
  line-height: 1.37rem;
`;

export const CloseIconWrapper = styled.div`
  height: clamp(0.5rem, 3.5vw, 1.09rem);
  width: clamp(0.5rem, 3.5vw, 1.09rem);
  margin: 0.5rem 0;
  cursor: pointer;
  @media (min-width: 30em) {
    margin: auto;
  }
  svg {
    color: ${(props) =>
      props.type === "danger" ? "var(--light-clr)" : "var(--dark-clr)"};
  }
`;
