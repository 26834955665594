import { put, takeEvery, call, all } from "redux-saga/effects";

import {
  fetchingRejectedInvoices,
  fetchingRejectedInvoicesFailed,
  fetchRejectedInvoices,
  fetchRejectedInvoicesCompleted,
  getForcastPaymentsDataCompleted,
  getForcastPaymentsDataFetchFailed,
  getInvoiceHistoryByIdCompleted,
  getInvoiceHistoryByIdFetchFailed,
  getLandingPageDataCompleted,
  getLandingPageDataFailed,
  getOverduePaymentsDataCompleted,
  getOverduePaymentsDataFetchFailed,
  // rejectedInvoicesResolved,
  // rejectedInvoicesResolving,
  // rejectedInvoicesResolvingFailed,
} from "./actions";

import {
  INIT_GET_FORCAST_INVOICES,
  INIT_GET_INVOICE_HISTORY_BY_ID,
  INIT_GET_LANDING_PAGE_DATA,
  INIT_GET_OVERDUE_INVOICES,
  INIT_GET_REJECTED_INVOICES,
  // INIT_RESOLVE_REJECTED_INVOICES,
} from "./actionTypes";
import axios from "../../../config/axiosCall";
// import { postRequestDetails } from "../../../config/requestHeaders";
import { errorMessages } from "../tools/FailedMessages";
import { store } from "react-notifications-component";
import moment from "moment";

export default function* watchHomePage() {
  // yield all([takeEvery(GET_NOTIFICATION_DATA, getNotification)]);
  yield all([takeEvery(INIT_GET_REJECTED_INVOICES, getRejectedInvoices)]);
  yield all([takeEvery(INIT_GET_LANDING_PAGE_DATA, getLandingPageData)]);
  yield all([takeEvery(INIT_GET_OVERDUE_INVOICES, getOverduePaymentsData)]);
  yield all([takeEvery(INIT_GET_FORCAST_INVOICES, getForcastPaymentsData)]);
  yield all([takeEvery(INIT_GET_INVOICE_HISTORY_BY_ID, getInvoiceHistoryById)]);
  yield all([
    // takeEvery(INIT_RESOLVE_REJECTED_INVOICES, resolveRejectedInvoicesSaga),
  ]);
}
const findDuplicates = (data, flag) => {
  const todaysDate = data["currentDate"] || moment().format("DD-MMM-YYYY");
  if (flag === "today") {
    return Object.keys(data).filter(
      (key) => key && (data[key] === todaysDate || key === "currentDate")
    );
  } else {
    return [
      ...new Set(data.filter((v) => data.indexOf(v) !== data.lastIndexOf(v))),
    ];
  }
};
const compareDates = (obj) => {
  const isDatesSameAsTodaysDate = findDuplicates(obj, "today");
  const getSameDateValueExcluToday = findDuplicates(Object.values(obj)).filter(
    (item) => item !== null && item !== "",
    "others"
  );
  if (isDatesSameAsTodaysDate && isDatesSameAsTodaysDate.length) {
    const addMergedDatesKeyValuePair = {
      [`${isDatesSameAsTodaysDate.join("/")}`]: moment().format("DD-MMM-YYYY"),
    };
    isDatesSameAsTodaysDate.forEach((e) => delete obj[e]);
    Object.assign(obj, addMergedDatesKeyValuePair);
  }
  const getKeyPairsOfSameValue =
    getSameDateValueExcluToday && getSameDateValueExcluToday.length > 0
      ? Object.keys(obj).filter(
          (key) => obj[key] === getSameDateValueExcluToday[0]
        )
      : [];
  const addMergedDatesKeyValuePair = getKeyPairsOfSameValue &&
    getKeyPairsOfSameValue.length > 0 && {
      [getKeyPairsOfSameValue.join("/")]: moment(
        getSameDateValueExcluToday[0]
      ).format("DD-MMM-YYYY"),
    };
  getKeyPairsOfSameValue.forEach((e) => delete obj[e]);
  Object.assign(obj, addMergedDatesKeyValuePair);

  // obj = {
  //   ...obj,
  //   todaysDate: moment().format('DD-MMM-YYYY')
  // }
  // }
  return obj;
};

function* getRejectedInvoices(action) {
  yield put(fetchingRejectedInvoices());
  const url = `/Users/rejectedInvoices?currencyID=${action.currencyID}`;
  try {
    let response = yield call(axios, url);
    if (response && response.data) {
      let updateData =
        response.data &&
        response.data.map((item) => ({
          ...item,
          // nextPaymentDate: "NA",
        }));

      updateData = updateData.map((objItem) => {
        let updatedObj = {};
        for (const key in objItem) {
          if (key === "getDates" && Object.keys(objItem[key]).length > 0) {
            const datesObj = { ...objItem[key] };
            for (const dateKey in datesObj) {
              datesObj[dateKey] = moment(datesObj[dateKey]).isValid()
                ? moment(datesObj[dateKey]).format("DD-MMM-YYYY")
                : datesObj[dateKey];
            }
            updatedObj = {
              ...objItem,
              getDates: compareDates({ ...datesObj }),
            };
          }
        }
        return updatedObj;
      });

      yield put(fetchRejectedInvoices(updateData));
      yield put(fetchRejectedInvoicesCompleted());
    } else if (response && response.status === 204) {
      store.addNotification({
        ...errorMessages["noRecordFound"],
        dismiss: {
          duration: 1000,
        },
      });
      yield put(fetchRejectedInvoices([]));
      yield put(fetchRejectedInvoicesCompleted());
    } else {
      yield put(fetchingRejectedInvoicesFailed(true));
      store.addNotification({
        ...errorMessages["rejectedInvoicesFetchError"],
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(fetchingRejectedInvoicesFailed(true));
    store.addNotification({
      ...errorMessages["rejectedInvoicesFetchError"],
      dismiss: {
        duration: 1000,
      },
    });
  }
}
function* getLandingPageData(action) {
  const url = `/Users/landingPageData?noOfDays=${action.noOfDays}&currencyID=${action.currencyID}`;
  try {
    let response = yield call(axios, url);
    if (response && response.data) {
      yield put(getLandingPageDataCompleted(response.data));
    } else if (response && response.status === 204) {
      store.addNotification({
        ...errorMessages["noRecordFound"],
        dismiss: {
          duration: 1000,
        },
      });
      yield put(getLandingPageDataCompleted([]));
    } else {
      store.addNotification({
        ...errorMessages["getLandingPageDataFailed"],
        dismiss: {
          duration: 1000,
        },
      });
      yield put(getLandingPageDataFailed(true));
    }
  } catch (error) {
    yield put(getLandingPageDataFailed(true));
    store.addNotification({
      ...errorMessages["getLandingPageDataFailed"],
      dismiss: {
        duration: 1000,
      },
    });
    console.log(error);
  }
}

function* getOverduePaymentsData(action) {
  const url = `/Users/overdueInvoices?currencyID=${action.currencyID}`;
  try {
    let response = yield call(axios, url);
    if (response && response.data) {
      let updateData = response.data.map((item) => ({
        ...item,
        // nextPaymentDate: "NA",
      }));
      updateData = updateData.map((objItem) => {
        let updatedObj = {};
        for (const key in objItem) {
          if (key === "getDates" && Object.keys(objItem[key]).length > 0) {
            const datesObj = { ...objItem[key] };
            for (const dateKey in datesObj) {
              datesObj[dateKey] = moment(datesObj[dateKey]).isValid()
                ? moment(datesObj[dateKey]).format("DD-MMM-YYYY")
                : datesObj[dateKey];
            }
            updatedObj = {
              ...objItem,
              getDates: compareDates({ ...datesObj }),
            };
          }
        }
        return updatedObj;
      });
      yield put(getOverduePaymentsDataCompleted(updateData));
    } else if (response && response.status === 204) {
      store.addNotification({
        ...errorMessages["noRecordFound"],
        dismiss: {
          duration: 1000,
        },
      });
      yield put(getOverduePaymentsDataCompleted([]));
    } else {
      yield put(getOverduePaymentsDataFetchFailed(true));
      store.addNotification({
        ...errorMessages["overdueInvoicesFetchError"],
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(getOverduePaymentsDataFetchFailed(true));
    store.addNotification({
      ...errorMessages["overdueInvoicesFetchError"],
      dismiss: {
        duration: 1000,
      },
    });
  }
}

function* getForcastPaymentsData(action) {
  const url = `/Users/forecastInvoices?currencyID=${action.currencyID}`;
  try {
    let response = yield call(axios, url);
    if (response && response.data) {
      let updateData = response.data.map((item) => ({
        ...item,
        // nextPaymentDate: "NA",
      }));
      updateData = updateData.map((objItem) => {
        let updatedObj = {};
        for (const key in objItem) {
          if (key === "getDates" && Object.keys(objItem[key]).length > 0) {
            const datesObj = { ...objItem[key] };
            for (const dateKey in datesObj) {
              datesObj[dateKey] = moment(datesObj[dateKey]).isValid()
                ? moment(datesObj[dateKey]).format("DD-MMM-YYYY")
                : datesObj[dateKey];
            }
            updatedObj = {
              ...objItem,
              getDates: compareDates({ ...datesObj }),
            };
          }
        }
        return updatedObj;
      });
      yield put(getForcastPaymentsDataCompleted(updateData));
    } else if (response && response.status === 204) {
      store.addNotification({
        ...errorMessages["noRecordFound"],
        dismiss: {
          duration: 1000,
        },
      });
      yield put(getForcastPaymentsDataCompleted([]));
    } else {
      yield put(getForcastPaymentsDataFetchFailed(true));
      store.addNotification({
        ...errorMessages["forcastInvoicesFetchError"],
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(getForcastPaymentsDataFetchFailed(true));
    store.addNotification({
      ...errorMessages["forcastInvoicesFetchError"],
      dismiss: {
        duration: 1000,
      },
    });
  }
}

function* getInvoiceHistoryById(action) {
  const url = `/Users/getInvoiceHistory?InvoiceID=${action.invoiceId}`;
  try {
    let response = yield call(axios, url);
    if (response && response.data) {
      yield put(getInvoiceHistoryByIdCompleted(response.data));
    } else if (response && response.status === 204) {
      store.addNotification({
        ...errorMessages["noRecordFound"],
        dismiss: {
          duration: 1000,
        },
      });
      yield put(getInvoiceHistoryByIdCompleted(response.data));
    } else {
      yield put(getInvoiceHistoryByIdFetchFailed(true));
      store.addNotification({
        ...errorMessages["invoiceHistoryByIdFetchError"],
        dismiss: {
          duration: 1000,
        },
      });
    }
  } catch (error) {
    yield put(getInvoiceHistoryByIdFetchFailed(true));
    console.log(error);
    store.addNotification({
      ...errorMessages["invoiceHistoryByIdFetchError"],
      dismiss: {
        duration: 1000,
      },
    });
  }
}

// function* resolveRejectedInvoicesSaga(action) {
//   yield put(rejectedInvoicesResolving());
//   const url = "/Search/markAsResolved";
//   const requestDetails = { ...postRequestDetails };
//   yield (requestDetails.data = {
//     listOfIds: action.invoiceID.toString(),
//     userId: action.userId,
//   });
//   try {
//     let response = yield call(axios, url, requestDetails);
//     if (response && response.status) {
//       console.log("true", action.invoiceID);
//       if (action && action.resolveSuccess) {
//         action.resolveSuccess(action.invoiceID);
//       }
//       yield put(rejectedInvoicesResolved());
//     } else {
//       yield put(rejectedInvoicesResolvingFailed(true));
//       store.addNotification({
//         ...errorMessages["rejectedInvoicesResolvingError"],
//         dismiss: {
//           duration: 1000,
//         },
//       });
//     }
//   } catch (error) {
//     yield put(rejectedInvoicesResolvingFailed(true));
//     store.addNotification({
//       ...errorMessages["rejectedInvoicesResolvingError"],
//       dismiss: {
//         duration: 1000,
//       },
//     });
//   }
// }
