import styled from "styled-components";
import { IconWrapper } from "components";
export const BackButton = styled(IconWrapper)`
  width: clamp(1.5em, 3.5vw, 2em);
  height: clamp(1.5em, 3.5vw, 2em);
  border: 0.0625rem solid rgb(196, 205, 213);
  border-radius: 1.25rem;
  color: var(--grey-clr);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  font-size: 1rem;
  svg {
    height: 0.75em;
    width: 0.75em;
  }
  @media (min-width: 48em) {
    svg {
      height: 1em;
      width: 1em;
    }
  }
`;
export const ModuleHeaderTitle = styled.h1``;
export const ModuleHeaderActionButtons = styled.div``;
const ModuleHeaderStyled = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0.5em 0 0.5em 0.75em;
  gap: 0.5em;
  color: var(--dark-clr);
  ${ModuleHeaderTitle} {
    font-size: var(--fs-14);
    font-family: var(--ff-semibold);
    font-weight: var(--fw-bold);
  }
  ${ModuleHeaderActionButtons} {
    display: flex;
    flex-wrap: wrap;
    gap: clamp(0.5em, 2vw, 1em);
    flex-grow: 1;
    justify-content: flex-end;
    flex-grow: 1;
  }
  @media (min-width: 48em) {
    ${ModuleHeaderTitle} {
      font-size: clamp(var(--fs-18), 3.5vw, var(--fs-24));
    }
  }
`;

export default ModuleHeaderStyled;
