import { Rows } from "components/index";
import styled from "styled-components";

export const AdditionalContent = styled(Rows)``;
const PrivacyPolicy = styled(Rows)`
  overflow: auto;
  line-height: 1.3;
  padding: 0 1em 1em 1em;
  gap: 1em;
  h1 {
    font-size: clamp(var(--fs-14), 3vw, var(--fs-18));
  }
  h2 {
    font-size: clamp(var(--fs-12), 3vw, var(--fs-14));
  }
  p {
    font-size: clamp(var(--fs-12), 3vw, var(--fs-16));
  }
  ${AdditionalContent} {
  }
`;

export default PrivacyPolicy;
