export const statusCode = {
  LC: [
    {
      code: "001",
      message: "msp.loginSuccessful",
    },
    {
      code: "002",
      message: "msp.misMatchLoginTypeMsg",
    },
    {
      code: "003",
      message: "msp.changePasswordOnLogin",
    },
    {
      code: "004",
      message: "msp.accountIsLocked",
    },
    {
      code: "005",
      message: "msp.PasswordExpiryMessage",
    },
    {
      code: "006",
      message: "msp.wrongUsernamePassword",
    },
    {
      code: "007",
      message: "msp.incorrectPasswordAttempts",
    },
    {
      code: "008",
      message: "msp.someErrorOccurred",
    },
    {
      code: "009",
      message: "msp.passwordValidationMessage",
    },
    {
      code: "010",
      message: "msp.incorrectOldPassword",
    },
    {
      code: "011",
      message: "msp.passwordCannotChangedMessage",
    },
    {
      code: "012",
      message: "msp.errorMessage",
    },
    {
      code: "013",
      message: "msp.noLongerOTPvalid",
    },
    {
      code: "015",
      message: "msp.passwordChangedSuccessfully",
    },
    {
      code: "017",
      message: "msp.wrongUsernamePassword",
    },
    {
      code: "019",
      message: "msp.passwordAlreadyUpdated",
    },
    {
      code: "022",
      message: "msp.EnterUsernamePasswordValue",
    },
    {
      code: "023",
      message: "msp.passwordNotMatchMessage",
    },
    {
      code: "024",
      message: "msp.userNameAlreadyTaken",
    },
    {
      code: "028",
      message: "msp.errorMessage",
    },
    {
      code: "029",
      message: "msp.newOldPasswordValidation",
    },
    {
      code: "030",
      message: "msp.correctCurrentPasswordMessage",
    },
    {
      code: "031",
      message: "msp.passwordCannotChangedMessage",
    },
    {
      code: "032",
      message: "msp.errorMessage",
    },
    {
      code: "034",
      message: "msp.userAccountLockedMessage",
    },
    {
      code: "035",
      message: "msp.passwordChangedSuccessfully",
    },
    {
      code: "051",
      message: "msp.userNotExist",
    },
    {
      code: "036",
      message: "msp.wrongSecurityQuestion",
    },
    {
      code: "037",
      message: "msp.securityQuestionNotSetMsg",
    },
    {
      code: "038",
      message: "msp.securityQuesAnsValidation",
    },
    {
      code: "039",
      message: "msp.passwordMismatch",
    },
    {
      code: "052",
      message: "msp.incorrectOTP",
    },
    {
      code: "053",
      message: "msp.passwordHintTwo",
    },
    {
      code: "055",
      message: "msp.userIsRejected",
    },
    {
      code: "056",
      message: "msp.lockedAccountMessage",
    },
    {
      code: "054",
      message: "msp.expiredLinkToken",
    },
    {
      code: "059",
      message: "msp.expiredLinkToken",
    },
    {
      code: "060",
      message: "msp.OTPValidationsuccessful"
    },
    {
      code: "061",
      message: "msp.accountLockedOTP"
    },
    {
      code: "062",
      message: "msp.SSOForgetPassword"
    }
  ],
  UC: [
    {
      code: "002",
      message: "msp.usernameHint",
    },
    {
      code: "004",
      message: "msp.invalidTemplateFormat",
    },
    {
      code: "005",
      message: "msp.usernameIncorrect",
    },
    {
      code: "006",
      message: "msp.invalidCoumnName",
    },
    {
      code: "007",
      message: "msp.maximumUserUpload",
    },
    {
      code: "010",
      message: "msp.groupnameAlreadyInUse",
    },
    {
      code: "011",
      message: "msp.userDetailsInvalid",
    },
    {
      code: "012",
      message: "msp.wrongOTPMessage",
    },
    {
      code: "013",
      message: "msp.noLongerOTPvalid",
    },
    {
      code: "057",
      message: "msp.roleAlreadyExists",
    },
    {
      code: "016",
      message: "msp.userAlreadyConfirmed",
    },
    {
      code: "017",
      message: "msp.userIsRejected",
    },
    {
      code: "018",
      message: "msp.userNotApprovedMessage",
    },
    {
      code: "019",
      message: "msp.incorrectPassword",
    },
    {
      code: "020",
      message: "msp.onlyOneSheetAllowed",
    },
    {
      code: "027",
      message: "msp.onlyTwoSheetAllowed",
    },
    {
      code: "021",
      message: "msp.userApprovalUnderProgress",
    },
    {
      code: "022",
      message: "msp.userEmailAlreadyExists",
    },
    {
      code: "023",
      message: "msp.assignSupplierToAccount",
    },
    {
      code: "057",
      message: "msp.userRoleExistsMessage",
    },
  ],
  SC: [
    {
      code: "021",
      message: "msp.primaryEmailAreadyExists",
    },
    {
      code: "022",
      message: "msp.locationAlreadyExists",
    },
    {
      code: "031",
      message: "msp.proxyCannotTakenMessage",
    },
    {
      code: "032",
      message: "msp.proxyErrorOccuredMessage",
    },
  ],
  RECONC: [
    {
      code: "016",
      message: "msp.emptyRowsExcelInfo",
    },
    {
      code: "017",
      message: "msp.uploadedExcelHint",
    },
    {
      code: "018",
      message: "msp.sNoHint",
    },
    {
      code: "019",
      message: "msp.incorrectCurrencyFieldInf",
    },
    {
      code: "020",
      message: "msp.incorrectPoNumberFieldInfo",
    },
    {
      code: "021",
      message: "msp.onlyOneSheetAllowed",
    },
    {
      code: "022",
      message: "msp.incorrectInvoiceAmountFieldInfo",
    },
    {
      code: "023",
      message: "msp.IncorrectCompanyNameMsg",
    },
    {
      code: "024",
      message: "msp.maxRecordsExceeded",
    },
    {
      code: "025",
      message: "msp.incorrectDateProvided",
    },
    {
      code: "026",
      message: "msp.incorrectHeaderNameinExcel",
    },
    {
      code: "050",
      message: "msp.extentionNotAllowed",
    },
    {
      code: "051",
      message: "msp.exeFile",
    },
  ],
  SUBC: [
    {
      code: "001",
      message: "msp.invalidFileMsg",
    },
    {
      code: "002",
      message: "msp.NoRecordFoundInfo",
    },
    {
      code: "003",
      message: "msp.duplicateRecordsFound",
    },
    {
      code: "004",
      message: "msp.saveSubmissionFailed",
    },
    {
      code: "005",
      message: "msp.duplicateFileNameMsg",
    },
    {
      code: "006",
      message: "msp.invalidExcelData",
    },
    {
      code: "007",
      message: "msp.uploadAttachmentHint",
    },
    {
      code: "008",
      message: "msp.incorrectHeaderLineColumn",
    },
    {
      code: "009",
      message: "msp.improperFileFormat",
    },
    {
      code: "012",
      message: "msp.noPONumberRequired",
    },
    {
      code: "013",
      message: "msp.noPOnumberField",
    },
    {
      code: "016",
      message: "msp.submissionCreatedNavigatedInfo",
    },
    {
      code: "017",
      message: "msp.submissionFailedInfo",
    },
    {
      code: "018",
      message: "msp.attachmentImproper",
    },
    {
      code: "019",
      message: "msp.columnsRequired",
    },
    {
      code: "020",
      message: "msp.excelColumnNullValue",
    },
    {
      code: "022",
      message: "msp.onlyOneSheetAllowed",
    },
    {
      code: "025",
      message:
        "Date in the uploaded excel did not match the date format selected. Please check.",
    },
    {
      code: "050",
      message: "msp.extentionNotAllowed",
    },
    {
      code: "051",
      message: "msp.exeFile",
    },
  ],
  EINV: [
    {
      code: "001",
      message: "msp.alreadyExistInvoiceInfo",
    },
    {
      code: "004",
      message: "msp.submissionUpdateFailed",
    },
    {
      code: "005",
      message: "msp.saveSubmissionFailed",
    },
    {
      code: "006",
      message: "msp.markAsResolvedFailed",
    },
    {
      code: "007",
      message: "msp.recordsAlreadyCreated",
    },
    {
      code: "050",
      message: "msp.extentionNotAllowed",
    },
    {
      code: "051",
      message: "msp.exeFile",
    },
  ],
  CON: [
    {
      code: "001",
      message: "msp.invalidRequestDetailsInfo",
    },
  ],
  AC: [
    {
      code: "001",
      message: "msp.attributeNameValidation",
    },
  ],
  ET: [
    {
      code: "001",
      message: "msp.emailTemplateValidation",
    },
  ],
  CLC: [{ code: "001", message: "msp.customListValidation" }],
  HC: [
    {
      code: "002",
      message: "msp.exeFile",
    },
  ],
};
