import React from "react";
import SvgIcon from "./style";
const Bell = (props) => {
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 18 20"
      xmlSpace="preserve"
    >
      <g>
        <g transform="translate(-1.000000, 0.000000)">
          <path
            d="M9.7,16.6c0.5,1.3-0.2,2.7-1.5,3.2c-1.3,0.5-2.7-0.2-3.2-1.5l0,0L9.7,16.6z M5.1,0.1
c0.6-0.2,1.4,0.1,1.6,0.7l0,0L7,1.6c2.9-0.4,5.7,1.1,6.8,4c1.4,3.8,3.1,4.4,4.1,4.9c0.3,0.2,0.5,0.4,0.6,0.7
c0.2,0.6,0,1.3-0.8,1.6l0,0L3.7,17.9c-0.7,0.3-1.4-0.1-1.6-0.7c-0.1-0.3-0.1-0.6,0-0.9c0.4-1,1.3-2.6,0-6.4C1,7,2.2,4,4.7,2.4l0,0
L4.4,1.7C4.2,1,4.5,0.3,5.1,0.1z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Bell;
