import {
  INIT_GET_HELPDESK_QUERY_LIST, FETCHING_HELPDESK_QUERY_LIST, FETCH_HELPDESK_QUERY_LIST,
  HELPDESK_QUERY_LIST_FETCH_COMPLETE,
  INIT_GET_EMAIL_TEMPLATES_LIST, FETCHING_GET_EMAIL_TEMPLATES_LIST, FETCH_GET_EMAIL_TEMPLATES_LIST, GET_EMAIL_TEMPLATES_LIST_FETCH_COMPLETE,
  INIT_GET_HELPDESK_QUERY_ATTRIBUTE_LIST,
  FETCHING_HELPDESK_QUERY_ATTRIBUTE_LIST,FETCH_HELPDESK_QUERY_ATTRIBUTE_LIST,
  HELPDESK_QUERY_ATTRIBUTE_LIST_FETCH_COMPLETE,
  INIT_GET_HELPDESK_QUERY_BY_ID,FETCHING_HELPDESK_QUERY_BY_ID,FETCH_HELPDESK_QUERY_BY_ID,HELPDESK_QUERY_BY_ID_FETCH_COMPLETE, INIT_HELPDESK_CONFIG_SAVE, HELPDESK_CONFIG_SAVING_FAILED, HELPDESK_CONFIG_SAVED, HELPDESK_CONFIG_SAVING
} from "./actionTypes";

export const initGetHelpdeskQueryList = () => {
  return {
    type: INIT_GET_HELPDESK_QUERY_LIST,
  };
};

export const fetchingHelpdeskQueryList = () => {
  return {
    type: FETCHING_HELPDESK_QUERY_LIST,
  };
};

export const fetchHelpdeskQueryList = (data) => {
  return {
    type: FETCH_HELPDESK_QUERY_LIST,
    data: data,
  };
};

export const helpdeskQueryListFetchingComplete = () => {
  return {
    type: HELPDESK_QUERY_LIST_FETCH_COMPLETE,
  };
};

export const initGetEmailTemplatesList = () => {
  return {
    type: INIT_GET_EMAIL_TEMPLATES_LIST,
  };
};

export const fetchingEmailTemplatesList = () => {
  return {
    type: FETCHING_GET_EMAIL_TEMPLATES_LIST,
  };
};

export const fetchEmailTemplatesList = (data) => {
  return {
    type: FETCH_GET_EMAIL_TEMPLATES_LIST,
    data: data,
  };
};

export const emailTemplatesListFetchingComplete = () => {
  return {
    type: GET_EMAIL_TEMPLATES_LIST_FETCH_COMPLETE,
  };
};

export const initGetHelpdeskAttributeList = () => {
  return {
    type: INIT_GET_HELPDESK_QUERY_ATTRIBUTE_LIST,
  };
};

export const fetchingHelpdeskAttributeList = () => {
  return {
    type: FETCHING_HELPDESK_QUERY_ATTRIBUTE_LIST,
  };
};

export const fetchHelpdeskAttributeList = (data) => {
  return {
    type: FETCH_HELPDESK_QUERY_ATTRIBUTE_LIST,
    data: data,
  };
};

export const HelpdeskAttributeListFetchingComplete = () => {
  return {
    type: HELPDESK_QUERY_ATTRIBUTE_LIST_FETCH_COMPLETE,
  };
};

export const initGetHelpdeskQueryByID = (
  queryID,
  fetchSuccessHelpdeskQueryByID
) => {
  return {
    type: INIT_GET_HELPDESK_QUERY_BY_ID,
    queryID,
    fetchSuccessHelpdeskQueryByID,
  };
};

export const fetchingHelpdeskQueryByID = () => {
  return {
    type: FETCHING_HELPDESK_QUERY_BY_ID,
  };
};

export const fetchHelpdeskQueryByID = (data) => {
  return {
    type: FETCH_HELPDESK_QUERY_BY_ID,
    data: data,
  };
};

export const HelpdeskQueryByIDFetchingComplete = () => {
  return {
    type: HELPDESK_QUERY_BY_ID_FETCH_COMPLETE,
  };
};

export const initHelpdeskConfigarationSave = (
  formdata,
  helpdeskConfigSaveSuccess
) => {
  return {
    type: INIT_HELPDESK_CONFIG_SAVE,
    formdata,
    helpdeskConfigSaveSuccess,
  };
};

export const HelpdeskConfigSaving = () => {
  return {
    type: HELPDESK_CONFIG_SAVING,
  };
};

export const HelpdeskConfigSaved = () => {
  return {
    type: HELPDESK_CONFIG_SAVED,
  };
};

export const HelpdeskConfigSavingFailed = () => {
  return {
    type: HELPDESK_CONFIG_SAVING_FAILED,
  };
};