import GlobalSearchStyled from "components/Layout/Header/SiteActions/GlobalSearch/style";

import styled from "styled-components";
import { Dropdown } from "../style";
import CurrencyDropdownStyled from "./CurrencyDropdown/style";
import NotificationStyled from "./Notifications/style";

export const GlobalSearchDropdown = styled(Dropdown)``;
export const CurrencyDropdown = styled(Dropdown)``;
export const NotificationDropdown = styled(Dropdown)`
  position: relative;
  ::before {
    content: "";
    position: absolute;
    width: 0.5em;
    height: 0.5em;
    background-color: var(--error-clr);
    color: var(--light-clr);
    top: 0.5em;
    right: 0.52em;
    border-radius: 50%;
  }
`;

export const ActionItems = styled.li`
  border: 0.0625em solid transparent;
  border-radius: 0;
  /*  */
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: var(--fs-18);
  ${Dropdown} {
    padding: 0.5rem;
  }
  @media (min-width: 36em) {
    font-size: var(--fs-16);
    ${Dropdown} {
      color: var(--light-clr);
    }
  }
`;

export const GlobalSearchPortal = styled(ActionItems)``;
export const NotificationActionItem = styled(ActionItems)``;

const SiteActionStyled = styled.ul`
  display: flex;
  flex-direction: column;
  border: var(--onePx) solid transparent;
  order: 1;
  gap: 0.5em;
  color: var(--primary-clr);
  background-color: var(--light-clr);
  position: absolute;
  top: 100%;
  padding: 0.5em 0.25em;
  height: calc(100vh - 3.5em);
  transform: scale(0, 1);
  transform-origin: left;
  transition: transform 400ms ease-in-out;
  margin: 0;
  left: 0;
  max-width: 3em;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  z-index: 5;

  @media (min-width: 36em) {
    all: unset;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    color: var(--light-clr);
    align-items: center;
    gap: 0.75em;
    order: 3;
    margin-left: auto;
  }

  ${ActionItems} {
    position: relative;
    margin: 0;
    ${Dropdown} {
      gap: 0.5em;
      svg {
        transition: transform 400ms ease-in-out;
      }
    }

    ${CurrencyDropdownStyled} {
      transform: ${(props) =>
        props.currencyDropdownOpened ? `scale(1, 1)` : ``};
    }
    ${NotificationStyled} {
      transform: ${(props) => (props.notificationOpened ? `scale(1, 1)` : ``)};
    }
    :hover,
    :focus {
      border-color: var(--primary-clr);

      @media (min-width: 36em) {
        border-color: var(--light-clr);
      }
    }
    ${CurrencyDropdown} {
      svg {
        transform: ${(props) => {
          if (props.hasCurrency && props.currencyDropdownOpened) {
            return `rotate(-90deg)`;
          }
        }};
        @media (min-width: 36em) {
          transform: ${(props) => {
            if (props.hasCurrency && props.currencyDropdownOpened) {
              return `rotate(-180deg)`;
            }
          }};
        }
      }
    }
  }
  ${NotificationActionItem} {
    position: static;
    @media (min-width: 36em) {
      position: relative;
    }
  }
  ${GlobalSearchPortal} {
    z-index: 2;
    :hover,
    :focus {
      ${(props) => props.searchOpened && `border:none`};
    }

    ${GlobalSearchStyled} {
      transform: ${(props) =>
        props.searchOpened ? `scale(1, 1)` : `scale(0, 1);`};
    }

    @media (min-width: 36em) {
      position: static;

      ${(props) =>
        props.searchOpened
          ? `padding: 0;
              border: none;
              flex-grow: 1;`
          : ``};
      ${GlobalSearchStyled} {
        transition: transform 400ms ease-in-out, opacity 200ms ease-in-out;
        ${(props) =>
          props.searchOpened
            ? `transform: scale(1, 1); inset: 0; opacity: 1; z-index: 1;`
            : `transform: scale(0, 0); opacity: 0; z-index: -9999;`};
      }
      :focus,
      :focus-within {
        ${GlobalSearchStyled} {
          transform: unset;
        }
      }
    }
    @media (min-width: 62em) {
      ${GlobalSearchStyled} {
        position: ${(props) => (props.searchOpened ? `relative` : `absolute`)};
      }
    }
  }

  @media (min-width: 62em) {
    position: relative;
    ${GlobalSearchStyled} {
      width: 100%;
    }
    ${GlobalSearchDropdown} {
      display: ${(props) => (props.searchOpened ? `none` : `block`)};
    }
  }
`;

export default SiteActionStyled;
