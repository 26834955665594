import { useEffect } from "react";
import { getImageContainer } from "../func/index";
import {
  useMouseDownEvent,
  useMouseMoveEvent,
  useMouseUpEvent,
} from "./useRubberbandMouseEvents";
import useInputSelection from "./useInputSelection";

export default function useOCREventListeners(fullOCRExtract) {
  const captureMouseDown = useMouseDownEvent();
  const captureMouseUp = useMouseUpEvent(fullOCRExtract);
  const captureMouseMove = useMouseMoveEvent();
  const selectInput = useInputSelection();
  useEffect(() => {
    const imgContainer = getImageContainer();
    const lineInputs = document.querySelectorAll('[data-inputtype="L"]');
    if (lineInputs) {
      [...lineInputs].forEach((input) => {
        input.addEventListener("focus", (e) => selectInput(e, "l"));
      });
    }

    if (imgContainer) {
      imgContainer.addEventListener("mousedown", captureMouseDown);
      imgContainer.addEventListener("mouseup", captureMouseUp);
      imgContainer.addEventListener("mousemove", captureMouseMove);

      return () => {
        imgContainer.removeEventListener("mousedown", captureMouseDown);
        imgContainer.removeEventListener("mouseup", captureMouseUp);
        imgContainer.removeEventListener("mousemove", captureMouseMove);
        if (lineInputs) {
          [...lineInputs].forEach((input) => {
            input.removeEventListener("focus", (e) => selectInput(e, "l"));
          });
        }
      };
    }
  }, [
    captureMouseDown,
    captureMouseMove,
    captureMouseUp,
    fullOCRExtract,
    selectInput,
  ]);
}
